import { isUndefined } from "@/src/utils/is";
import { useContext } from "react";
import {
  StaffSignUpButtonStatusContext,
  StaffSignUpResourceContext,
  StaffSignupStepContext,
} from ".";

export const useStaffSignUpStepContext = () => {
  const context = useContext(StaffSignupStepContext);

  if (isUndefined(context)) {
    throw Error("Signup Form Context가 존재 하지 않습니다.");
  }

  return context;
};

export const useStaffSignUpResourceContext = () => {
  const context = useContext(StaffSignUpResourceContext);

  if (isUndefined(context)) {
    throw Error("Sign Up Resource Context가 존재 하지 않습니다.");
  }

  return context;
};

export const useStaffSignUpButtonStatusContext = () => {
  const context = useContext(StaffSignUpButtonStatusContext);

  if (isUndefined(context)) {
    throw Error("SignUp Button Context가 존재 하지 않습니다.");
  }

  return context;
};
