import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const columnEmailHistoryList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "emailSender",
    flex: 1,
    minWidth: 130,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.emailSender) {
        return <Typo typoType="b9r">{params.data.emailSender}</Typo>;
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "templateType",
    flex: 1,
    minWidth: 190,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.templateType) {
        return <Typo typoType="b9r">{params.data.templateType}</Typo>;
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "buyerName",
    flex: 1,
    minWidth: 190,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.buyerName) {
        return <Typo typoType="b9r">{params.data.buyerName}</Typo>;
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "recipients",
    flex: 1,
    minWidth: 230,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.recipients) {
        return <Typo typoType="b9r">{params.data.recipients}</Typo>;
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "cc",
    flex: 1,
    minWidth: 260,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.cc) {
        return <Typo typoType="b9r">{params.data.cc}</Typo>;
      }

      return "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "sentDateAt",
    flex: 1,
    minWidth: 170,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.sentDateAt) {
        return (
          <Typo typoType="b9r">
            {dayjs
              .utc(params.data.sentDateAt)
              .format(DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
];
