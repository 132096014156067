import GuideBookSvg from "@/src/assets/icons/icon-guide-book.svg";
import useAlert from "@/src/hooks/useAlert";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import {
  useEditExporterUserAdditionalInfosMutation,
  useGetExporterUserAdditionalInfosQuery,
} from "@/src/store/apis/auth";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { useGetFactoriesQuery } from "@/src/store/apis/client/factory";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import { useGetWarehousesQuery } from "@/src/store/apis/warehouse";
import colorSet from "@/src/styles/color";
import { isNull, isUndefined } from "@/src/utils/is";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Icon from "../../atom/Icon";
import Loader from "../../atom/Loader";
import Typo from "../../atom/Typo";
import Flex from "../../molecule/Flex";
import SectionCardWithoutHeader from "../../molecule/SectionCardWithoutHeader";
import GuideCloseContent from "./GuideCloseContent";
import GuideContent from "./GuideContent";
import GuideCount from "./GuideCount";

interface QuickStartGuideProps {
  type: "exporter" | "importer";
}

const QuickStartGuide = ({ type }: QuickStartGuideProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const alert = useAlert();

  const [quickStartGuideClose, { isLoading }] =
    useEditExporterUserAdditionalInfosMutation();
  // 퀵가이드 열림여부
  const {
    importQuickGuideOpen,
    exportQuickGuideOpen,
    isAdditionalInfoFetching,
  } = useGetExporterUserAdditionalInfosQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        isAdditionalInfoFetching: isFetching,
        importQuickGuideOpen: isStable
          ? currentData.row.importQuickStartGuideNeeded
          : false,
        exportQuickGuideOpen: isStable
          ? currentData.row.exportQuickStartGuideNeeded
          : false,
      };
    },
  });
  // 거래처
  const { buyerListCount, isBuyerListFetching } = useGetBuyersQuery(
    { page: 1, pageSize: 1 },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          buyerListCount: isStable ? currentData.data.length : 0,
          isBuyerListFetching: isFetching,
        };
      },
    },
  );
  // 회사서명
  const { signatureCount, isSignatureCountFetching } = useGetExportersQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isSignatureCountFetching: isFetching,
          signatureCount: isStable
            ? isNull(
                currentData.signatureManagement.simpleExporterSignatureMedias,
              )
              ? 0
              : 1
            : 0,
        };
      },
    },
  );
  // 작업지
  const { factoryListCount, isFactoryListFetching } = useGetFactoriesQuery(
    { page: 1, pageSize: 1 },
    {
      // 수출자 대시보드에서만 호출
      skip: location.pathname === IMPORTER_PRIVATE_PATH.HOME,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          factoryListCount: isStable ? currentData.rows.length : 0,
          isFactoryListFetching: isFetching,
        };
      },
    },
  );
  // 입고지
  const { warehouseListCount, isWarehouseFetching } = useGetWarehousesQuery(
    { page: 1, pageSize: 1 },
    {
      // 수입자 대시보드에서만 호출
      skip: location.pathname === EXPORTER_PRIVATE_PATH.HOME,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          warehouseListCount: isStable ? currentData.rows.length : 0,
          isWarehouseFetching: isFetching,
        };
      },
    },
  );

  // 퀵 가이드 데이터 (체크여부, 문자열)
  const guideList = useMemo(() => {
    let result = [];
    switch (type) {
      case "exporter":
        result = [
          {
            title: t("common:guide.exporterClientRegistration"),
            description: (
              <Trans
                i18nKey="common:guide.exporterClientRegistrationDescription"
                components={{
                  strong: <Typo typoType="h9" color="gray6" />,
                }}
              />
            ),
            linkPath: EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD,
            isCheck: !!buyerListCount,
            circleColor: colorSet.green2,
            lineColor:
              !!buyerListCount && !!signatureCount
                ? colorSet.green2
                : colorSet.gray8,
          },
          {
            title: t("common:guide.exporterSignatureRegistration"),
            description: (
              <Trans
                i18nKey="common:guide.exporterSignatureRegistrationDescription"
                components={{
                  strong: <Typo typoType="h9" color="gray6" />,
                }}
              />
            ),
            linkPath: EXPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
            isCheck: !!signatureCount,
            circleColor: !!buyerListCount ? colorSet.green2 : colorSet.gray8,
            lineColor:
              !!factoryListCount && !!signatureCount
                ? colorSet.green2
                : colorSet.gray8,
          },
          {
            title: t("common:guide.exporterFactoryRegistration"),
            description: (
              <Trans
                i18nKey="common:guide.exporterFactoryRegistrationDescription"
                components={{
                  strong: <Typo typoType="h9" color="gray6" />,
                }}
              />
            ),
            linkPath: EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_ADD,
            isCheck: !!factoryListCount,
            circleColor:
              (!!buyerListCount && !!signatureCount) || !!factoryListCount
                ? colorSet.green2
                : colorSet.gray8,
            lineColor: colorSet.green2,
          },
        ];
        break;
      case "importer":
        result = [
          {
            title: t("common:guide.importerClientRegistration"),
            description: (
              <Trans
                i18nKey="common:guide.importerClientRegistrationDescription"
                components={{
                  strong: <Typo typoType="h9" color="gray6" />,
                }}
              />
            ),
            linkPath: IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD,
            isCheck: !!buyerListCount,
            circleColor: colorSet.green2,
            lineColor:
              !!buyerListCount && !!signatureCount
                ? colorSet.green2
                : colorSet.gray8,
          },
          {
            title: t("common:guide.importerSignatureRegistration"),
            description: (
              <Trans
                i18nKey="common:guide.importerSignatureRegistrationDescription"
                components={{
                  strong: <Typo typoType="h9" color="gray6" />,
                }}
              />
            ),
            linkPath: IMPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
            isCheck: !!signatureCount,
            circleColor: !!buyerListCount ? colorSet.green2 : colorSet.gray8,
            lineColor:
              !!warehouseListCount && !!signatureCount
                ? colorSet.green2
                : colorSet.gray8,
          },
          {
            title: t("common:guide.importerWarehouseRegistration"),
            description: (
              <Trans
                i18nKey="common:guide.importerWarehouseRegistrationDescription"
                components={{
                  strong: <Typo typoType="h9" color="gray6" />,
                }}
              />
            ),
            linkPath: IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_ADD,
            isCheck: !!warehouseListCount,
            circleColor:
              (!!buyerListCount && !!signatureCount) || !!warehouseListCount
                ? colorSet.green2
                : colorSet.gray8,
            lineColor: colorSet.green2,
          },
        ];
        break;
    }
    return result;
  }, [
    type,
    t,
    buyerListCount,
    signatureCount,
    factoryListCount,
    warehouseListCount,
  ]);
  // 카운트 계산 로직 분리
  const calculateTotalCount = useCallback(
    (type: "exporter" | "importer") => {
      const baseCount = buyerListCount + signatureCount;
      return type === "exporter"
        ? baseCount + factoryListCount
        : baseCount + warehouseListCount;
    },
    [buyerListCount, factoryListCount, signatureCount, warehouseListCount],
  );

  const isCompleted = useMemo(() => {
    const totalCount = calculateTotalCount(type);
    return totalCount === 3;
  }, [calculateTotalCount, type]);

  const closeGuide = async (type: "exporter" | "importer") => {
    try {
      await quickStartGuideClose({
        exportQuickStartGuideNeeded:
          type === "exporter" ? false : exportQuickGuideOpen,
        importQuickStartGuideNeeded:
          type === "importer" ? false : importQuickGuideOpen,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleCloseButtonClick = () => {
    if (type === "exporter") {
      closeGuide("exporter");
    }
    if (type === "importer") {
      closeGuide("importer");
    }
  };

  if (
    (type === "exporter" && !exportQuickGuideOpen) ||
    (type === "importer" && !importQuickGuideOpen)
  ) {
    return null;
  }
  // Loading
  if (
    (type === "exporter" && isFactoryListFetching) ||
    (type === "importer" && isWarehouseFetching) ||
    isBuyerListFetching ||
    isSignatureCountFetching ||
    isAdditionalInfoFetching
  ) {
    return (
      <LoaderContainer justifyContent="center">
        <Loader size={40} />
      </LoaderContainer>
    );
  }

  return (
    <StyledSectionCardWithoutHeader>
      <Flex flexDirection="column">
        <Flex alignItems="center" gap={8}>
          <Icon iconSrc={GuideBookSvg} iconSize={24} />
          <Flex alignItems="center" gap={16}>
            <Typo typoType="h4">{t("common:guide.title")}</Typo>
            <GuideCount count={calculateTotalCount(type)} />
          </Flex>
        </Flex>
        <GuideContent guideList={guideList} />
        <GuideCloseContent
          isOpen={isCompleted}
          isLoading={isLoading}
          onClose={handleCloseButtonClick}
        />
      </Flex>
    </StyledSectionCardWithoutHeader>
  );
};

export default QuickStartGuide;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  padding: 24px 20px;
`;

const LoaderContainer = styled(Flex)`
  padding: 32px 0;
`;
