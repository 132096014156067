import { ExporterUserDto } from "@/src/store/apis/auth/interface";
import { ColDef, ValueGetterParams } from "ag-grid-community";

export const clientCorporateManagerColumn = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Name",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Email",
    cellStyle: { textAlign: "center" },
    field: "aId",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contact (Personal)",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.personalContact
            ? `${data?.personalContactPrefix ?? ""} ${data?.personalContact}`
            : "-"}
        </>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contact (Office)",
    cellStyle: { textAlign: "center" },
    field: "officeContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.officeContact
            ? `${data?.officeContactPrefix ?? ""} ${data?.officeContact}`
            : "-"}
        </>
      );
    },
  },
] as ColDef[];

export const staffManagerColumn = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Name",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Email",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contact (Personal)",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.personalContact
            ? `${data?.personalContactPrefix ?? ""} ${data?.personalContact}`
            : "-"}
        </>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contact (Office)",
    cellStyle: { textAlign: "center" },
    field: "officeContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.officeContact
            ? `${data?.officeContactPrefix ?? ""} ${data?.officeContact}`
            : "-"}
        </>
      );
    },
  },
] as ColDef[];
