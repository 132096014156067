import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import colorSet, { ColorType } from "@/src/styles/color";
import styled from "styled-components";

export const renderSellerDoughnutChartLabel = (
  index: number,
  label: string,
) => {
  let circle = null;

  switch (index) {
    case 0:
      circle = <Circle color="chartBlueViolet6" />;
      break;
    case 1:
      circle = <Circle color="chartBlueViolet4" />;
      break;
    case 2:
      circle = <Circle color="chartBlueViolet3" />;
      break;
    case 3:
      circle = <Circle color="chartBlueViolet2" />;
      break;
    case 4:
      circle = <Circle color="chartBlueViolet1" />;
      break;
    default:
      circle = null;
      break;
  }

  return (
    <Flex alignItems="center" gap={4}>
      {circle}
      <Typo typoType="b10r" color="gray6">
        {label}
      </Typo>
    </Flex>
  );
};

export const renderItemDoughnutChartLabel = (index: number, label: string) => {
  let circle = null;

  switch (index) {
    case 0:
      circle = <Circle color="chartAqua1" />;
      break;
    case 1:
      circle = <Circle color="chartAqua2" />;
      break;
    case 2:
      circle = <Circle color="chartAqua3" />;
      break;
    case 3:
      circle = <Circle color="chartAqua4" />;
      break;
    case 4:
      circle = <Circle color="chartAqua5" />;
      break;
    default:
      circle = null;
      break;
  }

  return (
    <Flex alignItems="center" gap={4}>
      {circle}
      <Typo typoType="b10r" color="gray6">
        {label}
      </Typo>
    </Flex>
  );
};

const Circle = styled.div<{ color: ColorType }>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${({ color }) => colorSet[color]};
`;
