import { SimpleContainerMediaDto } from "@/src/store/apis/tasks/taskDetail/interface";

export const convertImgList = (param: {
  list: SimpleContainerMediaDto[];
  maxCount: number;
}) => {
  const { list, maxCount } = param;
  let array: string[] = ["", "", ""];

  if (list.length === 3) {
    return list.slice(0, maxCount) as unknown as SimpleContainerMediaDto[];
  }

  if (list.length === 2 || list.length === 1) {
    return [...list, ...array].slice(
      0,
      maxCount,
    ) as unknown as SimpleContainerMediaDto[];
  }

  return array.slice(0, maxCount) as unknown as SimpleContainerMediaDto[];
};
