import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { IconButton } from "../../atom/Button";
import Icon from "../../atom/Icon";

interface RightArrowIconProps extends HTMLAttributes<HTMLButtonElement> {
  isCardOpen: boolean;
}

function RightArrowIcon({ isCardOpen, ...props }: RightArrowIconProps) {
  return (
    <IconButton {...props}>
      <StyledIcon
        iconSrc={ArrowDownSvg}
        iconSize={24}
        isCardOpen={isCardOpen}
      />
    </IconButton>
  );
}

export default RightArrowIcon;

const StyledIcon = styled(Icon)<{ isCardOpen: boolean }>`
  cursor: pointer;
  transition: 0.3s;

  ${({ isCardOpen }) =>
    isCardOpen
      ? css`
          transform: rotateZ(-180deg);
        `
      : css`
          transform: rotateZ(0deg);
        `};
`;
