const ROOT_PATH = "/export";

const EXPORTER_PRIVATE_PATH = {
  // Public
  ROOT: `${ROOT_PATH}`,
  LOGIN: `${ROOT_PATH}/login`,
  SIGNUP: `${ROOT_PATH}/signup`,
  CORP_SIGNUP: `${ROOT_PATH}/corp-signup`,
  STAFF_SIGNUP: `${ROOT_PATH}/staff-signup`,
  FIND_PASSWORD: `${ROOT_PATH}/find-password`,

  // Side Nav
  HOME: `${ROOT_PATH}/home`,
  CONTRACT: `${ROOT_PATH}/contract`,
  CONTRACT_DETAIL: `${ROOT_PATH}/contract/detail`,
  CONTRACT_ADD: `${ROOT_PATH}/contract/add`,
  CONTRACT_EDIT: `${ROOT_PATH}/contract/edit`,
  CONTRACT_EDIT_V2: `${ROOT_PATH}/contract/edit/v2`,

  BOOKING: `${ROOT_PATH}/booking`,
  BOOKING_ADD: `${ROOT_PATH}/booking/add`,
  BOOKING_EDIT: `${ROOT_PATH}/booking/edit`,
  BOOKING_DETAIL: `${ROOT_PATH}/booking/detail`,

  LOADING: `${ROOT_PATH}/loading`,
  LOADING_ADD: `${ROOT_PATH}/loading/add`,
  LOADING_DETAIL: `${ROOT_PATH}/loading/detail`,
  LOADING_EDIT: `${ROOT_PATH}/loading/edit`,

  SHIPMENT: `${ROOT_PATH}/shipment`,
  SHIPMENT_ADD_CI: `${ROOT_PATH}/shipment/add/ci`,
  SHIPMENT_ADD_PL: `${ROOT_PATH}/shipment/add/pl`,
  SHIPMENT_EDIT_CI: `${ROOT_PATH}/shipment/edit/ci`,
  SHIPMENT_EDIT_PL: `${ROOT_PATH}/shipment/edit/pl`,
  SHIPMENT_ADD: `${ROOT_PATH}/shipment/add`,
  SHIPMENT_DETAIL: `${ROOT_PATH}/shipment/detail`,

  CLIENT_MANAGEMENT: `${ROOT_PATH}/client-management`,
  CLIENT_MANAGEMENT_ADD: `${ROOT_PATH}/client-management/add`,
  CLIENT_MANAGEMENT_DETAIL: `${ROOT_PATH}/client-management/detail`,
  CLIENT_MANAGEMENT_EDIT: `${ROOT_PATH}/client-management/edit`,

  FACTORY_MANAGEMENT: `${ROOT_PATH}/factory-management`,
  FACTORY_MANAGEMENT_ADD: `${ROOT_PATH}/factory-management/add`,
  FACTORY_MANAGEMENT_DETAIL: `${ROOT_PATH}/factory-management/detail`,
  FACTORY_MANAGEMENT_EDIT: `${ROOT_PATH}/factory-management/edit`,

  COMPANY_INFORMATION_MANAGEMENT: `${ROOT_PATH}/company-information-management`,
  ITEM_MANAGEMENT: `${ROOT_PATH}/item-management`,
  STAFF_MANAGEMENT: `${ROOT_PATH}/staff-management`,

  NOTICE: `${ROOT_PATH}/notice`,

  INQUIRY: `${ROOT_PATH}/inquiry`,
  INQUIRY_ADD: `${ROOT_PATH}/inquiry/add`,
  INQUIRY_EDIT: `${ROOT_PATH}/inquiry/edit`,
  FAQ: `${ROOT_PATH}/faq`,

  MY_PAGE: `${ROOT_PATH}/mypage`,
  MY_PAGE_EDIT: `${ROOT_PATH}/mypage/edit`,

  FILE_BOX: `${ROOT_PATH}/file-box`,

  SUBSCRIPTION_MANAGEMENT: `${ROOT_PATH}/subscription-management`,
  SUBSCRIPTION_ANNUALLY_TERMINATION: `${ROOT_PATH}/subscription-annually-termination`,
  SUBSCRIPTION_LANDING: `${ROOT_PATH}/subscription-landing`,
  NORMAL_PLAN_SIGNUP: `${ROOT_PATH}/normal-plan-signup`,
  ENTERPRISE_SIGNUP: `${ROOT_PATH}/enterprise-signup`,
  NOT_FOUND: `${ROOT_PATH}/not-found`,
};

export default EXPORTER_PRIVATE_PATH;
