import { ContractHistory } from "../store/apis/contracts/contractDetail/interface";

export const getContractCurrentVersion = (histories: ContractHistory[]) => {
  if (!histories) {
    return {
      currentVersion: 0,
      currentVersionHistoryLength: 0,
    };
  }

  // 버전별로 그룹화
  const versionGroups = histories.reduce<Record<number, ContractHistory[]>>(
    (acc, curr) => {
      const version = curr.version;
      if (!acc[version]) {
        acc[version] = [];
      }
      acc[version].push(curr);
      return acc;
    },
    {},
  );

  // 최신 버전 찾기
  const currentVersion = Math.max(...Object.keys(versionGroups).map(Number));

  return {
    currentVersion,
    currentVersionHistoryLength: versionGroups[currentVersion]?.length ?? 0,
  };
};
