import { ReactComponent as CopySvg } from "@/src/assets/icons/icon-copy-black.svg";
import EditSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import ImageSvg from "@/src/assets/icons/icon-image.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import SendLinkCodeDialog from "@/src/components/organism/SendLinkCodeDialog";
import useAlert from "@/src/hooks/useAlert";
import { authApi } from "@/src/store/apis/auth";
import {
  companyApi,
  useSendCompanyLinkCodeMutation,
} from "@/src/store/apis/corporate/company";
import { ExporterDetailViewDto } from "@/src/store/apis/corporate/company/interface";
import colorSet from "@/src/styles/color";
import { isNullish, isUndefined } from "@/src/utils/is";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import BasicInfoEditDialog from "../dialog/BasicInfoEditDialog";

enum DialogState {
  NULL,
  BASIC_DETAIL,
  SEND_LINK_CODE,
}

const corporateManagerEmptyArray: ExporterDetailViewDto["basicInformation"]["corporateManagers"] =
  [];
const categoryEmptyArray: ExporterDetailViewDto["exporterDetailQueryResultDto"]["mainCategories"] =
  [];

const BasicInformation = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const corporateCodeRef = useRef<HTMLDivElement>(null);
  const linkCodeRef = useRef<HTMLDivElement>(null);
  const [dialogState, setDialogState] = useState(DialogState.NULL);

  // API
  const { exporterUserType } = authApi.endpoints.getSession.useQueryState(
    undefined,
    {
      selectFromResult: ({ currentData }) => {
        return {
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    },
  );
  const [sendCompanyLinkCode, { isLoading: isSendLinkCodeLoading }] =
    useSendCompanyLinkCodeMutation();

  const {
    corporateManagers,
    businessLogoSimpleMedia,
    companyCode,
    mainCategories,
    businessNumber,
    country,
    tel,
    fax,
    address,
    isFetching,
    companyLinkCode,
  } = companyApi.endpoints.getExporters.useQueryState(undefined, {
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      let countryAndCityString = isStable
        ? currentData?.basicInformation.country
        : "-";

      if (isStable) {
        const hasCityAndGmtOffset =
          !isNullish(currentData?.basicInformation.city) &&
          !isNullish(currentData?.basicInformation.gmtOffset);

        if (hasCityAndGmtOffset) {
          countryAndCityString += ` / ${
            currentData?.basicInformation.city
          } (UTC ${
            (currentData?.basicInformation.gmtOffset ?? 0) > 0 ? "+" : ""
          }${currentData?.basicInformation.gmtOffset})`;
        }
      }

      return {
        isFetching,
        corporateManagers: isStable
          ? (currentData?.basicInformation.corporateManagers ??
            corporateManagerEmptyArray)
          : corporateManagerEmptyArray,
        businessLogoSimpleMedia: isStable
          ? currentData?.basicInformation.businessLogoSimpleMedia
          : { mediaUrl: "" },
        companyCode: currentData?.basicInformation.companyCode,
        mainCategories: isStable
          ? (currentData.exporterDetailQueryResultDto.mainCategories ??
            categoryEmptyArray)
          : categoryEmptyArray,
        businessNumber: isStable
          ? currentData.basicInformation.businessNumber
          : "-",
        country: isStable ? countryAndCityString : "-",
        tel:
          isStable &&
          currentData.exporterDetailQueryResultDto.tel &&
          currentData.exporterDetailQueryResultDto.telPrefix
            ? `${currentData.exporterDetailQueryResultDto.telPrefix} ${currentData.exporterDetailQueryResultDto.tel}`
            : "-",
        fax:
          isStable &&
          currentData.exporterDetailQueryResultDto.fax &&
          currentData.exporterDetailQueryResultDto.faxPrefix
            ? `${currentData.exporterDetailQueryResultDto.faxPrefix} ${currentData.exporterDetailQueryResultDto.fax}`
            : "-",
        address: isStable
          ? (currentData?.basicInformation.address ?? "-")
          : "-",
        companyLinkCode: isStable
          ? currentData.exporterDetailQueryResultDto.companyLinkCode
          : "-",
      };
    },
  });

  const corporateInfo = [
    {
      label: t(
        "companyManagement:importer.companyInfo.basicInfo.businessNumber",
      ),
      value: businessNumber,
    },
    {
      label: t(
        "companyManagement:importer.companyInfo.basicInfo.countryAndCity",
      ),
      value: `${country}`,
    },
    {
      label: t("companyManagement:importer.companyInfo.basicInfo.contact"),
      value: tel,
    },
    {
      label: t("companyManagement:importer.companyInfo.basicInfo.fax"),
      value: fax,
    },
    {
      label: t("companyManagement:importer.companyInfo.basicInfo.address"),
      value: address,
    },
  ];

  const getCorporateManagerList = () => {
    if (corporateManagers.length === 0) {
      return ["", ""];
    }

    if (corporateManagers.length === 1) {
      return [...corporateManagers, ""];
    }

    return corporateManagers;
  };

  const handleCorporateCodeCopyClick = async () => {
    if (corporateCodeRef) {
      const corporateCode = corporateCodeRef.current?.childNodes[0].textContent;

      window.navigator.clipboard.writeText(corporateCode as string).then(() => {
        alert.showAlert({
          type: "success",
          message: t("alert:corporateCodeCopy"),
        });
      });
    }
  };

  const handleLinkCodeCopyClick = async () => {
    if (companyLinkCode === "-") {
      return;
    }

    if (linkCodeRef) {
      const linkCode = linkCodeRef.current?.childNodes[0].textContent;

      window.navigator.clipboard.writeText(linkCode as string).then(() => {
        alert.showAlert({ type: "success", message: t("alert:linkCodeCopy") });
      });
    }
  };

  const handleLinkCodeOpenClick = () => {
    setDialogState(DialogState.SEND_LINK_CODE);
  };

  const handleSendLinkCodeClick = async (emailList: string[]) => {
    try {
      await sendCompanyLinkCode({ emailList }).unwrap();
      setDialogState(DialogState.NULL);

      alert.showAlert({
        type: "success",
        message: t("sendLinkCodeDialog:common.emailSuccessAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderBasicInfoDialog = () => {
    if (dialogState === DialogState.NULL) {
      return;
    }

    if (dialogState === DialogState.BASIC_DETAIL) {
      return (
        <BasicInfoEditDialog
          open={dialogState === DialogState.BASIC_DETAIL}
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.SEND_LINK_CODE) {
      return (
        <SendLinkCodeDialog
          open={dialogState === DialogState.SEND_LINK_CODE}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          linkCode={companyLinkCode ?? "-"}
          onOk={handleSendLinkCodeClick}
          isLoading={isSendLinkCodeLoading}
        />
      );
    }
  };

  const renderBusinessLogo = () => {
    if (businessLogoSimpleMedia?.mediaUrl) {
      return (
        <LogoImage logo={businessLogoSimpleMedia.mediaUrl} $isLogo={true} />
      );
    }

    return <LogoImagePlaceholder logo={ImageSvg} $isLogo={false} />;
  };

  return (
    <SectionCard
      cardTitle={t("companyManagement:importer.companyInfo.basicInfo.title")}
      rightAccessory={
        exporterUserType === "CORPORATE_MANAGER" && (
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => setDialogState(DialogState.BASIC_DETAIL)}
            disabled={isFetching}
            isLoading={isFetching}
          >
            <FlexCenter>
              <Icon iconSrc={EditSvg} iconSize={16} />
              {t("companyManagement:importer.companyInfo.basicInfo.editButton")}
            </FlexCenter>
          </Button>
        )
      }
    >
      {renderBasicInfoDialog()}

      <BasicInformationContainer>
        <LeftFlexColumn>
          {renderBusinessLogo()}

          <SectionCardRow
            label={t(
              "companyManagement:importer.companyInfo.basicInfo.corporateCode",
            )}
            direction="vertical"
            value={
              <CorporateCodeContainer ref={corporateCodeRef}>
                <Typo typoType="h3" color="blue2" data-value={companyCode}>
                  {companyCode}
                </Typo>
                <CopyCorporateCodeButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  onClick={handleCorporateCodeCopyClick}
                >
                  <CopyIcon />
                </CopyCorporateCodeButton>
              </CorporateCodeContainer>
            }
          />
          <SectionCardRow
            label={t(
              "companyManagement:importer.companyInfo.basicInfo.mainCategory",
            )}
            direction="vertical"
            value={
              <CategoryListContainer>
                {mainCategories.map((item, idx) => {
                  return (
                    <li key={idx.toString()}>
                      <Typo color="gray5" typoType="b7r">
                        {item.codeItemNameEn}
                      </Typo>
                    </li>
                  );
                })}
              </CategoryListContainer>
            }
          />

          {exporterUserType === "CORPORATE_MANAGER" && (
            <>
              <Divider />
              <SectionCardRow
                label={t(
                  "companyManagement:importer.companyInfo.basicInfo.linkCode",
                )}
                direction="vertical"
                value={
                  <LinkCodeContainer ref={linkCodeRef}>
                    <div>
                      <Typo typoType="h3" color="blue2" data-value={"-"}>
                        {companyLinkCode}
                      </Typo>
                      <CopyCorporateCodeButton
                        buttonColor="black"
                        buttonGrade="tertiary"
                        onClick={handleLinkCodeCopyClick}
                        disabled={companyLinkCode === "-"}
                      >
                        <CopyIcon data-disabled={companyLinkCode === "-"} />
                      </CopyCorporateCodeButton>
                    </div>

                    <Button
                      buttonSize={32}
                      buttonColor="black"
                      buttonGrade="tertiary"
                      disabled={companyLinkCode === "-"}
                      onClick={handleLinkCodeOpenClick}
                    >
                      {t(
                        "companyManagement:importer.companyInfo.basicInfo.send",
                      )}
                    </Button>
                  </LinkCodeContainer>
                }
              />
            </>
          )}
        </LeftFlexColumn>

        <RightFlexColumn>
          {corporateInfo.map(({ label, value }, idx) => {
            return (
              <SectionCardRow
                label={label}
                direction="vertical"
                value={value}
                key={idx.toString()}
              />
            );
          })}

          <Divider />

          <SectionCardRow
            label={t(
              "companyManagement:importer.companyInfo.basicInfo.corporateManager",
            )}
            direction="vertical"
            value={
              <CorporateManagerContainer>
                {getCorporateManagerList()?.map(({ name, email }: any, idx) => {
                  return (
                    <li key={idx.toString()}>
                      <Typo typoType="b9m">{name ?? "-"}</Typo>
                      <Typo color="gray6" typoType="b10r">
                        {email}
                      </Typo>
                    </li>
                  );
                })}
              </CorporateManagerContainer>
            }
          />
        </RightFlexColumn>
      </BasicInformationContainer>
    </SectionCard>
  );
};

export default BasicInformation;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LeftFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  max-width: 163px;
`;

const RightFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 3;
`;

const BasicInformationContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const CorporateCodeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LinkCodeContainer = styled(CorporateCodeContainer)`
  flex-direction: column;
  align-items: start;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & > button {
    width: 100%;
    text-align: center;
  }
`;

const CategoryListContainer = styled.ul`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  li {
    list-style: none;

    &::after {
      content: ",";
      color: ${colorSet.gray5};
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
`;

const CopyCorporateCodeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

const CorporateManagerContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const LogoImage = styled.div<{ logo: string; $isLogo: boolean }>`
  width: 148px;
  height: 148px;
  border-radius: 15px;
  border: 1px solid ${colorSet.gray10};
  background: ${({ logo }) => `url(${logo}) no-repeat`};
  background-position: center;
  background-size: ${({ $isLogo }) => ($isLogo ? "contain" : "auto")};
`;

const LogoImagePlaceholder = styled(LogoImage)`
  width: 148px;
  height: 148px;
  border-radius: 15px;
  border: 1px solid ${colorSet.gray10};
  background: ${({ logo }) => `url(${logo}) no-repeat ${colorSet.gray10}`};
  background-position: center;
  background-size: auto;
`;

const CopyIcon = styled(CopySvg)<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
