import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const columnSalesList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    headerName: "Order Date",
    field: "orderDateAt",
    flex: 1,
    sortable: false,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r" color="gray2">
          {dayjs(params.data.orderDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD,
          )}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    headerName: "Contract No.",
    field: "salesNumber",
    flex: 1,
    sortable: false,
    minWidth: 160,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "warehouse.warehouseName",
    flex: 1,
    sortable: false,
    minWidth: 160,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "exporterItemCode.itemCode",
    flex: 1,
    sortable: false,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r" color="gray2">
          {params.data?.exporterItemCode?.itemCode || ""}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "finalProducts",
    flex: 1,
    sortable: false,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r" color="gray2">
          {params.data.salesFinalProducts
            .map(({ finalProduct }: any) => finalProduct.name)
            .join(", ")}
        </Typo>
      );
    },
  },
];
