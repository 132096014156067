import { ReactComponent as TooltipBlackIcon } from "@/src/assets/icons/icon-tooltip-black.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";

type TooltipPlacement =
  | "leftBottom"
  | "rightBottom"
  | "bottom"
  | "top"
  | "left"
  | "right"
  | "leftTop"
  | "rightTop";
type TooltipOpenType = "hover" | "default" | "click";

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  triggerComponent?: ReactNode;
  tooltipColor: ColorType;
  openType?: TooltipOpenType;
  placement?: TooltipPlacement;
  isArrow?: boolean;
  distance?: number;
}

const Tooltip = forwardRef<HTMLDivElement, PropsWithChildren<TooltipProps>>(
  (
    {
      triggerComponent,
      tooltipColor,
      placement = "bottom",
      openType = "default",
      children,
      isArrow = true,
      distance = 15,
      ...props
    },
    ref,
  ) => {
    const tooltipRef = useRef<HTMLDivElement>();
    const [isShowTrigger, setIsShowTrigger] = useState(false);

    const renderTooltip = () => {
      if (
        openType === "default" ||
        (openType === "hover" && isShowTrigger) ||
        (openType === "click" && isShowTrigger)
      ) {
        return (
          <TooltipContent
            ref={(node) => {
              if (node) {
                tooltipRef.current = node;
              }
              if (ref) {
                if (typeof ref === "function") {
                  ref(node);
                } else {
                  ref.current = node;
                }
              }
            }}
            onMouseOver={() => openType === "hover" && setIsShowTrigger(true)}
            onMouseLeave={() => openType === "hover" && setIsShowTrigger(false)}
            placement={placement}
            distance={distance}
            {...props}
          >
            <TooltipChildren $tooltipColor={tooltipColor}>
              {children}
            </TooltipChildren>

            {isArrow && (
              <StyledTooltipIcon
                $tooltipColor={tooltipColor}
                placement={placement}
                viewBox="0 0 10 6"
              />
            )}
          </TooltipContent>
        );
      }
    };

    return (
      <TooltipContainer>
        <TooltipTrigger
          onMouseOver={() => {
            openType === "hover" && setIsShowTrigger(true);
          }}
          onMouseLeave={() => {
            openType === "hover" && setIsShowTrigger(false);
          }}
          onClick={() =>
            openType === "click" && setIsShowTrigger((prev) => !prev)
          }
        >
          {triggerComponent}
        </TooltipTrigger>

        {renderTooltip()}
      </TooltipContainer>
    );
  },
);

export default Tooltip;

const getArrowPosition = (placement: TooltipPlacement) => {
  switch (placement) {
    case "rightTop":
    case "leftTop":
    case "top":
      return css`
        transform: rotate(180deg);
      `;

    case "rightBottom":
    case "leftBottom":
    case "bottom":
      return css``;

    case "left":
      return css`
        transform: rotate(90deg) translateY(2px);
      `;

    case "right":
      return css`
        transform: rotate(-90deg) translateY(2px);
      `;
  }
};

const getTooltipContainerStyle = (
  placement: TooltipPlacement,
  distance: number,
) => {
  const transformDistance = `${distance}px`;

  switch (placement) {
    case "top":
      return css`
        flex-direction: column;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: calc(transformDistance - 3px);
        bottom: ${transformDistance};
      `;

    case "leftTop":
      return css`
        flex-direction: column;
        align-items: center;
        left: 0;
        transform: translateX(-50%);
        padding-bottom: 3px;
        bottom: ${transformDistance};
      `;

    case "rightTop":
      return css`
        flex-direction: column;
        align-items: center;
        right: 0;
        transform: translateX(50%);
        padding-bottom: 3px;
        bottom: ${transformDistance};
      `;

    case "bottom":
      return css`
        flex-direction: column-reverse;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 3px;
        top: ${transformDistance};
      `;

    case "leftBottom":
      return css`
        flex-direction: column-reverse;
        align-items: center;
        left: 0;
        transform: translateX(-50%);
        padding-top: 3px;
        top: ${transformDistance};
      `;

    case "rightBottom":
      return css`
        flex-direction: column-reverse;
        align-items: center;
        transform: translateX(50%);
        right: 0;
        padding-top: 3px;
        top: ${transformDistance};
      `;

    case "left":
      return css`
        align-items: center;
        transform: translateY(-50%);
        top: 50%;
        padding-left: 3px;
        right: ${transformDistance};
      `;

    case "right":
      return css`
        flex-direction: row-reverse;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        padding-right: 3px;
        left: ${transformDistance};
      `;
  }
};

const TooltipContainer = styled.figure`
  position: relative;
  display: flex;
`;

const TooltipTrigger = styled.div`
  display: flex;
  width: 100%;
`;

const TooltipContent = styled.div<{
  placement: TooltipPlacement;
  distance: number;
}>`
  display: flex;
  position: absolute;
  ${({ placement, distance }) => getTooltipContainerStyle(placement, distance)}
`;

const TooltipChildren = styled.div<{ $tooltipColor: ColorType }>`
  text-align: center;
  width: max-content;
  padding: 4px 10px;
  border-radius: 4px;
  background: ${({ $tooltipColor }) => colorSet[$tooltipColor]};
`;

const StyledTooltipIcon = styled(TooltipBlackIcon)<{
  $tooltipColor: ColorType;
  placement: TooltipPlacement;
}>`
  ${({ placement }) => getArrowPosition(placement)}

  path {
    fill: ${({ $tooltipColor }) => colorSet[$tooltipColor]};
  }
`;
