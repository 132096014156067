import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import MixedChart from "@/src/components/atom/chart/MixedChart";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import { commonApi } from "@/src/store/apis/common";
import { useGetCategoryStatisticQuery } from "@/src/store/apis/dashboard/importer";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChartOptions } from "chart.js";
import dayjs from "dayjs";
import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useImporterStatisticsFilterContext } from "../hooks/hook";
import {
  getDailyChartDataSums,
  getMonthlyChartDataSums,
} from "../utils/getChartDataFormat";
import { getDayNames, getLast12Months } from "../utils/getDateFormat";
import {
  getDailyWeightSumsByCategory,
  getMonthlyWeightSumsByCategory,
} from "../utils/getTableDataFormat";

const THIRTY_ONE = 31;
const DEFAULT_COLUMN: Omit<ColDef, "headerName"> & {
  headerName: string;
} = {
  headerName: t("common:category"),
  headerClass: "ag-right-aligned-cell",
  field: "mainCategoryEn",
  cellStyle: { textAlign: "center" },
  lockPosition: "left",
  pinned: "left",
  width: 110,
  minWidth: 110,
  resizable: false,
  sortable: false,
};

const CHART_OPTION: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      border: {
        color: colorSet.gray10,
      },
      grid: {
        color: colorSet.gray10,
      },
    },
    y: {
      border: {
        color: colorSet.gray10,
      },
      grid: {
        color: colorSet.gray10,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      position: "bottom",
      labels: {
        color: colorSet.gray6,
        boxWidth: 6,
        boxHeight: 6,
        pointStyle: "circle",
        usePointStyle: true,
        padding: 16,
        font: {
          size: 13,
        },
      },
    },
  },
};

function ImportedVolumeTrend() {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const { ataAt, mainCategoryCodeItemName } =
    useImporterStatisticsFilterContext();

  const { currentData: mainCategories, isFetching: isMainCategoriesFetching } =
    commonApi.endpoints.getCommonCodeViaCodeName.useQueryState({
      codeName: "MAIN_CATEGORY",
    });

  const {
    totalWeight,
    totalNumberOfContainer,
    weightAndNumberOfContainerInfoList,
    isFetching,
  } = useGetCategoryStatisticQuery(
    {
      ataAtFrom: dayjs(ataAt?.[0]).startOf("day").toISOString(),
      ataAtTo: dayjs(ataAt?.[1]).endOf("day").toISOString(),
      importerMainCategories:
        mainCategoryCodeItemName !== "all"
          ? mainCategoryCodeItemName
          : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFetching,
          totalWeight: isStable ? currentData.totalWeight : 0,
          totalNumberOfContainer: isStable
            ? currentData.totalNumberOfContainer
            : 0,
          weightAndNumberOfContainerInfoList: isStable
            ? currentData.weightAndNumberOfContainerInfoDtoList
            : [],
        };
      },
    },
  );

  const startDate = dayjs(ataAt?.[0]);
  const endDate = dayjs(ataAt?.[1]);
  const duration = endDate.diff(startDate, "days");
  const isMonthlyType = duration > THIRTY_ONE;

  const tableData = isMonthlyType
    ? (getMonthlyWeightSumsByCategory({
        list: weightAndNumberOfContainerInfoList,
        ataAtTo:
          dayjs(ataAt?.[1]).startOf("day").toISOString() ||
          dayjs().startOf("day").toISOString(),
      })?.map((item) => {
        const mainCategoryData = mainCategories?.find(
          (mainCategory) => mainCategory?.codeItemName === item.mainCategory,
        );

        if (mainCategoryData) {
          return {
            ...item,
            mainCategoryEn: mainCategoryData?.codeItemNameEn ?? "-",
          };
        }

        return item;
      }) ?? [])
    : (getDailyWeightSumsByCategory({
        list: weightAndNumberOfContainerInfoList,
        ataAtFrom:
          dayjs(ataAt?.[0]).startOf("day").toISOString() ||
          dayjs().startOf("day").toISOString(),
        ataAtTo:
          dayjs(ataAt?.[1]).startOf("day").toISOString() ||
          dayjs().startOf("day").toISOString(),
      })?.map((item) => {
        const mainCategoryData = mainCategories?.find(
          (mainCategory) => mainCategory?.codeItemName === item.mainCategory,
        );

        if (mainCategoryData) {
          return {
            ...item,
            mainCategoryEn: mainCategoryData?.codeItemNameEn ?? "-",
          };
        }

        return item;
      }) ?? []);

  const getColumnDefs = useCallback((): ColDef[] => {
    if (isMonthlyType) {
      return getLast12Months(endDate)?.map(({ fullName, headerName }) => {
        return {
          headerName: `${headerName}`,
          headerClass: "ag-right-aligned-cell",
          field: fullName,
          cellStyle: { textAlign: "center" },
          width: 110,
          minWidth: 110,
          flex: 1,
          sortable: false,
        };
      });
    } else {
      return getDayNames(startDate, endDate)?.map(
        ({ fullName, headerName }) => {
          return {
            headerName: headerName,
            headerClass: "ag-right-aligned-cell",
            field: fullName,
            cellStyle: { textAlign: "center" },
            width: 110,
            minWidth: 110,
            flex: 1,
            sortable: false,
          };
        },
      );
    }
  }, [endDate, isMonthlyType, startDate]);

  const weeklyChartData = () => {
    const labelsList = isMonthlyType
      ? getLast12Months(endDate)?.map((month) => {
          return month.headerName;
        })
      : getDayNames(startDate, endDate).map((day) => {
          return day.headerName;
        });

    const weightDataList = isMonthlyType
      ? getMonthlyChartDataSums({
          list: weightAndNumberOfContainerInfoList,
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "sumOfWeight",
        }) || []
      : getDailyChartDataSums({
          list: weightAndNumberOfContainerInfoList,
          startDate: ataAt?.[0] || dayjs(),
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "sumOfWeight",
        }) || [];

    const containerDataList = isMonthlyType
      ? getMonthlyChartDataSums({
          list: weightAndNumberOfContainerInfoList,
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "numberOfContainer",
        }) || []
      : getDailyChartDataSums({
          list: weightAndNumberOfContainerInfoList,
          startDate: ataAt?.[0] || dayjs(),
          endDate: ataAt?.[1] || dayjs(),
          fieldName: "numberOfContainer",
        }) || [];

    return {
      labels: labelsList,
      datasets: [
        {
          type: "line" as const,
          label: t("dashboard:importer.shipmentSummary.importedVolume"),
          backgroundColor: colorSet.chartBlue1,
          borderColor: colorSet.chartBlue1,
          borderWidth: 2,
          data: weightDataList,
        },
        {
          type: "bar" as const,
          label: t("dashboard:importer.shipmentSummary.numberOfContainer"),
          backgroundColor: colorSet.chartViolet5,
          borderColor: colorSet.chartViolet5,
          borderWidth: 2,
          maxBarThickness: 20,
          data: containerDataList,
        },
      ],
    };
  };

  // State
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>([
    { ...DEFAULT_COLUMN, headerName: t("common:category") },
    ...getColumnDefs(),
  ]);

  const renderTotalInfo = () => {
    const data = [
      {
        label: t("dashboard:importer.statistics.totalContainerQuantity"),
        value: `${totalNumberOfContainer.toLocaleString("ko-KR")} EA`,
      },
      { isDivider: true },
      {
        label: t("dashboard:importer.statistics.totalImportedVolume"),
        value: `${totalWeight.toLocaleString("ko-KR")} MT`,
      },
    ];

    return (
      <Flex gap={10} alignItems="center">
        {data.map(({ label, value, isDivider }) => {
          if (isDivider) {
            return <Divider />;
          }

          return (
            <StyledFlex
              flexDirection="column"
              alignItems="center"
              gap={8}
              flex={1}
            >
              <Typo typoType="b9m" color="gray6">
                {label}
              </Typo>
              <Typo typoType="h4" color="blue2">
                {value}
              </Typo>
            </StyledFlex>
          );
        })}
      </Flex>
    );
  };

  const renderContent = () => {
    if (isFetching || isMainCategoriesFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    return (
      <Flex flexDirection="column" gap={16}>
        {renderTotalInfo()}
        {/* Chart */}
        <ChartContainer>
          <MixedChart data={weeklyChartData()} options={CHART_OPTION} />
        </ChartContainer>
        <HorizonDivider />

        {/* Table */}
        <Flex flexDirection="column" gap={8}>
          <TableTitle typoType="h8">
            {t("dashboard:importer.statistics.totalImportedVolume")}
          </TableTitle>
          <TableContainer>
            <Table
              ref={gridRef}
              rowData={
                tableData.length !== 0
                  ? tableData.map((item) => {
                      let newItem = {} as Record<string, string | number>;

                      for (let key in item) {
                        if (typeof item[key] === "number") {
                          newItem[key] = item[key].toLocaleString("ko-KR");
                        } else {
                          newItem[key] = item[key];
                        }
                      }

                      return newItem;
                    })
                  : []
              }
              columnDefs={columnDefs}
              isPagination={false}
              height={294}
              onGridReady={() => setIsReady(true)}
            />
          </TableContainer>

          <Flex alignItems="center" justifyContent="flex-end" gap={4}>
            <StyledIcon iconSrc={InfoSvg} />
            <Typo typoType="b9r" color="gray5">
              {t("dashboard:importer.statistics.unitInformation")}
            </Typo>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    if (gridRef.current && isReady) {
      setTimeout(() => {
        gridRef.current?.api.setColumnDefs([
          { ...DEFAULT_COLUMN, headerName: t("common:category") },
          ...getColumnDefs(),
        ]);
      }, 200);
    }
  }, [endDate, getColumnDefs, isReady, t]);

  return (
    <SectionCard
      cardTitle={t("dashboard:importer.statistics.totalImportedVolumeTrend")}
    >
      {renderContent()}
    </SectionCard>
  );
}

export default ImportedVolumeTrend;

const StyledFlex = styled(Flex)`
  margin: 16px 0;
`;

const Divider = styled.div`
  width: 1px;
  height: 56px;
  background: ${colorSet.gray9};
`;

const HorizonDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const ChartContainer = styled.div`
  height: 431px;
  padding: 16px 16px 24px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const TableContainer = styled.div`
  height: 294px;
  overflow: auto;
  ${StyledScroll}
`;

const TableTitle = styled(Typo)`
  padding: 7px 8px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 954px;
`;
