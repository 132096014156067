import Table, { renderNoRowsComponent } from "@/src/components/atom/Table";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAlert from "@/src/hooks/useAlert";
import { useLazyGetCombinedAndSplitHistoryOfContainersQuery } from "@/src/store/apis/shipments/shipmentDetail";
import { isUndefined } from "@/src/utils/is";
import { createViewAction } from "@/src/utils/row-data-util";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import SingleContainerPhotoDialog from "../../dialog/SingleContainerPhotoDialog";
import { columnCombinedSplitedHistory } from "../columnContainerTab";

enum DialogState {
  NULL,
  CONTAINER_PHOTO_DIALOG,
}

const CombinedSplitedHistoryCard = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefs] = useState<ColDef[]>(columnCombinedSplitedHistory);
  const [containerId, setContainerId] = useState<number>();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  const [getCombinedSplitedHistory, { isFetching, count, historyList }] =
    useLazyGetCombinedAndSplitHistoryOfContainersQuery({
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        return {
          isFetching,
          count: isUnstable ? 0 : currentData.count,
          historyList: isUnstable ? [] : currentData.rows,
        };
      },
    });

  const fetchCombinedSplitHistory = async () => {
    try {
      await getCombinedSplitedHistory({
        shipmentId: Number(params.id),
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const handleViewButtonClick = (data: any) => {
    setDialogState(DialogState.CONTAINER_PHOTO_DIALOG);
    setContainerId(data.id);
  };

  useEffect(() => {
    fetchCombinedSplitHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  const renderDialog = () => {
    if (dialogState === DialogState.CONTAINER_PHOTO_DIALOG && containerId) {
      return (
        <SingleContainerPhotoDialog
          containerId={containerId}
          onClose={() => {
            setContainerId(undefined);
            setDialogState(DialogState.NULL);
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      <SectionCard
        cardTitle={t("shipment:exporter.detail.label.combinedSplitedHistory")}
      >
        {!isFetching && historyList.length === 0 ? (
          <PlaceholderWrapper>{renderNoRowsComponent()}</PlaceholderWrapper>
        ) : (
          <Table
            ref={gridRef}
            rowData={isFetching ? undefined : historyList}
            columnDefs={columnDefs}
            totalPage={count}
            isPagination={false}
            height={252}
            rowClass={"ag-row-none-cursor"}
            components={{
              ...createViewAction(handleViewButtonClick),
            }}
          />
        )}

        {/* Dialog */}
        {renderDialog()}
      </SectionCard>
    </>
  );
};

export default CombinedSplitedHistoryCard;

const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 44px 0;
`;
