import { ReactComponent as NotFoundSvg } from "@/src/assets/icons/icon-not-found.svg";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

const PublicNotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <FlexColumnCenter style={{ height: "100vh" }}>
      <NotFoundIcon />
      <NotFoundTitle>{t("common:notFound")}</NotFoundTitle>
      <Typo color="gray2" typoType="b4m">
        <Typo color="gray2" typoType="b4m">
          {t("common:notFoundDescription")}
        </Typo>
      </Typo>

      <Flex>
        <Button
          style={{ width: "158px" }}
          onClick={() => window.history.back()}
        >
          {t("common:backToPrevious")}
        </Button>
      </Flex>
    </FlexColumnCenter>
  );
};

export default PublicNotFoundPage;

const FlexColumnCenter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: pre-wrap;
  text-align: center;
`;

const NotFoundIcon = styled(NotFoundSvg)`
  width: 283px;
  height: 172px;
`;

const NotFoundTitle = styled.h1`
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  font-family: "Pretendard Bold";
  line-height: normal;
  color: ${colorSet.gray2};
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 40px;
`;
