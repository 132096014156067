import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { forwardRef, HTMLAttributes, useState } from "react";
import styled from "styled-components";
import { InputError } from "../../molecule/FormItem";

interface BasicTextAreaProps extends HTMLAttributes<HTMLTextAreaElement> {
  errorMessage?: string;
  isReadOnly?: boolean;
}

const BasicTextArea = forwardRef<HTMLTextAreaElement, BasicTextAreaProps>(
  (props, ref) => {
    const { errorMessage, isReadOnly = false } = props;
    const [isFocus, setIsFocus] = useState<boolean>(false);

    return (
      <TextAreaContainer>
        <StyledTextAreaContainer
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          data-focus={isFocus}
          data-invalid={!!errorMessage}
        >
          <StyledTextArea ref={ref} readOnly={isReadOnly} {...props} />
        </StyledTextAreaContainer>
        {!!errorMessage && <InputError message={errorMessage} />}
      </TextAreaContainer>
    );
  },
);

export default BasicTextArea;

const StyledTextAreaContainer = styled.div<{ disabled?: boolean }>`
  cursor: text;
  transition: all 0.2s ease-in-out;
  border: ${({ disabled }) =>
    disabled ? `1px solid ${colorSet.gray8}` : `1px solid ${colorSet.gray9}`};
  border-radius: 8px;
  padding: 0 5px 5px 0;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background: ${({ disabled }) =>
    disabled ? colorSet.gray10 : colorSet.white};

  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled
        ? "none"
        : `0px 0px 0px 1px #1479eb, 0px 0px 0px 3px rgba(24, 123, 235, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25);`};
  }

  &[data-focus="true"] {
    border: 1px solid ${colorSet.blue1};
    box-shadow: none;
  }

  &[data-invalid="true"] {
    border: 1px solid ${colorSet.red2};
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  border: none;
  border-radius: 8px;
  width: 100%;
  min-height: 548px;
  padding: 10px 11px 0 16px;
  ${typo.b7r};

  &::placeholder {
    color: ${colorSet.gray7};
    ${typo.b7r}
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    border-radius: 8px;
    background: ${colorSet.gray10};
  }
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
