import { ReactComponent as DeleteSvg } from "@/src/assets/icons/icon-cancle-red.svg";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down-gray7.svg";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import ChevronRightSvg from "@/src/assets/icons/icon-chevron-right-white.svg";
import { ReactComponent as ClockSvg } from "@/src/assets/icons/icon-clock-gray5.svg";
import DotGray4Svg from "@/src/assets/icons/icon-dot-gray4.svg";
import DotGray7Svg from "@/src/assets/icons/icon-dot-gray7.svg";
import EditSvg from "@/src/assets/icons/icon-edit-white.svg";
import { ReactComponent as MailSvg } from "@/src/assets/icons/icon-mail-blue.svg";
import { Button } from "@/src/components/atom/Button";
import DropDown from "@/src/components/atom/DropDown";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import EmailHistoryDialog from "@/src/components/organism/EmailHistoryDialog";
import ShareableContractDialog from "@/src/components/organism/ShareableContractDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useLazyGetItemCodeListQuery } from "@/src/store/apis/corporate/companyItemManagement";
import { ExporterItemCodeListViewV2Dto } from "@/src/store/apis/corporate/companyItemManagement/interface";
import { useLazyGetEmailShareHistoriesQuery } from "@/src/store/apis/emailShareHistories";
import { EmailShareHistoryListViewDto } from "@/src/store/apis/emailShareHistories/interface";
import { useShareLoadingMutation } from "@/src/store/apis/sharing";
import { useLazyGetTasksInShipmentQuery } from "@/src/store/apis/shipments/shipmentRegister";
import { ShipmentTaskListViewDto } from "@/src/store/apis/shipments/shipmentRegister/interface";
import {
  useCancelTaskStatusMutation,
  useCompletedTaskStatusMutation,
  useGetContainerTaskChangeHistoryListQuery,
  useGetTaskDetailBookmarkQuery,
  useGetTaskDetailBookmarkUserQuery,
  useGetTaskDetailContainerListQuery,
  useGetTaskDetailQuery,
  useUpdateTaskDetailBookmarkMutation,
} from "@/src/store/apis/tasks/taskDetail";
import { TaskBookmarkListViewDto } from "@/src/store/apis/tasks/taskDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { CopiedBadge } from ".";
import BookingAndContractCard from "./components/detail/card/BookingAndContractCard";
import CombineSplitHistoryCard from "./components/detail/card/CombineSplitHistoryCard";
import ContainerTaskResultCard from "./components/detail/card/ContainerTaskResultCard";
import MemoCard from "./components/detail/card/MemoCard";
import SendTaskRequestCard from "./components/detail/card/SendTaskRequestCard";
import ShipmentList from "./components/detail/card/ShipmentList";
import TaskCard from "./components/detail/card/TaskCard";
import TaskCodeCard from "./components/detail/card/TaskCodeCard";
import TaskItemStatus from "./components/detail/card/TaskItemStatus";
import TransportationCard from "./components/detail/card/TransportationCard";
import CancelTaskAlertDialog from "./components/detail/dialog/CancelTaskAlertDialog";
import EmailDialog from "./components/detail/dialog/EmailDialog";
import TaskCompletedAlertDialog from "./components/detail/dialog/TaskCompletedAlertDialog";
import {
  renderContainerWeighCheckedStatus,
  renderLoadingStatusBadge,
} from "./utils/renderTaskStatusBadge";

enum DialogState {
  NULL,
  SEND_EMAIL,
  SENT_HISTORY,
  BOOK_MARK,
  SHARE,
}

enum AlertDialogState {
  CANCEL_TASK,
  COMPLETED_TASK,
  NULL,
}

const bookmarkEmptyArray: TaskBookmarkListViewDto[] = [];
const itemEmptyArray: ExporterItemCodeListViewV2Dto[] = [];
const emailEmptyArray: EmailShareHistoryListViewDto[] = [];
const shipmentEmptyArray: ShipmentTaskListViewDto[] = [];

function TaskDetailPage() {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const loadingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  // API
  const [getItemCodes, { itemList, isItemCodeUnstable }] =
    useLazyGetItemCodeListQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isItemCodeUnstable: isUnstable,
          itemList: !isUnstable
            ? (currentData?.rows ?? itemEmptyArray)
            : itemEmptyArray,
        };
      },
    });
  const { exporterUserType, userMainCategory } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        userId: currentData?.row.id,
        userMainCategory: isStable
          ? (currentData.row.mainCategoryCodeItemNames ?? [])
          : [],
        exporterUserType: currentData?.row.exporterUserType,
      };
    },
  });

  const {
    isLoadingError,
    isCopiedLoading,
    loadingStatus,
    loadingWeighingStatus,
    loadingNo,
    loadingCreatedAt,
    loadingUpdatedAt,
    loadingWriterName,
    loadingLastEditorName,
    buyerId,
    buyerNameCode,
    isCombinedOrSplited,
    isLoadingDetailFetching,
    isLoadingUnstable,
    isLoadingStable,
    exporterItemCode,
    sharedAt,
    refetch,
  } = useGetTaskDetailQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          isLoadingUnstable: isUnstable,
          isLoadingStable: isStable,
          isLoadingError: isError,
          isLoadingDetailFetching: isFetching,
          isCopiedLoading: isStable ? currentData.isCopiedTask : false,
          loadingWriterId: isStable ? currentData.taskWriterId : null,
          loadingStatus: isStable ? currentData?.taskStatus : null,
          loadingWeighingStatus: isStable
            ? currentData.taskWeighingStatus
            : null,
          loadingNo: isStable ? currentData.taskNo : "-",
          loadingCreatedAt:
            isStable && currentData.createdAt
              ? `(${dayjs(currentData.createdAt).format(
                  DATE_FORMAT_STRINGS.YY_MM_DD,
                )},${dayjs(currentData.createdAt).format(
                  DATE_FORMAT_STRINGS.HH_mm,
                )})`
              : "-",
          loadingUpdatedAt:
            isStable && currentData.updatedAt
              ? `(${dayjs(currentData.updatedAt).format(
                  DATE_FORMAT_STRINGS.YY_MM_DD,
                )},${dayjs(currentData.updatedAt).format(
                  DATE_FORMAT_STRINGS.HH_mm,
                )})`
              : "-",
          loadingWriterName: isStable
            ? (currentData.taskWriterName ?? "-")
            : "-",
          loadingLastEditorName: isStable
            ? (currentData?.taskLastEditorName ?? "-")
            : "-",
          buyerId: isStable ? currentData.buyerId : "",
          buyerNameCode: isStable ? currentData.buyerNameCode : undefined,
          isCombinedOrSplited: isStable
            ? currentData.isCombinedOrSplited
            : false,
          exporterItemCode: isStable ? currentData?.exporterItemCode : "",
          sharedAt: isStable ? currentData.sharedAt : undefined,
        };
      },
    },
  );
  useGetContainerTaskChangeHistoryListQuery(
    {
      taskId: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { isBookmarked } = useGetTaskDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return { isBookmarked: currentData?.isBookmarked };
      },
    },
  );
  const { bookmarkUserList } = useGetTaskDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          bookmarkUserList: isStable
            ? (currentData.rows ?? bookmarkEmptyArray)
            : bookmarkEmptyArray,
        };
      },
    },
  );
  const [getLoadingInShipment, { shipmentList }] =
    useLazyGetTasksInShipmentQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const isStable = !isUnstable;

        return {
          shipmentList: isStable
            ? (currentData ?? shipmentEmptyArray)
            : shipmentEmptyArray,
        };
      },
    });
  const [shareLoading] = useShareLoadingMutation();

  const isIncludeInShipment =
    shipmentList.map(({ id }) => id).includes(Number(params.id)) || false;

  useGetTaskDetailContainerListQuery(
    { id: Number(params.id) },
    { refetchOnMountOrArgChange: true },
  );
  const [editLoadingBookmark] = useUpdateTaskDetailBookmarkMutation();
  const [cancelLoadingStatus] = useCancelTaskStatusMutation();
  const [completedLoadingStatus] = useCompletedTaskStatusMutation();
  const [
    getLoadingSharedHistories,
    { count, isFetching, emailHistoryList, isEmailShareHistoriesError },
  ] = useLazyGetEmailShareHistoriesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;
      const isStable = !isUnstable;

      return {
        isFetching,
        count: isStable ? currentData.count : 0,
        emailHistoryList: isStable
          ? (currentData.rows ?? emailEmptyArray)
          : emailEmptyArray,
        isEmailShareHistoriesError: isError,
      };
    },
  });

  // State
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );

  const isEditAuth = !isLoadingError;

  const mainCategoryItemName = itemList?.find(
    (item) => item.itemCode === exporterItemCode,
  )?.mainCategoryCodeItemName;

  // 매니저,중간관리자 카테고리 비교
  const isMainCategoryAuthorized =
    exporterUserType === "MANAGER" || exporterUserType === "MIDDLE_MANAGER"
      ? userMainCategory.includes(mainCategoryItemName as string)
      : true;
  const isMainCategoryUnauthorized = !isMainCategoryAuthorized;

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };

      await editLoadingBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleLoadingStatusCancelClick = async () => {
    try {
      await cancelLoadingStatus({
        taskId: Number(params.id),
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("task:detail.alert.cancelLoadingAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleLoadingStatusCompletedClick = async () => {
    try {
      await completedLoadingStatus({ taskId: Number(params.id) });

      alert.showAlert({
        type: "success",
        message: t("alert:loadingCompletedSuccessful"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }

    if (dialogState === DialogState.SEND_EMAIL) {
      return (
        <EmailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.SENT_HISTORY) {
      return (
        <EmailHistoryDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isFetching={isFetching}
          isError={isEmailShareHistoriesError}
          emailHistoryList={emailHistoryList}
          totalCount={count}
          fetch={getLoadingSharedHistories}
          emailShareDomain={"TASK"}
          emailShareDomainId={Number(params.id)}
        />
      );
    }

    if (dialogState === DialogState.SHARE) {
      return (
        <ShareableContractDialog
          id={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
          onShare={async () => {
            try {
              setDialogState(DialogState.NULL);
              await shareLoading({
                taskId: Number(params.id),
              }).unwrap();

              alert.showAlert({
                type: "success",
                message: t("task:detail.alert.sharingAlert"),
              });
            } catch (e: any) {
              const message = Array.isArray(e.data?.message)
                ? e.data?.message[0]
                : e.data?.message;
              alert.showAlert({
                type: "error",
                message: message ?? t("alert:unHandled"),
              });
            } finally {
              refetch();
            }
          }}
          type="task"
        />
      );
    }
  };

  const renderAlertDialogs = () => {
    if (alertDialogState === AlertDialogState.CANCEL_TASK) {
      return (
        <CancelTaskAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            handleLoadingStatusCancelClick();
          }}
        />
      );
    }

    if (alertDialogState === AlertDialogState.COMPLETED_TASK) {
      return (
        <TaskCompletedAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            handleLoadingStatusCompletedClick();
          }}
        />
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    if (isLoadingUnstable || isItemCodeUnstable) {
      return;
    }

    if (isMainCategoryUnauthorized) {
      return (
        <NoDataContainer>
          <UnauthorizedDescription />
        </NoDataContainer>
      );
    }
  };

  const renderLoadingDetailContent = () => {
    if (
      (isLoadingUnstable && isItemCodeUnstable) ||
      isMainCategoryUnauthorized
    ) {
      return;
    }

    return (
      <>
        <FlexColumn>
          {/* Header */}
          <HeaderContainer>
            <TaskStatusContainer>
              {renderLoadingStatusBadge({
                type: loadingStatus,
                size: "L",
                t,
              })}
              {loadingStatus !== "CANCEL_TASK_REQUEST" &&
                renderContainerWeighCheckedStatus({
                  type: loadingWeighingStatus,
                  size: 24,
                  t: t,
                })}
            </TaskStatusContainer>

            <HeaderButtonContainer>
              {/* BookMark */}
              <BookMarkStatus
                bookMarkStatus={isBookmarked}
                bookMarkCount={bookmarkUserList.length}
                onClickBookMark={handleBookmarkClick}
                onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
              />

              <Button
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                onClick={() => setDialogState(DialogState.SHARE)}
                disabled={!!sharedAt}
              >
                {t("task:detail.button.shareButton")}
              </Button>

              {/* Email */}
              <DropDown
                dropdownContentProps={{ align: "end" }}
                dropdownTriggerProps={{ disabled: !isEditAuth }}
                dropdownTrigger={
                  <StyledButton
                    buttonSize={32}
                    buttonColor="blue"
                    buttonGrade="secondary"
                    disabled={!isEditAuth}
                  >
                    <MailIcon
                      size={16}
                      color="indigo"
                      data-disabled={!isEditAuth}
                    />
                    {t("task:detail.button.emailButton")}
                    <ArrowDownIcon data-disabled={!isEditAuth} />
                  </StyledButton>
                }
              >
                <StyledSectionCardWithoutHeader>
                  <EmailDropDownList>
                    <EmailDropDownItem
                      onSelect={() => setDialogState(DialogState.SEND_EMAIL)}
                    >
                      <MailIcon size={18} color="gray2" />
                      <Typo>{t("task:detail.button.sendEmailButton")}</Typo>
                    </EmailDropDownItem>

                    <EmailDropDownItem
                      onSelect={() => setDialogState(DialogState.SENT_HISTORY)}
                    >
                      <ClockIcon />
                      <Typo>{t("task:detail.button.sentHistoryButton")}</Typo>
                    </EmailDropDownItem>
                  </EmailDropDownList>
                </StyledSectionCardWithoutHeader>
              </DropDown>

              {/* Edit */}
              <StyledButton
                buttonSize={32}
                onClick={() => {
                  navigate(
                    `${EXPORTER_PRIVATE_PATH.LOADING_EDIT}/${params.id}`,
                  );
                }}
                disabled={
                  !isEditAuth ||
                  loadingStatus === "CANCEL_TASK_REQUEST" ||
                  loadingStatus === "TASK_COMPLETED"
                }
              >
                <Icon iconSrc={EditSvg} iconSize={16} />
                {t("task:detail.button.editButton")}
              </StyledButton>
            </HeaderButtonContainer>
          </HeaderContainer>

          {/* Card List */}
          <TaskCard />
          <TaskItemStatus />
          <BookingAndContractCard />
          <Grid>
            <TaskCodeCard />
            <SendTaskRequestCard />
            <TransportationCard />
            <MemoCard />
          </Grid>
          <ContainerTaskResultCard />
          <CombineSplitHistoryCard />
          <ShipmentList />

          {/* Last Editor */}
          <EditorContainer>
            <figure>
              <Icon iconSrc={DotGray7Svg} iconSize={4} />
              <Typo color="gray6" typoType="b9r">
                {`${t("task:detail.editor.registratant")} (${t(
                  "task:detail.editor.registratantAt",
                )}) ${loadingWriterName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {loadingCreatedAt}
              </Typo>
            </figure>
            <figure>
              <Icon iconSrc={DotGray4Svg} iconSize={4} />
              <Typo color="gray4" typoType="b9r">
                {`${t("task:detail.editor.lastEditor")} (${t(
                  "task:detail.editor.lastEditorAt",
                )}) ${loadingLastEditorName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {loadingUpdatedAt}
              </Typo>
            </figure>
          </EditorContainer>
        </FlexColumn>

        {/* Bottom Fixed */}
        <BottomFixedContainer>
          <BottomButtonContainer>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeft} iconSize={16} />
              {t("task:detail.button.backToPreviousButton")}
            </StyledButton>

            <figure>
              <StyledButton
                buttonColor="red"
                buttonGrade="secondary"
                onClick={() =>
                  setAlertDialogState(AlertDialogState.CANCEL_TASK)
                }
                disabled={
                  !isEditAuth ||
                  loadingStatus === "CANCEL_TASK_REQUEST" ||
                  loadingStatus === "TASK_COMPLETED"
                }
              >
                <DeleteIcon
                  disabled={
                    !isEditAuth ||
                    loadingStatus === "CANCEL_TASK_REQUEST" ||
                    loadingStatus === "TASK_COMPLETED"
                  }
                />
                {t("task:detail.button.cancelWorkRequestButton")}
              </StyledButton>

              <StyledButton
                buttonColor="blue"
                buttonGrade="secondary"
                onClick={() =>
                  setAlertDialogState(AlertDialogState.COMPLETED_TASK)
                }
                disabled={
                  !isEditAuth ||
                  loadingStatus === "CANCEL_TASK_REQUEST" ||
                  loadingStatus === "TASK_COMPLETED" ||
                  loadingWeighingStatus === "WEIGHING_CHECK_NEEDED"
                }
              >
                {t("task:detail.button.loadingCompletedButton")}
              </StyledButton>

              <StyledButton
                onClick={() =>
                  navigate(
                    `${EXPORTER_PRIVATE_PATH.SHIPMENT_ADD}?buyerId=${buyerId}&loadingId=${params.id}`,
                  )
                }
                disabled={
                  loadingStatus === "CANCEL_TASK_REQUEST" ||
                  !isIncludeInShipment
                }
              >
                {t("task:detail.button.createShipmentButton")}
                <Icon iconSrc={ChevronRightSvg} iconSize={16} />
              </StyledButton>
            </figure>
          </BottomButtonContainer>
        </BottomFixedContainer>
      </>
    );
  };

  useEffect(() => {
    (async () => {
      await getItemCodes({ itemCode: exporterItemCode });
    })();
  }, [exporterItemCode, getItemCodes]);

  useEffect(() => {
    (async () => {
      if (buyerNameCode) {
        await getLoadingInShipment({ buyerNameCode });
      }
    })();
  }, [buyerNameCode, getLoadingInShipment]);

  useEffect(() => {
    if (isLoadingDetailFetching) {
      const setTimeoutId = setTimeout(() => {
        handleContentLoadingOn();
      }, 300);

      loadingTimeoutId.current = setTimeoutId;
    } else {
      if (loadingTimeoutId.current) {
        clearTimeout(loadingTimeoutId.current);
      }
      handleContentLoadingOff();
    }
  }, [
    isLoadingDetailFetching,
    handleContentLoadingOff,
    handleContentLoadingOn,
  ]);

  return (
    <ExporterMainLayout
      breadcrumb={
        isLoadingStable && isMainCategoryAuthorized
          ? [t("sideNav:loading"), t("sideNav:loadingDetail")]
          : []
      }
      pageTitle={
        isLoadingStable &&
        isMainCategoryAuthorized && (
          <FlexCenter>
            {t("task:detail.header.title")} {loadingNo}{" "}
            {(isCopiedLoading || isCombinedOrSplited) && (
              <CopiedBadge size="s">C</CopiedBadge>
            )}
          </FlexCenter>
        )
      }
    >
      {/* Dialogs , AlertDialogs*/}
      {renderDialogs()}
      {renderAlertDialogs()}

      {/* Contents */}
      {renderLoadingDetailContent()}
      {renderUnauthorizedDescription()}
    </ExporterMainLayout>
  );
}

export default TaskDetailPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const TaskStatusContainer = styled(HeaderButtonContainer)``;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    display: flex;
    gap: 8px;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  figure {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

const DeleteIcon = styled(DeleteSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.red6};
      `}
  }
`;

const EmailDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;
const EmailDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const MailIcon = styled(MailSvg)<{ size: number; color: ColorType }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
const ArrowDownIcon = styled(ArrowDownSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.indigo};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
const ClockIcon = styled(ClockSvg)`
  width: 18px;
  height: 18px;

  path {
    fill: ${colorSet.gray2};
  }
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
