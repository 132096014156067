import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
} from "react";
import { styled } from "styled-components";

interface SectionCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  cardTitle?: ReactNode;
  rightAccessory?: ReactNode;
  cardContentContainerStyle?: CSSProperties;
}

const SectionCard = ({
  cardTitle,
  rightAccessory,
  cardContentContainerStyle,
  children,
  ...props
}: SectionCardProps) => {
  const isCardTitleString = typeof cardTitle === "string";

  return (
    <SectionCardArticle {...props}>
      <CardHeader>
        {isCardTitleString ? <CardTitle>{cardTitle}</CardTitle> : cardTitle}

        {rightAccessory ? (
          <RightAccessoryContainer>{rightAccessory}</RightAccessoryContainer>
        ) : null}
      </CardHeader>

      {children && (
        <CardContentContainer style={cardContentContainerStyle}>
          {children}
        </CardContentContainer>
      )}
    </SectionCardArticle>
  );
};

export default SectionCard;

const SectionCardArticle = styled.article`
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #0000000d;
`;

const CardTitle = styled.h2`
  ${typo.h6}
`;

const RightAccessoryContainer = styled.div`
  margin-left: auto;
`;

const CardHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 48px;
  background: ${colorSet.gray11};
  border-radius: 8px;

  ${CardTitle} {
    flex: 1;
  }
`;

const CardContentContainer = styled.div`
  padding: 24px 16px;
`;
