import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-white.svg";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import AddMemoDialog from "@/src/components/organism/AddMemoDialog";
import useAlert from "@/src/hooks/useAlert";
import { salesApi, useCreateSalesMemoMutation } from "@/src/store/apis/sales";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface SalesDetailMemoProps {
  isEditAuth: boolean;
}

enum DialogState {
  NULL,
  ADD_MEMO,
}

function SalesDetailMemo({ isEditAuth }: SalesDetailMemoProps) {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  // API
  const [createMemo] = useCreateSalesMemoMutation();
  const { memoList } = salesApi.endpoints.getSale.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return { memoList: isStable ? currentData?.salesMemos : [] };
      },
    },
  );

  const handleCreateMemoClick = async (memo: string) => {
    try {
      await createMemo({ id: Number(params.id), memo }).unwrap();
      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.ADD_MEMO) {
      return (
        <AddMemoDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleCreateMemoClick}
          isLoading={false}
        />
      );
    }
  };

  return (
    <StyledSectionCard
      cardTitle={t("sales:detail.memo")}
      rightAccessory={
        <StyledButton
          buttonSize={32}
          buttonColor="black"
          buttonGrade="tertiary"
          onClick={() => setDialogState(DialogState.ADD_MEMO)}
          disabled={!isEditAuth}
        >
          <AddIcon data-disabled={!isEditAuth} />
          {t("sales:detail.button.add")}
        </StyledButton>
      }
    >
      <Flex flexDirection="column" gap={24}>
        <Flex alignItems="center" gap={6}>
          <StyledIcon iconSrc={InfoSvg} />
          <Typo typoType="b9r" color="gray6">
            {t("sales:detail.memoInfo")}
          </Typo>
        </Flex>

        <MemoContent>
          {memoList?.length >= 1 ? (
            memoList?.map(({ createdAt, exporterUser, memo }, idx) => {
              return (
                <Flex flexDirection="column" gap={8} key={idx.toString()}>
                  <Flex alignItems="center" gap={4} flex={1} isFullWidth>
                    <Typo color="gray3" typoType="h9">
                      {exporterUser.name}
                    </Typo>
                    <Typo color="gray7" typoType="b9r">
                      {`(${dayjs(createdAt).format("YY-MM-DD")}, ${dayjs(
                        createdAt,
                      ).format("HH:mm")})`}
                    </Typo>
                  </Flex>

                  <ReadOnlyText typoType="b7r" color="gray6">
                    {memo}
                  </ReadOnlyText>
                </Flex>
              );
            })
          ) : (
            <MemoNoDataContainer>{renderNoRowsComponent()}</MemoNoDataContainer>
          )}
        </MemoContent>
      </Flex>

      {renderDialog()}
    </StyledSectionCard>
  );
}

export default SalesDetailMemo;

const StyledSectionCard = styled(SectionCard)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 402px;
  ${StyledScroll}
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.gray2};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
