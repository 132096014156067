import useAlert from "@/src/hooks/useAlert";
import { useLazyGetShipmentDetailContractBookingQuery } from "@/src/store/apis/shipments/shipmentDetail";
import { ShipmentDetailContractBookingListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { useLazyGetShipmentShareContractWithBookingQuery } from "@/src/store/apis/shipments/shipmentShare";
import { ShipmentDetailContractBookingShareInfosDto } from "@/src/store/apis/shipments/shipmentShare/interface";
import { isUndefined } from "@/src/utils/is";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import SharedBookingContractCardItem from "./SharedBookingContractCardItem";

interface ShipmentWithContractProps {
  shipmentShareKey: string;
  sharedShipmentId: number;
}

const previewEmptyArray: ShipmentDetailContractBookingListViewDto[] = [];
const contractWithBookingEmptyArray: ShipmentDetailContractBookingShareInfosDto[] =
  [];

function ShipmentWithContract({
  shipmentShareKey,
  sharedShipmentId,
}: ShipmentWithContractProps) {
  const alert = useAlert();
  const [searchParams] = useSearchParams();

  const isPreview = searchParams.get("preview") === "true";
  const previewShipmentId = Number(searchParams.get("shipmentId"));

  // Preview API
  const [
    getContractWithBookingPreview,
    { contractWithBookingPreviewList, isPreviewError },
  ] = useLazyGetShipmentDetailContractBookingQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isPreviewError: isError,
        contractWithBookingPreviewList: !isUnstable
          ? (currentData.rows ?? previewEmptyArray)
          : previewEmptyArray,
      };
    },
  });

  // API
  const [
    getSharedContractWithBookingList,
    { contractWithBookingList, isError },
  ] = useLazyGetShipmentShareContractWithBookingQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isError,
        contractWithBookingList: !isUnstable
          ? (currentData.rows ?? contractWithBookingEmptyArray)
          : contractWithBookingEmptyArray,
      };
    },
  });

  // Preview Mount
  const getContractWithBookingListPreview = useCallback(async () => {
    try {
      await getContractWithBookingPreview({
        id: previewShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  }, [alert, getContractWithBookingPreview, previewShipmentId]);

  // Mount
  const mountWhenFetch = useCallback(async () => {
    try {
      await getSharedContractWithBookingList({
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  }, [
    alert,
    getSharedContractWithBookingList,
    sharedShipmentId,
    shipmentShareKey,
  ]);

  useEffect(() => {
    isPreview ? getContractWithBookingListPreview() : mountWhenFetch();
  }, [getContractWithBookingListPreview, isPreview, mountWhenFetch]);

  const renderContractList = () => {
    let list:
      | ShipmentDetailContractBookingListViewDto[]
      | ShipmentDetailContractBookingShareInfosDto[];

    if (isPreview) {
      list = contractWithBookingPreviewList;

      return list.map((data, rowIdx) => {
        return (
          <SharedBookingContractCardItem
            previewData={data}
            index={rowIdx}
            key={rowIdx.toString()}
            isError={isPreview ? isPreviewError : isError}
          />
        );
      });
    } else {
      list = contractWithBookingList;

      return list.map((data, rowIdx) => {
        return (
          <SharedBookingContractCardItem
            data={data}
            index={rowIdx}
            key={rowIdx.toString()}
            isError={isPreview ? isPreviewError : isError}
          />
        );
      });
    }
  };

  return (
    <BookingContractCardContainer>
      {renderContractList()}
    </BookingContractCardContainer>
  );
}

export default ShipmentWithContract;

const BookingContractCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
