import Dialog from "@/src/components/atom/Dialog";
import Table from "@/src/components/atom/Table";
import Flex from "@/src/components/molecule/Flex";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useLazyGetSalesWithWarehousingsDetailQuery } from "@/src/store/apis/sales";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { columnSalesContainerList } from "../../../columns/columnStoreList";

interface SalesContainerListDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  warehousingId: number;
  salesContractId: number;
}

const BL_NO_FIELD = "blNo";
const PO_NO_FIELD = "poNo";

function SalesContainerListDialog({
  open,
  onOpenChange,
  warehousingId,
  salesContractId,
}: SalesContainerListDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(columnSalesContainerList);

  const [getSalesDetailList, { isFetching, list, isError, count }] =
    useLazyGetSalesWithWarehousingsDetailQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          isError,
          isFetching,
          count: isStable ? currentData.count : 0,
          list: isStable ? currentData.rows : [],
        };
      },
    });

  useAgGridHeaderRefresh({
    gridRef: gridRef?.current,
    isReady,
    headerSet: [
      {
        columnKey: "containerNo",
        langKey: "sales:common.containerNo",
      },
      {
        columnKey: "sealNo",
        langKey: "sales:common.sealNo",
      },
      {
        columnKey: "unitQuantity",
        langKey: "sales:common.unitQuantity",
      },
      {
        columnKey: "netWeight",
        langKey: "sales:common.netWeight",
      },
      {
        columnKey: "unitPrice",
        langKey: "sales:common.unitPrice",
      },
      {
        columnKey: "taskNo",
        langKey: "sales:common.taskNo",
      },
      {
        columnKey: "blNo",
        langKey: "sales:common.blNo",
      },
      {
        columnKey: "poNo",
        langKey: "sales:common.poNo",
      },
    ],
  });

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;

      if (!getSalesDetailList) {
        return;
      }

      try {
        await getSalesDetailList({
          page,
          pageSize,
          id: salesContractId,
          warehousingId,
        }).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getSalesDetailList,
      pagination.page,
      pagination.pageSize,
      salesContractId,
      warehousingId,
    ],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  // Mount
  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t("contract:importer.detail.dialogTitle.receiptList")}
      open={open}
      onOpenChange={onOpenChange}
      width={1000}
    >
      <Flex flexDirection="column" gap={16}>
        <Table
          ref={gridRef}
          rowData={isError ? [] : list}
          columnDefs={columnDefs}
          totalPage={count}
          pageSize={pagination.pageSize}
          page={pagination.page}
          isPaginationDataMaping
          handlePaginationClick={handlePaginationClick}
          onGridReady={() => {
            setIsReady(true);
          }}
          onCellClicked={(e) => {
            const selectShipmentRow = e.node.data;
            const isBlNoColumn = e.column.getColDef().field === BL_NO_FIELD;
            const isPoNoColumn = e.column.getColDef().field === PO_NO_FIELD;

            if (isBlNoColumn && selectShipmentRow.blNo) {
              navigate(
                `${IMPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${selectShipmentRow.bookingId}`,
              );
            }

            if (isPoNoColumn && selectShipmentRow.poNo) {
              navigate(
                `${IMPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${selectShipmentRow.contractId}`,
              );
            }
          }}
        />
      </Flex>
    </Dialog>
  );
}

export default SalesContainerListDialog;
