import { useParams } from "react-router-dom";
import ContractEditPage from "./components/edit/ContractEditPage";
import ContractEditBasedOnPoPage from "./components/edit/ContractEditBasedOnPo";
import { useGetContractQuery } from "@/src/store/apis/contracts";
import { useGetExporterContractSignatureHistoriesQuery } from "@/src/store/apis/contracts/contractDetail";

const ExporterContractEditV2Page = () => {
  const params = useParams();

  // API
  const { signatureStatus, contractStatus } = useGetContractQuery(
    {
      id: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isFetching, currentData }) => {
        return {
          isContractFetching: isFetching,
          signatureStatus: currentData?.row.signatureStatus,
          contractStatus: currentData?.row.contractStatus,
        };
      },
    },
  );
  const {
    isImporterSignedSC,
    isExporterSignedSC,
    isExporterSignedPO,
    isImporterSignedPO,
  } = useGetExporterContractSignatureHistoriesQuery(
    {
      id: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching }) => {
        return {
          isSignatureFetching: isFetching,
          isImporterSignedSC: currentData?.rows[0]?.isImporterSignedSC,
          isExporterSignedSC: currentData?.rows[0]?.isExporterSignedSC,
          isExporterSignedPO: currentData?.rows[0]?.isExporterSignedPO,
          isImporterSignedPO: currentData?.rows[0]?.isImporterSignedPO,
          isAllSignedBefore: currentData?.rows.some(
            (sign) =>
              sign.isExporterSignedPO &&
              sign.isExporterSignedSC &&
              sign.isImporterSignedPO &&
              sign.isImporterSignedSC,
          ),
        };
      },
    },
  );

  /**
   * 기본 수정폼(전체수정 폼)
   * - 거래처와 미연동일 경우
   * - 서명이 모두 연결이 되어있는 상태에서 수정(버전업)
   * - SC처음부터 반려되었을경우(서명모두 false)
   */
  const isSCBasicForm =
    contractStatus === "NOT_LINKED" ||
    signatureStatus === "LINKED" ||
    (signatureStatus === "PENDING" &&
      !isImporterSignedSC &&
      !isExporterSignedSC &&
      !isExporterSignedPO &&
      !isImporterSignedPO);

  return (
    <div>
      {isSCBasicForm ? (
        <ContractEditPage />
      ) : (
        <ContractEditBasedOnPoPage id={Number(params.id)} />
      )}
    </div>
  );
};

export default ExporterContractEditV2Page;
