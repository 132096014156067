import RadixTooltip from "@/src/components/atom/Tooltip/RadixTooltip";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import colorSet from "@/src/styles/color";
import zIndex from "@/src/styles/zIndex";
import { PropsWithChildren, useState } from "react";
import styled from "styled-components";

interface ShipmentItemInfoTooltipProps {
  itemList: string[];
}

function ShipmentItemInfoTooltip({
  children,
  itemList,
}: PropsWithChildren<ShipmentItemInfoTooltipProps>) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <RadixTooltip
      open={isTooltipOpen}
      onOpenChange={setIsTooltipOpen}
      contentProps={{
        side: "right",
      }}
      arrowProps={{ style: { opacity: 0.8 } }}
      arrowColor="gray1"
      content={
        <StyledTooltip>
          <Flex flexDirection="column" alignItems="flex-start" gap={4}>
            {itemList.map((text, idx) => {
              return (
                <Typo typoType="b10r" color="white" key={idx.toString()}>
                  {text}
                </Typo>
              );
            })}
          </Flex>
        </StyledTooltip>
      }
    >
      {children}
    </RadixTooltip>
  );
}

export default ShipmentItemInfoTooltip;

const StyledTooltip = styled.div`
  background: ${colorSet.gray1};
  white-space: pre-wrap;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0.8;
  text-align: center;
  z-index: ${zIndex.alert};
`;
