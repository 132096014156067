import ArrowRightSvg from "@/src/assets/icons/icon-arrow-right.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import useAlert from "@/src/hooks/useAlert";
import {
  subscriptionApi,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import {
  AnnualFeeDiscountType,
  PaymentCycleType,
  PlanType,
} from "@/src/store/apis/subscription/interface";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { transformAnnualFeeString } from "../../utils/getPayments";
import { paymentCycleStrings } from "../../utils/transformStatusStrings";

interface ChangePlanInfoDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CONFIRM,
}

function ChangePlanInfoDialog({
  open,
  onOpenChange,
}: ChangePlanInfoDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();

  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );

  // API
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const { plans } = subscriptionApi.endpoints.getPlans.useQueryState(
    {
      planType: "NORMAL",
    },
    {
      selectFromResult: ({ currentData }) => {
        return { plans: currentData?.rows };
      },
    },
  );
  const { subscriptionId } =
    subscriptionApi.endpoints.getSubscriptions.useQueryState(
      {
        page: 1,
        pageSize: 10,
      },
      {
        selectFromResult: ({ currentData }) => {
          return {
            subscription: currentData?.rows?.[0],
            subscriptionId: currentData?.rows?.[0].id,
          };
        },
      },
    );
  const {
    paymentCycle,
    annualFee,
    monthlyFee,
    currentPlanVersion,
    nextPlanId,
    nextPlanPaymentCycle,
    annualFeeDiscount,
    annualFeeDiscountType,
    planType,
    nextPlanVersion,
    subscriptionType,
  } = subscriptionApi.endpoints.getSubscription.useQueryState(
    {
      id: subscriptionId as number,
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          subscriptionType: currentData?.subscriptionType,
          planType: currentData?.plan.planType,
          planName: isStable ? currentData?.plan.name : "-",
          paymentCycle: currentData?.paymentCycle,
          annualFee: currentData?.planVersion.annualFee ?? "",
          monthlyFee: currentData?.planVersion.monthlyFee ?? "",
          numberOfMember: currentData?.planVersion.numberOfMember,
          subscriptionEndAtTo: currentData?.subscriptionEndAtTo,
          paymentList: isStable ? currentData.paymentList : [],
          currentPlanVersion: currentData?.planVersion,
          nextPlanId: currentData?.nextPlan?.id,
          nextPlanPaymentCycle: currentData?.nextPlanPaymentCycle,
          currencyUnit: currentData?.planVersion.currencyUnit,
          annualFeeDiscount: currentData?.planVersion.annualFeeDiscount ?? "",
          numberOfLoadingCode: currentData?.planVersion.numberOfLoadingCode,
          annualFeeDiscountType: currentData?.planVersion.annualFeeDiscountType,
          nextPlanVersion: currentData?.nextPlanVersion,
        };
      },
    },
  );

  const nextPlan = plans?.find((item) => item.id === nextPlanId);

  const handlePlanChangeCancelClick = () => {
    setAlertDialogState(AlertDialogState.CONFIRM);
  };

  const handlePlanChangeConfirmClick = async () => {
    if (!subscriptionId) {
      return;
    }

    try {
      await updateSubscription({
        id: subscriptionId,
        nextPlanId: null,
        nextPlanPaymentCycle: null,
      }).unwrap();

      onOpenChange(false);
      setAlertDialogState(AlertDialogState.NULL);

      alert.showAlert({
        type: "success",
        message: t(
          "subscriptionManagement:subscription.alert.planChangeCancelled",
        ),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  const renderCurrentPrice = () => {
    // 무료 기간
    if (subscriptionType === "FREE") {
      return "-";
    }
    // 연간 결제
    if (paymentCycle === "ANNUALLY") {
      return transformAnnualFeeString({
        annualFee,
        annualFeeDiscount,
        planType: planType as PlanType,
        annualFeeDiscountType: annualFeeDiscountType as AnnualFeeDiscountType,
      });
    }

    // 월간 결제
    if (paymentCycle === "MONTHLY") {
      return `USD ${Number(monthlyFee).toLocaleString("ko-KR")}`;
    }
  };

  const renderNextPrice = () => {
    if (nextPlanVersion && nextPlanPaymentCycle && nextPlan) {
      if (nextPlanPaymentCycle === "ANNUALLY") {
        // 연간 결제
        return transformAnnualFeeString({
          annualFee: nextPlanVersion?.annualFee as string,
          annualFeeDiscount: nextPlanVersion?.annualFeeDiscount as string,
          planType: nextPlan.planType as PlanType,
          annualFeeDiscountType:
            nextPlanVersion?.annualFeeDiscountType as AnnualFeeDiscountType,
        });
      }
      // 월간 결제
      if (nextPlanPaymentCycle === "MONTHLY") {
        return `USD ${Number(nextPlanVersion.monthlyFee).toLocaleString(
          "ko-KR",
        )}`;
      }
    }

    if (paymentCycle === "ANNUALLY") {
      // 연간 결제
      return transformAnnualFeeString({
        annualFee,
        annualFeeDiscount,
        planType: planType as PlanType,
        annualFeeDiscountType: annualFeeDiscountType as AnnualFeeDiscountType,
      });
    }
    // 월간 결제
    if (paymentCycle === "MONTHLY") {
      return `${Number(monthlyFee).toLocaleString("ko-KR")}`;
    }
  };

  const getChangePlanData = () => {
    return {
      planName: nextPlan?.name ?? "-",
      dataArray: [
        {
          label: t("subscriptionManagement:subscription.paymentCycle"),
          currentValue: `${paymentCycleStrings({
            type: paymentCycle as PaymentCycleType,
            t,
          })}`,
          nextValue: `${paymentCycleStrings({
            type: nextPlanPaymentCycle as PaymentCycleType,
            t,
          })}`,
        },
        {
          label: t("subscriptionManagement:subscription.subscriptionPrice"),
          currentValue: `${renderCurrentPrice()}`,
          nextValue: `USD ${renderNextPrice()}`,
        },
        {
          label: t("subscriptionManagement:subscription.loadingCredit"),
          currentValue: t("common:loadingCodeCount", {
            loadingCodeCount: currentPlanVersion?.numberOfLoadingCode,
          }),
          nextValue: t("common:loadingCodeCount", {
            loadingCodeCount:
              nextPlan?.planVersionList?.[0].numberOfLoadingCode,
          }),
        },
        {
          label: t("subscriptionManagement:subscription.numberOfUsers"),
          currentValue: t("common:maxMembers", {
            memberCount: currentPlanVersion?.numberOfMember,
          }),
          nextValue: t("common:maxMembers", {
            memberCount: currentPlanVersion?.numberOfMember,
          }),
        },
      ],
    };
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CONFIRM) {
      return (
        <AlertDialog
          title={t("subscriptionManagement:subscription.changePlanCancel")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          okText={t("subscriptionManagement:subscription.button.ok")}
          cancelText={t("subscriptionManagement:subscription.button.cancel")}
          onOk={handlePlanChangeConfirmClick}
        >
          {t(
            "subscriptionManagement:subscription.areYouSureYouWantToCancelThePlanChange",
          )}
        </AlertDialog>
      );
    }
  };

  return (
    <Dialog
      title={t("subscriptionManagement:subscription.changePlanInformation")}
      open={open}
      onOpenChange={onOpenChange}
      width={640}
    >
      <FlexColumn gap={24}>
        <SubTitle typoType="h2">
          {t(
            "subscriptionManagement:subscription.pleaseCheckTheChangePlanInformation",
          )}
        </SubTitle>

        <CallOut backgroundColor="white" borderColor="gray9">
          <FlexColumn gap={16}>
            <FlexColumn gap={8}>
              <Typo>
                {t("subscriptionManagement:subscription.upcomingPlan")}
              </Typo>
              <Typo typoType="d6">{getChangePlanData().planName}</Typo>
            </FlexColumn>
            <Divider />

            <GridContainer>
              {getChangePlanData().dataArray.map(
                ({ label, currentValue, nextValue }, idx) => {
                  return (
                    <FlexColumn gap={8} key={idx.toString()}>
                      <Typo typoType="h9">{label}</Typo>
                      <GridThreeColumnContainer>
                        <Typo typoType="b9r">{currentValue}</Typo>
                        <Icon iconSrc={ArrowRightSvg} iconSize={16} />
                        <Typo typoType="b9r">{nextValue}</Typo>
                      </GridThreeColumnContainer>
                    </FlexColumn>
                  );
                },
              )}
            </GridContainer>
          </FlexColumn>
        </CallOut>

        <StyledButton
          buttonGrade="secondary"
          onClick={handlePlanChangeCancelClick}
        >
          {t("subscriptionManagement:subscription.button.changePlanCancel")}
        </StyledButton>
      </FlexColumn>

      {renderAlertDialog()}
    </Dialog>
  );
}

export default ChangePlanInfoDialog;

const FlexColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

const SubTitle = styled(Typo)`
  text-align: center;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledButton = styled(Button)`
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const GridThreeColumnContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(80px, 80px) 16px minmax(80px, 80px);
  gap: 16px;
`;
