import { Button } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import Flex from "@/src/components/molecule/Flex";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import useAlert from "@/src/hooks/useAlert";
import useCompanyHomeRedirect from "@/src/hooks/useCompanyHomeRedirect";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCreateSubscriptionsMutation,
  useGetCancelFeeQuery,
  useGetPaymentMethodsQuery,
  useLazyGetCancelFeeQuery,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import {
  ModifySubscriptionDto,
  SubscriptionStatusType,
  SubscriptionType,
} from "@/src/store/apis/subscription/interface";
import { changeAnnuallySubscription } from "@/src/store/slice/subscription";
import { isNull, isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PlanChangeConfirmAlertDialog from "../SubscriptionManagement/components/dialog/PlanChangeConfirmAlertDialog";
import { paymentCycleStrings } from "../SubscriptionManagement/utils/transformStatusStrings";

enum AlertDialogState {
  NULL,
  CHANGE_CONFIRM,
}

function SubscriptionAnnuallyTerminationPage() {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subscriptionData = useAppSelector(
    (state) => state.subscription.modifyAnnuallySubscription,
  );

  const [isConfirm, setIsConfirm] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );

  // API
  const { currentData: payments } = useGetPaymentMethodsQuery({
    page: 1,
    pageSize: 2,
  });
  const [createSubscriptions, { isLoading: isCreateLoading }] =
    useCreateSubscriptionsMutation();
  const [updateSubscription, { isLoading: isUpdateLoading }] =
    useUpdateSubscriptionMutation();
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { usedDays } = useGetCancelFeeQuery(
    {
      id: subscriptionData?.id as number,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          usedDays: isStable ? currentData?.row.usedDays : 0,
        };
      },
    },
  );

  const [getCancelFee, { isFetching, cancelFee, cancelCurrencyUnit }] =
    useLazyGetCancelFeeQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFetching,
          cancelFee: isStable ? currentData?.row.cancellationFee : "-",
          cancelCurrencyUnit: isStable ? currentData?.row.currencyUnit : "-",
        };
      },
    });

  const {
    func: { redirectNonCorporate },
  } = useCompanyHomeRedirect({
    companyType:
      session.currentData?.row.exporterUserType === "CORPORATE_MANAGER"
        ? session.currentData?.row.exporter.companyType
        : session.currentData?.row.exporterUserMainFieldType,
    userType: session.currentData?.row.exporterUserType,
  });

  const payment = payments?.rows.find((payment) => payment.isRepresentative);

  const isCardCancelType = usedDays < 180;

  const getConfirmButtonDisabled = () => {
    if (isCardCancelType) {
      return !isConfirm && !!errors;
    }

    return !isConfirm;
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    refundAccountName: string;
    refundBankName: string;
    refundAccountNo: string;
    estimatedRefundDateAt?: string;
    refundBankAddress?: string;
    swiftCode?: string;
    memo?: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      refundAccountName: "",
      refundBankName: "",
      refundAccountNo: "",
      estimatedRefundDateAt: undefined,
      refundBankAddress: undefined,
      swiftCode: undefined,
      memo: undefined,
    },
  });

  const handleCancelClick = () => {
    navigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT);
  };

  /**
   * @description
   * 연간 결제 변경시
   * - 플랜 즉시해지 후 등록
   */
  const handleSubscribeChangeConfirmClick = async () => {
    if (!subscriptionData) {
      return;
    }

    /**
     * @description 구독 즉시 해지
     * - 카드취소 (180일 이전)
     * - 국내계좌 (180일 이후)
     * - 해외계좌 (180일 이후)
     */
    const immediateData = isCardCancelType
      ? {
          // 카드취소
          subscriptionEndAtTo: dayjs().toISOString(),
          isImmediateCancel: true,
          subscriptionStatus: "CANCELED" as SubscriptionStatusType,
        }
      : {
          // 계좌정보 (국내, 해외)
          subscriptionEndAtTo: dayjs().toISOString(),
          isImmediateCancel: true,
          subscriptionStatus: "CANCELED" as SubscriptionStatusType,
          refundAccountType:
            payments?.rows[0].paymentCurrencyUnit === "KRW" ? "KOREA" : "OTHER",
          refundAccountName: getValues("refundAccountName") || undefined,
          refundBankName: getValues("refundBankName") || undefined,
          refundAccountNo: getValues("refundAccountNo") || undefined,
          estimatedRefundDateAt:
            getValues("estimatedRefundDateAt") || undefined,
          refundBankAddress: getValues("refundBankAddress") || undefined,
          swiftCode: getValues("swiftCode") || undefined,
          memo: getValues("memo") || undefined,
        };

    try {
      const res = await updateSubscription({
        id: subscriptionData.id,
        ...(immediateData as ModifySubscriptionDto),
      }).unwrap();

      if (res) {
        await createSubscriptions({
          subscriptionStatus: "PROGRESS" as SubscriptionStatusType,
          subscriptionType: "STANDARD" as SubscriptionType,
          paymentCycle: subscriptionData.nextPaymentCycle,
          exporterId: session.currentData?.row.exporter.id as number,
          planId: subscriptionData.nextPlanId,
          planVersionId: subscriptionData.planVersionId,
          paymentMethodId: payment?.id,
        }).unwrap();

        navigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT);
        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleChangeButtonClick = () => {
    setAlertDialogState(AlertDialogState.CHANGE_CONFIRM);
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CHANGE_CONFIRM) {
      return (
        <PlanChangeConfirmAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={handleSubscribeChangeConfirmClick}
          currentPlanName={subscriptionData?.planName ?? "-"}
          currentPaymentCycle={
            subscriptionData?.paymentCycle === "ANNUALLY"
              ? t("normalPlanSignup:periodYearly")
              : t("normalPlanSignup:periodMonthly")
          }
          nextPlanName={subscriptionData?.nextPlanName ?? "-"}
          nextPaymentCycle={
            subscriptionData?.nextPaymentCycle === "ANNUALLY"
              ? t("normalPlanSignup:periodYearly")
              : t("normalPlanSignup:periodMonthly")
          }
          isOkDisabled={isCreateLoading || isUpdateLoading}
          isOkLoading={isCreateLoading || isUpdateLoading}
        />
      );
    }
  };

  const renderCancelFee = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (subscriptionData?.isFreePlan) {
      return "-";
    }

    return (
      <Flex flexDirection="column">
        <Typo typoType="b7r" color="gray5">
          {Number(cancelFee).toLocaleString("ko-KR")} {cancelCurrencyUnit}
        </Typo>
        <Typo typoType="b11r" color="gray5">
          {t("subscriptionManagement:penalty10Percent")}
        </Typo>
      </Flex>
    );
  };

  const renderContent = () => {
    if (isNull(subscriptionData)) {
      return <></>;
    }

    return (
      <SignUpLayout
        onLogoClick={() => {
          navigate(EXPORTER_PRIVATE_PATH.HOME);
        }}
      >
        <StyledMain>
          <Title typoType="d3">
            {t("subscriptionAnnuallyTermination:title")}
          </Title>
          <StepsContainer>
            <StyledSectionCard
              cardTitle={t("subscriptionAnnuallyTermination:title")}
              cardContentContainerStyle={{ padding: "0px" }}
            >
              <Container flexDirection="column" gap={32}>
                <Flex flexDirection="column" gap={12} alignItems="center">
                  <Typo typoType="d6">
                    {t(
                      "subscriptionAnnuallyTermination:noticeOfAnnualSubscriptionChange",
                    )}
                  </Typo>
                  <WhiteSpaceTypo typoType="b7m">
                    {t("subscriptionAnnuallyTermination:description")}
                  </WhiteSpaceTypo>
                </Flex>

                <Flex flexDirection="column" gap={24} alignItems="center">
                  <CallOut>
                    <Flex flexDirection="column" gap={16}>
                      <SectionCardRow
                        label={t("subscriptionAnnuallyTermination:changePlan")}
                        value={`${
                          subscriptionData?.nextPlanName
                        } / ${paymentCycleStrings({
                          type: subscriptionData?.nextPaymentCycle,
                          t,
                        })}`}
                      />
                      <SectionCardRow
                        label={t(
                          "subscriptionAnnuallyTermination:expectedRefundAmount",
                        )}
                        value={renderCancelFee()}
                      />
                    </Flex>
                  </CallOut>
                  {renderRefundAccountType()}

                  <CheckboxContainer>
                    <Checkbox
                      checked={isConfirm}
                      onChange={() => setIsConfirm((prev) => !prev)}
                    />
                    <Typo typoType="b7r">
                      {t("subscriptionAnnuallyTermination:confirmDescription")}
                    </Typo>
                  </CheckboxContainer>

                  <ButtonContainer>
                    <Button
                      buttonGrade="tertiary"
                      buttonColor="black"
                      onClick={handleCancelClick}
                    >
                      {t("subscriptionAnnuallyTermination:button.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      disabled={getConfirmButtonDisabled()}
                      onClick={
                        isCardCancelType
                          ? handleSubmit(handleChangeButtonClick)
                          : handleChangeButtonClick
                      }
                    >
                      {t("subscriptionAnnuallyTermination:button.change")}
                    </Button>
                  </ButtonContainer>
                </Flex>
              </Container>
            </StyledSectionCard>
          </StepsContainer>
        </StyledMain>

        {renderAlertDialog()}
      </SignUpLayout>
    );
  };

  const renderRefundAccountType = () => {
    if (isCardCancelType) {
      return null;
    }

    const isKoreaType = payment?.paymentCurrencyUnit === "KRW";
    const isOtherType = payment?.paymentCurrencyUnit === "USD";

    // 국내계좌 환불
    if (isKoreaType) {
      return (
        <Flex flexDirection="column" gap={16} isFullWidth>
          <FormItem
            label={t("subscriptionManagement:subscription.accountName")}
            name="refundAccountName"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.accountNamePlaceholder",
              ),
            }}
            rules={{
              required: isKoreaType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <Flex gap={16}>
            <StyledFormItem
              label={t("subscriptionManagement:subscription.bankName")}
              name="refundBankName"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.bankNamePlaceholder",
                ),
              }}
              rules={{
                required: isKoreaType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountNo")}
              name="refundAccountNo"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNoPlaceholder",
                ),
              }}
              rules={{
                required: isKoreaType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </Flex>
        </Flex>
      );
    }

    // 해외계좌 환불
    if (isOtherType) {
      return (
        <Flex flexDirection="column" gap={16} isFullWidth>
          <Flex gap={16}>
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountName")}
              name="refundAccountName"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNamePlaceholder",
                ),
              }}
              rules={{
                required: isOtherType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountNo")}
              name="refundAccountNo"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNoPlaceholder",
                ),
              }}
              rules={{
                required: isOtherType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </Flex>
          <FormItem
            label={t("subscriptionManagement:subscription.bankName")}
            name="refundBankName"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.bankNamePlaceholder",
              ),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label={t("subscriptionManagement:subscription.bankAddress")}
            name="refundBankAddress"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.bankAddressPlaceholder",
              ),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label={t("subscriptionManagement:subscription.swiftCode")}
            name="swiftCode"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.swiftCodePlaceholder",
              ),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label={t("subscriptionManagement:subscription.memo")}
            name="memo"
            type="textarea"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.memoPlaceholder",
              ),
              className: "resize-none",
              maxLength: 140,
            }}
          />
        </Flex>
      );
    }
  };

  useEffect(() => {
    if (isNull(subscriptionData)) {
      return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }

    redirectNonCorporate();
  }, [
    navigate,
    redirectNonCorporate,
    session?.currentData?.row.exporterUserType,
    subscriptionData,
  ]);

  useEffect(() => {
    return () => {
      dispatch(changeAnnuallySubscription(null));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!subscriptionData?.id) {
        return;
      }

      try {
        await getCancelFee({ id: subscriptionData.id }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    })();
  }, [alert, getCancelFee, subscriptionData?.id]);

  return renderContent();
}

export default SubscriptionAnnuallyTerminationPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`;

const Title = styled(Typo)`
  padding: 24px 0;
`;

const StepsContainer = styled.div`
  width: 800px;
`;

const StyledSectionCard = styled(SectionCard)`
  width: 800px;
`;

const Container = styled(Flex)`
  padding: 32px 152px;
`;

const WhiteSpaceTypo = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
  width: 100vw;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  button {
    flex: 1;
    text-align: center;
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  gap: 8px;
  cursor: pointer;

  input[type="checkbox"] {
    margin-top: 3px;
    flex-shrink: 0;
  }

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
`;
