import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import RadioDisabledSvg from "@/src/assets/icons/icon-radio-disalbed-unchecked.svg";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import { useLazyGetBookingRecombinationListQuery } from "@/src/store/apis/tasks/taskDetail";
import { BookingRecombinationBookingListViewDto } from "@/src/store/apis/tasks/taskDetail/interface";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { ColDef, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { combineSplitBookingTableColumn } from "../../../../columns/columnTaskList";

interface BookingContentProps {
  isBuyerFetching: boolean;
  selectedBookingId: number | null;
  selectedContainerCount: number;
  buyerCode?: string;
  buyers: BuyerListViewDto[];
  loadingId: number;
  page: number;
  onPageChange: (page: number) => void;
  onBookingChange?: (id: BookingRecombinationBookingListViewDto | null) => void;
  onBuyerChange?: (buyerCode: string) => void;
  onTabChange?: () => void;
  onReset: () => void;
  onPaginationReset: () => void;
}

const emptyArray: any[] = [];

const BookingContent = ({
  isBuyerFetching,
  selectedBookingId,
  selectedContainerCount,
  buyers,
  buyerCode = "all",
  loadingId,
  page,
  onPageChange,
  onBookingChange,
  onBuyerChange,
  onTabChange,
  onReset,
  onPaginationReset,
}: BookingContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const [isReady, setIsReady] = useState(false);
  const [bookingColumn] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => <>{radioRenderer(params)}</>,
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...combineSplitBookingTableColumn,
  ]);

  const [getBookingList, { bookingList, bookingCount, isBookingListFetching }] =
    useLazyGetBookingRecombinationListQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        return {
          bookingCount: !isUnstable ? currentData.count : 0,
          bookingList: !isUnstable ? currentData.rows : emptyArray,
          isBookingListFetching: isFetching,
        };
      },
    });

  const buyerList = buyers.reduce<{ label: string; value: string }[]>(
    (acc, val) => {
      const resource = {
        label: val?.buyerNameCode || "",
        value: `${val.buyerNameCode}` || "",
      };
      return [...acc, resource];
    },
    [{ label: t("common:all"), value: "all" }],
  );

  const radioRenderer = (params: IRowNode) => {
    const handleRadioChange = () => {
      onBookingChange?.(params.data);
    };

    const checked = params.data.isChecked;

    return (
      <StyledRadio data-disabled={params.data.isOverThanRemainingContainer}>
        <input
          type="radio"
          className="custom-ag-radio"
          checked={checked}
          onChange={handleRadioChange}
          disabled={params.data.isOverThanRemainingContainer}
        />
        <span className="custom-radio-mark" />
      </StyledRadio>
    );
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "bookingNo",
        langKey: "table:bookingNo",
      },
      {
        columnKey: "etd",
        langKey: "table:etd",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "numberOfContainer",
        langKey: "table:noOfContainer",
      },
      {
        columnKey: "remainingNumberOfContainer",
        langKey: "table:remainingContainer",
      },
      {
        columnKey: "etaAt",
        langKey: "table:eta",
      },
    ],
  });

  useEffect(() => {
    (async () => {
      try {
        await getBookingList({
          taskId: loadingId,
          page: 1,
        }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBookingList, loadingId]);

  useEffect(() => {
    if (isBookingListFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isBookingListFetching]);

  return (
    <>
      <AccordionContentWrapper>
        <InputContainer>
          <Typo typoType="b7m">{t("common:buyerCode")}</Typo>
          <Select
            options={buyerList}
            showSearch
            value={buyerCode}
            suffixIcon={isBuyerFetching ? <Loader /> : undefined}
            disabled={isBuyerFetching}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onChange={async (value, option: any) => {
              onBuyerChange?.(value === "all" ? "all" : option.label);
              onPageChange(1);
              onReset();
              onPaginationReset();
              onBookingChange?.(null);
              await getBookingList({
                buyerNameCode: value === "all" ? undefined : option.label,
                taskId: loadingId,
                page: 1,
              });
            }}
          />
        </InputContainer>

        <InfoDiv>
          <Icon iconSrc={InfoGray6Svg} iconSize={16} />
          <Typo typoType="b9r" color="gray6">
            {t("task:detail.dialog.containerSplitBookingInfo")}
          </Typo>
        </InfoDiv>

        <TableWrapper>
          <Table
            ref={gridRef}
            onGridReady={() => setIsReady(true)}
            columnDefs={bookingColumn}
            rowData={
              isBookingListFetching
                ? undefined
                : bookingList.map((list) => ({
                    ...list,
                    isChecked: selectedBookingId === list.id,
                    isOverThanRemainingContainer:
                      selectedContainerCount > list.remainingNumberOfContainer,
                  }))
            }
            getRowClass={(e) =>
              e.data.isOverThanRemainingContainer
                ? "not-allowed-row"
                : undefined
            }
            totalPage={bookingCount}
            page={page}
            height={372}
            pageSize={10}
            handlePaginationClick={async (page, pageSize) => {
              onPageChange?.(page);
              await getBookingList({
                page,
                taskId: loadingId,
                buyerNameCode: buyerCode,
              });
            }}
            onCellClicked={(e) => {
              const rowData = e.node.data;
              const isBookingNoColumn =
                e.column.getColDef().field === "bookingNo";

              if (isBookingNoColumn) {
                navigate(
                  `${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${rowData.id}`,
                );
              } else {
                if (rowData.isOverThanRemainingContainer) {
                  return;
                }

                if (selectedBookingId) {
                  if (selectedBookingId !== rowData.id) {
                    onBookingChange?.(rowData);
                    onReset();
                  }
                } else {
                  onBookingChange?.(rowData);
                }
                onTabChange?.();
              }
            }}
          />
        </TableWrapper>
      </AccordionContentWrapper>
    </>
  );
};

export default BookingContent;

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  &[data-disabled="true"] {
    input {
      cursor: not-allowed;
    }
  }

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:disabled + .custom-radio-mark {
    background: url(${RadioDisabledSvg}) no-repeat;
    background-size: cover;
  }
`;

const AccordionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableWrapper = styled.div`
  background: ${colorSet.white};
  padding: 8px 8px 0;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;

  .ant-pagination-options {
    display: none;
  }
`;

const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
