import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReactNode } from "react";
import { styled } from "styled-components";
import PlContent from "./PlContent";
import { PlData } from "./types";

interface CiDownLoadProps {
  /**
   * @description PDF 필드의 title, value, hidden 필수값
   * data만 있어도 pdf 다운로드 가능합니다
   * */
  data: PlData;

  /**@description .pdf 확장자 입력 */
  fileName?: string;
  trigger?: ReactNode;

  /**
   * @description PDF URL 원본을 구하기 위한 함수
   * URL로 새창을 띄우면 브라우저 내장 PDF View 실행
   */
  getUrl?: (url: string | null) => void;
  getBlob?: (file: Blob | null) => void;
}

function PlDownLoad({
  fileName = "pl.pdf",
  data,
  getBlob,
  getUrl,
  trigger,
}: CiDownLoadProps) {
  return (
    <PDFDownloadLink fileName={fileName} document={<PlContent data={data} />}>
      {({ blob, url }) => {
        getBlob && getBlob(blob);
        getUrl && getUrl(url);

        return <StyledDiv>{trigger ?? "PDF Download"}</StyledDiv>;
      }}
    </PDFDownloadLink>
  );
}

export default PlDownLoad;

const StyledDiv = styled.div`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: initial;
  font-size: initial;
  display: inline-block;

  a {
    width: 100%;
    display: inline-block;
  }
`;
