import { Button } from "@/components/atom/Button";
import Typo from "@/components/atom/Typo";
import Flex from "@/components/molecule/Flex";
import { localstorageKeySet } from "@/src/constant/localstorage";
import useAlert from "@/src/hooks/useAlert";
import { persistor } from "@/src/index";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { useSignOutMutation } from "@/src/store/apis/auth";
import { apiSlice } from "@/src/store/apis/baseQuery";
import { currencyApi } from "@/src/store/apis/currency";
import { resetTimer, setRemainingTime } from "@/src/store/slice/sessionTime";
import colorSet from "@/styles/color";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// 상수 분리
const TIMER_INTERVAL = 1000;
const TIME_FORMAT_PADDING = 2;

// 타입 정의
interface ErrorResponse {
  data: {
    message: string | string[];
  };
}

const SessionTimeController = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { remainingTime } = useAppSelector((state) => state.sessionTime);
  const intervalRef = useRef<NodeJS.Timer>();
  const expirationTimeRef = useRef<number>(0);

  // API
  const [signOut] = useSignOutMutation();

  // 에러 처리 로직 분리
  const responseError = useCallback(
    (error: unknown) => {
      const err = error as ErrorResponse;
      const message = Array.isArray(err.data.message)
        ? err.data.message[0]
        : err.data.message;
      alert.showAlert({ message, type: "error" });
    },
    [alert],
  );

  // 타이머 만료
  const handleTimeExpired = useCallback(async () => {
    try {
      await signOut().unwrap();
    } catch (error) {
      responseError(error);
    } finally {
      persistor.purge();
      dispatch(currencyApi.util.resetApiState());
      dispatch(apiSlice.util.resetApiState());
      dispatch(resetTimer());
      clearInterval(intervalRef.current);
      navigate(PUBLIC_PATH.LOGIN, { replace: true });
      localStorage.setItem(localstorageKeySet.IS_SESSION_TIME_OUT, "true");
    }
  }, [dispatch, navigate, signOut, responseError]);

  // 연장 버튼
  const handleExtendSessionTime = useCallback(() => {
    dispatch(resetTimer());
  }, [dispatch]);

  // 시간 포맷팅
  const formattedTime = useMemo(() => {
    const min = Math.floor(remainingTime / 60)
      .toString()
      .padStart(TIME_FORMAT_PADDING, "0");
    const second = (remainingTime % 60)
      .toString()
      .padStart(TIME_FORMAT_PADDING, "0");
    return `${min}:${second}`;
  }, [remainingTime]);

  // 타이머 시작 또는 재시작
  const startTimer = useCallback(() => {
    expirationTimeRef.current = Date.now() + remainingTime * TIMER_INTERVAL;

    intervalRef.current = setInterval(() => {
      const now = Date.now();
      const remainingSecs = Math.ceil(
        (expirationTimeRef.current - now) / TIMER_INTERVAL,
      );

      if (remainingSecs <= 0) {
        handleTimeExpired();
      } else {
        dispatch(setRemainingTime(remainingSecs));
      }
    }, TIMER_INTERVAL);
  }, [remainingTime, dispatch, handleTimeExpired]);

  // 페이지 포커스 시 타이머 재계산
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "visible") {
      clearInterval(intervalRef.current);
      startTimer();
    }
  }, [startTimer]);

  useEffect(() => {
    startTimer();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(intervalRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [startTimer, handleVisibilityChange, remainingTime]);

  return (
    <SessionTimeControllerContainer alignItems="center">
      <StyledTypo typoType="h11" color="indigo">
        {formattedTime}
      </StyledTypo>
      <Button
        buttonGrade="secondary"
        buttonSize={24}
        onClick={handleExtendSessionTime}
      >
        {t("common:sessionExtend")}
      </Button>
    </SessionTimeControllerContainer>
  );
};

export default memo(SessionTimeController);

const SessionTimeControllerContainer = styled(Flex)`
  background: ${colorSet.gray11};
  border-radius: 6px;
`;

const StyledTypo = styled(Typo)`
  padding: 0 10px;
  width: 52px;
`;
