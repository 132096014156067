import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { localstorageKeySet } from "@/src/constant/localstorage";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import {
  useGetPlansQuery,
  useLazyGetSubscriptionQuery,
  useLazyGetSubscriptionsQuery,
} from "@/src/store/apis/subscription";
import { isNull, isUndefined } from "@/src/utils/is";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FreeCodeDialog from "./components/dialog/FreeCodeDialog";
import PlanChangeDialog from "./components/dialog/PlanChangeDialog";
import UnsubscribeCompletedDialog from "./components/dialog/UnsubscribeCompletedDialog";
import UnsubscribeDialog, {
  UnsubscribeType,
} from "./components/dialog/UnsubscribeDialog";
import MonthlyUsageHistory from "./components/MonthlyUsageHistory";
import PaymentManagement from "./components/PaymentManagement";
import SubscriptionInfo from "./components/SubscriptionInfo";
import SubscriptionStatusCallout from "./components/SubscriptionStatusCallout";
import { renderSubscriptionStatusBadge } from "./utils/renderSubscriptionStatusBadge";

enum AlertDialogState {
  NULL,
  UNSUBSCRIBE,
}

export enum SubscriptionDialogState {
  NULL,
  FREE_CODE,
  UNSUBSCRIBE,
  PLAN_CHANGE,
  EXPIRATION,
  IMMEDIATE,
}

function SubscriptionManagementPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const loadingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  const [dialogState, setDialogState] = useState<SubscriptionDialogState>(
    SubscriptionDialogState.NULL
  );
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [subscriptionIds, setSubscriptionIds] = useState<number[]>([]);
  const [freeCodeIds, setFreeCodeIds] = useState<number[]>([]);
  const [unsubscribeType, setUnsubscribeType] =
    useState<UnsubscribeType | null>(null);

  // API
  useGetPlansQuery({ planType: "NORMAL" }, { refetchOnMountOrArgChange: true });
  useGetCommonCodeViaCodeNameQuery({
    codeName: "COUNTRY",
  });

  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [getSubscriptions, { isError }] = useLazyGetSubscriptionsQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isFetching || isError || isUndefined(currentData);
      const isStable = !isUnstable;

      return { currentData, isSubscriptionsStable: isStable, isError };
    },
  });

  const [
    getSubscription,
    {
      isFetching,
      planType,
      subscriptionStatus,
      nextPlan,
      isStable,
      retractFreeCodeReason,
    },
  ] = useLazyGetSubscriptionQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isFetching || isError || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        isFetching,
        isStable,
        planType: currentData?.plan.planType,
        subscriptionStatus: currentData?.subscriptionStatus,
        nextPlan: currentData?.nextPlan,
        freeCodeList: currentData?.freeCodeList,
        retractFreeCodeReason: currentData?.retractFreeCodeReason,
      };
    },
  });

  const isManagerType =
    session.currentData?.row.exporterUserType === "MANAGER" ||
    session.currentData?.row.exporterUserType === "MIDDLE_MANAGER";

  const handleUnsubscribeClick = () => {
    setAlertDialogState(AlertDialogState.UNSUBSCRIBE);
  };
  const handleFreeCodeClick = () => {
    setDialogState(SubscriptionDialogState.FREE_CODE);
  };
  const handlePlanChangeClick = () => {
    setDialogState(SubscriptionDialogState.PLAN_CHANGE);
  };
  const handleUnsubscribeConfirmClick = () => {
    setAlertDialogState(AlertDialogState.NULL);
    setDialogState(SubscriptionDialogState.UNSUBSCRIBE);
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.UNSUBSCRIBE) {
      return (
        <AlertDialog
          open
          title={t("subscriptionManagement:subscription.unsubscribePlan")}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("subscriptionManagement:subscription.button.cancel")}
          okText={t("subscriptionManagement:subscription.button.ok")}
          onOk={handleUnsubscribeConfirmClick}
        >
          <WhiteSpaceTypo color="gray5" typoType="b8r">
            {t(
              "subscriptionManagement:subscription.unsubscribePlanDescription"
            )}
          </WhiteSpaceTypo>
        </AlertDialog>
      );
    }
  };

  const renderUnsubscribeCompletedDialog = () => {
    if (unsubscribeType) {
      // 기간 만기 해지
      if (unsubscribeType === "EXPIRATION") {
        return (
          <UnsubscribeCompletedDialog
            open
            onOpenChange={() => {
              setDialogState(SubscriptionDialogState.NULL);
              setUnsubscribeType(null);
            }}
            unsubscribeType={unsubscribeType}
          />
        );
      }

      // 즉시 해지
      if (unsubscribeType === "IMMEDIATE") {
        return (
          <UnsubscribeCompletedDialog
            open
            onOpenChange={() => {
              setDialogState(SubscriptionDialogState.NULL);
              setUnsubscribeType(null);
            }}
            unsubscribeType={unsubscribeType}
          />
        );
      }
    }
  };

  const renderDialog = () => {
    // 무료 기간 코드
    if (dialogState === SubscriptionDialogState.FREE_CODE) {
      return (
        <FreeCodeDialog
          open
          onOpenChange={() => setDialogState(SubscriptionDialogState.NULL)}
        />
      );
    }

    // 구독 해지
    if (dialogState === SubscriptionDialogState.UNSUBSCRIBE) {
      return (
        <UnsubscribeDialog
          open
          onOpenChange={() => setDialogState(SubscriptionDialogState.NULL)}
          onSave={(type) => {
            setUnsubscribeType(type);
          }}
        />
      );
    }

    // 플랜 변경
    if (dialogState === SubscriptionDialogState.PLAN_CHANGE) {
      return (
        <PlanChangeDialog
          open
          onOpenChange={() => setDialogState(SubscriptionDialogState.NULL)}
        />
      );
    }
  };

  const renderBadgeContainer = () => {
    const isUnstableNextPlan = isNull(nextPlan);

    // 구독해지 예정, 구독해지
    if (subscriptionStatus === "CANCELED") {
      return (
        <BadgeContainer justifyContent="space-between">
          {renderSubscriptionStatusBadge({
            type: subscriptionStatus,
            t,
            size: "L",
          })}
        </BadgeContainer>
      );
    }

    return (
      <BadgeContainer justifyContent="space-between">
        {renderSubscriptionStatusBadge({ type: planType, t, size: "L" })}
        <PlanButtonContainer gap={8}>
          <Button
            buttonColor="blue"
            buttonGrade="secondary"
            buttonSize={32}
            disabled={isManagerType}
            onClick={handleFreeCodeClick}
          >
            {t("subscriptionManagement:subscription.button.freeTrialCode")}
          </Button>

          {isUnstableNextPlan && (
            <Button
              buttonSize={32}
              disabled={isManagerType}
              onClick={handlePlanChangeClick}
            >
              {t("subscriptionManagement:subscription.button.planChange")}
            </Button>
          )}
        </PlanButtonContainer>
      </BadgeContainer>
    );
  };

  const renderSubscriptionContent = () => {
    if (!isFetching) {
      if (isError) {
        return (
          <SubscriptionStatusCallout
            subscriptionIds={subscriptionIds}
            onSubscriptionIdsChange={setSubscriptionIds}
          />
        );
      }

      if (isStable) {
        return (
          <>
            {renderBadgeContainer()}
            <Flex direction="column" gap={16}>
              {/* 구독 Callout */}
              <SubscriptionStatusCallout
                subscriptionIds={subscriptionIds}
                onSubscriptionIdsChange={setSubscriptionIds}
              />

              {/* 무료기간 코드 회수 */}
              {!!retractFreeCodeReason && (
                <SubscriptionStatusCallout
                  subscriptionIds={subscriptionIds}
                  onSubscriptionIdsChange={setSubscriptionIds}
                  freeCodeIds={freeCodeIds}
                  onFreeCodeIdsChange={setFreeCodeIds}
                  type={"RETRACT_FREE_CODE"}
                />
              )}

              {/**
               * - 구독 정보
               * - 결제 관리
               * - 구독 내역
               * */}
              <SubscriptionInfo />
              <PaymentManagement />
              <MonthlyUsageHistory />
              {/* 구독 해지 버튼 */}
              {subscriptionStatus !== "CANCELED" && (
                <ButtonContainer>
                  <Button
                    buttonColor="black"
                    buttonGrade="tertiary"
                    disabled={isManagerType}
                    onClick={handleUnsubscribeClick}
                  >
                    {t(
                      "subscriptionManagement:subscription.button.unsubscribe"
                    )}
                  </Button>
                </ButtonContainer>
              )}
            </Flex>
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (isManagerType) {
      if (session.currentData?.row.exporterUserMainFieldType === "BUYER") {
        return navigate(IMPORTER_PRIVATE_PATH.HOME);
      }
      return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  }, [
    isManagerType,
    navigate,
    session.currentData?.row.exporterUserMainFieldType,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getSubscriptions({
          page: 1,
          pageSize: 10,
        }).unwrap();

        if (res.rows) {
          await getSubscription({ id: res.rows[0].id }).unwrap();
        }

        // 콜아웃 닫은 id ids배열
        const localStorageFreeCodeIds = localStorage.getItem(
          localstorageKeySet.FREE_CODE_IDS
        )
          ? (JSON.parse(
              localStorage.getItem(localstorageKeySet.FREE_CODE_IDS) as string
            ) as string[])
          : [];
        const localStorageSubscriptionIds = localStorage.getItem(
          localstorageKeySet.SUBSCRIPTION_IDS
        )
          ? (JSON.parse(
              localStorage.getItem(
                localstorageKeySet.SUBSCRIPTION_IDS
              ) as string
            ) as string[])
          : [];

        setFreeCodeIds(localStorageFreeCodeIds.map((item) => Number(item)));
        setSubscriptionIds(
          localStorageSubscriptionIds.map((item) => Number(item))
        );
      } catch (e: any) {
        if (e.status !== 404) {
          const message = Array.isArray(e.data?.message)
            ? e.data?.message[0]
            : e.data?.message;
          alert.showAlert({
            type: "error",
            message: message,
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetching) {
      const setTimeoutId = setTimeout(() => {
        handleContentLoadingOn();
      }, 300);

      loadingTimeoutId.current = setTimeoutId;
    } else {
      if (loadingTimeoutId.current) {
        clearTimeout(loadingTimeoutId.current);
      }
      handleContentLoadingOff();
    }
  }, [handleContentLoadingOff, handleContentLoadingOn, isFetching]);

  useEffect(() => {
    return () => {
      handleContentLoadingOff();
    };
  }, [handleContentLoadingOff]);

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:management"),
        t("sideNav:subscriptionManagement"),
      ]}
      pageTitle={t("sideNav:subscriptionManagement")}
    >
      {renderSubscriptionContent()}
      {renderUnsubscribeCompletedDialog()}
      {renderAlertDialog()}
      {renderDialog()}
    </ExporterMainLayout>
  );
}

export default SubscriptionManagementPage;

const Flex = styled.div<{
  direction?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  gap?: number;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap || 0}px;
`;

const BadgeContainer = styled(Flex)`
  padding-bottom: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const WhiteSpaceTypo = styled(Typo)`
  white-space: pre-wrap;
`;

const PlanButtonContainer = styled(Flex)`
  margin-left: auto;
`;
