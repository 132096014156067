import colorSet, { ColorType } from "@/src/styles/color";
import { FontSizeType } from "@/src/styles/typography";
import { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { css, styled } from "styled-components";
import Typo from "../Typo";

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  text?: string | ReactNode;
  typoType?: FontSizeType;
  color?: ColorType;
  badgeColor?: ColorType;
  badgeSize?: "L" | "S";
  badgeType?: "line" | "normal";
  borderColor?: ColorType;
}

const Badge = ({
  text,
  color = "white",
  badgeColor = "blue2",
  badgeSize = "L",
  badgeType = "normal",
  borderColor = "blue3",
  children,
  ...props
}: PropsWithChildren<BadgeProps>) => {
  const renderText = () => {
    if (children) {
      return children;
    }

    if (typeof text === "string") {
      return (
        <Typo color={color} typoType={badgeSize === "L" ? "b8m" : "b11m"}>
          {text}
        </Typo>
      );
    }

    return text;
  };

  return (
    <StyledBadge
      $badgeColor={badgeColor}
      $badgeSize={badgeSize}
      $badgeType={badgeType}
      $borderColor={borderColor}
      {...props}
    >
      {renderText()}
    </StyledBadge>
  );
};

export default Badge;

const StyledBadge = styled.div<{
  $badgeColor: ColorType;
  $badgeSize: "L" | "S";
  $badgeType: "line" | "normal";
  $borderColor: ColorType;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: 4px 10px;
  background: ${({ $badgeColor }) => colorSet[$badgeColor]};
  ${({ $badgeType, $borderColor }) =>
    $badgeType === "line" &&
    css`
      border: 1px solid ${colorSet[$borderColor]};
      background: transparent;
    `};
  ${({ $badgeSize }) =>
    $badgeSize === "L"
      ? css`
          padding: 4px 14px;
        `
      : css`
          padding: 2px 10px;
        `};
`;
