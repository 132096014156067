import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import {
  scDownload,
  scPreview,
} from "@/src/components/template/pdfs/v2/Sc/ScContent";
import { ScData } from "@/src/components/template/pdfs/v2/Sc/types";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import {
  useLazyGetContractDetailQuery,
  useLazyGetExporterContractSignatureHistoriesQuery,
} from "@/src/store/apis/contracts/contractDetail";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import {
  InternalScFileInfo,
  SimpleFileMediaDto,
} from "@/src/store/apis/shipments/shipmentDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { downloadFile } from "@/src/utils/downloadFile";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "styled-components";

interface AllFileCardProps {
  internalScFile?: InternalScFileInfo[];
  scAttachmentFile?: SimpleFileMediaDto[];
  poFile?: SimpleFileMediaDto[];
  lcFile?: SimpleFileMediaDto[];
  bookingFile?: SimpleFileMediaDto[];
  isSuccess: boolean;
}

function AllFileCard({
  internalScFile,
  scAttachmentFile,
  poFile,
  lcFile,
  bookingFile,
  isSuccess,
}: AllFileCardProps) {
  const { t } = useTranslation();
  const [getContractPreviewSignature] =
    useLazyGetExporterContractSignatureHistoriesQuery();
  const [getContractDetail] = useLazyGetContractDetailQuery();
  const alert = useAlert();
  const [contractsDetail, setContractsDetail] =
    useState<ContractDetailViewDto[]>();
  const allButtonRef = useRef<HTMLButtonElement[]>([]);

  // SC PDF
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const [scDataList, setScDataList] = useState<ScData[]>([]);

  const isUndefinedFiles =
    !internalScFile && !scAttachmentFile && !poFile && !lcFile && !bookingFile;

  const handlePreview = (scData: ScData) => {
    scPreview(scData);
  };

  const handleDownload = (scData: ScData) => {
    scDownload(scData, `${t("common:scNo")}${scData?.scNumber}.pdf`);
  };

  const fetchInternalScFile = async () => {
    try {
      const contractDetailList = await Promise.all(
        internalScFile?.map(({ contractId }) =>
          getContractDetail({ id: contractId }).unwrap(),
        ) ?? [],
      );

      setContractsDetail(contractDetailList);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const createBaseScData = (
    data: ContractDetailViewDto,
  ): Omit<ScData, "sellerSignatureUrl" | "buyerSignatureUrl"> => ({
    scNumber: data?.scNo || "",
    componyLogoUrl: data?.businessLogoSimpleMedia?.mediaUrl || "",
    sellerCorporation: data?.companyName || "",
    sellerContact: data?.tel || "",
    sellerContactPrefix: data?.telPrefix || "",
    sellerFax: data?.fax || "",
    sellerFaxPrefix: data?.faxPrefix || "",
    sellerCountryName: data?.countryName || "",
    sellerRegion: data?.region || "",
    sellerPostalCode: data?.postalCode || "",
    sellerLocality: data?.locality || "",
    sellerStreetAddress: data?.streetAddress || "",

    buyerCorporation: data?.buyerName || "",
    buyerContactPrefix: data?.buyerTelPrefix || "",
    buyerContact: data?.buyerTel || "",
    buyerFaxPrefix: data?.buyerFaxPrefix || "",
    buyerFax: data?.buyerFax || "",
    buyerCountryName: data?.buyerCountryName || "",
    buyerRegion: data?.buyerRegion || "",
    buyerPostalCode: data?.buyerPostalCode || "",
    buyerLocality: data?.buyerLocality || "",
    buyerStreetAddress: data?.buyerStreetAddress || "",

    orderDate: data?.orderDateAt
      ? dayjs(data?.orderDateAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
      : "",
    paymentTerm: data?.paymentTerms || "",
    paymentTermsRemark: data?.paymentTermsRemark || "",
    originLocation: data?.origin || "",
    shippingTerm: data?.shippingTerms || "",
    shippingTermRemark: data?.shippingTermsRemark || "",
    lastShipmentDate: data?.lastShipmentDateAt
      ? dayjs(data?.lastShipmentDateAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
      : "",
    quantityPrefix: data?.quantityUnit || "",
    quantity: data?.quantity
      ? Number(data?.quantity.toString().replace(/[,]/g, "")).toLocaleString(
          "ko-KR",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        )
      : "",
    description: data?.exporterItem || "",
    hsCode: data?.hsCode || "",
    unitPricePrefix: data?.unitPriceUnit || "",
    unitPrice: data?.unitPrice
      ? Number(data?.unitPrice.toString().replace(/[,]/g, "")).toLocaleString(
          "ko-KR",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        )
      : "",
    amount:
      (data?.quantity &&
        data?.unitPrice &&
        Number(
          (
            (parseFloat(data?.quantity.toString().replace(/,/g, "")) || 0) *
            (parseFloat(data?.unitPrice.toString().replace(/,/g, "")) || 0)
          ).toFixed(2),
        ).toLocaleString("ko-KR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })) ||
      "",
    remark: data?.contractRemark || "",

    isAddBankDetail: !!data?.isAddBankDetail,
    isAddContractTerms: !!data?.isAddContractTerms,
    contractTermsTitle: data?.salesContractTerms?.title || "",
    contractTerms:
      data?.salesContractTerms?.latestSalesContractTermsHistory?.body || "",
    bankName: data?.bankName || "",
    bankTelPrefix: data?.bankTelPrefix || "",
    bankTel: data?.bankTel || "",
    bankFaxPrefix: data?.bankFaxPrefix || "",
    bankFax: data?.bankFax || "",
    bankStreetAddress: data?.bankStreetAddress || "",
    bankLocality: data?.bankLocality || "",
    bankRegion: data?.bankRegion || "",
    bankPostalCode: data?.bankPostalCode || "",
    bankCountryName: data?.bankCountryName || "",
    swiftCode: data?.swiftCode || "",
    accountNumber: data?.accountNumber || "",
    accountName: data?.accountName || "",
  });

  const getScData = async (data: ContractDetailViewDto) => {
    try {
      const signResponse = await getContractPreviewSignature({
        id: data.id,
      }).unwrap();

      return {
        ...createBaseScData(data),
        sellerSignatureUrl:
          signResponse.rows[0].exporterScSignMedia?.mediaUrl || "",
        buyerSignatureUrl:
          signResponse.rows[0].importerScSignMedia?.mediaUrl || "",
      };
    } catch {
      return {
        ...createBaseScData(data),
        sellerSignatureUrl: "",
        buyerSignatureUrl: "",
      };
    }
  };

  useEffect(() => {
    if (internalScFile?.length) {
      fetchInternalScFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalScFile]);

  useEffect(() => {
    const loadScData = async () => {
      if (contractsDetail) {
        const dataList = await Promise.all(
          contractsDetail.map((data) => getScData(data)),
        );
        setScDataList(dataList);
      }
    };

    loadScData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsDetail]);

  const handleAllDownload = () => {
    allButtonRef.current
      .filter((buttonNode) => buttonNode && !buttonNode.disabled)
      .forEach((buttonNode) => {
        // 약간의 딜레이를 두어 브라우저의 다운로드 제한을 방지
        setTimeout(() => {
          buttonNode.click();
        }, 300);
      });
  };

  return (
    <SectionCard
      cardTitle={t("shipment:exporter.detail.label.allFile")}
      rightAccessory={
        <StyledButton
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={32}
          onClick={handleAllDownload}
        >
          <DownloadIcon />
          {t("shipment:exporter.detail.button.downloadButton")}
        </StyledButton>
      }
    >
      {isUndefinedFiles ? (
        <NoDataContainer>{renderNoRowsComponent()}</NoDataContainer>
      ) : (
        <AllFileContainer>
          {contractsDetail?.map((data, idx) => {
            return (
              <SectionCardRow
                label={`${t("common:scFile")} ${addZeroPrefix(idx + 1)}`}
                value={
                  <FileInfo>
                    <FileName typoType="b7r" color="gray5">
                      {data?.scNo ?? ""}
                    </FileName>
                    <FileButtons>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        onClick={() => handleDownload(scDataList[idx])}
                        ref={(node) => {
                          if (node) {
                            const isAlreadyInRef = allButtonRef.current.some(
                              (buttonNode) => buttonNode.isSameNode(node),
                            );
                            if (!isAlreadyInRef) {
                              allButtonRef.current =
                                allButtonRef.current.concat(node);
                            }
                          }
                        }}
                      >
                        <DownloadIcon color="indigo" />
                      </IconButton>
                      <IconButton
                        buttonSize={24}
                        buttonColor="blue"
                        buttonGrade="secondary"
                        ref={previewButtonRef}
                        isLoading={isPreviewLoading}
                        disabled={isPreviewLoading}
                        onClick={() => {
                          setIsPreviewLoading(true);
                          setTimeout(() => {
                            if (previewButtonRef.current) {
                              previewButtonRef.current.click();
                              setIsPreviewLoading(false);
                            }
                          }, 1500);
                          handlePreview(scDataList[idx]);
                        }}
                      >
                        <PreviewIcon color="indigo" />
                      </IconButton>
                    </FileButtons>
                  </FileInfo>
                }
              />
            );
          })}

          {scAttachmentFile &&
            scAttachmentFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:scFile")} ${addZeroPrefix(idx + 1)} (${t(
                    "common:attachment",
                  )})`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? "",
                            );
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}

          {poFile &&
            poFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:poFile")} ${addZeroPrefix(idx + 1)}`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? "",
                            );
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}

          {lcFile &&
            lcFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:lcFile")} ${addZeroPrefix(idx + 1)}`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? "",
                            );
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}

          {bookingFile &&
            bookingFile.map(({ mediaUrl, originalFileName }, idx) => {
              return (
                <SectionCardRow
                  label={`${t("common:bookingFile")} ${addZeroPrefix(idx + 1)}`}
                  value={
                    <FileInfo>
                      <FileName typoType="b7r" color="gray5">
                        {originalFileName || "-"}
                      </FileName>
                      <FileButtons>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(
                              mediaUrl ?? "",
                              originalFileName ?? "",
                            );
                          }}
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.current.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                allButtonRef.current =
                                  allButtonRef.current.concat(node);
                              }
                            }
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a href={mediaUrl} target="_blank" rel="noreferrer">
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </FileInfo>
                  }
                />
              );
            })}
        </AllFileContainer>
      )}
    </SectionCard>
  );
}

export default AllFileCard;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AllFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const FileName = styled(Typo)`
  word-break: break-all;
`;

const FileButtons = styled.div`
  display: flex;
  gap: 4px;

  a {
    line-height: 0px;
  }
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 216px;
`;
