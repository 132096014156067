interface WithCreatedAt {
  createdAt: string;
  version: number;
}

/**
 * 각 버전별로 가장 최근의 createdAt을 가진 항목만 필터링하여 반환합니다.
 * @param histories - createdAt과 version 속성을 가진 객체 배열
 * @returns 각 버전별 최신 항목만 포함된 배열
 */
export const getLatestVersionHistory = <T extends WithCreatedAt>(
  histories: T[],
): T[] => {
  const versionMap = new Map<number, T>();

  histories.forEach((history) => {
    const version = history.version;
    const existingHistory = versionMap.get(version);

    if (
      !existingHistory ||
      new Date(history.createdAt) > new Date(existingHistory.createdAt)
    ) {
      versionMap.set(version, history);
    }
  });

  return Array.from(versionMap.values());
};
