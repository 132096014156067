import Button from "@/src/components/atom/Button/BasicButton";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { forwardRef, ReactNode } from "react";
import { css, styled } from "styled-components";

interface SignUpCardProps {
  icon?: string;
  iconSize?: number;
  description?: ReactNode;
  onClick?: () => void;
  className?: string;
  type: "corporate" | "staff";
  buttonText: string;
}

const SignUpCard = forwardRef<HTMLDivElement, SignUpCardProps>((props, ref) => {
  return (
    <CardContainer
      className={props.className}
      onClick={props.onClick}
      tabIndex={1}
      ref={ref}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          props.onClick?.();
        }
      }}
    >
      <IconContainer>
        {props.icon ? (
          <Icon iconSrc={props.icon} iconSize={props.iconSize ?? 105} />
        ) : null}
      </IconContainer>

      <ContentContainer>
        <DescriptionDiv>
          <Typo as="p" typoType="b7m" color="gray4">
            {props.description}
          </Typo>
        </DescriptionDiv>

        <StyledButton data-type={props.type === "corporate" && "corporate"}>
          {props.buttonText}
        </StyledButton>
      </ContentContainer>
    </CardContainer>
  );
});

export default SignUpCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 32px;
  height: 390px;
  cursor: pointer;
  background: ${colorSet.white};
  box-shadow:
    0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
    0px 1px 2px rgba(5, 29, 57, 0.1);
  border-radius: 16px;
  transition: all 0.15s;

  &:hover {
    outline: 1px solid ${colorSet.gray1};
    box-shadow:
      0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
      0px 2px 4px rgba(5, 29, 57, 0.2);
  }
`;

const Icon = styled.span<{ iconSrc: string; iconSize: number }>`
  ${({ iconSize }) => css`
    width: ${iconSize}px;
    height: ${iconSize}px;
  `};
  display: inline-block;
  background: url(${({ iconSrc }) => iconSrc}) center center/contain no-repeat;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  flex: 1;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 8px 16px;
  ${typo.h2}

  &[data-type='corporate'] {
    background: ${colorSet.blue4};
  }
`;

const DescriptionDiv = styled.div`
  min-height: 48px;
`;
