import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import Flex from "@/src/components/molecule/Flex";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import useAlert from "@/src/hooks/useAlert";
import {
  useGetImporterBookingDetailBookmarkQuery,
  useGetImporterBookingDetailBookmarkUserQuery,
  useGetImporterBookingDetailQuery,
  useUpdateImporterBookingDetailBookmarkMutation,
} from "@/src/store/apis/bookings/bookingDetail";
import { isUndefined } from "@/src/utils/is";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import BookingInfoCard from "./components/detail/card/BookingInfoCard";
import ContainerListCard from "./components/detail/card/ContainerListCard";
import ContractInformationCard from "./components/detail/card/ContractInformationCard";
import ShipmentMemoCard from "./components/detail/card/ShipmentMemoCard";
import ShipmentTrackingCard from "./components/detail/card/ShipmentTrackingCard";
import { renderShipmentStatusBadge } from "./utils/renderShipmentStatusBadge";

const EMPTY_ARRAY: [] = [];

enum DialogState {
  NULL,
  BOOK_MARK,
}

const ImporterShipmentDetailPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const id = Number(params.id);
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  const { currentData } = useGetImporterBookingDetailQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { isBookmarked } = useGetImporterBookingDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return { isBookmarked: currentData?.isBookmarked };
      },
    },
  );
  const { bookmarkUserList } = useGetImporterBookingDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookmarkUserList: !isUnstable ? currentData.rows : EMPTY_ARRAY,
        };
      },
    },
  );
  const [updateBookingDetailBookmark] =
    useUpdateImporterBookingDetailBookmarkMutation();

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };

      await updateBookingDetailBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.NULL) return null;
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }
  };

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:importStatus"), t("sideNav:importStatusDetail")]}
      pageTitle={
        <>
          {t("common:bookingNo")} {currentData?.bookingNo || ""}
        </>
      }
    >
      <ContentContainer flexDirection="column" gap={16}>
        <Flex justifyContent="space-between">
          {renderShipmentStatusBadge({
            t,
            type: currentData?.bookingStatusForImporter || "CONFIRMED_BOOKING",
            size: "L",
          })}

          <BookMarkStatus
            bookMarkStatus={isBookmarked}
            bookMarkCount={bookmarkUserList.length}
            onClickBookMark={handleBookmarkClick}
            onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
          />
        </Flex>
        <BookingInfoCard id={id} />
        <ContainerListCard bookingId={id} />
        <ShipmentTrackingCard id={id} />
        <ContractInformationCard id={id} />
        <ShipmentMemoCard id={id} />
      </ContentContainer>
      <BottomFixedContainer>
        <Button
          buttonColor="black"
          buttonGrade="tertiary"
          onClick={() => navigate(-1)}
        >
          <Flex alignItems="center" gap={4}>
            <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
            {t("shipment:importer.detail.button.backToPreviousButton")}
          </Flex>
        </Button>
      </BottomFixedContainer>
      {renderDialogs()}
    </ImporterMainLayout>
  );
};

export default ImporterShipmentDetailPage;

const ContentContainer = styled(Flex)`
  margin-bottom: 72px;
`;
