import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle.svg";
import PaperAirplaneSvg from "@/src/assets/icons/icon-paper-airplane.svg";
import { Button } from "@/src/components/atom/Button";
import TextButton from "@/src/components/atom/Button/TextButton";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import useAlert from "@/src/hooks/useAlert";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useResetPasswordMutation } from "@/src/store/apis/auth";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { emailRegex } from "@/src/utils/regex";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const FindPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const nameRef = useRef<HTMLInputElement>(null);
  const secondStepButtonRef = useRef<HTMLButtonElement>(null);
  const alert = useAlert();
  const [step, setStep] = useState(1);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      id: "",
    },
  });

  const handlePasswordReset = async () => {
    try {
      await resetPassword({
        name: watch("name"),
        aId: watch("id"),
      }).unwrap();

      setStep(2);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  useEffect(() => {
    if (step === 1) {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    } else {
      if (secondStepButtonRef.current) {
        secondStepButtonRef.current.focus();
      }
    }
  }, [step]);

  const goTo = (path: keyof typeof PUBLIC_PATH) => {
    navigate(PUBLIC_PATH[path]);
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Card>
              <TypoSection>
                <Typo typoType="d6" color="gray1">
                  {t("findPassword:resetPasswordTitle")}
                </Typo>
                <Typo>{t("findPassword:resetPasswordDescription")}</Typo>
              </TypoSection>

              <StyledForm onSubmit={handleSubmit(handlePasswordReset)}>
                <FieldDiv>
                  <label htmlFor="name">
                    <Typo>
                      {t("findPassword:fullName")} <strong>*</strong>
                    </Typo>
                  </label>
                  <Controller
                    name="name"
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        ref={nameRef}
                        data-invalid={!!errors.name}
                        id="name"
                        placeholder={t("findPassword:fullNamePlaceholder")}
                        onClear={() => setValue("name", "")}
                      />
                    )}
                  />

                  {errors.name?.type === "required" && (
                    <FormErrorMessage>
                      <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                      {t("error:required")}
                    </FormErrorMessage>
                  )}
                </FieldDiv>

                <FieldDiv>
                  <label htmlFor="id">
                    <Typo>
                      {t("findPassword:id")} <strong>*</strong>
                    </Typo>
                  </label>
                  <Controller
                    name="id"
                    rules={{
                      required: true,
                      pattern: emailRegex,
                    }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        data-invalid={!!errors.id}
                        id="id"
                        placeholder={t("findPassword:idPlaceholder")}
                        onClear={() => setValue("id", "")}
                      />
                    )}
                  />

                  {errors.id?.type === "required" && (
                    <FormErrorMessage>
                      <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                      {t("error:required")}
                    </FormErrorMessage>
                  )}
                  {errors.id?.type === "pattern" && (
                    <FormErrorMessage>
                      <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                      {t("error:login.emailTypeMissMatching")}
                    </FormErrorMessage>
                  )}
                </FieldDiv>

                <Button style={{ textAlign: "center" }} type="submit">
                  <Typo typoType="btn3m" color="white">
                    {t("findPassword:resetPasswordButtonLabel")}
                  </Typo>
                </Button>
              </StyledForm>
            </Card>
            <LoginBackSection>
              <Typo typoType="b9m">{t("findPassword:rememberPassword")}</Typo>
              <StyledTextButton onClick={() => goTo("LOGIN")}>
                {t("findPassword:backToLogin")}
              </StyledTextButton>
            </LoginBackSection>
          </>
        );
      case 2:
        return (
          <Card>
            <IconContainer>
              <Icon iconSrc={PaperAirplaneSvg} iconSize={80} />
            </IconContainer>

            <TypoSection
              style={{ textAlign: "center", whiteSpace: "pre-wrap" }}
            >
              <Typo typoType="d6" color="gray1">
                {t("findPassword:resetCompleteTitle")}
              </Typo>
              <Typo>{t("findPassword:resetCompleteDescription")}</Typo>
            </TypoSection>

            <StyledButton
              buttonGrade="secondary"
              onClick={() => goTo("LOGIN")}
              ref={secondStepButtonRef}
            >
              <Typo typoType="btn3m" color="blue4">
                {t("findPassword:backToLogin")}
              </Typo>
            </StyledButton>
          </Card>
        );
    }
  };

  return (
    <SignUpLayout>
      <StyledMain>
        <h2>{t("findPassword:resetPassword")}</h2>
        {renderContent()}
      </StyledMain>
    </SignUpLayout>
  );
};

export default FindPasswordPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    padding: 24px 0;
    ${typo.d3};
  }
`;

const Card = styled.div`
  width: 640px;
  padding: 48px;
  margin-top: 24px;
  background: ${colorSet.white};
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TypoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    color: ${colorSet.red2};
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const FormErrorMessage = styled.span`
  ${typo.b9r}
  color: ${colorSet.red2};
  padding-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledButton = styled(Button)`
  text-align: center;
  width: 100%;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginBackSection = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding-top: 41px;
`;

const StyledTextButton = styled(TextButton)`
  color: ${colorSet.systemBlue2};
  ${typo.b9m};
`;
