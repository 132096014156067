import { ClientType, ExporterDto, ExporterUserDto } from "../auth/interface";
import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import {
  ApproveCompanyLinkageDto,
  BuyerDto,
  CompanyLinkageHistorySummaryDto,
  GenerateLinkDto,
  GenerateLinkedBuyerDto,
  GetCompanyLinkageHistorySummaryDtoRequest,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["buyerEmployees", "buyer", "buyers"],
});

const linkApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getCompanyLinkageSentHistory: build.query<
      Rows<CompanyLinkageHistorySummaryDto>,
      GetCompanyLinkageHistorySummaryDtoRequest
    >({
      query: (params) => ({
        url: "/company-linkage-histories/sent",
        params,
      }),
    }),
    getCompanyLinkageReceivedHistory: build.query<
      Rows<CompanyLinkageHistorySummaryDto>,
      GetCompanyLinkageHistorySummaryDtoRequest
    >({
      query: (params) => ({
        url: "/company-linkage-histories/received",
        params,
      }),
    }),
    getCompanyLinkagePendingList: build.query<
      Rows<CompanyLinkageHistorySummaryDto>,
      void
    >({
      query: () => ({
        url: "/company-linkage-histories/pending",
      }),
    }),
    getCompanyInfoViaLinkCode: build.query<
      Row<ExporterDto>,
      { companyLinkCode: string }
    >({
      query: (params) => ({
        url: "/exporter",
        params,
      }),
    }),
    getCorporateManagerInfoViaLinkCode: build.query<
      Row<ExporterUserDto>,
      { companyLinkCode: string }
    >({
      query: (params) => ({
        url: "/exporter/corporate-manager",
        params,
      }),
    }),
    linkNewClient: build.mutation<Row<BuyerDto>, GenerateLinkedBuyerDto>({
      query: (body) => ({
        url: "/linked-buyers",
        method: "POST",
        body,
      }),
    }),
    linkExistedClient: build.mutation<Row<BuyerDto>, GenerateLinkDto>({
      query: (body) => ({
        url: "/link",
        method: "POST",
        body,
      }),
    }),
    approveLink: build.mutation<
      ApproveCompanyLinkageDto,
      {
        id: number;
        companyType: ClientType;
        buyerNameCode: string;
        exporterUserIdList: number[];
      }
    >({
      query: ({ id, companyType, buyerNameCode, exporterUserIdList }) => ({
        url: `/buyers/${id}/approval`,
        method: "PUT",
        body: {
          companyType,
          buyerNameCode,
          exporterUserIdList,
        },
      }),
    }),
    rejectLink: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/buyers/${id}/rejection`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetCompanyLinkageReceivedHistoryQuery,
  useGetCompanyLinkageSentHistoryQuery,
  useLazyGetCompanyInfoViaLinkCodeQuery,
  useLazyGetCorporateManagerInfoViaLinkCodeQuery,
  useGetCompanyLinkagePendingListQuery,
  useLazyGetCompanyLinkagePendingListQuery,
  useLinkNewClientMutation,
  useLinkExistedClientMutation,
  useApproveLinkMutation,
  useRejectLinkMutation,
} = linkApi;
export { linkApi };
