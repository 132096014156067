import AddBlueSvg, {
  ReactComponent as AddPlusSvg,
} from "@/src/assets/icons/icon-add-blue.svg";
import CancelRedSvg from "@/src/assets/icons/icon-cancle-red.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import ClockSvg from "@/src/assets/icons/icon-clock-black.svg";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import NoDataBagSvg from "@/src/assets/icons/icon-no-data-bag.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import DatePicker, { StyledTimePicker } from "@/src/components/atom/DatePicker";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { DIRECT_INPUT_LABEL, DIRECT_INPUT_VALUE } from "@/src/constant";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppSelector } from "@/src/store";
import {
  useCreateBookingMutation,
  useLazyCheckBookingNoQuery,
} from "@/src/store/apis/bookings/bookingRegister";
import { GenerateBookingDto } from "@/src/store/apis/bookings/bookingRegister/interface";
import { useGetCommonCodeListQuery } from "@/src/store/apis/common";
import { useLazyGetContractQuery } from "@/src/store/apis/contracts";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { MediaDto } from "@/src/store/apis/media/interface";
import {
  useLazyGetTemporaryDataQuery,
  useSaveTemporaryDataMutation,
} from "@/src/store/apis/tempSave";
import colorSet from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import { commonCodeMapper } from "@/src/utils/commonCodeHelper";
import { isNull, isUndefined } from "@/src/utils/is";
import { transformValueOrUndefined } from "@/src/utils/transform";
import { ConfigProvider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { css, styled } from "styled-components";
import LoadContractInformationCard from "./components/add/cards/LoadContractInformationCard";
import LoadContractsMultipleSelectDialog from "./components/add/dialog/LoadContractsMultipleSelectDialog";
import { bookingDefaultValue } from "./utils/defaultValues";

type OptionType = {
  value: string;
  label: string;
};

enum AlertDialogState {
  NULL,
  SAVED_DATA,
  BACK_TO_PREVIOUS,
}

const ExporterBookingAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.auth.user);
  const subscription = useAppSelector((state) => state.subscription);
  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    user?.exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const contractIdFromContractPage = searchParams.get("contractId");
  const [getSavedData] = useLazyGetTemporaryDataQuery();
  const [checkBookingNo] = useLazyCheckBookingNoQuery();
  const [loadDialogOpen, setLoadDialogOpen] = useState<boolean>(false);
  const [contractList, setContractList] = useState<ContractDetailViewDto[]>([]);
  const [estimatedWeight, setEstimatedWeight] = useState<string>();
  const [getContractDetail] = useLazyGetContractQuery();
  const [createBooking] = useCreateBookingMutation();
  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setFocus,
    unregister,
  } = useForm<
    Omit<GenerateBookingDto, "numberOfContainer"> & {
      containerTypeDirectInput?: string;
      bookingFileMediaId?: MediaDto[];
      numberOfContainer?: string;
      docCutOffAt?: Dayjs;
      cargoCutOffAt?: Dayjs;
      docCutOffAtHour?: Dayjs;
      cargoCutOffAtHour?: Dayjs;
    }
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: bookingDefaultValue,
  });

  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [savedData, setSavedData] = useState<any>();
  const [tempSave] = useSaveTemporaryDataMutation();

  const aheadDayBetweenEstimationDay = dayjs(watch("etdAt") || null).isBefore(
    dayjs(watch("etaAt")),
    "day",
  )
    ? dayjs(watch("etdAt") || null)
    : dayjs(watch("etaAt") || null).isValid()
      ? dayjs(watch("etaAt") || null)
      : dayjs(watch("etdAt") || null);

  const tailDayBetweenCutOff = dayjs(watch("docCutOffAt") || null).isBefore(
    dayjs(watch("cargoCutOffAt") || null),
    "day",
  )
    ? dayjs(watch("cargoCutOffAt") || null)
    : dayjs(watch("docCutOffAt") || null).isValid()
      ? dayjs(watch("docCutOffAt") || null)
      : dayjs(watch("cargoCutOffAt") || null);

  const { isCommonCodeFetching, commonCodeCurrentData } =
    useGetCommonCodeListQuery(
      {
        codeNameList: [
          { codeName: "CONTAINER_TYPE" },
          {
            codeName: "PLACE_OF_DELIVERY",
            sort: "codeItemNameEn",
            order: "ASC",
          },
          { codeName: "SHIPPING_LINE", sort: "codeItemNameEn", order: "ASC" },
          { codeName: "MEASUREMENT" },
        ],
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ isFetching, currentData }) => {
          return {
            isCommonCodeFetching: isFetching,
            commonCodeCurrentData: currentData ?? [],
          };
        },
      },
    );

  const [
    containerTypeList = [],
    placeOfDeliveryList = [],
    shippingLineList = [],
    measurementList = [],
  ] = commonCodeCurrentData;

  const measurementListToOptionList = measurementList.reduce<OptionType[]>(
    commonCodeMapper,
    [],
  );
  const shippingLineListToOptionList = shippingLineList.reduce<OptionType[]>(
    commonCodeMapper,
    [],
  );
  const placeOfDeliveryListToOptionList = placeOfDeliveryList.reduce<
    OptionType[]
  >(commonCodeMapper, []);
  const containerTypeListToOptionList = containerTypeList.reduce<OptionType[]>(
    (acc, val) => {
      const resource = {
        label: val.codeItemNameEn,
        value: val.codeItemName,
      };
      return [...acc, { ...resource }];
    },
    [
      {
        label: t(DIRECT_INPUT_LABEL),
        value: DIRECT_INPUT_VALUE,
      },
    ],
  );

  const handleCreateBooking = async () => {
    try {
      handleContentLoadingOn();
      const valuesFromHookForm = getValues();
      const body = transformValueOrUndefined({
        targetObject: valuesFromHookForm,
        removeFieldKey: ["docCutOffAtHour", "cargoCutOffAtHour"],
      }) as GenerateBookingDto;
      const res = await createBooking({
        ...body,
        etaAt: dayjs(dayjs(body.etaAt).format("YYYY-MM-DD")).toISOString(),
        etdAt: dayjs(dayjs(body.etdAt).format("YYYY-MM-DD")).toISOString(),
        docCutOffAt: dayjs(body.docCutOffAt)
          .set(
            "hour",
            valuesFromHookForm.docCutOffAtHour
              ? valuesFromHookForm.docCutOffAtHour.get("hour")
              : 0,
          )
          .set("minute", valuesFromHookForm.docCutOffAtHour ? 0 : 30)
          .set("second", 0)
          .toISOString(),
        cargoCutOffAt: dayjs(body.cargoCutOffAt)
          .set(
            "hour",
            valuesFromHookForm.cargoCutOffAtHour
              ? valuesFromHookForm.cargoCutOffAtHour.get("hour")
              : 0,
          )
          .set("minute", valuesFromHookForm.cargoCutOffAtHour ? 0 : 30)
          .set("second", 0)
          .toISOString(),
        bookingFileMediaId: (body?.bookingFileMediaId as unknown as MediaDto[])
          ?.length
          ? (body.bookingFileMediaId as unknown as MediaDto[])?.[0].id
          : undefined,
        estimatedWeight: !isUndefined(estimatedWeight)
          ? Number(estimatedWeight.toString().replace(/,/g, ""))
          : null,
        numberOfContainer: Number(
          body.numberOfContainer.toString().replace(/,/g, ""),
        ),
        containerType:
          body.containerTypeCodeItemName === DIRECT_INPUT_VALUE
            ? (watch("containerTypeDirectInput") ?? "")
            : body.containerType,
        containerTypeCodeItemName:
          body.containerTypeCodeItemName === DIRECT_INPUT_VALUE
            ? undefined
            : body.containerTypeCodeItemName,
      }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("booking:add.alert.saveSuccess"),
      });
      if (res.row.id) {
        navigate(`${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${res.row.id}`);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  const handleLoadContractDialogOpen = () => setLoadDialogOpen(true);

  const handleContractDeleteClick = (id: number) => {
    setContractList((prev) => prev.filter((contract) => contract.id !== id));
    setValue(
      "contractIdList",
      watch("contractIdList").filter((contractId) => contractId !== id),
    );
  };

  const handleTempSave = async () => {
    try {
      await tempSave({
        storageTarget: "BOOKING",
        jsonData: { ...getValues() },
      }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("booking:add.alert.temporarySaveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleOverrideData = () => {
    let cargoCutOffAt = undefined;
    let docCutOffAt = undefined;
    let etaAt = undefined;
    let etdAt = undefined;

    if (savedData?.cargoCutOffAt)
      cargoCutOffAt = dayjs(savedData?.cargoCutOffAt);
    if (savedData?.docCutOffAt) docCutOffAt = dayjs(savedData?.docCutOffAt);
    if (savedData?.etaAt) etaAt = dayjs(savedData?.etaAt);
    if (savedData?.etdAt) etdAt = dayjs(savedData?.etdAt);

    if (savedData?.contractIdList.length) {
      Promise.all(
        savedData?.contractIdList.map((id: number) =>
          getContractDetail({ id }).unwrap(),
        ),
      )
        .then((res) => {
          setContractList(res.map(({ row }) => row));
        })
        .catch((e: any) => {
          const message = Array.isArray(e.data.message)
            ? e.data.message[0]
            : e.data.message;
          alert.showAlert({ type: "error", message });
        });
    }

    setFocus("bookingNo");

    reset({
      ...savedData,
      cargoCutOffAt,
      docCutOffAt,
      etaAt,
      etdAt,
    });
  };

  const renderBasicConfirmationInformation = () => {
    return (
      <SectionCard
        cardTitle={t("booking:add.common.bookingInformation")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <HalfFormItemContainer>
          <StyledFormItem
            label={t("common:bookingNo")}
            type={"text"}
            name={"bookingNo"}
            control={control as any}
            inputProps={{
              placeholder: t("booking:add.placeholder.bookingNo"),
              onBlur: () =>
                setValue("bookingNo", watch("bookingNo").toUpperCase()),
            }}
            rules={{
              required: true,
              validate: async (bookingNo) => {
                const checkResult = await checkBookingNo({ bookingNo });
                return !checkResult.isError;
              },
            }}
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
            }}
          />
          <StyledFormItem
            label={t("common:shippingLine")}
            rules={{
              required: true,
            }}
            type="singleSelect"
            control={control as any}
            name="shippingLineCodeItemName"
            inputContainerClassName="selector-size-m"
            inputProps={{
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              disabled: isCommonCodeFetching,
              placeholder: t("booking:add.placeholder.shippingLine"),
              filterOption: (input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()),
              onChange: (value: any, option: any) => {
                setValue("shippingLine", option.label as string);
              },
            }}
            options={shippingLineListToOptionList}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("common:vesselAndVoyageNo")}
            </StyledCustomLabel>
            <Flex style={{ gap: "8px" }}>
              <DoubleInputContainer>
                <Controller
                  name="vessel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        style={{ width: "50%", flexShrink: "0" }}
                        data-invalid={!!errors.vessel || !!errors.voyageNo}
                        placeholder={t("booking:add.placeholder.vessel")}
                        onBlur={() => {
                          field.onBlur();
                          setValue("vessel", watch("vessel").toUpperCase());
                        }}
                        onClear={() => setValue("vessel", "")}
                      />
                      <Input
                        {...register("voyageNo", { required: true })}
                        style={{ width: "50%" }}
                        data-invalid={!!errors.vessel || !!errors.voyageNo}
                        placeholder={t("booking:add.placeholder.voyageNo")}
                        onBlur={() =>
                          setValue("voyageNo", watch("voyageNo").toUpperCase())
                        }
                        onClear={() => setValue("voyageNo", "")}
                      />
                    </>
                  )}
                />
              </DoubleInputContainer>

              {(!!errors.vessel || !!errors.voyageNo) && (
                <InputError message={t("error:required")} />
              )}
            </Flex>
          </CustomFormItemRow>
          <StyledFormItem
            label={t("common:placeOfReceipt")}
            type="singleSelect"
            control={control as any}
            name="placeOfReceiptCodeItemName"
            rules={{ required: true }}
            options={placeOfDeliveryListToOptionList}
            inputContainerClassName="selector-size-m"
            inputProps={{
              disabled: isCommonCodeFetching,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              placeholder: t("booking:add.placeholder.port"),
              onChange: (value: any, option: any) => {
                setValue("placeOfReceipt", option.label as string);
                if (
                  !watch("portOfLoading") &&
                  !watch("portOfLoadingCodeItemName")
                ) {
                  setValue("portOfLoading", option.label as string);
                  setValue("portOfLoadingCodeItemName", value as string);
                }
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <StyledFormItem
            label={t("common:portOfLoading")}
            type="singleSelect"
            control={control as any}
            name="portOfLoadingCodeItemName"
            rules={{ required: true }}
            options={placeOfDeliveryListToOptionList}
            inputContainerClassName="selector-size-m"
            inputProps={{
              disabled: isCommonCodeFetching,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              placeholder: t("booking:add.placeholder.port"),
              onChange: (value: any, option: any) => {
                setValue("portOfLoading", option.label as string);
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <StyledFormItem
            label={t("common:portOfDischarge")}
            type="singleSelect"
            control={control as any}
            name="portOfDischargeCodeItemName"
            rules={{ required: true }}
            options={placeOfDeliveryListToOptionList}
            inputContainerClassName="selector-size-m"
            inputProps={{
              disabled: isCommonCodeFetching,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              placeholder: t("booking:add.placeholder.port"),
              onChange: (value: any, option: any) => {
                setValue("portOfDischarge", option.label as string);
                if (
                  !watch("placeOfDelivery") &&
                  !watch("placeOfDeliveryCodeItemName")
                ) {
                  setValue("placeOfDelivery", option.label as string);
                  setValue("placeOfDeliveryCodeItemName", value as string);
                }
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <StyledFormItem
            label={t("common:placeOfDelivery")}
            type="singleSelect"
            control={control as any}
            name="placeOfDeliveryCodeItemName"
            rules={{ required: true }}
            options={placeOfDeliveryListToOptionList}
            inputContainerClassName="selector-size-m"
            inputProps={{
              disabled: isCommonCodeFetching,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              placeholder: t("booking:add.placeholder.port"),
              onChange: (value: any, option: any) => {
                setValue("placeOfDelivery", option.label as string);
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
        </HalfFormItemContainer>
        <HalfFormItemContainer>
          <CustomFormItemRow>
            <StyledCustomLabel>{t("common:noOfContainer")}</StyledCustomLabel>
            <Flex style={{ gap: "8px" }}>
              <DoubleInputContainer>
                <Controller
                  name="numberOfContainer"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "50%" }}>
                        <Input
                          {...field}
                          type="text"
                          data-invalid={
                            !!errors.numberOfContainer ||
                            !!errors.containerTypeCodeItemName ||
                            !!errors.containerTypeDirectInput
                          }
                          placeholder={t(
                            "booking:add.placeholder.noOfContainer",
                          )}
                          onBlur={() => {
                            if (!watch("containerTypeCodeItemName")) {
                              setFocus("containerTypeCodeItemName");
                            }
                            field.onBlur();
                          }}
                          allowClear={false}
                          onInput={(e) => {
                            const input = e.target as HTMLInputElement;
                            // 한글 및 영문 제거
                            input.value = input.value.replace(
                              /[\u3131-\u318E\uAC00-\uD7A3a-zA-Z]/g,
                              "",
                            );
                          }}
                          onChange={(e) => {
                            const input = e.target as HTMLInputElement;
                            // 숫자 외의 문자를 제거하고, 3자리마다 콤마 추가
                            let value = input.value.replace(/[^0-9]/g, "");
                            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            input.value = value;
                            setValue("numberOfContainer", value);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
                <div style={{ width: "50%" }}>
                  <Controller
                    name="containerTypeCodeItemName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          style={{ width: "100%" }}
                          data-invalid={
                            !!errors.numberOfContainer ||
                            !!errors.containerTypeCodeItemName ||
                            !!errors.containerTypeDirectInput
                          }
                          options={containerTypeListToOptionList}
                          placeholder={t(
                            "booking:add.placeholder.containerType",
                          )}
                          virtual={false}
                          filterOption={(input, option) =>
                            ((option?.label as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase()) ||
                            ((option?.value as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase()) ||
                            option?.value === DIRECT_INPUT_VALUE
                          }
                          dropdownRender={(menu) => {
                            return (
                              <CustomDropdownWrapper>
                                {menu}
                              </CustomDropdownWrapper>
                            );
                          }}
                          suffixIcon={
                            isCommonCodeFetching ? <Loader /> : undefined
                          }
                          disabled={isCommonCodeFetching}
                          onChange={(value: any, option: any) => {
                            setValue(
                              "containerTypeCodeItemName",
                              option.value as string,
                            );
                            setValue("containerType", option.label as string);
                            if (value !== DIRECT_INPUT_VALUE) {
                              unregister("containerTypeDirectInput");
                            } else {
                              setTimeout(() => {
                                setFocus("containerTypeDirectInput");
                              }, 0);
                            }
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </DoubleInputContainer>
              {watch("containerTypeCodeItemName") === DIRECT_INPUT_VALUE && (
                <div
                  style={{
                    width: "calc(50% - 4px)",
                    alignSelf: "flex-end",
                  }}
                >
                  <ContainerDirectInput
                    {...register("containerTypeDirectInput", {
                      required:
                        watch("containerTypeCodeItemName") ===
                        DIRECT_INPUT_VALUE,
                    })}
                    data-invalid={
                      !!errors.numberOfContainer ||
                      !!errors.containerType ||
                      !!errors.containerTypeDirectInput
                    }
                    onClear={() => {
                      setValue("containerTypeDirectInput", undefined);
                    }}
                  />
                </div>
              )}
              {(!!errors.numberOfContainer ||
                !!errors.containerType ||
                !!errors.containerTypeDirectInput) && (
                <InputError message={t("error:required")} />
              )}

              <WarningDescription>
                <Icon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("booking:add.containerInfo")}
                </Typo>
              </WarningDescription>
            </Flex>
          </CustomFormItemRow>

          <StyledFormItem
            label="ETD"
            type="date"
            control={control as any}
            name="etdAt"
            rules={{ required: true }}
            inputProps={{
              showToday: false,
              disabledDate: (etdAt) => {
                if (tailDayBetweenCutOff.isValid()) {
                  if (dayjs(watch("etaAt")).isValid()) {
                    return (
                      tailDayBetweenCutOff.isAfter(etdAt, "day") ||
                      dayjs(watch("etaAt")).isBefore(etdAt, "day")
                    );
                  }
                  return tailDayBetweenCutOff.isAfter(etdAt, "day");
                }
                return dayjs(watch("etaAt")).isBefore(etdAt, "day");
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <StyledFormItem
            label="ETA"
            type="date"
            control={control as any}
            name="etaAt"
            rules={{ required: true }}
            inputProps={{
              showToday: false,
              disabledDate: (etaAt) => {
                if (tailDayBetweenCutOff.isValid()) {
                  if (dayjs(watch("etdAt")).isValid()) {
                    return (
                      dayjs(watch("etdAt")).isAfter(etaAt, "day") ||
                      tailDayBetweenCutOff.isAfter(etaAt, "day")
                    );
                  }
                  return tailDayBetweenCutOff.isAfter(etaAt, "day");
                }
                return dayjs(watch("etdAt")).isAfter(etaAt, "day");
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />

          <CustomFormItemRow>
            <StyledCustomLabel isNotRequired>
              <DocCutOffLabel>
                {`Doc Cut-Off\n(VGM Cut-Off)`} <strong>*</strong>
              </DocCutOffLabel>
            </StyledCustomLabel>
            <Flex style={{ gap: "8px" }}>
              <DoubleInputContainer>
                <AntdDatePickerWrapper data-invalid={!!errors.docCutOffAt}>
                  <Controller
                    name="docCutOffAt"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ConfigProvider
                        theme={{
                          components: {
                            DatePicker: {
                              fontSize: 16,
                            },
                          },
                        }}
                      >
                        <DatePicker
                          {...field}
                          style={{ width: "100%" }}
                          data-invalid={!!errors.docCutOffAt}
                          showToday={false}
                          disabledDate={(docCutOff) => {
                            if (aheadDayBetweenEstimationDay.isValid()) {
                              return aheadDayBetweenEstimationDay.isBefore(
                                docCutOff,
                                "day",
                              );
                            }
                            return false;
                          }}
                        />
                      </ConfigProvider>
                    )}
                  />
                </AntdDatePickerWrapper>
                <AntdDatePickerWrapper>
                  <Controller
                    name="docCutOffAtHour"
                    control={control}
                    render={({ field }) => {
                      return (
                        <ConfigProvider
                          theme={{
                            components: {
                              DatePicker: {
                                fontSize: 16,
                                colorPrimary: colorSet.blue4,
                                colorText: colorSet.gray2,
                              },
                            },
                          }}
                        >
                          <StyledTimePicker
                            {...field}
                            placeholder={t("placeholder:optionalTimeHour")}
                            changeOnBlur
                            showNow={false}
                            format={"HH:mm"}
                            showMinute={false}
                            suffixIcon={<TimePickerSuffixIcon url={ClockSvg} />}
                          />
                        </ConfigProvider>
                      );
                    }}
                  />
                </AntdDatePickerWrapper>
              </DoubleInputContainer>

              {!!errors.docCutOffAt && (
                <InputError message={t("error:required")} />
              )}
            </Flex>
          </CustomFormItemRow>

          <CustomFormItemRow>
            <StyledCustomLabel>{t("common:cargoCutOff")}</StyledCustomLabel>
            <Flex style={{ gap: "8px" }}>
              <DoubleInputContainer>
                <AntdDatePickerWrapper data-invalid={!!errors.cargoCutOffAt}>
                  <Controller
                    name="cargoCutOffAt"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ConfigProvider
                        theme={{
                          components: {
                            DatePicker: {
                              fontSize: 16,
                            },
                          },
                        }}
                      >
                        <DatePicker
                          {...field}
                          style={{ width: "100%" }}
                          data-invalid={!!errors.cargoCutOffAt}
                          showToday={false}
                          disabledDate={(cargoCutOff) => {
                            if (aheadDayBetweenEstimationDay.isValid()) {
                              return aheadDayBetweenEstimationDay.isBefore(
                                cargoCutOff,
                                "day",
                              );
                            }
                            return false;
                          }}
                        />
                      </ConfigProvider>
                    )}
                  />
                </AntdDatePickerWrapper>
                <AntdDatePickerWrapper>
                  <Controller
                    name="cargoCutOffAtHour"
                    control={control}
                    render={({ field }) => {
                      return (
                        <ConfigProvider
                          theme={{
                            components: {
                              DatePicker: {
                                fontSize: 16,
                                colorPrimary: colorSet.blue4,
                                colorText: colorSet.gray2,
                              },
                            },
                          }}
                        >
                          <StyledTimePicker
                            {...field}
                            placeholder={t("placeholder:optionalTimeHour")}
                            changeOnBlur
                            showNow={false}
                            showMinute={false}
                            format={"HH:mm"}
                            suffixIcon={<TimePickerSuffixIcon url={ClockSvg} />}
                          />
                        </ConfigProvider>
                      );
                    }}
                  />
                </AntdDatePickerWrapper>
              </DoubleInputContainer>

              {!!errors.cargoCutOffAt && (
                <InputError message={t("error:required")} />
              )}
            </Flex>
          </CustomFormItemRow>

          <StyledFormItem
            label={t("common:cfsCyCode")}
            type={"text"}
            name={"cfs"}
            control={control as any}
            inputProps={{
              placeholder: t("booking:add.placeholder.cfs"),
            }}
            rules={{
              required: true,
            }}
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
            }}
          />

          <CustomFormItemRow>
            <StyledCustomLabel isNotRequired>
              {t("common:estimatedWeight")}
            </StyledCustomLabel>
            <DoubleInputContainer className="fixed-width-container">
              <Controller
                name="estimatedWeightUnit"
                control={control}
                render={({ field }) => (
                  <Flex>
                    <DoubleInputContainer>
                      <StyledSelect
                        {...field}
                        data-invalid={false}
                        style={{ width: "108px", flexShrink: "0" }}
                        value={watch("estimatedWeightUnitCodeItemName")}
                        options={measurementListToOptionList}
                        onChange={(_, option: any) => {
                          setValue("estimatedWeightUnit", option.label);
                          setValue(
                            "estimatedWeightUnitCodeItemName",
                            option.value,
                          );
                        }}
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                      />
                      <Input
                        value={estimatedWeight}
                        style={{ flex: "1", width: "1%" }}
                        allowClear={false}
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          // 한글 및 영문 제거
                          input.value = input.value.replace(
                            /[\u3131-\u318E\uAC00-\uD7A3a-zA-Z]/g,
                            "",
                          );
                        }}
                        placeholder={t(
                          "booking:add.placeholder.estimateWeight",
                        )}
                        onChange={(e) => {
                          const input = e.target as HTMLInputElement;
                          // 숫자 외의 문자를 제거하고, 3자리마다 콤마 추가
                          let value = input.value.replace(/[^0-9]/g, "");
                          value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          input.value = value;
                          setEstimatedWeight(isNull(value) ? undefined : value);
                        }}
                      />
                    </DoubleInputContainer>
                  </Flex>
                )}
              />
            </DoubleInputContainer>
          </CustomFormItemRow>
        </HalfFormItemContainer>
      </SectionCard>
    );
  };

  const renderAttachmentFile = () => {
    return (
      <StyledSectionCard cardTitle={t("booking:add.common.attachmentFile")}>
        <FlexGapDiv>
          <StyledFormItem
            label={t("booking:add.common.bookingFile")}
            type="file"
            name="bookingFileMediaId"
            control={control as any}
            inputProps={{
              defaultFileList: watch("bookingFileMediaId")?.length
                ? [
                    new File(
                      [""],
                      watch("bookingFileMediaId")?.[0]?.originalFileName ?? "",
                    ),
                  ]
                : undefined,
              onRemove: () => setValue("bookingFileMediaId", undefined),
            }}
          />
        </FlexGapDiv>
      </StyledSectionCard>
    );
  };

  const renderBookingRemark = () => {
    return (
      <StyledSectionCard cardTitle={t("booking:add.common.bookingRemark")}>
        <FormItem
          type="textarea"
          name="bookingRemark"
          control={control}
          inputProps={{
            style: { width: "100%" },
            placeholder: t("booking:add.placeholder.bookingRemark"),
            maxLength: 1000,
          }}
          noLabel
        />
      </StyledSectionCard>
    );
  };

  const renderMemo = () => {
    return (
      <StyledSectionCard
        cardTitle={t("booking:add.common.memo")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <MemoHalfContainer>
          <MemoPlaceholder>
            <Icon iconSrc={NoDataBagSvg} iconSize={56} />
            <Typo as="p" typoType="b7m" color="gray8">
              {t("booking:add.common.noData")}
            </Typo>
          </MemoPlaceholder>
        </MemoHalfContainer>
        <MemoHalfContainer>
          <MemoInputContainer>
            <WarningDescription style={{ color: colorSet.gray6 }}>
              <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
              {t("booking:add.memoInfo")}
            </WarningDescription>
            <FormItem
              type="textarea"
              name="memo"
              control={control}
              inputProps={{
                style: { width: "100%" },
                placeholder: t("booking:add.placeholder.memo"),
                maxLength: 500,
              }}
              noLabel
            />
          </MemoInputContainer>
        </MemoHalfContainer>
      </StyledSectionCard>
    );
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.SAVED_DATA) {
      return (
        <AlertDialog
          open
          title={t("booking:add.alertDialog.loadTemporarySave.title")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            setFocus("bookingNo");
          }}
          onOk={() => {
            handleOverrideData();
            setAlertDialogState(AlertDialogState.NULL);
          }}
          okText={t("booking:add.common.ok")}
          cancelText={t("booking:add.common.exit")}
        >
          {t("booking:add.alertDialog.loadTemporarySave.description")}
        </AlertDialog>
      );
    }
    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t("booking:add.alertDialog.backToPrevious.title")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => navigate(-1)}
          okText={t("booking:add.common.ok")}
          cancelText={t("booking:add.common.exit")}
        >
          {t("booking:add.alertDialog.backToPrevious.description")}
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    if (contractIdFromContractPage) return;
    (async () => {
      try {
        const data = await getSavedData({ storageTarget: "BOOKING" }).unwrap();
        if (data) {
          setAlertDialogState(AlertDialogState.SAVED_DATA);
          setSavedData(data.row.jsonData);
        }
      } catch (e) {
        setFocus("bookingNo");
      }
    })();
  }, [contractIdFromContractPage, getSavedData, reset, setFocus]);

  useEffect(
    () => {
      if (contractIdFromContractPage) {
        (async () => {
          try {
            handleContentLoadingOn();
            const contractDetail = await getContractDetail({
              id: Number(contractIdFromContractPage),
            }).unwrap();
            setContractList((prev) => prev.concat(contractDetail.row));
            setValue("contractIdList", [Number(contractIdFromContractPage)]);
          } catch (e: any) {
            const message = Array.isArray(e.data.message)
              ? e.data.message[0]
              : e.data.message;
            alert.showAlert({ type: "error", message });
          } finally {
            handleContentLoadingOff();
          }
        })();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <ExporterMainLayout
        breadcrumb={[t("sideNav:booking"), t("sideNav:bookingRegistration")]}
        customPageTitle={
          <HeaderInnerDiv>
            <h1>{t("sideNav:bookingRegistration")}</h1>
          </HeaderInnerDiv>
        }
        pageTitle={t("sideNav:contractRegistration")}
      >
        <ContentSection>
          <WarningDescription>
            <Icon iconSrc={InfoSvg} iconSize={16} />
            {t("booking:add.bookingAddInfo")}
          </WarningDescription>

          <SectionCard
            cardTitle={t("booking:add.common.contractInformation")}
            rightAccessory={
              contractList.length >= 2 && (
                <Button
                  buttonSize={32}
                  buttonColor="red"
                  buttonGrade="secondary"
                  onClick={() => {
                    setValue("contractIdList", []);
                    setContractList([]);
                  }}
                >
                  <ButtonContent>
                    <Icon iconSrc={CancelRedSvg} />
                    {t("booking:add.buttonLabel.allDelete")}
                  </ButtonContent>
                </Button>
              )
            }
          >
            {contractList?.map((contract, idx, origin) => {
              return (
                <LoadContractInformationCard
                  key={contract.id}
                  id={contract.id}
                  isLastItem={origin.length === idx + 1}
                  contractDetail={contract}
                  onLoadButtonClick={handleLoadContractDialogOpen}
                  onDeleteButtonClick={(id) => {
                    handleContractDeleteClick(id);
                  }}
                />
              );
            })}

            <PlaceholderContainer>
              {contractList.length === 0 && (
                <Typo as="p" typoType="b7r" color="gray6">
                  {t("booking:add.card.loadContract.placeholder")}
                </Typo>
              )}
              <Button
                buttonColor="blue"
                buttonGrade="secondary"
                onClick={handleLoadContractDialogOpen}
              >
                <ButtonContent>
                  {contractList.length !== 0 && <AddPlusIcon />}
                  {contractList.length === 0
                    ? t("booking:add.buttonLabel.loadContract")
                    : t("booking:add.buttonLabel.addContract")}
                </ButtonContent>
              </Button>
            </PlaceholderContainer>
          </SectionCard>
          <StyledForm onSubmit={handleSubmit(handleCreateBooking)}>
            {renderBasicConfirmationInformation()}

            <Section>
              {renderAttachmentFile()}
              {renderBookingRemark()}
            </Section>
            {renderMemo()}

            <BottomFixedContainer>
              <FooterButtonSection>
                <Button
                  buttonGrade="tertiary"
                  buttonColor="black"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  onClick={() =>
                    setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS)
                  }
                >
                  <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
                  {t("booking:add.buttonLabel.backToPrevious")}
                </Button>

                <SaveButtonContainer>
                  <Button
                    buttonGrade="secondary"
                    buttonColor="blue"
                    onClick={handleTempSave}
                  >
                    {t("booking:add.buttonLabel.temporarySave")}
                  </Button>
                  <Button
                    buttonGrade="primary"
                    style={{ width: 158 }}
                    type="submit"
                    disabled={isCreateDisabled}
                  >
                    {t("booking:add.buttonLabel.save")}
                  </Button>
                </SaveButtonContainer>
              </FooterButtonSection>
            </BottomFixedContainer>
          </StyledForm>
        </ContentSection>
        {loadDialogOpen && (
          <LoadContractsMultipleSelectDialog
            open
            onClose={() => setLoadDialogOpen(false)}
            excludeIdList={contractList.map(({ id }) => id)}
            onFetchContractViaId={(detail) => {
              setValue(
                "contractIdList",
                watch("contractIdList").concat(detail.id),
              );
              setContractList((prev) => [...prev, detail]);
            }}
          />
        )}
        {renderAlertDialog()}
      </ExporterMainLayout>
    </>
  );
};

export default ExporterBookingAddPage;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const StyleButton = styled(Button)`
  ${typo.btn3m}
`;

const HeaderInnerDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  h1 {
    ${typo.h1}
  }

  ${StyleButton} {
    flex-shrink: 0;
    margin-left: auto;
  }
`;

const WarningDescription = styled.p`
  ${typo.b9r};
  color: ${colorSet.gray4};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const HalfFormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
  gap: 24px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.selector-size-s {
    width: calc(100% - 164px - 16px - 100px);
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
  .ant-select-selection-placeholder {
    width: 1px;
  }
`;

const CustomLabel = styled.p<{ isNotRequired?: boolean }>`
  width: 164px;
  color: ${colorSet.gray6};
  ${typo.b9r};
  flex-shrink: 0;

  ${({ isNotRequired }) =>
    !isNotRequired &&
    css`
      &::after {
        content: " *";
        color: ${colorSet.red2};
      }
    `};
`;

const StyledCustomLabel = styled(CustomLabel)`
  color: ${colorSet.gray1};
  ${typo.b7m};
`;

const CustomFormItemRow = styled.div`
  display: flex;
  gap: 8px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const DoubleInputContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  &.fixed-width-container {
    width: calc(100% - 172px);

    ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
      width: 100%;
    }
  }
`;

const StyledSectionCard = styled(SectionCard)`
  flex: 1;
`;

const Section = styled.section`
  display: flex;
  gap: 16px;
`;

const MemoHalfContainer = styled.div`
  flex: 1;
`;

const MemoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const MemoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FlexGapDiv = styled(Flex)`
  gap: 24px;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const StyledFormItem = styled(FormItem)`
  .ant-select-selection-placeholder {
    width: 1px;
  }
  .selector-size-m {
    width: calc(100% - 164px - 8px);
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;

    .selector-size-m {
      width: 100%;
    }
  }
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const ContainerDirectInput = styled(Input)`
  width: calc(50% - 4px);
  align-self: flex-end;
`;

const DocCutOffLabel = styled.p`
  white-space: pre-wrap;
  width: 164px;
  flex-shrink: 0;
  display: flex;
  strong {
    color: ${colorSet.red2};
  }
  ${typo.b7m}
`;

const TimePickerSuffixIcon = styled.span<{ url: string }>`
  display: inline-block;
  width: 20px;
  height: 20px;

  ${({ url }) => css`
    background: url(${url});
  `};
`;

const AntdDatePickerWrapper = styled.div`
  width: 50%;

  &[data-invalid="true"] {
    .ant-picker {
      border: 1px solid ${colorSet.red2};
    }
  }
`;

const CustomDropdownWrapper = styled.div`
  div.ant-select-item {
    &:first-of-type {
      color: ${colorSet.blue4};

      &::before {
        content: url(${AddBlueSvg});
        width: 18px;
        height: 18px;
        position: relative;
        margin-right: 8px;
        top: 4px;
      }
    }
  }
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const AddPlusIcon = styled(AddPlusSvg)`
  path {
    fill: ${colorSet.indigo};
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
