import { isUndefined } from "@/src/utils/is";
import { useContext } from "react";
import { NormalPlanSignupContext } from ".";

export const useNormalPlanSignupContext = () => {
  const context = useContext(NormalPlanSignupContext);

  if (isUndefined(context)) {
    throw Error("Normal Plan Signup Context가 존재 하지 않습니다.");
  }

  return context;
};
