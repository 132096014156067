import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import colorSet from "@/src/styles/color";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

interface BookingContractCardItemProps {
  data: {
    freetime?: string;
    id: number;
    importerItemCode?: string;
    lcNo?: string;
    paymentTerms: string;
    paymentTermsRemark?: string;
    poNo?: string;
    quantity: number;
    quantityUnit: string;
    scNo?: string;
    unitPrice: number;
    unitPriceUnit: string;
  };
  index: number;
}

function BookingContractCardItem({
  data,
  index,
}: BookingContractCardItemProps) {
  const {
    freetime,
    id,
    importerItemCode,
    lcNo,
    paymentTerms,
    paymentTermsRemark,
    poNo,
    scNo,
    unitPrice,
    unitPriceUnit,
  } = data;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderContractContent = () => {
    return (
      <ContractContainer>
        <Title typoType="h4">
          <>
            {t("shipment:importer.detail.label.contract")} {""}
            {addZeroPrefix(Number(index + 1))}
          </>
        </Title>
        <CardContent>
          <FlexColumn>
            <SectionCardRow
              label={t("common:poNo")}
              value={
                <BlueText
                  typoType="h7"
                  color="systemBlue2"
                  onClick={() => {
                    scNo &&
                      id &&
                      navigate(
                        `${IMPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${id}`,
                      );
                  }}
                >
                  {scNo ?? "-"}
                </BlueText>
              }
            />
            <SectionCardRow
              label={t("common:scNo")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {poNo ?? "-"}
                </BreakWordTypo>
              }
            />

            <SectionCardRow
              label={t("shipment:importer.detail.label.itemCode")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {importerItemCode ?? "-"}
                </BreakWordTypo>
              }
            />
            <SectionCardRow
              label={t("shipment:importer.detail.label.unitPrice")}
              value={
                unitPrice && unitPriceUnit ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${Number(unitPrice).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${unitPriceUnit}`}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
          </FlexColumn>

          <GrayLine />

          <FlexColumn>
            <SectionCardRow
              label={t("common:paymentTerms")}
              value={
                paymentTerms ? (
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {`${paymentTerms} ${
                      paymentTermsRemark ? paymentTermsRemark : ""
                    }
                      `}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            {paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <SectionCardRow
                label={t("common:lcNo")}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {lcNo ?? "-"}
                  </BreakWordTypo>
                }
              />
            )}

            <SectionCardRow
              label={t("common:freetime")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5">
                  {freetime ?? "-"}
                </BreakWordTypo>
              }
            />
          </FlexColumn>
        </CardContent>
      </ContractContainer>
    );
  };

  return (
    <BookingContractCardContainer>
      {/* Contract */}
      {renderContractContent()}
    </BookingContractCardContainer>
  );
}

export default BookingContractCardItem;

const BookingContractCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContractContainer = styled.section`
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${colorSet.gray9};

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  padding: 24px;
  flex: 1;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${colorSet.gray9};
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
  word-break: break-word;
`;

const Title = styled(Typo)`
  min-width: 120px;
`;
