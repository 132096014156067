import BarChart from "@/src/components/atom/chart/BarChart";
import DoughnutChart from "@/src/components/atom/chart/DoughnutChart";
import Dot from "@/src/components/atom/Dot";
import Typo from "@/src/components/atom/Typo";
import colorSet, { ColorType } from "@/src/styles/color";
import { useState } from "react";
import styled, { css } from "styled-components";
import { BAR_TEAL_COLOR_SET, sortStackedBarChartPlugin } from "../util/chart";

import { Button } from "@/src/components/atom/Button";
import Select from "@/src/components/atom/Select";
import TabItem from "@/src/components/molecule/TabItem";
import {
  useGetBuyerTopRankStatisticInfoByItemCodeQuery,
  useGetBuyerTopRankStatisticInfoByItemQuery,
  useGetBuyerTopRankStatisticInfoQuery,
} from "@/src/store/apis/dashboard";
import {
  BuyerTopRankByItemCodeDto,
  BuyerTopRankByItemDto,
} from "@/src/store/apis/dashboard/interface";
import typo from "@/src/styles/typography";
import { isUndefined } from "@/src/utils/is";
import { ChartData } from "chart.js";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import BuyerItemCodeRank from "./dialog/BuyerItemCodeRank";
import BuyerItemRank from "./dialog/BuyerItemRank";

interface BuyerStatisticsProps {
  tab: "item" | "itemCode";
  category: string;
  isCategoryFetching: boolean;
  categoryList: { label: string; value: string }[];
  period: [Dayjs, Dayjs];
  onTabChange: (tab: BuyerStatisticsProps["tab"]) => void;
  onCategoryChange: (value: string) => void;
}

const buyerChartColor = [
  colorSet.chartAqua1,
  colorSet.chartAqua2,
  colorSet.chartAqua3,
  colorSet.chartAqua4,
  colorSet.chartAqua5,
];
const buyerChartStringifyColor = [
  "chartAqua1",
  "chartAqua2",
  "chartAqua3",
  "chartAqua4",
  "chartAqua5",
];

const ALL = "ALL";
const emptyArray: any[] = [];

enum DialogState {
  NULL,
  BUYER_ITEM_RANK,
  BUYER_ITEM_CODE_RANK,
}

const BuyerStatistics = ({
  tab,
  category,
  categoryList,
  period,
  onTabChange,
  onCategoryChange,
}: BuyerStatisticsProps) => {
  const { t } = useTranslation();
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  const { topRankList, buyerTopRankTotalWeight } =
    useGetBuyerTopRankStatisticInfoQuery(
      {
        etdAtFrom: period[0].startOf("day").toISOString(),
        etdAtTo: period[1].endOf("day").toISOString(),
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isFetching || isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            topRankList: isStable ? currentData.row.buyerTopRankList : [],
            buyerTopRankTotalWeight: isStable
              ? currentData.row.buyerTopRankTotalWeight
              : "-",
          };
        },
      },
    );

  const { buyerTopRankListByItem } = useGetBuyerTopRankStatisticInfoByItemQuery(
    {
      etdAtFrom: period[0].startOf("day").toISOString(),
      etdAtTo: period[1].endOf("day").toISOString(),
      mainCategoryCodeItemName: category === ALL ? undefined : category,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: tab === "itemCode",
      selectFromResult: ({ isFetching, isError, currentData }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          buyerTopRankListByItem: isStable
            ? currentData.row.buyerTopRankListByItem
            : (emptyArray as BuyerTopRankByItemDto[]),
        };
      },
    },
  );

  const { buyerTopRankListByItemCode } =
    useGetBuyerTopRankStatisticInfoByItemCodeQuery(
      {
        etdAtFrom: period[0].startOf("day").toISOString(),
        etdAtTo: period[1].endOf("day").toISOString(),
        mainCategoryCodeItemName: category === ALL ? undefined : category,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: tab === "item",
        selectFromResult: ({ isFetching, isError, currentData }) => {
          const isUnstable = isFetching || isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            buyerTopRankListByItemCode: isStable
              ? currentData.row.buyerTopRankListByItemCode
              : (emptyArray as BuyerTopRankByItemCodeDto[]),
          };
        },
      },
    );

  const buyerTopFiveData = topRankList.reduce<{
    labels: string[];
    data: number[];
  }>(
    (acc, val) => ({
      labels: [...acc.labels, val.buyerNameCode],
      data: [...acc.data, Number(val.totalNetWeight)],
    }),
    {
      labels: [],
      data: [],
    },
  );

  const topFiveBuyerIndex: {
    chipColor: ColorType;
    label: string;
    value: string;
  }[] = topRankList.map(
    (
      {
        buyerNameCode,
        totalNetWeightPercent,
        totalNetWeight,
        totalNetWeightUnit,
      },
      index,
    ) => {
      return {
        chipColor: buyerChartStringifyColor[index] as ColorType,
        label: buyerNameCode,
        value: `${Number(
          totalNetWeight,
        ).toLocaleString()} ${totalNetWeightUnit} (${totalNetWeightPercent}%)`,
      };
    },
  );

  const datasetByItem = buyerTopRankListByItem.reduce<
    {
      exporterItemId: number;
      data: (number | null)[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      label: string;
    }[]
  >((acc, val, idx) => {
    val.transactionInfoListByItem.forEach(
      ({ exporterItemId, exporterItem, netWeight }) => {
        const colorIndex = acc.filter(
          ({ exporterItemId }) => exporterItemId !== 0,
        ).length;
        const item = acc.find(
          (stack) => stack.exporterItemId === exporterItemId,
        );

        if (item) {
          const data = item.data;
          const nullishData = data.map((item) => (!item ? null : item));
          nullishData[idx] = Number(netWeight);
          acc = acc.map((current) =>
            current.exporterItemId === exporterItemId
              ? {
                  ...current,
                  data: nullishData,
                }
              : current,
          );
        } else {
          const dataArray = [];
          dataArray[idx] = Number(netWeight);
          acc.push({
            exporterItemId,
            data: dataArray,
            backgroundColor:
              exporterItemId === 0
                ? colorSet.chartEtc
                : BAR_TEAL_COLOR_SET[colorIndex],
            borderColor: "white",
            borderWidth: 0.7,
            maxBarThickness: 64,
            label: exporterItem,
          });
        }
      },
    );

    return acc;
  }, []);

  const datasetByItemCode = buyerTopRankListByItemCode.reduce<
    {
      exporterItemCodeId: number;
      data: (number | null)[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      label: string;
    }[]
  >((acc, val, idx) => {
    val.transactionInfoListByItemCode.forEach(
      ({ exporterItemCodeId, exporterItemCode, netWeight }) => {
        const colorIndex = acc.filter(
          ({ exporterItemCodeId }) => exporterItemCodeId !== 0,
        ).length;
        const item = acc.find(
          (stack) => stack.exporterItemCodeId === exporterItemCodeId,
        );

        if (item) {
          const data = item.data;
          const nullishData = data.map((item) => (!item ? null : item));
          nullishData[idx] = Number(netWeight);
          acc = acc.map((current) =>
            current.exporterItemCodeId === exporterItemCodeId
              ? {
                  ...current,
                  data: nullishData,
                }
              : current,
          );
        } else {
          const dataArray = [];
          dataArray[idx] = Number(netWeight);
          acc.push({
            exporterItemCodeId,
            data: dataArray,
            backgroundColor:
              exporterItemCodeId === 0
                ? colorSet.chartEtc
                : BAR_TEAL_COLOR_SET[colorIndex],
            borderColor: "white",
            borderWidth: 0.7,
            maxBarThickness: 64,
            label: exporterItemCode,
          });
        }
      },
    );

    return acc;
  }, []);

  const handleChange = async ({ tab }: { tab: "item" | "itemCode" }) => {
    onTabChange(tab);
  };

  const dataset =
    tab === "item"
      ? datasetByItem.map((item) => {
          const { exporterItemId, ...rest } = item;
          return rest;
        })
      : datasetByItemCode.map((item) => {
          const { exporterItemCodeId, ...rest } = item;
          return rest;
        });

  const labels =
    tab === "item"
      ? buyerTopRankListByItem.map(({ buyerNameCode }) => buyerNameCode)
      : buyerTopRankListByItemCode.map(({ buyerNameCode }) => buyerNameCode);

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) {
      return null;
    }

    if (dialogState === DialogState.BUYER_ITEM_RANK) {
      return (
        <BuyerItemRank
          from={period[0]}
          to={period[1]}
          mainCategoryCodeItemName={category === ALL ? undefined : category}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.BUYER_ITEM_CODE_RANK) {
      return (
        <BuyerItemCodeRank
          from={period[0]}
          to={period[1]}
          mainCategoryCodeItemName={category === ALL ? undefined : category}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  return (
    <Flex gap={16}>
      <Flex gap={16} direction="column">
        <div>
          <DoughnutChart
            data={{
              labels: buyerTopFiveData.labels,
              datasets: [
                {
                  data: buyerTopFiveData.data,
                  backgroundColor: buyerChartColor,
                  borderRadius: buyerTopFiveData.data.length === 1 ? 0 : 4,
                  borderColor:
                    buyerTopFiveData.data.length === 1
                      ? colorSet.chartAqua1
                      : undefined,
                },
              ],
            }}
            height={300}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  bodyColor: colorSet.gray8,
                  titleColor: colorSet.gray10,
                  bodyFont: {
                    size: 14,
                  },
                  xAlign: "left",
                  yAlign: "center",
                  callbacks: {
                    title: (chartItem) => chartItem[0].dataset.label,
                    label: (a) => {
                      return `${a.formattedValue} MT`;
                    },
                  },
                },
                title: {
                  display: true,
                  color: colorSet.gray5,
                  padding: {
                    top: 24,
                    bottom: 32,
                  },
                  font: {
                    size: 15,
                  },
                  text: t("dashboard:exporter.transactionVolumeTop5ByBuyer"),
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
        <p>
          <Typo typoType="h7">{t("common:total")}</Typo>{" "}
          <Typo typoType="h4">{buyerTopRankTotalWeight}</Typo>
        </p>

        <StyledList>
          {topFiveBuyerIndex.map(({ chipColor, label, value }) => {
            return (
              <ListItem key={label}>
                <LeftListItem>
                  <Flex gap={4}>
                    <Dot dotColor={chipColor} />
                    <Typo typoType="b10r" color="gray6">
                      {label}
                    </Typo>
                  </Flex>
                </LeftListItem>

                <RightListItem>
                  <Typo typoType="b10r">{value}</Typo>
                </RightListItem>
              </ListItem>
            );
          })}
        </StyledList>
      </Flex>

      <FullWidthDiv>
        <SpaceBetween>
          <Tabs>
            <StyledTabItem
              tabValue={"item"}
              tabIndex={tab === "item" ? 0 : -1}
              onFocus={() => handleChange({ tab: "item" })}
              onClick={() => handleChange({ tab: "item" })}
              data-selected={tab === "item"}
            >
              {t("common:item")}
            </StyledTabItem>
            <StyledTabItem
              tabValue={"itemCode"}
              tabIndex={tab === "itemCode" ? 0 : -1}
              onFocus={() => handleChange({ tab: "itemCode" })}
              onClick={() => handleChange({ tab: "itemCode" })}
              data-selected={tab === "itemCode"}
            >
              {t("common:itemCode")}
            </StyledTabItem>
          </Tabs>

          <Flex gap={8}>
            <StyledLabel>
              <Typo typoType="b10r">{t("common:category")}</Typo>

              <StyledSelect
                size="middle"
                value={category}
                options={categoryList}
                onChange={(value) => onCategoryChange(value)}
              />
            </StyledLabel>
          </Flex>
        </SpaceBetween>

        <Flex gap={8} direction="column" style={{ width: "100%" }}>
          <ChartTitleDiv>
            <Typo color="gray5" typoType="h8">
              {t("dashboard:exporter.top5BuyersByVolume")} (
              {tab === "item" ? t("common:item") : t("common:itemCode")}{" "}
              {t("dashboard:exporter.basis")})
            </Typo>

            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() => {
                if (tab === "item") {
                  setDialogState(DialogState.BUYER_ITEM_RANK);
                } else {
                  setDialogState(DialogState.BUYER_ITEM_CODE_RANK);
                }
              }}
            >
              {t("dashboard:exporter.moreDetails")}
            </Button>
          </ChartTitleDiv>

          <div style={{ width: "100%" }}>
            <TransactionVolumeChart
              datasets={dataset}
              labels={labels}
              displayY={false}
            />
          </div>
        </Flex>
      </FullWidthDiv>
      {renderDialog()}
    </Flex>
  );
};

export default BuyerStatistics;

const Flex = styled.div<{ gap: number; direction?: "column" | "row" }>`
  display: flex;
  align-items: center;
  ${({ direction }) =>
    direction
      ? css`
          flex-direction: ${direction};
        `
      : undefined};

  ${({ gap }) => css`
    gap: ${gap}px;
  `};
`;

const FullWidthDiv = styled.div`
  width: calc(100% - 316px);
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px 16px 8px;
`;

const StyledList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftListItem = styled.div`
  flex: 1;
`;
const RightListItem = styled.div`
  width: 124px;
  text-align: right;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const ChartTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 16px;
`;

const Tabs = styled.div`
  display: flex;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  width: 98px;
  cursor: pointer;
  background: ${colorSet.gray11};
  color: ${colorSet.gray7};
  border: 1px solid ${colorSet.gray9};
  ${typo.b9m};

  &[data-selected="true"] {
    background: ${colorSet.white};
    color: ${colorSet.blue4};
    border: 1px solid ${colorSet.blue4};
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0px;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface BuyerChartProps {
  datasets?: ChartData<"bar">["datasets"];
  labels?: ChartData<"bar">["labels"];
  displayY?: boolean;
}

export const TransactionVolumeChart = ({
  datasets = [],
  labels,
  displayY = true,
}: BuyerChartProps) => {
  const getMinIndex = () => {
    const dataPoke = datasets.map(({ data }) => data);
    const dataLengthPoke = datasets.map(({ data }) => data.length);
    const maxLength = Math.max(...dataLengthPoke);
    const datasetLength = datasets.length;

    let result = [];

    for (let i = 0; i < maxLength; i++) {
      let arr = [];
      for (let j = 0; j < datasetLength; j++) {
        arr.push({
          value: dataPoke[j][i] || Infinity,
          dataIndex: i,
          datasetIndex: j,
        });
      }
      const minArray = arr.reduce((acc, cur) => {
        if (acc.value > cur.value) {
          return cur;
        }
        return acc;
      }, arr[0]);

      result.push(minArray);
    }

    return result;
  };

  return (
    <>
      <BarChart
        type="bar"
        height={400}
        data={{
          datasets: datasets.map((item) => ({
            ...item,
            minIndex: getMinIndex(),
          })),
          labels,
        }}
        plugins={[sortStackedBarChartPlugin]}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              bodyColor: colorSet.gray8,
              titleColor: colorSet.gray10,
              bodyFont: {
                size: 14,
              },
              xAlign: "left",
              yAlign: "center",
              callbacks: {
                title: (chartItem) => chartItem[0].dataset.label,
                label: (a) => {
                  return `${a.formattedValue} MT`;
                },
              },
            },
            datalabels: {
              font: {
                size: 13,
              },
              color: colorSet.gray5,
              formatter: (_, context) => {
                const datasetArray: { number: number; index: number }[] = [];

                const contextDataIndex = context.dataIndex;
                const contextDatasetIndex = context.datasetIndex;

                context.chart.data.datasets.forEach((dataset) => {
                  if (dataset.data[context.dataIndex] !== undefined) {
                    datasetArray.push({
                      number: dataset.data[context.dataIndex] as number,
                      index: context.dataIndex,
                    });
                  }
                });

                const total = datasetArray.reduce(
                  (total, data) => total + data.number,
                  0,
                );

                if (
                  !!((context.dataset as any).minIndex as []).find(
                    ({ dataIndex, datasetIndex }) =>
                      dataIndex === contextDataIndex &&
                      datasetIndex === contextDatasetIndex,
                  )
                ) {
                  return total === 0 ? "" : total.toLocaleString();
                } else {
                  return "";
                }
              },
              anchor: "end",
              align: "end",
            },
          },
          layout: {
            padding: {
              top: 23,
            },
          },
          skipNull: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                display: displayY,
              },
            },
          },
        }}
      />
    </>
  );
};
