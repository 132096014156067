import Badge from "@/src/components/atom/Badge";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import { useGetTaskListQuery } from "@/src/store/apis/tasks/taskList";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";

function TaskCard() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const {
    isError,
    bookingId,
    bookingNo,
    buyerName,
    workplaceName,
    workplaceId,
    workingDayAt,
    cargoCutOffAt,
    docCutOffAt,
    cfs,
    loadingEstimatedWeightAndUnit,
  } = taskDetailApi.endpoints.getTaskDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ isError, currentData, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          isError,
          bookingId: Number(currentData?.bookingId),
          bookingNo: !isUnstable ? currentData.bookingNo : "-",
          buyerName: !isUnstable ? currentData.buyerName : "-",
          workplaceId: currentData?.workplaceId,
          workplaceName: !isUnstable ? currentData.workplaceName : "-",
          workingDayAt:
            !isUnstable && currentData.workingDayAt
              ? dayjs(currentData.workingDayAt).format(
                  DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
                )
              : "-",
          cargoCutOffAt:
            !isUnstable && currentData?.cargoCutOffAt
              ? dayjs(currentData.cargoCutOffAt).format(
                  dayjs(currentData.cargoCutOffAt).get("minute") === 30
                    ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                    : DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
                )
              : "-",
          docCutOffAt:
            !isUnstable && currentData.docCutOffAt
              ? dayjs(currentData.docCutOffAt).format(
                  dayjs(currentData.docCutOffAt).get("minute") === 30
                    ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                    : DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
                )
              : "-",
          cfs: !isUnstable ? currentData.cfs : "-",
          loadingEstimatedWeightAndUnit:
            !isUnstable &&
            currentData.taskEstimatedWeight &&
            currentData.estimatedWeightUnit
              ? `${Number(currentData.taskEstimatedWeight).toLocaleString(
                  "ko-KR",
                )} ${currentData.estimatedWeightUnit}`
              : "-",
        };
      },
    },
  );

  const { bookingWithLoadingList } = useGetTaskListQuery(
    {
      bookingId,
      page: 1,
      pageSize: 10,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookingWithLoadingList: !isUnstable
            ? (currentData.rows?.slice() ?? [])
            : [],
        };
      },
    },
  );

  return (
    <SectionCardGrid>
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:buyer")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{buyerName}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <BookingNo>
            <Typo color="gray6" typoType="b9m" as="p">
              {t("common:bookingNo")}
            </Typo>
            {bookingWithLoadingList.length >= 2 ? (
              <Badge
                badgeType="line"
                color="systemGrape3"
                borderColor="systemGrape3"
                text={t("status:booking.split")}
                badgeSize="S"
              />
            ) : null}
          </BookingNo>
        }
        direction="vertical"
        value={
          !isError ? (
            <BlueText
              color="systemBlue2"
              typoType="h4"
              onClick={() => {
                navigate(
                  `${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${bookingId}`,
                );
              }}
            >
              {bookingNo}
            </BlueText>
          ) : (
            <BreakWordTypo typoType="h4">-</BreakWordTypo>
          )
        }
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:factory")}
          </Typo>
        }
        direction="vertical"
        value={
          !isError ? (
            <BlueText
              color="systemBlue2"
              typoType="h4"
              onClick={() => {
                navigate(
                  `${EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_DETAIL}/${workplaceId}`,
                );
              }}
            >
              {workplaceName}
            </BlueText>
          ) : (
            <BreakWordTypo typoType="h4">-</BreakWordTypo>
          )
        }
      />
      <StyledSectionCardRow
        label={
          <BreakWordTypo color="gray6" typoType="b9m">
            {t("task:detail.cards.workingDay")}
          </BreakWordTypo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{workingDayAt}</BreakWordTypo>}
      />

      <GrayLine />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:cargoCutOff")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{cargoCutOffAt}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:docCutOffVgmCutOff")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{docCutOffAt}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:cfsCyCode")}
          </Typo>
        }
        direction="vertical"
        value={<Typo typoType="h4">{cfs}</Typo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:estimatedWeight")}
          </Typo>
        }
        direction="vertical"
        value={
          <BreakWordTypo typoType="h4">
            {loadingEstimatedWeightAndUnit}
          </BreakWordTypo>
        }
      />
    </SectionCardGrid>
  );
}

export default TaskCard;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;

  p {
    max-width: 100%;
  }
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const BookingNo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    ${typo.b7m}
  }
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
  word-break: break-all;
`;
