import { useParams } from "react-router-dom";
import ContractEditPage from "./components/edit/ContractEditPage";
import ContractEditBasedOnScPage from "./components/edit/ContractEditBasedOnSc";
import {
  useGetImporterContractDetailQuery,
  useGetImporterContractSignatureHistoriesQuery,
} from "@/src/store/apis/contracts/contractDetail";

const ImporterContractEditV2Page = () => {
  const params = useParams();

  // API
  const { signatureStatus, contractStatus } = useGetImporterContractDetailQuery(
    {
      id: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isFetching, currentData }) => {
        return {
          isContractFetching: isFetching,
          signatureStatus: currentData?.signatureStatus,
          contractStatus: currentData?.contractStatus,
        };
      },
    },
  );
  const {
    isImporterSignedSC,
    isExporterSignedSC,
    isExporterSignedPO,
    isImporterSignedPO,
  } = useGetImporterContractSignatureHistoriesQuery(
    {
      id: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching }) => {
        return {
          isSignatureFetching: isFetching,
          isImporterSignedSC: currentData?.rows[0]?.isImporterSignedSC,
          isExporterSignedSC: currentData?.rows[0]?.isExporterSignedSC,
          isExporterSignedPO: currentData?.rows[0]?.isExporterSignedPO,
          isImporterSignedPO: currentData?.rows[0]?.isImporterSignedPO,
          isAllSignedBefore: currentData?.rows.some(
            (sign) =>
              sign.isExporterSignedPO &&
              sign.isExporterSignedSC &&
              sign.isImporterSignedPO &&
              sign.isImporterSignedSC,
          ),
        };
      },
    },
  );

  /**
   * 기본 수정폼(전체수정 폼)
   * - 거래처와 미연동일 경우
   * - 서명이 모두 연결이 되어있는 상태에서 수정(버전업)
   * - PO처음부터 반려되었을경우(서명모두 false)
   */
  const isPOBasicForm =
    contractStatus === "NOT_LINKED" ||
    signatureStatus === "LINKED" ||
    (signatureStatus === "PENDING" &&
      !isImporterSignedSC &&
      !isExporterSignedSC &&
      !isExporterSignedPO &&
      !isImporterSignedPO);

  return (
    <div>
      {isPOBasicForm ? (
        <ContractEditPage />
      ) : (
        <ContractEditBasedOnScPage id={Number(params.id)} />
      )}
    </div>
  );
};

export default ImporterContractEditV2Page;
