import CloseSvg from "@/src/assets/icons/icon-close-red.svg";
import PencelSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Icon from "@/src/components/atom/Icon";
import Tag from "@/src/components/atom/Tag";
import Typo from "@/src/components/atom/Typo";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import SingleImageUpload from "@/src/components/molecule/SingleImageUpload";
import useAlert from "@/src/hooks/useAlert";
import { commonApi, useGetTimeZonesQuery } from "@/src/store/apis/common";
import {
  companyApi,
  useEditExportersMutation,
} from "@/src/store/apis/corporate/company";
import { ModifyExporterDto } from "@/src/store/apis/corporate/company/interface";
import { MediaDto } from "@/src/store/apis/media/interface";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface BasicInfoEditDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

function BasicInfoEditDialog({ open, onOpenChange }: BasicInfoEditDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  // API
  const [editExporters, { isLoading }] = useEditExportersMutation();
  const { currentData: countryCategoryList } =
    commonApi.endpoints.getCommonCodeViaCodeName.useQueryState({
      codeName: "COUNTRY",
    });
  const { currentData: mainCategories } =
    commonApi.endpoints.getCommonCodeViaCodeName.useQueryState({
      codeName: "MAIN_CATEGORY",
    });
  const { currentData: basicData } =
    companyApi.endpoints.getExporters.useQueryState(undefined);

  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const initMainCategory = basicData?.exporterDetailQueryResultDto
    .mainCategories
    ? basicData?.exporterDetailQueryResultDto.mainCategories.map(
        (item) => item.codeItemName,
      )
    : [];

  const {
    control,
    watch,
    handleSubmit,
    register,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
    clearErrors,
  } = useForm<{
    companyCode: string;
    companyName: string;
    businessNumber: string;
    businessLogoSimpleMedia: MediaDto | null;
    countryCodeItemName: string;
    companyType: string;

    apartment: string;
    city: string;
    state: string;
    postal: string;
    country: string;

    telPrefix: string;
    tel: string;
    faxPrefix: string;
    fax: string;
    mainCategories: string[];

    timezoneCity: string;
    timezoneGmtOffset: number;
  }>({
    mode: "all",
    defaultValues: {
      companyCode: basicData?.basicInformation.companyCode,
      companyName: basicData?.basicInformation.companyName,
      businessNumber: basicData?.basicInformation.businessNumber,
      countryCodeItemName: countryCategoryList?.find(
        (country) =>
          country.codeItemName ===
          basicData?.exporterDetailQueryResultDto.countryCodeItemName,
      )?.codeItemNameKo,
      businessLogoSimpleMedia:
        basicData?.basicInformation.businessLogoSimpleMedia,

      apartment: basicData?.exporterDetailQueryResultDto.streetAddress,
      city: basicData?.exporterDetailQueryResultDto.locality,
      state: basicData?.exporterDetailQueryResultDto.region,
      postal: basicData?.exporterDetailQueryResultDto.postalCode,
      country: basicData?.exporterDetailQueryResultDto.countryName,

      telPrefix: basicData?.exporterDetailQueryResultDto.telPrefix,
      tel: basicData?.exporterDetailQueryResultDto.tel,
      faxPrefix: basicData?.exporterDetailQueryResultDto.faxPrefix,
      fax: basicData?.exporterDetailQueryResultDto.fax,
      companyType: basicData?.exporterDetailQueryResultDto.companyType,
      mainCategories:
        basicData?.exporterDetailQueryResultDto.mainCategories.map(
          (item) => item.codeItemName,
        ),

      timezoneCity: basicData?.exporterDetailQueryResultDto.city,
      timezoneGmtOffset: basicData?.exporterDetailQueryResultDto.gmtOffset,
    },
  });

  const {
    currentData: timeZoneList,
    isFetching: isFetchingTimeZoneList,
    isError: isErrorTimeZoneList,
  } = useGetTimeZonesQuery(
    {
      countryCode: countryCategoryList?.find(
        (country) => country.codeItemNameKo === watch("countryCodeItemName"),
      )?.codeItemName,
    },
    {
      skip: !countryCategoryList?.find(
        (country) => country.codeItemNameKo === watch("countryCodeItemName"),
      )?.codeItemName,
    },
  );

  useEffect(() => {
    setFocus("companyName");
  }, [setFocus]);

  const handleSubmitClick = async () => {
    const transformCountryCodeItem = countryCategoryList?.find(
      (country) => country.codeItemNameKo === watch("countryCodeItemName"),
    )?.codeItemName;

    try {
      const params = {
        basicInformation: {
          companyName: getValues("companyName") || null,
          telPrefix: getValues("telPrefix") || null,
          tel: getValues("tel") || null,
          faxPrefix: getValues("faxPrefix") || null,
          fax: getValues("fax") || null,
          countryName: getValues("country") || null,
          region: getValues("state") || null,
          locality: getValues("city") || null,
          streetAddress: getValues("apartment") || null,
          postalCode: getValues("postal") || null,
          countryCodeItemName: transformCountryCodeItem || null,
          mainCategoryCodeItemNames: getValues("mainCategories") || null,
          businessLogoMediaId: getValues("businessLogoSimpleMedia")?.id || null,
          city: getValues("timezoneCity") || null,
          gmtOffset: getValues("timezoneGmtOffset"),
          companyType: getValues("companyType"),
        },
      } as ModifyExporterDto;

      await editExporters(params).unwrap();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
      onOpenChange(false);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    if (timeZoneList && timeZoneList.length === 1) {
      setValue("timezoneCity", timeZoneList[0].zoneName);
      setValue("timezoneGmtOffset", timeZoneList[0].gmtOffset);
      clearErrors("timezoneCity");
    } else {
      // timeZoneList에 값이 2개 이상일 경우 focus 설정
      if (timeZoneList) {
        if (timeZoneList.length >= 2) {
          setFocus("timezoneCity");
        }
      }
    }
  }, [timeZoneList, setValue, clearErrors, setFocus]);

  return (
    <Dialog
      title={t("companyManagement:exporter.companyInfoDialog.basicInfo.title")}
      open={open}
      width={850}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
            ref={cancelButtonRef}
            onKeyUp={(e) => {
              if (e.shiftKey && e.code === "Tab") {
                setFocus("mainCategories");
              }
            }}
          >
            <Typo typoType="btn3m">
              {t(
                "companyManagement:exporter.companyInfoDialog.basicInfo.cancelButton",
              )}
            </Typo>
          </Button>
          <Button
            onClick={() => {
              submitButtonRef.current?.click();
            }}
            isLoading={isLoading}
            disabled={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t(
                "companyManagement:exporter.companyInfoDialog.basicInfo.saveButton",
              )}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <FlexColumn>
          <FormItem
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.corporateCode",
            )}
            type="text"
            name="companyCode"
            rules={{ required: true }}
            control={control}
            direction="vertical"
            inputProps={{ disabled: true }}
          />
          <FormItem
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.corporateName",
            )}
            type="text"
            name="companyName"
            rules={{ required: true }}
            errorsMessage={{
              required: t("error:required"),
            }}
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "companyManagement:exporter.companyInfoDialog.basicInfo.corporateNamePlaceholder",
              ),
            }}
          />
          <FormItem
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.businessNumber",
            )}
            type="text"
            name="businessNumber"
            rules={{ required: true }}
            control={control}
            direction="vertical"
            inputProps={{ disabled: true }}
          />
          <SectionLogoContainer>
            <SectionCardRow
              label={
                <Typo typoType="b7m">
                  {t(
                    "companyManagement:exporter.companyInfoDialog.basicInfo.businessLogo",
                  )}
                </Typo>
              }
              direction="vertical"
              value={
                <Controller
                  control={control}
                  name="businessLogoSimpleMedia"
                  render={({ field }) => {
                    return (
                      <LogoContainer>
                        <SingleImageUpload
                          onChange={(media) => {
                            setValue("businessLogoSimpleMedia", media);
                          }}
                          value={field.value ? field.value.mediaUrl : undefined}
                          topAccessory={({ handleEdit, handleRemove }) => {
                            return (
                              <IconButtonContainer>
                                <StyledIconButton
                                  buttonColor="black"
                                  buttonGrade="tertiary"
                                  buttonSize={24}
                                  onClick={() => handleEdit()}
                                  type="button"
                                >
                                  <Icon iconSrc={PencelSvg} iconSize={16} />
                                </StyledIconButton>
                                <StyledIconButton
                                  buttonColor="black"
                                  buttonGrade="tertiary"
                                  buttonSize={24}
                                  type="button"
                                  onClick={() => {
                                    setValue("businessLogoSimpleMedia", null);
                                    handleRemove();
                                  }}
                                >
                                  <Icon iconSrc={CloseSvg} iconSize={16} />
                                </StyledIconButton>
                              </IconButtonContainer>
                            );
                          }}
                        />
                        <LogoUploadDescription>
                          <DescriptionIcon iconSrc={InfoSvg} iconSize={16} />
                          <Typo color="gray6" typoType="b9r">
                            {t(
                              "companyManagement:exporter.companyInfoDialog.basicInfo.logoDescription",
                            )}
                          </Typo>
                        </LogoUploadDescription>
                      </LogoContainer>
                    );
                  }}
                />
              }
            />
          </SectionLogoContainer>

          <SectionCardRow
            label={
              <FormLabel data-required={true}>
                {t(
                  "companyManagement:exporter.companyInfoDialog.basicInfo.countryAndCity",
                )}
              </FormLabel>
            }
            direction="vertical"
            value={
              <MultiFormItemContainer>
                <FormItem
                  type="singleSelect"
                  name="countryCodeItemName"
                  rules={{ required: true }}
                  errorsMessage={{
                    required: t("error:required"),
                  }}
                  control={control}
                  inputProps={{
                    placeholder: t(
                      "companyManagement:exporter.companyInfoDialog.basicInfo.countryPlaceholder",
                    ),
                    getPopupContainer: (triggerNode) => {
                      return triggerNode.parentElement;
                    },
                    onChange: () => {
                      setValue("timezoneCity", "");
                      setValue("timezoneGmtOffset", 0);
                    },
                    filterOption: (input, option) =>
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      ((option?.value as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()),
                  }}
                  options={
                    countryCategoryList
                      ? countryCategoryList.map((item) => {
                          return {
                            label: `${item.codeItemName}) ${item.codeItemNameEn}`,
                            value: item.codeItemNameKo,
                          };
                        })
                      : []
                  }
                />
                <FormItem
                  type="singleSelect"
                  name="timezoneCity"
                  rules={{ required: true }}
                  errorsMessage={{
                    required: t("error:required"),
                  }}
                  control={control}
                  inputProps={{
                    placeholder: t(
                      "companyManagement:exporter.companyInfoDialog.basicInfo.cityPlaceholder",
                    ),
                    getPopupContainer: (triggerNode) => {
                      return triggerNode.parentElement;
                    },
                    filterOption: (input, option) =>
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      ((option?.value as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()),
                    onChange: (value: string) => {
                      const gmtOffset =
                        timeZoneList?.find((item) => item.zoneName === value)
                          ?.gmtOffset ?? 0;

                      setValue("timezoneCity", value);
                      setValue("timezoneGmtOffset", gmtOffset);
                    },
                  }}
                  disabled={isFetchingTimeZoneList || isErrorTimeZoneList}
                  options={
                    timeZoneList
                      ? timeZoneList.map((item) => {
                          return {
                            label: `${item.zoneName} (UTC ${
                              item.gmtOffset > 0 ? "+" : ""
                            }${item.gmtOffset})`,
                            value: item.zoneName,
                          };
                        })
                      : []
                  }
                />
              </MultiFormItemContainer>
            }
          />

          <AddressFormItem
            register={register}
            setValue={setValue}
            direction="horizontal"
            isRequired={true}
            error={errors}
            clearErrors={clearErrors}
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.address",
            )}
          />
        </FlexColumn>
        <FlexColumn>
          <ContactFormItem
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.contact",
            )}
            prefixValue={watch("telPrefix")}
            prefixName="telPrefix"
            restContactName="tel"
            register={register}
            setValue={setValue}
            direction="vertical"
            isRequired={true}
            errors={errors}
          />

          <ContactFormItem
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.fax",
            )}
            prefixValue={watch("faxPrefix")}
            prefixName="faxPrefix"
            restContactName="fax"
            register={register}
            setValue={setValue}
            direction="vertical"
            isRequired={false}
          />

          <SectionCardRow
            label={
              <Typo typoType="b7r">
                {t(
                  "companyManagement:exporter.companyInfoDialog.basicInfo.corporateManager",
                )}
              </Typo>
            }
            direction="vertical"
            value={
              <CorporateManagerContainer>
                {basicData?.basicInformation &&
                  basicData?.basicInformation.corporateManagers.map(
                    (item, idx) => {
                      return (
                        <li key={idx.toString()}>
                          <Typo typoType="b9m">{item.name}</Typo>
                          <Typo color="gray6" typoType="b10r">
                            {item.email}
                          </Typo>
                        </li>
                      );
                    },
                  )}
              </CorporateManagerContainer>
            }
          />

          <FormItem
            label={t(
              "companyManagement:exporter.companyInfoDialog.basicInfo.mainCategory",
            )}
            type="multipleSelect"
            name="mainCategories"
            rules={{ required: true }}
            errorsMessage={{
              required: t("error:required"),
            }}
            control={control}
            direction="vertical"
            options={
              mainCategories
                ? mainCategories.map((item) => {
                    return {
                      label: item.codeItemNameEn,
                      value: item.codeItemName,
                    };
                  })
                : []
            }
            inputProps={{
              getPopupContainer: (triggerNode) => {
                return triggerNode.parentElement;
              },
              placeholder: "Select add category",
              tagRender: (props) => {
                return (
                  <Tag
                    style={{ margin: "4px" }}
                    label={props.label}
                    color={
                      initMainCategory.includes(props.value)
                        ? "blue4"
                        : "green1"
                    }
                    tagColor={
                      initMainCategory.includes(props.value)
                        ? "blue10"
                        : "green6"
                    }
                    onRemove={() => {
                      const watchCategory = watch("mainCategories");
                      const watchCategoryFilter = watchCategory.filter(
                        (item) => item !== props.value,
                      );
                      setValue("mainCategories", watchCategoryFilter);
                    }}
                  />
                );
              },
            }}
          />
        </FlexColumn>
        <Hidden
          ref={submitButtonRef}
          type="submit"
          onFocus={() => cancelButtonRef.current?.focus()}
        />
      </Form>
    </Dialog>
  );
}

export default BasicInfoEditDialog;

const FormLabel = styled.p`
  ${typo.b7m};
  flex-shrink: 0;
  min-width: 164px;
  color: ${colorSet.gray2};

  &[data-required="true"] {
    &::after {
      content: " *";
      color: ${colorSet.red2};
    }
  }
`;

const MultiFormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Form = styled.form`
  display: flex;
  gap: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`;

const CorporateManagerContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const SectionLogoContainer = styled.div``;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  img {
    height: 142px;
  }

  & > button {
    height: 142px;
    padding: 26px 34px;
  }
`;

const LogoUploadDescription = styled.div`
  display: flex;
  gap: 6px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const IconButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 4px 0 0;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionIcon = styled(Icon)`
  flex-shrink: 0;
  height: 18px;
`;
