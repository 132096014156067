import colorSet from "@/src/styles/color";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
// @ts-ignore
import RobotoBoldItalic from "../../../../assets/fonts/Roboto-BoldItalic.ttf";
// @ts-ignore
import RobotoItalic from "../../../../assets/fonts/Roboto-Italic.ttf";
import stringToPdfPrieceText from "../utils/stringToPdfPrieceText";
import { PoData } from "./types";

interface PoContentProps {
  data: PoData;
}

function PoContent({ data }: PoContentProps) {
  const sellerAddress = [
    data.sellerLocality,
    data.sellerRegion,
    data.sellerPostalCode,
  ]
    .filter(Boolean)
    .join(", ");

  const buyerAddress = [
    data.buyerLocality,
    data.buyerRegion,
    data.buyerPostalCode,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* Title */}
        <View style={styles.titleContainer}>
          <View style={styles.companyLogo}>
            {!!data.componyLogoUrl ? (
              <Image
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  maxWidth: "152px",
                }}
                src={{
                  uri: data.componyLogoUrl,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
            ) : (
              <View style={styles.companyLogoDefault} />
            )}
          </View>

          <Text style={styles.title}>PURCHASE ORDER</Text>
          <Text style={styles.draft}>PO No.{data.poNumber}</Text>
        </View>

        {/* order Data */}
        <View style={styles.orderDataRow}>
          <Text style={styles.orderDate}>Date</Text>
          <Text style={styles.orderDateText}>{data.orderDate}</Text>
        </View>

        {/* row 1 */}
        <View style={styles.flexRowOne}>
          <View style={styles.flexRowOneItem}>
            <Text style={styles.itemTitle}>Consignee / Buyer</Text>
            <Text style={styles.itemText}>{data.buyerCorporation}</Text>
            <Text style={styles.itemText}>{data.buyerStreetAddress}</Text>
            <Text style={styles.itemText}>{buyerAddress}</Text>
            <Text style={styles.itemText}>{data.buyerCountryName}</Text>

            {data.buyerContact && data.buyerContactPrefix && (
              <Text style={styles.itemText}>
                Contact:{data.buyerContactPrefix} {data.buyerContact}
              </Text>
            )}
            {data.buyerFax && data.buyerFaxPrefix && (
              <Text style={styles.itemText}>
                FAX: {data.buyerFaxPrefix} {data.buyerFax}
              </Text>
            )}
          </View>

          <View style={styles.flexRowOneItem}>
            <Text style={styles.itemTitle}>Supplier / Seller</Text>
            <Text style={styles.itemText}>{data.sellerCorporation}</Text>
            <Text style={styles.itemText}>{data.sellerStreetAddress}</Text>
            <Text style={styles.itemText}>{sellerAddress}</Text>
            <Text style={styles.itemText}>{data.sellerCountryName}</Text>

            {data.sellerContact && data.sellerContactPrefix && (
              <Text style={styles.itemText}>
                Contact: {data.sellerContactPrefix} {data.sellerContact}
              </Text>
            )}
            {data.sellerFax && data.sellerFaxPrefix && (
              <Text style={styles.itemText}>
                FAX: {data.sellerFaxPrefix} {data.sellerFax}
              </Text>
            )}
            <Text style={styles.itemText}>
              Seller Contact: {data.sellerContactPersonName}
            </Text>
          </View>
        </View>

        {/* row 2 */}
        <View style={styles.flexRowTwo}>
          <Text style={styles.flexRowTwoText}>PO NO.</Text>
          <Text style={{ ...styles.flexRowTwoText, flex: 3 }}>DESCRIPTION</Text>
          <Text style={styles.flexRowTwoText}>QUANTITY</Text>
          <Text style={styles.flexRowTwoText}>UNIT PRICE</Text>
          <Text style={styles.flexRowTwoText}>AMOUNT</Text>
        </View>

        {/* row 3 */}
        <View style={styles.flexRowThree}>
          <Text style={styles.flexRowThreeText}>{data.poNumber}</Text>
          <Text style={{ ...styles.flexRowThreeText, flex: 3 }}>
            {data.description}
          </Text>
          <Text style={styles.flexRowThreeText}>
            {stringToPdfPrieceText({ value: data.quantity })}{" "}
            {data.quantityPrefix}
          </Text>
          <Text style={styles.flexRowThreeText}>
            {data.unitPricePrefix}{" "}
            {stringToPdfPrieceText({ value: data.unitPrice })}
          </Text>
          <Text style={{ ...styles.flexRowThreeText }} wrap={true}>
            {data.unitPricePrefix}{" "}
            {stringToPdfPrieceText({ value: data.amount })}
          </Text>
        </View>

        {/* row 4 */}
        <View style={styles.flexRowFour}>
          <Text style={styles.flexRowFourText}>PAYMENT TERM</Text>
          <Text style={styles.flexRowFourText}>SHIPPING TERM</Text>
          <Text style={{ ...styles.flexRowFourText, maxHeight: "auto" }}>
            {"LAST SHIPMENT\nDATE"}
          </Text>
          <Text style={styles.flexRowFourText}>HS Code</Text>
          <Text style={styles.flexRowFourText}>DELIVERY DATE</Text>
        </View>

        {/* row 5 */}
        <View style={styles.flexRowFive}>
          <Text style={styles.flexRowFiveText}>
            {data.paymentTerm} {data.paymentTermsRemark}
          </Text>
          <Text style={styles.flexRowFiveText}>
            {data.shippingTerm} {data.shippingTermRemark}
          </Text>
          <Text style={styles.flexRowFiveText}>{data.lastShipmentDate}</Text>
          <Text style={styles.flexRowFiveText}>{data.hsCode}</Text>
          <Text style={styles.flexRowFiveText}>{data.deliveryDate}</Text>
        </View>

        <View style={styles.remarkRow}>
          <View style={styles.remarkColumn}>
            {/* remark */}
            <View style={styles.remarkTextRow}>
              <Text style={styles.remarkTitle}>Remark</Text>
              <View>
                {!!data.freeTime && (
                  <View style={styles.viewInRemark}>
                    <Text style={{ ...styles.textInRemark, fontWeight: 700 }}>
                      &lt;Freetime&gt;
                    </Text>
                    <Text style={styles.textInRemark}>{data.freeTime}</Text>
                  </View>
                )}
                {!!data.originLocation && (
                  <View style={styles.viewInRemark}>
                    <Text style={{ ...styles.textInRemark, fontWeight: 700 }}>
                      &lt;Origin/Location&gt;
                    </Text>
                    <Text style={styles.textInRemark}>
                      {data.originLocation}
                    </Text>
                  </View>
                )}
                {!!data.placeOfDelivery && (
                  <View style={styles.viewInRemark}>
                    <Text style={{ ...styles.textInRemark, fontWeight: 700 }}>
                      &lt;Place of delivery&gt;
                    </Text>
                    <Text style={styles.textInRemark}>
                      {data.placeOfDelivery}
                    </Text>
                  </View>
                )}
                <Text style={styles.remarkText}>{data.remark}</Text>
              </View>
            </View>
            <View style={styles.signatureColumn}>
              {/* Signature */}
              <View style={styles.signatureContainer}>
                <View style={{ ...styles.signatureItem, marginLeft: 0 }}>
                  <View style={styles.signatureImageContainer}>
                    {!!data.buyerSignatureUrl ? (
                      <>
                        <img
                          src={data.buyerSignatureUrl}
                          alt="sellerSignature"
                        />
                        <Image
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          src={{
                            uri: data.buyerSignatureUrl,
                            method: "GET",
                            headers: { "Cache-Control": "no-cache" },
                            body: "",
                          }}
                        />
                      </>
                    ) : (
                      <View style={styles.signatureImageDefault} />
                    )}
                  </View>

                  <View style={styles.signatureBorder} />
                  <Text style={styles.signatureTitle}>Buyer Signature</Text>
                </View>

                <View style={styles.signatureItem}>
                  <View style={styles.signatureImageContainer}>
                    {!!data.sellerSignatureUrl ? (
                      <>
                        <img
                          src={data.sellerSignatureUrl}
                          alt="sellerSignature"
                        />
                        <Image
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          src={{
                            uri: data.sellerSignatureUrl,
                            method: "GET",
                            headers: { "Cache-Control": "no-cache" },
                            body: "",
                          }}
                        />
                      </>
                    ) : (
                      <View style={styles.signatureImageDefault} />
                    )}
                  </View>
                  <View style={styles.signatureBorder} />
                  <Text style={styles.signatureTitle}>Seller Signature</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>

      {data.isAddContractTerms && (
        <Page style={styles.body} size="A4">
          <Text style={styles.termsTitle}>{data.contractTermsTitle || ""}</Text>
          <View style={styles.terms}>
            <Text style={styles.termsText}>{data.contractTerms || ""}</Text>
          </View>
        </Page>
      )}
    </Document>
  );
}

export default PoContent;

Font.register({
  family: "SpoqaHanSans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf",
      fontWeight: 700,
      fontStyle: "normal",
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoBoldItalic,
      fontWeight: 700,
      fontStyle: "italic",
    },
    {
      src: RobotoItalic,
      fontWeight: 400,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  terms: { border: 1, flex: 1, padding: "8px" },

  // Title
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "81px",
    paddingBottom: "5px",
    borderBottom: 1,
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    width: "100%",
    textAlign: "center",
    alignSelf: "center",
    position: "absolute",
  },
  draft: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Roboto",
    fontStyle: "italic",
    alignSelf: "flex-end",
    marginLeft: "auto",
  },
  orderDate: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Roboto",
    fontStyle: "italic",
  },
  orderDateText: {
    fontSize: 9,
    fontWeight: 400,
    fontFamily: "Roboto",
    fontStyle: "italic",
  },
  companyLogoDefault: {
    position: "absolute",
    width: "76px",
    height: "76px",
    backgroundColor: colorSet.white,
  },
  companyLogo: {
    position: "absolute",
    minWidth: "76px",
    maxWidth: "152px",
    height: "76px",
  },

  // Display
  orderDataRow: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    marginLeft: "auto",
    marginTop: "3px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
  },
  // flexRowOne
  flexRowOne: {
    display: "flex",
    flexDirection: "row",
    maxHeight: "278px",
    marginBottom: "16px",
  },
  flexRowOneItem: {
    display: "flex",
    flex: 1,
    border: "none",
    padding: "6px 12px",
    minHeight: "80px",
    maxHeight: "80px",
  },
  // flexRowTwo
  flexRowTwo: {
    display: "flex",
    flexDirection: "row",
    borderTop: 1,
  },
  flexRowTwoText: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    padding: "4px",
    flex: 1,
  },
  // flexRowThree
  flexRowThree: {
    display: "flex",
    flexDirection: "row",
    borderTop: 1,
    borderBottom: 1,
    width: "100%",
    minHeight: "83px",
    maxHeight: "83px",
  },
  flexRowThreeText: {
    display: "flex",
    alignItems: "center",
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    padding: "2px",
    flex: 1,
  },
  // flexRowTFour
  flexRowFour: {
    display: "flex",
    flexDirection: "row",
    minHeight: "34px",
    maxHeight: "34px",
  },
  flexRowFourText: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    padding: "4px",
    minHeight: "20px",
    maxHeight: "20px",
    flex: 1,
  },
  // flexRowFive
  flexRowFive: {
    display: "flex",
    flexDirection: "row",
    borderTop: 1,
    borderBottom: 1,
  },
  flexRowFiveText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    padding: "2px",
    minHeight: "46px",
    maxHeight: "46px",
    flex: 1,
  },

  remarkRow: {
    display: "flex",
    flexDirection: "row",
    border: 1,
    flex: 1,
    marginTop: "16px",
  },
  remarkTextRow: {
    display: "flex",
    flex: 1,
  },
  remarkColumn: { display: "flex", flexDirection: "column", flex: 1 },
  signatureColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: "auto",
    borderTop: 1,
    flexBasis: "106px",
    flexGrow: 0,
    flexShrink: 0,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    width: "100%",
    paddingRight: 8,
    paddingLeft: 8,
  },
  signatureItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 16,
    width: 128,
  },
  signatureImageContainer: {
    marginTop: "auto",
    width: "100%",
    maxHeight: 64,
  },
  signatureImageDefault: {
    marginTop: "auto",
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: colorSet.white,
  },
  signatureBorder: {
    display: "flex",
    width: "100%",
    height: 1,
    borderBottom: 1,
    marginTop: 6,
  },

  // item text
  itemTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    marginBottom: "4px",
  },
  itemText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
  },
  termsTitle: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    marginTop: "5px",
    marginBottom: "15px",
    textAlign: "center",
  },
  termsText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
    lineHeight: "140%",
  },
  // Table
  tableTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    minHeight: "auto",
    maxHeight: "32px",
    borderBottom: 1,
    padding: "6px",
  },
  tableOriginTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    minHeight: "auto",
  },
  tableText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    height: "45px",
    padding: "6px",
  },
  tableTextLarge: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    minHeight: "78px",
    maxHeight: "78px",
    padding: "6px 8px",
  },
  remarkTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    borderBottom: 1,
    padding: "6px",
  },
  viewInRemark: {
    display: "flex",
    flexDirection: "row",
    padding: 8,
    gap: 2,
  },
  textInRemark: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
  },
  remarkText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
    height: "164px",
    padding: 8,
  },
  signatureTitle: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    padding: "6px 0",
  },
});
