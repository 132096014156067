import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import useAlert from "@/src/hooks/useAlert";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { authApi } from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useCreateEnterpriseInquiriesMutation } from "@/src/store/apis/subscription";
import { PaymentCycleType } from "@/src/store/apis/subscription/interface";
import { isNull, isUndefined } from "@/src/utils/is";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useEnterPriseSignupStepContext } from "../hooks";

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

enum AlertDialogState {
  NULL,
  SUBMIT,
}

const CONTAINER_FREIGHT_VOLUME_LIST = [
  {
    label: "enterpriseSignup:countRange.under100",
    value: "100",
  },
  {
    label: "enterpriseSignup:countRange.100to300",
    value: "100~300",
  },
  {
    label: "enterpriseSignup:countRange.300to500",
    value: "300~500",
  },
  {
    label: "enterpriseSignup:countRange.500to1000",
    value: "500~1000",
  },
  {
    label: "enterpriseSignup:countRange.1000to10000",
    value: "1000~10000",
  },
  {
    label: "enterpriseSignup:countRange.over10000",
    value: "10000",
  },
];

function EnterpriseInquiry() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const isInquiryQuery = !!searchParams.get("isInquiry");

  const { onStepChange } = useEnterPriseSignupStepContext();

  // API
  const [createEnterpriseInquiries, { isLoading }] =
    useCreateEnterpriseInquiriesMutation();
  const { companyName, name, aId, tel, telPrefix, countryCodeItemName } =
    authApi.endpoints.getSession.useQueryState(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          currentData: currentData?.row,
          aId: isStable ? currentData?.row.aId : "-",
          name: isStable ? currentData?.row.name : "-",
          countryCodeItemName: isStable
            ? currentData?.row.exporter.countryCodeItemName
            : "-",
          tel: isStable ? currentData?.row.personalContact : "",
          telPrefix: isStable ? currentData?.row.officeContactPrefix : "",
          companyType: isStable && currentData.row.exporter.companyType,
          companyName: isStable ? currentData.row.exporter.companyName : "-",
        };
      },
    });

  const { mainCategoryCurrentData, isMainCategoryFetching } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isMainCategoryFetching: isFetching,
            mainCategoryCurrentData: isStable
              ? (currentData ?? mainCategoryEmptyArray)
              : mainCategoryEmptyArray,
          };
        },
      }
    );

  const {
    handleSubmit,
    control,
    setFocus,
    getValues,
    setValue,
    watch,
    register,
  } = useForm<{
    personalContactPrefix?: string;
    personalContact?: string;
    position: string;
    mainCategoryCodeItemNames: string[];
    importMonthlyContainerFreightVolume: string;
    exportMonthlyContainerFreightVolume: string;
    expectNumberOfMember: string;
    body: string;
    paymentCycle: PaymentCycleType;
  }>({
    mode: "onBlur",
    defaultValues: {
      personalContactPrefix: telPrefix,
      personalContact: tel,
      position: "",
      mainCategoryCodeItemNames: [],
      importMonthlyContainerFreightVolume: "",
      exportMonthlyContainerFreightVolume: "",
      expectNumberOfMember: "",
      body: "",
      paymentCycle: "MONTHLY",
    },
  });
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  const corporateInfoArray = [
    {
      label: t("enterpriseSignup:corporate"),
      value: companyName,
      isVisible: true,
    },
    {
      label: t("enterpriseSignup:name"),
      value: name,
      isVisible: true,
    },
    {
      label: t("enterpriseSignup:contact"),
      value: `${telPrefix}(${countryCodeItemName}) ${tel}`,
      isVisible: !!telPrefix && !!tel,
    },
    {
      label: t("enterpriseSignup:email"),
      value: aId,
      isVisible: true,
    },
  ];

  const mainCategoryFilterList = mainCategoryCurrentData.map((item) => {
    return {
      label: item.codeItemNameEn,
      value: item.codeItemName,
    };
  });

  const goToNormalPlanPage = () => {
    if (isInquiryQuery) {
      return navigate(
        `${EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP}?isInquiry=true`
      );
    }

    navigate(EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP);
  };

  const handleSubmitClick = async () => {
    const isTel = !isNull(tel);

    const params = {
      personalContactPrefix: isTel
        ? undefined
        : getValues("personalContactPrefix"),
      personalContact: isTel ? undefined : getValues("personalContact"),
      position: getValues("position"),
      mainCategoryCodeItemNames: getValues("mainCategoryCodeItemNames"),
      importMonthlyContainerFreightVolume: getValues(
        "importMonthlyContainerFreightVolume"
      ),
      exportMonthlyContainerFreightVolume: getValues(
        "exportMonthlyContainerFreightVolume"
      ),
      expectNumberOfMember: Number(getValues("expectNumberOfMember")),
      paymentCycle: getValues("paymentCycle"),
      body: getValues("body"),
    };

    try {
      const res = await createEnterpriseInquiries(params).unwrap();

      if (res.statusCode === 201) {
        onStepChange("INQUIRY_COMPLETED");
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setAlertDialogState(AlertDialogState.NULL);
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.SUBMIT) {
      return (
        <AlertDialog
          title={t("enterpriseSignup:contactSalesTeam")}
          open
          onOpenChange={() => {
            if (!isLoading) {
              setAlertDialogState(AlertDialogState.NULL);
            }
          }}
          onOk={handleSubmit(handleSubmitClick, () => {
            setAlertDialogState(AlertDialogState.NULL);
          })}
          isCancelDisabled={isLoading}
          isOkLoading={isLoading}
          isOkDisabled={isLoading}
          cancelText={t("common:cancel")}
          okText={t("common:ok")}
        >
          {t("enterpriseSignup:contactSalesTeamDescription")}
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    setFocus("position");
  }, [setFocus]);

  return (
    <StyledSectionCard
      cardTitle={t("enterpriseSignup:cardTitle")}
      cardContentContainerStyle={{ padding: "40px 160px" }}
    >
      <Description typoType="h2">
        {t("enterpriseSignup:description")}
      </Description>

      <Form onSubmit={() => setAlertDialogState(AlertDialogState.SUBMIT)}>
        <StyledCallOut>
          {corporateInfoArray.map(({ label, value, isVisible }) => {
            return (
              isVisible && (
                <SectionCardRow key={label} label={label} value={value} />
              )
            );
          })}
        </StyledCallOut>

        {isNull(tel) && (
          <ContactFormItem
            label={t("enterpriseSignup:label.contact")}
            prefixValue={watch("personalContactPrefix")}
            prefixName="personalContactPrefix"
            restContactName="personalContact"
            register={register}
            setValue={setValue}
            direction="vertical"
            isRequired={true}
            errorMessage={t("error:required")}
            className="form-item"
          />
        )}

        <FormItem
          type="text"
          direction="vertical"
          label={t("enterpriseSignup:label.position")}
          control={control}
          name="position"
          rules={{ required: true }}
          inputProps={{
            placeholder: t("enterpriseSignup:placeholder.position"),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
          className="form-item"
        />
        <FormItem
          type="multipleSelect"
          direction="vertical"
          label={t("enterpriseSignup:label.mainCategory")}
          control={control}
          name="mainCategoryCodeItemNames"
          rules={{ required: true }}
          inputProps={{
            suffixIcon: isMainCategoryFetching ? <Loader /> : undefined,
            placeholder: t("enterpriseSignup:placeholder.mainCategory"),
            onRemoveItem: (value) => {
              const watchCategory = watch("mainCategoryCodeItemNames");
              const watchCategoryFilter = watchCategory.filter(
                (item) => item !== value
              );
              setValue("mainCategoryCodeItemNames", watchCategoryFilter);
            },
          }}
          options={mainCategoryFilterList}
          errorsMessage={{
            required: t("error:required"),
          }}
          className="form-item"
        />
        <FormItem
          type="singleSelect"
          direction="vertical"
          label={t(
            "enterpriseSignup:label.importMonthlyContainerFreightVolume"
          )}
          control={control}
          name="importMonthlyContainerFreightVolume"
          rules={{ required: true }}
          inputProps={{
            placeholder: t(
              "enterpriseSignup:placeholder.importMonthlyContainerFreightVolume"
            ),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
          className="form-item"
          options={CONTAINER_FREIGHT_VOLUME_LIST.map(({ label, value }) => {
            return { label: t(label), value: value };
          })}
        />
        <FormItem
          type="singleSelect"
          direction="vertical"
          label={t(
            "enterpriseSignup:label.exportMonthlyContainerFreightVolume"
          )}
          control={control}
          name="exportMonthlyContainerFreightVolume"
          rules={{ required: true }}
          inputProps={{
            placeholder: t(
              "enterpriseSignup:placeholder.exportMonthlyContainerFreightVolume"
            ),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
          className="form-item"
          options={CONTAINER_FREIGHT_VOLUME_LIST.map(({ label, value }) => {
            return { label: t(label), value: value };
          })}
        />
        <FormItem
          type="text"
          direction="vertical"
          label={t("enterpriseSignup:label.expectNumberOfMember")}
          control={control}
          name="expectNumberOfMember"
          rules={{ required: true }}
          inputProps={{
            placeholder: t("enterpriseSignup:placeholder.expectNumberOfMember"),
            onChange: (e) => {
              const replaceValue = e.target.value.replace(/[^0-9]/g, "");

              setValue(
                "expectNumberOfMember",
                Number(replaceValue).toLocaleString("ko-KR")
              );
            },
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
          className="form-item"
        />
        <FormItem
          type="radioGroup"
          direction="vertical"
          label={t("enterpriseSignup:label.paymentCycle")}
          control={control}
          name="paymentCycle"
          rules={{ required: true }}
          options={[
            {
              label: t("enterpriseSignup:label.monthlyOption"),
              value: "MONTHLY",
            },
            {
              label: t("enterpriseSignup:label.annuallyOption"),
              value: "ANNUALLY",
            },
          ]}
          className="form-item"
        />
        <FormItem
          type="textarea"
          direction="vertical"
          label={t("enterpriseSignup:label.inquiryContent")}
          control={control}
          name="body"
          rules={{ required: true }}
          inputProps={{
            placeholder: t("enterpriseSignup:placeholder.inquiryContent"),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
          className="form-item"
        />

        <ButtonContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={goToNormalPlanPage}
          >
            {t("enterpriseSignup:button.back")}
          </Button>
          <Button
            onClick={() => {
              setAlertDialogState(AlertDialogState.SUBMIT);
            }}
          >
            {t("enterpriseSignup:button.inquiry")}
          </Button>
        </ButtonContainer>
      </Form>

      {renderAlertDialog()}
    </StyledSectionCard>
  );
}

export default EnterpriseInquiry;

const StyledSectionCard = styled(SectionCard)`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;

  .form-item {
    width: 100%;
  }
`;

const Description = styled(Typo)`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const StyledCallOut = styled(CallOut)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  button {
    flex: 1;
    text-align: center;
  }
`;
