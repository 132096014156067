import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import colorSet from "@/src/styles/color";
import dayjs from "dayjs";
import styled from "styled-components";

interface GalleryFileCardProps {
  fileSrc?: string;
  category?: string;
  fileName?: string;
  uploadedAt?: string;
  onClick?: () => void;
}

const GalleryFileCard = ({
  category,
  fileSrc,
  fileName,
  uploadedAt,
  onClick,
}: GalleryFileCardProps) => {
  return (
    <Article onClick={onClick}>
      <Container>
        <StyledFigure>
          <img src={fileSrc} alt="png" />
        </StyledFigure>
      </Container>

      <FlexColumn>
        <Typo as="p" typoType="b9r" className="ellipsis">
          {fileName}
        </Typo>

        <Flex>
          <Typo typoType="b9m" color="gray6">
            {category}
          </Typo>
          <ColDivider />
          <Typo typoType="b9m" color="gray6">
            {dayjs(uploadedAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm)}
          </Typo>
        </Flex>
      </FlexColumn>
    </Article>
  );
};

export default GalleryFileCard;

const StyledFigure = styled.figure`
  width: 80%;
  height: 80%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  cursor: pointer;
  width: 210px;
  &:hover {
    ${StyledFigure} {
      transition: all 0.25s ease-out;
      transform: scale(1.05);
    }
  }
`;

const Container = styled.div`
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${colorSet.gray10};
  border: 1px solid ${colorSet.gray9};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ColDivider = styled.div`
  height: 14px;
  border-right: 1px solid ${colorSet.gray9};
  margin: 0 8px;
`;
