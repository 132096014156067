import Badge from "@/src/components/atom/Badge";
import {
  PlanType,
  SubscriptionStatusType,
} from "@/src/store/apis/subscription/interface";
import { TFunction } from "i18next";

export const renderSubscriptionStatusBadge = (param: {
  t: TFunction;
  type?: PlanType | SubscriptionStatusType | null;
  size?: "S" | "L";
}) => {
  const { t, type, size = "S" } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    // PlanType
    case "NORMAL":
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.normal")}
          color="systemLime1"
          badgeColor="systemLime5"
          badgeSize={size}
        />
      );
    case "ENTERPRISE":
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.enterprise")}
          color="systemLime1"
          badgeColor="systemLime5"
          badgeSize={size}
        />
      );
    // SubscriptionStatusType
    case "PROGRESS":
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.progress")}
          color="systemLime1"
          badgeColor="systemLime5"
          badgeSize={size}
        />
      );
    case "CANCELED":
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.canceled")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
    case "PAYMENT_FAIL":
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.paymentFail")}
          color="red2"
          badgeColor="red8"
          badgeSize={size}
        />
      );
    case "FINISHED":
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.finished")}
          color="gray7"
          badgeColor="gray10"
          badgeSize={size}
        />
      );

    default:
      return <></>;
  }
};
