import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { shipmentDetailApi } from "@/src/store/apis/shipments/shipmentDetail";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

function ShipmentCard() {
  const { t } = useTranslation();
  const params = useParams();
  const {
    buyerName,
    docCutOffAt,
    etdAt,
    etaAt,
    shippingLine,
    vesselAndVoyageNo,
    portOfLoading,
    placeOfDelivery,
  } = shipmentDetailApi.endpoints.getShipmentDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          buyerName: !isUnstable ? (currentData.buyerName ?? "-") : "-",
          docCutOffAt:
            !isUnstable && currentData.docCutOffAt
              ? dayjs(currentData?.docCutOffAt).format(
                  DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
                )
              : "-",
          etdAt:
            !isUnstable && currentData.etdAt
              ? dayjs(currentData?.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : "-",
          etaAt:
            !isUnstable && currentData.etaAt
              ? dayjs(currentData?.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : "-",
          shippingLine: !isUnstable ? (currentData.shippingLine ?? "-") : "-",
          vesselAndVoyageNo:
            !isUnstable && currentData.vessel && currentData.voyageNo
              ? `${currentData.vessel} ${currentData.voyageNo}`
              : "-",
          portOfLoading: !isUnstable ? (currentData.portOfLoading ?? "-") : "-",
          placeOfDelivery: !isUnstable
            ? (currentData.placeOfDelivery ?? "-")
            : "-",
        };
      },
    },
  );

  return (
    <SectionCardGrid>
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:buyer")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{buyerName}</BreakWordTypo>}
      />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:docCutOff")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{docCutOffAt}</BreakWordTypo>}
      />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:etd")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{etdAt}</BreakWordTypo>}
      />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:eta")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{etaAt}</BreakWordTypo>}
      />

      <GrayLine />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:shippingLine")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{shippingLine}</BreakWordTypo>}
      />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:vesselAndVoyageNo")}
          </Typo>
        }
        direction="vertical"
        value={<Typo typoType="h4">{vesselAndVoyageNo}</Typo>}
      />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:portOfLoading")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{portOfLoading}</BreakWordTypo>}
      />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:placeOfDelivery")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{placeOfDelivery}</BreakWordTypo>}
      />
    </SectionCardGrid>
  );
}

export default ShipmentCard;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;

  p {
    max-width: 100%;
  }
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;
