import CheckCircleSvg from "@/src/assets/icons/icon-checkmark-circle-green-fill.svg";
import FooterPng from "@/src/assets/images/img-email-footer.png";
import WorldMapPng from "@/src/assets/images/img-world-map.png";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import PUBLIC_PATH from "@/src/routes/public/path";
import colorSet from "@/src/styles/color";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

function EmailFooter() {
  const { t } = useTranslation();
  const location = useLocation();
  const sharedEncryptKey = location.search.slice(1);

  const goToLogin = () => {
    window.open(PUBLIC_PATH.LOGIN, "_blank");
  };

  const goToCorporateSignUp = () => {
    window.open(
      `${PUBLIC_PATH.TEMPORARY_SIGNUP}?buyer=${sharedEncryptKey}`,
      "_blank",
    );
  };

  const descriptionList = [
    t("shareEmail:sharedFooter.digitalizedContainerInspection"),
    t("shareEmail:sharedFooter.automaticDocumentation"),
    t("shareEmail:sharedFooter.RealTimeCargoTracking"),
    t("shareEmail:sharedFooter.highUtilizationDashboard"),
  ];

  return (
    <StyledSectionCardWithoutHeader>
      <FooterContainer>
        {/* EcoyaInformation */}
        <EcoyaInformation>
          <StyledTypo typoType="h1">
            {t("shareEmail:sharedFooter.title")}
          </StyledTypo>

          <Image src={FooterPng} />
          <ListContainer>
            <WorldMapImage src={WorldMapPng} />
            <List>
              {descriptionList.map((string, idx) => {
                return (
                  <ListItem key={idx.toString()}>
                    <StyledIcon iconSrc={CheckCircleSvg} iconSize={24} />

                    <Typo typoType="b7m">{string}</Typo>
                  </ListItem>
                );
              })}
            </List>
          </ListContainer>

          <ButtonContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={goToLogin}
            >
              {t("shareEmail:sharedFooter.button.login")}
            </Button>
            <Button onClick={goToCorporateSignUp}>
              {t("shareEmail:sharedFooter.button.corpSignUP")}
            </Button>
          </ButtonContainer>
        </EcoyaInformation>

        <Divider />

        {/* Copyright */}
        <Copyright>
          <Typo typoType="b9r" color="gray6">
            {t("shareEmail:sharedFooter.emailNotification")}
          </Typo>
          <Typo typoType="b9r" color="gray6">
            {t("shareEmail:sharedFooter.copyright")}
          </Typo>
        </Copyright>
      </FooterContainer>
    </StyledSectionCardWithoutHeader>
  );
}

export default EmailFooter;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 32px 56px;
  margin-top: 24px;
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 560px;
`;

const EcoyaInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0px 16px 32px;
`;

const StyledTypo = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px 0 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;

  & > button {
    flex: 1;
    text-align: center;
  }
`;

const Image = styled.img`
  height: 396px;
  object-fit: cover;
  border-radius: 8px;
`;

const ListContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const WorldMapImage = styled.img`
  width: 240px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
