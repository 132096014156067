import ImagePlaceholderSvg from "@/src/assets/icons/icon-image.svg";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import { lineClamp } from "@/src/styles/truncate";
import typo from "@/src/styles/typography";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface TaskThumbnailProps {
  imageUrlList: (string | undefined)[];
  title: ReactNode;
  description?: ReactNode;
  icon: ReactNode;
  onClickImage: (imageUrl: string) => void;
  index: number;
}

function TaskThumbnail({
  title,
  description,
  icon,
  index,
  onClickImage,
  imageUrlList,
}: TaskThumbnailProps) {
  const { t } = useTranslation();
  const [imageListHoverIndex, setImageListHoverIndex] = useState<number>(0);

  return (
    <TaskInformationItem>
      <TaskTypeInfo>
        <TaskDetailTypeContainer>
          {icon}
          <Typo typoType="b10r" color="gray6">
            {t("common:loadingPhotoType")} {index + 1}
          </Typo>
          {title}
          {description && <Clamp>{description}</Clamp>}
        </TaskDetailTypeContainer>

        <TaskImageList>
          {imageUrlList.map((image, imageIdx) => {
            return (
              <TaskImage
                key={imageIdx.toString()}
                imageUrl={image || ""}
                onMouseOver={() => {
                  if (!image) {
                    return;
                  }
                  setImageListHoverIndex(imageIdx);
                }}
                $isOpacity={imageListHoverIndex !== imageIdx}
                onClick={() => {
                  if (!image) {
                    return;
                  }

                  onClickImage(image);
                }}
              />
            );
          })}
        </TaskImageList>
      </TaskTypeInfo>
      <StyledTaskThumbnail
        imageUrl={imageUrlList[imageListHoverIndex] || ""}
        onClick={() => {
          if (!imageUrlList[imageListHoverIndex]) {
            return;
          }

          onClickImage(imageUrlList[imageListHoverIndex] || "");
        }}
      />
    </TaskInformationItem>
  );
}

export default TaskThumbnail;

const TaskInformationItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${colorSet.gray9};
`;

const TaskTypeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  min-height: 150px;
`;

const TaskDetailTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const TaskImageList = styled.div`
  display: flex;
  gap: 8px;
  margin-top: auto;
`;

const StyledTaskThumbnail = styled.div<{ imageUrl: string }>`
  flex: 1;
  min-width: 150px;
  min-height: 150px;
  border-radius: 8px;
  background: ${({ imageUrl }) =>
      !!imageUrl ? `url(${imageUrl})` : `url(${ImagePlaceholderSvg})`}
    no-repeat;
  background-color: ${({ imageUrl }) =>
    !!imageUrl ? "none" : `${colorSet.gray11}`};
  background-position: center;
  background-size: ${({ imageUrl }) => (imageUrl ? "cover" : "auto")};
  border: 1px solid ${colorSet.gray10};
  cursor: ${({ imageUrl }) => (imageUrl ? "pointer" : "not-allowed")};
`;

const TaskImage = styled.div<{ imageUrl: string; $isOpacity: boolean }>`
  max-width: 44px;
  flex: 1;
  height: 44px;
  border-radius: 8px;
  background: ${({ imageUrl }) =>
      !!imageUrl ? `url(${imageUrl})` : `url(${ImagePlaceholderSvg})`}
    no-repeat;
  background-color: ${({ imageUrl }) =>
    !!imageUrl ? "none" : `${colorSet.gray11}`};
  background-position: center;
  background-size: ${({ imageUrl }) => (imageUrl ? "contain" : "auto")};
  border: 1px solid ${colorSet.gray10};

  opacity: ${({ $isOpacity }) => ($isOpacity ? 0.5 : 1)};
  cursor: ${({ imageUrl }) => (imageUrl ? "pointer" : "not-allowed")};
`;

const Clamp = styled.div`
  ${lineClamp(2)};
  ${typo.b9r};
  color: ${colorSet.gray5};
`;
