import Dialog from "@/src/components/atom/Dialog";
import Table from "@/src/components/atom/Table";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { authApi } from "@/src/store/apis/auth";
import { commonApi } from "@/src/store/apis/common";
import { useLazyGetPaymentsQuery } from "@/src/store/apis/subscription";
import { PaymentDto } from "@/src/store/apis/subscription/interface";
import colorSet from "@/src/styles/color";
import { openInvoiceInNewWindow } from "@/src/utils/invoice";
import { isUndefined } from "@/src/utils/is";
import { createUnderLineLinkAction } from "@/src/utils/row-data-util";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { columnPaymentList } from "../../columns/columnPaymentList";
import { getAmountOfPayment } from "../../utils/getPayments";

interface PaymentDetailDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

const emptyArray: PaymentDto[] = [];

function PaymentDetailDialog({ open, onOpenChange }: PaymentDetailDialogProps) {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();

  const [columnDefs] = useState<ColDef[]>(columnPaymentList);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);

  // API
  const session = authApi.endpoints.getSession.useQueryState(undefined);
  const { countryList } =
    commonApi.endpoints.getCommonCodeViaCodeName.useQueryState(
      {
        codeName: "COUNTRY",
      },
      {
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;
          const isStable = !isUnstable;

          return {
            isFetching,
            countryList: isStable ? currentData : [],
          };
        },
      },
    );
  const [getPayments, { isFetching, count, list }] = useLazyGetPaymentsQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;
      const isStable = !isUnstable;

      return {
        isFetching,
        count: isStable ? currentData.count : 0,
        list: isStable ? currentData.rows : emptyArray,
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "createdAt",
        langKey: "subscriptionManagement:paymentList.table.createdAt",
      },
      {
        columnKey: "refundDateAt",
        langKey: "subscriptionManagement:paymentList.table.refundDateAt",
      },
      {
        columnKey: "paymentDetail",
        langKey: "subscriptionManagement:paymentList.table.paymentDetail",
      },
      {
        columnKey: "paymentStatus",
        langKey: "subscriptionManagement:paymentList.table.paymentStatus",
      },
      {
        columnKey: "paymentNo",
        langKey: "subscriptionManagement:paymentList.table.paymentNo",
      },
      {
        columnKey: "cardNumber",
        langKey: "subscriptionManagement:paymentList.table.cardNumber",
      },
      {
        columnKey: "price",
        langKey: "subscriptionManagement:paymentList.table.price",
      },
      {
        columnKey: "tax",
        langKey: "subscriptionManagement:paymentList.table.tax",
      },
      {
        columnKey: "amount",
        langKey: "subscriptionManagement:paymentList.table.amount",
      },
    ],
  });

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;
      const params = {
        page,
        pageSize,
        exporterId: session.currentData?.row.exporter.id,
      };

      try {
        await getPayments(params).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getPayments,
      pagination.page,
      pagination.pageSize,
      session.currentData?.row.exporter.id,
    ],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  const handlePaymentNoClick = async (payment: any) => {
    const price = getAmountOfPayment({
      exchangeRate: Number(payment?.exchangeRate),
      price: Number(payment.price),
      currencyUnit: payment.currencyUnit,
    });

    const tax = price / 10 || 0;

    openInvoiceInNewWindow({
      invoiceNo: payment.paymentNo,
      invoiceDate: payment.createdAt,
      buyer: payment.exporter.companyName,
      buyerAddress: `${[
        session.currentData?.row?.exporter.postalCode || "",
        session.currentData?.row?.exporter.streetAddress || "",
        session.currentData?.row?.exporter.locality || "",
        session.currentData?.row?.exporter.region || "",
      ]
        .filter(Boolean)
        .join(", ")}`,
      buyerContact: `${session.currentData?.row?.exporter.telPrefix}) ${session.currentData?.row?.exporter.tel}`,
      buyerCountry:
        countryList
          .find(
            (item) =>
              item.codeItemName ===
              session.currentData?.row.exporter.countryCodeItemName,
          )
          ?.codeItemNameEn.toUpperCase() || "-",
      paymentDate: "-",
      currencyUnit: payment.currencyUnit as string,
      items: [
        {
          description: payment.subscription.plan.name,
          unitPrice: price - price / 10,
          tax,
          amount: price,
        },
      ],
      period: `${dayjs(payment.subscription.subscriptionStartAtFrom).format(
        "YYYY-MM-DD",
      )} ~ ${dayjs(payment.subscription.originalSubscriptionEndAtTo).format(
        "YYYY-MM-DD",
      )}`,
      total: {
        unitPrice: price - price / 10,
        tax,
        totalPrice: price,
      },
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t("subscriptionManagement:subscription.paymentDetails")}
      open={open}
      onOpenChange={onOpenChange}
      width={1000}
    >
      <TableContainer>
        <Table
          ref={gridRef}
          rowData={list}
          columnDefs={columnDefs}
          isPaginationDataMaping
          totalPage={count}
          pageSize={pagination.pageSize}
          page={pagination.page}
          handlePaginationClick={handlePaginationClick}
          onGridReady={() => {
            setIsReady(true);
          }}
          components={{
            ...createUnderLineLinkAction(handlePaymentNoClick, "paymentNo"),
          }}
        />
      </TableContainer>
    </Dialog>
  );
}

export default PaymentDetailDialog;

const TableContainer = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;
