import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import { Button } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Icon from "@/src/components/atom/Icon";
import Label from "@/src/components/atom/Label";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import { InputError } from "@/src/components/molecule/FormItem";
import VerificationInputDiv from "@/src/components/molecule/VerificationInputDiv";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import {
  subscriptionApi,
  useFreeCodeRegisteredMutation,
  useLazyGetCurrentSubscriptionQuery,
  useLazyGetFreeCodesQuery,
} from "@/src/store/apis/subscription";
import { FreeCodeDto } from "@/src/store/apis/subscription/interface";
import { ErrorResponse } from "@/src/store/apis/type";
import colorSet, { ColorType } from "@/src/styles/color";
import dayjs from "dayjs";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

interface FreeCodeDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CANCEL,
}

type FreeCodeStepType = "FREE_CODE_INPUT" | "FREE_CODE_CONFIRM";

const FREE_CODE_EMPTY_LIST = "FREE_CODE_EMPTY_LIST";
const DEFAULT_VERIFICATION = ["", "", "", "", "", "", "", ""];

function FreeCodeDialog({ open, onOpenChange }: FreeCodeDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const ref = useRef<HTMLDivElement[]>([]);

  const [isConfirm, setIsConfirm] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [searchFreeCode, setSearchFreeCode] = useState<FreeCodeDto | null>(
    null,
  );
  const [freeCodeStep, setFreeCodeStep] =
    useState<FreeCodeStepType>("FREE_CODE_INPUT");
  const [isError, setIsError] = useState(false);
  const [verification, setVerification] =
    useState<string[]>(DEFAULT_VERIFICATION);

  // API
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();
  const [freeCodeRegister] = useFreeCodeRegisteredMutation();
  const [getFreeCodes] = useLazyGetFreeCodesQuery();
  const {
    numberOfMember,
    numberOfLoadingCode,
    subscriptionEndAtTo,
    subscriptionId,
  } = subscriptionApi.endpoints.getSubscriptions.useQueryState(
    {
      page: 1,
      pageSize: 10,
    },
    {
      selectFromResult: ({ currentData }) => {
        return {
          numberOfMember: currentData?.rows?.[0].planVersion.numberOfMember,
          numberOfLoadingCode:
            currentData?.rows?.[0].planVersion.numberOfLoadingCode,
          subscriptionEndAtTo: currentData?.rows[0].subscriptionEndAtTo,
          subscriptionId: currentData?.rows[0].id,
        };
      },
    },
  );

  const handleFreeCodeSearchClick = async () => {
    try {
      const res = await getFreeCodes({
        code: verification.join(""),
        freeCodeStatus: "FRESH",
      }).unwrap();

      setFreeCodeStep("FREE_CODE_CONFIRM");
      setIsError(false);
      setSearchFreeCode(res.rows[0]);
    } catch (e) {
      const error = e as ErrorResponse;

      if (error?.data.errorCode === FREE_CODE_EMPTY_LIST) {
        setIsError(true);
      }
    }
  };

  const handleFreeCodeRegister = async () => {
    if (!subscriptionId || !searchFreeCode) {
      return;
    }

    try {
      await freeCodeRegister({
        code: searchFreeCode.code,
        id: subscriptionId,
      }).unwrap();
      await getCurrentSubscription();

      onOpenChange(false);
      alert.showAlert({
        type: "success",
        message: t("alert:freeCodeUse"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  const handleCancelClick = () => {
    setAlertDialogState(AlertDialogState.CANCEL);
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onOpenChange(false);
          }}
        />
      );
    }
  };

  useEffect(() => {
    ref.current[0].focus();
  }, []);

  const renderContent = () => {
    if (freeCodeStep === "FREE_CODE_INPUT") {
      return (
        <FlexColumn gap={40}>
          <FlexColumn gap={12}>
            <SubTitle typoType="d6">
              {t(
                "subscriptionManagement:subscription.PleaseEnterTheFreeTrialCode",
              )}
            </SubTitle>
            <WhiteSpaceTypo typoType="b7m">
              {t(
                "subscriptionManagement:subscription.pleaseEnter8DigitsOfTheFreeTrialCode",
              )}
            </WhiteSpaceTypo>
          </FlexColumn>

          <VerificationDiv>
            <Label required>
              {t("subscriptionManagement:subscription.freeTrialCode")}
            </Label>
            <VerificationInputDivContainer
              onKeyDown={(e) => {
                if (e.code === "Enter" && verification.every((item) => item)) {
                  handleFreeCodeSearchClick();
                }
              }}
            >
              {verification.map((_, idx) => {
                return (
                  <StyledVerificationInputDiv
                    key={idx.toString()}
                    ref={(node) => node && ref.current.push(node)}
                    char={verification[idx]}
                    style={idx === 3 ? { marginRight: "20px" } : undefined}
                    onKeyDown={(e) => {
                      const key = e.key;
                      if (key === "ArrowLeft") {
                        if (idx - 1 !== -1) {
                          ref.current[idx - 1].focus();
                        }
                      }
                      if (key === "ArrowRight") {
                        if (idx + 1 !== verification.length) {
                          ref.current[idx + 1].focus();
                        }
                      }
                      if ((e.ctrlKey || e.metaKey) && e.key === "v") {
                        navigator.clipboard.readText().then((res) => {
                          const overridden = Array.from(
                            { length: 8 },
                            () => "",
                          );
                          const pasteText = res
                            .split("")
                            .slice(0, 8)
                            .concat(overridden)
                            .slice(0, 8);
                          setVerification(pasteText);
                        });
                      }

                      setIsError(false);
                    }}
                    onCharChange={(char) => {
                      if (!!verification[idx]) {
                        if (idx + 1 !== verification.length) {
                          ref.current[idx + 1].focus();
                        }
                        return;
                      }
                      const copied = verification.slice();
                      copied.splice(idx, 1, char ?? "");
                      setVerification(copied);
                      if (idx + 1 !== verification.length) {
                        ref.current[idx + 1].focus();
                      }
                      setIsError(false);
                    }}
                    onBackSpaceKeyDown={() => {
                      const copied = verification.slice();
                      copied.splice(idx, 1, "");
                      setVerification(copied);
                      if (idx - 1 !== -1) {
                        ref.current[idx - 1].focus();
                      }
                      setIsError(false);
                    }}
                    isValidation={isError}
                  />
                );
              })}
            </VerificationInputDivContainer>
            {isError && (
              <StyledInputError
                message={t("subscriptionManagement:subscription.invalidCode")}
              />
            )}
          </VerificationDiv>

          <CheckboxContainer>
            <Checkbox
              checked={isConfirm}
              onChange={() => setIsConfirm((prev) => !prev)}
            />
            <Typo typoType="b7r">
              {t("subscriptionManagement:subscription.confirmText")}
            </Typo>
          </CheckboxContainer>
        </FlexColumn>
      );
    }

    // 1~30일: 이용 중인 플랜의 작업코드 개수만큼 부여
    // 60일: 이용 중인 플랜의 작업코드 개수의 2배를 부여
    // 90일: 이용 중인 플랜의 작업코드 개수의 3배를 부여
    if (
      freeCodeStep === "FREE_CODE_CONFIRM" &&
      numberOfLoadingCode &&
      searchFreeCode?.freeDateCount
    ) {
      let loadingCodeCount = 0;

      if (searchFreeCode.freeDateCount <= 30) {
        loadingCodeCount = numberOfLoadingCode;
      }
      if (searchFreeCode.freeDateCount === 60) {
        loadingCodeCount = numberOfLoadingCode * 2;
      }
      if (searchFreeCode.freeDateCount === 90) {
        loadingCodeCount = numberOfLoadingCode * 3;
      }

      const nextPaymentDate = dayjs(subscriptionEndAtTo).add(
        searchFreeCode.freeDateCount,
        "day",
      );
      const nextPaymentDay = nextPaymentDate.get("date");

      return (
        <FlexColumn gap={40}>
          <SubTitle typoType="d6">
            {t(
              "subscriptionManagement:subscription.pleaseCheckTheCodeInformation",
            )}
          </SubTitle>

          <FlexColumn gap={16}>
            <CallOut borderColor="blue9" backgroundColor="blue10">
              <Flex alignItems="center" gap={8}>
                <InfoIcon color="blue4" />
                <Typo color="indigo" typoType="b9r">
                  {t(
                    "subscriptionManagement:subscription.whenYouRegisterAFreeTrialCodeThePaymentDateWillChange",
                  )}
                </Typo>
              </Flex>
            </CallOut>

            <CallOut borderColor="gray9" backgroundColor="white">
              <FlexColumn gap={16}>
                <FlexColumn gap={16}>
                  <Typo typoType="h4">
                    {searchFreeCode.freeDateCount}{" "}
                    {t("subscriptionManagement:subscription.daysFree")}
                  </Typo>
                  <Flex gap={4} alignItems="center">
                    <Typo typoType="b7m">
                      {dayjs(subscriptionEndAtTo).format(
                        DATE_FORMAT_STRINGS.YYYY_MM_DD,
                      )}{" "}
                      ~ {nextPaymentDate.format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
                    </Typo>
                    <Typo typoType="b9r" color="blue4">
                      (
                      {t(
                        "subscriptionManagement:subscription.changePaymentDate",
                      )}
                      : {nextPaymentDay}
                      {t("subscriptionManagement:subscription.th")})
                    </Typo>
                  </Flex>
                </FlexColumn>
                <Divider />
                <FlexColumn gap={8}>
                  <Flex gap={8} alignItems="center">
                    <Icon iconSrc={IconCheck} iconSize={16} />
                    <Typo>
                      {t("subscriptionManagement:subscription.activeAccounts")}{" "}
                      {numberOfMember}
                      {t("subscriptionManagement:subscription.users")}
                    </Typo>
                  </Flex>

                  <Flex gap={8} alignItems="center">
                    <Icon iconSrc={IconCheck} iconSize={16} />
                    <Typo>
                      {t("subscriptionManagement:subscription.loadingCode")}{" "}
                      {loadingCodeCount}{" "}
                      {t(
                        "subscriptionManagement:subscription.timesTransmissionPossible",
                      )}
                    </Typo>
                  </Flex>
                </FlexColumn>
              </FlexColumn>
            </CallOut>
          </FlexColumn>
        </FlexColumn>
      );
    }
  };

  const renderButtonContainer = () => {
    if (freeCodeStep === "FREE_CODE_INPUT") {
      return (
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => handleCancelClick()}
          >
            {t("subscriptionManagement:subscription.button.cancel")}
          </Button>
          <Button
            onClick={handleFreeCodeSearchClick}
            disabled={verification.join("").length !== 8 || !isConfirm}
          >
            {t("subscriptionManagement:subscription.button.confirm")}
          </Button>
        </DialogFooterContainer>
      );
    }

    if (freeCodeStep === "FREE_CODE_CONFIRM") {
      return (
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => handleCancelClick()}
          >
            {t("subscriptionManagement:subscription.button.cancel")}
          </Button>
          <Button onClick={handleFreeCodeRegister}>
            {t("subscriptionManagement:subscription.button.ok")}
          </Button>
        </DialogFooterContainer>
      );
    }
  };

  return (
    <Dialog
      title={t("subscriptionManagement:subscription.freeCodeDialogTitle")}
      open={open}
      onOpenChange={onOpenChange}
      width={640}
      footer={renderButtonContainer()}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
    >
      {renderContent()}
      {renderAlertDialog()}
    </Dialog>
  );
}

export default FreeCodeDialog;

const FlexColumn = styled.div<{ gap?: number }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

const SubTitle = styled(Typo)`
  text-align: center;
`;

const WhiteSpaceTypo = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
`;

const VerificationDiv = styled.div`
  margin: 0 auto;
`;

const VerificationInputDivContainer = styled.div`
  display: flex;
  gap: 4px;
  padding-top: 8px;
`;

const StyledVerificationInputDiv = styled(VerificationInputDiv)<{
  isValidation: boolean;
}>`
  ${({ isValidation }) =>
    isValidation &&
    css`
      border: 1px solid ${colorSet.red2};
    `}
`;

const StyledInputError = styled(InputError)`
  margin-top: 8px;
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  * {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const Flex = styled.div<{
  direction?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  gap?: number;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap || 0}px;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const CheckboxContainer = styled.label`
  display: flex;
  gap: 8px;
  width: 464px;
  margin: 0 auto;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: relative;
    top: 3px;
    flex-shrink: 0;
  }
`;
