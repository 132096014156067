import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SessionTimeSliceState {
  remainingTime: number;
}

const initialState: SessionTimeSliceState = {
  remainingTime: 60 * 60, // 60분 초
};

export const sessionTimeSlice = createSlice({
  name: "sessionTime",
  initialState,
  reducers: {
    setRemainingTime: (state, action: PayloadAction<number>) => {
      state.remainingTime = action.payload;
    },
    resetTimer: (state) => {
      state.remainingTime = initialState.remainingTime;
    },
  },
});

export const { setRemainingTime, resetTimer } = sessionTimeSlice.actions;
export default sessionTimeSlice.reducer;
