import { apiSlice } from "../../baseQuery";
import { IsBookmarkedInfoDto } from "../../contracts/contractDetail/interface";
import { Row, Rows } from "../../type";
import {
  BookingBookmarkListViewDto,
  ExporterUserBookingBookmarkInfoDto,
} from "../bookingList/interface";
import {
  BookingDetailViewDto,
  BookingTaskSummaryInfoDto,
  ModifyBookingStatusDto,
  SendBookingInfoEmailV2Dto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["detail", "bookmark", "importer-detail", "importer-bookmark"],
});

const bookingDetailApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 부킹 단일 조회
    getBookingDetail: build.query<BookingDetailViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/bookings/${id}`,
      }),
      transformResponse: (res: Row<BookingDetailViewDto>) => res.row,
      providesTags: ["detail"],
    }),

    // 부킹 상태 취소
    cancelBookingDetail: build.mutation<void, ModifyBookingStatusDto>({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/booking-status-to-canceled-booking`,
        body: body,
      }),
      invalidatesTags: ["detail"],
    }),

    // 수출입웹 유저의 단일 부킹에 대한 북마크 정보 조회
    getBookingDetailBookmark: build.query<
      ExporterUserBookingBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporter-users/this/bookings/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserBookingBookmarkInfoDto>) =>
        res.row,
      providesTags: ["bookmark"],
    }),

    // 부킹을 북마크한 유저 목록 조회
    getBookingDetailBookmarkUser: build.query<
      Rows<BookingBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/bookings/${id}/booking-bookmarks`,
      }),
      providesTags: ["bookmark"],
    }),

    // 수출입웹 유저의 단일 부킹에 대한 북마크 업데이트
    updateBookingDetailBookmark: build.mutation<
      Row<ExporterUserBookingBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporter-users/this/bookings/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["bookmark"],
    }),

    // (v2)부킹 정보 이메일 발송 API
    bookingDetailSendEmail: build.mutation<
      void,
      SendBookingInfoEmailV2Dto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/v2/exporters/this/bookings/${id}/send-booking-info-email`,
        body: body,
      }),
    }),

    // 부킹 상세에서 부킹과 연결된 작업에 대한 요약 정보 조회 API
    getBookingTaskSummaryInfo: build.query<
      BookingTaskSummaryInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/bookings/${id}/task-summary-info`,
      }),
      transformResponse: (res: Row<BookingTaskSummaryInfoDto>) => res.row,
    }),

    getImporterBookingDetail: build.query<BookingDetailViewDto, { id: number }>(
      {
        query: ({ id }) => ({
          url: `/importers/this/bookings/${id}`,
        }),
        transformResponse: (res: Row<BookingDetailViewDto>) => res.row,
        providesTags: ["importer-detail"],
      },
    ),
    addImporterBookingMemo: build.mutation<
      BookingDetailViewDto,
      { id: number; memo: string }
    >({
      query: ({ id, memo }) => ({
        method: "POST",
        url: `/importers/this/bookings/${id}/memos`,
        body: { memo },
      }),
      invalidatesTags: ["importer-detail"],
    }),

    // 수출입웹 유저의 단일 부킹에 대한 북마크 정보 조회
    getImporterBookingDetailBookmark: build.query<
      ExporterUserBookingBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importer-users/this/bookings/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserBookingBookmarkInfoDto>) =>
        res.row,
      providesTags: ["importer-bookmark"],
    }),
    // 부킹을 북마크한 유저 목록 조회
    getImporterBookingDetailBookmarkUser: build.query<
      Rows<BookingBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/bookings/${id}/booking-bookmarks`,
      }),
      providesTags: ["importer-bookmark"],
    }),

    // 수출입웹 유저의 단일 부킹에 대한 북마크 업데이트
    updateImporterBookingDetailBookmark: build.mutation<
      Row<ExporterUserBookingBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/importer-users/this/bookings/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["importer-bookmark"],
    }),
  }),
});

export const {
  useGetBookingDetailQuery,
  useCancelBookingDetailMutation,
  useLazyGetBookingDetailQuery,

  useGetBookingDetailBookmarkQuery,
  useGetBookingDetailBookmarkUserQuery,
  useUpdateBookingDetailBookmarkMutation,
  useBookingDetailSendEmailMutation,
  useGetBookingTaskSummaryInfoQuery,

  useGetImporterBookingDetailQuery,
  useAddImporterBookingMemoMutation,

  useGetImporterBookingDetailBookmarkQuery,
  useGetImporterBookingDetailBookmarkUserQuery,
  useUpdateImporterBookingDetailBookmarkMutation,
} = bookingDetailApi;
export { bookingDetailApi };
