import EditSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { useAppSelector } from "@/src/store";
import { companyApi } from "@/src/store/apis/corporate/company";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BankDetailEditDialog from "../dialog/BankDetailEditDialog";
import { FlexCenter } from "./BasicInformation";

enum DialogState {
  NULL,
  BANK_DETAIL,
}

const BankDetails = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const {
    address,
    bankName,
    bankTel,
    bankFax,
    swiftCode,
    accountNumber,
    accountName,
    isFetching,
  } = companyApi.endpoints.getExporters.useQueryState(undefined, {
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined = isError || currentData === undefined;

      const mergedAddress = !isErrorAndUndefined
        ? [
            currentData?.exporterDetailQueryResultDto.bankPostalCode,
            currentData?.exporterDetailQueryResultDto.bankStreetAddress,
            currentData?.exporterDetailQueryResultDto.bankLocality,
            currentData?.exporterDetailQueryResultDto.bankRegion,
            currentData?.exporterDetailQueryResultDto.bankCountryName,
          ].filter(Boolean)
        : ["-"];

      return {
        isFetching,
        address: mergedAddress.length >= 1 ? mergedAddress.join(", ") : "-",
        bankName: !isErrorAndUndefined
          ? (currentData.exporterDetailQueryResultDto.bankName ?? "-")
          : "-",
        bankTel:
          !isErrorAndUndefined &&
          currentData.exporterDetailQueryResultDto.bankTelPrefix &&
          currentData.exporterDetailQueryResultDto.bankTel
            ? `${currentData?.exporterDetailQueryResultDto.bankTelPrefix} ${currentData?.exporterDetailQueryResultDto.bankTel}`
            : "-",
        bankFax:
          !isErrorAndUndefined &&
          currentData?.exporterDetailQueryResultDto.bankFaxPrefix &&
          currentData?.exporterDetailQueryResultDto.bankFax
            ? `${currentData.exporterDetailQueryResultDto.bankFaxPrefix} ${currentData.exporterDetailQueryResultDto.bankFax}`
            : "-",
        swiftCode: !isErrorAndUndefined
          ? (currentData.exporterDetailQueryResultDto.swiftCode ?? "-")
          : "-",
        accountNumber: !isErrorAndUndefined
          ? (currentData.exporterDetailQueryResultDto.accountNumber ?? "-")
          : "-",
        accountName: !isErrorAndUndefined
          ? (currentData.exporterDetailQueryResultDto.accountName ?? "-")
          : "-",
      };
    },
  });

  const [bankDialogState, setBankDialogState] = useState(DialogState.NULL);

  const renderBankDialog = () => {
    if (bankDialogState === DialogState.NULL) {
      return;
    }

    if (bankDialogState === DialogState.BANK_DETAIL) {
      return (
        <BankDetailEditDialog
          open={bankDialogState === DialogState.BANK_DETAIL}
          onOpenChange={() => setBankDialogState(DialogState.NULL)}
        />
      );
    }
  };

  return (
    <SectionCard
      cardTitle={t("companyManagement:exporter.companyInfo.bankDetail.title")}
      rightAccessory={
        user?.exporterUserType === "CORPORATE_MANAGER" && (
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => setBankDialogState(DialogState.BANK_DETAIL)}
            disabled={isFetching}
            isLoading={isFetching}
          >
            <FlexCenter>
              <Icon iconSrc={EditSvg} iconSize={16} />
              {t(
                "companyManagement:exporter.companyInfo.bankDetail.editButton",
              )}
            </FlexCenter>
          </Button>
        )
      }
    >
      {renderBankDialog()}

      <BankDetailContainer>
        <SectionCardRow
          label={t(
            "companyManagement:exporter.companyInfo.bankDetail.bankName",
          )}
          direction="horizontal"
          value={bankName ?? "-"}
        />
        <SectionCardRow
          label={t(
            "companyManagement:exporter.companyInfo.bankDetail.bankAddress",
          )}
          direction="horizontal"
          value={address}
        />
        <SectionCardRow
          label={t("companyManagement:exporter.companyInfo.bankDetail.bankTel")}
          direction="horizontal"
          value={bankTel}
        />
        <SectionCardRow
          label={t("companyManagement:exporter.companyInfo.bankDetail.bankFax")}
          direction="horizontal"
          value={bankFax}
        />
        <SectionCardRow
          label={t(
            "companyManagement:exporter.companyInfo.bankDetail.swiftCode",
          )}
          direction="horizontal"
          value={swiftCode}
        />
        <SectionCardRow
          label={t(
            "companyManagement:exporter.companyInfo.bankDetail.accountNumber",
          )}
          direction="horizontal"
          value={accountNumber}
        />
        <SectionCardRow
          label={t(
            "companyManagement:exporter.companyInfo.bankDetail.accountName",
          )}
          direction="horizontal"
          value={accountName}
        />
      </BankDetailContainer>
    </SectionCard>
  );
};

export default BankDetails;

const BankDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
