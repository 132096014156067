import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import { StyledScroll } from "@/src/styles/scroll";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css, styled } from "styled-components";

function TaskCodeCard() {
  const { t } = useTranslation();
  const params = useParams();

  const {
    isError,
    requestPhotoUsedTypeCount,
    loadingCode,
    requestPhotoType,
    containerExtraMediasCount,
    extraMediaRemark,
    containerLoadingRequestRemark,
  } = taskDetailApi.endpoints.getTaskDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ isError, currentData, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const requestPhotoUsedTypeCount: number = !isUnstable
          ? [
              currentData?.containerBothDoorsClosedMediasCount,
              currentData?.containerCloseUpSealMediasCount,
              currentData?.containerEmptyMediasCount,
              currentData?.containerExtraMediasCount,
              currentData?.containerFullLoadedMediasCount,
              currentData?.containerHalfLoadedMediasCount,
              currentData?.containerOneDoorClosedMediasCount,
              currentData?.containerQuarterLoadedMediasCount,
              currentData?.containerSampleBaleMediasCount,
            ].filter((item) => item !== 0).length
          : 0;

        return {
          isError,
          requestPhotoUsedTypeCount,
          loadingCode: !isUnstable ? currentData.taskCode : "-",
          requestPhotoType: !isUnstable ? currentData.requestPhotoType : "-",
          containerExtraMediasCount: !isUnstable
            ? (currentData.containerExtraMediasCount ?? "-")
            : "-",
          extraMediaRemark: !isUnstable
            ? (currentData.extraMediaRemark ?? "-")
            : "-",
          containerLoadingRequestRemark: !isUnstable
            ? (currentData.containerTaskRequestRemark ?? "-")
            : "-",
        };
      },
    },
  );

  return (
    <StyledSectionCard
      cardTitle={
        !isError ? (
          <TitleContainer>
            <Typo typoType="h6">{t("task:detail.cards.loadingCode")}</Typo>
            <Typo typoType="h6" color="blue2">
              {loadingCode}
            </Typo>
          </TitleContainer>
        ) : (
          "-"
        )
      }
    >
      <FlexColumn>
        <StyledSectionCardRow
          label={
            <RequestPhotoTypeTitle>
              <Typo typoType="b7m">
                {t("task:detail.cards.requestPhotoType")}
              </Typo>
              <Typo typoType="b7r" color="blue2">
                {t("task:detail.cards.total")} {requestPhotoUsedTypeCount}
              </Typo>
            </RequestPhotoTypeTitle>
          }
          direction="vertical"
          value={
            <ReadOnlyText typoType="b7r" color="gray5">
              {requestPhotoType}
            </ReadOnlyText>
          }
        />

        {containerExtraMediasCount ? (
          <StyledSectionCardRow
            label={
              <Typo typoType="b7m">
                {t("task:detail.cards.extraPhotosRemark")}
              </Typo>
            }
            direction="vertical"
            value={
              <ReadOnlyText typoType="b7r" color="gray5">
                {extraMediaRemark}
              </ReadOnlyText>
            }
          />
        ) : null}

        <StyledSectionCardRow
          label={
            <Typo typoType="b7m">
              {t("task:detail.cards.loadingRequestRemark")}
            </Typo>
          }
          direction="vertical"
          value={
            <StyledReadOnlyText typoType="b7r" color="gray5">
              {containerLoadingRequestRemark}
            </StyledReadOnlyText>
          }
        />
      </FlexColumn>
    </StyledSectionCard>
  );
}

export default TaskCodeCard;

const StyledSectionCard = styled(SectionCard)`
  grid-column: span 5;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  p {
    max-width: 100%;
  }

  ${() => css`
    container-type: inline-size;
  `}
`;

const RequestPhotoTypeTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledReadOnlyText = styled(ReadOnlyText)`
  margin-top: 8px;
  height: 142px;
  overflow: auto;

  ${StyledScroll}

  ${() => css`
    @container (max-width: 470px) {
      height: 100%;
    }
  `}
`;
