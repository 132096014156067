import RadixTooltip from "@/src/components/atom/Tooltip/RadixTooltip";
import colorSet from "@/src/styles/color";
import { ReactNode, useState } from "react";
import styled from "styled-components";

interface InformationItemTooltipProps {
  trigger: ReactNode;
  content: ReactNode;
}

function InformationItemTooltip({
  trigger,
  content,
}: InformationItemTooltipProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <RadixTooltip
      open={isTooltipOpen}
      onOpenChange={setIsTooltipOpen}
      contentProps={{
        side: "top",
        align: "center",
      }}
      arrowProps={{ style: { opacity: 0.8 } }}
      arrowColor="gray1"
      content={<StyledTooltipContent>{content}</StyledTooltipContent>}
    >
      <StyledTrigger>{trigger}</StyledTrigger>
    </RadixTooltip>
  );
}

export default InformationItemTooltip;

const StyledTooltipContent = styled.div`
  background: ${colorSet.gray1};
  white-space: pre-wrap;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0.8;
  text-align: center;
  max-width: 190px;
`;

const StyledTrigger = styled.div`
  align-self: flex-start;
  max-width: 100%;
  background: none;
  border: none;
  outline: none;
  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
