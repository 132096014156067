import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem from "@/src/components/molecule/FormItem";
import useAlert from "@/src/hooks/useAlert";
import {
  companyApi,
  useEditExportersMutation,
} from "@/src/store/apis/corporate/company";
import { ModifyExporterDto } from "@/src/store/apis/corporate/company/interface";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface BankDetailEditDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

const BankDetailEditDialog = ({
  open,
  onOpenChange,
}: BankDetailEditDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [editExporters, { isLoading }] = useEditExportersMutation();

  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);
  const { currentData: bankDetailData } =
    companyApi.endpoints.getExporters.useQueryState(undefined);

  const {
    control,
    watch,
    handleSubmit,
    register,
    setValue,
    setFocus,
    getValues,
  } = useForm<{
    bankName: string;
    swiftCode: string;
    accountNumber: string;
    accountName: string;
    bankTelPrefix: string;
    bankTel: string;
    bankFaxPrefix: string;
    bankFax: string;

    apartment: string;
    city: string;
    state: string;
    postal: string;
    country: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      bankName: bankDetailData?.exporterDetailQueryResultDto.bankName,
      swiftCode: bankDetailData?.exporterDetailQueryResultDto.swiftCode,
      accountNumber: bankDetailData?.exporterDetailQueryResultDto.accountNumber,
      accountName: bankDetailData?.exporterDetailQueryResultDto.accountName,
      bankTelPrefix: bankDetailData?.exporterDetailQueryResultDto.bankTelPrefix,
      bankTel: bankDetailData?.exporterDetailQueryResultDto.bankTel,
      bankFaxPrefix: bankDetailData?.exporterDetailQueryResultDto.bankFaxPrefix,
      bankFax: bankDetailData?.exporterDetailQueryResultDto.bankFax,

      apartment: bankDetailData?.exporterDetailQueryResultDto.bankStreetAddress,
      city: bankDetailData?.exporterDetailQueryResultDto.bankLocality,
      state: bankDetailData?.exporterDetailQueryResultDto.bankRegion,
      postal: bankDetailData?.exporterDetailQueryResultDto.bankPostalCode,
      country: bankDetailData?.exporterDetailQueryResultDto.bankCountryName,
    },
  });

  useEffect(() => {
    setFocus("bankName");
  }, [setFocus]);

  const handleSubmitClick = async () => {
    const params = {
      bankDetail: {
        bankName: getValues("bankName"),
        swiftCode: getValues("swiftCode"),
        accountNumber: getValues("accountNumber"),
        accountName: getValues("accountName"),
        bankTelPrefix: getValues("bankTelPrefix") || null,
        bankTel: getValues("bankTel") || null,
        bankFaxPrefix: getValues("bankFaxPrefix") || null,
        bankFax: getValues("bankFax") || null,
        bankCountryName: getValues("country") || null,
        bankRegion: getValues("state") || null,
        bankLocality: getValues("city") || null,
        bankStreetAddress: getValues("apartment") || null,
        bankPostalCode: getValues("postal") || null,
      },
    } as ModifyExporterDto;

    try {
      await editExporters(params).unwrap();

      onOpenChange(false);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  return (
    <Dialog
      title={t("companyManagement:importer.companyInfoDialog.bankDetail.title")}
      open={open}
      width={496}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
            ref={cancelButtonRef}
            onKeyUp={(e) => {
              if (e.shiftKey && e.code === "Tab") {
                setFocus("accountName");
              }
            }}
          >
            <Typo typoType="btn3m">
              {t(
                "companyManagement:importer.companyInfoDialog.bankDetail.cancelButton",
              )}
            </Typo>
          </Button>
          <Button
            onClick={() => submitButtonRef.current?.click()}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t(
                "companyManagement:importer.companyInfoDialog.bankDetail.saveButton",
              )}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <FormItem
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.bankName",
          )}
          type="text"
          name="bankName"
          control={control}
          rules={{ required: true }}
          direction="vertical"
          inputProps={{ placeholder: t("placeholder:enterBankName") }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />

        <AddressFormItem
          register={register}
          setValue={setValue}
          direction="horizontal"
          isRequired={false}
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.bankAddress",
          )}
        />

        <ContactFormItem
          prefixValue={watch("bankTelPrefix")}
          prefixName="bankTelPrefix"
          restContactName="bankTel"
          register={register}
          setValue={setValue}
          direction="vertical"
          isRequired={false}
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.bankTel",
          )}
        />

        <ContactFormItem
          prefixValue={watch("bankFaxPrefix")}
          prefixName="bankFaxPrefix"
          restContactName="bankFax"
          register={register}
          setValue={setValue}
          direction="vertical"
          isRequired={false}
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.bankFax",
          )}
        />

        <FormItem
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.swiftCode",
          )}
          type="text"
          name="swiftCode"
          control={control}
          direction="vertical"
          rules={{ required: true }}
          inputProps={{
            placeholder: t(
              "companyManagement:importer.companyInfoDialog.bankDetail.swiftCodePlaceholder",
            ),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />

        <FormItem
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.accountNumber",
          )}
          type="text"
          name="accountNumber"
          control={control}
          direction="vertical"
          rules={{ required: true }}
          inputProps={{
            placeholder: t(
              "companyManagement:importer.companyInfoDialog.bankDetail.accountNumberPlaceholder",
            ),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;

              return setValue(
                "accountNumber",
                value.replace(/[^0123456789-]/g, ""),
              );
            },
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />

        <FormItem
          label={t(
            "companyManagement:importer.companyInfoDialog.bankDetail.accountName",
          )}
          type="text"
          name="accountName"
          control={control}
          direction="vertical"
          rules={{ required: true }}
          inputProps={{
            placeholder: t(
              "companyManagement:importer.companyInfoDialog.bankDetail.accountNamePlaceholder",
            ),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />
        <Hidden
          ref={submitButtonRef}
          type="submit"
          onFocus={() => cancelButtonRef.current?.focus()}
        />
      </Form>
    </Dialog>
  );
};

export default BankDetailEditDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
