import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import useCompanyHomeRedirect from "@/src/hooks/useCompanyHomeRedirect";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useAppSelector } from "@/src/store";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useGetCurrentSubscriptionQuery,
  useGetEnterpriseInquiriesQuery,
  useGetPlansQuery,
} from "@/src/store/apis/subscription";
import { isUndefined } from "@/src/utils/is";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AdminConfirmCompleted from "./components/AdminConfirmCompleted";
import EnterpriseInquiry from "./components/EnterpriseInquiry";
import EnterpriseInquiryCompleted from "./components/EnterpriseInquiryCompleted";
import EnterpriseInquiryRequesting from "./components/EnterpriseInquiryRequesting";
import EnterprisePaymentRegistration from "./components/EnterprisePaymentRegistration";
import EnterpriseSignupCompleted from "./components/EnterpriseSignupCompleted";

// Step Context
export type EnterPriseSignupState =
  | "INQUIRY"
  | "INQUIRY_REQUESTING"
  | "INQUIRY_COMPLETED"
  | "ADMIN_CONFIRM_COMPLETED"
  | "PAYMENT_REGISTRATION"
  | "COMPLETED";
export type EnterPriseSignupStepContextType = {
  step: EnterPriseSignupState | null;
  onStepChange: Dispatch<SetStateAction<EnterPriseSignupState | null>>;
};
export const EnterPriseSignupStepContext = createContext<
  EnterPriseSignupStepContextType | undefined
>(undefined);

// Date Context
type SubscriptionSignupDataType = {
  subscriptionStartAtFrom?: string;
  subscriptionEndAtTo?: string;
  planName?: string;
};
export type EnterPriseSignupDateContextType = SubscriptionSignupDataType & {
  onSubscriptionsDateChange: Dispatch<
    SetStateAction<SubscriptionSignupDataType>
  >;
};
export const EnterPriseSignupDateContext = createContext<
  EnterPriseSignupDateContextType | undefined
>(undefined);

function EnterpriseSignupPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isInquiryQuery = searchParams.get("isInquiry") === "true";

  const [planState, setPlanState] = useState<EnterPriseSignupState | null>(
    null,
  );
  const [subscriptionsData, setSubscriptionsData] =
    useState<SubscriptionSignupDataType>({});

  // API
  const { companyType, isCompanyTypeFetching, exporterUserType } =
    useGetSessionQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching }) => {
        return {
          exporterUserType: currentData?.row.exporterUserType,
          isCompanyTypeFetching: isFetching,
          companyType:
            currentData?.row.exporterUserType === "CORPORATE_MANAGER"
              ? currentData?.row.exporter.companyType
              : currentData?.row.exporterUserMainFieldType,
        };
      },
    });

  const { subscriptions, isSubscriptionsFetching } =
    useGetCurrentSubscriptionQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isSubscriptionsFetching: isFetching,
          subscriptions: isStable ? currentData : undefined,
        };
      },
    });
  useGetPlansQuery(
    { planType: "ENTERPRISE" },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  useGetEnterpriseInquiriesQuery(
    { page: 1, pageSize: 10 },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { isEnterpriseInquiries, isEnterprisePlan } = useAppSelector(
    (state) => state.subscription,
  );

  const {
    func: { redirectNonCorporate },
  } = useCompanyHomeRedirect({
    companyType,
    userType: exporterUserType,
  });

  const goToHome = () => {
    switch (companyType) {
      case "BOTH":
      case "SELLER":
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
      case "BUYER":
        return navigate(IMPORTER_PRIVATE_PATH.HOME);
      default:
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  };

  const redirectRoute = useCallback(() => {
    const isSubscription = subscriptions?.subscriptionStatus === "PROGRESS";

    // 현재 구독중, 엔터프라이즈 문의 query가 없는경우
    if (isSubscription && !isInquiryQuery) {
      return navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
    }

    redirectNonCorporate();
  }, [isInquiryQuery, navigate, redirectNonCorporate, subscriptions]);

  const mountWhenPlanState = useCallback(() => {
    if (isInquiryQuery) {
      return setPlanState("INQUIRY");
    }

    // 해당 유저의 기업의 엔터프라이즈 플랜의 문의, 플랜이 생성되었는지?
    if (isEnterpriseInquiries && isEnterprisePlan) {
      return setPlanState("ADMIN_CONFIRM_COMPLETED");
    }

    // 해당 유저의 기업이 엔터프라이즈 문의가 있는지?
    if (isEnterpriseInquiries) {
      return setPlanState("INQUIRY_REQUESTING");
    }

    return setPlanState("INQUIRY");
  }, [isEnterpriseInquiries, isEnterprisePlan, isInquiryQuery]);

  const renderContent = () => {
    if (isCompanyTypeFetching || isSubscriptionsFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    if (planState === "INQUIRY") {
      // 문의 요청란 입력
      return <EnterpriseInquiry />;
    }
    // 문의 요청완료
    if (planState === "INQUIRY_COMPLETED") {
      return <EnterpriseInquiryCompleted />;
    }
    // 문의 요청중, (로그인 시)
    if (planState === "INQUIRY_REQUESTING") {
      return <EnterpriseInquiryRequesting />;
    }
    // 영업팀 승인 완료, (로그인 시)
    if (planState === "ADMIN_CONFIRM_COMPLETED") {
      return <AdminConfirmCompleted />;
    }
    // 결제 정보입력
    if (planState === "PAYMENT_REGISTRATION") {
      return <EnterprisePaymentRegistration />;
    }
    // 엔터프라이즈 플랜 구독 완료
    if (planState === "COMPLETED") {
      return <EnterpriseSignupCompleted />;
    }
  };

  useEffect(() => {
    mountWhenPlanState();
  }, [mountWhenPlanState]);

  useEffect(() => {
    redirectRoute();
  }, [redirectRoute]);

  return (
    <SignUpLayout onLogoClick={goToHome}>
      <StyledMain>
        <Title typoType="d3">{t("enterpriseSignup:title")}</Title>

        <EnterPriseSignupDateContext.Provider
          value={{
            ...subscriptionsData,
            onSubscriptionsDateChange: setSubscriptionsData,
          }}
        >
          <EnterPriseSignupStepContext.Provider
            value={{ step: planState, onStepChange: setPlanState }}
          >
            {renderContent()}
          </EnterPriseSignupStepContext.Provider>
        </EnterPriseSignupDateContext.Provider>
      </StyledMain>
    </SignUpLayout>
  );
}

export default EnterpriseSignupPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  margin: 0 auto;
`;

const Title = styled(Typo)`
  padding: 24px 0;
  margin-bottom: 24px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
