import { apiSlice } from "../../baseQuery";
import { IsBookmarkedInfoDto } from "../../contracts/contractDetail/interface";
import { Row, Rows } from "../../type";
import {
  BookingRecombinationBookingListViewDto,
  BookingRecombinationShipmentListViewDto,
  CombineAndSplitBookingDto,
  ContainerDetailViewDto,
  ContainerListViewDto,
  ContainerTaskChangeHistoryContainerDetailDto,
  ContainerTaskChangeHistoryListByContainerIdDto,
  ContainerTaskChangeHistoryListByTaskIdDto,
  ControlContainerTaskAndWeightInfoDto,
  ExporterUserTaskBookmarkInfoDto,
  ModifyTaskStatusDto,
  RemoveBulkContainersDto,
  SendTaskInfoEmailV2Dto,
  TaskBookmarkListViewDto,
  TaskDetailViewDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["detail", "bookmark", "container", "containers-history"],
});

const taskDetailApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 작업 단일 조회 API
    getTaskDetail: build.query<TaskDetailViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/tasks/${id}`,
      }),
      transformResponse: (res: Row<TaskDetailViewDto>) => res.row,
      providesTags: ["detail"],
    }),

    // 수출입웹 유저의 단일 작업에 대한 북마크 정보 조회
    getTaskDetailBookmark: build.query<
      ExporterUserTaskBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporter-users/this/tasks/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserTaskBookmarkInfoDto>) => res.row,
      providesTags: ["bookmark"],
    }),

    // 작업을 북마크한 수출입웹 유저 목록 조회
    getTaskDetailBookmarkUser: build.query<
      Rows<TaskBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/tasks/${id}/task-bookmarks`,
      }),
      providesTags: ["bookmark"],
    }),

    // 수출입웹 유저의 단일 작업에 대한 북마크 정보 관장 API
    updateTaskDetailBookmark: build.mutation<
      Row<ExporterUserTaskBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporter-users/this/tasks/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["bookmark"],
    }),

    // Task Code 이메일/SMS 발송 API(by taskWorkplaceEmployeeId)
    sendTaskRequest: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: "POST",
        url: `/exporters/this/task-workplace-employees/${id}/send-task-code`,
      }),
    }),

    // 작업 상태 CANCEL_TASK_REQUEST로 변경 API (<주의> 작업에 귀속된 모든 컨테이너 정보 삭제됨)
    cancelTaskStatus: build.mutation<void, ModifyTaskStatusDto>({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/task-status-to-cancel-task-request`,
        body,
      }),
      invalidatesTags: ["detail"],
    }),

    // 작업 상태 TASK_COMPLETED로 변경 API
    completedTaskStatus: build.mutation<void, ModifyTaskStatusDto>({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/task-status-to-task-completed`,
        body,
      }),
      invalidatesTags: ["detail"],
    }),

    // 작업에 귀속된 컨테이너 목록을 조회하는 API 입니다.
    getTaskDetailContainerList: build.query<
      Rows<ContainerListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/tasks/${id}/containers`,
      }),
      providesTags: ["detail", "container"],
    }),

    // 작업에 귀속된 컨테이너 단일 추가 API
    createTaskDetailContainer: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: "POST",
        url: `/exporters/this/tasks/${id}/containers`,
      }),
      invalidatesTags: ["detail", "container"],
    }),

    // 작업에 귀속된 컨테이너 목록 벌크 삭제 API
    deleteTaskDetailContainer: build.mutation<
      void,
      RemoveBulkContainersDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporters/this/tasks/${id}/containers/bulk-delete`,
        body: body,
      }),
      invalidatesTags: ["detail", "container"],
    }),

    // 컨테이너 단일 조회 API
    getTaskDetailContainer: build.query<ContainerDetailViewDto, { id: number }>(
      {
        query: ({ id }) => ({
          url: `/exporters/this/containers/${id}`,
        }),
        transformResponse: (res: Row<ContainerDetailViewDto>) => res.row,
      },
    ),

    // 작업에 귀속된 단일 컨테이너의 Weight Information,Task Information 관장 API
    updateTaskDetailContainer: build.mutation<
      void,
      ControlContainerTaskAndWeightInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporters/this/containers/${id}/task-and-weight-info`,
        body: body,
      }),
    }),

    // 작업에 귀속된 컨테이너의 목록의 무게상태를 WEIGHING_CHECK_COMPLETED로 벌크 업데이트 API
    updateContainersWeightStatus: build.mutation<
      void,
      RemoveBulkContainersDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/exporters/this/tasks/${id}/containers/bulk-modify-task-weighing-status`,
        body: body,
      }),
      invalidatesTags: ["detail", "container"],
    }),

    // (v2)작업 정보 이메일 발송 API
    taskDetailSendEmail: build.mutation<
      void,
      SendTaskInfoEmailV2Dto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/v2/exporters/this/tasks/${id}/send-task-info-email`,
        body: body,
      }),
    }),

    // 부킹 쪼개기 합치기 관련 API
    getBookingRecombinationList: build.query<
      Rows<BookingRecombinationBookingListViewDto>,
      { buyerNameCode?: string; taskId: number; page: number }
    >({
      query: ({ buyerNameCode, taskId, page }) => ({
        url: "/exporters/this/booking-recombination/bookings",
        params: {
          buyerNameCode,
          taskId,
          page,
          pageSize: 10,
        },
      }),
    }),

    // 부킹 쪼개기 합치기 관련 API
    getContractRecombinationList: build.query<
      Rows<BookingRecombinationBookingListViewDto>,
      {
        buyerNameCode?: string;
        taskId: number;
        page: number;
        bookingId: number;
      }
    >({
      query: ({ buyerNameCode, taskId, bookingId, page }) => ({
        url: "/exporters/this/booking-recombination/contracts",
        params: {
          buyerNameCode,
          taskId,
          bookingId,
          page,
          pageSize: 10,
        },
      }),
    }),

    // 부킹 쪼개기 합치기 관련 API
    getShipmentecombinationList: build.query<
      Rows<BookingRecombinationShipmentListViewDto>,
      {
        bookingId: number;
        contractId: number;
        page: number;
      }
    >({
      query: ({ contractId, bookingId, page }) => ({
        url: "/exporters/this/booking-recombination/shipments",
        params: {
          timezoneOffset: new Date().getTimezoneOffset(),
          contractId,
          bookingId,
          page,
          pageSize: 10,
        },
      }),
    }),
    combineAndSplitBooking: build.mutation<
      Row<TaskDetailViewDto>,
      Omit<CombineAndSplitBookingDto, "timezoneOffset">
    >({
      query: (body) => ({
        method: "POST",
        url: "/exporters/this/booking-combine-and-split",
        body: {
          ...body,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
      }),
      invalidatesTags: ["detail", "container", "containers-history"],
    }),
    getContainerTaskChangeHistoryList: build.query<
      Rows<ContainerTaskChangeHistoryListByTaskIdDto>,
      { taskId: number }
    >({
      query: ({ taskId }) => ({
        url: "/exporters/this/container-task-change-history/history-list-inquiry-by-task-id",
        params: {
          taskId,
        },
      }),
      providesTags: ["containers-history"],
    }),

    getContainerDetailInCombineAndSplit: build.query<
      Row<ContainerTaskChangeHistoryContainerDetailDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/container-task-change-history/containers/${id}`,
      }),
    }),

    getContainerHistory: build.query<
      Rows<ContainerTaskChangeHistoryListByContainerIdDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: "/exporters/this/container-task-change-history/history-list-inquiry-by-container-id",
        params: {
          containerId: id,
        },
      }),
    }),

    getImporterTaskDetail: build.query<TaskDetailViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/importers/this/tasks/${id}`,
      }),
      transformResponse: (res: Row<TaskDetailViewDto>) => res.row,
    }),

    getImporterTaskDetailContainerList: build.query<
      Rows<ContainerListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/tasks/${id}/containers`,
      }),
    }),

    getImporterTaskDetailContainer: build.query<
      ContainerDetailViewDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/containers/${id}`,
      }),
      transformResponse: (res: Row<ContainerDetailViewDto>) => res.row,
    }),
  }),
});

export const {
  useGetTaskDetailQuery,
  useLazyGetTaskDetailQuery,
  useCancelTaskStatusMutation,
  useCompletedTaskStatusMutation,
  useSendTaskRequestMutation,

  // Bookmark
  useGetTaskDetailBookmarkQuery,
  useGetTaskDetailBookmarkUserQuery,
  useUpdateTaskDetailBookmarkMutation,

  // Container
  useGetTaskDetailContainerListQuery,
  useLazyGetTaskDetailContainerListQuery,
  useCreateTaskDetailContainerMutation,
  useDeleteTaskDetailContainerMutation,
  useLazyGetTaskDetailContainerQuery,
  usePrefetch: useTaskDetailPrefetch,
  useTaskDetailSendEmailMutation,
  useUpdateTaskDetailContainerMutation,
  useUpdateContainersWeightStatusMutation,

  // 부킹 쪼개기 합치기
  useLazyGetBookingRecombinationListQuery,
  useLazyGetContractRecombinationListQuery,
  useLazyGetShipmentecombinationListQuery,
  useCombineAndSplitBookingMutation,
  useGetContainerTaskChangeHistoryListQuery,

  useGetContainerDetailInCombineAndSplitQuery,
  useGetContainerHistoryQuery,

  useGetImporterTaskDetailQuery,
  useGetImporterTaskDetailContainerListQuery,
  useLazyGetImporterTaskDetailContainerQuery,
} = taskDetailApi;
export { taskDetailApi };
