import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import ResetSvg from "@/src/assets/icons/icon-filter-reset.svg";
import IconButton from "@/src/components/atom/Button/IconButton";
import Checkbox from "@/src/components/atom/Checkbox";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import ViewPhotoCollectionDialog, {
  ContainerMediaData,
} from "@/src/components/organism/ViewPhotoCollectionDialog";
import useAlert from "@/src/hooks/useAlert";
import TaskThumbnail from "@/src/pages/exporter/Task/components/detail/TaskThumbnail";
import {
  renderContainerIcon,
  renderContainerText,
} from "@/src/pages/exporter/Task/components/detail/utils/renderContainerType";
import { useContainerIdToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import {
  taskDetailApi,
  useLazyGetImporterTaskDetailContainerQuery,
  useTaskDetailPrefetch,
} from "@/src/store/apis/tasks/taskDetail";
import {
  ContainerDetailViewDto,
  ContainerListViewDto,
  ContainerMediaType,
  SimpleContainerMediaDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { downloadImageWithExif } from "@/src/utils/imageMetadataUtils";
import { isUndefined } from "@/src/utils/is";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import dayjs from "dayjs";
import { CSSProperties, useId, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import styled, { css } from "styled-components";

type TaskContainerDialogItemType = "VIEW_PHOTO" | null;

interface ContainerAccordionItemProps {
  containerId: number;
  onCheckChange?: (id: number, checked: boolean) => void;
  containerNo?: string;
  sealNo?: string;
  containerCheckList: number[];
  index: number;
  extraRemark: string | null;
  loadingId?: number;
}

const containerCountMediaMap = {
  containerSampleBaleMediasCount: "sampleBaleSimpleContainerMedias",
  containerEmptyMediasCount: "emptySimpleContainerMedias",
  containerQuarterLoadedMediasCount: "quarterLoadedSimpleContainerMedias",
  containerHalfLoadedMediasCount: "halfLoadedSimpleContainerMedias",
  containerFullLoadedMediasCount: "fullLoadedSimpleContainerMedias",
  containerOneDoorClosedMediasCount: "oneDoorClosedSimpleContainerMedias",
  containerBothDoorsClosedMediasCount: "bothDoorsClosedSimpleContainerMedias",
  containerCloseUpSealMediasCount: "closeUpSealSimpleContainerMedias",
  containerExtraMediasCount: "extraSimpleContainerMedias",
};

const countKeyConvertToContainerType = (
  countKey: keyof typeof containerCountMediaMap,
): ContainerMediaType | undefined => {
  if (countKey === "containerSampleBaleMediasCount") {
    return "SAMPLE_BALE";
  }
  if (countKey === "containerEmptyMediasCount") {
    return "EMPTY";
  }
  if (countKey === "containerQuarterLoadedMediasCount") {
    return "QUARTER_LOADED";
  }
  if (countKey === "containerHalfLoadedMediasCount") {
    return "HALF_LOADED";
  }
  if (countKey === "containerFullLoadedMediasCount") {
    return "FULL_LOADED";
  }
  if (countKey === "containerOneDoorClosedMediasCount") {
    return "ONE_DOOR_CLOSED";
  }
  if (countKey === "containerBothDoorsClosedMediasCount") {
    return "BOTH_DOORS_CLOSED";
  }
  if (countKey === "containerCloseUpSealMediasCount") {
    return "CLOSE_UP_SEAL";
  }
  if (countKey === "containerExtraMediasCount") {
    return "EXTRA";
  }
};

function ContainerAccordionItem({
  containerId,
  onCheckChange,
  containerNo,
  sealNo,
  containerCheckList,
  index,
  extraRemark,
  loadingId,
}: ContainerAccordionItemProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const isFetched = useRef(false);
  const id = useId();
  const loadingImageRef = useRef<HTMLDivElement>(null);

  const [selectDialog, setSelectDialog] =
    useState<TaskContainerDialogItemType>(null);
  const [startIndex, setStartIndex] = useState<number>();
  const [selectLoading, setSelectLoading] = useState<
    ContainerListViewDto | ContainerDetailViewDto | null
  >(null);

  const [isRefetched, setIsRefetched] = useState<boolean>(false);
  const [selectImageUrl, setSelectImage] = useState<ContainerMediaData | null>(
    null,
  );
  const [isPreviewDownloadLoading, setIsPreviewDownloadLoading] =
    useState(false);

  // API
  const { mediaCoordinateRange } =
    taskDetailApi.endpoints.getImporterTaskDetail.useQueryState(
      {
        id: loadingId as number,
      },
      {
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;
          const isStable = !isUnstable;

          return {
            mediaCoordinateRange: isStable
              ? currentData.mediaCoordinateRange
              : undefined,
          };
        },
      },
    );
  const [getLoadingDetailContainer, loadingDetailContainerData] =
    useLazyGetImporterTaskDetailContainerQuery();
  const { currentData, isFetching } =
    taskDetailApi.endpoints.getImporterTaskDetailContainer.useQueryState({
      id: containerId,
    });
  const [containerIdToSeaVantageUrl] = useContainerIdToSeaVantageUrlMutation();
  const prefetch = useTaskDetailPrefetch("getImporterTaskDetailContainer");

  const handlePrefetch = async () => {
    try {
      await prefetch({ id: containerId }, { force: !isFetched.current });
      isFetched.current = true;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContainerRefetch = async () => {
    try {
      await getLoadingDetailContainer({ id: containerId }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setIsRefetched(true);
    }
  };

  const handleContainerNoClick = async () => {
    try {
      const { cargoTrackUrl } = await containerIdToSeaVantageUrl({
        containerId,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const containerImageListMaping = useMemo(
    () =>
      Object.entries(containerCountMediaMap).reduce<{
        lastUpdatedAt: undefined | string;
        imageResource: {
          isUsed: boolean;
          imageList: (string | undefined)[];
          icon: any;
          title: any;
          mediaDataList: ContainerMediaData[];
          description?: string;
        }[];
      }>(
        (acc, [countKey, mediaKey]) => {
          const count =
            currentData?.[countKey as keyof ContainerDetailViewDto] ?? 0;
          let lastUpdatedAt = acc.lastUpdatedAt;

          const mediaDataList = count
            ? ((
                currentData?.[
                  mediaKey as keyof ContainerDetailViewDto
                ] as SimpleContainerMediaDto[]
              )?.map(
                ({
                  containerPhotoSimpleMedia,
                  containerMediaCreatedAt,
                  coordinate,
                }) => {
                  return {
                    createAt: containerMediaCreatedAt,
                    latitude: coordinate?.latitude ?? undefined,
                    longitude: coordinate?.longitude ?? undefined,
                    zoomLevel: coordinate?.zoomLevel
                      ? Number(coordinate?.zoomLevel)
                      : undefined,
                    originalFileName:
                      containerPhotoSimpleMedia.originalFileName ?? undefined,
                    mediaUrl: containerPhotoSimpleMedia
                      ? containerPhotoSimpleMedia?.mediaUrl
                      : undefined,
                  };
                },
              ) ?? [])
            : [];

          const imageList = count
            ? ((
                currentData?.[
                  mediaKey as keyof ContainerDetailViewDto
                ] as SimpleContainerMediaDto[]
              )?.map(
                ({ containerPhotoSimpleMedia, containerMediaUpdatedAt }) => {
                  if (!lastUpdatedAt) {
                    lastUpdatedAt = containerMediaUpdatedAt;
                  }

                  if (
                    dayjs(containerMediaUpdatedAt).isAfter(dayjs(lastUpdatedAt))
                  ) {
                    lastUpdatedAt = containerMediaUpdatedAt;
                  }
                  return containerPhotoSimpleMedia.mediaUrl;
                },
              ) ?? [])
            : [];

          const containerType = countKeyConvertToContainerType(
            countKey as keyof typeof containerCountMediaMap,
          );

          const resource = {
            isUsed: !!count,
            imageList: [
              ...(imageList as unknown as string[]),
              ...Array.from({ length: count as number }, () => undefined),
            ].slice(0, count as number),
            icon: renderContainerIcon(containerType),
            title: renderContainerText(t, containerType, "b7m"),
            description:
              containerType === "EXTRA"
                ? (extraRemark ?? undefined)
                : undefined,
            mediaDataList,
          };

          return {
            lastUpdatedAt,
            imageResource: [...acc.imageResource, resource].filter(
              (item) => item.isUsed,
            ),
          };
        },
        {
          lastUpdatedAt: undefined,
          imageResource: [],
        },
      ),
    [currentData, extraRemark, t],
  );

  const allImageList = containerImageListMaping.imageResource.reduce<string[]>(
    (acc, val) => {
      const validImage = val.imageList.filter((image) => image) as string[];

      return [...acc, ...validImage];
    },
    [],
  );

  const allMediaDataList = containerImageListMaping.imageResource.reduce<
    ContainerMediaData[]
  >(
    (acc, val) => {
      const validImage = val.mediaDataList;

      return [...acc, ...validImage].filter(
        ({ mediaUrl }) => mediaUrl !== undefined,
      );
    },
    [
      {
        latitude: undefined,
        longitude: undefined,
        mediaUrl: undefined,
        originalFileName: undefined,
        zoomLevel: undefined,
      },
    ],
  );

  const handlePreviewDownloadClick = async () => {
    try {
      setIsPreviewDownloadLoading(true);
      const selectUrl = selectImageUrl;

      if (!selectUrl?.mediaUrl) {
        return;
      }

      await downloadImageWithExif({
        url: selectUrl.mediaUrl,
        latitude: Number(selectUrl?.latitude),
        longitude: Number(selectUrl?.longitude),
        fileName: selectUrl.originalFileName,
      });
    } finally {
      setIsPreviewDownloadLoading(false);
    }
  };

  const renderDialogs = () => {
    // 수입자 > 발주 상세 > 로딩 > 컨테이너 사진 슬라이드
    if (selectDialog === "VIEW_PHOTO") {
      return (
        <ViewPhotoCollectionDialog
          items={allImageList.map((item) => {
            return { original: item, thumbnail: item };
          })}
          open={selectDialog === "VIEW_PHOTO"}
          onClose={() => {
            setSelectDialog(null);
            setSelectLoading(null);
          }}
          selectLoading={selectLoading}
          startIndex={startIndex}
          mediaDataList={allMediaDataList}
          imgProps={{
            onContextMenu: (e) => {
              e.preventDefault();
            },
          }}
          thumbnailProps={{
            onContextMenu: (e) => {
              e.preventDefault();
            },
          }}
          isDownloadBlock={true}
          mediaCoordinateRange={mediaCoordinateRange}
          onSelectImageChange={(media) => setSelectImage(media)}
          photoDetailRightAccessory={
            <IconButton
              buttonGrade="tertiary"
              buttonColor="black"
              buttonSize={32}
              onClick={handlePreviewDownloadClick}
              isLoading={isPreviewDownloadLoading}
            >
              <DownloadIcon />
            </IconButton>
          }
        />
      );
    }
  };

  return (
    <AccordionItem
      value={id}
      onMouseEnter={() => {
        handlePrefetch();
      }}
      onKeyDown={() => {
        handlePrefetch();
      }}
    >
      <StyledAccordionTrigger>
        <AccordionTriggerContainer onClick={(e) => e.stopPropagation()}>
          <Checkbox
            onChange={(e) => {
              e.stopPropagation();
              onCheckChange?.(containerId, e.target.checked);
            }}
            checked={containerCheckList.includes(containerId)}
          />

          {/* Badge, Container No, Seal No */}
          <AccordionTriggerTextContainer>
            <TextAndBadgeContainer>
              <TextContainer>
                <Typo typoType="b7m">
                  {t("common:dynamicContainerNo", { index: index + 1 })}
                </Typo>

                {!loadingDetailContainerData?.currentData?.containerNo &&
                !containerNo ? (
                  <Typo typoType="b7m" color="blue2">
                    -
                  </Typo>
                ) : (
                  <TableBlueText
                    typoType="b7m"
                    color="systemBlue2"
                    onClick={() => handleContainerNoClick()}
                  >
                    {isRefetched
                      ? loadingDetailContainerData?.currentData?.containerNo
                      : containerNo}
                  </TableBlueText>
                )}
              </TextContainer>

              <TextContainer>
                <Typo typoType="b7m">{t("common:sealNo")}</Typo>
                <Typo typoType="b7m" color="blue2">
                  {isRefetched
                    ? (loadingDetailContainerData?.currentData?.sealNo ?? "-")
                    : (sealNo ?? "-")}
                </Typo>
              </TextContainer>
            </TextAndBadgeContainer>
          </AccordionTriggerTextContainer>
        </AccordionTriggerContainer>

        <ArrowIcon iconSrc={ArrowDownSvg} iconSize={24} />
      </StyledAccordionTrigger>

      <AccordionContent>
        <AccordionSectionCardContent>
          {/* Container Loading Information */}
          <StyledSectionCard
            cardTitle={t(
              "contract:importer.detail.label.containerLoadingInformation",
            )}
            rightAccessory={
              <SectionCardRight>
                <IconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleContainerRefetch}
                >
                  <Icon iconSrc={ResetSvg} iconSize={20} />
                </IconButton>
              </SectionCardRight>
            }
          >
            <FlexDiv flexDirection="column" gap={8} className="pdbt-32">
              <Typo typoType="b9m" color="gray7">
                {t("contract:importer.detail.label.weightInformation")}
              </Typo>

              <FlexDiv gap={16}>
                <FlexDiv className="flex-1" gap={4}>
                  <Typo typoType="b7m">{t("common:grossWeight")}</Typo>
                  <Typo typoType="b7r" color="gray5">
                    {currentData?.grossWeight ? (
                      <>
                        {Number(currentData.grossWeight).toLocaleString(
                          "ko-KR",
                        )}{" "}
                        {currentData.weightUnit}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typo>
                </FlexDiv>
                <div className="divider" />
                <FlexDiv className="flex-1" gap={4}>
                  <Typo typoType="b7m">{t("common:netWeight")}</Typo>
                  <Typo typoType="b7r" color="gray5">
                    {currentData?.netWeight ? (
                      <>
                        {Number(currentData.netWeight).toLocaleString("ko-KR")}{" "}
                        {currentData.weightUnit} (
                        {currentData?.convertedNetWeight})
                      </>
                    ) : (
                      "-"
                    )}
                  </Typo>
                </FlexDiv>
                <div className="divider" />
                <FlexDiv className="flex-1" gap={4}>
                  <Typo typoType="b7m">{t("common:unitQuantity")}</Typo>
                  <Typo typoType="b7r" color="gray5">
                    {currentData?.unitQuantity?.toLocaleString("ko-KR")}{" "}
                    {currentData?.unitQuantityUnit}
                  </Typo>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>

            <TaskInformationContainer ref={loadingImageRef}>
              {isFetching ? (
                <LoadingContainer
                  height={
                    loadingImageRef?.current?.getBoundingClientRect().height
                  }
                >
                  <PulseLoader color={colorSet.blue4} />
                </LoadingContainer>
              ) : (
                containerImageListMaping.imageResource.map((item, idx) => {
                  return (
                    <TaskThumbnail
                      title={item.title}
                      icon={item.icon}
                      description={item.description}
                      imageUrlList={item.imageList}
                      onClickImage={(imageUrl) => {
                        if (!currentData) {
                          return;
                        }

                        setSelectLoading(currentData);
                        setSelectDialog("VIEW_PHOTO");
                        setStartIndex(
                          allImageList.findIndex((item) => item === imageUrl),
                        );
                      }}
                      index={idx}
                      key={idx}
                    />
                  );
                })
              )}
            </TaskInformationContainer>
          </StyledSectionCard>
        </AccordionSectionCardContent>
      </AccordionContent>

      {/* Dialogs */}
      {renderDialogs()}
    </AccordionItem>
  );
}

export default ContainerAccordionItem;

const ArrowIcon = styled(Icon)`
  transition: 0.3s;
`;

const StyledAccordionTrigger = styled(AccordionTrigger)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid ${colorSet.gray10};
  padding: 16px;
  margin: 0;
  cursor: pointer;

  &:hover {
    background: ${colorSet.blue10};
  }

  &[data-state="open"] {
    ${ArrowIcon} {
      transform: rotateZ(-180deg);
    }
  }

  &[data-state="closed"] {
    ${ArrowIcon} {
      transform: rotateZ(0deg);
    }
  }
`;

const AccordionTriggerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const AccordionTriggerTextContainer = styled(AccordionTriggerContainer)``;
const TextAndBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AccordionSectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background: ${colorSet.gray11};
`;

const StyledSectionCard = styled(SectionCard)`
  background: ${colorSet.white};
`;

const SectionCardRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TaskInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 460px));
  justify-content: center;
  gap: 16px;
`;

const LoadingContainer = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}px;
`;

const FlexDiv = styled.div<{
  flexDirection?: CSSProperties["flexDirection"];
  gap?: CSSProperties["gap"];
}>`
  display: flex;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};

  &.pdbt-32 {
    padding-bottom: 32px;
  }

  .divider {
    border-right: 1px solid ${colorSet.gray9};
  }

  .flex-1 {
    flex: 1;
    flex-shrink: 0;
  }
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;
