import BookingIndigoSvg from "@/src/assets/icons/icon-booking-indigo.svg";
import BookingSvg from "@/src/assets/icons/icon-booking-white.svg";
import CorporateManagementSvg from "@/src/assets/icons/icon-building-setting-white.svg";
import ContractIndigoSvg from "@/src/assets/icons/icon-document-copy-indigo.svg";
import ContractSvg from "@/src/assets/icons/icon-document-copy-white.svg";
import ClientManagementSvg from "@/src/assets/icons/icon-document-setting-white.svg";
import FaqIndigoSvg from "@/src/assets/icons/icon-faq-indigo.svg";
import FaqSvg from "@/src/assets/icons/icon-faq.svg";
import FileBoxIndigoSvg from "@/src/assets/icons/icon-file-box-indigo.svg";
import FileBoxSvg from "@/src/assets/icons/icon-file-box-white.svg";
import HomeIndigoSvg from "@/src/assets/icons/icon-home-indigo.svg";
import HomeSvg from "@/src/assets/icons/icon-home.svg";
import InquiryIndigoSvg from "@/src/assets/icons/icon-inquiry-indigo.svg";
import InquirySvg from "@/src/assets/icons/icon-inquiry.svg";
import LoadingContainerIndigoSvg from "@/src/assets/icons/icon-loading-container-indigo.svg";
import LoadingContainerSvg from "@/src/assets/icons/icon-loading-container.svg";
import NoticeIndigoSvg from "@/src/assets/icons/icon-notice-indigo.svg";
import NoticeSvg from "@/src/assets/icons/icon-notice.svg";
import ShipmentIndigoSvg from "@/src/assets/icons/icon-ship-indigo.svg";
import ShipmentSvg from "@/src/assets/icons/icon-ship-white.svg";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import PUBLIC_PATH from "@/src/routes/public/path";

const EXPORTER_OPERATION_NAV_MENU = [
  {
    iconSrc: HomeSvg,
    selectedIconSrc: HomeIndigoSvg,
    labelKey: "sideNav:home",
    destination: EXPORTER_PRIVATE_PATH.HOME,
  },
  {
    iconSrc: ContractSvg,
    selectedIconSrc: ContractIndigoSvg,
    labelKey: "sideNav:contract",
    destination: EXPORTER_PRIVATE_PATH.CONTRACT,
  },
  {
    iconSrc: BookingSvg,
    selectedIconSrc: BookingIndigoSvg,
    labelKey: "sideNav:booking",
    destination: EXPORTER_PRIVATE_PATH.BOOKING,
  },
  {
    iconSrc: LoadingContainerSvg,
    selectedIconSrc: LoadingContainerIndigoSvg,
    labelKey: "sideNav:loading",
    destination: EXPORTER_PRIVATE_PATH.LOADING,
  },
  {
    iconSrc: ShipmentSvg,
    selectedIconSrc: ShipmentIndigoSvg,
    labelKey: "sideNav:shipment",
    destination: EXPORTER_PRIVATE_PATH.SHIPMENT,
  },
  {
    iconSrc: FileBoxSvg,
    selectedIconSrc: FileBoxIndigoSvg,
    labelKey: "sideNav:fileBox",
    destination: EXPORTER_PRIVATE_PATH.FILE_BOX,
  },
];

const EXPORTER_CUSTOMER_SERVICE_MENU = [
  {
    iconSrc: NoticeSvg,
    selectedIconSrc: NoticeIndigoSvg,
    labelKey: "sideNav:notice",
    destination: EXPORTER_PRIVATE_PATH.NOTICE,
  },
  {
    iconSrc: InquirySvg,
    selectedIconSrc: InquiryIndigoSvg,
    labelKey: "sideNav:inquiry",
    destination: EXPORTER_PRIVATE_PATH.INQUIRY,
  },
  {
    iconSrc: FaqSvg,
    selectedIconSrc: FaqIndigoSvg,
    labelKey: "sideNav:faq",
    destination: EXPORTER_PRIVATE_PATH.FAQ,
  },
];

const EXPORTER_MANAGEMENT_NAV_MENU = [
  {
    iconSrc: CorporateManagementSvg,
    isOpen: false,
    labelKey: "sideNav:corporateManagement",
    closeHeight: 72,
    fullHeight: 240,
    includePath: [
      "company-information-management",
      "staff-management",
      "client-management",
    ],
    children: [
      {
        labelKey: "sideNav:companyInformationManagement",
        destination: EXPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
      },

      {
        labelKey: "sideNav:staffManagement",
        destination: EXPORTER_PRIVATE_PATH.STAFF_MANAGEMENT,
      },
      {
        labelKey: "sideNav:clientManagement",
        destination: EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT,
      },
    ],
  },
  {
    iconSrc: ClientManagementSvg,
    isOpen: false,
    labelKey: "sideNav:management",
    closeHeight: 48,
    fullHeight: 152,
    includePath: [
      "subscription-management",
      "importer-management",
      "factory-management",
    ],
    children: [
      {
        labelKey: "sideNav:subscriptionManagement",
        destination: EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT,
      },
      {
        labelKey: "sideNav:itemManagement",
        destination: EXPORTER_PRIVATE_PATH.ITEM_MANAGEMENT,
      },
      {
        labelKey: "sideNav:factoryManagement",
        destination: EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT,
      },
    ],
  },
];

const EXPORTER_TERMS_POLICY_NAV_MENU = [
  {
    destination: `${PUBLIC_PATH.TERMS}?selectTab=termsOfUse`,
    isBlank: true,
    labelKey: "sideNav:termsOfUse",
  },
  {
    destination: `${PUBLIC_PATH.TERMS}?selectTab=privacyPolicy`,
    isBlank: true,
    labelKey: "sideNav:privacyPolicy",
  },
];

export {
  EXPORTER_OPERATION_NAV_MENU,
  EXPORTER_CUSTOMER_SERVICE_MENU,
  EXPORTER_MANAGEMENT_NAV_MENU,
  EXPORTER_TERMS_POLICY_NAV_MENU,
};
