import AlertDialog from "@/src/components/atom/AlertDialog";
import { useTranslation } from "react-i18next";

interface CancelAlertDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onOk: () => void;
}

function CancelContractAlertDialog({
  open,
  onOpenChange,
  onOk,
}: CancelAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      title={t("contract:exporter.detail.alert.cancelTitle")}
      onOpenChange={onOpenChange}
      onOk={onOk}
      okText={t("contract:exporter.detail.button.okButton")}
      cancelText={t("contract:exporter.detail.button.cancelButton")}
    >
      {t("contract:exporter.detail.alert.cancelDescription")}
    </AlertDialog>
  );
}

export default CancelContractAlertDialog;
