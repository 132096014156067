import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { renderSubscriptionStatusBadge } from "../utils/renderSubscriptionStatusBadge";
import { planStatusStrings } from "../utils/transformStatusStrings";

export const columnSubscriptionList = (t: TFunction): ColDef[] => [
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "subscriptionType",
    sortable: false,
    flex: 1,
    minWidth: 160,
    cellRenderer: (params: ValueGetterParams) => {
      const isFreeType = params.data.subscriptionType === "FREE";

      return params.data ? (
        <Typo typoType="b9r">
          {isFreeType
            ? planStatusStrings({
                t: params.data.t,
                type: params.data.subscriptionType,
              })
            : planStatusStrings({
                t: params.data.t,
                type: params.data.plan.planType,
              })}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "planName",
    sortable: false,
    flex: 1,
    minWidth: 448,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.plan ? (
        <Typo typoType="b9r">{params.data.plan.name}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "subscriptionStartAtFrom",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.subscriptionStartAtFrom) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.subscriptionStartAtFrom).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD,
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "subscriptionEndAtTo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.subscriptionEndAtTo) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.subscriptionEndAtTo).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD,
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "usedLoadingCredit",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.loadingCodeCredit ? (
        <Typo typoType="b9r">
          {params.data.loadingCodeCredit}/
          {params.data.planVersion.numberOfLoadingCode}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "usedMember",
    flex: 1,
    minWidth: 100,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.memberNumberCredit ? (
        <Typo typoType="b9r">
          {params.data.planVersion.numberOfMember -
            params.data.memberNumberCredit}
          /{params.data.planVersion.numberOfMember}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "subscriptionStatus",
    flex: 1,
    minWidth: 90,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        const endDate = dayjs(
          dayjs(params.data.subscriptionEndAtTo).format("YYYY-MM-DD"),
        );
        const duration = endDate.diff(dayjs(), "days");
        const isProgress =
          params.data.subscriptionStatus ===
          t("subscriptionManagement:subscriptionStatus.progress");

        let subscriptionStatus;
        subscriptionStatus =
          duration < 0 && isProgress
            ? t("subscriptionManagement:subscriptionStatus.progress")
            : params.data.subscriptionStatus;

        return renderSubscriptionStatusBadge({
          t: params.data.t,
          type: subscriptionStatus,
        });
      } else {
        return "-";
      }
    },
  },
];
