import Typo from "@/src/components/atom/Typo";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import { ShipmentTaskListViewDto } from "@/src/store/apis/shipments/shipmentRegister/interface";
import colorSet from "@/src/styles/color";
import { CELL_TYPE_VIEW_ACTION } from "@/src/utils/row-data-util";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

const MUST_SAME_CONDITION = [
  "buyerId",
  "quantityUnitCodeItemName",
  "unitPriceUnitCodeItemName",
  "shippingLineCodeItemName",
  "vessel",
  "voyageNo",
  "portOfLoadingCodeItemName",
  "portOfDischargeCodeItemName",
  "placeOfDeliveryCodeItemName",
  "etdAt",
  "etaAt",
  "docCutOffDay",
];

export const getSameConditionObject = (candidateList: {}, list: string[]) => {
  return Object.entries(candidateList).reduce<any>((acc, [key, val]) => {
    if (list.includes(key)) {
      return { ...acc, [key]: val };
    }

    return acc;
  }, {});
};

const getIsSameCondition = (compareItem: any, selectedItem: any) => {
  const rowResource = Object.values(
    getSameConditionObject(compareItem, MUST_SAME_CONDITION),
  );
  const condition = Object.values(
    getSameConditionObject(selectedItem ?? {}, MUST_SAME_CONDITION),
  );

  let isAllSame = true;

  if (condition.length) {
    for (let i = 0; i <= rowResource.length; i++) {
      if (rowResource[i] !== condition[i]) {
        isAllSame = false;
        break;
      }
    }
  }

  return isAllSame;
};

export const getColumnShipmentTaskList: (
  selectedItem?: ShipmentTaskListViewDto,
) => ColDef[] = (selectedItem?: ShipmentTaskListViewDto) => {
  return [
    {
      lockPosition: "left",
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      resizable: false,
      sortable: false,
      width: 42,
      headerClass: (params) => {
        let className = "ag-right-aligned-cell";

        const selectedNodes = params.api.getSelectedNodes() ?? [];

        if (selectedNodes.length === 0) {
          className +=
            " ag-grid-checkbox-disabled ag-grid-header-checkbox-disabled";
        }

        return className;
      },

      cellClass: (params) => {
        return !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? "ag-grid-checkbox-disabled"
          : undefined;
      },
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvent: "none",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "taskNo",
      flex: 1,
      sortable: false,
      minWidth: 110,
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "buyerNameCode",
      flex: 1,
      sortable: false,
      minWidth: 140,
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "etdAt",
      flex: 1,
      minWidth: 160,
      sortable: false,

      cellRenderer: (params: any) => {
        return (
          <>
            {dayjs(params.data.etdAt).format("YYYY-MM-DD")}{" "}
            <EtdCompareCount
              typoType="b9m"
              initDate={params.data.initialEtdAt}
              currentDate={params.data.etdAt}
              color={
                getIsSameCondition(params.data, selectedItem ?? undefined)
                  ? undefined
                  : "gray7"
              }
            />
          </>
        );
      },
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "etaAt",
      flex: 1,
      minWidth: 140,
      sortable: false,
      cellRenderer: (params: ValueGetterParams) => {
        return params.data.etaAt ? (
          <Typo
            typoType="b9r"
            color={
              !getIsSameCondition(params.data, selectedItem ?? undefined)
                ? "gray7"
                : "gray2"
            }
          >
            {dayjs(params.data.etaAt).format("YYYY-MM-DD")}
          </Typo>
        ) : (
          "-"
        );
      },
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "docCutOffAt",
      flex: 1,
      minWidth: 140,
      sortable: false,
      cellRenderer: (params: ValueGetterParams) => {
        return params.data.docCutOffAt ? (
          <Typo
            typoType="b9r"
            color={
              !getIsSameCondition(params.data, selectedItem ?? undefined)
                ? "gray7"
                : "gray2"
            }
          >
            {dayjs(params.data.docCutOffAt).format(
              dayjs(params.data.docCutOffAt).get("minute") === 30
                ? "YYYY-MM-DD"
                : "YYYY-MM-DD hA",
            )}
          </Typo>
        ) : (
          "-"
        );
      },
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      minWidth: 160,
      sortable: false,
      field: "shippingLine",
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "vesselAndVoyageNo",
      minWidth: 160,
      flex: 1,
      sortable: false,
      cellRenderer: (params: ValueGetterParams) => {
        return params.data.vessel && params.data.voyageNo ? (
          <Typo
            typoType="b9r"
            color={
              !getIsSameCondition(params.data, selectedItem ?? undefined)
                ? "gray7"
                : "gray2"
            }
          >
            {params.data.vessel} / {params.data.voyageNo}
          </Typo>
        ) : (
          "-"
        );
      },
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "portOfLoading",
      minWidth: 160,
      sortable: false,
      flex: 1,
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "portOfDischarge",
      minWidth: 160,
      sortable: false,
      flex: 1,
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      field: "placeOfDelivery",
      minWidth: 160,
      sortable: false,
      flex: 1,
    },
    {
      headerClass: "ag-right-aligned-cell",
      headerName: "Qty",
      field: "quantityUnit",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      minWidth: 110,
      sortable: false,
      flex: 1,
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      sortable: false,
      minWidth: 110,
      field: "unitPriceUnit",
      flex: 1,
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      minWidth: 160,
      sortable: false,
      field: "workplaceName",
      flex: 1,
    },
    {
      headerClass: "ag-right-aligned-cell",
      headerName: "No. of Container",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      minWidth: 120,
      sortable: false,
      field: "numberOfTaskContainerAndContainerType",
      flex: 1,
      cellRenderer: (params: ValueGetterParams) => {
        return params.data.numberOfTaskContainer &&
          params.data.containerType ? (
          <Typo
            typoType="b9r"
            color={
              !getIsSameCondition(params.data, selectedItem ?? undefined)
                ? "gray7"
                : "gray2"
            }
          >
            {params.data.numberOfTaskContainer}x{params.data.containerType}
          </Typo>
        ) : (
          "-"
        );
      },
    },
    {
      headerClass: "ag-right-aligned-cell",
      cellStyle: (params) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: !getIsSameCondition(params.data, selectedItem ?? undefined)
          ? colorSet.gray7
          : colorSet.gray2,
      }),
      minWidth: 200,
      sortable: false,
      field: "itemCode",
      flex: 1,
    },
  ];
};

export const columnOfContainer: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: (params) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pointerEvent: "none",
    }),
    field: "taskNo",
    flex: 1,
    sortable: false,
    minWidth: 110,
    cellRenderer: (params: any) => {
      return <Typo typoType="b9r">{params.rowIndex + 1}</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: (params) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "itemCode",
    flex: 1,
    sortable: false,
    minWidth: 200,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "containerNo",
    flex: 1,
    sortable: false,
    minWidth: 140,
    cellRenderer: (params: any) => {
      return <Typo typoType="b9r">{params.data.containerNo ?? "-"}</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "sealNo",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: any) => {
      return <Typo typoType="b9r">{params.data.sealNo ?? "-"}</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "quantityUnit",
    flex: 1,
    minWidth: 140,
    sortable: false,

    cellRenderer: (params: any) => {
      return (
        <Typo typoType="b9r">
          {params.data.unitQuantity && params.data.unitQuantityUnit
            ? `${Number(params.data.unitQuantity).toLocaleString("ko-KR")} ${
                params.data.unitQuantityUnit
              }`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "grossWeight",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.grossWeight
            ? `${Number(params.data.grossWeight).toLocaleString("ko-KR")} ${
                params.data.quantityUnit
              }`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    minWidth: 160,
    sortable: false,
    field: "netWeight",
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.grossWeight
            ? `${Number(params.data.netWeight).toLocaleString("ko-KR")} ${
                params.data.quantityUnit
              }`
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "photo",
    flex: 1,
    width: 110,
    sortable: false,
    lockPosition: "right",
    pinned: "right",
    cellRenderer: CELL_TYPE_VIEW_ACTION,
  },
];
