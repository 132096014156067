import CloseSvg from "@/src/assets/icons/icon-close-red.svg";
import PencelSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Icon from "@/src/components/atom/Icon";
import SingleImageUpload from "@/src/components/molecule/SingleImageUpload";
import useAlert from "@/src/hooks/useAlert";
import { useConfirmImporterScMutation } from "@/src/store/apis/contracts/contractDetail";
import { useAddSignMutation } from "@/src/store/apis/corporate/company";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface RegisterSignDialogProps {
  id: number;
  onClose: () => void;
}

const RegisterSignDialog = ({ id, onClose }: RegisterSignDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [sign, setSign] = useState<{
    id: number;
    mediaUrl: string;
  }>();

  const [addSign, { isLoading: isAddingSign }] = useAddSignMutation();
  const [confirmImporterSc, { isLoading: isConfirmingSc }] =
    useConfirmImporterScMutation();

  const handleRegister = async () => {
    if (!sign) return;
    try {
      await addSign({ signatureMediaId: sign.id }).unwrap();
      await confirmImporterSc({
        id,
        signatureMediaId: sign.id,
      }).unwrap();
      onClose();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  return (
    <Dialog
      title={t("contract:importer.detail.dialogTitle.registerSignature")}
      open
      onOpenChange={onClose}
      footer={
        <DialogFooterContainer>
          <Button buttonColor="black" buttonGrade="tertiary" onClick={onClose}>
            {t("contract:importer.detail.button.cancelButton")}
          </Button>
          <Button
            isLoading={isAddingSign || isConfirmingSc}
            disabled={!sign}
            onClick={handleRegister}
          >
            {t("contract:importer.detail.button.registerSaveButton")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Container>
        <SingleImageUpload
          onChange={(media) =>
            setSign({
              id: media.id,
              mediaUrl: media.mediaUrl,
            })
          }
          value={sign ? sign.mediaUrl : undefined}
          topAccessory={({ handleEdit, handleRemove }) => {
            return (
              <IconButtonContainer>
                <StyledIconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={24}
                  onClick={() => handleEdit()}
                >
                  <Icon iconSrc={PencelSvg} iconSize={16} />
                </StyledIconButton>
                <StyledIconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={24}
                  onClick={() => {
                    handleRemove();
                    setSign(undefined);
                  }}
                >
                  <Icon iconSrc={CloseSvg} iconSize={16} />
                </StyledIconButton>
              </IconButtonContainer>
            );
          }}
        />
      </Container>
    </Dialog>
  );
};

export default RegisterSignDialog;

const IconButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 4px 0 0;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  height: 205px;
`;
