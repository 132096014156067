import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { ReactComponent as EditSvg } from "@/src/assets/icons/icon-edit-pencel.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import CheckboxGroup from "@/src/components/atom/CheckboxGroup";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import Loader from "@/src/components/atom/Loader";
import Radio from "@/src/components/atom/Radio";
import Select from "@/src/components/atom/Select";
import Switch from "@/src/components/atom/Switch";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import { LANGUAGE_LIST } from "@/src/constant/optionList";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import {
  useEditSessionMutation,
  useGetSessionQuery,
} from "@/src/store/apis/auth";
import { ExporterUserDto } from "@/src/store/apis/auth/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import colorSet, { ColorType } from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import { isUndefined } from "@/src/utils/is";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { getExporterUserType } from ".";
import ChangePasswordDialog from "./dialog/ChangePasswordDialog";

enum AlertDialogState {
  NULL,
  BACK_TO_PREVIOUS,
}

const NOTIFICATION_STRINGS = {
  email: "EMAIL",
  sms: "SMS",
  webPush: "WEB_PUSH",
};

const ImporterMypageEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  const [isEmailReceive, setIsEmailReceive] = useState(false);
  const [isMfa, setIsMfa] = useState(false);

  const {
    isFetching,
    isSuccess,
    notificationEmailReceive,
    notificationSmsReceive,
    notificationWebPushReceive,
    name,
    email,
    companyName,
    mainCategoryCodeItemNames,
    isMarketingEmailReceive,
    row,
    exporterUserType,
    isMfaOn,
  } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isFetching, isSuccess, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isFetching,
        isSuccess,
        notificationEmailReceive:
          !isUnstable && currentData.row.account.notificationEmailReceive
            ? NOTIFICATION_STRINGS.email
            : "",
        notificationSmsReceive:
          !isUnstable && currentData.row.account.notificationSmsReceive
            ? NOTIFICATION_STRINGS.sms
            : "",
        notificationWebPushReceive:
          !isUnstable && currentData.row.account.notificationWebPushReceive
            ? NOTIFICATION_STRINGS.webPush
            : "",
        name: !isUnstable ? currentData.row.name : "-",
        email: !isUnstable ? currentData.row.email : "-",
        companyName: !isUnstable ? currentData?.row.exporter.companyName : "-",
        mainCategoryCodeItemNames: !isUnstable
          ? currentData?.row.mainCategoryCodeItemNames.join(", ")
          : "-",
        isMarketingEmailReceive: !isUnstable
          ? currentData.row.account.isMarketingEmailReceive
          : isUnstable,
        row: currentData?.row,
        exporterUserType: !isUnstable
          ? getExporterUserType(currentData.row.exporterUserType, t)
          : "-",
        isMfaOn: !isUnstable ? currentData?.row.account.isMfaOn : isUnstable,
      };
    },
  });
  const [editMe] = useEditSessionMutation();
  const [isPasswordChangeDialogOpen, setIsPasswordChangeDialogOpen] =
    useState(false);
  const [selectLanguage, setSelectLanguage] = useState<string>();
  const [personalContact, setPersonalContact] = useState<{
    prefix?: string;
    restContact?: string;
  }>({
    prefix: undefined,
    restContact: undefined,
  });
  const [officeContact, setOfficeContact] = useState<{
    prefix?: string;
    restContact?: string;
  }>({
    prefix: undefined,
    restContact: undefined,
  });
  const [alertDialogState, setAlertDialogState] = useState(
    AlertDialogState.NULL
  );
  const [selectNotification, setSelectNotification] = useState<
    CheckboxValueType[]
  >([
    notificationEmailReceive,
    notificationSmsReceive,
    notificationWebPushReceive,
  ]);

  const { currentData: countryList, isFetching: isCountryListFetching } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "COUNTRY",
    });

  const countryListToOptionList = countryList
    ? countryList.reduce<{ label: string; value?: string }[]>((acc, val) => {
        if (!val.value1) {
          return acc;
        }
        const resource = {
          label: `${val.value1} (${val.codeItemName})`,
          value: `${val.value1}/${val.codeItemNameEn}/${val.codeItemNameKo}`,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const handleLanguageChange = (lang: "en" | "ko") => {
    setSelectLanguage(lang);
  };

  const informationSet = [
    { label: t("myPage:importer.label.name"), value: name },
    { label: t("myPage:importer.label.id"), value: email },
    {
      label: t("myPage:importer.label.affiliatedCompany"),
      value: companyName,
    },
    {
      label: t("myPage:importer.label.authority"),
      value: exporterUserType,
    },
    {
      label: t("myPage:importer.label.password"),
      value: (
        <FlexCenter className="gap-8">
          <span>••••••••</span>
          <StyledButton
            buttonGrade="secondary"
            buttonSize={32}
            onClick={() => setIsPasswordChangeDialogOpen(true)}
          >
            <EditIcon color="indigo" />
            {t("myPage:importer.edit")}
          </StyledButton>
        </FlexCenter>
      ),
    },
    {
      label: t("myPage:importer.label.contactPersonal"),
      value: (
        <ContactContainer>
          <StyledSelect
            showSearch
            allowClear
            options={countryListToOptionList}
            disabled={isCountryListFetching}
            suffixIcon={isCountryListFetching ? <Loader /> : undefined}
            placeholder="+1"
            filterOption={(input, option) => {
              if (!option) return false;
              const searchValue = input.toLowerCase();
              const optionValue = String(option.value || "").toLowerCase();
              const optionLabel = String(option.label || "").toLowerCase();

              return (
                optionValue.includes(searchValue) ||
                optionLabel.includes(searchValue)
              );
            }}
            value={
              countryList?.reduce((acc, val) => {
                if (val.value1 === personalContact.prefix) {
                  return `${val.value1}/${val.codeItemNameEn}/${val.codeItemNameKo}`;
                }

                return acc;
              }, "") || undefined
            }
            onChange={(value: string, option: any) => {
              const prefix = option.label.split("(")[0].trim();

              if (!value) {
                return setPersonalContact({
                  prefix: "",
                  restContact: personalContact.restContact,
                });
              }
              setPersonalContact({
                prefix,
                restContact: personalContact.restContact,
              });
            }}
          />
          <InputContainer>
            <StyledInput
              value={personalContact.restContact}
              placeholder={t("myPage:importer.placeholder.contactPlaceholder")}
              onClear={() => {
                setPersonalContact({
                  prefix: personalContact.prefix,
                  restContact: "",
                });
              }}
              onBlur={(e) => {
                const noZero = e.target.value.replace(/^0+/, "");
                setPersonalContact({
                  prefix: personalContact.prefix,
                  restContact: noZero,
                });
              }}
              onChange={(e) => {
                const value = e.target.value;
                const onlyNumber = value.replace(/[^0-9]/g, "");
                setPersonalContact({
                  prefix: personalContact.prefix,
                  restContact: onlyNumber,
                });
              }}
            />
          </InputContainer>
        </ContactContainer>
      ),
    },
    {
      label: t("myPage:importer.label.contactOffice"),
      value: (
        <ContactContainer>
          <StyledSelect
            showSearch
            allowClear
            options={countryListToOptionList}
            disabled={isCountryListFetching}
            suffixIcon={isCountryListFetching ? <Loader /> : undefined}
            placeholder="+1"
            filterOption={(input, option) => {
              if (!option) return false;
              const searchValue = input.toLowerCase();
              const optionValue = String(option.value || "").toLowerCase();
              const optionLabel = String(option.label || "").toLowerCase();

              return (
                optionValue.includes(searchValue) ||
                optionLabel.includes(searchValue)
              );
            }}
            value={
              countryList?.reduce((acc, val) => {
                if (val.value1 === officeContact.prefix) {
                  return `${val.value1}/${val.codeItemNameEn}/${val.codeItemNameKo}`;
                }

                return acc;
              }, "") || undefined
            }
            onChange={(value: string, option: any) => {
              const prefix = option.label.split("(")[0].trim();

              if (!value) {
                return setOfficeContact({
                  prefix: "",
                  restContact: officeContact.restContact,
                });
              }
              setOfficeContact({
                prefix,
                restContact: officeContact.restContact,
              });
            }}
          />
          <InputContainer>
            <StyledInput
              value={officeContact.restContact}
              placeholder={t("myPage:importer.placeholder.contactPlaceholder")}
              onClear={() => {
                setOfficeContact({
                  prefix: officeContact.prefix,
                  restContact: "",
                });
              }}
              onBlur={(e) => {
                const noZero = e.target.value.replace(/^0+/, "");
                setOfficeContact({
                  prefix: officeContact.prefix,
                  restContact: noZero,
                });
              }}
              onChange={(e) => {
                const value = e.target.value;
                const onlyNumber = value.replace(/[^0-9]/g, "");
                setOfficeContact({
                  prefix: officeContact.prefix,
                  restContact: onlyNumber,
                });
              }}
            />
          </InputContainer>
        </ContactContainer>
      ),
    },
  ];

  const informationRightSet = [
    {
      label: t("myPage:importer.label.assignedCategory"),
      value: mainCategoryCodeItemNames,
    },
    {
      label: t("myPage:importer.label.notification"),
      value: (
        <CheckboxGroup
          value={selectNotification}
          onChange={(e) => setSelectNotification(e)}
          options={[
            { value: NOTIFICATION_STRINGS.email, label: t("common:email.en") },
            { value: NOTIFICATION_STRINGS.sms, label: t("common:sms.en") },
            {
              value: NOTIFICATION_STRINGS.webPush,
              label: t("common:webPush.en"),
            },
          ]}
        />
      ),
    },
    {
      label: t("myPage:importer.label.agreeToReceiveEmail"),
      value: (
        <>
          <Radio
            label={t("myPage:importer.optionValue.agree")}
            typoType="b7r"
            color="gray4"
            checked={isEmailReceive}
            onChange={() => setIsEmailReceive(true)}
          />
          <Radio
            label={t("myPage:importer.optionValue.disagree")}
            typoType="b7r"
            color="gray4"
            checked={!isEmailReceive}
            onChange={() => setIsEmailReceive(false)}
          />
        </>
      ),
    },
    {
      label: t("myPage:importer.label.twoFactorAuthentication"),
      value: (
        <SwitchContainer>
          <Switch checked={isMfa} onChange={() => setIsMfa((prev) => !prev)} />
        </SwitchContainer>
      ),
    },
    {
      label: t("common:languageSetting"),
      value: (
        <StyledSelect
          style={{ width: "100%" }}
          showSearch={false}
          value={selectLanguage}
          onChange={handleLanguageChange}
          options={LANGUAGE_LIST.map((item) => {
            return { ...item, label: t(item.label) };
          })}
        />
      ),
    },
  ];

  const initContact = (data: ExporterUserDto) => {
    setPersonalContact({
      prefix: data.personalContactPrefix,
      restContact: data.personalContact,
    });
    setOfficeContact({
      prefix: data.officeContactPrefix,
      restContact: data.officeContact,
    });
    setSelectLanguage(data.account.language);
  };

  const handleEditMe = async () => {
    try {
      handleContentLoadingOn();
      await editMe({
        personalContactPrefix: personalContact.prefix || null,
        personalContact: personalContact.restContact || null,
        officeContactPrefix: officeContact.prefix || null,
        officeContact: officeContact.restContact || null,
        isMarketingEmailReceive: isEmailReceive,
        isMfaOn: isMfa,
        notificationEmailReceive: selectNotification.includes("EMAIL"),
        notificationSmsReceive: selectNotification.includes("SMS"),
        notificationWebPushReceive: selectNotification.includes("WEB_PUSH"),
        // en | ko
        language: selectLanguage as string,
      }).unwrap();

      navigate(IMPORTER_PRIVATE_PATH.MY_PAGE);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setIsEmailReceive(isMarketingEmailReceive);
      setIsMfa(isMfaOn);

      row && initContact(row);
    }
  }, [isFetching, isMarketingEmailReceive, isMfaOn, isSuccess, row]);

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <>
          <AlertDialog
            title={t("myPage:importer.backToPrevious.title")}
            description={t("myPage:importer.backToPrevious.description")}
            open
            onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
            onOk={() => navigate(-1)}
            okText={t("myPage:importer.ok")}
            cancelText={t("myPage:importer.exit")}
            onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
          />
        </>
      );
    }
  };

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:myPage")]}
      customPageTitle={
        <>
          <StyledHeader>
            {
              <Typo typoType="h1" as="h1" color="gray1">
                {t("common:editMyPage")}
              </Typo>
            }
          </StyledHeader>
        </>
      }
    >
      <SectionCard cardTitle={t("myPage:importer.label.information")}>
        <FlexBox>
          <HalfBox>
            {informationSet.map(({ label, value }) => {
              return <StyledSectionRow label={label} value={value} />;
            })}
          </HalfBox>
          <HalfBox>
            {informationRightSet.map(({ label, value }) => {
              return <StyledSectionRow label={label} value={value} />;
            })}
          </HalfBox>
        </FlexBox>
      </SectionCard>

      <BottomFixedContainer>
        <FooterButtonSection>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
            onClick={() =>
              setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS)
            }
          >
            <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
            {t("myPage:importer.backToPrevious.title")}
          </Button>
          <Button
            buttonGrade="primary"
            style={{ width: 158, textAlign: "center" }}
            onClick={handleEditMe}
          >
            {t("myPage:importer.save")}
          </Button>
        </FooterButtonSection>
      </BottomFixedContainer>

      {isPasswordChangeDialogOpen && (
        <ChangePasswordDialog
          onClose={() => setIsPasswordChangeDialogOpen(false)}
        />
      )}
      {renderAlertDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterMypageEditPage;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const EditIcon = styled(EditSvg)<{ color: ColorType }>`
  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const HalfBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
  gap: 24px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    gap: 16px;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  &.gap-8 {
    gap: 8px;
  }
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  width: 140px;
  flex-shrink: 0;
`;

const ContactContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const InputContainer = styled.div`
  flex: 1;
  max-width: 300px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSectionRow = styled(SectionCardRow)`
  .section-row-single-value {
    width: 100%;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column !important;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
