import Table from "@/src/components/atom/Table";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { columnShipmentList } from "@/src/pages/exporter/Contract/columns/columnShipmentList";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useBlnoToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import {
  shipmentListApi,
  useLazyGetShipmentListQuery,
} from "@/src/store/apis/shipments/shipmentList";
import { ShipmentListViewDto } from "@/src/store/apis/shipments/shipmentList/interface";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const shipmentEmptyArray: ShipmentListViewDto[] = [];

function ShipmentList() {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefs] = useState<ColDef[]>(columnShipmentList(t));
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  // API
  const [blnoToSeaVantageUrl] = useBlnoToSeaVantageUrlMutation();
  const [getShipmentList] = useLazyGetShipmentListQuery();
  const { isFetching, count, bookingWithShipmentList } =
    shipmentListApi.endpoints.getShipmentList.useQueryState(
      {
        bookingId: Number(params.id),
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
      {
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            isFetching,
            count: !isUnstable ? currentData.count : 0,
            bookingWithShipmentList: !isUnstable
              ? currentData.rows
              : shipmentEmptyArray,
          };
        },
      },
    );

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "status",
        langKey: "contract:exporter.detail.shipmentList.status",
      },
      {
        columnKey: "blNo",
        langKey: "contract:exporter.detail.shipmentList.blNo",
      },
      {
        columnKey: "bookingNo",
        langKey: "contract:exporter.detail.shipmentList.bookingNo",
      },
      {
        columnKey: "etdAt",
        langKey: "contract:exporter.detail.shipmentList.etdAt",
      },
      {
        columnKey: "etaAt",
        langKey: "contract:exporter.detail.shipmentList.etaAt",
      },
      {
        columnKey: "numberOfContainer",
        langKey: "contract:exporter.detail.shipmentList.numberOfContainer",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "contract:exporter.detail.shipmentList.totalNetWeight",
      },
      {
        columnKey: "portOfLoading",
        langKey: "contract:exporter.detail.shipmentList.portOfLoading",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "contract:exporter.detail.shipmentList.placeOfDelivery",
      },
    ],
  });

  const handleBlnoClick = async (selectCell: ShipmentListViewDto) => {
    try {
      const { cargoTrackUrl } = await blnoToSeaVantageUrl({
        blNo: selectCell.blNo,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;
      const shipmentListParams = {
        page,
        pageSize,
        bookingId: Number(params.id),
      };

      try {
        await getShipmentList(shipmentListParams).unwrap();
        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [alert, getShipmentList, pagination.page, pagination.pageSize, params.id],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <SectionFoldableCard
      open={isCardOpen}
      onOpenChange={setIsCardOpen}
      cardTitle={t("contract:exporter.detail.shipmentList.title")}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={isCardOpen}
          onClick={() => setIsCardOpen((prev) => !prev)}
        />
      }
    >
      {/* Table */}
      <Table
        ref={gridRef}
        rowData={bookingWithShipmentList}
        columnDefs={columnDefs}
        isPaginationDataMaping
        totalPage={count}
        pageSize={pagination.pageSize}
        page={pagination.page}
        onCellClicked={(e) => {
          const selectShipmentRow = e.node.data;
          const isBlNoColumn = e.column.getColDef().field === "blNo";

          if (isBlNoColumn) {
            if (selectShipmentRow.blNo) {
              handleBlnoClick(selectShipmentRow);
            }
          } else {
            return navigate(
              `${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${selectShipmentRow.id}`,
            );
          }
        }}
        handlePaginationClick={handlePaginationClick}
        onGridReady={() => setIsReady(true)}
      />
    </SectionFoldableCard>
  );
}

export default ShipmentList;
