import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import Badge from "@/src/components/atom/Badge";
import { Button, IconButton } from "@/src/components/atom/Button";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { SimpleDocumentMediaDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { downloadFile } from "@/src/utils/downloadFile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "styled-components";

interface DocumentsExternalCardProps {
  data: SimpleDocumentMediaDto[];
}

function DocumentsExternalCard({ data }: DocumentsExternalCardProps) {
  const { t } = useTranslation();
  const [allButtonRef, setAllButtonRef] = useState<HTMLButtonElement[]>([]);

  return (
    <SectionCard
      cardTitle={
        <TitleWrapper>
          <Typo typoType="h6">
            {t("shipment:exporter.detail.label.additionalDocuments")}
          </Typo>
          <Badge
            badgeColor="white"
            borderColor="green2"
            color="green2"
            badgeSize="S"
            badgeType="line"
            text={t("shipment:exporter.detail.label.share")}
          />
        </TitleWrapper>
      }
      rightAccessory={
        <RightAccessoryContainer>
          <StyledButton
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => allButtonRef.forEach((button) => button.click())}
          >
            <DownloadIcon />
            {t("shipment:exporter.detail.button.downloadButton")}
          </StyledButton>
        </RightAccessoryContainer>
      }
    >
      <Files>
        {data.length >= 1 ? (
          data.map((item, idx) => {
            return (
              <SectionCardRow
                key={idx.toString()}
                label={`${t(
                  "shipment:exporter.detail.label.document",
                )} ${addZeroPrefix(idx + 1)}`}
                value={
                  <FileInfo>
                    <FileName typoType="b7r" color="gray5">
                      {item.originalFileName}
                    </FileName>

                    <ButtonContainer>
                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                setAllButtonRef(allButtonRef.concat(node));
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(item.mediaUrl, item.originalFileName);
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>

                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a
                            href={item.mediaUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </ButtonContainer>
                  </FileInfo>
                }
              />
            );
          })
        ) : (
          <MemoNoDataContainer>{renderNoRowsComponent()}</MemoNoDataContainer>
        )}
      </Files>
    </SectionCard>
  );
}

export default DocumentsExternalCard;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RightAccessoryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 220px;
  padding: 2px 0;

  ${StyledScroll}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FileInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const FileButtons = styled.div`
  display: flex;
  gap: 4px;

  a {
    line-height: 0px;
  }
`;

const FileName = styled(Typo)`
  word-break: break-all;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 216px;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
