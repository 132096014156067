import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ContentLoading from "./components/molecule/ContentLoading";
import FullLoading from "./components/molecule/FullLoading";
import { AlertQuantumRoot } from "./components/quantum/AlertQuantum";
import CountryCode from "./CountryCode";
import CurrentSubscription from "./CurrentSubscription";
import i18n from "./i18n";
import PushAlarms from "./PushAlarms";
import exporterPrivateRoutes from "./routes/exporter/private";
import importerPrivateRoutes from "./routes/importer/private";
import privateRoutes from "./routes/private/private";
import publicRoutes from "./routes/public/public";
import { useAppSelector } from "./store";
import GlobalStyle from "./styles/GlobalStyle";
import { isDevelopment } from "./utils/environment";
import { isNull } from "./utils/is";
import useContentLoading from "./hooks/useContentLoading";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FIREBASE_CONFIG } from "./constant/firebaseConfig";

const app = initializeApp(FIREBASE_CONFIG);
export const analytics = !isDevelopment ? getAnalytics(app) : null;

const DOCUMENT_TITLE_DEVELOP = "[Dev] ECOYA Export";
const router = createBrowserRouter([
  ...importerPrivateRoutes,
  ...exporterPrivateRoutes,
  ...privateRoutes,
  ...publicRoutes,
]);

function App() {
  const user = useAppSelector((state) => state.auth.user);
  const webUtilLoading = useAppSelector((state) => state.webUtil.loading);
  const [isReady, setIsReady] = useState(false);
  useContentLoading();

  // 개발환경 dev, local시 document Title, favicon 변경
  useEffect(() => {
    if (isDevelopment) {
      document.title = DOCUMENT_TITLE_DEVELOP;

      const favicon = document.querySelector("link[rel='icon']");
      if (favicon instanceof HTMLLinkElement) {
        favicon.href = isDevelopment ? "/favicon-dev.ico" : "/favicon.ico";
      }
    }
  }, []);

  // 이메일 공유 페이지, 로그인 하지 않은 유저는 라이브러리 언어 (개인정보 언어에 따르지 않음)
  // 라이브러리 언어값은 항상 유저의 언어값으로 동기화
  useEffect(() => {
    const isSharedEmailPage = window.location.pathname.includes("shared-email");

    if (isSharedEmailPage || isNull(user)) {
      return;
    }

    if (user?.account.language !== i18n.language) {
      i18n.changeLanguage(user?.account.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.account.language]);

  // i18next json 모두 불러온이후 렌더링
  useEffect(() => {
    if (i18n.isInitialized) {
      setIsReady(true);
    } else {
      i18n.on("initialized", () => setIsReady(true));
    }
  }, []);

  if (!isReady) {
    return <></>;
  }

  return (
    <AlertQuantumRoot>
      <GlobalStyle />
      <CountryCode />
      <PushAlarms />
      <CurrentSubscription />
      <RouterProvider router={router} />
      <div id="alert" />
      {webUtilLoading && <FullLoading />}
      <ContentLoading />
    </AlertQuantumRoot>
  );
}

export default App;
