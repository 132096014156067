import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as EditSvg } from "@/src/assets/icons/icon-edit-pencel.svg";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import OpenSvg from "@/src/assets/icons/icon-open.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import ShareableContractDialog from "@/src/components/organism/ShareableContractDialog";
import CiDownLoad from "@/src/components/template/pdfs/Ci/DownLoad";
import CiPreview from "@/src/components/template/pdfs/Ci/Preview";
import PlDownLoad from "@/src/components/template/pdfs/Pl/DownLoad";
import PlPreview from "@/src/components/template/pdfs/Pl/Preview";
import useAlert from "@/src/hooks/useAlert";
import EXPORTER_PATH from "@/src/routes/exporter/path";
import { useBlnoToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import {
  useShareBlMutation,
  useShareCiPlMutation,
} from "@/src/store/apis/sharing";
import { useGetCiInformationQuery } from "@/src/store/apis/shipments/ci";
import { useGetPlInformationQuery } from "@/src/store/apis/shipments/pl";
import { useLazyGetShipmentDetailDocumentAndMemoQuery } from "@/src/store/apis/shipments/shipmentDetail";
import {
  BlStatusType,
  CiStatusType,
  InternalScFileInfo,
  PlStatusType,
  SimpleDocumentMediaDto,
  SimpleFileMediaDto,
  SimpleShipmentMemoDto,
} from "@/src/store/apis/shipments/shipmentDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { downloadFile } from "@/src/utils/downloadFile";
import { isNull, isUndefined, isValidUrl } from "@/src/utils/is";
import { decodeHtml } from "@/src/utils/transform";
import dayjs from "dayjs";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { css, styled } from "styled-components";
import { renderDocumentStatusBadge } from "../../../utils/renderShipmentStatusBadge";
import RegisterBlDialog from "../dialog/RegisterBlDialog";
import AllFileCard from "./AllFileCard";
import DocumentsExternalCard from "./DocumentsExternalCard";
import DocumentsInternalCard from "./DocumentsInternalCard";
import MemoCard from "./MemoCard";

type DocumentType = "CI" | "PL" | "BL";
type DialogItemType = null | "BL_REGISTER" | "BL_EDIT" | "SHARE" | "BL_SHARE";

interface DocumentMemoCardProps extends HTMLAttributes<HTMLDivElement> {
  isEditAuth: boolean;
  isShipmentCompleted: boolean;
}

const simpleDocumentEmptyArray: SimpleDocumentMediaDto[] = [];
const scEmptyArray: InternalScFileInfo[] = [];
const fileEmptyArray: SimpleFileMediaDto[] = [];
const memoEmptyArray: SimpleShipmentMemoDto[] = [];

function DocumentMemoCard({
  isEditAuth,
  isShipmentCompleted,
  ...props
}: DocumentMemoCardProps) {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const navigate = useNavigate();

  const [allButtonRef, setAllButtonRef] = useState<HTMLButtonElement[]>([]);
  const [selectDialog, setSelectDialog] = useState<DialogItemType>(null);
  const [
    getShipmentDocumentAndMemo,
    {
      isSuccess,
      blNo,
      blStatus,
      shippingLine,
      shippingLineUrl,
      draftBlMedia,
      finalBlMedia,
      ciStatus,
      plStatus,
      internalSimpleDocumentMedias,
      externalSimpleDocumentMedias,
      internalScFileInfos,
      scAttactedFileSimpleFileMedias,
      poFileSimpleFileMedias,
      lcFileSimpleFileMedias,
      bookingFileSimpleFileMedias,
      simpleShipmentMemos,
      isCiRecheckNeeded,
      isPlRecheckNeeded,
      isCiPlSharedAt,
      isBlSharedAt,
    },
  ] = useLazyGetShipmentDetailDocumentAndMemoQuery({
    selectFromResult: ({ isError, currentData, isSuccess, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isSuccess,
        blNo: !isUnstable ? currentData.row.blNo : "",
        blStatus: currentData?.row.blStatus as BlStatusType,
        shippingLine: !isUnstable ? (currentData.row.shippingLine ?? "") : "",
        shippingLineUrl: !isUnstable ? currentData.row.shippingLineUrl : "",
        draftBlMedia: currentData?.row.blMedias?.find(
          (media) => media.documentType === "BL_DRAFT",
        ),
        finalBlMedia: currentData?.row.blMedias?.find(
          (media) => media.documentType === "BL_FINAL",
        ),
        ciStatus: currentData?.row.ciStatus as CiStatusType,
        plStatus: currentData?.row.plStatus as PlStatusType,
        internalSimpleDocumentMedias: !isUnstable
          ? (currentData.row.internalSimpleDocumentMedias ??
            simpleDocumentEmptyArray)
          : simpleDocumentEmptyArray,
        externalSimpleDocumentMedias: !isUnstable
          ? (currentData.row.externalSimpleDocumentMedias ??
            simpleDocumentEmptyArray)
          : simpleDocumentEmptyArray,
        internalScFileInfos: !isUnstable
          ? (currentData.row.internalScFileInfos ?? scEmptyArray)
          : scEmptyArray,
        scAttactedFileSimpleFileMedias: !isUnstable
          ? (currentData.row.scAttactedFileSimpleFileMedias ?? fileEmptyArray)
          : fileEmptyArray,
        poFileSimpleFileMedias: !isUnstable
          ? (currentData.row.poFileSimpleFileMedias ?? fileEmptyArray)
          : fileEmptyArray,
        lcFileSimpleFileMedias: !isUnstable
          ? (currentData.row.lcFileSimpleFileMedias ?? fileEmptyArray)
          : fileEmptyArray,
        bookingFileSimpleFileMedias: !isUnstable
          ? (currentData.row.bookingFileSimpleFileMedias ?? fileEmptyArray)
          : fileEmptyArray,
        simpleShipmentMemos: !isUnstable
          ? (currentData.row.simpleShipmentMemos ?? memoEmptyArray)
          : memoEmptyArray,
        isCiRecheckNeeded: currentData?.row.isCiRecheckNeeded,
        isPlRecheckNeeded: currentData?.row.isPlRecheckNeeded,
        isCiPlSharedAt: !isUnstable ? !!currentData.row.ciPlSharedAt : true,
        isBlSharedAt: !isUnstable ? !!currentData.row.blSharedAt : true,
      };
    },
  });
  const [blnoToSeaVantageUrl] = useBlnoToSeaVantageUrlMutation();
  const [shareCiPl] = useShareCiPlMutation();
  const [shareBl] = useShareBlMutation();

  const {
    data: ciData,
    isFetching: isCiFetching,
    isError: isCiError,
    isSuccess: isCiSuccess,
  } = useGetCiInformationQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const {
    data: plData,
    isFetching: isPlFetching,
    isError: isPlError,
    isSuccess: isPlSuccess,
  } = useGetPlInformationQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const totalOfContainer = plData?.plItems.reduce<{
    quantityUnit: string[];
    quantity: number;
    grossWeight: number;
    grossWeightUnit: string;
    netWeight: number;
    netWeightUnit: string;
    cbm: number;
    isGrossWeightHidden: boolean;
    isNetWeightHidden: boolean;
    isCbmHidden: boolean;
  }>(
    (acc, val) => {
      const totalOfsingleContainer = val.plItemContainers.reduce<{
        quantityUnit: string[];
        quantity: number;
        grossWeight: number;
        grossWeightUnit: string;
        netWeight: number;
        netWeightUnit: string;
        cbm: number;
        isGrossWeightHidden: boolean;
        isNetWeightHidden: boolean;
        isCbmHidden: boolean;
      }>(
        (a, v) => {
          return {
            quantityUnit: v.quantityUnit
              ? a.quantityUnit.includes(v.quantityUnit)
                ? a.quantityUnit
                : a.quantityUnit.concat(v.quantityUnit)
              : a.quantityUnit,
            quantity: a.quantity + (v?.quantity || 0),
            grossWeight:
              a.grossWeight +
              (Number.isNaN(Number(v?.grossWeight))
                ? 0
                : Number(v?.grossWeight)),
            grossWeightUnit: v.grossWeightUnit,
            netWeight:
              a.netWeight +
              (Number.isNaN(Number(v?.netWeight)) ? 0 : Number(v?.netWeight)),
            netWeightUnit: v.netWeightUnit,
            cbm: a.cbm + (isNull(v?.cbm) ? 0 : Number(v?.cbm) || 0),
            isGrossWeightHidden: a.isGrossWeightHidden
              ? true
              : v.isGrossWeightHidden,
            isNetWeightHidden: a.isNetWeightHidden ? true : v.isNetWeightHidden,
            isCbmHidden: a.isCbmHidden ? true : v.isCbmHidden,
          };
        },
        {
          quantityUnit: [],
          quantity: 0,
          grossWeight: 0,
          grossWeightUnit: "",
          netWeight: 0,
          netWeightUnit: "",
          cbm: 0,
          isGrossWeightHidden: false,
          isNetWeightHidden: false,
          isCbmHidden: false,
        },
      );

      return {
        quantityUnit: [
          ...acc.quantityUnit,
          ...totalOfsingleContainer.quantityUnit,
        ],
        quantity: acc.quantity + totalOfsingleContainer.quantity,
        grossWeight: acc.grossWeight + totalOfsingleContainer.grossWeight,
        grossWeightUnit: totalOfsingleContainer.grossWeightUnit,
        netWeight: acc.netWeight + totalOfsingleContainer.netWeight,
        netWeightUnit: totalOfsingleContainer.netWeightUnit,
        cbm: acc.cbm + totalOfsingleContainer.cbm,
        isGrossWeightHidden: acc.isGrossWeightHidden
          ? true
          : totalOfsingleContainer.isGrossWeightHidden,
        isNetWeightHidden: acc.isNetWeightHidden
          ? true
          : totalOfsingleContainer.isNetWeightHidden,
        isCbmHidden: acc.isCbmHidden
          ? true
          : totalOfsingleContainer.isCbmHidden,
      };
    },
    {
      quantityUnit: [],
      quantity: 0,
      grossWeight: 0,
      grossWeightUnit: "",
      netWeight: 0,
      netWeightUnit: "",
      cbm: 0,
      isGrossWeightHidden: false,
      isNetWeightHidden: false,
      isCbmHidden: false,
    },
  );

  useEffect(() => {
    (async () => {
      try {
        await getShipmentDocumentAndMemo({ id: Number(params.id) }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShippingLineUrlClick = () => {
    if (!shippingLineUrl) {
      return;
    }
    // 저장된 URL 디코딩 후 정제
    const decodedUrl = decodeHtml(shippingLineUrl);
    const cleanUrl = decodedUrl
      .replace(/&#47;/g, "/")
      .replace(/&#59;/g, ";")
      .replace(/&#183;/g, ".");
    // URL 검증
    if (!isValidUrl(cleanUrl)) {
      alert.showAlert({
        type: "error",
        message: t("alert:invalidUrl"),
      });
      return;
    }
    window.open(cleanUrl, "_blank", "noopener,noreferrer");
  };

  const handleBlNoClick = async () => {
    if (!blNo) {
      return;
    }

    try {
      const { cargoTrackUrl } = await blnoToSeaVantageUrl({
        blNo,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderCiPdfButtons = () => {
    if (isCiFetching || isCiError || !isCiSuccess) {
      return (
        <>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <PreviewIcon disabled />
          </IconButton>
          <IconButton
            disabled
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <DownloadIcon disabled />
          </IconButton>
        </>
      );
    }

    const ciInfoToCiData = {
      ...ciData,
      etdAt: ciData?.etdAt
        ? dayjs(ciData.etdAt).format("YYYY-MM-DD")
        : undefined,
      lcInfoList: ciData?.lcInfoList
        ? ciData.lcInfoList.map(({ lcNo, expireDateAt }) => {
            return {
              lcNo,
              expireDateAt: dayjs(expireDateAt).format("YYYY-MM-DD"),
            };
          })
        : [],
      invoiceDateAt: ciData?.invoiceDateAt
        ? dayjs(ciData.invoiceDateAt).format("YYYY-MM-DD")
        : undefined,
      commonWeightUnit: ciData.ciItems?.[0]?.netWeightUnit,
      componyLogoUrl: ciData.businessLogoSimpleMedia?.mediaUrl,
      signatureUrl: ciData.simpleSignatureMedias?.find(
        ({ isDefault }) => isDefault,
      )?.mediaUrl,
      totalIsGrossWeightHidden: ciData.ciItems.some(
        ({ isGrossWeightHidden }) => isGrossWeightHidden,
      ),
      totalIsNetWeightHidden: ciData.ciItems.some(
        ({ isNetWeightHidden }) => isNetWeightHidden,
      ),
    };

    return (
      <>
        <CiPreview
          data={ciInfoToCiData}
          trigger={
            <IconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <PreviewIcon />
            </IconButton>
          }
        />

        <CiDownLoad
          data={ciInfoToCiData}
          fileName={`${t("common:invoiceNo")} ${ciData.invoiceNo}.pdf`}
          trigger={
            <IconButton
              ref={(node) => {
                if (node) {
                  const isAlreadyInRef = allButtonRef.some((buttonNode) =>
                    buttonNode.isSameNode(node),
                  );

                  if (!isAlreadyInRef) {
                    setAllButtonRef(allButtonRef.concat(node));
                  }
                }
              }}
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <DownloadIcon />
            </IconButton>
          }
        />
      </>
    );
  };

  const renderPlPdfButtons = () => {
    if (isPlFetching || isPlError || !isPlSuccess || !totalOfContainer) {
      return (
        <>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <PreviewIcon disabled />
          </IconButton>
          <IconButton
            disabled
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <DownloadIcon disabled />
          </IconButton>
        </>
      );
    }

    const plInfoToPlData = {
      ...plData,
      etdAt: plData?.etdAt
        ? dayjs(plData?.etdAt).format("YYYY-MM-DD")
        : undefined,
      lcInfoList: plData?.lcInfoList
        ? plData.lcInfoList.map(({ lcNo, expireDateAt }) => {
            return {
              lcNo,
              expireDateAt: dayjs(expireDateAt).format("YYYY-MM-DD"),
            };
          })
        : [],
      invoiceDateAt: plData?.invoiceDateAt
        ? dayjs(plData?.invoiceDateAt).format("YYYY-MM-DD")
        : undefined,
      commonWeightUnit: totalOfContainer.grossWeightUnit,
      componyLogoUrl: plData.businessLogoSimpleMedia?.mediaUrl,
      signatureUrl: plData.simpleSignatureMedias?.find(
        ({ isDefault }) => isDefault,
      )?.mediaUrl,
      totalQuantityUnit: totalOfContainer.quantityUnit,
      totalQuantity: totalOfContainer.quantity,
      totalGrossWeight: totalOfContainer.grossWeight,
      totalGrossWeightUnit: totalOfContainer.grossWeightUnit,
      totalNetWeight: totalOfContainer.netWeight,
      totalNetWeightUnit: totalOfContainer.netWeightUnit,
      totalCbm: totalOfContainer.cbm,
      totalIsGrossWeightHidden: totalOfContainer.isGrossWeightHidden,
      totalIsNetWeightHidden: totalOfContainer.isNetWeightHidden,
      totalIsCbmHidden: totalOfContainer.isCbmHidden,
    };

    return (
      <>
        <PlPreview
          data={plInfoToPlData}
          trigger={
            <IconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <PreviewIcon />
            </IconButton>
          }
        />
        <PlDownLoad
          data={plInfoToPlData}
          trigger={
            <IconButton
              ref={(node) => {
                if (node) {
                  const isAlreadyInRef = allButtonRef.some((buttonNode) =>
                    buttonNode.isSameNode(node),
                  );

                  if (!isAlreadyInRef) {
                    setAllButtonRef(allButtonRef.concat(node));
                  }
                }
              }}
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <DownloadIcon />
            </IconButton>
          }
        />
      </>
    );
  };

  const renderDocumentEditButtons = (
    type: DocumentType,
    status: "TEMPORARY" | "FINAL" | null,
  ) => {
    switch (type) {
      case "CI":
        if (!status) {
          return (
            <CreateButton
              buttonGrade="secondary"
              buttonSize={32}
              disabled={!isEditAuth || isShipmentCompleted}
              onClick={() =>
                navigate(`${EXPORTER_PATH.SHIPMENT_ADD_CI}/${params.id}`)
              }
            >
              {t("shipment:exporter.detail.button.createCIButton")}
            </CreateButton>
          );
        }

        return (
          <DocumentButtonContainer>
            {renderCiPdfButtons()}
            <StyledButton
              buttonSize={32}
              buttonGrade="secondary"
              disabled={
                !isEditAuth || blStatus === "BL_FINAL" || isShipmentCompleted
              }
              onClick={() =>
                navigate(`${EXPORTER_PATH.SHIPMENT_EDIT_CI}/${params.id}`)
              }
            >
              <EditIcon
                color={
                  !isEditAuth || blStatus === "BL_FINAL" || isShipmentCompleted
                    ? "gray8"
                    : "indigo"
                }
              />
              {t("common:edit")}
            </StyledButton>
          </DocumentButtonContainer>
        );

      case "PL":
        if (!status) {
          return (
            <CreateButton
              buttonGrade="secondary"
              buttonSize={32}
              disabled={!isEditAuth || isShipmentCompleted}
              onClick={() =>
                navigate(`${EXPORTER_PATH.SHIPMENT_ADD_PL}/${params.id}`)
              }
            >
              {t("shipment:exporter.detail.button.createPLButton")}
            </CreateButton>
          );
        }

        return (
          <DocumentButtonContainer>
            {renderPlPdfButtons()}
            <StyledButton
              buttonSize={32}
              buttonGrade="secondary"
              disabled={
                !isEditAuth || blStatus === "BL_FINAL" || isShipmentCompleted
              }
              onClick={() =>
                navigate(`${EXPORTER_PATH.SHIPMENT_EDIT_PL}/${params.id}`)
              }
            >
              <EditIcon
                color={
                  !isEditAuth || blStatus === "BL_FINAL" || isShipmentCompleted
                    ? "gray8"
                    : "indigo"
                }
              />
              {t("common:edit")}
            </StyledButton>
          </DocumentButtonContainer>
        );

      case "BL":
        if (!status) {
          return (
            <CreateButton
              buttonGrade="secondary"
              buttonSize={32}
              disabled={!isEditAuth || isShipmentCompleted}
              onClick={() => setSelectDialog("BL_REGISTER")}
            >
              {t("shipment:exporter.detail.button.registerPLButton")}
            </CreateButton>
          );
        }

        return (
          <>
            <BlDocumentInfo>
              <Typo typoType="b7r">{t("common:shippingLine")}</Typo>
              <BlDocumentName>
                <BlName typoType="b7r" color="gray5">
                  {shippingLine || "-"}
                </BlName>
                {shippingLineUrl && (
                  <IconButton
                    buttonSize={24}
                    buttonColor="black"
                    buttonGrade="tertiary"
                    onClick={handleShippingLineUrlClick}
                  >
                    <Icon iconSrc={OpenSvg} iconSize={20} />
                  </IconButton>
                )}
              </BlDocumentName>
            </BlDocumentInfo>

            <BlDocumentBottomContainer>
              {(draftBlMedia || finalBlMedia) && (
                <BlDocumentFileContainer>
                  {draftBlMedia && (
                    <BlDocumentFileInnerContainer>
                      <Typo typoType="b7m" color="gray2">
                        {t("common:draft")}
                      </Typo>
                      <BlDocumentFileButtonContainer>
                        <IconButton
                          buttonSize={32}
                          buttonColor="black"
                          buttonGrade="tertiary"
                          disabled={!draftBlMedia.mediaUrl}
                          onClick={() => {
                            window.open(draftBlMedia.mediaUrl, "_blank");
                          }}
                        >
                          <PreviewIcon disabled={!draftBlMedia.mediaUrl} />
                        </IconButton>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                setAllButtonRef(allButtonRef.concat(node));
                              }
                            }
                          }}
                          buttonSize={32}
                          buttonColor="black"
                          buttonGrade="tertiary"
                          disabled={!draftBlMedia.mediaUrl}
                          onClick={() => {
                            if (draftBlMedia.mediaUrl) {
                              downloadFile(
                                draftBlMedia.mediaUrl ?? "",
                                draftBlMedia.mediaOriginalFileName,
                              );
                            }
                          }}
                        >
                          <DownloadIcon disabled={!draftBlMedia.mediaUrl} />
                        </IconButton>
                      </BlDocumentFileButtonContainer>
                    </BlDocumentFileInnerContainer>
                  )}
                  {draftBlMedia && finalBlMedia && <BlDocumentFileDivider />}
                  {finalBlMedia && (
                    <BlDocumentFileInnerContainer>
                      <Typo typoType="b7m" color="gray2">
                        {t("common:final")}
                      </Typo>
                      <BlDocumentFileButtonContainer>
                        <IconButton
                          buttonSize={32}
                          buttonColor="black"
                          buttonGrade="tertiary"
                          disabled={!finalBlMedia.mediaUrl}
                          onClick={() => {
                            window.open(finalBlMedia.mediaUrl, "_blank");
                          }}
                        >
                          <PreviewIcon disabled={!finalBlMedia.mediaUrl} />
                        </IconButton>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                setAllButtonRef(allButtonRef.concat(node));
                              }
                            }
                          }}
                          buttonSize={32}
                          buttonColor="black"
                          buttonGrade="tertiary"
                          disabled={!finalBlMedia.mediaUrl}
                          onClick={() => {
                            if (finalBlMedia.mediaUrl) {
                              downloadFile(
                                finalBlMedia.mediaUrl ?? "",
                                finalBlMedia.mediaOriginalFileName,
                              );
                            }
                          }}
                        >
                          <DownloadIcon disabled={!finalBlMedia.mediaUrl} />
                        </IconButton>
                      </BlDocumentFileButtonContainer>
                    </BlDocumentFileInnerContainer>
                  )}
                </BlDocumentFileContainer>
              )}
              <Flex gap={8}>
                <Button
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={() => setSelectDialog("BL_SHARE")}
                  disabled={blStatus !== "BL_FINAL" || isBlSharedAt}
                >
                  {t("common:sharing")}
                </Button>
                <StyledButton
                  buttonSize={32}
                  buttonGrade="secondary"
                  onClick={() => setSelectDialog("BL_EDIT")}
                  style={{
                    alignSelf: "center",
                  }}
                  disabled={!isEditAuth}
                >
                  <EditIcon color={!isEditAuth ? "gray8" : "indigo"} />

                  {t("common:edit")}
                </StyledButton>
              </Flex>
            </BlDocumentBottomContainer>
          </>
        );
    }
  };

  const renderDialogs = () => {
    if (selectDialog === "BL_REGISTER") {
      return (
        <RegisterBlDialog
          open={selectDialog === "BL_REGISTER"}
          onClose={() => setSelectDialog(null)}
          type={"REGISTER"}
          blNo={blNo}
          blStatus={
            blStatus === "BL_DRAFT"
              ? "DRAFT"
              : blStatus === "BL_FINAL"
                ? "FINAL"
                : undefined
          }
          shippingLineUrl={shippingLineUrl ?? ""}
          shippingLine={shippingLine ?? ""}
        />
      );
    }

    if (selectDialog === "BL_EDIT") {
      return (
        <RegisterBlDialog
          open={selectDialog === "BL_EDIT"}
          onClose={() => setSelectDialog(null)}
          type={"EDIT"}
          blNo={blNo}
          blStatus={
            blStatus === "BL_DRAFT"
              ? "DRAFT"
              : blStatus === "BL_FINAL"
                ? "FINAL"
                : undefined
          }
          shippingLineUrl={shippingLineUrl ?? ""}
          shippingLine={shippingLine ?? ""}
          draftBlFile={draftBlMedia}
          finalBlFile={finalBlMedia}
        />
      );
    }

    if (selectDialog === "SHARE") {
      return (
        <ShareableContractDialog
          id={Number(params.id)}
          onClose={() => setSelectDialog(null)}
          onShare={async () => {
            try {
              setSelectDialog(null);
              await shareCiPl({ shipmentId: Number(params.id) }).unwrap();
            } catch (e: any) {
              const message = Array.isArray(e.data?.message)
                ? e.data?.message[0]
                : e.data?.message;
              alert.showAlert({
                type: "error",
                message: message ?? t("alert:unHandled"),
              });
            } finally {
              await getShipmentDocumentAndMemo({ id: Number(params.id) });
            }
          }}
          type="shipment"
        />
      );
    }

    if (selectDialog === "BL_SHARE") {
      return (
        <ShareableContractDialog
          id={Number(params.id)}
          onClose={() => setSelectDialog(null)}
          onShare={async () => {
            try {
              setSelectDialog(null);
              await shareBl({ shipmentId: Number(params.id) }).unwrap();
            } catch (e: any) {
              const message = Array.isArray(e.data?.message)
                ? e.data?.message[0]
                : e.data?.message;
              alert.showAlert({
                type: "error",
                message: message ?? t("alert:unHandled"),
              });
            } finally {
              await getShipmentDocumentAndMemo({ id: Number(params.id) });
            }
          }}
          type="shipment"
        />
      );
    }
  };

  return (
    <DocumentMemoContainer {...props}>
      <SectionCard
        cardTitle={t("shipment:exporter.detail.label.shippingDocuments")}
        rightAccessory={
          <StyledButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => {
              allButtonRef.forEach((buttonNode) => buttonNode.click());
            }}
          >
            <DownloadIcon size={16} />
            {t("shipment:exporter.detail.button.downloadButton")}
          </StyledButton>
        }
      >
        <ShippingDocument>
          <ShippingDocumentsItem>
            <Flex alignItems="center" justifyContent="space-evenly" isFullWidth>
              <Flex flexDirection="column" gap={8}>
                <DocumentTitle>
                  <Typo typoType="h3">C/I</Typo>
                  <StatusBadgeContainer>
                    {isCiRecheckNeeded && <CheckNeededAlarm />}
                    {renderDocumentStatusBadge({
                      t,
                      type:
                        ciStatus === "CI_TEMPORARY"
                          ? "TEMPORARY_REGISTER"
                          : ciStatus === "CI_FINAL"
                            ? "COMPLETED"
                            : "BEFORE_REGISTRATION",
                    })}
                  </StatusBadgeContainer>
                </DocumentTitle>
                {renderDocumentEditButtons(
                  "CI",
                  ciStatus === "CI_TEMPORARY"
                    ? "TEMPORARY"
                    : ciStatus === "CI_FINAL"
                      ? "FINAL"
                      : null,
                )}
              </Flex>

              <div className="divider" />

              <Flex flexDirection="column" gap={8}>
                <DocumentTitle>
                  <Typo typoType="h3">P/L</Typo>
                  <StatusBadgeContainer>
                    {isPlRecheckNeeded && <CheckNeededAlarm />}
                    {renderDocumentStatusBadge({
                      t,
                      type:
                        plStatus === "PL_TEMPORARY"
                          ? "TEMPORARY_REGISTER"
                          : plStatus === "PL_FINAL"
                            ? "COMPLETED"
                            : "BEFORE_REGISTRATION",
                    })}
                  </StatusBadgeContainer>
                </DocumentTitle>
                {renderDocumentEditButtons(
                  "PL",
                  plStatus === "PL_TEMPORARY"
                    ? "TEMPORARY"
                    : plStatus === "PL_FINAL"
                      ? "FINAL"
                      : null,
                )}
              </Flex>
            </Flex>

            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() => setSelectDialog("SHARE")}
              disabled={
                ciStatus !== "CI_FINAL" ||
                plStatus !== "PL_FINAL" ||
                isCiPlSharedAt
              }
            >
              {t("common:sharing")}
            </Button>
          </ShippingDocumentsItem>

          <ShippingDocumentItem
            $gap={
              blStatus !== "BL_DRAFT" && blStatus !== "BL_FINAL"
                ? 56
                : undefined
            }
          >
            <DocumentTitle>
              <BlTextContainer>
                <Typo typoType="h3">{t("common:blNo")}</Typo>
                {blNo && (
                  <BlueText
                    typoType="h4"
                    color="systemBlue2"
                    onClick={handleBlNoClick}
                  >
                    {blNo}
                  </BlueText>
                )}
              </BlTextContainer>
              {renderDocumentStatusBadge({
                t,
                type:
                  blStatus === "BL_DRAFT"
                    ? "DRAFT"
                    : blStatus === "BL_FINAL"
                      ? "FINAL"
                      : "BEFORE_REGISTRATION",
              })}
            </DocumentTitle>

            {renderDocumentEditButtons(
              "BL",
              blStatus === "BL_DRAFT"
                ? "TEMPORARY"
                : blStatus === "BL_FINAL"
                  ? "FINAL"
                  : null,
            )}
          </ShippingDocumentItem>
        </ShippingDocument>
        {(ciStatus !== null || plStatus !== null) && (
          <NoticeContainer>
            <StyledIcon iconSrc={InfoSvg} />
            <Typo typoType="b9r" color="gray6">
              {t("shipment:exporter.detail.description.shippingDocuments")}
            </Typo>
          </NoticeContainer>
        )}
      </SectionCard>

      {/* Cards */}
      <CardContainer>
        <DocumentsInternalCard
          data={internalSimpleDocumentMedias}
          isEditAuth={isEditAuth}
          isShipmentCompleted={isShipmentCompleted}
        />
        <DocumentsExternalCard
          data={externalSimpleDocumentMedias}
          isEditAuth={isEditAuth}
          isShipmentCompleted={isShipmentCompleted}
        />
        <AllFileCard
          isSuccess={isSuccess}
          internalScFile={internalScFileInfos}
          scAttachmentFile={scAttactedFileSimpleFileMedias}
          poFile={poFileSimpleFileMedias}
          lcFile={lcFileSimpleFileMedias}
          bookingFile={bookingFileSimpleFileMedias}
        />
        <MemoCard
          memoData={simpleShipmentMemos}
          isEditAuth={isEditAuth}
          isShipmentCompleted={isShipmentCompleted}
        />
      </CardContainer>

      {renderDialogs()}
    </DocumentMemoContainer>
  );
}

export default DocumentMemoCard;

const DocumentMemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CreateButton = styled(Button)`
  min-width: 144px;
  text-align: center;
`;

const NoticeContainer = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 6px;
  align-items: center;
`;

const ShippingDocument = styled.section`
  display: flex;
  gap: 24px;
`;

const DocumentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ShippingDocumentItem = styled.div<{ $gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 24px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  gap: ${({ $gap }) => `${$gap ?? 16}px`};
`;

const ShippingDocumentsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 24px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  gap: 16px;

  .divider {
    border-right: 1px solid ${colorSet.gray9};
    height: 110px;
  }
`;

const DocumentButtonContainer = styled.div`
  display: flex;
  gap: 8px;

  a {
    line-height: 0px;
  }
`;

const BlDocumentInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlDocumentName = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BlName = styled(Typo)`
  word-break: break-all;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const EditIcon = styled(EditSvg)<{ color: ColorType }>`
  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
  size?: number;
}>`
  width: ${({ size }) => (size ? `${size}px` : "20px")};
  height: ${({ size }) => (size ? `${size}px` : "20px")};

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 20px;
  height: 20px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const BlTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

const StatusBadgeContainer = styled.div`
  position: relative;
`;

const CheckNeededAlarm = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: ${colorSet.red2};
  border-radius: 100%;
  right: 0;
`;

const BlDocumentFileContainer = styled.div`
  display: flex;
  width: 358px;
  align-items: center;
  background-color: ${colorSet.gray12};
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
`;

const BlDocumentFileDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #eceff2;
`;

const BlDocumentFileInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
`;

const BlDocumentFileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const BlDocumentBottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
