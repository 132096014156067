import AddItemDialog, {
  AddItemType,
} from "@/components/organism/AddItemDialog";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import Filter from "@/src/components/molecule/Filter";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import EditItemDialog from "@/src/components/organism/EditItemDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import {
  useCreateBulkItemCodeMutation,
  useGetNonFilteredItemCodesQuery,
  useLazyGetItemCodeListQuery,
} from "@/src/store/apis/corporate/companyItemManagement";
import {
  ExporterItemCodeListViewV2Dto,
  GetItemManagementListRequest,
} from "@/src/store/apis/corporate/companyItemManagement/interface";
import { isUndefined } from "@/src/utils/is";
import { editAction } from "@/src/utils/row-data-util";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { GroupType } from "types";
import { columnItemList } from "./columns/columnItemList";

const Init: GetItemManagementListRequest = {
  item: "all",
  mainCategoryCodeItemName: "all",
  subCategoryCodeItemName: "all",
  isActivated: "all" as unknown as boolean,
};

const FAST_SEARCH_QUERY_KEY = [
  "mainCategoryCodeItemName",
  "subCategoryCodeItemName",
  "item",
  "isActivated",
];

const ACTIVATED_OPTION_LIST = [
  { value: "true", label: "Y" },
  { value: "false", label: "N" },
];

enum DialogState {
  NULL,
  ADD_ITEM,
  EDIT_ITEM,
}

const itemListEmptyArray: ExporterItemCodeListViewV2Dto[] = [];
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ExporterItemManagementPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();

  // API
  const { mainCategoryList } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined =
        isError || isFetching || currentData === undefined;

      const isManager =
        currentData?.row.exporterUserType === "MANAGER" ||
        currentData?.row.exporterUserType === "MIDDLE_MANAGER";

      return {
        mainCategoryList: !isErrorAndUndefined
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : mainCategoryEmptyArray,
      };
    },
  });
  const [createBulkItemCode] = useCreateBulkItemCodeMutation();

  const [getItemCodes, { isFetching, itemList, count, isError }] =
    useLazyGetItemCodeListQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isFetching,
          itemList: !isUnstable ? currentData?.rows : undefined,
          count: !isUnstable ? currentData?.count : 0,
          isError,
        };
      },
    });

  const { itemsList } = useGetNonFilteredItemCodesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const isStable = !isUnstable;

        return {
          itemsList: isStable ? currentData.rows : itemListEmptyArray,
          isItemsFetching: isFetching,
        };
      },
    },
  );

  const itemOptionFilterList = useMemo(
    () =>
      Array.from(
        new Map(
          itemsList
            ?.filter((item) =>
              (mainCategoryList as string[]).includes(
                item.mainCategoryCodeItemName,
              ),
            )
            .map((item) => [
              `${item.exporterItemId}_${item.item}`,
              {
                value: String(item.exporterItemId),
                label: item.item,
                mainCategory: item.mainCategory,
                mainCategoryCodeItemName: item.mainCategoryCodeItemName,
                subCategoryCodeItemName: item.subCategoryCodeItemName,
                subCategory: item.subCategory,
              },
            ]),
        ).values(),
      ),
    [itemsList, mainCategoryList],
  );

  const mainCategoryFilterList = useMemo(
    () =>
      Array.from(
        new Map(
          itemOptionFilterList.map((item) => [
            item.mainCategoryCodeItemName,
            {
              label: item.mainCategory,
              value: item.mainCategoryCodeItemName,
            },
          ]),
        ).values(),
      ),
    [itemOptionFilterList],
  );

  const itemOptionGroupList = useMemo(
    () =>
      itemOptionFilterList?.reduce((acc: GroupType[], item) => {
        const groupLabel = `${item.mainCategoryCodeItemName} > ${item.subCategory}`;
        let group = acc.find((g) => g.label === groupLabel);
        if (!group) {
          group = { label: groupLabel, options: [] };
          acc.push(group);
        }

        group.options.push({
          value: item.value,
          label: item.label,
        });

        return acc;
      }, []) || [],
    [itemOptionFilterList],
  );

  const subCategoryGroupList = useMemo(
    () =>
      itemOptionFilterList.reduce((acc: GroupType[], item) => {
        const groupLabel = item.mainCategoryCodeItemName;
        let group = acc.find((g) => g.label === groupLabel);

        if (!group) {
          group = { label: groupLabel, options: [] };
          acc.push(group);
        }

        // 중복된 서브카테고리 방지를 위한 체크
        const existingOption = group.options.find(
          (option) => option.value === item.subCategoryCodeItemName,
        );

        if (!existingOption) {
          group.options.push({
            value: item.subCategoryCodeItemName,
            label: item.subCategory,
          });
        }

        return acc;
      }, []),
    [itemOptionFilterList],
  );

  const allOptionItem = {
    label: t("itemManagement:exporter.list.placeholder.all"),
    value: "all",
  };

  // State
  const [columnDefs] = useState<ColDef[]>(columnItemList);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [selectItemCode, setSelectItemCode] =
    useState<ExporterItemCodeListViewV2Dto | null>(null);

  const handleEditClick = (row: ExporterItemCodeListViewV2Dto) => {
    setSelectItemCode(row);
    setDialogState(DialogState.EDIT_ITEM);
  };

  const handleAddItems = async (params: AddItemType) => {
    try {
      await createBulkItemCode(params).unwrap();
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });

      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.NULL) {
      return;
    }

    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open={dialogState === DialogState.ADD_ITEM}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleAddItems}
        />
      );
    }

    if (dialogState === DialogState.EDIT_ITEM && selectItemCode) {
      return (
        <EditItemDialog
          open={dialogState === DialogState.EDIT_ITEM}
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setSelectItemCode(null);
          }}
          selectItemCode={selectItemCode}
        />
      );
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "item",
        langKey: "itemManagement:exporter.list.table.item",
      },
      {
        columnKey: "itemCode",
        langKey: "itemManagement:exporter.list.table.itemCode",
      },
      {
        columnKey: "subCategory",
        langKey: "itemManagement:exporter.list.table.subCategory",
      },
      {
        columnKey: "mainCategory",
        langKey: "itemManagement:exporter.list.table.mainCategory",
      },
      {
        columnKey: "description",
        langKey: "itemManagement:exporter.list.table.description",
      },
      {
        columnKey: "isActivated",
        langKey: "itemManagement:exporter.list.table.isActivated",
      },
      {
        columnKey: "edit",
        langKey: "itemManagement:exporter.list.table.edit",
      },
    ],
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<
    GetItemManagementListRequest,
    GetItemManagementListRequest
  >({
    isReady,
    gridRef: gridRef.current,
    fetch: getItemCodes,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        subCategoryCodeItemName:
          data.subCategoryCodeItemName === "all"
            ? undefined
            : data?.subCategoryCodeItemName,
        itemCode: data?.itemCode || undefined,
        item:
          data.item === "all"
            ? undefined
            : itemOptionFilterList.find(({ value }) => value === data.item)
                ?.label,
        isActivated:
          (data?.isActivated as unknown as string) === "all"
            ? undefined
            : (data?.isActivated as any) === "true",
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        subCategoryCodeItemName: urlObject.subCategoryCodeItemName ?? "all",
        item:
          itemOptionFilterList.find(({ label }) => urlObject.item === label)
            ?.value ?? "all",
        itemCode: urlObject.itemCode,
        isActivated: (urlObject.isActivated
          ? urlObject.isActivated === "true"
            ? "true"
            : "false"
          : "all") as unknown as boolean,
      };
    },
  });

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:management"), t("sideNav:itemManagement")]}
      pageTitle={t("sideNav:itemManagement")}
    >
      <FlexColumn>
        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "mainCategoryCodeItemName",
              label: t("itemManagement:exporter.list.filter.mainCategory"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:exporter.list.placeholder.all"),
                allowClear: filterData.mainCategoryCodeItemName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("mainCategoryCodeItemName", "all");
                    onForceSearch("mainCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("mainCategoryCodeItemName", value);
                    onForceSearch("mainCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "subCategoryCodeItemName",
              label: t("itemManagement:exporter.list.filter.subCategory"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:exporter.list.placeholder.all"),
                allowClear: filterData.subCategoryCodeItemName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("subCategoryCodeItemName", "all");
                    onForceSearch("subCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("subCategoryCodeItemName", value);
                    onForceSearch("subCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "item",
              label: t("itemManagement:exporter.list.filter.item"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:exporter.list.placeholder.all"),
                allowClear: filterData.item !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("item", "all");
                    onForceSearch("item", "all");
                  } else {
                    onFilterDataChange("item", value);
                    onForceSearch("item", value);
                  }
                },
              },
            },
            {
              key: "itemCode",
              label: t("itemManagement:exporter.list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t(
                  "itemManagement:exporter.list.placeholder.itemCode",
                ),
              },
            },
            {
              key: "isActivated",
              label: t("itemManagement:exporter.list.filter.use"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:exporter.list.placeholder.all"),
                allowClear: (filterData.isActivated as any) !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("isActivated", "all");
                    onForceSearch("isActivated", "all");
                  } else {
                    onFilterDataChange("isActivated", value);
                    onForceSearch("isActivated", value);
                  }
                },
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
            {
              key: "subCategoryCodeItemName",
              option: [allOptionItem, ...(subCategoryGroupList as any[])],
            },
            {
              key: "isActivated",
              option: [allOptionItem, ...ACTIVATED_OPTION_LIST],
            },
            {
              key: "item",
              option: [allOptionItem, ...(itemOptionGroupList as any[])],
            },
          ]}
        />

        <div>
          <TableTitleContainer>
            <Typo color="gray4" typoType="b5m">
              {t("itemManagement:exporter.list.table.title")}
            </Typo>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                onClick={() => setDialogState(DialogState.ADD_ITEM)}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />

                {t("itemManagement:exporter.list.buttons.addItemButton")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>
          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : itemList}
              isPaginationDataMaping
              columnDefs={columnDefs}
              handlePaginationClick={(page, pageSize) => {
                onPaginationChange({ page, pageSize });
              }}
              totalPage={count}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onGridReady={() => setIsReady(true)}
              components={{
                ...editAction({
                  disabled: (data) => {
                    // 일반 매니저, 중간관리자 자신이 포함한 메인카테고리 영역만
                    return !mainCategoryList.includes(
                      data?.mainCategoryCodeItemName,
                    );
                  },
                  onClick: (row) => {
                    handleEditClick(
                      row as unknown as ExporterItemCodeListViewV2Dto,
                    );
                  },
                }),
              }}
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>

      {renderDialogs()}
    </ExporterMainLayout>
  );
};

export default ExporterItemManagementPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
