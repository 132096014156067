import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle.svg";
import EyeOffSvg from "@/src/assets/icons/icon-eye-off.svg";
import EyeSvg from "@/src/assets/icons/icon-eye.svg";
import MockPng from "@/src/assets/icons/side-mock.png";
import LogoDevSvg from "@/src/assets/logos/logo-export-dev.svg";
import LogoSvg from "@/src/assets/logos/logo-export.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import TextButton from "@/src/components/atom/Button/TextButton";
import Checkbox from "@/src/components/atom/Checkbox";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import IconInput from "@/src/components/atom/Input/IconInput";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Typo from "@/src/components/atom/Typo";
import PublicFooter from "@/src/components/molecule/PublicFooter";
import AutoLogoutAlertDialog from "@/src/components/organism/AutoLogoutAlertDialog";
import {
  LOCKED_ACCOUNT_CODE,
  SUBSCRIPTION_EMPTY_LIST_ERROR_CODE,
} from "@/src/constant/error";
import { localstorageKeySet } from "@/src/constant/localstorage";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import useRedirectNavigate from "@/src/hooks/useRedirectNavigate";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PRIVATE_PATH from "@/src/routes/private/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import {
  useEditSessionMutation,
  usePendingResetPasswordMutation,
  useSignInMutation,
} from "@/src/store/apis/auth";
import { ExporterUserDto } from "@/src/store/apis/auth/interface";
import { useGetPopupQuery } from "@/src/store/apis/common";
import { useLazyGetCompanyLinkagePendingListQuery } from "@/src/store/apis/link";
import { useGetOnBoardingImageQuery } from "@/src/store/apis/onBoarding";
import {
  useLazyGetCurrentSubscriptionQuery,
  useLazyGetEnterpriseInquiriesQuery,
  useLazyGetPlansQuery,
} from "@/src/store/apis/subscription";
import { setMfaUser, setTempUser, setUser } from "@/src/store/slice/auth";
import { openLinkageRequestAlertDialog } from "@/src/store/slice/webUtil";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isDevelopment } from "@/src/utils/environment";
import { emailRegex } from "@/src/utils/regex";
import { utcNow } from "@/src/utils/utcDayjs";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";

enum ALERT_MODAL_STATUS {
  NONE,
  PASSWORD_CHANGE_90DAYS_AFTER,
  LOCKED_ACCOUNT,
  AUTO_LOGOUT,
}

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { tempUser, token } = useAppSelector((state) => state.auth);

  const idInputRef = useRef<HTMLInputElement | null>(null);
  const mainRef = useRef<HTMLElement | null>(null);
  const redirectOrNavigate = useRedirectNavigate();
  const { handleContentLoadingOff } = useContentLoading();

  const isAutoLogoutDialogOpen = localStorage.getItem(
    localstorageKeySet.IS_SESSION_TIME_OUT
  );

  // API
  const [editMe, { isLoading: isEditUser }] = useEditSessionMutation();
  const [signIn, { isLoading: isSigningIn }] = useSignInMutation();
  const [resetPassword] = usePendingResetPasswordMutation();
  const { data, isFetching } = useGetOnBoardingImageQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useGetPopupQuery(undefined, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });
  const [getPendingLinkageList] = useLazyGetCompanyLinkagePendingListQuery();
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();
  const [getPlans] = useLazyGetPlansQuery();
  const [getEnterpriseInquiries] = useLazyGetEnterpriseInquiriesQuery();

  const [alertModalState, setAlertModalState] = useState<ALERT_MODAL_STATUS>(
    ALERT_MODAL_STATUS.NONE
  );
  const [isRememberId, setIsRememberId] = useState<boolean>(
    !!localStorage.getItem(localstorageKeySet.ECOYA_ID)
  );
  const [passwordInputType, setPasswordInputType] = useState<
    "password" | "text"
  >("password");

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      id: localStorage.getItem(localstorageKeySet.ECOYA_ID) ?? "",
      password: "",
    },
  });

  const redirectUserByType = async (res: ExporterUserDto) => {
    const companyType =
      res.exporterUserType === "CORPORATE_MANAGER"
        ? res.exporter.companyType
        : res.exporterUserMainFieldType;

    try {
      const subscription = await getCurrentSubscription().unwrap();
      await getPlans({ planType: "ENTERPRISE" });
      await getEnterpriseInquiries({
        page: 1,
        pageSize: 1,
      });

      dispatch(setUser(res));
      dispatch(setTempUser(null));

      switch (companyType) {
        case "BUYER":
          return redirectOrNavigate(IMPORTER_PRIVATE_PATH.HOME);
        case "SELLER":
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
        case "BOTH":
          if (subscription.subscriptionStatus === "CANCELED") {
            return redirectOrNavigate(
              EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING
            );
          }
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
        default:
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
      }
    } catch (e: any) {
      // 구독 내역이 없는 경우, 수출자, BOTH
      if (e.data.errorCode === SUBSCRIPTION_EMPTY_LIST_ERROR_CODE) {
        return redirectOrNavigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING);
      }

      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  const handleLanguageChange = (lang: "en" | "ko") => {
    i18n.changeLanguage(lang);
  };

  const goTo = ({
    path,
    option,
  }: {
    path: keyof typeof PUBLIC_PATH | keyof typeof PRIVATE_PATH;
    option?: NavigateOptions;
  }) => {
    navigate({ ...PRIVATE_PATH, ...PUBLIC_PATH }[path], option);
  };

  const handleLoginClick = async () => {
    const watchId = getValues("id");
    const password = getValues("password");

    try {
      const { statusCode, row } = await signIn({
        aId: watchId,
        password,
      }).unwrap();

      if (isRememberId) {
        localStorage.setItem(localstorageKeySet.ECOYA_ID, watchId);
      } else {
        localStorage.setItem(localstorageKeySet.ECOYA_ID, "");
      }

      const { data } = await getPendingLinkageList();

      if (data && data.count > 0) {
        dispatch(openLinkageRequestAlertDialog());
      }

      // 이중인증 ON
      if (statusCode === 202) {
        dispatch(setMfaUser(row));
        return goTo({
          path: "TWO_FACTOR_AUTHENTICATION",
        });
      }

      // 이중인증 OFF (3 Type)
      // 1. 임시 비밀번호 발급상태 (로그인 5회 싪패, 첫 로그인)
      // 2. 90일 이후 비밀번호 변경 Alert
      // 3. 위 2가지 타입 외 대시보드 이동
      const { row: user } = await editMe({
        language: i18n.language,
      }).unwrap();

      if (statusCode === 201) {
        const newestChangedDate = dayjs
          .utc(row?.account.passwordChangePendedAt)
          .isAfter(row.account.passwordChangedAt)
          ? row?.account.passwordChangePendedAt
          : row?.account.passwordChangedAt;

        const isSafePassword = dayjs
          .utc(newestChangedDate)
          .add(90, "day")
          .isAfter(utcNow());

        // 1. 임시 비밀번호 발급상태 (로그인 5회 싪패, 첫 로그인)
        if (row.account.isTemporaryPassword) {
          dispatch(setTempUser({ ...user, language: i18n.language }));
          return goTo({
            path: "CHANGE_PASSWORD",
          });
        }

        // 3. 위 2가지 타입 외 대시보드 이동
        // 현재 Public Router 단에서 user가 있을시 대시보드 이동로직이 있음
        // 3-1 수입자
        // 3-2 수출자
        // 3-3 BOTH
        // 3-4 기업관리자, 중간관리자, 일반매니저
        if (isSafePassword) {
          redirectUserByType({ ...user, language: i18n.language });
        } else {
          // 2. 90일 이후 비밀번호 변경 Alert
          dispatch(setTempUser(user));
          setAlertModalState(ALERT_MODAL_STATUS.PASSWORD_CHANGE_90DAYS_AFTER);
        }
      }
    } catch (e: any) {
      if (e.data?.errorCode === LOCKED_ACCOUNT_CODE) {
        return setAlertModalState(ALERT_MODAL_STATUS.LOCKED_ACCOUNT);
      }

      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  const handlePasswordChangePending = async () => {
    if (!tempUser) return;
    try {
      await resetPassword().unwrap();
      dispatch(setUser(tempUser));
      dispatch(setTempUser(null));
    } catch (e: any) {
      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    } finally {
      goTo({ path: "ROOT", option: { replace: true } });
    }
  };

  const handleAutoLogoutClose = () => {
    setAlertModalState(ALERT_MODAL_STATUS.NONE);
    localStorage.removeItem(localstorageKeySet.IS_SESSION_TIME_OUT);
  };

  const renderAlertDialog = () => {
    // 90일 이후 비밀번호 변경 Alert
    if (alertModalState === ALERT_MODAL_STATUS.PASSWORD_CHANGE_90DAYS_AFTER) {
      return (
        <AlertDialog
          title={t("common:changingPasswordAlertTitle")}
          description={
            <AlertDialogDescription>
              {t("common:changingPasswordAlertDescription")}
            </AlertDialogDescription>
          }
          onOk={() => {
            goTo({ path: "CHANGE_PASSWORD" });
          }}
          onCancel={handlePasswordChangePending}
          cancelText={t("common:changingPasswordAlertCancelText")}
          okText={t("common:changingPasswordAlertOkText")}
          open
          onOpenChange={(open) => {
            if (!open) {
              setAlertModalState(ALERT_MODAL_STATUS.NONE);
            }
          }}
        />
      );
    }
    // 계정 잠금 알림창
    if (alertModalState === ALERT_MODAL_STATUS.LOCKED_ACCOUNT) {
      return (
        <AlertDialog
          title={t("common:lockedAccountAlertTitle")}
          description={
            <AlertDialogDescription>
              {t("common:lockedAccountAlertDescription")}
            </AlertDialogDescription>
          }
          onOk={() => {
            goTo({ path: "FIND_PASSWORD" });
          }}
          onCancel={() => {
            setAlertModalState(ALERT_MODAL_STATUS.NONE);
          }}
          cancelText={t("common:lockedAccountCancelText")}
          okText={t("common:lockedAccountOkText")}
          open
          onOpenChange={(open) => {
            if (!open) {
              setAlertModalState(ALERT_MODAL_STATUS.NONE);
            }
          }}
        />
      );
    }
    // 자동 로그아웃 알림창
    if (alertModalState === ALERT_MODAL_STATUS.AUTO_LOGOUT) {
      return (
        <AutoLogoutAlertDialog
          open
          onOpenChange={() => handleAutoLogoutClose()}
          onClose={handleAutoLogoutClose}
        />
      );
    }
  };

  useEffect(() => idInputRef.current?.focus(), []);
  useEffect(() => {
    dispatch(setTempUser(null));
  }, [dispatch]);

  useEffect(() => {
    if (isAutoLogoutDialogOpen) {
      setAlertModalState(ALERT_MODAL_STATUS.AUTO_LOGOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleContentLoadingOff();
  }, [handleContentLoadingOff]);

  return (
    <Layout ref={mainRef}>
      <LoginSection>
        <FormSection>
          <FormContainer>
            <Select
              style={{ width: "200px" }}
              showSearch={false}
              value={i18n.language}
              onChange={handleLanguageChange}
              options={[
                { label: t("common:english.en"), value: "en" },
                { label: t("common:korean.ko"), value: "ko" },
                { label: t("common:japanese.ja"), value: "ja" },
                { label: t("common:chinese.zh-TW"), value: "zh-TW" },
                { label: t("common:chinese.zh-CN"), value: "zh-CN" },
              ]}
            />
            <Logo
              logoSrc={isDevelopment ? LogoDevSvg : LogoSvg}
              isDevelopment={isDevelopment}
            />

            <StyledForm onSubmit={handleSubmit(handleLoginClick)} noValidate>
              <FormField isError={!!errors.id}>
                <Typo typoType="b7r" as="label">
                  {t("common:id")}
                </Typo>
                <Controller
                  name="id"
                  control={control}
                  rules={{
                    required: true,
                    pattern: emailRegex,
                  }}
                  render={({ field }) => {
                    return (
                      <StyledInput
                        {...field}
                        required
                        type="text"
                        placeholder={t("common:placeholder.id")}
                        name="id"
                        ref={idInputRef}
                        onClear={() => setValue("id", "")}
                        data-invalid={!!errors.id}
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          input.value = input.value.replace(
                            /[\u3131-\u318E\uAC00-\uD7A3]/g,
                            ""
                          );
                        }}
                      />
                    );
                  }}
                />

                {errors.id?.type === "required" && (
                  <FormErrorMessage>
                    <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                    {t("error:login.emailValueMissing")}
                  </FormErrorMessage>
                )}
                {errors.id?.type === "pattern" && (
                  <FormErrorMessage>
                    <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                    {t("error:login.emailTypeMissMatching")}
                  </FormErrorMessage>
                )}
              </FormField>

              <FormField isError={!!errors.password}>
                <Typo typoType="b7r" as="label">
                  {t("common:password")}
                </Typo>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <StyledIconInput
                        {...field}
                        name="password"
                        type={passwordInputType}
                        icon={
                          <StyledIcon
                            iconSrc={
                              passwordInputType === "password"
                                ? EyeOffSvg
                                : EyeSvg
                            }
                            iconSize={20}
                            onClick={() =>
                              setPasswordInputType((type) =>
                                type === "password" ? "text" : "password"
                              )
                            }
                          />
                        }
                        onClear={() => setValue("password", "")}
                        placeholder={t("common:placeholder.password")}
                      />
                    );
                  }}
                />

                {errors.password?.type === "required" && (
                  <FormErrorMessage>
                    <Icon iconSrc={ErrorCircleSvg} iconSize={16} />
                    {t("error:login.passwordValueMissing")}
                  </FormErrorMessage>
                )}
              </FormField>

              <MiddleDiv>
                <StyledLabel htmlFor="rememberId">
                  <Checkbox
                    checked={isRememberId}
                    id="rememberId"
                    onChange={() => setIsRememberId(!isRememberId)}
                  />
                  <Typo typoType="b9r">{t("common:rememberId")}</Typo>
                </StyledLabel>
                <TextButton
                  role="button"
                  onClick={() => goTo({ path: "FIND_PASSWORD" })}
                >
                  <Typo typoType="b9r" color="systemBlue2">
                    {t("common:findPassword")}
                  </Typo>
                </TextButton>
              </MiddleDiv>

              <LoginButton
                type="submit"
                isLoading={isSigningIn || isEditUser}
                disabled={isSigningIn || isEditUser}
              >
                <Typo typoType="btn3m" color="white">
                  {t("common:login")}
                </Typo>
              </LoginButton>
            </StyledForm>
            <SignUpRoadSection>
              <Typo typoType="b9m">{t("common:dontHaveAnAccount")}</Typo>

              <TextButton
                role="button"
                onClick={() => goTo({ path: "SIGNUP" })}
              >
                <Typo typoType="b9m" color="systemBlue2">
                  {t("common:signUp")}
                </Typo>
              </TextButton>
            </SignUpRoadSection>
          </FormContainer>
        </FormSection>

        <PublicFooter />
      </LoginSection>

      {isFetching ? (
        <LoadingSection>
          <Loader size={50} />
        </LoadingSection>
      ) : (
        <ImageSection
          imageUrl={data?.loginHomeMedia?.mediaUrl ?? MockPng}
          style={data?.link ? { cursor: "pointer" } : undefined}
          onClick={() => {
            if (data?.link) {
              window.open(data.link, "_blank");
            }
          }}
        >
          <div />
        </ImageSection>
      )}
      {renderAlertDialog()}
    </Layout>
  );
};

export default LoginPage;

const Layout = styled.main`
  min-width: 1250px;
  display: flex;
  min-height: 100vh;
`;

const Section = styled.section`
  flex: 1;
`;

const LoginSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 18.75% 0px 18.75%;
  flex: 1;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 482px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const Logo = styled.span<{ logoSrc: string; isDevelopment: boolean }>`
  width: ${({ isDevelopment }) => (isDevelopment ? "197px" : "156px")};
  height: 24px;
  display: inline-block;
  background: ${({ logoSrc }) => `url(${logoSrc}) center center no-repeat`};
  background-size: contain;
`;

const StyledInput = styled(Input)``;
const StyledIconInput = styled(IconInput)``;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormField = styled.div<{ isError: boolean }>`
  ${({ isError }) => isError && css``};
  display: flex;
  flex-direction: column;
  gap: 8px;

  .icon-input-container {
    ${({ isError }) =>
      isError &&
      css`
        border: 1px solid ${colorSet.red2};
      `};
  }
`;

const FormErrorMessage = styled.span`
  ${typo.b9r};
  color: ${colorSet.red2};
  padding-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const LoginButton = styled(Button)`
  text-align: center;
  margin-top: 16px;
`;

const MiddleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImageSection = styled(Section)<{ imageUrl: string }>`
  padding: 16px;

  div {
    width: 100%;
    height: 100%;
    background: url(${({ imageUrl }) => imageUrl}) no-repeat center/cover;
    border-radius: 24px;
  }
`;

const LoadingSection = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignUpRoadSection = styled.section`
  padding-top: 16px;
  display: flex;
  gap: 14px;
  justify-content: center;
`;

const AlertDialogDescription = styled.p`
  white-space: pre-wrap;
`;
