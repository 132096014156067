import AlertDialog from "@/src/components/atom/AlertDialog";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

interface CancelTaskAlertDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onOk: () => void;
}

function CancelTaskAlertDialog({
  open,
  onOpenChange,
  onOk,
}: CancelTaskAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      title={t("task:detail.alert.cancelTitle")}
      onOpenChange={onOpenChange}
      onOk={onOk}
      okText={t("task:detail.button.okButton")}
      cancelText={t("task:detail.button.cancelButton")}
    >
      <Description>{t("task:detail.alert.cancelDescription")}</Description>
    </AlertDialog>
  );
}

export default CancelTaskAlertDialog;

const Description = styled.div`
  white-space: pre-wrap;
`;
