import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import ResetSvg from "@/src/assets/icons/icon-reset-circle.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import Filter from "@/src/components/molecule/Filter";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAgGirdSetColumn from "@/src/hooks/useAgGridSetColumn";
import useAlert from "@/src/hooks/useAlert";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppSelector } from "@/src/store";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import { useGetFactoriesQuery } from "@/src/store/apis/client/factory";
import { WorkplaceListViewDto } from "@/src/store/apis/client/factory/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useGetCurrentSubscriptionQuery } from "@/src/store/apis/subscription";
import {
  useLazyGetTaskCategoryFilterListQuery,
  useLazyGetTasksExcelListQuery,
} from "@/src/store/apis/tasks/taskList";
import {
  GetTaskListFilterRequest,
  GetTaskListRequest,
} from "@/src/store/apis/tasks/taskList/interface";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isUndefined } from "@/src/utils/is";
import { gridColIdLocalStorage } from "@/src/utils/localStorageFixedColId";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";
import { columnTaskList } from "./columns/columnTaskList";

const Init: GetTaskListFilterRequest = {
  taskStatus: "all",
  mainCategoryCodeItemName: "all",
  itemCode: undefined,
  buyerNameCode: "all",
  workplaceName: "all",
  etdAt: undefined,
  workingDayAt: undefined,
  taskCode: undefined,
  scNo: undefined,
  taskNo: undefined,
};

const FAST_SEARCH_QUERY_KEY: string[] = [
  "taskStatus",
  "workingDayAt",
  "mainCategoryCodeItemName",
  "buyerNameCode",
  "etdAt",
  "workplaceName",
];

const workplaceEmptyArray: WorkplaceListViewDto[] = [];
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];
const buyerEmptyObject: {
  data: BuyerListViewDto[];
  count: number;
} = { data: [], count: 0 };

const TaskPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const subscription = useAppSelector((state) => state.subscription);

  // API
  useGetCurrentSubscriptionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [getExcel] = useLazyGetTasksExcelListQuery();
  const { mainCategoryList, exporterUserMainFieldType } = useGetSessionQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isErrorAndUndefined =
          isError || isFetching || currentData === undefined;

        const isManager =
          currentData?.row.exporterUserType === "MANAGER" ||
          currentData?.row.exporterUserType === "MIDDLE_MANAGER";

        return {
          exporterUserMainFieldType: currentData?.row.exporterUserMainFieldType,
          mainCategoryList: !isErrorAndUndefined
            ? isManager
              ? currentData.row.mainCategoryCodeItemNames
              : currentData.row.exporter.mainCategoryCodeItemNames
            : mainCategoryEmptyArray,
        };
      },
    },
  );
  const [
    getLoadingList,
    { originalArgs, isFetching, count, loadingList, isError },
  ] = useLazyGetTaskCategoryFilterListQuery({
    selectFromResult: ({ currentData, isError, isFetching, originalArgs }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isFetching,
        originalArgs,
        count: !isUnstable ? currentData.count : 0,
        loadingList: !isUnstable ? currentData.rows : undefined,
        isError,
      };
    },
  });
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategoryCurrentData: !isUnstable
            ? (currentData ?? mainCategoryEmptyArray)
            : mainCategoryEmptyArray,
        };
      },
    },
  );
  const { buyerCurrentData } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          buyerCurrentData: !isUnstable ? currentData : buyerEmptyObject,
        };
      },
    },
  );
  const { factoriesCurrentData } = useGetFactoriesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          factoriesCurrentData: !isUnstable
            ? (currentData.rows ?? workplaceEmptyArray)
            : workplaceEmptyArray,
        };
      },
    },
  );

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const buyerOptionList = buyerCurrentData.data?.map((item) => {
    return { label: item.buyerNameCode, value: String(item.id) };
  });
  const factoriesOptionList = factoriesCurrentData.map((item) => {
    return {
      label: item.workplaceName,
      value: String(item.workplaceListQueryResultDto.id),
    };
  });

  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      (mainCategoryList as string[]).includes(item.codeItemName),
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  const allOptionItem = {
    label: t("task:list.placeholder.all"),
    value: "all",
  };

  // State
  const [isReady, setIsReady] = useState<boolean>(false);
  const {
    func: { handleColumnMoved, handleColumnReset },
    state: { col },
  } = useAgGirdSetColumn({
    key: gridColIdLocalStorage.task,
    columns: columnTaskList,
  });

  const handleExcelDownload = async () => {
    try {
      if (originalArgs) {
        await getExcel(originalArgs).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "isBookmarked",
        langKey: "task:list.table.isBookmarked",
      },
      {
        columnKey: "taskStatus",
        langKey: "task:list.table.loadingStatus",
      },
      {
        columnKey: "taskNo",
        langKey: "task:list.table.loadingNo",
      },
      {
        columnKey: "workingDayAt",
        langKey: "task:list.table.workingDayAt",
      },
      {
        columnKey: "workplaceName",
        langKey: "task:list.table.workplaceName",
      },
      {
        columnKey: "taskCode",
        langKey: "task:list.table.loadingCode",
      },
      {
        columnKey: "mainCategoryCodeItemName",
        langKey: "task:list.table.mainCategoryCodeItemName",
      },
      {
        columnKey: "itemCode",
        langKey: "task:list.table.itemCode",
      },
      {
        columnKey: "numberOfTaskContainer",
        langKey: "task:list.table.numberOfLoadingContainer",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "task:list.table.buyerNameCode",
      },
      {
        columnKey: "docCutOffAt",
        langKey: "task:list.table.docCutOffAt",
      },
      {
        columnKey: "cargoCutOffAt",
        langKey: "task:list.table.cargoCutOffAt",
      },
      {
        columnKey: "etdAt",
        langKey: "task:list.table.etdAt",
      },
      {
        columnKey: "portOfLoading",
        langKey: "task:list.table.portOfLoading",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "task:list.table.placeOfDelivery",
      },
      {
        columnKey: "scNo",
        langKey: "task:list.table.scNo",
      },
      {
        columnKey: "bookingNo",
        langKey: "task:list.table.bookingNo",
      },
    ],
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetTaskListRequest, GetTaskListFilterRequest>({
    isReady,
    gridRef: gridRef.current,
    fetch: getLoadingList,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        taskStatus: data.taskStatus === "all" ? undefined : data?.taskStatus,
        scNo: data?.scNo || undefined,
        taskCode: data?.taskCode || undefined,
        taskNo: data?.taskNo,
        workplaceName:
          data.workplaceName === "all"
            ? undefined
            : factoriesOptionList.find(
                ({ value }) => value === data.workplaceName,
              )?.label,
        buyerNameCode:
          data.buyerNameCode === "all"
            ? undefined
            : buyerOptionList.find(({ value }) => value === data.buyerNameCode)
                ?.label,
        itemCode: data?.itemCode || undefined,
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        etdAt: undefined,
        etdAtFrom: data.etdAt?.[0]
          ? dayjs(data.etdAt?.[0]).startOf("day").toISOString()
          : undefined,
        etdAtTo: data.etdAt?.[1]
          ? dayjs(data.etdAt?.[1]).endOf("day").toISOString()
          : undefined,
        workingDayAt: undefined,
        workingDayAtFrom: data.workingDayAt?.[0]
          ? dayjs(data.workingDayAt?.[0]).startOf("day").toISOString()
          : undefined,
        workingDayAtTo: data.workingDayAt?.[1]
          ? dayjs(data.workingDayAt?.[1]).endOf("day").toISOString()
          : undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        taskStatus: urlObject.taskStatus ?? "all",
        buyerNameCode:
          buyerOptionList.find(({ label }) => label === urlObject.buyerNameCode)
            ?.value ?? "all",
        workplaceName:
          factoriesOptionList.find(
            ({ label }) => label === urlObject.workplaceName,
          )?.value ?? "all",
        itemCode: urlObject.itemCode,
        taskCode: urlObject.taskCode,
        scNo: urlObject.scNo,
        taskNo: urlObject.taskNo,
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        etdAt:
          urlObject.etdAtFrom && urlObject.etdAtTo
            ? [dayjs(urlObject.etdAtFrom), dayjs(urlObject.etdAtTo)]
            : undefined,
        etdAtFrom: urlObject.etdAtFrom,
        etdAtTo: urlObject.etdAtTo,
        workingDayAt:
          urlObject.workingDayAtFrom && urlObject.workingDayAtTo
            ? [
                dayjs(urlObject.workingDayAtFrom),
                dayjs(urlObject.workingDayAtTo),
              ]
            : undefined,
        workingDayAtFrom: urlObject.workingDayAtFrom,
        workingDayAtTo: urlObject.workingDayAtTo,
      };
    },
  });

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:loading")]}
      pageTitle={t("sideNav:loadingManagement")}
    >
      <FlexColumn>
        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "taskStatus",
              label: t("task:list.filter.loadingStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("task:list.placeholder.all"),
                allowClear: filterData.taskStatus !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("taskStatus", "all");
                    onForceSearch("taskStatus", "all");
                  } else {
                    onFilterDataChange("taskStatus", value);
                    onForceSearch("taskStatus", value);
                  }
                },
              },
            },
            {
              key: "workingDayAt",
              label: t("task:list.filter.workingDay"),
              type: "dateRangePicker",
            },
            {
              key: "workplaceName",
              label: t("task:list.filter.workplaceName"),
              type: "searchSelect",
              props: {
                placeholder: t("task:list.placeholder.all"),
                allowClear: filterData.workplaceName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("workplaceName", "all");
                    onForceSearch("workplaceName", "all");
                  } else {
                    onFilterDataChange("workplaceName", value);
                    onForceSearch("workplaceName", value);
                  }
                },
              },
            },
            {
              key: "mainCategoryCodeItemName",
              label: t("task:list.filter.mainCategoryCodeItemName"),
              type: "searchSelect",
              props: {
                placeholder: t("task:list.placeholder.all"),
                allowClear: filterData.mainCategoryCodeItemName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("mainCategoryCodeItemName", "all");
                    onForceSearch("mainCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("mainCategoryCodeItemName", value);
                    onForceSearch("mainCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "itemCode",
              label: t("task:list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t("task:list.placeholder.itemCode"),
              },
            },
            {
              key: "buyerNameCode",
              label: t("task:list.filter.buyerNameCode"),
              type: "searchSelect",
              props: {
                placeholder: t("task:list.placeholder.all"),
                allowClear: filterData.buyerNameCode !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("buyerNameCode", "all");
                    onForceSearch("buyerNameCode", "all");
                  } else {
                    onFilterDataChange("buyerNameCode", value);
                    onForceSearch("buyerNameCode", value);
                  }
                },
              },
            },
            {
              key: "etdAt",
              label: t("task:list.filter.etd"),
              type: "dateRangePicker",
            },
            {
              key: "scNo",
              label: t("task:list.filter.scNo"),
              type: "input",
              props: {
                placeholder: t("task:list.placeholder.scNo"),
              },
            },
            {
              key: "taskCode",
              label: t("task:list.filter.loadingCode"),
              type: "input",
              props: {
                placeholder: t("task:list.placeholder.loadingCode"),
              },
            },
            {
              key: "taskNo",
              label: t("task:list.filter.loadingNo"),
              type: "input",
              props: {
                placeholder: t("task:list.placeholder.loadingNo"),
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "taskStatus",
              option: [
                allOptionItem,
                {
                  label: t("task:list.badge.loadingRequest"),
                  value: "TASK_REQUEST",
                },
                {
                  label: t("task:list.badge.loadingScheduled"),
                  value: "TASK_SCHEDULED",
                },
                {
                  label: t("task:list.badge.working"),
                  value: "WORKING",
                },
                {
                  label: t("task:list.badge.photoSubmitted"),
                  value: "PHOTO_SUBMISSION",
                },
                {
                  label: t("task:list.badge.fieldWorkCompleted"),
                  value: "CONTAINER_COMPLETED",
                },
                {
                  label: t("task:list.badge.loadingCompleted"),
                  value: "TASK_COMPLETED",
                },
                {
                  label: t("task:list.badge.cancelLoadingRequest"),
                  value: "CANCEL_TASK_REQUEST",
                },
              ],
            },
            {
              key: "buyerNameCode",
              option: [allOptionItem, ...buyerOptionList],
            },
            {
              key: "workplaceName",
              option: [allOptionItem, ...factoriesOptionList],
            },
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
          ]}
        />

        <div>
          <TableTitleContainer>
            <FlexAlignCenter gap={12}>
              <Typo color="gray4" typoType="b5m">
                {t("task:list.table.title")}
              </Typo>
              <FlexAlignCenter>
                <CopiedBadge size="xs">C</CopiedBadge>
                <Typo typoType="b9r" color="gray6">
                  {t("common:combinedSplitedLoading")}
                </Typo>
              </FlexAlignCenter>
            </FlexAlignCenter>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleExcelDownload}
              >
                <Icon iconSrc={ExcelSvg} iconSize={16} />
                {t("task:list.table.excel")}
              </StyledButton>
              <StyledButton
                buttonSize={40}
                onClick={() => navigate(EXPORTER_PRIVATE_PATH.LOADING_ADD)}
                disabled={isCreateDisabled}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />
                {t("task:list.table.addButton")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>
          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : loadingList}
              isPaginationDataMaping
              columnDefs={col}
              handlePaginationClick={(page, pageSize) =>
                onPaginationChange({ page, pageSize })
              }
              totalPage={count}
              onRowClicked={({ data }) => {
                navigate(`${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${data.id}`);
              }}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onColumnMoved={handleColumnMoved}
              onGridReady={() => setIsReady(true)}
              paginationRightAccessory={
                <StyledButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleColumnReset}
                  style={{ marginLeft: "8px" }}
                >
                  <Icon iconSrc={ResetSvg} iconSize={16} />
                  <Typo typoType="btn4m">{t("common:columnReset")}</Typo>
                </StyledButton>
              }
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
    </ExporterMainLayout>
  );
};

export default TaskPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CopiedBadge = styled.span<{ size: "xs" | "s" }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ size }) =>
    size === "xs"
      ? css`
          width: 14px;
          height: 14px;
          ${typo.b11m}
          line-height: 15px;
        `
      : css`
          width: 20px;
          height: 20px;
          ${typo.btn5m}
        `};

  color: ${colorSet.white};
  background: ${colorSet.systemLime1};
  border-radius: 2px;
  flex-shrink: 0;
  margin-right: 4px;
`;

const FlexDiv = styled.div<{ gap?: number; direction?: "column" | "row" }>`
  display: flex;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `};
`;

const FlexAlignCenter = styled(FlexDiv)`
  align-items: center;
`;
