import React, { useEffect, useState } from "react";
import Dialog from "../../atom/Dialog";
import { useTranslation } from "react-i18next";
import DialogFooterContainer from "../../atom/Dialog/DialogFooterContainer";
import { Button } from "../../atom/Button";
import Typo from "../../atom/Typo";
import { companyApi } from "@/src/store/apis/corporate/company";
import { isUndefined } from "@/src/utils/is";
import styled from "styled-components";
import Radio from "@/src/components/atom/Radio";
import Loader from "../../atom/Loader";
import colorSet from "@/src/styles/color";

interface SignatureSelectDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onSave?: (selectedSignatureId: number) => void;
  isLoading?: boolean;
}

const SignatureSelectDialog = ({
  open,
  onOpenChange,
  isLoading,
  onSave,
}: SignatureSelectDialogProps) => {
  const { t } = useTranslation();
  const [selectedSignatureId, setSelectedSignatureId] = useState<number | null>(
    null,
  );

  const { simpleExporterSignatureMedias } =
    companyApi.endpoints.getExporters.useQueryState(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isUndefined(currentData) || isFetching;
        const isStable = !isUnstable;

        return {
          simpleExporterSignatureMedias: isStable
            ? currentData.signatureManagement.simpleExporterSignatureMedias
            : [],
        };
      },
    });

  useEffect(() => {
    const defaultSignature = simpleExporterSignatureMedias.find(
      (item) => item.isDefault,
    );
    if (defaultSignature) {
      setSelectedSignatureId(defaultSignature.signatureSimpleMedia.id);
    }
  }, [simpleExporterSignatureMedias]);

  return (
    <Dialog
      title={t("common:signatureSelect")}
      open={open}
      width={624}
      onOpenChange={onOpenChange}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            <Typo typoType="btn3m">{t("common:cancel")}</Typo>
          </Button>
          <Button
            isLoading={isLoading}
            onClick={() => onSave?.(selectedSignatureId as number)}
          >
            <Typo typoType="btn3m" color="white">
              {t("common:select")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <Content>
        {simpleExporterSignatureMedias.map((item, index) => {
          if (isLoading)
            return (
              <FlexCenter key={index.toString()}>
                <Loader />
              </FlexCenter>
            );
          return (
            <SingleImageContainer key={index.toString()}>
              <SignatureImage
                src={item.signatureSimpleMedia.mediaUrl}
                onClick={() =>
                  setSelectedSignatureId(item.signatureSimpleMedia.id)
                }
              />
              <RadioRow>
                <Radio
                  disabled={!item}
                  checked={selectedSignatureId === item.signatureSimpleMedia.id}
                  onClick={() =>
                    setSelectedSignatureId(item.signatureSimpleMedia.id)
                  }
                >
                  <Typo typoType="b7r" color={item ? "gray2" : "gray7"}>
                    {t(
                      "companyManagement:exporter.companyInfo.signatureManagement.signatureItem",
                    )}{" "}
                    {index + 1}
                  </Typo>
                </Radio>
              </RadioRow>
            </SingleImageContainer>
          );
        })}
      </Content>
    </Dialog>
  );
};

export default SignatureSelectDialog;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const RadioRow = styled.div`
  text-align: center;
  padding-top: 8px;
`;

const SingleImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;
`;

const SignatureImage = styled.img`
  width: 280px;
  height: 128px;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};
  cursor: pointer;
`;
