import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-indigo.svg";
import { ReactComponent as PencelSvg } from "@/src/assets/icons/icon-edit-pencel.svg";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { salesApi } from "@/src/store/apis/sales";
import colorSet from "@/src/styles/color";
import { downloadFile } from "@/src/utils/downloadFile";
import { isNull, isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

interface SalesDetailInformationProps {
  isEditAuth: boolean;
}

function SalesDetailInformation({ isEditAuth }: SalesDetailInformationProps) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const {
    orderDateAt,
    warehouseEmployee,
    item,
    itemCode,
    warehouseName,
    unitPrice,
    salesFinalProducts,
    amount,
    salesMedia,
  } = salesApi.endpoints.getSale.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          amount: isStable
            ? `${(
                Number(currentData.quantity) * Number(currentData.unitPrice)
              ).toLocaleString("ko-KR")} ${currentData.unitPriceUnit}`
            : "-",
          unitPrice:
            isStable && currentData.unitPrice && currentData.unitPriceUnit
              ? `${Number(currentData.unitPrice).toLocaleString("ko-KR")} ${
                  currentData.unitPriceUnit
                }`
              : "-",
          warehouseName: isStable ? currentData.warehouse.warehouseName : "-",
          itemCode: isStable
            ? currentData.exporterItemCode?.itemCode || "-"
            : "-",
          item: isStable ? currentData.exporterItem?.item || "-" : "-",
          salesFinalProducts: isStable ? currentData.salesFinalProducts : [],
          orderDateAt: isStable
            ? dayjs(currentData.orderDateAt).format(
                DATE_FORMAT_STRINGS.YYYY_MM_DD,
              )
            : "-",
          warehouseEmployee: isStable
            ? currentData.warehouseEmployee.name
            : "-",
          salesMedia: currentData?.salesMedia,
        };
      },
    },
  );

  const renderCard = () => {
    return (
      <GridContainer>
        <Flex flexDirection="column" gap={8} alignItems="center">
          <Typo color="gray6" typoType="b9m">
            {t("sales:detail.warehouse")}
          </Typo>
          <PaymentTermsContainer>
            <BreakWordTypo color="blue2" typoType="h4">
              {warehouseName}
            </BreakWordTypo>
          </PaymentTermsContainer>
        </Flex>
        <Flex flexDirection="column" gap={8} alignItems="center">
          <Typo color="gray6" typoType="b9m">
            {t("sales:detail.itemCode")}
          </Typo>
          <Typo color="blue2" typoType="h4">
            {itemCode}
          </Typo>
        </Flex>

        <Divider />

        <Flex flexDirection="column" gap={8} alignItems="center">
          <Typo color="gray6" typoType="b9m">
            {t("sales:detail.unitPrice")}
          </Typo>
          <Typo color="blue2" typoType="h4">
            {unitPrice}
          </Typo>
        </Flex>
        <Flex flexDirection="column" gap={8} alignItems="center">
          <Typo color="gray6" typoType="b9m">
            {t("sales:detail.finalProduct")}
          </Typo>
          <Typo color="blue2" typoType="h4">
            {salesFinalProducts
              ? salesFinalProducts
                  .map((item) => item.finalProduct.name)
                  .join(", ")
              : "-"}
          </Typo>
        </Flex>
      </GridContainer>
    );
  };

  const renderRows = () => {
    return (
      <Flex flexDirection="column" gap={24}>
        <ContractInformationRow>
          <Typo as="p" typoType="b9m" color="gray7">
            {t("sales:detail.basicInformation")}
          </Typo>
          <section>
            <SectionCardRow
              label={t("sales:detail.WarehouseContact")}
              value={warehouseEmployee}
            />
            <SectionCardRow label={t("common:orderDate")} value={orderDateAt} />
            <SectionCardRow
              label={t("sales:detail.file")}
              value={
                <FileInfoContainer>
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {salesMedia?.originalFileName ?? "-"}
                  </BreakWordTypo>
                  <div>
                    <IconButton
                      buttonSize={24}
                      buttonColor="blue"
                      buttonGrade="secondary"
                      disabled={isNull(salesMedia)}
                    >
                      {isNull(salesMedia) ? (
                        <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                      ) : (
                        <Icon
                          iconSrc={DownloadSvg}
                          iconSize={16}
                          onClick={() =>
                            downloadFile(
                              salesMedia?.mediaUrl ?? "",
                              salesMedia?.originalFileName ?? "",
                            )
                          }
                        />
                      )}
                    </IconButton>
                    <IconButton
                      buttonSize={24}
                      buttonColor="blue"
                      buttonGrade="secondary"
                      disabled={isNull(salesMedia)}
                    >
                      {isNull(salesMedia) ? (
                        <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                      ) : (
                        <a
                          href={salesMedia?.mediaUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon iconSrc={PreviewSvg} iconSize={16} />
                        </a>
                      )}
                    </IconButton>
                  </div>
                </FileInfoContainer>
              }
            />
          </section>
        </ContractInformationRow>

        <ContractInformationRow>
          <Typo as="p" typoType="b9m" color="gray7">
            {t("sales:detail.itemInformation")}
          </Typo>
          <section>
            <SectionCardRow label={t("sales:detail.item")} value={item} />
            <SectionCardRow label={t("sales:detail.amount")} value={amount} />
          </section>
        </ContractInformationRow>
      </Flex>
    );
  };

  return (
    <StyledSectionCard
      cardTitle={t("sales:detail.receiptInformation")}
      rightAccessory={
        <StyledButton
          buttonSize={32}
          onClick={() =>
            navigate(`${IMPORTER_PRIVATE_PATH.SALES_EDIT}/${params.id}`)
          }
          disabled={!isEditAuth}
        >
          <PencelIcon />
          {t("sales:detail.button.edit")}
        </StyledButton>
      }
    >
      {/* Contents */}
      <Flex flexDirection="column" gap={24}>
        {renderCard()}
        {renderRows()}
      </Flex>
    </StyledSectionCard>
  );
}

export default SalesDetailInformation;

const StyledSectionCard = styled(SectionCard)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PencelIcon = styled(PencelSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.white};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  background: ${colorSet.gray11};
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const Divider = styled.div`
  grid-column: 2 span;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const ContractInformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;
