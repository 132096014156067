/**
 * @description PDF에서 Word Wrap 가 되지않아 ,를 구분하여 (, )공백으로 만들어
 * PDF Text컴포넌트에서 개행처리를 위한 함수
 *
 * @param referenceIndex : 몇 번째 콤마부터 띄어쓰기를 진행할지
 */
const stringToPdfPriceText = (param: {
  value: string;
  referenceIndex?: number;
}) => {
  const { value, referenceIndex = 3 } = param;

  const [integerPart, decimalPart] = value.split(".");

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ",",
  );

  const formattedValue = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  const parts = formattedValue.split(",");

  const result = parts
    .map((item, idx) => {
      if ((idx + 1) % referenceIndex === 0 && idx !== parts.length - 1) {
        return `${item}, `;
      } else {
        return `${item},`;
      }
    })
    .join("")
    .replace(/, $/, "")
    .slice(0, -1);

  return result;
};

export default stringToPdfPriceText;
