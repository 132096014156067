import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down-gray7.svg";
import { ReactComponent as ClockSvg } from "@/src/assets/icons/icon-clock-gray5.svg";
import EditSvg from "@/src/assets/icons/icon-edit-white.svg";
import { ReactComponent as MailSvg } from "@/src/assets/icons/icon-mail-blue.svg";
import { Button } from "@/src/components/atom/Button";
import DropDown from "@/src/components/atom/DropDown";
import Icon from "@/src/components/atom/Icon";
import Tooltip from "@/src/components/atom/Tooltip";
import Typo from "@/src/components/atom/Typo";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import colorSet, { ColorType } from "@/src/styles/color";
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { keyframes, styled } from "styled-components";
import BuyerCard from "./card/BuyerCard";
import ContractInformationCard from "./card/ContractInformationCard";
import { contractDetailApi } from "@/src/store/apis/contracts/contractDetail";
import { getContractCurrentVersion } from "@/src/utils/getContractCurrentVersion";
import { isUndefined } from "@/src/utils/is";

interface ContractInformationTabProps {
  isEditAuth: boolean;
  balanceOfContractV2: string;
  percentOfShipment: number;
  totalShippingWeightV2: string;
  emailDialogOn: () => void;
  historyDialogOn: () => void;
  isOnlyReceivedPo?: boolean;
  isDisabledEdit: boolean;
}

const ContractInformationTab = ({
  isEditAuth,
  balanceOfContractV2,
  percentOfShipment,
  totalShippingWeightV2,
  emailDialogOn,
  historyDialogOn,
  isOnlyReceivedPo,
  isDisabledEdit,
}: ContractInformationTabProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { scHistories, isZeroSc } =
    contractDetailApi.endpoints.getExporterScHistories.useQueryState(
      {
        contractId: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError }) => {
          return {
            scHistories: currentData?.rows ?? [],
            isZeroSc: isError,
          };
        },
      },
    );

  const { signatureStatus, contractStatus } =
    contractDetailApi.endpoints.getContractDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;
          const isStable = !isUnstable;

          return {
            contractStatus: isStable
              ? currentData.contractStatus
              : "NOT_LINKED",
            signatureStatus: isStable
              ? currentData.signatureStatus || "DRAFT"
              : "DRAFT",
          };
        },
      },
    );

  const disabled = !isEditAuth || isZeroSc;

  const isScCreateButton =
    signatureStatus === "APPROVED" &&
    getContractCurrentVersion(scHistories).currentVersion >= 2 &&
    getContractCurrentVersion(scHistories).currentVersionHistoryLength === 1;

  return (
    <>
      <FlexEnd>
        {/* Email */}
        <DropDown
          dropdownContentProps={{ align: "end" }}
          dropdownTriggerProps={{ disabled }}
          dropdownTrigger={
            <StyledButton
              buttonSize={32}
              buttonColor="blue"
              buttonGrade="secondary"
              disabled={disabled}
            >
              <MailIcon size={16} color="indigo" data-disabled={disabled} />
              {t("contract:exporter.detail.button.emailButton")}
              <ArrowDownIcon data-disabled={disabled} />
            </StyledButton>
          }
        >
          <StyledSectionCardWithoutHeader>
            <EmailDropDownList>
              <EmailDropDownItem onSelect={emailDialogOn}>
                <MailIcon size={18} color="gray2" />
                <Typo>
                  {t("contract:exporter.detail.button.sendEmailButton")}
                </Typo>
              </EmailDropDownItem>

              <EmailDropDownItem onSelect={historyDialogOn}>
                <ClockIcon />
                <Typo>
                  {t("contract:exporter.detail.button.sentHistoryButton")}
                </Typo>
              </EmailDropDownItem>
            </EmailDropDownList>
          </StyledSectionCardWithoutHeader>
        </DropDown>

        {/* Edit */}
        {isZeroSc || isScCreateButton ? (
          <StyledButton
            buttonSize={32}
            onClick={() =>
              navigate(
                `${EXPORTER_PRIVATE_PATH.CONTRACT_ADD}?id=${params.id}&clientCheck=false`,
              )
            }
            disabled={
              !isEditAuth || isOnlyReceivedPo || contractStatus === "COMPLETE"
            }
          >
            <Icon iconSrc={AddSvg} iconSize={16} />
            {t("contract:exporter.detail.button.createSCButton")}
          </StyledButton>
        ) : (
          <StyledButton
            buttonSize={32}
            onClick={() =>
              navigate(`${EXPORTER_PRIVATE_PATH.CONTRACT_EDIT_V2}/${params.id}`)
            }
            disabled={isDisabledEdit}
          >
            <Icon iconSrc={EditSvg} iconSize={16} />
            {t("contract:exporter.detail.button.editButton")}
          </StyledButton>
        )}
      </FlexEnd>

      {/* Contents */}
      <BuyerCard />

      <SectionCard>
        <ShipmentStatusContainer>
          {/* Status */}
          <ShipmentStatusTextGrid>
            <ShipmentStatusText>
              <Typo typoType="b9m" color="gray6">
                {t("contract:exporter.detail.shipmentList.balanceOfContract")}
              </Typo>
              <Typo typoType="h4" color="blue2">
                {balanceOfContractV2}
              </Typo>
            </ShipmentStatusText>

            <ShipmentStatusText>
              <Typo typoType="b9m" color="gray6">
                {t("contract:exporter.detail.shipmentList.totalShippingWeight")}
              </Typo>
              <Typo typoType="h4" color="blue2">
                {totalShippingWeightV2}
              </Typo>
            </ShipmentStatusText>
          </ShipmentStatusTextGrid>

          {/* Status Percentage */}
          <StatusPercentageContainer>
            {/* Percentage Tooltip */}
            <StyledTooltip
              tooltipColor="green2"
              placement="leftTop"
              $isInnerWindow
              left={percentOfShipment >= 100 ? 100 : percentOfShipment}
              triggerComponent={
                <PercentageContainer>
                  <PercentageColor
                    percent={percentOfShipment >= 100 ? 100 : percentOfShipment}
                    $isInnerWindow
                  />
                  <PercentageOverlay />
                </PercentageContainer>
              }
            >
              <Typo color="white" typoType="b10m">
                {percentOfShipment}%
              </Typo>
            </StyledTooltip>

            {/* 0 ~ 100% */}
            <TopPercentage>
              <Typo typoType="b10m" color="gray7">
                0%
              </Typo>
              <Typo typoType="b10m" color="gray7">
                100%
              </Typo>
            </TopPercentage>

            {/* 선적 비율 */}
            <PercentageTypo>
              <Typo typoType="b9m" color="gray6">
                {t("contract:exporter.detail.shipmentList.shipmentRate")}
              </Typo>
              <Typo typoType="h4" color="green1">
                {percentOfShipment}%
              </Typo>
            </PercentageTypo>
          </StatusPercentageContainer>
        </ShipmentStatusContainer>
      </SectionCard>

      <ContractInformationCard />
    </>
  );
};

export default ContractInformationTab;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EmailDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;
const EmailDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const MailIcon = styled(MailSvg)<{ size: number; color: ColorType }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;

const ArrowDownIcon = styled(ArrowDownSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.indigo};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
const ClockIcon = styled(ClockSvg)`
  width: 18px;
  height: 18px;

  path {
    fill: ${colorSet.gray2};
  }
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
`;

const FlexEnd = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const ShipmentStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const ShipmentStatusTextGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 176px);
  align-items: start;
`;

const ShipmentStatusText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 16px 8px;

  &:first-child {
    border-right: 1px solid ${colorSet.gray10};
  }
`;

const StatusPercentageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const TopPercentage = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PercentageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 16px;
  margin: 24px 0 8px 0;
`;

const PercentageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${colorSet.gray9};
`;

const percentageAnimation = (number: number) => keyframes`
    0% {
        width: 0;
    }
    100% {
        width: ${Math.round(number)}%
    }
`;

const percentageTooltipAnimation = (number: number) => keyframes`
    0% {
        left: 0;
    }
    100% {
        left: ${Math.round(number)}%;
    }
`;

const PercentageColor = styled.div<{
  percent: number;
  $isInnerWindow: boolean;
}>`
  position: absolute;
  height: 100%;
  border-radius: 20px;
  background: ${colorSet.green2};
  z-index: 1;
  animation: ${({ percent, $isInnerWindow }) =>
    $isInnerWindow ? percentageAnimation(percent) : null};
  animation-duration: 500ms;
  animation-fill-mode: both;
`;

const PercentageTypo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTooltip = styled(Tooltip)<{
  left: number;
  $isInnerWindow: boolean;
}>`
  bottom: 30px;
  animation: ${({ left, $isInnerWindow }) =>
    $isInnerWindow ? percentageTooltipAnimation(left) : null};
  animation-duration: 500ms;
  animation-fill-mode: both;
`;

const SectionCard = styled(StyledSectionCardWithoutHeader)`
  padding: 24px 16px;
`;
