import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import {
  AuthTokenIdDto,
  AuthTokenIdVerifyStringDto,
  AuthTokenVerifiedDto,
  ChangeExporterUserPasswordBySessionDto,
  ChangeExporterUserPasswordDto,
  CorporateManagerSignUpDto,
  ExporterDto,
  ExportersInfoRequestParams,
  ExporterUserAdditionalInfoDto,
  ExporterUserDto,
  ExporterUserMfaSignInDto,
  ExporterUserSignInRequestBody,
  GenerateAuthTokenForMfaDto,
  ManagerSignUpDto,
  MfaDto,
  ModifyExporterUserAdditionalInfoDto,
  ModifyExporterUserBySessionDto,
  WithdrawalReasonDto,
} from "./interface";

type SignInMfaDto = {
  row: MfaDto;
  statusCode: 202;
};

type SignInExporterUserDto = {
  row: ExporterUserDto;
  statusCode: 201;
};

const authApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ["additional-infos"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      /**
       * 기업 계정 가입
       */
      corpSignUp: build.mutation<
        Row<ExporterUserDto>,
        CorporateManagerSignUpDto
      >({
        query: (body) => ({
          url: "/exporter-user/corporate-manager-sign-up",
          method: "POST",
          body,
        }),
      }),
      /**
       * 직원 계정 가입
       */
      staffSignUp: build.mutation<Row<ExporterUserDto>, ManagerSignUpDto>({
        query: (body) => ({
          url: "/exporter-user/manager-sign-up",
          method: "POST",
          body,
        }),
      }),
      /**
       * 인증 번호 발송
       */
      sendTokenEmail: build.mutation<
        { authTokenId: string },
        { contact: string }
      >({
        query: (body) => ({
          url: "/exporter-user/auth-tokens",
          method: "POST",
          body: {
            contact: body.contact,
            usage: "SIGN_UP",
          },
        }),
        transformResponse: (res: Row<{ authTokenId: string }>) => res.row,
      }),
      /**
       * 인증번호 유효성 검사
       */
      checkSendEmailCode: build.mutation<
        AuthTokenVerifiedDto,
        AuthTokenIdVerifyStringDto
      >({
        query: (body) => ({
          url: "/exporter-user/auth-token-verification",
          method: "POST",
          body,
        }),
        transformResponse: (res: Row<AuthTokenVerifiedDto>) => res.row,
      }),
      /**
       * 사업자 번호 인증
       */
      checkBusinessNumber: build.query<void, { businessNumber: string }>({
        query: ({ businessNumber }) => ({
          url: "/exporter/business-number-check",
          params: { businessNumber },
        }),
      }),
      /** 회사 이름 중복 조회 */
      checkBusinessName: build.query<void, { companyName: string }>({
        query: ({ companyName }) => ({
          url: "/exporter/company-name-check",
          params: { companyName },
        }),
      }),
      /** 아이디 중복 조회 */
      checkId: build.query<void, { id: string }>({
        query: ({ id }) => ({
          url: "/exporter-user/a-id-check",
          params: { aId: id },
        }),
      }),
      /**
       * 수출회사 조회
       */
      getExporter: build.query<ExporterDto[], ExportersInfoRequestParams>({
        query: (params) => ({
          url: "/exporters",
          params,
        }),
        transformResponse: (res: Rows<ExporterDto>) => res.rows,
      }),
      /**
       * 비밀번호 초기화
       */
      resetPassword: build.mutation<void, { name: string; aId: string }>({
        query: (body) => ({
          url: "/exporter-user/password-initialization",
          method: "POST",
          body,
        }),
      }),
      /**
       * 비밀번호 변경
       */
      changePassword: build.mutation<void, ChangeExporterUserPasswordDto>({
        query: (body) => ({
          url: "/exporter-user/password-change",
          method: "POST",
          body,
        }),
      }),
      /**
       * 로그인
       */
      signIn: build.mutation<
        SignInMfaDto | (SignInExporterUserDto & { token: string }),
        ExporterUserSignInRequestBody
      >({
        query: ({ aId, password }) => ({
          url: "/exporter-user/sign-in",
          method: "POST",
          body: { aId, password },
        }),
        transformResponse: (
          res: SignInMfaDto | SignInExporterUserDto,
          meta: FetchBaseQueryMeta,
        ) => {
          // 이중인증 ON 202
          // 이중인증 OFF 201
          if (res.statusCode === 201) {
            return {
              ...res,
              token: meta.response?.headers.get("Authorization") || "",
            };
          } else {
            return res;
          }
        },
      }),
      pendingResetPassword: build.mutation<void, void>({
        query: () => ({
          url: "/exporter-user/password-change-pending",
          method: "POST",
        }),
      }),
      /**
       * 로그아웃
       */
      signOut: build.mutation<void, void>({
        query: () => ({
          url: "/exporter-user/sign-out",
          method: "POST",
        }),
      }),
      /**
       * 세션 가져오기 수출입웹 토큰으로 유저정보 조회 API
       */
      getSession: build.query<Row<ExporterUserDto>, void>({
        query: () => ({
          url: "/exporter-users/this",
        }),
      }),

      /**
       * 수출입웹 토큰으로 유저정보를 수정하는 API 입니다.
       */
      editSession: build.mutation<
        Row<ExporterUserDto>,
        ModifyExporterUserBySessionDto
      >({
        query: (body) => ({
          url: "/exporter-users/this",
          method: "PUT",
          body,
        }),
      }),

      /**
       * 수출입웹 토큰으로 비밀번호를 변경하는 API 입니다.
       */
      changePasswordOnMyPage: build.mutation<
        void,
        ChangeExporterUserPasswordBySessionDto
      >({
        query: (body) => ({
          url: "/exporter-users/this/password-change",
          method: "POST",
          body,
        }),
      }),

      /**
       * 수출입웹 토큰으로 유저의 추가 정보를 조회하는 API 입니다.
       * - 가이드 화면 렌더 여부 의존
       * - 퀵 가이드 화면 열림 여부 의존
       */
      getExporterUserAdditionalInfos: build.query<
        Row<ExporterUserAdditionalInfoDto>,
        void
      >({
        query: () => ({
          url: "/exporter-user-additional-infos/this",
        }),
        providesTags: ["additional-infos"],
      }),

      /**
       * 수출입웹 토큰으로 유저의 추가 정보를 수정하는 API 입니다.
       * - 가이드 화면 렌더 여부 의존
       * - 퀵 가이드 화면 열림 여부 의존
       */
      editExporterUserAdditionalInfos: build.mutation<
        void,
        ModifyExporterUserAdditionalInfoDto
      >({
        query: (body) => ({
          url: "/exporter-user-additional-infos/this",
          method: "PUT",
          body,
        }),
        invalidatesTags: ["additional-infos"],
      }),

      /**
       * 수출입웹 회원탈퇴 API
       */
      withdrawUser: build.mutation<void, WithdrawalReasonDto>({
        query: (body) => ({
          url: "/exporter-user/withdraw-user",
          method: "PUT",
          body,
        }),
      }),

      /**
       * 수출입웹 기업 회원탈퇴 API
       */
      withdrawExporterAndUsers: build.mutation<void, WithdrawalReasonDto>({
        query: (body) => ({
          url: "/exporter-user/withdraw-exporter-and-users",
          method: "PUT",
          body,
        }),
      }),

      /**
       * 이메일 또는 SMS로 MFA 인증번호 발급 API
       */
      getMfaAuthToken: build.mutation<
        AuthTokenIdDto,
        GenerateAuthTokenForMfaDto
      >({
        query: (body) => ({
          url: "/exporter-user/mfa/auth-tokens",
          method: "POST",
          body,
        }),

        transformResponse: (res: Row<AuthTokenIdDto>) => {
          return res.row;
        },
      }),

      /**
       * MFA를 통한 수출입웹 로그인 API
       */
      mfaAuthSignIn: build.mutation<
        ExporterUserDto & { token: string },
        ExporterUserMfaSignInDto
      >({
        query: (body) => ({
          url: "/exporter-user/mfa/sign-in",
          method: "POST",
          body,
        }),
        transformResponse: (
          res: Row<ExporterUserDto>,
          meta: FetchBaseQueryMeta,
        ) => {
          return {
            ...res.row,
            token: meta?.response?.headers.get("Authorization") || "",
          };
        },
      }),
    }),
  });

export const {
  useSignInMutation,
  useSignOutMutation,
  useStaffSignUpMutation,
  useSendTokenEmailMutation,
  useResetPasswordMutation,
  usePendingResetPasswordMutation,
  useChangePasswordMutation,
  useGetExporterUserAdditionalInfosQuery,
  useLazyGetExporterUserAdditionalInfosQuery,
  useCorpSignUpMutation,
  useCheckSendEmailCodeMutation,

  useLazyGetExporterQuery,
  useLazyCheckBusinessNumberQuery,
  useLazyCheckBusinessNameQuery,
  useLazyCheckIdQuery,

  useGetSessionQuery,
  useLazyGetSessionQuery,
  useEditSessionMutation,
  useChangePasswordOnMyPageMutation,
  useEditExporterUserAdditionalInfosMutation,

  // 회원탈퇴, 기업탈퇴
  useWithdrawUserMutation,
  useWithdrawExporterAndUsersMutation,

  // Mfa 이중인증
  useGetMfaAuthTokenMutation,
  useMfaAuthSignInMutation,
} = authApi;
export { authApi };
