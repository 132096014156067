import colorSet from "@/src/styles/color";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../atom/Button";
import Typo from "../../atom/Typo";
import Flex from "../../molecule/Flex";

interface GuideCloseContentProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
}

const GuideCloseContent = ({
  isOpen,
  isLoading,
  onClose,
}: GuideCloseContentProps) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <CloseContent flexDirection="column" alignItems="center" gap={16}>
      <Flex flexDirection="column" alignItems="center" gap={8}>
        <Typo typoType="b8m" color="gray1">
          {t("common:guide.closeSuccessDescription")}
        </Typo>
        <Typo typoType="b8m" color="gray1">
          <Trans
            i18nKey="common:guide.faqDescription"
            components={{
              strong: <Typo typoType="h8" color="gray1" />,
            }}
          />
        </Typo>
      </Flex>
      <div>
        <Button
          buttonGrade="tertiary"
          buttonColor="black"
          onClick={onClose}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t("common:guide.close")}
        </Button>
      </div>
    </CloseContent>
  );
};

export default GuideCloseContent;

const CloseContent = styled(Flex)`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid ${colorSet.gray9};
`;
