import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { forwardRef, HTMLAttributes } from "react";
import { styled } from "styled-components";
import { ALPHABET_AND_NUMBER } from "./constant";

interface VerificationInputDivProps extends HTMLAttributes<HTMLDivElement> {
  char: string;
  onCharChange: (code?: string) => void;
  allowKey?: string[];
  onBackSpaceKeyDown?: () => void;
}

const VerificationInputDiv = forwardRef<
  HTMLDivElement,
  VerificationInputDivProps
>(
  (
    {
      onCharChange,
      char,
      allowKey = ALPHABET_AND_NUMBER,
      onBackSpaceKeyDown = () => {},
      ...restProps
    },
    ref,
  ) => {
    const { onKeyDown } = restProps;
    return (
      <StyledVerificationInputDiv
        ref={ref}
        tabIndex={0}
        {...restProps}
        onKeyDown={(e) => {
          const key = e.key;
          if (key === "Backspace") {
            return onBackSpaceKeyDown?.();
          }
          if (allowKey.includes(key)) {
            onCharChange(key);
          }
          onKeyDown?.(e);
        }}
      >
        {char}
      </StyledVerificationInputDiv>
    );
  },
);

export default VerificationInputDiv;

const StyledVerificationInputDiv = styled.div`
  width: 52px;
  height: 56px;
  border: 1px solid ${colorSet.gray9};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  ${typo.b7r}

  &:hover, &:focus {
    box-shadow:
      0px 0px 0px 1px #1479eb,
      0px 0px 0px 3px rgba(24, 123, 235, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25);
  }
`;
