import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import { TaskStatusType } from "@/src/store/apis/tasks/taskList/interface";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css, styled } from "styled-components";

function TaskItemStatus() {
  const { t } = useTranslation();
  const params = useParams();

  const {
    loadingStatus,
    exporterItem,
    loadingStartDateAt,
    photoSubmittedDateAt,
    numberOfLoadingContainerAndType,
    totalNetWeight,
    totalUnitQuantity,
    workEstimatedTime,
    workDelayedMinute,
  } = taskDetailApi.endpoints.getTaskDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ isError, currentData, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        const workEstimatedHour =
          !isUnstable &&
          currentData.workEstimatedMinute &&
          Math.floor(currentData.workEstimatedMinute / 60) < 10
            ? "0" + Math.floor(currentData.workEstimatedMinute / 60)
            : Math.floor((currentData?.workEstimatedMinute as number) / 60);

        const workEstimatedMinute =
          !isUnstable &&
          currentData.workEstimatedMinute &&
          Math.floor(currentData.workEstimatedMinute % 60)
            ? Math.floor(currentData.workEstimatedMinute % 60)
            : "00";

        return {
          isError,
          loadingStatus: currentData?.taskStatus as TaskStatusType,
          exporterItem: !isUnstable ? currentData.exporterItem : "-",
          loadingStartDateAt:
            !isUnstable && currentData.taskStartDateAt
              ? dayjs(currentData.taskStartDateAt).format(
                  DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
                )
              : "-",
          photoSubmittedDateAt:
            !isUnstable && currentData.photoSubmittedDateAt
              ? dayjs(currentData.photoSubmittedDateAt).format(
                  DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
                )
              : "-",
          numberOfLoadingContainerAndType:
            !isUnstable &&
            currentData.numberOfTaskContainer &&
            currentData.containerType
              ? `${currentData?.numberOfTaskContainer}X${currentData?.containerType}`
              : "-",
          totalNetWeight: !isUnstable ? currentData.totalNetWeight : "-",
          totalUnitQuantity: !isUnstable ? currentData.totalUnitQuantity : "-",
          workEstimatedTime:
            !isUnstable && currentData.workEstimatedMinute
              ? `${workEstimatedHour} : ${workEstimatedMinute}`
              : "-",
          workDelayedMinute:
            !isUnstable && currentData.workDelayedMinute
              ? currentData.workDelayedMinute
              : null,
          workEstimatedMinute: currentData?.workEstimatedMinute,
        };
      },
    },
  );

  const isWorkingAndCompleted =
    loadingStatus === "WORKING" ||
    loadingStatus === "CONTAINER_COMPLETED" ||
    loadingStatus === "TASK_COMPLETED";

  return (
    <TaskItemStatusContainer isWorkingAndCompleted={!isWorkingAndCompleted}>
      <FlexRow isWorkingAndCompleted={!isWorkingAndCompleted}>
        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("common:item")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {exporterItem}
          </BreakWordTypo>
        </TaskItem>

        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("task:detail.cards.workEstimatedTime")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {workEstimatedTime}{" "}
            {workDelayedMinute && (
              <Typo
                typoType="h6"
                color={!isWorkingAndCompleted ? "gray7" : "red2"}
              >
                ({workDelayedMinute.toLocaleString()}{" "}
                {t("common:loadingWorkEstimatedDelay")})
              </Typo>
            )}
          </BreakWordTypo>
        </TaskItem>

        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("task:detail.cards.loadingStartDate")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {loadingStartDateAt}
          </BreakWordTypo>
        </TaskItem>

        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("task:detail.cards.photoSubmittedDate")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {photoSubmittedDateAt}
          </BreakWordTypo>
        </TaskItem>
      </FlexRow>

      <FlexRow isWorkingAndCompleted={!isWorkingAndCompleted}>
        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("common:noOfContainer")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {numberOfLoadingContainerAndType}
          </BreakWordTypo>
        </TaskItem>

        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("common:totalNetWeight")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {totalNetWeight}
          </BreakWordTypo>
        </TaskItem>

        <TaskItem>
          <Typo typoType="b9m" color="gray6">
            {t("common:totalUnitQty")}
          </Typo>

          <BreakWordTypo
            typoType="h4"
            color={!isWorkingAndCompleted ? "gray7" : "blue2"}
          >
            {totalUnitQuantity}
          </BreakWordTypo>
        </TaskItem>
      </FlexRow>
    </TaskItemStatusContainer>
  );
}

export default TaskItemStatus;

const renderItemContainerColor = (isWorking: boolean) => {
  if (isWorking) {
    return css`
      border: 1px solid ${colorSet.gray9};
      background: ${colorSet.gray11};
    `;
  }

  return css`
    border: 1px solid ${colorSet.blue8};
    background: ${colorSet.blue10};
  `;
};

const TaskItemStatusContainer = styled.article<{
  isWorkingAndCompleted: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 0 24px;

  ${({ isWorkingAndCompleted }) =>
    renderItemContainerColor(isWorkingAndCompleted)}
`;

const FlexRow = styled.div<{ isWorkingAndCompleted: boolean }>`
  display: flex;

  &:first-child {
    border-bottom: ${({ isWorkingAndCompleted }) =>
      isWorkingAndCompleted
        ? `1px solid ${colorSet.gray9}`
        : ` 1px solid ${colorSet.blue8}`};
  }
`;

const TaskItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 16px 8px;
  text-align: center;
`;
