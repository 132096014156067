import useAlert from "@/src/hooks/useAlert";
import { useSendCompanySignRequestMutation } from "@/src/store/apis/corporate/company";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../atom/AlertDialog";

interface SignatureRequestAlertDialogProps {
  open: boolean;
  onOpenChange: () => void;
  onCancel: () => void;
}

/**
 * @description
 * - 일반 매니저경우 서명을 등록 할 수 없어 기업관리자에게 서명등록 요청하는 모달
 */
const SignatureRequestAlertDialog = ({
  open,
  onOpenChange,
  onCancel,
}: SignatureRequestAlertDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const [sendCompanySignRequest, { isLoading }] =
    useSendCompanySignRequestMutation();

  const handleCompanySignRequest = async () => {
    try {
      await sendCompanySignRequest().unwrap();
      onCancel();
      alert.showAlert({
        type: "success",
        message: t("alert:sendSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
      title={t("common:noRegisteredSignatureTitle")}
      description={t("common:requestSignatureNotificationDescription")}
      onOk={handleCompanySignRequest}
      onCancel={onCancel}
      cancelText={t("common:close")}
      okText={t("common:signatureRequest")}
      isOkLoading={isLoading}
    />
  );
};

export default SignatureRequestAlertDialog;
