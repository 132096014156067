import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../baseQuery";
import { IsBookmarkedInfoDto } from "../contracts/contractDetail/interface";
import { Row, Rows } from "../type";
import {
  ExporterUserSalesBookmarkInfoDto,
  FinalProductDto,
  GenerateFinalProductDto,
  GenerateSalesDto,
  GenerateSalesMemoDto,
  GenerateWarehousingContainerDto,
  GetSalesRequestParams,
  GetSalesWithWarehousingDetailRequest,
  GetSalesWithWarehousingRequest,
  ModifySalesDto,
  ModifyWarehousingDto,
  SalesBookmarkListViewDto,
  SalesDto,
  WarehousingContainerDto,
  WarehousingDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["list", "detail", "finalProducts", "warehousings", "bookmark"],
});

const salesApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 발주 계약 목록 API
    getSales: build.query<Rows<SalesDto>, GetSalesRequestParams>({
      query: (params) => ({
        url: "/importers/sales",
        params,
      }),
    }),

    // 발주 계약 상세 API
    getSale: build.query<SalesDto, { id: number }>({
      query: ({ id }) => ({
        url: `/importers/sales/${id}`,
      }),
      transformResponse: (res: Row<SalesDto>) => res.row,
      providesTags: ["detail"],
    }),

    // 발주 계약 생성 API
    createSale: build.mutation<Row<SalesDto>, GenerateSalesDto>({
      query: (body) => ({
        method: "POST",
        url: `/importers/sales`,
        body,
      }),
    }),

    // 발주 계약 수정
    editSale: build.mutation<Row<SalesDto>, ModifySalesDto & { id: number }>({
      query: ({ id, ...restBody }) => ({
        method: "PUT",
        url: `/importers/sales/${id}`,
        body: { ...restBody },
      }),
    }),

    // 발주 계약 종료
    salesClose: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: "PUT",
        url: `/importers/sales/${id}/closing`,
      }),
      invalidatesTags: ["detail"],
    }),

    // 발주 계약 재오픈
    salesRedo: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: "PUT",
        url: `/importers/sales/${id}/redo`,
      }),
      invalidatesTags: ["detail"],
    }),

    // 최종 공산품 생성 API
    createFinalProduct: build.mutation<
      Row<FinalProductDto>,
      GenerateFinalProductDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/importers/final-products",
        body,
      }),
      invalidatesTags: ["finalProducts"],
    }),

    // 최종 공산품 목록 API
    getFinalProducts: build.query<Rows<FinalProductDto>, void>({
      query: () => ({
        url: "/importers/final-products",
      }),
      providesTags: ["finalProducts"],
    }),

    // 발주계약의 입고 목록을 불러옵니다.
    getSalesWithWarehousings: build.query<
      Rows<WarehousingDto>,
      GetSalesWithWarehousingRequest & { id: number }
    >({
      query: ({ id, ...rest }) => ({
        url: `/importers/sales/${id}/warehousings`,
        params: { ...rest },
      }),
      providesTags: ["warehousings"],
    }),

    // 발주계약의 입고 상세를 불러옵니다.
    getSalesWithWarehousingsDetail: build.query<
      Rows<WarehousingContainerDto>,
      GetSalesWithWarehousingDetailRequest
    >({
      query: ({ id, warehousingId, ...rest }) => ({
        url: `/importers/sales/${id}/warehousings/${warehousingId}`,
        params: { ...rest },
      }),
      providesTags: ["warehousings"],
    }),

    // 입고 정보를 수정합니다.
    updateWarehousings: build.mutation<
      void,
      ModifyWarehousingDto & { id: number }
    >({
      query: ({ id, ...rest }) => ({
        method: "PUT",
        url: `/importers/warehousings/${id}`,
        body: { ...rest },
      }),
      invalidatesTags: ["warehousings"],
    }),

    // 발주 계약 메모 생성 API
    createSalesMemo: build.mutation<
      void,
      GenerateSalesMemoDto & { id: number }
    >({
      query: ({ id, ...rest }) => ({
        method: "POST",
        url: `/importers/sales/${id}/memos`,
        body: { ...rest },
      }),
      invalidatesTags: ["detail"],
    }),

    getWarehousingWithSale: build.query<
      Rows<WarehousingDto>,
      { id: number; warehousedAt?: string; page?: number; pageSize?: number }
    >({
      query: ({ id, warehousedAt, page, pageSize }) => ({
        url: `/importers/sales/${id}/warehousings`,
        params: {
          warehousedAt,
          page,
          pageSize,
        },
      }),
    }),

    connectWarehouseWithSale: build.mutation<
      void,
      GenerateWarehousingContainerDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/importers/warehousings",
        body,
      }),
    }),
    updateWarehouseWithSale: build.mutation<
      void,
      GenerateWarehousingContainerDto
    >({
      query: (body) => ({
        method: "PUT",
        url: "/importers/warehousings",
        body,
      }),
    }),
    disconnectWarehouseWithSale: build.mutation<
      void,
      { containerIdList: number[] }
    >({
      query: (body) => ({
        method: "DELETE",
        url: "/importers/warehousings",
        body,
      }),
    }),

    // 발주 계약 엑셀 다운로드 API
    getSalesExcelList: build.query<void, GetSalesRequestParams>({
      query: (params) => ({
        url: "/importers/this/sales-excel",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "sales_list.xlsx",
          });
        },
      }),
    }),

    // 수출입웹 유저의 단일 발주계약에 대한 북마크 정보 조회 API
    getSalesDetailBookmark: build.query<
      ExporterUserSalesBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporter-users/this/sales/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserSalesBookmarkInfoDto>) =>
        res.row,
      providesTags: ["bookmark"],
    }),

    // 북마크한 수출입웹 유저 목록 조회 API
    getSalesDetailBookmarkUser: build.query<
      Rows<SalesBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/sales/${id}/sales-bookmarks`,
      }),
      providesTags: ["bookmark"],
    }),

    // 수출입웹 유저의 단일 부킹에 대한 북마크 정보 관장 API
    updateSalesDetailBookmark: build.mutation<
      Row<ExporterUserSalesBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporter-users/this/sales/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["bookmark"],
    }),
  }),
});

export const {
  // 발주
  useGetSalesQuery,
  useLazyGetSalesQuery,
  useGetSaleQuery,
  useLazyGetSaleQuery,
  useCreateSaleMutation,
  useEditSaleMutation,
  useSalesCloseMutation,
  useSalesRedoMutation,
  useCreateSalesMemoMutation,
  useLazyGetSalesExcelListQuery,

  // 발주 북마크
  useGetSalesDetailBookmarkQuery,
  useGetSalesDetailBookmarkUserQuery,
  useUpdateSalesDetailBookmarkMutation,

  // 최종 공산품
  useCreateFinalProductMutation,
  useGetFinalProductsQuery,

  // 발주계약의 입고
  useLazyGetSalesWithWarehousingsQuery,
  useGetSalesWithWarehousingsQuery,
  useUpdateWarehousingsMutation,
  useLazyGetSalesWithWarehousingsDetailQuery,

  useLazyGetWarehousingWithSaleQuery,
  useConnectWarehouseWithSaleMutation,
  useUpdateWarehouseWithSaleMutation,
  useDisconnectWarehouseWithSaleMutation,
} = salesApi;
export { salesApi };
