import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  ExporterItemCodeDto,
  ExporterItemCodeListViewDto,
  ExporterItemDto,
  ExporterItemListViewDto,
  GenerateBulkExporterItemCodesDto,
  GenerateBulkExporterItemsDto,
  GetItemsRequest,
  ModifyExporterItemCodeDto,
  ModifyExporterItemDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["items", "itemCodes"],
});

const companyItemApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 아이템 목록 조회
    getItems: build.query<Rows<ExporterItemListViewDto>, GetItemsRequest>({
      query: (params) => ({
        url: "/exporters/this/exporter-items",
        params,
      }),
      providesTags: ["items"],
    }),

    // 아이템 생성
    createItem: build.mutation<Row<void>, GenerateBulkExporterItemsDto>({
      query: (body) => {
        return {
          method: "POST",
          url: "/exporters/this/bulk-exporter-items",
          body: body,
        };
      },
      invalidatesTags: ["items"],
    }),

    // 단일 아이템 수정
    editItem: build.mutation<
      Row<ExporterItemDto>,
      ModifyExporterItemDto & { id: number }
    >({
      query: ({ id, ...body }) => {
        return {
          method: "PUT",
          url: `/exporters/this/exporter-items/${id}`,
          body: body,
        };
      },
      invalidatesTags: ["items"],
    }),

    // 아이템 코드 목록 조회
    getItemCodes: build.query<
      Rows<ExporterItemCodeListViewDto>,
      GetItemsRequest
    >({
      query: (params) => ({
        url: "/exporters/this/exporter-item-codes",
        params,
      }),
      providesTags: ["itemCodes"],
    }),

    // 아이템 코드 생성
    createItemCode: build.mutation<Row<void>, GenerateBulkExporterItemCodesDto>(
      {
        query: (body) => {
          return {
            method: "POST",
            url: "/exporters/this/bulk-exporter-item-codes",
            body: body,
          };
        },
        invalidatesTags: ["itemCodes"],
      },
    ),

    // 아이템 코드 수정
    editItemCode: build.mutation<
      Row<ExporterItemCodeDto>,
      ModifyExporterItemCodeDto & { id: number }
    >({
      query: ({ id, ...body }) => {
        return {
          method: "PUT",
          url: `/exporters/this/exporter-item-codes/${id}`,
          body: body,
        };
      },
      invalidatesTags: ["itemCodes"],
    }),
  }),
});

export const {
  useGetItemsQuery,
  useLazyGetItemsQuery,
  useCreateItemMutation,
  useEditItemMutation,

  useLazyGetItemCodesQuery,
  useCreateItemCodeMutation,
  useEditItemCodeMutation,
} = companyItemApi;
export { companyItemApi };
