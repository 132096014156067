import PaperAirplaneSvg from "@/src/assets/icons/icon-paper-airplane.svg";
import PersonSvg from "@/src/assets/icons/icon-person-gray.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import Steps from "@/src/components/molecule/Steps";
import PUBLIC_PATH from "@/src/routes/public/path";
import typo from "@/src/styles/typography";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StaffSignUpStepThree = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goLoginButtonRef = useRef<HTMLButtonElement>(null);

  const goTo = (path: keyof typeof PUBLIC_PATH) =>
    navigate(PUBLIC_PATH[path], { replace: true });

  useEffect(() => {
    goLoginButtonRef.current?.focus();
  }, []);

  return (
    <StyledSectionCard>
      <StepInfo>
        <IconContainer>
          <Icon iconSrc={PaperAirplaneSvg} iconSize={56} />
          <Icon iconSrc={PersonSvg} iconSize={56} />
        </IconContainer>

        <Steps
          items={[
            {
              title: t("signupCorp:content.requestedForSignUpApproval"),
            },
            {
              title: t("signupCorp:content.approvalOfCompanyCompleted"),
            },
          ]}
          current={1}
        />
      </StepInfo>

      <TypoSection>
        <Typo typoType="d6" color="gray1">
          {t("signupCorp:content.requestedSignUpTitle")}
        </Typo>

        <StyledTypo typoType="b7m" color="gray2">
          {t("signupCorp:content.requestedSignUpDescription")}
        </StyledTypo>
      </TypoSection>

      <BackToLoginButton
        buttonGrade="secondary"
        buttonSize={40}
        onClick={() => goTo("LOGIN")}
        ref={goLoginButtonRef}
      >
        {t("signupCorp:button.backToLogin")}
      </BackToLoginButton>
    </StyledSectionCard>
  );
};

export default StaffSignUpStepThree;

const StyledSectionCard = styled(SectionCardWithoutHeader)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 640px;
  padding: 40px 48px;
  gap: 40px;
`;

const StepInfo = styled.div`
  max-width: 400px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 12px;
`;

const TypoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
`;

const StyledTypo = styled(Typo)`
  white-space: pre;
`;

const BackToLoginButton = styled(Button)`
  width: 100%;
  text-align: center;
  ${typo.btn3m};
`;
