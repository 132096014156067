import { Button } from "@/src/components/atom/Button";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { useLazyGetInquiriesQuery } from "@/src/store/apis/inquiry";
import { InquiryDto } from "@/src/store/apis/inquiry/interface";
import colorSet from "@/src/styles/color";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { columnInquiryList } from "./columns/columnInquiryList";

const ImporterInquiryPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [getInquiries, inquiriesResult] = useLazyGetInquiriesQuery();
  const { currentData: isInquiryCategory } = useGetCommonCodeViaCodeNameQuery({
    codeName: "INQUIRY_CATEGORY",
  });

  const {
    state: { pagination },
    func: { onPaginationChange },
  } = useFilterSearch({
    isReady,
    gridRef: gridRef.current,
    fetch: getInquiries,
    defaultFilterData: {},
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "category",
        langKey: "inquiry:table.category",
      },
      {
        columnKey: "title",
        langKey: "inquiry:table.title",
      },
      {
        columnKey: "createdAt",
        langKey: "inquiry:table.registerDate",
      },
      {
        columnKey: "status",
        langKey: "inquiry:table.answer",
      },
    ],
  });

  const rowData = inquiriesResult.currentData?.rows.reduce<
    (InquiryDto & { categoryKo: string; categoryEn: string })[]
  >((acc, val) => {
    const category = isInquiryCategory?.find(
      ({ codeItemName }) => codeItemName === val.category,
    );
    const resource = {
      ...val,
      categoryKo: category?.codeItemNameKo ?? "",
      categoryEn: category?.codeItemNameEn ?? "",
    };

    return [...acc, resource];
  }, []);

  useEffect(() => {
    if (gridRef.current) {
      if (inquiriesResult.isFetching) {
        gridRef.current.api.showLoadingOverlay();
      }
    }
  }, [inquiriesResult.isFetching]);

  useEffect(() => {
    if (isReady && gridRef.current) {
      gridRef.current.api.setColumnDefs(columnInquiryList);
    }
  }, [isReady]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:inquiry")]}
      customPageTitle={
        <SpaceBetweenDiv>
          <Typo as="h1" typoType="h1">
            {t("sideNav:inquiry")}
          </Typo>

          <Button
            buttonColor="blue"
            buttonSize={32}
            buttonGrade="primary"
            onClick={() => navigate(IMPORTER_PRIVATE_PATH.INQUIRY_ADD)}
          >
            + {t("sideNav:inquiryRegistration")}
          </Button>
        </SpaceBetweenDiv>
      }
    >
      <Card>
        <Table
          ref={gridRef}
          onGridReady={() => setIsReady(true)}
          columnDefs={columnInquiryList}
          rowData={inquiriesResult.isSuccess ? rowData : []}
          totalPage={inquiriesResult?.currentData?.count}
          onRowClicked={({ data }) => {
            navigate(`${IMPORTER_PRIVATE_PATH.INQUIRY}/${data.id}`);
          }}
          isPaginationDataMaping
          pageSize={pagination.pageSize}
          page={pagination.page}
          handlePaginationClick={(page, pageSize) =>
            onPaginationChange({ page, pageSize })
          }
        />
      </Card>
    </ImporterMainLayout>
  );
};

export default ImporterInquiryPage;

const Card = styled.div`
  border-radius: 16px;
  background: ${colorSet.white};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 8px;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;
