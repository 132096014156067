import { ColDef, ColumnMovedEvent } from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";
import { isNull } from "../utils/is";

interface UseAgGridColumnProps {
  key: string;
  columns: ColDef[];
}

function useAgGirdSetColumn({ key, columns }: UseAgGridColumnProps) {
  const colFromLocalStorage: string[] = useMemo(
    () =>
      JSON.parse(
        !isNull(localStorage.getItem(key))
          ? (localStorage.getItem(key) as string)
          : "[]",
      ),
    [key],
  );

  const columnFollowedByLocalStorage = useMemo(() => {
    return colFromLocalStorage.length === 0 ||
      colFromLocalStorage.length !== columns.length // (2024.04.08) 테이블 컬럼이 정책변경의 이유로 수정되었을 때, 기존의 localStorage 컬럼과 충돌을 방지하기 위함
      ? columns
      : colFromLocalStorage.map(
          (id) => columns.find(({ field }) => field === id) ?? {},
        );
  }, [colFromLocalStorage, columns]);

  const [col, setCol] = useState(columnFollowedByLocalStorage);

  const handleColumnMoved = useCallback(
    (e: ColumnMovedEvent) => {
      const changedGrid = e.columnApi.getAllGridColumns();
      const colIdList = changedGrid.map((col) => col["colId"]);
      setCol(
        colIdList.map((id) => columns.find(({ field }) => field === id) ?? {}),
      );
      localStorage.setItem(key, JSON.stringify(colIdList));
    },
    [columns, key],
  );

  const handleColumnReset = useCallback(() => {
    setCol(columns);
  }, [columns]);

  return {
    func: {
      handleColumnMoved,
      handleColumnReset,
      setCol,
    },
    state: {
      col,
    },
  };
}

export default useAgGirdSetColumn;
