import Table from "@/src/components/atom/Table";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { BookingProgressContainerInfoDto } from "@/src/store/apis/shipments/importerShipment/interface";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";
import { columnContainerListWithoutSelection } from "../../../../columns/columnContainerList";

interface SelectedContainerListProps {
  selectedContainer: BookingProgressContainerInfoDto[];
}

const SelectedContainerList = ({
  selectedContainer,
}: SelectedContainerListProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const [isReady, setIsReady] = useState(false);
  const [columnDefs] = useState(columnContainerListWithoutSelection);

  // AgGrid Header
  useAgGridHeaderRefresh({
    gridRef: gridRef?.current,
    isReady,
    headerSet: [
      {
        columnKey: "itemCode",
        langKey: "shipment:importer.detail.table.itemCode",
      },
    ],
  });

  return (
    <>
      <Table
        ref={gridRef}
        isPagination={false}
        rowData={selectedContainer}
        columnDefs={columnDefs}
        isPaginationDataMaping
        height={253}
        onGridReady={() => setIsReady(true)}
      />
    </>
  );
};

export default SelectedContainerList;
