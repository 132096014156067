import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { Button } from "@/src/components/atom/Button";
import CheckboxGroup from "@/src/components/atom/CheckboxGroup";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import MultipleInput from "@/src/components/atom/Input/MultipleInput";
import Loader from "@/src/components/atom/Loader";
import BasicTextArea from "@/src/components/atom/TextArea/BasicTextArea";
import Typo from "@/src/components/atom/Typo";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { TEMPLATE_OPTION_VALUE } from "@/src/constant/emailDialog";
import useAlert from "@/src/hooks/useAlert";
import SharedEmailBuyerEmployeeAddDialog from "@/src/pages/exporter/ClientManagement/dialog/SharedEmailBuyerEmployeeAddDialog";
import PRIVATE_PATH from "@/src/routes/private/path";
import { useAppSelector } from "@/src/store";
import { useCreateBuyerEmployeesMutation } from "@/src/store/apis/client/buyer";
import {
  BuyerEmployeeDto,
  GenerateBuyerEmployeeDto,
} from "@/src/store/apis/client/buyer/interface";
import { useGetContractDetailBuyerEmployeesQuery } from "@/src/store/apis/contracts/contractDetail";
import {
  taskDetailApi,
  useTaskDetailSendEmailMutation,
} from "@/src/store/apis/tasks/taskDetail";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

interface EmailDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CANCEL,
}

enum DialogState {
  NULL,
  ADD_CONTACT_PERSON,
}

const buyerEmptyArray: BuyerEmployeeDto[] = [];

function EmailDialog({ open, onOpenChange }: EmailDialogProps) {
  const { t } = useTranslation();
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const recommendRef = useRef<HTMLDivElement>(null);

  const alert = useAlert();
  const params = useParams();
  const company = useAppSelector((state) => state.auth.user);

  // API
  const { buyerId, buyerName, bookingNo, scNo, poNo, bookingId, contractId } =
    taskDetailApi.endpoints.getTaskDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            buyerId: currentData?.buyerId as number,
            buyerName: !isUnstable ? currentData?.buyerName : "-",
            bookingNo: !isUnstable ? currentData?.bookingNo : "",
            scNo: !isUnstable ? currentData?.scNo : "",
            poNo: !isUnstable ? currentData?.poNo : "",
            bookingId: currentData?.bookingId,
            contractId: currentData?.contractId,
          };
        },
      },
    );
  const { buyerList, isBuyerFetching, refetch } =
    useGetContractDetailBuyerEmployeesQuery(
      {
        id: buyerId,
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            buyerList: !isUnstable
              ? (currentData.rows ?? buyerEmptyArray)
              : buyerEmptyArray,
            isBuyerFetching: isFetching,
          };
        },
      },
    );
  const [createEmployee] = useCreateBuyerEmployeesMutation();
  const [sendEmail, { isLoading }] = useTaskDetailSendEmailMutation({
    selectFromResult: ({ isLoading }) => {
      return { isLoading };
    },
  });

  const recipientsOptionList = buyerList.map((item) => {
    return {
      label: `${item.name} (${item.email})`,
      value: String(item.id),
    };
  });

  // State
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [isRecommendTitleOpen, setIsRecommendTitleOpen] = useState(false);

  const {
    control,
    watch,
    setValue,
    setFocus,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<{
    email: string[];
    cc: string;
    emailCcList: string[];
    buyerName: string;
    emailTitle: string;
    emailContent: string;
    template: string[];
  }>({
    mode: "onBlur",
    defaultValues: {
      email: [],
      cc: "",
      emailCcList: [],
      buyerName,
      emailTitle: "",
      emailContent: "",
      template: [TEMPLATE_OPTION_VALUE.loading],
    },
  });

  const defaultBookingNoAndScNo = [
    `${t("common:bookingNo")}${bookingNo}`,
    `${
      getValues("template").includes(TEMPLATE_OPTION_VALUE.contract) && poNo
        ? `${t("common:poNo")}${poNo}`
        : ""
    }`,
    `${
      getValues("template").includes(TEMPLATE_OPTION_VALUE.contract)
        ? `${t("common:scNo")}${scNo}`
        : ""
    }`,
  ]
    .filter(Boolean)
    .join(" / ");
  const defaultTitle = `[${company?.exporter.companyName}] (${defaultBookingNoAndScNo})`;

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  useEffect(() => {
    setValue("emailTitle", defaultTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRecommendOutsideClick = (e: MouseEvent): void => {
    if (
      titleContainerRef.current &&
      !titleContainerRef.current.contains(e.target as Node)
    ) {
      setIsRecommendTitleOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleRecommendOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleRecommendOutsideClick);
    };
  }, []);

  const isValidEmail = (value: string) => {
    const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

    return emailRegex.test(value);
  };

  const handleCreateEmployee = async (
    params: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    },
  ) => {
    try {
      const res = await createEmployee({
        id: buyerId,
        email: params.email,
        name: params.name,
        isActivated: params.isActivated === "Y",
        officeContact: params.officeContact || undefined,
        officeContactPrefix: params.officeContactPrefix || undefined,
        personalContact: params.personalContact || undefined,
        personalContactPrefix: params.personalContactPrefix || undefined,
        buyerEmployeeRemark: params.buyerEmployeeRemark || undefined,
      }).unwrap();

      setDialogState(DialogState.NULL);
      refetch();
      clearErrors("email");

      return res.row.id.toString();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });

      return;
    }
  };

  const handleSubmitClick = async () => {
    const emailCcList = getValues("emailCcList").filter((email) =>
      isValidEmail(email),
    );

    const buyerEmployeeIdList =
      buyerList
        .filter((buyer) =>
          getValues("email").some((id) => id === String(buyer.id)),
        )
        .map((item) => item.id) ?? [];

    const emailParams = {
      id: Number(params.id),
      emailCcList,
      emailTitle: getValues("emailTitle"),
      emailContent: getValues("emailContent")
        ? getValues("emailContent")
        : undefined,
      buyerId,
      buyerEmployeeIdList,
      contractId: getValues("template").includes(TEMPLATE_OPTION_VALUE.contract)
        ? contractId
        : undefined,
      bookingId: getValues("template").includes(TEMPLATE_OPTION_VALUE.booking)
        ? bookingId
        : undefined,
    };

    try {
      await sendEmail(emailParams).unwrap();
      onOpenChange(false);

      alert.showAlert({
        type: "success",
        message: t("emailDialog:emailSuccessAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const handlePreviewClick = () => {
    window.open(
      `${PRIVATE_PATH.SHARED_EMAIL_TASK}?preview=true&taskId=${params.id}${
        getValues("template").includes("CONTRACT")
          ? `&contractId=${contractId}`
          : ""
      }${
        getValues("template").includes("BOOKING")
          ? `&bookingId=${bookingId}`
          : ""
      }&template=${getValues("template")}`,
      "_blank",
    );
  };

  const handleRecommendTitleClick = () => {
    clearErrors("emailTitle");
    setValue("emailTitle", defaultTitle);
    setFocus("emailTitle");
    setIsRecommendTitleOpen(false);
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelAlertDialog
          open={alertDialogState === AlertDialogState.CANCEL}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            setFocus("email");
          }}
          onOk={() => onOpenChange(false)}
        />
      );
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      return (
        <SharedEmailBuyerEmployeeAddDialog
          onSave={async (e) => {
            const employee = await handleCreateEmployee(e);

            if (employee) {
              setValue("email", [employee, ...getValues("email")]);
            }
          }}
          onClose={() => {
            setDialogState(DialogState.NULL);
          }}
        />
      );
    }
  };

  return (
    <Dialog
      title={t("emailDialog:dialogTitle")}
      width={850}
      open={open}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setAlertDialogState(AlertDialogState.CANCEL)}
          >
            <Typo typoType="btn3m">{t("emailDialog:button.cancelButton")}</Typo>
          </Button>
          <Button
            onClick={handleSubmit(handleSubmitClick)}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t("emailDialog:button.sendButton")}
          </Button>
        </DialogFooterContainer>
      }
    >
      {renderAlertDialog()}
      {renderDialog()}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        {/* Buyer */}
        <FormItem
          label={t("common:buyer")}
          type="singleSelect"
          name="buyerName"
          rules={{ required: true }}
          control={control}
          options={[]}
          direction="horizontal"
          defaultValue={buyerName}
          inputProps={{ disabled: true }}
        />

        {/* Recipients */}
        <RecipientsContainer>
          <RecipientsForm
            label={t("emailDialog:label.recipients")}
            type="multipleSelect"
            name="email"
            rules={{ required: true }}
            control={control as any}
            options={recipientsOptionList}
            direction="horizontal"
            inputProps={{
              suffixIcon: isBuyerFetching ? <Loader /> : undefined,
              placeholder: t("emailDialog:placeholder.email"),
              getPopupContainer: (triggerNode: any) => {
                return triggerNode.parentElement;
              },
              onRemoveItem: (value) => {
                const watchCategory = watch("email");
                const watchCategoryFilter = watchCategory.filter(
                  (item) => item !== value,
                );
                setValue("email", watchCategoryFilter);
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <StyledButton
            buttonSize={40}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setDialogState(DialogState.ADD_CONTACT_PERSON)}
          >
            <AddIcon />
            <Typo typoType="btn3m">
              {t("emailDialog:button.addContactPersonButton")}
            </Typo>
          </StyledButton>
        </RecipientsContainer>

        {/* CC */}
        <StyledSectionCardRow
          label="CC"
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="cc"
              render={({ field }) => (
                <MultipleInput
                  placeholder={t("emailDialog:placeholder.cc")}
                  value={field.value}
                  valueList={watch("emailCcList")}
                  onChangeValue={(value) => setValue("cc", value)}
                  onChangeValueList={(valueList) =>
                    setValue("emailCcList", valueList)
                  }
                  validation={isValidEmail}
                />
              )}
            />
          }
        />

        <GrayLine />

        {/* Template */}
        <StyledSectionCardRow
          label={<Label>{t("emailDialog:label.template")}</Label>}
          direction="horizontal"
          value={
            <TemplateContainer>
              <Controller
                control={control}
                name="template"
                render={({ field }) => (
                  <CheckboxGroup
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setFocus("emailTitle");
                    }}
                    options={[
                      {
                        label: t("emailDialog:templateOption.contract"),
                        value: TEMPLATE_OPTION_VALUE.contract,
                      },
                      {
                        label: t("emailDialog:templateOption.booking"),
                        value: TEMPLATE_OPTION_VALUE.booking,
                      },
                      {
                        label: t("emailDialog:templateOption.loading"),
                        value: TEMPLATE_OPTION_VALUE.loading,
                        disabled: true,
                      },
                    ]}
                  />
                )}
              />

              <StyledButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handlePreviewClick}
              >
                <PreviewIcon />
                <Typo typoType="btn3m">
                  {t("emailDialog:button.previewTemplateButton")}
                </Typo>
              </StyledButton>
            </TemplateContainer>
          }
        />

        {/* Title */}
        <TitleContainer ref={titleContainerRef}>
          <FormItem
            label={t("emailDialog:label.title")}
            name="emailTitle"
            control={control}
            type="text"
            rules={{ required: true }}
            inputProps={{
              placeholder: t("emailDialog:placeholder.title"),
              onClick: () => {
                setIsRecommendTitleOpen(true);
              },
              onFocus: () => {
                setIsRecommendTitleOpen(true);
              },
              onChange: () => {
                setIsRecommendTitleOpen(true);
              },
              onKeyDown: (e) => {
                if (e.shiftKey && e.code === "Tab") {
                  setIsRecommendTitleOpen(false);
                }
                if (e.code === "ArrowDown") {
                  recommendRef.current && recommendRef.current.focus();
                }
                if (e.code === "Enter") {
                  setIsRecommendTitleOpen(false);
                }
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          {isRecommendTitleOpen && (
            <RecommendTitleContainer>
              <RecommendTitle typoType="b9r" color="gray7">
                {t("common:recommendTitle")}
              </RecommendTitle>

              <RecommendText
                ref={recommendRef}
                tabIndex={0}
                onClick={handleRecommendTitleClick}
                onKeyDown={(e) => {
                  if (e.code === "ArrowUp") {
                    setFocus("emailTitle");
                  }
                  if (e.code === "Enter") {
                    handleRecommendTitleClick();
                  }
                }}
                onBlur={() => {
                  setIsRecommendTitleOpen(false);
                }}
              >
                <Typo>{defaultTitle}</Typo>
              </RecommendText>
            </RecommendTitleContainer>
          )}
        </TitleContainer>

        {/* Contents */}
        <StyledSectionCardRow
          label={t("emailDialog:label.emailContent")}
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="emailContent"
              render={({ field }) => (
                <StyledTextArea
                  placeholder={t("emailDialog:placeholder.emailContent")}
                  {...field}
                />
              )}
            />
          }
        />
      </Form>
    </Dialog>
  );
}

export default EmailDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  p {
    width: 100%;
  }
`;

const ControllerContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
`;

const TemplateContainer = styled(ControllerContainer)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const GrayLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  height: max-content;
`;

const StyledTextArea = styled(BasicTextArea)`
  min-height: 379px;
`;

const Label = styled(Typo)`
  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const PreviewIcon = styled(PreviewSvg)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const RecipientsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const RecipientsForm = styled(FormItem)`
  width: 100%;
`;

const TitleContainer = styled.div`
  position: relative;
`;

const RecommendTitleContainer = styled(SectionCardWithoutHeader)`
  position: absolute;
  right: 0;
  top: 46px;
  width: calc(100% - 172px);
  background: ${colorSet.white};
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-radius: 8px;
`;

const RecommendTitle = styled(Typo)`
  padding: 8px 16px 0;
`;

const RecommendText = styled.div`
  cursor: pointer;
  padding: 8px 16px;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
    outline: none;
  }
`;
