import BothDoorsClosedSvg from "@/src/assets/icons/icon-both-doors-closed.svg";
import CloseUpSealSvg from "@/src/assets/icons/icon-close-up-seal.svg";
import EmptyIconSvg from "@/src/assets/icons/icon-empty.svg";
import ExtraSvg from "@/src/assets/icons/icon-extra.svg";
import FullLoadedSvg from "@/src/assets/icons/icon-full-loaded.svg";
import HalfContainerSvg from "@/src/assets/icons/icon-half-container.svg";
import OneDoorClosedSvg from "@/src/assets/icons/icon-one-door-closed.svg";
import QuaterSvg from "@/src/assets/icons/icon-quater.svg";
import SampleBaleSvg from "@/src/assets/icons/icon-sample-bale.svg";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import { ContainerMediaType } from "@/src/store/apis/tasks/taskDetail/interface";
import { FontSizeType } from "@/src/styles/typography";
import { TFunction } from "i18next";

const renderContainerIcon = (type?: ContainerMediaType) => {
  let containerIcon = "";

  switch (type) {
    case "SAMPLE_BALE":
      return <Icon iconSrc={SampleBaleSvg} iconSize={18} />;

    case "EMPTY":
      containerIcon = EmptyIconSvg;
      break;

    case "QUARTER_LOADED":
      containerIcon = QuaterSvg;
      break;

    case "HALF_LOADED":
      containerIcon = HalfContainerSvg;
      break;

    case "FULL_LOADED":
      containerIcon = FullLoadedSvg;
      break;

    case "ONE_DOOR_CLOSED":
      containerIcon = OneDoorClosedSvg;
      break;

    case "BOTH_DOORS_CLOSED":
      containerIcon = BothDoorsClosedSvg;
      break;

    case "CLOSE_UP_SEAL":
      containerIcon = CloseUpSealSvg;
      break;

    case "EXTRA":
      containerIcon = ExtraSvg;
      break;
  }

  return <Icon iconSrc={containerIcon} iconSize={24} />;
};

const renderContainerText = (
  t: TFunction,
  type?: ContainerMediaType,
  typoType: FontSizeType = "b7m",
) => {
  let containerTypeText = "";

  switch (type) {
    case "SAMPLE_BALE":
      containerTypeText = t("task:add.photoType.sampleBale");
      break;

    case "EMPTY":
      containerTypeText = t("task:add.photoType.empty");
      break;

    case "QUARTER_LOADED":
      containerTypeText = t("task:add.photoType.quarterLoaded");
      break;

    case "HALF_LOADED":
      containerTypeText = t("task:add.photoType.halfLoaded");
      break;

    case "FULL_LOADED":
      containerTypeText = t("task:add.photoType.fullLoaded");
      break;

    case "ONE_DOOR_CLOSED":
      containerTypeText = t("task:add.photoType.oneDoorClosed");
      break;

    case "BOTH_DOORS_CLOSED":
      containerTypeText = t("task:add.photoType.bothDoorsClosed");
      break;

    case "CLOSE_UP_SEAL":
      containerTypeText = t("task:add.photoType.closeUpSeal");
      break;

    case "EXTRA":
      containerTypeText = t("task:add.photoType.extra");
      break;
  }

  return <Typo typoType={typoType}>{containerTypeText}</Typo>;
};

export { renderContainerIcon, renderContainerText };
