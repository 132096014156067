import RangePicker from "@/src/components/atom/RangePicker";
import Typo from "@/src/components/atom/Typo";
import TabItem from "@/src/components/molecule/TabItem";
import { authApi } from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import dayjs, { Dayjs } from "dayjs";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BuyerStatistics from "./BuyerStatistics";
import CategoryStatistics from "./CategoryStatistics";

const mainCategoryEmptyArray: any[] = [];
const emptyArray: any[] = [];

type TabType = "buyer" | "category";

const today = dayjs();

const Statistics = () => {
  const { t } = useTranslation("dashboard");
  const rangePickerRef = useRef<{
    blur: () => void;
    focus: () => void;
  }>(null);
  const [tab, setTab] = useState<TabType>("buyer");

  const [periodInBuyer, setPeriodInBuyer] = useState<[Dayjs, Dayjs]>([
    today.clone().subtract(1, "month"),
    today.clone().subtract(1, "day"),
  ]);
  const [tempPeriodInBuyer, setTempPeriodInBuyer] = useState<[Dayjs, Dayjs]>([
    today.clone().subtract(1, "month"),
    today.clone().subtract(1, "day"),
  ]);
  const [periodInCategory, setPeriodInCategory] = useState<[Dayjs, Dayjs]>([
    today.clone().subtract(1, "month"),
    today.clone().subtract(1, "day"),
  ]);
  const [tempPeriodInCategory, setTempPeriodInCategory] = useState<
    [Dayjs, Dayjs]
  >([today.clone().subtract(1, "month"), today.clone().subtract(1, "day")]);

  const [tabInBuyer, setTabInBuyer] = useState<"item" | "itemCode">("item");
  const [tabInCategory, setTabInCategory] = useState<"buyer" | "itemCode">(
    "buyer",
  );
  const [categoryInBuyer, setCategoryInBuyer] = useState<string>("ALL");
  const [categoryInCategory, setCategoryInCategory] = useState<string>("ALL");

  /**
   * api
   */
  const { mainCategoryList } = authApi.endpoints.getSession.useQueryState(
    undefined,
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isErrorAndUndefined =
          isError || isFetching || currentData === undefined;

        const isManager =
          currentData?.row.exporterUserType === "MANAGER" ||
          currentData?.row.exporterUserType === "MIDDLE_MANAGER";

        return {
          mainCategoryList: !isErrorAndUndefined
            ? isManager
              ? currentData.row.mainCategoryCodeItemNames
              : currentData.row.exporter.mainCategoryCodeItemNames
            : mainCategoryEmptyArray,
        };
      },
    },
  );
  const { isMainCategoryFetching, mainCategoryCurrentData } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategoryCurrentData: !isErrorAndUndefined
              ? (currentData ?? emptyArray)
              : emptyArray,
          };
        },
      },
    );

  const mainCategoryOptionList = mainCategoryCurrentData
    ?.filter((item) => mainCategoryList.includes(item.codeItemName))
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  const renderTabContent = () => {
    if (tab === "buyer") {
      return (
        <BuyerStatistics
          tab={tabInBuyer}
          category={categoryInBuyer}
          isCategoryFetching={isMainCategoryFetching}
          categoryList={[
            { label: t("common:all"), value: "ALL" },
            ...mainCategoryOptionList,
          ]}
          onCategoryChange={setCategoryInBuyer}
          onTabChange={setTabInBuyer}
          period={periodInBuyer}
        />
      );
    }
    if (tab === "category") {
      return (
        <CategoryStatistics
          tab={tabInCategory}
          category={categoryInCategory}
          isCategoryFetching={isMainCategoryFetching}
          categoryList={[
            { label: t("common:all"), value: "ALL" },
            ...mainCategoryOptionList,
          ]}
          onCategoryChange={setCategoryInCategory}
          onTabChange={setTabInCategory}
          period={periodInCategory}
        />
      );
    }
  };

  return (
    <>
      <SpaceBetween>
        <Tabs role="tablist">
          <StyledTabItem
            tabValue={"buyer"}
            tabIndex={tab === "buyer" ? 0 : -1}
            onFocus={() => setTab("buyer")}
            onClick={() => setTab("buyer")}
            data-selected={tab === "buyer"}
          >
            <Typo typoType="b7m">{t("exporter.tabs.buyer")}</Typo>
          </StyledTabItem>
          <StyledTabItem
            tabValue={"category"}
            tabIndex={tab === "category" ? 0 : -1}
            onFocus={() => setTab("category")}
            onClick={() => setTab("category")}
            data-selected={tab === "category"}
          >
            <Typo typoType="b7m">{t("exporter.tabs.category")}</Typo>
          </StyledTabItem>
        </Tabs>

        <StyledRangePicker
          ref={rangePickerRef}
          value={tab === "buyer" ? periodInBuyer : periodInCategory}
          onChange={(value) => {
            if (tab === "buyer") {
              setPeriodInBuyer(value as [Dayjs, Dayjs]);
            } else {
              setPeriodInCategory(value as [Dayjs, Dayjs]);
            }
          }}
          onCalendarChange={(values) => {
            if (values !== null) {
              setTempPeriodInBuyer(values as [Dayjs, Dayjs]);
            }
          }}
          presets={[]}
          dropdownClassName="custom-range-picker-header"
          renderExtraFooter={() => {
            return (
              <>
                <PresetHeader>
                  <Typo as="p" typoType="h6">
                    {(tab === "buyer"
                      ? tempPeriodInBuyer[0]
                      : tempPeriodInCategory[0]
                    )?.format("YY.MM.DD") || ""}{" "}
                    ~{" "}
                    {(tab === "buyer"
                      ? tempPeriodInBuyer[1]
                      : tempPeriodInCategory[1]
                    )?.format("YY.MM.DD") || ""}
                  </Typo>
                </PresetHeader>

                <PresetContent>
                  {[
                    {
                      label: t("common:duration.oneWeek"),
                      from: today.clone().subtract(1 + 7, "day"),
                      to: today.clone().subtract(1, "day"),
                    },
                    {
                      label: t("common:duration.twoWeeks"),
                      from: today.clone().subtract(1 + 14, "day"),
                      to: today.clone().subtract(1, "day"),
                    },
                    {
                      label: t("common:duration.oneMonth"),
                      from: today.clone().subtract(1, "month"),
                      to: today.clone().subtract(1, "day"),
                    },
                    {
                      label: t("common:duration.threeMonths"),
                      from: today.clone().subtract(3, "month"),
                      to: today.clone().subtract(1, "day"),
                    },
                    {
                      label: t("common:duration.sixMonths"),
                      from: today.clone().subtract(6, "month"),
                      to: today.clone().subtract(1, "day"),
                    },
                    {
                      label: t("common:duration.oneYear"),
                      from: today.clone().subtract(1, "year"),
                      to: today.clone().subtract(1, "day"),
                    },
                  ].map(({ label, from, to }) => {
                    return (
                      <button
                        onClick={() => {
                          const fromTo: [Dayjs, Dayjs] = [from, to];
                          if (tab === "buyer") {
                            setTempPeriodInBuyer(fromTo);
                            setPeriodInBuyer(fromTo);
                          } else {
                            setPeriodInCategory(fromTo);
                            setTempPeriodInCategory(fromTo);
                          }
                          if (rangePickerRef.current) {
                            rangePickerRef.current?.blur?.();
                          }
                        }}
                      >
                        {label}
                      </button>
                    );
                  })}
                </PresetContent>
              </>
            );
          }}
        />
      </SpaceBetween>

      {renderTabContent()}
    </>
  );
};

export default Statistics;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${colorSet.gray10};
  margin-bottom: 16px;
  width: 100%;
`;

const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border: none;
  cursor: pointer;
  background: none;

  &[data-selected="true"] {
    padding: 10px 13px 8px 13px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const StyledRangePicker = styled(RangePicker)`
  padding: 4px 11px 4px;
  align-self: flex-start;
  position: absolute;
  right: 0;
`;

const PresetHeader = styled.div`
  padding: 12px 16px;
  text-align: center;
`;

const PresetContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  button {
    all: unset;
    text-align: center;
    cursor: pointer;
    border: 0.5px solid ${colorSet.gray9};
    ${typo.b9m};
    padding: 8px 16px;

    &:hover {
      background: ${colorSet.gray9};
    }
  }
`;
