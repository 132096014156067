import Logo from "@/src/assets/logos/logo-export.svg";
import Typo from "@/src/components/atom/Typo";
import PublicFooter from "@/src/components/molecule/PublicFooter";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface CertificationCodeLayoutProps {
  title: string;
}

function CertificationCodeLayout({
  children,
  title,
}: PropsWithChildren<CertificationCodeLayoutProps>) {
  const { t } = useTranslation();

  return (
    <Main>
      <EcoyaLogo src={Logo} alt="logo" />

      <Article>
        <Card>
          <TitleContainer>
            <Typo typoType="d6">{title}</Typo>
            <Typo typoType="b7m">{t("common:enterCertificationCode")}</Typo>
          </TitleContainer>
          {children}
        </Card>

        <PublicFooter />
      </Article>
    </Main>
  );
}

export default CertificationCodeLayout;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1250px;
  height: 100vh;
  gap: 40px;
  margin: 0 auto;
`;

const EcoyaLogo = styled.img`
  width: 156px;
  height: 24px;
  margin: 32px auto;
`;

const Card = styled(SectionCardWithoutHeader)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 576px;
  padding: 48px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
