import dayjs, { Dayjs } from "dayjs";

// ATA
// 선택된 날짜 기준의 -12개월을 하여 속한 개월의 배열 가져오기 및 fieldName 합산
export const getMonthlyChartDataSums = <
  T extends { ataDateAt?: string } & Record<string, any>,
>({
  list,
  endDate,
  fieldName,
}: {
  list: T[];
  endDate: Dayjs;
  fieldName: string;
}): number[] => {
  const monthlySums = new Array(12).fill(0);
  const startDate = endDate?.subtract(11, "month").startOf("month");

  list.forEach((item) => {
    const ataDateAt = dayjs(item.ataDateAt).startOf("month");
    // 범위시작일, 범위종료일, 시작일과 종료일을 포함할지 여부, 범위를 열거나 닫을지 여부
    if (ataDateAt.isBetween(startDate, ataDateAt, null, "[]")) {
      const monthIndex = ataDateAt.diff(startDate, "month");
      if (monthIndex >= 0 && monthIndex < 12) {
        monthlySums[monthIndex] += item[fieldName];
      }
    }
  });

  return monthlySums;
};

// 시작날짜, 끝날짜를 기준으로 하여 속한 배열 가져오기 및 fieldName 합산
export const getDailyChartDataSums = <
  T extends { ataDateAt?: string } & Record<string, any>,
>({
  list,
  startDate,
  endDate,
  fieldName,
}: {
  list: T[];
  startDate: Dayjs;
  endDate: Dayjs;
  fieldName: string;
}): number[] => {
  const daysDiff = endDate.diff(startDate, "day") + 1;

  // 만약 시작일과 종료일이 같다면 하나의 값만 합산하여 반환
  if (daysDiff === 1) {
    const sum = list.reduce((acc, item) => {
      const ataDateAt = dayjs(item.ataDateAt);
      if (ataDateAt.isSame(startDate, "day")) {
        return acc + item[fieldName];
      }
      return acc;
    }, 0);
    return [sum];
  }

  // 시작일과 종료일이 다를 경우, 기존 로직 사용
  const dailySums = new Array(daysDiff).fill(0);

  list.forEach((item) => {
    const ataDateAt = dayjs(item.ataDateAt);

    if (ataDateAt.isBetween(startDate, endDate, null, "[)")) {
      const dayIndex = ataDateAt.diff(startDate, "day");
      if (dayIndex >= 0 && dayIndex < daysDiff) {
        dailySums[dayIndex] += item[fieldName];
      }
    } else if (ataDateAt.isSame(endDate, "day")) {
      // 마지막 날짜 데이터 처리
      dailySums[daysDiff - 1] += item[fieldName];
    }
  });

  return dailySums;
};

// ETA
// 시작날짜, 끝날짜를 기준으로 하여 속한 배열 가져오기 및 fieldName 합산
export const getEtaDailyChardDataSums = <
  T extends { etaDateAt?: string } & Record<string, any>,
>({
  list,
  startDate,
  endDate,
  fieldName,
}: {
  list: T[];
  startDate: Dayjs;
  endDate: Dayjs;
  fieldName: string;
}): number[] => {
  const daysDiff = endDate.diff(startDate, "day") + 1;

  // 만약 시작일과 종료일이 같다면 하나의 값만 합산하여 반환
  if (daysDiff === 1) {
    const sum = list.reduce((acc, item) => {
      const etaDateAt = dayjs(item.etaDateAt);
      if (etaDateAt.isSame(startDate, "day")) {
        return acc + item[fieldName];
      }
      return acc;
    }, 0);
    return [sum];
  }

  // 시작일과 종료일이 다를 경우, 기존 로직 사용
  const dailySums = new Array(daysDiff).fill(0);

  list.forEach((item) => {
    const etaDateAt = dayjs(item.etaDateAt);

    if (etaDateAt.isBetween(startDate, endDate, null, "[]")) {
      const dayIndex = etaDateAt.diff(startDate, "day");
      if (dayIndex >= 0 && dayIndex < daysDiff) {
        dailySums[dayIndex] += item[fieldName];
      }
    }
  });

  return dailySums;
};
