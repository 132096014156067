import Badge from "@/src/components/atom/Badge";
import { DocumentStatusType } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { ShipmentStatusType } from "@/src/store/apis/shipments/shipmentList/interface";
import { TFunction } from "i18next";

export const renderShipmentStatusBadge = (param: {
  t: TFunction;
  type: ShipmentStatusType | null;
  size?: "S" | "L";
}) => {
  const { t, type, size = "S" } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    case "BEFORE_REGISTRATION":
      return (
        <Badge
          text={t("shipment:exporter.list.badge.beforeRegistration")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );
    case "CI_PL_WRITING":
      return (
        <Badge
          text={t("shipment:exporter.list.badge.ciPlWriting")}
          color="systemLime1"
          badgeColor="systemLime6"
          badgeSize={size}
        />
      );
    case "BL_WRITING":
      return (
        <Badge
          text={t("shipment:exporter.list.badge.blWriting")}
          color="systemGrape2"
          badgeColor="systemGrape6"
          badgeSize={size}
        />
      );

    case "TEMPORARY_REGISTER":
      return (
        <Badge
          text={t("shipment:exporter.list.badge.temporaryRegister")}
          color="systemOrange2"
          badgeColor="systemOrange6"
          badgeSize={size}
        />
      );

    case "CI_PL_COMPLETED":
      return (
        <Badge
          text={t("shipment:exporter.list.badge.ciPlCompleted")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );

    case "COMPLETED":
      return (
        <Badge
          text={t("shipment:exporter.list.badge.completed")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};

export const renderDocumentStatusBadge = (param: {
  t: TFunction;
  type: DocumentStatusType;
  size?: "S" | "L";
}) => {
  const { t, type, size = "S" } = param;

  switch (type) {
    case "BEFORE_REGISTRATION":
      return (
        <Badge
          text={t("shipment:exporter.detail.badge.beforeRegistration")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );

    case "TEMPORARY_REGISTER":
      return (
        <Badge
          text={t("shipment:exporter.detail.badge.temporaryRegister")}
          color="systemOrange2"
          badgeColor="systemOrange6"
          badgeSize={size}
        />
      );

    case "DRAFT":
      return (
        <Badge
          text={t("shipment:exporter.detail.badge.draft")}
          color="systemPink2"
          badgeColor="systemPink6"
          badgeSize={size}
        />
      );

    case "COMPLETED":
      return (
        <Badge
          text={t("shipment:exporter.detail.badge.completed")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );

    case "FINAL":
      return (
        <Badge
          text={t("shipment:exporter.detail.badge.final")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};
