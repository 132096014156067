import { ReactComponent as DeleteSvg } from "@/src/assets/icons/icon-cancle-red.svg";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down-gray7.svg";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import ChevronRightSvg from "@/src/assets/icons/icon-chevron-right-white.svg";
import { ReactComponent as ClockSvg } from "@/src/assets/icons/icon-clock-gray5.svg";
import DotGray4Svg from "@/src/assets/icons/icon-dot-gray4.svg";
import DotGray7Svg from "@/src/assets/icons/icon-dot-gray7.svg";
import EditSvg from "@/src/assets/icons/icon-edit-white.svg";
import { ReactComponent as MailSvg } from "@/src/assets/icons/icon-mail-blue.svg";
import Badge from "@/src/components/atom/Badge";
import { Button } from "@/src/components/atom/Button";
import DropDown from "@/src/components/atom/DropDown";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import EmailHistoryDialog from "@/src/components/organism/EmailHistoryDialog";
import ShareableContractDialog from "@/src/components/organism/ShareableContractDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppSelector } from "@/src/store";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCancelBookingDetailMutation,
  useGetBookingDetailBookmarkQuery,
  useGetBookingDetailBookmarkUserQuery,
  useGetBookingDetailQuery,
  useGetBookingTaskSummaryInfoQuery,
  useUpdateBookingDetailBookmarkMutation,
} from "@/src/store/apis/bookings/bookingDetail";
import { SimpleContractDto } from "@/src/store/apis/bookings/bookingDetail/interface";
import { BookingBookmarkListViewDto } from "@/src/store/apis/bookings/bookingList/interface";
import { useLazyGetEmailShareHistoriesQuery } from "@/src/store/apis/emailShareHistories";
import { useShareBookingMutation } from "@/src/store/apis/sharing";
import { useGetShipmentListQuery } from "@/src/store/apis/shipments/shipmentList";
import { ShipmentListViewDto } from "@/src/store/apis/shipments/shipmentList/interface";
import { useGetTaskListQuery } from "@/src/store/apis/tasks/taskList";
import { TaskListViewDto } from "@/src/store/apis/tasks/taskList/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { isNull, isUndefined } from "@/src/utils/is";
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import LoadContractDialog from "../Task/components/add/dialog/LoadContractDialog";
import BookingCard from "./components/detail/card/BookingCard";
import BookingInformation from "./components/detail/card/BookingInformation";
import ShipmentList from "./components/detail/card/ShipmentList";
import SimpleContract from "./components/detail/card/SimpleContract";
import TaskList from "./components/detail/card/TaskList";
import CancelBookingAlertDialog from "./components/detail/dialog/CancelBookingAlertDialog";
import EmailDialog from "./components/detail/dialog/EmailDialog";
import renderBookingStatusBadge from "./utils/renderBookingStatusBadge";

enum DialogState {
  NULL,
  SEND_EMAIL,
  SENT_HISTORY,
  BOOK_MARK,
  LOAD_CONTRACT,
  SHARE,
}

const loadingListEmptyArray: TaskListViewDto[] = [];
const shipmentEmptyArray: ShipmentListViewDto[] = [];
const bookmarkEmptyArray: BookingBookmarkListViewDto[] = [];
const simpleContractEmptyArray: SimpleContractDto[] = [];

function ExporterBookingDetailPage() {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const loadingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const subscription = useAppSelector((state) => state.subscription);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  // API
  const [shareBooking] = useShareBookingMutation();
  const { bookingDetailWithLoadingData } = useGetTaskListQuery(
    {
      bookingId: Number(params.id),
      page: 1,
      pageSize: 10,
      isCancelTaskRequestExist: false,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookingDetailWithLoadingData: !isUnstable
            ? currentData.rows
            : loadingListEmptyArray,
        };
      },
    },
  );
  const { bookingDetailWithShipmentData } = useGetShipmentListQuery(
    {
      bookingId: Number(params.id),
      page: 1,
      pageSize: 10,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookingDetailWithShipmentData: !isUnstable
            ? currentData.rows
            : shipmentEmptyArray,
        };
      },
    },
  );

  const {
    exporterUserType,
    userMainCategory,
    exporterUserMainFieldType,
    exporterUserId,
  } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        userMainCategory: isStable
          ? currentData.row.mainCategoryCodeItemNames
          : [],
        exporterUserType: currentData?.row.exporterUserType,
        exporterUserMainFieldType: currentData?.row.exporterUserMainFieldType,
        exporterUserId: currentData?.row.id,
      };
    },
  });

  const {
    isBookingError,
    simpleContracts,
    bookingNo,
    bookingStatus,
    bookingDataId,
    bookingCreatedAt,
    bookingUpdatedAt,
    bookingWriterName,
    bookingLastEditorName,
    isBookingDetailFetching,
    hasSharedAt,
    isUnstable,
    bookingWriterId,
    refetch: getBookingDetail,
  } = useGetBookingDetailQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          isUnstable,
          isBookingError: isError,
          isBookingDetailFetching: isFetching,
          bookingWriterId: isStable ? currentData.bookingWriterId : null,
          simpleContracts: isStable
            ? (currentData.simpleContracts ?? simpleContractEmptyArray)
            : simpleContractEmptyArray,
          bookingNo: isStable ? (currentData.bookingNo ?? "-") : "-",
          bookingStatus: isStable ? currentData.bookingStatus : null,
          bookingDataId: isStable ? currentData.id : "",
          bookingCreatedAt:
            isStable && currentData.createdAt
              ? `(${dayjs(currentData.createdAt).format(
                  DATE_FORMAT_STRINGS.YY_MM_DD,
                )},${dayjs(currentData.createdAt).format(
                  DATE_FORMAT_STRINGS.HH_mm,
                )})`
              : "-",
          bookingUpdatedAt:
            isStable && currentData.updatedAt
              ? `(${dayjs(currentData.updatedAt).format(
                  DATE_FORMAT_STRINGS.YY_MM_DD,
                )},${dayjs(currentData.updatedAt).format(
                  DATE_FORMAT_STRINGS.HH_mm,
                )})`
              : "-",
          bookingWriterName: isStable
            ? (currentData.bookingWriterName ?? "-")
            : "-",
          bookingLastEditorName: isStable
            ? (currentData?.bookingLastEditorName ?? "-")
            : "-",
          hasSharedAt: isStable ? !isNull(currentData.sharedAt) : true,
        };
      },
    },
  );

  const { isBookmarked } = useGetBookingDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return { isBookmarked: currentData?.isBookmarked };
      },
    },
  );
  const { bookmarkUserList } = useGetBookingDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookmarkUserList: !isUnstable ? currentData.rows : bookmarkEmptyArray,
        };
      },
    },
  );
  useGetBookingTaskSummaryInfoQuery(
    { id: Number(params.id) },
    { refetchOnMountOrArgChange: true },
  );
  const [updateBookingDetailBookmark] =
    useUpdateBookingDetailBookmarkMutation();
  const [cancelBookingDetail] = useCancelBookingDetailMutation();
  const [
    getBookingSharedHistories,
    { count, isFetching, emailHistoryList, isEmailShareHistoriesError },
  ] = useLazyGetEmailShareHistoriesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isFetching,
        count: !isUnstable ? currentData.count : 0,
        emailHistoryList: !isUnstable ? currentData.rows : undefined,
        isEmailShareHistoriesError: isError,
      };
    },
  });

  // state
  const [isCancelBookingAlertOpen, setIsCancelBookingAlertOpen] =
    useState(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  const isEditAuth = useMemo(() => {
    if (isBookingError) {
      return false;
    }

    switch (bookingStatus) {
      case "TEMPORARY_BOOKING":
        /**
         * 가부킹 상태
         * - 부킹 생성자, 기업관리자만 수정 가능
         */
        return (
          exporterUserType === "CORPORATE_MANAGER" ||
          exporterUserId === bookingWriterId
        );

      case "CANCELED_BOOKING":
        /**
         * 취소 상태
         * - 수정 버튼 미노출
         */
        return false;
      default:
        return true;
    }
  }, [
    bookingStatus,
    bookingWriterId,
    exporterUserId,
    exporterUserType,
    isBookingError,
  ]);

  const isTemporaryBooking = bookingStatus === "TEMPORARY_BOOKING";
  const isCanceledBooking = bookingStatus === "CANCELED_BOOKING";

  const isCancelButtonDisabled =
    bookingDetailWithLoadingData.length >= 1 ||
    bookingDetailWithShipmentData.length >= 1;

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };

      await updateBookingDetailBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleBookingCancelClick = async () => {
    try {
      await cancelBookingDetail({ bookingId: Number(params.id) }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("booking:detail.alert.cancelBooking"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setIsCancelBookingAlertOpen(false);
    }
  };

  // 작업생성 버튼 클릭시
  const handleLoadingCreateClick = () => {
    if (!simpleContracts) {
      return;
    }

    // 계약이 하나인경우 작업생성 페이지로 라우팅
    if (simpleContracts.length === 1) {
      return navigate(
        `${EXPORTER_PRIVATE_PATH.LOADING_ADD}?bookingId=${Number(
          params.id,
        )}&contractId=${Number(simpleContracts[0].id)}`,
      );
    }

    // 매니저 타입인지
    if (exporterUserType === "MANAGER") {
      const managerTypeSimpleContracts = simpleContracts.filter((item) =>
        userMainCategory.includes(item.mainCategory),
      );

      // 부킹내 계약이 다수이지만, 매니저 타입이면서 담당하고 있는 카테고리의 계약이 하나인 경우 작업생성 페이지로 라우팅
      if (managerTypeSimpleContracts.length === 1) {
        return navigate(
          `${EXPORTER_PRIVATE_PATH.LOADING_ADD}?bookingId=${Number(
            params.id,
          )}&contractId=${Number(managerTypeSimpleContracts[0].id)}`,
        );
      }

      // 매니저 타입이면서 담당하고 있는 카테고리의 계약이 다수인 경우 담당하고 있는 계약건만 모달에서 보여줌
      if (managerTypeSimpleContracts.length >= 2) {
        return setDialogState(DialogState.LOAD_CONTRACT);
      }
    } else {
      // 중간관리자, 기업관리자는 계약이 다수인 경우 모달에서 계약건 모두 보여줌
      if (simpleContracts.length >= 2) {
        return setDialogState(DialogState.LOAD_CONTRACT);
      }
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }

    if (dialogState === DialogState.SEND_EMAIL) {
      return (
        <EmailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.LOAD_CONTRACT) {
      return (
        <LoadContractDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
          bookingId={Number(params.id)}
          onSelect={(detail) => {
            navigate(
              `${EXPORTER_PRIVATE_PATH.LOADING_ADD}?bookingId=${Number(
                params.id,
              )}&contractId=${detail?.id}`,
            );
          }}
        />
      );
    }

    if (dialogState === DialogState.SENT_HISTORY) {
      return (
        <EmailHistoryDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isFetching={isFetching}
          isError={isEmailShareHistoriesError}
          emailHistoryList={emailHistoryList}
          totalCount={count}
          fetch={getBookingSharedHistories}
          emailShareDomain={"BOOKING"}
          emailShareDomainId={Number(params.id)}
        />
      );
    }

    if (dialogState === DialogState.SHARE) {
      return (
        <ShareableContractDialog
          id={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
          onShare={async () => {
            try {
              setDialogState(DialogState.NULL);
              await shareBooking({
                bookingId: Number(params.id),
              }).unwrap();

              await getBookingDetail().unwrap();

              alert.showAlert({
                type: "success",
                message: t("booking:detail.alert.sharingAlert"),
              });
            } catch (e: any) {
              const message = Array.isArray(e.data?.message)
                ? e.data?.message[0]
                : e.data?.message;
              alert.showAlert({
                type: "error",
                message: message ?? t("alert:unHandled"),
              });
            }
          }}
          type="booking"
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (isCancelBookingAlertOpen) {
      return (
        <CancelBookingAlertDialog
          open={isCancelBookingAlertOpen}
          onOpenChange={setIsCancelBookingAlertOpen}
          onOk={handleBookingCancelClick}
        />
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    // 권한이 없을 경우에 보여준다, 서버 http Status 404를 받음
    if (isBookingError) {
      return (
        <NoDataContainer>
          <UnauthorizedDescription />
        </NoDataContainer>
      );
    }
  };

  const renderBookingDetailContent = () => {
    if (isUnstable) {
      return;
    }

    return (
      <>
        <FlexColumn>
          {/* Header */}
          <HeaderContainer>
            <HeaderButtonContainer>
              {renderBookingStatusBadge({
                type: bookingStatus,
                size: "L",
                t,
              })}
              {bookingDetailWithLoadingData &&
              bookingDetailWithLoadingData.length >= 2 ? (
                <Badge
                  badgeType="line"
                  color="systemGrape3"
                  borderColor="systemGrape3"
                  text={t("status:booking.split")}
                />
              ) : null}
            </HeaderButtonContainer>

            <HeaderButtonContainer>
              {/* BookMark */}
              <BookMarkStatus
                bookMarkStatus={isBookmarked}
                bookMarkCount={bookmarkUserList.length}
                onClickBookMark={handleBookmarkClick}
                onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
              />
              {!isTemporaryBooking && !isCanceledBooking && (
                <Button
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  disabled={hasSharedAt}
                  onClick={() => setDialogState(DialogState.SHARE)}
                >
                  {t("booking:detail.button.shareButton")}
                </Button>
              )}

              {/* Email */}
              {!isTemporaryBooking && (
                <DropDown
                  dropdownContentProps={{ align: "end" }}
                  dropdownTrigger={
                    <StyledButton
                      buttonSize={32}
                      buttonColor="blue"
                      buttonGrade="secondary"
                    >
                      <MailIcon size={16} color="indigo" />
                      {t("booking:detail.button.emailButton")}
                      <ArrowDownIcon />
                    </StyledButton>
                  }
                >
                  <StyledSectionCardWithoutHeader>
                    <EmailDropDownList>
                      {!isCanceledBooking && (
                        <EmailDropDownItem
                          onSelect={() =>
                            setDialogState(DialogState.SEND_EMAIL)
                          }
                        >
                          <MailIcon size={18} color="gray2" />
                          <Typo>
                            {t("booking:detail.button.sendEmailButton")}
                          </Typo>
                        </EmailDropDownItem>
                      )}

                      <EmailDropDownItem
                        onSelect={() =>
                          setDialogState(DialogState.SENT_HISTORY)
                        }
                      >
                        <ClockIcon />
                        <Typo>
                          {t("booking:detail.button.sentHistoryButton")}
                        </Typo>
                      </EmailDropDownItem>
                    </EmailDropDownList>
                  </StyledSectionCardWithoutHeader>
                </DropDown>
              )}

              {/* Edit */}
              {isEditAuth && (
                <StyledButton
                  buttonSize={32}
                  onClick={() =>
                    navigate(
                      `${EXPORTER_PRIVATE_PATH.BOOKING_EDIT}/${bookingDataId}`,
                    )
                  }
                >
                  <Icon iconSrc={EditSvg} iconSize={16} />
                  {t("booking:detail.button.editButton")}
                </StyledButton>
              )}
            </HeaderButtonContainer>
          </HeaderContainer>

          {/* Booking Contents */}
          <BookingCard />
          <BookingInformation />
          {simpleContracts?.map((data, idx) => {
            return (
              <SimpleContract key={idx.toString()} index={idx} data={data} />
            );
          })}
          {/* Table List */}
          {!isTemporaryBooking && (
            <>
              <TaskList />
              <ShipmentList />
            </>
          )}

          {/* Last Editor */}
          <EditorContainer>
            <figure>
              <Icon iconSrc={DotGray7Svg} iconSize={4} />
              <Typo color="gray6" typoType="b9r">
                {`${t("booking:detail.editor.registratant")} (${t(
                  "booking:detail.editor.registratantAt",
                )}) ${bookingWriterName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {bookingCreatedAt}
              </Typo>
            </figure>
            <figure>
              <Icon iconSrc={DotGray4Svg} iconSize={4} />
              <Typo color="gray4" typoType="b9r">
                {`${t("booking:detail.editor.lastEditor")} (${t(
                  "booking:detail.editor.lastEditorAt",
                )}) ${bookingLastEditorName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {bookingUpdatedAt}
              </Typo>
            </figure>
          </EditorContainer>
        </FlexColumn>

        {/* Bottom Fixed */}
        <BottomFixedContainer>
          <BottomButtonContainer>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeft} iconSize={16} />
              {t("booking:detail.button.backToPreviousButton")}
            </StyledButton>

            {isEditAuth && (
              <figure>
                <StyledButton
                  buttonColor="red"
                  buttonGrade="secondary"
                  onClick={() => setIsCancelBookingAlertOpen(true)}
                  disabled={isCancelButtonDisabled || isCreateDisabled}
                >
                  <DeleteIcon
                    disabled={isCancelButtonDisabled || isCreateDisabled}
                  />
                  {t("booking:detail.button.cancelBookingButton")}
                </StyledButton>

                <StyledButton
                  onClick={handleLoadingCreateClick}
                  disabled={
                    !(bookingStatus === "CONFIRMED_BOOKING") || isCreateDisabled
                  }
                >
                  {t("booking:detail.button.createLoadingButton")}
                  <Icon iconSrc={ChevronRightSvg} iconSize={16} />
                </StyledButton>
              </figure>
            )}
          </BottomButtonContainer>
        </BottomFixedContainer>
      </>
    );
  };

  useEffect(() => {
    if (isBookingDetailFetching) {
      const setTimeoutId = setTimeout(() => {
        handleContentLoadingOn();
      }, 300);

      loadingTimeoutId.current = setTimeoutId;
    } else {
      if (loadingTimeoutId.current) {
        clearTimeout(loadingTimeoutId.current);
      }
      handleContentLoadingOff();
    }
  }, [
    isBookingDetailFetching,
    handleContentLoadingOff,
    handleContentLoadingOn,
  ]);

  return (
    <ExporterMainLayout
      breadcrumb={
        !isUnstable ? [t("sideNav:booking"), t("sideNav:bookingDetail")] : []
      }
      pageTitle={
        !isUnstable && `${t("booking:detail.header.title")} ${bookingNo}`
      }
    >
      {/* Dialogs , AlertDialogs */}
      {renderDialogs()}
      {renderAlertDialog()}
      {/* Contents */}
      {renderBookingDetailContent()}
      {renderUnauthorizedDescription()}
    </ExporterMainLayout>
  );
}

export default ExporterBookingDetailPage;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    display: flex;
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  figure {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const DeleteIcon = styled(DeleteSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.red6};
      `}
  }
`;

const EmailDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;
const EmailDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const MailIcon = styled(MailSvg)<{ size: number; color: ColorType }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
const ArrowDownIcon = styled(ArrowDownSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.indigo};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
const ClockIcon = styled(ClockSvg)`
  width: 18px;
  height: 18px;

  path {
    fill: ${colorSet.gray2};
  }
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
