import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

interface CompanyItemInitialState {
  itemType: "item" | "itemCode";
}

const initialState: CompanyItemInitialState = {
  itemType: "item",
};

export const companyItemSlice = createSlice({
  name: "companyItem",
  initialState,
  reducers: {
    setItemType: (
      state,
      { payload }: PayloadAction<CompanyItemInitialState["itemType"]>,
    ) => {
      state.itemType = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export default companyItemSlice.reducer;
export const { setItemType } = companyItemSlice.actions;
