import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import IconButton from "@/src/components/atom/Button/IconButton";
import Icon from "@/src/components/atom/Icon";
import RadixTooltip from "@/src/components/atom/Tooltip/RadixTooltip";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import colorSet from "@/src/styles/color";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function BookingInfoTooltip() {
  const { t } = useTranslation();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <RadixTooltip
      open={isTooltipOpen}
      onOpenChange={setIsTooltipOpen}
      contentProps={{
        side: "bottom",
      }}
      arrowProps={{ style: { opacity: 0.8 } }}
      arrowColor="gray1"
      content={
        <StyledTooltip>
          <BreakWordTypo color="white" typoType="b10m">
            {t("shipment:exporter.detail.tooltip.bookingInfo")}
          </BreakWordTypo>
        </StyledTooltip>
      }
    >
      <IconButton>
        <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
      </IconButton>
    </RadixTooltip>
  );
}

export default BookingInfoTooltip;

const StyledTooltip = styled.div`
  background: ${colorSet.gray1};
  white-space: pre-wrap;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0.8;
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  cursor: pointer;
`;
