import colorSet from "@/src/styles/color";
import zIndex from "@/src/styles/zIndex";
import {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";

function BottomFixedContainer({ children }: PropsWithChildren) {
  const navRef = useRef<HTMLElement | null>(null);
  const [right, setRight] = useState<number>();

  useLayoutEffect(() => {
    const nav = document.querySelector("nav");
    navRef.current = nav;
    setRight(navRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const listener = () => {
      setRight(navRef.current?.getBoundingClientRect().right);
    };
    window?.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <BottomContainer $bottomStartPoint={right}>{children}</BottomContainer>
  );
}

export default BottomFixedContainer;

const BottomContainer = styled.div<{ $bottomStartPoint?: number }>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: ${({ $bottomStartPoint }) => `${$bottomStartPoint}px`};
  width: calc(100% - 256px);
  padding: 16px 40px;
  background: ${colorSet.white};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  z-index: ${zIndex.bottomFixedButtons};
  min-width: 996px;
`;
