import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import InfoSvg from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import Badge from "@/src/components/atom/Badge";
import RectangularBadge from "@/src/components/atom/Badge/RectangularBadge";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import TabItem from "@/src/components/molecule/TabItem";
import useAlert from "@/src/hooks/useAlert";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppDispatch } from "@/src/store";
import {
  subscriptionApi,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import {
  PaymentCycleType,
  PlanDto,
  PlanVersionDto,
} from "@/src/store/apis/subscription/interface";
import { changeAnnuallySubscription } from "@/src/store/slice/subscription";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { convertPaymentTypeToString } from "../../../NormalPlanSignup/utils";
import PlanChangeConfirmAlertDialog from "./PlanChangeConfirmAlertDialog";

interface PlanChangeDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CHANGE_CONFIRM,
}

const TAB_LIST: PaymentCycleType[] = ["MONTHLY", "ANNUALLY"];

function PlanChangeDialog({ open, onOpenChange }: PlanChangeDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [selectPlan, setSelectPlan] = useState<PlanDto | null>(null);
  const [selectPaymentCycle, setSelectPaymentCycle] =
    useState<PaymentCycleType | null>(null);

  // API
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const { plans, isPlansIsFetching } =
    subscriptionApi.endpoints.getPlans.useQueryState(
      { planType: "NORMAL" },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isPlansIsFetching: isFetching,
            plans: isStable ? currentData.rows : [],
          };
        },
      },
    );
  const {
    subscriptionId,
    paymentCycle,
    currentPlan,
    currentPlanVersion,
    subscriptionEndAtTo,
    subscriptionType,
  } = subscriptionApi.endpoints.getSubscriptions.useQueryState(
    {
      page: 1,
      pageSize: 10,
    },
    {
      selectFromResult: ({ currentData }) => {
        return {
          subscriptionId: currentData?.rows?.[0].id,
          subscriptionType: currentData?.rows?.[0].subscriptionType,
          subscriptionEndAtTo: currentData?.rows?.[0].subscriptionEndAtTo,
          currentPlan: currentData?.rows?.[0].plan,
          currentPlanVersion: currentData?.rows?.[0].planVersion,
          paymentCycle: currentData?.rows?.[0].paymentCycle,
        };
      },
    },
  );

  const goToEnterpriseInquiryPage = () => {
    navigate(`${EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP}?isInquiry=true`);
  };

  const handleSubscriptionChangeClick = () => {
    // 기존 월간 플랜 경우
    if (paymentCycle === "MONTHLY") {
      setAlertDialogState(AlertDialogState.CHANGE_CONFIRM);
    }

    /**
     * @description
     * - 기존 연간 플랜 경우
     * - 플랜 변경에 필요한 데이터 전역 저장
     * - 환불금액 안내 페이지 이동,
     */
    if (
      paymentCycle === "ANNUALLY" &&
      subscriptionId &&
      subscriptionEndAtTo &&
      currentPlanVersion?.currencyUnit &&
      selectPlan?.id &&
      selectPlan.planVersionList?.[0].id &&
      selectPaymentCycle
    ) {
      dispatch(
        changeAnnuallySubscription({
          id: subscriptionId,
          subscriptionEndAtTo,
          planName: currentPlan?.name as string,
          paymentCycle,
          nextPlanId: selectPlan.id,
          nextPlanName: selectPlan.name ?? "-",
          planVersionId: selectPlan.planVersionList?.[0].id,
          nextPaymentCycle: selectPaymentCycle,
          isFreePlan: subscriptionType === "FREE",
        }),
      );
      navigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_ANNUALLY_TERMINATION);
    }
  };

  const handleSubscribeChangeConfirmClick = async () => {
    if (!subscriptionId || !selectPlan || !selectPaymentCycle) {
      return;
    }

    try {
      await updateSubscription({
        id: subscriptionId,
        nextPlanId: selectPlan.id,
        nextPlanPaymentCycle: selectPaymentCycle,
      }).unwrap();

      onOpenChange(false);
      setAlertDialogState(AlertDialogState.NULL);

      alert.showAlert({
        type: "success",
        message: t(
          "subscriptionManagement:subscription.alert.planChangeCompleted",
        ),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderCurrentSubscribeBadge = (planId: number) => {
    if (currentPlan?.id === planId) {
      return (
        <Badge
          badgeSize="S"
          typoType="b11m"
          badgeColor="systemBlue6"
          color="systemBlue2"
          text={t("normalPlanSignup:currentSubscribePlan")}
        />
      );
    }
  };

  const renderTabItemTitle = (type: PaymentCycleType) => {
    switch (type) {
      case "MONTHLY":
        return t("normalPlanSignup:monthlySubscription");

      case "ANNUALLY":
        return t("normalPlanSignup:annuallySubscription");
    }
  };

  const renderCard = (plan: PlanDto) => {
    const currentVersion = plan.planVersionList?.[0] as PlanVersionDto;
    const {
      currencyUnit,
      monthlyFee,
      numberOfLoadingCode,
      numberOfMember,
      annualFee,
      annualFeeDiscount,
      annualFeeDiscountType,
    } = currentVersion;

    /**
     * @description
     * - 타입이 PERCENT 인경우 `annualFeeDiscount` 바로사용
     * - 타입이 NUMBER 인경우 `annualFeeDiscount / (monthlyFee * 12)) * 100` 계산해서 사용
     */
    const annualDiscount =
      annualFeeDiscountType === "PERCENT"
        ? annualFeeDiscount
        : annualFeeDiscount &&
          monthlyFee &&
          (
            (Number(annualFeeDiscount) / (Number(monthlyFee) * 12)) *
            100
          ).toFixed(2);

    if (selectPaymentCycle === "MONTHLY") {
      return (
        <CardItem key={plan.id}>
          <Card
            isSelect={selectPlan?.id === plan.id}
            onClick={() =>
              setSelectPlan((prev) => {
                if (prev !== null) {
                  return {
                    ...plan,
                  };
                }

                return null;
              })
            }
          >
            <div>
              <FlexRow gap={4}>
                <Typo typoType="b7m">{plan.name}</Typo>
                {paymentCycle === selectPaymentCycle &&
                  renderCurrentSubscribeBadge(plan.id)}
              </FlexRow>
              <br />
              <Typo typoType="d6">
                {t("normalPlanSignup:monthly")} {currencyUnit}{" "}
                {Number(monthlyFee)?.toLocaleString("ko-KR")}
              </Typo>
            </div>
            <Divider />
            <ul>
              <li>
                <Typo typoType="h9">{t("normalPlanSignup:usedAccount")}</Typo>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {t("normalPlanSignup:max")} {numberOfMember}
                    {t("normalPlanSignup:accountUnit")}
                  </Typo>
                </div>
              </li>
              <li>
                <StyledDiv>
                  <Typo typoType="h9">{t("normalPlanSignup:loadingCode")}</Typo>
                  <WhiteSpaceTypo typoType="b10r" color="gray5">
                    {t("normalPlanSignup:loadingCountDescription")}
                  </WhiteSpaceTypo>
                </StyledDiv>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {numberOfLoadingCode}
                    {t("normalPlanSignup:transmittedCount")}
                  </Typo>
                </div>
              </li>
            </ul>
          </Card>
        </CardItem>
      );
    }

    if (selectPaymentCycle === "ANNUALLY") {
      return (
        <CardItem key={plan.id}>
          <Card
            isSelect={selectPlan?.id === plan.id}
            onClick={() =>
              setSelectPlan((prev) => {
                if (prev !== null) {
                  return {
                    ...plan,
                  };
                }
                return null;
              })
            }
          >
            <div>
              <FlexRow gap={4}>
                <Typo typoType="b7m">{plan.name}</Typo>
                {paymentCycle === selectPaymentCycle &&
                  renderCurrentSubscribeBadge(plan.id)}
              </FlexRow>
              <br />
              <FlexRow gap={8}>
                <Typo typoType="d6">
                  {t("normalPlanSignup:annually")} {currencyUnit}{" "}
                  {Number(annualFee)?.toLocaleString("ko-KR")}
                </Typo>
                <RectangularBadge size="s">
                  {annualDiscount}% {t("normalPlanSignup:discount")}
                </RectangularBadge>
              </FlexRow>
              <Typo typoType="b10m">
                / {t("normalPlanSignup:monthly")} {currencyUnit}{" "}
                {(
                  Number(Number(currentVersion?.annualFee).toFixed(2)) / 12
                ).toLocaleString("ko-kR")}
              </Typo>
            </div>
            <Divider />
            <ul>
              <li>
                <Typo typoType="h9">{t("normalPlanSignup:usedAccount")}</Typo>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {t("normalPlanSignup:max")} {numberOfMember}
                    {t("normalPlanSignup:accountUnit")}
                  </Typo>
                </div>
              </li>
              <li>
                <StyledDiv>
                  <Typo typoType="h9">{t("normalPlanSignup:loadingCode")}</Typo>
                  <WhiteSpaceTypo typoType="b10r" color="gray5">
                    {t("normalPlanSignup:loadingCountDescription")}
                  </WhiteSpaceTypo>
                </StyledDiv>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {numberOfLoadingCode}
                    {t("normalPlanSignup:transmittedCount")}
                  </Typo>
                </div>
              </li>
            </ul>
          </Card>
        </CardItem>
      );
    }
  };

  const renderContent = () => {
    const paymentPrice =
      currentPlanVersion?.paymentCycle === "MONTHLY"
        ? currentPlanVersion?.monthlyFee
        : currentPlanVersion?.annualFee;

    if (currentPlan?.planType === "ENTERPRISE") {
      return (
        <FlexColumn gap={16}>
          <CallOut borderColor="blue9" backgroundColor="blue10">
            <FlexRow gap={8}>
              <Icon iconSrc={InfoSvg} iconSize={16} />
              <Typo color="indigo" typoType="b9r">
                {t(
                  "subscriptionManagement:subscription.enterprisePlanPleaseContact",
                )}
              </Typo>
            </FlexRow>
          </CallOut>

          <StyledCallOut backgroundColor="white">
            <EnterPriseCallout>
              <FlexColumn gap={16}>
                <FlexRow
                  gap={8}
                  className="call-out-row"
                  justifyContent="space-between"
                >
                  <FlexColumn>
                    <FlexRow gap={8}>
                      <Typo typoType="b7m">{currentPlan.name}</Typo>

                      <Badge
                        badgeSize="S"
                        typoType="b11m"
                        badgeColor="systemBlue6"
                        color="systemBlue2"
                        text={t("normalPlanSignup:currentSubscribePlan")}
                      />
                    </FlexRow>

                    <div>
                      <Typo typoType="h4">
                        {paymentCycle &&
                          convertPaymentTypeToString(paymentCycle, t)}{" "}
                        {Number(paymentPrice).toLocaleString("ko-KR")}{" "}
                        {currentPlanVersion?.currencyUnit}
                      </Typo>
                    </div>
                  </FlexColumn>

                  <Button
                    buttonColor="blue"
                    buttonGrade="secondary"
                    onClick={goToEnterpriseInquiryPage}
                  >
                    {t("normalPlanSignup:button.salesInquiry")}
                  </Button>
                </FlexRow>

                <Divider />

                <FlexColumn gap={8}>
                  <FlexRow gap={4}>
                    <Icon iconSrc={IconCheck} iconSize={16} />
                    <Typo typoType="b9r">
                      {t("normalPlanSignup:usedAccount")}{" "}
                      {currentPlanVersion?.numberOfMember}
                      {t("normalPlanSignup:accountUnit")}
                    </Typo>
                  </FlexRow>
                  <FlexRow gap={4}>
                    <Icon iconSrc={IconCheck} iconSize={16} />
                    <Typo typoType="b9r">
                      {t("normalPlanSignup:loadingCode")}{" "}
                      {currentPlanVersion?.numberOfLoadingCode}
                      {t("normalPlanSignup:transmittedCount")}
                    </Typo>
                  </FlexRow>
                </FlexColumn>
              </FlexColumn>
            </EnterPriseCallout>
          </StyledCallOut>

          <Divider />

          <PlanInformation>
            <Tabs role="tablist">
              {TAB_LIST.map((item, idx) => {
                return (
                  <StyledTabItem
                    ref={idx === 0 ? buttonRef : null}
                    key={idx.toString()}
                    tabIndex={item === selectPaymentCycle ? 0 : -1}
                    data-selected={item === selectPaymentCycle}
                    tabValue={item}
                    onClick={() => {
                      setSelectPaymentCycle(item);
                    }}
                    onFocusItem={(value) => {
                      setSelectPaymentCycle(value);
                    }}
                  >
                    <Typo
                      typoType="b9r"
                      color={item === selectPaymentCycle ? "blue4" : "gray7"}
                    >
                      {renderTabItemTitle(item)}
                    </Typo>
                  </StyledTabItem>
                );
              })}
            </Tabs>

            <CardContainer>
              {isPlansIsFetching ? (
                <LoaderContainer>
                  <Loader size={60} />
                </LoaderContainer>
              ) : (
                plans
                  .filter((item) => {
                    const isAfter = dayjs().isAfter(
                      dayjs(item.planVersionList?.[0].releasedAt).format(
                        "YYYY-MM-DD",
                      ),
                    );
                    return item.isActivated && isAfter;
                  })
                  .map((plan) => {
                    return renderCard(plan);
                  })
              )}
            </CardContainer>
          </PlanInformation>
        </FlexColumn>
      );
    }

    if (currentPlan?.planType === "NORMAL") {
      return (
        <PlanInformation>
          <Tabs role="tablist">
            {TAB_LIST.map((item, idx) => {
              return (
                <StyledTabItem
                  ref={idx === 0 ? buttonRef : null}
                  key={idx.toString()}
                  tabIndex={item === selectPaymentCycle ? 0 : -1}
                  data-selected={item === selectPaymentCycle}
                  tabValue={item}
                  onClick={() => {
                    setSelectPaymentCycle(item);
                  }}
                  onFocusItem={(value) => {
                    setSelectPaymentCycle(value);
                  }}
                >
                  <Typo
                    typoType="b9r"
                    color={item === selectPaymentCycle ? "blue4" : "gray7"}
                  >
                    {renderTabItemTitle(item)}
                  </Typo>
                </StyledTabItem>
              );
            })}
          </Tabs>

          <CardContainer>
            {isPlansIsFetching ? (
              <LoaderContainer>
                <Loader size={60} />
              </LoaderContainer>
            ) : (
              plans
                .filter((item) => {
                  const isAfter = dayjs().isAfter(
                    dayjs(item.planVersionList?.[0].releasedAt).format(
                      "YYYY-MM-DD",
                    ),
                  );
                  return item.isActivated && isAfter;
                })
                .map((plan) => {
                  return renderCard(plan);
                })
            )}
          </CardContainer>

          <StyledCallOut backgroundColor="white">
            <FlexColumn>
              <Typo color="gray5" typoType="b7r">
                {t("normalPlanSignup:expectedUsage")}
              </Typo>
              <div>
                <Typo color="gray5" typoType="h7">
                  {t("normalPlanSignup:enterprisePlan")}
                </Typo>
                <Typo color="gray5" typoType="b7r">
                  {t("normalPlanSignup:salesInquiry")}
                </Typo>
              </div>
            </FlexColumn>

            <Button
              buttonColor="blue"
              buttonGrade="secondary"
              onClick={goToEnterpriseInquiryPage}
            >
              {t("normalPlanSignup:button.salesInquiry")}
            </Button>
          </StyledCallOut>
        </PlanInformation>
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CHANGE_CONFIRM) {
      return (
        <PlanChangeConfirmAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={handleSubscribeChangeConfirmClick}
          currentPlanName={currentPlan?.name ?? "-"}
          currentPaymentCycle={
            paymentCycle === "ANNUALLY"
              ? t("subscriptionManagement:subscription.annuallyPaymentCycle")
              : t("subscriptionManagement:subscription.monthlyPaymentCycle")
          }
          nextPlanName={selectPlan?.name ?? "-"}
          nextPaymentCycle={
            selectPaymentCycle === "ANNUALLY"
              ? t("subscriptionManagement:subscription.annuallyPaymentCycle")
              : t("subscriptionManagement:subscription.monthlyPaymentCycle")
          }
        />
      );
    }
  };

  useLayoutEffect(() => {
    if (currentPlan && paymentCycle && currentPlanVersion) {
      setSelectPlan({ ...currentPlan, planVersionList: [currentPlanVersion] });
      setSelectPaymentCycle(paymentCycle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        title={t("subscriptionManagement:subscription.planChangeDialogTitle")}
        open={open}
        onOpenChange={onOpenChange}
        width={800}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={() => onOpenChange(false)}
            >
              {t("subscriptionManagement:subscription.button.cancel")}
            </Button>
            <Button
              onClick={handleSubscriptionChangeClick}
              disabled={
                currentPlan?.planType === "NORMAL"
                  ? selectPlan?.id === currentPlan?.id &&
                    selectPaymentCycle === paymentCycle &&
                    selectPlan?.planType === currentPlan?.planType
                  : selectPlan?.id === currentPlan?.id
              }
            >
              {t("subscriptionManagement:subscription.button.change")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <Container>
          <SubTitle typoType="d6">
            {t(
              "subscriptionManagement:subscription.pleaseSelectThePlanYouWantToChange",
            )}
          </SubTitle>
        </Container>

        {renderContent()}
      </Dialog>

      {renderAlertDialog()}
    </>
  );
}

export default PlanChangeDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled(Typo)`
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

const PlanInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  gap: 8px;
`;

const LoaderContainer = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 0;
`;

const CardItem = styled.div`
  grid-column: span 1;
`;

const Card = styled.div<{ isSelect: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  cursor: pointer;
  flex: 1;

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  &:hover {
    border-color: ${colorSet.indigo};
  }

  ${({ isSelect }) =>
    isSelect &&
    css`
      border-color: ${colorSet.indigo};
    `}
`;

const StyledCallOut = styled(CallOut)`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FlexColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1);
  margin: 0 auto;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &[data-selected="true"] {
    background: ${colorSet.white};
    border: 1px solid ${colorSet.blue4};
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const WhiteSpaceTypo = styled(Typo)`
  white-space: pre-wrap;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FlexRow = styled.div<{
  gap?: number;
  justifyContent?: CSSProperties["justifyContent"];
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap}px;
`;

const EnterPriseCallout = styled.div`
  width: 100%;

  .call-out-row {
    width: 100%;
  }
`;
