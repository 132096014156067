import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { CompanyLinkageHistorySummaryDto } from "@/src/store/apis/link/interface";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import LinkStatusBadge from "../../LinkStatusBadge";

export const clientSendLinkageListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "linkedStatus",
    flex: 1,
    cellRenderer: ({ data }: ValueGetterParams) => {
      return (
        <LinkStatusBadge
          status={data?.receiverBuyer?.linkedStatus || "PENDING"}
          t={data?.t}
        />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "client",
    minWidth: 200,
    flex: 1,
    sortable: false,
    cellRenderer: ({
      data,
    }: ValueGetterParams<CompanyLinkageHistorySummaryDto>) => {
      return <>{data?.receiverBuyer?.buyerName || "-"}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "clientCode",
    minWidth: 200,
    flex: 1,
    sortable: false,
    cellRenderer: ({
      data,
    }: ValueGetterParams<CompanyLinkageHistorySummaryDto>) => {
      return <>{data?.receiverBuyer?.buyerNameCode || "-"}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<any>) => {
      const at = data.createdAt;
      if (!at) return "-";
      return <>{dayjs(at).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "linkedAt",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<any>) => {
      const at = data.linkedAt;
      if (!at) return "-";
      return <>{dayjs(at).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}</>;
    },
  },
];

export const clientRecieveLinkageListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "linkedStatus",
    flex: 1,
    cellRenderer: ({ data }: ValueGetterParams) => {
      return (
        <LinkStatusBadge
          status={data?.senderBuyer?.linkedStatus || "PENDING"}
          t={data?.t}
        />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "client",
    minWidth: 200,
    flex: 1,
    sortable: false,
    cellRenderer: ({
      data,
    }: ValueGetterParams<CompanyLinkageHistorySummaryDto>) => {
      return <>{data?.senderBuyer?.buyerName || "-"}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "clientCode",
    minWidth: 200,
    flex: 1,
    sortable: false,
    cellRenderer: ({
      data,
    }: ValueGetterParams<CompanyLinkageHistorySummaryDto>) => {
      return <>{data?.senderBuyer?.buyerNameCode || "-"}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<any>) => {
      const at = data.createdAt;
      if (!at) return "-";
      return <>{dayjs(at).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "linkedAt",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<any>) => {
      const at = data.linkedAt;
      if (!at) return "-";
      return <>{dayjs(at).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}</>;
    },
  },
];
