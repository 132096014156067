import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import Badge from "@/src/components/atom/Badge";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import useAlert from "@/src/hooks/useAlert";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { useLazyGetInquiryQuery } from "@/src/store/apis/inquiry";
import { InquiryStatusType } from "@/src/store/apis/inquiry/interface";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";

const ExporterInquiryDetailPage = () => {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [getInquiry, inquiryResult] = useLazyGetInquiryQuery();
  const { currentData: inquiryCategory } = useGetCommonCodeViaCodeNameQuery({
    codeName: "INQUIRY_CATEGORY",
  });

  const category = inquiryCategory?.find(
    ({ codeItemName }) => codeItemName === inquiryResult.currentData?.category,
  );

  useEffect(() => {
    (async () => {
      try {
        await getInquiry({ id: Number(id) }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ message, type: "error" });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:inquiry")]}
      pageTitle={t("sideNav:inquiry")}
    >
      <Article>
        <SectionCard
          cardTitle={
            <>
              <CardTitleDiv>
                <Typo typoType="h6">{t("inquiry:label.oneToOneInquiry")}</Typo>
                {getBadge({ status: inquiryResult.currentData?.status, t })}
              </CardTitleDiv>
            </>
          }
        >
          <InquiryTitleContainer>
            <InquiryTitle>
              {inquiryResult.currentData?.category &&
                `[${
                  i18n.language === "ko"
                    ? (category?.codeItemNameKo ?? "")
                    : (category?.codeItemNameEn ?? "")
                }]`}{" "}
              {inquiryResult.currentData?.title ?? ""}
            </InquiryTitle>
            <Typo typoType="b7r" color="gray6" className="flex-shrink-0">
              {dayjs(inquiryResult.currentData?.createdAt).format("YYYY-MM-DD")}
            </Typo>
          </InquiryTitleContainer>
          <InquiryContent>
            <QuestionContainer>
              <UpperCaseQ>{t("inquiry:label.q")}</UpperCaseQ>
              <StyledBreakWordTypo typoType="b7r">
                {inquiryResult.currentData?.body ?? ""}
              </StyledBreakWordTypo>
            </QuestionContainer>
            {inquiryResult.currentData?.status === "replied" && (
              <AnswerContainer>
                <UpperCaseA>{t("inquiry:label.a")}</UpperCaseA>
                <StyledBreakWordTypo typoType="b7r">
                  {inquiryResult.currentData?.reply ?? ""}
                </StyledBreakWordTypo>
              </AnswerContainer>
            )}
          </InquiryContent>
        </SectionCard>

        <BottomFixedContainer>
          <FooterButtonSection>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
              {t("common:backToPrevious")}
            </Button>
          </FooterButtonSection>
        </BottomFixedContainer>
      </Article>
    </ExporterMainLayout>
  );
};

export default ExporterInquiryDetailPage;

const Article = styled.article`
  margin-bottom: 72px;
`;

const InquiryTitleContainer = styled.h2`
  padding: 16px;
  ${typo.h4}
  border-bottom: 1px solid ${colorSet.gray8};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const InquiryTitle = styled.p`
  word-break: break-all;
`;

const InquiryContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const QuestionContainer = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px 40px;
`;

const AnswerContainer = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px 40px;
  border-top: 1px solid ${colorSet.gray10};
`;

const UpperCaseQ = styled.p`
  color: ${colorSet.gray7};
  ${typo.h2};
  flex-shrink: 0;
`;

const UpperCaseA = styled.p`
  color: ${colorSet.blue2};
  ${typo.h2};
  flex-shrink: 0;
`;

const CardTitleDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const getBadge = ({
  status,
  t,
}: {
  status?: InquiryStatusType;
  t: TFunction;
}) => {
  if (status === "replied") {
    return (
      <Badge
        text={t("inquiry:badge.complete")}
        color="systemLime1"
        badgeColor="systemLime5"
        badgeSize={"S"}
      />
    );
  }

  return (
    <Badge text={"Reply"} color="gray12" badgeColor="gray7" badgeSize={"S"} />
  );
};

const StyledBreakWordTypo = styled(BreakWordTypo)`
  white-space: pre-wrap;
`;
