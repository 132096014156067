import { useTranslation } from "react-i18next";
import AlertDialog from "../../atom/AlertDialog";

interface NoRegisteredSignatureAlertDialogProps {
  open: boolean;
  onOpenChange: () => void;
  onOk: () => void;
  onCancel: () => void;
}

/**
 * @description
 * - 서명이 없는경우 사용하는 AlertDialog
 */
const NoRegisteredSignatureAlertDialog = ({
  open,
  onOk,
  onOpenChange,
  onCancel,
}: NoRegisteredSignatureAlertDialogProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
      title={t("common:noRegisteredSignatureTitle")}
      description={t("common:noRegisteredSignatureDescription")}
      onOk={onOk}
      onCancel={onCancel}
      cancelText={t("common:close")}
      okText={t("common:signatureRegister")}
    />
  );
};

export default NoRegisteredSignatureAlertDialog;
