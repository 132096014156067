import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import colorSet from "@/src/styles/color";
import { ConfigProvider, SelectProps } from "antd";
import { forwardRef, Ref } from "react";
import Select from ".";
import Icon from "../Icon";

export interface SearchSelectProps extends SelectProps {
  options?: SelectProps["options"];
}

const SearchSelect = forwardRef(
  ({ ...props }: SearchSelectProps, ref: Ref<any>) => {
    return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Pretendard Regular",
            controlItemBgHover: colorSet.gray10,
            borderRadius: 8,
            colorTextPlaceholder: colorSet.gray7,
          },
        }}
      >
        <Select
          ref={ref}
          showSearch
          style={{
            width: "100%",
          }}
          showAction={["focus"]}
          filterOption={(input, option) =>
            ((option?.label as string) ?? "")
              .toLowerCase()
              .includes(input.toLowerCase()) ||
            ((option?.value as string) ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          suffixIcon={<Icon iconSrc={ArrowDownSvg} iconSize={20} />}
          placeholder={props.placeholder ?? "Search"}
          onKeyDown={(e) => e.stopPropagation()}
          {...props}
        />
      </ConfigProvider>
    );
  },
);

export default SearchSelect;
