import Table from "@/src/components/atom/Table";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useGetShipmentRateInfoQuery } from "@/src/store/apis/dashboard";
import { ShipmentRateInfoDto } from "@/src/store/apis/dashboard/interface";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shipmentRateColumns } from "../columns/shipmentRateColumns";

const ShipmentRate = () => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { currentData, isFetching } = useGetShipmentRateInfoQuery(
    { page, pageSize },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "orderDateAt",
        langKey: "table:orderDateAt",
      },
      {
        columnKey: "exporterItem",
        langKey: "table:item",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyer",
      },
      {
        columnKey: "unitPrice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "totalQuantity",
        langKey: "table:totalQuantity",
      },
      {
        columnKey: "percentOfShipment",
        langKey: "table:shipmentRate",
      },
    ],
  });

  useEffect(() => {
    if (gridRef.current && gridRef && isReady) {
      if (isFetching) {
        gridRef.current.api.showLoadingOverlay();
      }
    }
  }, [isFetching, isReady]);

  return (
    <>
      <Table
        ref={gridRef}
        onGridReady={() => setIsReady(true)}
        rowData={currentData?.rows ?? []}
        columnDefs={shipmentRateColumns}
        totalPage={currentData?.count ?? 0}
        height={252}
        page={page}
        pageSize={pageSize}
        onRowClicked={({ data }: { data: ShipmentRateInfoDto }) =>
          navigate(
            `${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${data.contractId}`,
          )
        }
        handlePaginationClick={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};

export default ShipmentRate;
