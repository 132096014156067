import EtcSvg from "@/src/assets/icons/icon-docs-type.svg";
import PDFSvg from "@/src/assets/icons/icon-pdf.svg";
import Checkbox from "@/src/components/atom/Checkbox";
import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { imageFileExtensionList } from "@/src/constant/fileExtension";
import colorSet from "@/src/styles/color";
import { blockContextMenuInAntdPreview } from "@/src/utils/blockContextMenuInAntdPreview";
import { Image } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

interface GalleryFileCardProps {
  fileSrc?: string;
  category?: string;
  fileName?: string;
  uploadedAt?: string;
  onClick?: () => void;
  isChecked: boolean;
  onCheckedChange: () => void;
  fileExtension: string;
}

const GalleryFileCard = ({
  category,
  fileSrc,
  fileName,
  uploadedAt,
  onClick,
  isChecked,
  onCheckedChange,
  fileExtension,
}: GalleryFileCardProps) => {
  const renderImage = () => {
    if (fileExtension === "pdf") {
      return <img onClick={onClick} src={PDFSvg} alt="png" />;
    }
    if (imageFileExtensionList.includes(fileExtension)) {
      return (
        <Image
          src={fileSrc}
          height={130}
          width={130}
          alt="png"
          preview={{
            onVisibleChange: (visible) => {
              if (visible) {
                blockContextMenuInAntdPreview();
              }
            },
          }}
        />
      );
    }

    return <img src={EtcSvg} alt="png" />;
  };

  return (
    <Article onContextMenu={(e) => e.preventDefault()}>
      <Container>
        <StyledCheckbox
          checked={isChecked}
          onChange={onCheckedChange}
          onClick={(e) => e.stopPropagation()}
        />
        <StyledFigure>{renderImage()}</StyledFigure>
      </Container>

      <FlexColumn>
        <Typo as="p" typoType="b9r" className="ellipsis">
          {fileName}
        </Typo>

        <Flex>
          <Typo typoType="b9m" color="gray6">
            {category}
          </Typo>
          <ColDivider />
          <Typo typoType="b9m" color="gray6">
            {dayjs(uploadedAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm)}
          </Typo>
        </Flex>
      </FlexColumn>
    </Article>
  );
};

export default GalleryFileCard;

const StyledFigure = styled.figure`
  width: 60%;
  height: 60%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  cursor: pointer;
  width: 210px;
  &:hover {
    ${StyledFigure} {
      transition: all 0.25s ease-out;
      transform: scale(1.05);
    }
  }
`;

const Container = styled.div`
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${colorSet.gray10};
  border: 1px solid ${colorSet.gray9};
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ColDivider = styled.div`
  height: 14px;
  border-right: 1px solid ${colorSet.gray9};
  margin: 0 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 12px;
  left: 12px;
`;
