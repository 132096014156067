import { ReactComponent as CancelCircleSvg } from "@/src/assets/icons/icon-cancle-circle.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as ClipSvg } from "@/src/assets/icons/icon-link-clip.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import Badge from "@/src/components/atom/Badge";
import { Button, IconButton } from "@/src/components/atom/Button";
import Loader from "@/src/components/atom/Loader";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import useAlert from "@/src/hooks/useAlert";
import { useUploadBigFileMutation } from "@/src/store/apis/media";
import {
  useCreateInternalDocumentMutation,
  useDeleteShipmentInternalDocumentMutation,
} from "@/src/store/apis/shipments/shipmentDetail";
import { SimpleDocumentMediaDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import { acceptData } from "@/src/utils/accept";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { downloadFile } from "@/src/utils/downloadFile";
import { isFileAccepted } from "@/src/utils/is";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css, styled } from "styled-components";

interface DocumentsInternalCardProps {
  data: SimpleDocumentMediaDto[];
  isEditAuth: boolean;
  isShipmentCompleted: boolean;
}

function DocumentsInternalCard({
  isEditAuth,
  isShipmentCompleted,
  data,
}: DocumentsInternalCardProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [deleteDocument, { isLoading: isDeleting }] =
    useDeleteShipmentInternalDocumentMutation();
  const [upload, { isLoading: isUploading }] = useUploadBigFileMutation();
  const [createInternalDocument, { isLoading }] =
    useCreateInternalDocumentMutation();

  const [allButtonRef, setAllButtonRef] = useState<HTMLButtonElement[]>([]);

  const handleDelete = async (id: number) => {
    try {
      await deleteDocument({ id }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const filteredFiles = Array.from(e.target.files).filter((file) =>
      isFileAccepted(file, acceptData.file),
    );
    if (filteredFiles.length === 0) {
      alert.showAlert({
        type: "error",
        message: t("alert:fileUploadFail"),
      });
      return;
    }

    try {
      const data = await upload({
        files: Array.from(e.target.files),
        folder: "shipment-internal-files/",
      }).unwrap();

      await createInternalDocument({
        id: Number(params.id),
        mediaId: data[0].id,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  return (
    <SectionCard
      cardTitle={
        <TitleWrapper>
          <Typo typoType="h6">
            {t("shipment:exporter.detail.label.additionalDocuments")}
          </Typo>
          <Badge
            badgeType="line"
            badgeColor="white"
            borderColor="red2"
            color="red2"
            badgeSize="S"
            text={t("shipment:exporter.detail.label.nonShare")}
          />
        </TitleWrapper>
      }
      rightAccessory={
        <RightAccessoryContainer>
          <StyledButton
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => {
              allButtonRef.forEach((button) => button.click());
            }}
          >
            <DownloadIcon />
            {t("shipment:exporter.detail.button.downloadButton")}
          </StyledButton>

          <StyledButton
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            disabled={
              isUploading || isLoading || !isEditAuth || isShipmentCompleted
            }
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            {isUploading || isLoading ? (
              <Loader />
            ) : (
              <ClipIcon disabled={!isEditAuth || isShipmentCompleted} />
            )}
            {t("shipment:exporter.detail.button.addFileButton")}
          </StyledButton>
          <HiddenInput
            ref={fileInputRef}
            type="file"
            multiple={false}
            onChange={handleAddFile}
            value={""}
            accept={acceptData.file}
          />
        </RightAccessoryContainer>
      }
    >
      <Files>
        {data.length >= 1 ? (
          data.map((item, idx) => {
            return (
              <SectionCardRow
                key={idx.toString()}
                label={`${t(
                  "shipment:exporter.detail.label.document",
                )} ${addZeroPrefix(idx + 1)}`}
                value={
                  <FileInfo>
                    <FileName typoType="b7r" color="gray5">
                      {item.originalFileName}
                    </FileName>

                    <ButtonContainer>
                      {isDeleting ? (
                        <Loader />
                      ) : (
                        <CancelCircleIcon
                          onClick={() => handleDelete(item.id)}
                          isEditAuth={!isEditAuth || isShipmentCompleted}
                        />
                      )}

                      <FileButtons>
                        <IconButton
                          ref={(node) => {
                            if (node) {
                              const isAlreadyInRef = allButtonRef.some(
                                (buttonNode) => buttonNode.isSameNode(node),
                              );

                              if (!isAlreadyInRef) {
                                setAllButtonRef(allButtonRef.concat(node));
                              }
                            }
                          }}
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          onClick={() => {
                            downloadFile(item.mediaUrl, item.originalFileName);
                          }}
                        >
                          <DownloadIcon color="indigo" />
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                        >
                          <a
                            href={item.mediaUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PreviewIcon color="indigo" />
                          </a>
                        </IconButton>
                      </FileButtons>
                    </ButtonContainer>
                  </FileInfo>
                }
              />
            );
          })
        ) : (
          <MemoNoDataContainer>{renderNoRowsComponent()}</MemoNoDataContainer>
        )}
      </Files>
    </SectionCard>
  );
}

export default DocumentsInternalCard;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RightAccessoryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 220px;
  padding: 2px 0;

  ${StyledScroll}
`;

const FileInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const FileButtons = styled.div`
  display: flex;
  gap: 4px;

  a {
    line-height: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FileName = styled(Typo)`
  word-break: break-all;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 216px;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const ClipIcon = styled(ClipSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const CancelCircleIcon = styled(CancelCircleSvg)<{ isEditAuth: boolean }>`
  cursor: pointer;
  flex-shrink: 0;

  ${({ isEditAuth }) =>
    isEditAuth &&
    css`
      display: none;
    `}
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
