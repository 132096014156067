import Steps from "@/components/molecule/Steps";
import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import {
  ClientType,
  SignupJoinPathType,
} from "@/src/store/apis/auth/interface";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CorporateSignUpStepFour from "./CorporateSignUpStepFour";
import CorporateSignUpStepOne from "./CorporateSignUpStepOne";
import CorporateSignUpStepThree from "./CorporateSignUpStepThree";
import CorporateSignUpStepTwo from "./CorporateSignUpStepTwo";

// Button Status Context
type SignUpButtonStatusType = {
  isEmailConfirm: boolean;
  isCodeSendClick: boolean;
  isCodeConfirm: boolean;
};
type SignupButtonStateContextType = SignUpButtonStatusType & {
  onButtonStatusChange: React.Dispatch<
    React.SetStateAction<SignUpButtonStatusType>
  >;
};
export const SignUpButtonStatusContext = createContext<
  SignupButtonStateContextType | undefined
>(undefined);

// SignUp Form Context
export type CorporationSignUpFormType = {
  companyType: ClientType;
  countryCodeItemName: string;
  businessNumber: string;
  verifyString: string;
  companyName: string;
  tel: string;
  telPrefix: string;
  faxPrefix: string;
  fax?: string;
  countryName: string;
  locality: string;
  postalCode: string;
  streetAddress: string;
  region: string;
  industryCodeItemNames?: string[];
  name: string;
  aId: string;
  password: string;
  mainCategoryCodeItemNames: string[];
  authTokenId: string;
  requiredTermsIdList: number[];
  optionalTermsHistoryIdList: number[];
  isMarketingEmailReceive: boolean;

  joinPath?: SignupJoinPathType | undefined;
  joinPathDescription?: string;

  city: string;
  gmtOffset: number;
};
type CorporationSignUpContextValueType = CorporationSignUpFormType & {
  onFormValueChange: React.Dispatch<
    React.SetStateAction<CorporationSignUpFormType>
  >;
};
export const CorporationSignUpFormContext = createContext<
  undefined | CorporationSignUpContextValueType
>(undefined);
const { Provider: CorporationSignUpFormProvider } =
  CorporationSignUpFormContext;

// Step Context
export const CorporationStepContext = createContext<
  | {
      step: number;
      onStepNext: () => void;
      onStepPrev: () => void;
    }
  | undefined
>(undefined);
const { Provider: CorporationStepContextProvider } = CorporationStepContext;

const CorporateSignupPage = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);
  const onStepNext = useCallback(() => setStep((prev) => prev + 1), []);
  const onStepPrev = useCallback(() => setStep((prev) => prev - 1), []);
  const [signUpFormSet, setSignUpFormSet] = useState<CorporationSignUpFormType>(
    {
      companyType: "SELLER",
      countryCodeItemName: "",
      businessNumber: "",
      companyName: "",
      tel: "",
      telPrefix: "",
      fax: "",
      faxPrefix: "",
      countryName: "",
      locality: "",
      postalCode: "",
      streetAddress: "",
      region: "",
      industryCodeItemNames: [],
      name: "",
      aId: "",
      password: "",
      mainCategoryCodeItemNames: [],
      authTokenId: "",
      verifyString: "",
      requiredTermsIdList: [],
      optionalTermsHistoryIdList: [],
      isMarketingEmailReceive: false,
      joinPath: undefined,
      joinPathDescription: "",
      city: "",
      gmtOffset: 0,
    },
  );

  const [signUpButtonStatus, setSignUpButtonStatus] =
    useState<SignUpButtonStatusType>({
      isEmailConfirm: false,
      isCodeSendClick: false,
      isCodeConfirm: false,
    });

  const renderContent = () => {
    switch (step) {
      case 1:
        // 기업 기본정보
        return <CorporateSignUpStepOne />;
      case 2:
        // 기업 상세정보
        return <CorporateSignUpStepTwo />;
      case 3:
        // 담당자 정보
        return <CorporateSignUpStepThree />;
      case 4:
        // 등록 완료
        return <CorporateSignUpStepFour />;
    }
  };

  // 새로고침 감지
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <SignUpLayout>
      <StyledMain>
        <Typo as="h1" typoType="d3" color="gray1">
          {t("signup:common.signupForACorp")}
        </Typo>
        <>
          <StepsContainer>
            <Steps
              items={[
                { title: t("signupCorp:steps.firstStep") },
                { title: t("signupCorp:steps.secondStep") },
                { title: t("signupCorp:steps.thirdStep") },
                { title: t("signupCorp:steps.fourthStep") },
              ]}
              current={step}
            />
          </StepsContainer>
          <SignUpButtonStatusContext.Provider
            value={{
              ...signUpButtonStatus,
              onButtonStatusChange: setSignUpButtonStatus,
            }}
          >
            <CorporationStepContextProvider
              value={{
                step,
                onStepNext,
                onStepPrev,
              }}
            >
              <CorporationSignUpFormProvider
                value={{
                  ...signUpFormSet,
                  onFormValueChange: setSignUpFormSet,
                }}
              >
                {renderContent()}
              </CorporationSignUpFormProvider>
            </CorporationStepContextProvider>
          </SignUpButtonStatusContext.Provider>
        </>
      </StyledMain>
    </SignUpLayout>
  );
};

export default CorporateSignupPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    padding: 24px 0;
  }
`;

const StepsContainer = styled.div`
  width: 800px;
  padding: 24px 0;
`;
