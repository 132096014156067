import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import Badge from "@/src/components/atom/Badge";
import RectangularBadge from "@/src/components/atom/Badge/RectangularBadge";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import TabItem from "@/src/components/molecule/TabItem";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { authApi } from "@/src/store/apis/auth";
import {
  subscriptionApi,
  useCreateSubscriptionsMutation,
} from "@/src/store/apis/subscription";
import {
  PaymentCycleType,
  PaymentMethodDto,
  PlanDto,
  PlanVersionDto,
  SubscriptionType,
} from "@/src/store/apis/subscription/interface";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useNormalPlanSignupContext } from "../hooks";

const TAB_LIST: PaymentCycleType[] = ["MONTHLY", "ANNUALLY"];

const emptyArray: PaymentMethodDto[] = [];

function SelectNormalPlan() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const alert = useAlert();
  const [searchParams] = useSearchParams();

  const {
    paymentCycle,
    planId,
    onPlanChange,
    planVersionId,
    onStepChange,
    isFreeTrial,
  } = useNormalPlanSignupContext();
  const [isEnterpriseSelect, setIsEnterpriseSelect] = useState(false);
  const isInquiryQuery = !!searchParams.get("isInquiry");

  // API
  const [createSubscriptions, { isLoading: isSubscribeLoading }] =
    useCreateSubscriptionsMutation();

  const { companyType, exporter } = authApi.endpoints.getSession.useQueryState(
    undefined,
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isStable,
          exporter: currentData?.row.exporter,
          companyType:
            currentData?.row.exporterUserType === "CORPORATE_MANAGER"
              ? currentData?.row.exporter.companyType
              : currentData?.row.exporterUserMainFieldType,
        };
      },
    },
  );

  const { plans, isPlansUnstable, isPlanFetch } =
    subscriptionApi.endpoints.getPlans.useQueryState(
      {},
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isPlansUnstable: isUnstable,
            plans: isStable ? currentData.rows : [],
            isPlanFetch: isFetching,
          };
        },
      },
    );

  const { payments } =
    subscriptionApi.endpoints.getPaymentMethods.useQueryState(
      {
        page: 1,
        pageSize: 2,
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnStable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnStable;

          return {
            payments: isStable ? currentData.rows : emptyArray,
          };
        },
      },
    );

  const { isReSubscribe, subscriptions, isCurrentPlanEnterprise } =
    subscriptionApi.endpoints.getCurrentSubscription.useQueryState(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isReSubscribe: isStable
            ? currentData.subscriptionStatus === "CANCELED"
            : false,
          subscriptions: currentData,
          isCurrentPlanEnterprise: isStable
            ? currentData.plan.planType === "ENTERPRISE"
            : false,
        };
      },
    });

  const representativePayments = payments
    ? payments.find((item) => item.isRepresentative)
    : null;

  const enterprisePlan = plans.filter(({ planType, planVersionList }) => {
    const releaseAt = dayjs(
      dayjs(planVersionList?.[0].releasedAt).format(
        DATE_FORMAT_STRINGS.YYYY_MM_DD,
      ),
    );
    const duration = releaseAt.diff(dayjs(), "days");
    // 엔터프라이즈, 오늘날짜 기준 반영일자가 포함되는 날
    return planType === "ENTERPRISE" && duration >= 0;
  })[0];

  const isEnterprisePlan = !!enterprisePlan;

  const goToEnterprisePlanInquiryPage = () => {
    return navigate(
      `${EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP}?isInquiry=${isInquiryQuery}`,
    );
  };
  const goToHome = () => {
    switch (companyType) {
      case "BOTH":
      case "SELLER":
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
      case "BUYER":
        return navigate(IMPORTER_PRIVATE_PATH.HOME);
      default:
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  };

  const goToPrev = () => {
    navigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT);
  };

  const handlePaymentInformationClick = () => {
    onStepChange("PAYMENT_INFORMATION");
  };

  const handleCardClick = ({
    planId,
    planVersionId,
    planName,
    currencyUnit,
    numberOfMember,
    numberOfLoadingCode,
    annualFee,
    monthlyFee,
    paymentCycle,
  }: {
    planId?: number;
    planVersionId?: number;
    planName: string;
    currencyUnit: string;
    numberOfMember?: number;
    numberOfLoadingCode?: number;
    monthlyFee?: string;
    annualFee?: string;
    paymentCycle?: PaymentCycleType;
  }) => {
    onPlanChange((prev) => {
      return {
        ...prev,
        planId,
        planVersionId,
        planName,
        currencyUnit,
        numberOfMember,
        numberOfLoadingCode,
        annualFee,
        monthlyFee,
        paymentCycle: paymentCycle || prev.paymentCycle,
      };
    });
  };

  const createSubscribe = async () => {
    if (
      !planId ||
      !planVersionId ||
      !representativePayments?.id ||
      !exporter?.id
    ) {
      return;
    }

    try {
      const res = await createSubscriptions({
        subscriptionStatus: "PROGRESS",
        subscriptionType: (isFreeTrial
          ? "FREE"
          : "STANDARD") as SubscriptionType,
        paymentCycle,
        planId,
        planVersionId,
        paymentMethodId: representativePayments.id,
        exporterId: exporter.id,
      }).unwrap();

      if (res.row) {
        onPlanChange((prev) => {
          return {
            ...prev,
            planName: res.row.plan.name,
            isFreeTrial: res.row.subscriptionType === "FREE",
            subscriptionStartAtFrom: res.row.subscriptionStartAtFrom,
            subscriptionEndAtTo: res.row.subscriptionEndAtTo,
          };
        });
        onStepChange("COMPLETED");
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  // 렌더 함수
  const renderTabItemTitle = (type: PaymentCycleType) => {
    switch (type) {
      case "MONTHLY":
        return t("normalPlanSignup:monthlySubscription");

      case "ANNUALLY":
        return t("normalPlanSignup:annuallySubscription");
    }
  };

  const renderBadge = (planId: number) => {
    const previousPlanId = subscriptions?.plan.id;

    // 재구독시 이전 구독 안내
    if (isReSubscribe && planId === previousPlanId) {
      return (
        <Badge
          badgeSize="S"
          typoType="b11m"
          badgeColor="gray10"
          color="gray4"
          text={t("normalPlanSignup:previousPlan")}
        />
      );
    }

    // 무료 가능
    if (isFreeTrial) {
      return (
        <Badge
          badgeSize="S"
          typoType="b11m"
          badgeColor="systemBlue6"
          color="systemBlue2"
          text={t("normalPlanSignup:freeTrial")}
        />
      );
    }
  };

  const renderCard = (plan: PlanDto) => {
    const currentVersion = plan.planVersionList?.[0] as PlanVersionDto;
    const {
      id: planVersionId,
      currencyUnit,
      monthlyFee,
      numberOfLoadingCode,
      numberOfMember,
      annualFee,
      annualFeeDiscount,
      annualFeeDiscountType,
    } = currentVersion;

    /**
     * @description
     * - 타입이 PERCENT 인경우 `annualFeeDiscount` 바로사용
     * - 타입이 NUMBER 인경우 `annualFeeDiscount / (monthlyFee * 12)) * 100` 계산해서 사용
     */
    const annualDiscount =
      annualFeeDiscountType === "PERCENT"
        ? annualFeeDiscount
        : annualFeeDiscount &&
          monthlyFee &&
          (
            (Number(annualFeeDiscount) / (Number(monthlyFee) * 12)) *
            100
          ).toFixed(1);

    if (paymentCycle === "MONTHLY") {
      return (
        <CardItem>
          <Card
            isSelect={planId === plan.id}
            onClick={() => {
              setIsEnterpriseSelect(false);
              handleCardClick({
                planId: plan.id,
                planVersionId,
                planName: plan.name ?? "",
                currencyUnit: currencyUnit ?? "",
                numberOfMember,
                numberOfLoadingCode,
                monthlyFee,
                annualFee,
              });
            }}
          >
            <Flex flexDirection="column" gap={8}>
              <Flex alignItems="center" gap={4}>
                <Typo typoType="b7m">{plan.name}</Typo>
                {renderBadge(plan.id)}
              </Flex>
              <Typo typoType="d6">
                {t("normalPlanSignup:monthly")} {currencyUnit}{" "}
                {Number(monthlyFee)?.toLocaleString("ko-KR")}
              </Typo>
            </Flex>

            <Divider />
            <ul className="gap-16">
              <li>
                <Typo typoType="h9">{t("normalPlanSignup:usedAccount")}</Typo>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {t("normalPlanSignup:max")} {numberOfMember}
                    {t("normalPlanSignup:accountUnit")}
                  </Typo>
                </div>
              </li>
              <li>
                <StyledDiv>
                  <Typo typoType="h9">{t("normalPlanSignup:loadingCode")}</Typo>
                  <WhiteSpaceTypo typoType="b10r" color="gray5">
                    {t("normalPlanSignup:loadingCountDescription")}
                  </WhiteSpaceTypo>
                </StyledDiv>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {numberOfLoadingCode}
                    {t("normalPlanSignup:transmittedCount")}
                  </Typo>
                </div>
              </li>
            </ul>
          </Card>
        </CardItem>
      );
    }

    if (paymentCycle === "ANNUALLY") {
      return (
        <CardItem>
          <Card
            isSelect={planId === plan.id}
            onClick={() => {
              setIsEnterpriseSelect(false);
              handleCardClick({
                planId: plan.id,
                planVersionId,
                planName: plan.name ?? "",
                currencyUnit: currencyUnit ?? "",
                numberOfMember,
                numberOfLoadingCode,
                monthlyFee,
                annualFee,
              });
            }}
          >
            <Flex flexDirection="column">
              <Flex flexDirection="column" gap={8}>
                <Flex alignItems="center" gap={4}>
                  <Typo typoType="b7m">{plan.name}</Typo>
                  {renderBadge(plan.id)}
                </Flex>
                <Flex alignItems="center" gap={8}>
                  <Typo typoType="d6">
                    {t("normalPlanSignup:annually")} {currencyUnit}{" "}
                    {Number(annualFee)?.toLocaleString("ko-KR")}
                  </Typo>
                  <RectangularBadge size="s">
                    {annualDiscount}% {t("normalPlanSignup:discount")}
                  </RectangularBadge>
                </Flex>
              </Flex>
              <Typo typoType="b10m">
                / {t("normalPlanSignup:monthly")} {currencyUnit}{" "}
                {(
                  Number(Number(currentVersion?.annualFee).toFixed(2)) / 12
                ).toLocaleString("ko-kR")}
              </Typo>
            </Flex>

            <Divider />
            <ul className="gap-16">
              <li>
                <Typo typoType="h9">{t("normalPlanSignup:usedAccount")}</Typo>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {t("normalPlanSignup:max")} {numberOfMember}
                    {t("normalPlanSignup:accountUnit")}
                  </Typo>
                </div>
              </li>
              <li>
                <StyledDiv>
                  <Typo typoType="h9">{t("normalPlanSignup:loadingCode")}</Typo>
                  <WhiteSpaceTypo typoType="b10r" color="gray5">
                    {t("normalPlanSignup:loadingCountDescription")}
                  </WhiteSpaceTypo>
                </StyledDiv>
                <div>
                  <Icon iconSrc={IconCheck} iconSize={16} />
                  <Typo typoType="b9r">
                    {numberOfLoadingCode}
                    {t("normalPlanSignup:transmittedCount")}
                  </Typo>
                </div>
              </li>
            </ul>
          </Card>
        </CardItem>
      );
    }
  };

  const renderEnterprisePlan = () => {
    if (isEnterprisePlan) {
      const { id, name, planVersionList } = enterprisePlan;
      const {
        id: planVersionId,
        currencyUnit,
        monthlyFee,
        numberOfLoadingCode,
        numberOfMember,
        annualFee,
        paymentCycle: enterprisePaymentCycle,
      } = planVersionList?.[0] as PlanVersionDto;

      const paymentPrice =
        enterprisePaymentCycle === "MONTHLY" ? monthlyFee : annualFee;
      const paymentCycleText =
        enterprisePaymentCycle === "MONTHLY"
          ? t("normalPlanSignup:monthly")
          : t("normalPlanSignup:annually");

      return (
        <Card
          isSelect={planId === id}
          onClick={() => {
            setIsEnterpriseSelect(true);
            handleCardClick({
              planId: id,
              planVersionId,
              planName: name ?? "",
              currencyUnit: currencyUnit ?? "",
              numberOfMember,
              numberOfLoadingCode,
              monthlyFee,
              annualFee,
              paymentCycle: enterprisePaymentCycle as PaymentCycleType,
            });
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flexDirection="column" gap={8}>
              <Flex alignItems="center" gap={4}>
                <Typo typoType="b7m">{name}</Typo>
                {renderBadge(id)}
              </Flex>

              <Typo typoType="d6">
                {paymentCycleText}{" "}
                {Number(paymentPrice)?.toLocaleString("ko-KR")} {currencyUnit}
              </Typo>
            </Flex>

            <StyledButton
              buttonColor="blue"
              buttonGrade="secondary"
              onClick={goToEnterprisePlanInquiryPage}
            >
              {t("normalPlanSignup:button.salesInquiry")}
            </StyledButton>
          </Flex>
          <Divider />
          <ul className="gap-8">
            <li>
              <div>
                <Icon iconSrc={IconCheck} iconSize={16} />
                <Typo typoType="b9r">
                  {t("normalPlanSignup:max")} {numberOfMember}
                  {t("normalPlanSignup:accountUnit")}
                </Typo>
              </div>
            </li>
            <li>
              <div>
                <Icon iconSrc={IconCheck} iconSize={16} />
                <Typo typoType="b9r">
                  {numberOfLoadingCode}
                  {t("normalPlanSignup:transmittedCount")}
                </Typo>
              </div>
            </li>
          </ul>
        </Card>
      );
    }

    // 현재 버전이 엔터프라이즈, 구독기록이 있는
    if (isCurrentPlanEnterprise && isReSubscribe && subscriptions) {
      const { plan, planVersion, paymentCycle } = subscriptions;
      const {
        id: planVersionId,
        currencyUnit,
        monthlyFee,
        numberOfLoadingCode,
        numberOfMember,
        annualFee,
      } = planVersion;

      const releaseAt = dayjs(
        dayjs(planVersion.releasedAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD),
      );
      const duration = releaseAt.diff(dayjs(), "days");

      const paymentPrice = paymentCycle === "MONTHLY" ? monthlyFee : annualFee;
      const paymentCycleText =
        paymentCycle === "MONTHLY"
          ? t("normalPlanSignup:monthly")
          : t("normalPlanSignup:annually");

      // 반영일자가 지났으면 보이지 않는다
      if (duration < 0) {
        return null;
      }

      return (
        <Card
          isSelect={planId === plan.id}
          onClick={() => {
            setIsEnterpriseSelect(true);
            handleCardClick({
              planId: plan.id,
              planVersionId,
              planName: plan.name ?? "",
              currencyUnit: currencyUnit ?? "",
              numberOfMember,
              numberOfLoadingCode,
              monthlyFee,
              annualFee,
            });
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flexDirection="column" gap={8}>
              <Flex alignItems="center" gap={4}>
                <Typo typoType="b7m">{plan.name}</Typo>
                {renderBadge(plan.id)}
              </Flex>

              <Typo typoType="d6">
                {paymentCycleText} {currencyUnit}
                {Number(paymentPrice)?.toLocaleString("ko-KR")}
              </Typo>
            </Flex>

            <StyledButton
              buttonColor="blue"
              buttonGrade="secondary"
              onClick={goToEnterprisePlanInquiryPage}
            >
              {t("normalPlanSignup:button.salesInquiry")}
            </StyledButton>
          </Flex>
          <Divider />
          <ul className="gap-8">
            <li>
              <div>
                <Icon iconSrc={IconCheck} iconSize={16} />
                <Typo typoType="b9r">
                  {t("normalPlanSignup:max")} {numberOfMember}
                  {t("normalPlanSignup:accountUnit")}
                </Typo>
              </div>
            </li>
            <li>
              <div>
                <Icon iconSrc={IconCheck} iconSize={16} />
                <Typo typoType="b9r">
                  {numberOfLoadingCode}
                  {t("normalPlanSignup:transmittedCount")}
                </Typo>
              </div>
            </li>
          </ul>
        </Card>
      );
    }
  };

  const renderTabs = () => {
    if (isPlansUnstable) {
      return <></>;
    }

    return (
      <Tabs role="tablist">
        {TAB_LIST.map((item, idx) => {
          return (
            <StyledTabItem
              ref={idx === 0 ? buttonRef : null}
              key={idx.toString()}
              tabIndex={item === paymentCycle ? 0 : -1}
              data-selected={item === paymentCycle}
              tabValue={item}
              onClick={() =>
                !isEnterpriseSelect &&
                onPlanChange((prev) => {
                  return { ...prev, paymentCycle: item };
                })
              }
              onFocusItem={(value) => {
                !isEnterpriseSelect &&
                  onPlanChange((prev) => {
                    return { ...prev, paymentCycle: value };
                  });
              }}
            >
              <Typo
                typoType="b9r"
                color={item === paymentCycle ? "blue4" : "gray7"}
              >
                {renderTabItemTitle(item)}
              </Typo>
            </StyledTabItem>
          );
        })}
      </Tabs>
    );
  };

  const renderCancelButton = () => {
    // 재구독시, 결제실패로 진입시
    if (isReSubscribe || subscriptions?.subscriptionStatus === "PAYMENT_FAIL") {
      return (
        <Button buttonGrade="tertiary" buttonColor="black" onClick={goToPrev}>
          {t("normalPlanSignup:button.cancel")}
        </Button>
      );
    }

    return (
      <Button buttonGrade="tertiary" buttonColor="black" onClick={goToHome}>
        {t("normalPlanSignup:button.home")}
      </Button>
    );
  };

  const renderConfirmButton = () => {
    // 재구독시
    if (isReSubscribe) {
      return (
        <Button
          onClick={createSubscribe}
          disabled={typeof planId === "undefined" || isSubscribeLoading}
          isLoading={isSubscribeLoading}
        >
          {t("normalPlanSignup:button.subscription")}
        </Button>
      );
    }

    return (
      <Button
        onClick={handlePaymentInformationClick}
        disabled={typeof planId === "undefined"}
      >
        {t("normalPlanSignup:button.paymentInformation")}
      </Button>
    );
  };

  const renderCardList = () => {
    const normalPlans = plans.filter(({ planType }) => planType === "NORMAL");

    if (isPlanFetch) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    return (
      <CardContainer>
        {normalPlans
          .filter((item) => {
            const isAfter = dayjs().isAfter(
              dayjs(item.planVersionList?.[0].releasedAt).format("YYYY-MM-DD"),
            );
            return item.isActivated && isAfter;
          })
          .map((plan) => {
            return renderCard(plan);
          })}
      </CardContainer>
    );
  };

  return (
    <StyledSectionCard
      cardTitle={t("normalPlanSignup:cardTitle")}
      cardContentContainerStyle={{ padding: "40px 32px" }}
    >
      <Content>
        <Description>
          <WhiteSpaceType typoType="h1">
            {t("normalPlanSignup:copyright")}
          </WhiteSpaceType>
          <WhiteSpaceType typoType="b7m">
            {t("normalPlanSignup:description")}
          </WhiteSpaceType>
        </Description>

        <PlanInformation>
          {renderEnterprisePlan()}
          {renderTabs()}
          {renderCardList()}

          {!isEnterprisePlan && (
            <StyledCallOut backgroundColor="white">
              <Flex flexDirection="column">
                <Typo color="gray5" typoType="b7r">
                  {t("normalPlanSignup:expectedUsage")}
                </Typo>
                <div>
                  {i18n.language === "ko" ? (
                    <>
                      <Typo color="gray5" typoType="h7">
                        {t("normalPlanSignup:enterprisePlan")}
                      </Typo>
                      <Typo color="gray5" typoType="b7r">
                        {t("normalPlanSignup:salesInquiry")}
                      </Typo>
                    </>
                  ) : (
                    <>
                      <Typo color="gray5" typoType="b7r">
                        {t("normalPlanSignup:salesInquiry")}
                      </Typo>
                      <Typo color="gray5" typoType="h7">
                        {" "}
                        {t("normalPlanSignup:enterprisePlan")}
                      </Typo>
                    </>
                  )}
                </div>
              </Flex>

              <Button
                buttonColor="blue"
                buttonGrade="secondary"
                onClick={goToEnterprisePlanInquiryPage}
              >
                {t("normalPlanSignup:button.salesInquiry")}
              </Button>
            </StyledCallOut>
          )}
        </PlanInformation>

        <ButtonContainer>
          {renderCancelButton()}
          {renderConfirmButton()}
        </ButtonContainer>
      </Content>
    </StyledSectionCard>
  );
}

export default SelectNormalPlan;

const StyledSectionCard = styled(SectionCard)`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  button {
    flex: 1;
    text-align: center;
  }
`;

const WhiteSpaceType = styled(Typo)`
  white-space: pre-wrap;
`;

const PlanInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const CardItem = styled.div`
  grid-column: span 1;
`;

const Card = styled.div<{ isSelect: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  cursor: pointer;
  flex: 1;

  & > ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  & > .gap-16 {
    gap: 16px;
  }

  & > .gap-8 {
    gap: 8px;
  }

  &:hover {
    border-color: ${colorSet.indigo};
  }

  ${({ isSelect }) =>
    isSelect &&
    css`
      border-color: ${colorSet.indigo};
    `}
`;

const StyledCallOut = styled(CallOut)`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1);
  margin: 0 auto;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &[data-selected="true"] {
    background: ${colorSet.white};
    border: 1px solid ${colorSet.blue4};
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const WhiteSpaceTypo = styled(Typo)`
  white-space: pre-wrap;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LoaderContainer = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 0;
`;

const StyledButton = styled(Button)`
  text-align: center;
  height: 100%;
`;
