import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAlert from "@/src/hooks/useAlert";
import { CopiedBadge } from "@/src/pages/exporter/Task";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useLazyGetShipmentDetailTasksQuery } from "@/src/store/apis/shipments/shipmentDetail";
import { ShipmentDetailTaskListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { columnLoadingTab } from "../columnTaskTab";

interface TaskCardProps extends HTMLAttributes<HTMLDivElement> {}

const shipmentWithLoadingListEmptyArray: ShipmentDetailTaskListViewDto[] = [];

function TaskCard({ ...props }: TaskCardProps) {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();

  const [columnDefs] = useState<ColDef[]>(columnLoadingTab);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  const [
    getShipmentDetailLoadings,
    { isFetching, shipmentWithLoadingList, count },
  ] = useLazyGetShipmentDetailTasksQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isFetching,
        count: !isUnstable ? currentData.count : 0,
        shipmentWithLoadingList: !isUnstable
          ? (currentData.rows ?? shipmentWithLoadingListEmptyArray)
          : shipmentWithLoadingListEmptyArray,
      };
    },
  });

  // Submit
  const handleSubmit = useCallback(
    async (param?: { page?: number; pageSize?: number }) => {
      const page = param?.page || pagination.page;
      const pageSize = param?.pageSize || pagination.pageSize;
      const loadingListParams = { page, pageSize, id: Number(params.id) };

      try {
        await getShipmentDetailLoadings(loadingListParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getShipmentDetailLoadings,
      pagination.page,
      pagination.pageSize,
      params.id,
    ],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard
      cardTitle={
        <FlexAlignCenter gap={16}>
          <Typo typoType="h6" as="h2">
            {t("shipment:exporter.detail.label.loading")}
          </Typo>

          <FlexAlignCenter>
            <CopiedBadge size="xs">C</CopiedBadge>
            <Typo typoType="b9r" color="gray6">
              {t("common:combinedSplitedLoading")}
            </Typo>
          </FlexAlignCenter>
        </FlexAlignCenter>
      }
      {...props}
    >
      <Table
        ref={gridRef}
        rowData={shipmentWithLoadingList}
        totalPage={count}
        columnDefs={columnDefs}
        isPaginationDataMaping
        handlePaginationClick={handlePaginationClick}
        pageSize={pagination.pageSize}
        page={pagination.page}
        onRowClicked={({ data }) => {
          return navigate(`${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${data.id}`);
        }}
      />
    </SectionCard>
  );
}

export default TaskCard;

const FlexDiv = styled.div<{ gap?: number; direction?: "column" | "row" }>`
  display: flex;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `};
`;

const FlexAlignCenter = styled(FlexDiv)`
  align-items: center;
`;
