import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { Button } from "@/src/components/atom/Button";
import CheckboxGroup from "@/src/components/atom/CheckboxGroup";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import MultipleInput from "@/src/components/atom/Input/MultipleInput";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import BasicTextArea from "@/src/components/atom/TextArea/BasicTextArea";
import Typo from "@/src/components/atom/Typo";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { TEMPLATE_OPTION_VALUE } from "@/src/constant/emailDialog";
import useAlert from "@/src/hooks/useAlert";
import SharedEmailBuyerEmployeeAddDialog from "@/src/pages/exporter/ClientManagement/dialog/SharedEmailBuyerEmployeeAddDialog";
import PRIVATE_PATH from "@/src/routes/private/path";
import { useAppSelector } from "@/src/store";
import { authApi } from "@/src/store/apis/auth";
import {
  bookingDetailApi,
  useBookingDetailSendEmailMutation,
} from "@/src/store/apis/bookings/bookingDetail";
import { SimpleContractDto } from "@/src/store/apis/bookings/bookingDetail/interface";
import { useCreateBuyerEmployeesMutation } from "@/src/store/apis/client/buyer";
import {
  BuyerEmployeeDto,
  GenerateBuyerEmployeeDto,
} from "@/src/store/apis/client/buyer/interface";
import { useLazyGetContractDetailBuyerEmployeesQuery } from "@/src/store/apis/contracts/contractDetail";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

interface EmailDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CANCEL,
}

enum DialogState {
  NULL,
  ADD_CONTACT_PERSON,
}

const buyerEmptyArray: BuyerEmployeeDto[] = [];
const simpleContractEmptyArray: SimpleContractDto[] = [];

function EmailDialog({ open, onOpenChange }: EmailDialogProps) {
  const { t } = useTranslation();
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const recommendRef = useRef<HTMLDivElement>(null);

  const params = useParams();
  const alert = useAlert();
  const company = useAppSelector((state) => state.auth.user);

  // API
  const [getBuyerEmployees, { buyerList, isBuyerFetching }] =
    useLazyGetContractDetailBuyerEmployeesQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          buyerList: !isUnstable
            ? (currentData.rows ?? buyerEmptyArray)
            : buyerEmptyArray,
          isBuyerFetching: isFetching,
        };
      },
    });
  const { bookingNo, simpleContracts, isSimpleContractsFetching } =
    bookingDetailApi.endpoints.getBookingDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            simpleContracts: !isUnstable
              ? (currentData?.simpleContracts ?? simpleContractEmptyArray)
              : simpleContractEmptyArray,
            isSimpleContractsFetching: isFetching,
            bookingNo: !isUnstable ? currentData?.bookingNo : "",
          };
        },
      },
    );
  const [createEmployee] = useCreateBuyerEmployeesMutation();
  const [sendEmail, { isLoading }] = useBookingDetailSendEmailMutation({
    selectFromResult: ({ isLoading }) => {
      return { isLoading };
    },
  });

  const { exporterUserType, userMainCategory } =
    authApi.endpoints.getSession.useQueryState(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          userMainCategory: isStable
            ? currentData.row.mainCategoryCodeItemNames
            : [],
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    });

  // State
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [isRecommendTitleOpen, setIsRecommendTitleOpen] = useState(false);

  const {
    control,
    watch,
    setValue,
    setFocus,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<{
    buyerId: string;
    contractId?: string;
    email: string[];
    emailKeyword: string;
    cc: string;
    emailCcList: string[];
    emailTitle: string;
    emailContent: string;
    template: string[];
  }>({
    mode: "all",
    defaultValues: {
      buyerId: "",
      contractId: undefined,
      emailKeyword: "",
      email: [],
      cc: "",
      emailCcList: [],
      emailTitle: "",
      emailContent: "",
      template: [TEMPLATE_OPTION_VALUE.booking],
    },
  });

  // Option List
  const simpleBuyerList = simpleContracts?.map((item) => {
    return {
      label: `${item.buyerName}`,
      value: String(item.buyerId),
      mainCategory: item.mainCategory,
    };
  });

  const simpleBuyerMappingList =
    exporterUserType === "MANAGER"
      ? simpleBuyerList?.filter((simpleBuyer) => {
          return userMainCategory.includes(
            simpleBuyer.mainCategory.replace(/-/g, "_").toLocaleUpperCase(),
          );
        })
      : simpleBuyerList;

  const uniqueBuyerIds = simpleBuyerMappingList.reduce(
    (acc: Record<string, string>, obj) => {
      const { label, value } = obj;

      if (!acc[label]) {
        acc[label] = value;
      }

      return acc;
    },
    {},
  );
  const buyerOptionList = Object.keys(uniqueBuyerIds).map((label) => ({
    label,
    value: uniqueBuyerIds[label],
  }));

  const recipientsOptionList = buyerList.map((item) => {
    return {
      label: `${item.name} (${item.email})`,
      value: String(item.id),
    };
  });

  const getContractOptionList = (): { label: string; value: string }[] => {
    if (!simpleContracts) {
      return [];
    }

    // 유저 타입 매니저인지
    if (exporterUserType === "MANAGER") {
      return simpleContracts
        .filter(
          ({ buyerId, mainCategory }) =>
            String(buyerId) === getValues("buyerId") &&
            userMainCategory.includes(
              mainCategory.toUpperCase().replace(/-/g, "_"),
            ),
        )
        .map(({ scNo, exporterItem, id }) => {
          return {
            label: `${exporterItem} > ${scNo}`,
            value: id.toString(),
          };
        });
    } else {
      return simpleContracts
        .filter(({ buyerId }) => String(buyerId) === getValues("buyerId"))
        .map(({ scNo, exporterItem, id }) => {
          return {
            label: `${exporterItem} > ${scNo}`,
            value: id.toString(),
          };
        });
    }
  };

  const isContractTemplate = getValues("template").includes(
    TEMPLATE_OPTION_VALUE.contract,
  );
  const selectContract = simpleContracts.find(
    (item) => String(item.id) === watch("contractId"),
  );

  const defaultPoNoAndScNo = selectContract
    ? [
        selectContract.poNo && `${t("common:poNo")}${selectContract.poNo}`,
        `${t("common:scNo")}${selectContract.scNo}`,
      ]
        .filter(Boolean)
        .join(" / ")
    : "";

  const defaultTitle = `[${company?.exporter.companyName}] ${t(
    "common:shareBookingInformation",
  )} (${t("common:bookingNo")}${bookingNo}${
    isContractTemplate && selectContract ? ` / ${defaultPoNoAndScNo}` : ""
  })`;

  useEffect(() => {
    setFocus("buyerId");
  }, [setFocus]);

  const handleRecommendOutsideClick = (e: MouseEvent): void => {
    if (
      titleContainerRef.current &&
      !titleContainerRef.current.contains(e.target as Node)
    ) {
      setIsRecommendTitleOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleRecommendOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleRecommendOutsideClick);
    };
  }, []);

  const handleBuyerClick = async (buyerId: number) => {
    try {
      await getBuyerEmployees({ id: buyerId }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const isValidEmail = (value: string) => {
    const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

    return emailRegex.test(value);
  };

  const handleCreateEmployee = async (
    params: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    },
  ) => {
    try {
      const res = await createEmployee({
        id: Number(getValues("buyerId")),
        email: params.email,
        name: params.name,
        isActivated: params.isActivated === "Y",
        officeContact: params.officeContact || undefined,
        officeContactPrefix: params.officeContactPrefix || undefined,
        personalContact: params.personalContact || undefined,
        personalContactPrefix: params.personalContactPrefix || undefined,
        buyerEmployeeRemark: params.buyerEmployeeRemark || undefined,
      }).unwrap();

      setDialogState(DialogState.NULL);
      handleBuyerClick(Number(getValues("buyerId")));
      clearErrors("email");

      return res.row.id.toString();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });

      return;
    }
  };

  // Submit
  const handleSubmitClick = async () => {
    const emailCcList = getValues("emailCcList").filter((email) =>
      isValidEmail(email),
    );

    const buyerEmployeeIdList =
      buyerList
        .filter((buyer) =>
          getValues("email").some((id) => id === String(buyer.id)),
        )
        .map((item) => item.id) ?? [];

    const emailParams = {
      id: Number(params.id),
      emailCcList,
      emailTitle: getValues("emailTitle"),
      emailContent: getValues("emailContent")
        ? getValues("emailContent")
        : undefined,
      contractId: getValues("template").includes(TEMPLATE_OPTION_VALUE.contract)
        ? Number(getValues("contractId"))
        : undefined,
      buyerId: Number(getValues("buyerId")),
      buyerEmployeeIdList,
    };

    try {
      await sendEmail(emailParams).unwrap();
      onOpenChange(false);

      alert.showAlert({
        type: "success",
        message: t("booking:detail.alert.emailAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handlePreviewClick = () => {
    window.open(
      `${PRIVATE_PATH.SHARED_EMAIL_BOOKING}?preview=true&bookingId=${
        params.id
      }${
        isContractTemplate ? `&contractId=${getValues("contractId")}` : ""
      }&template=${getValues("template")}`,
      "_blank",
    );
  };

  const handleRecommendTitleClick = () => {
    clearErrors("emailTitle");
    setValue("emailTitle", defaultTitle);
    setFocus("emailTitle");
    setIsRecommendTitleOpen(false);
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelAlertDialog
          open={alertDialogState === AlertDialogState.CANCEL}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => onOpenChange(false)}
        />
      );
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      return (
        <SharedEmailBuyerEmployeeAddDialog
          onSave={async (e) => {
            const employee = await handleCreateEmployee(e);

            if (employee) {
              setValue("email", [employee, ...getValues("email")]);
            }
          }}
          onClose={() => {
            setDialogState(DialogState.NULL);
          }}
        />
      );
    }
  };

  return (
    <Dialog
      title={t("emailDialog:dialogTitle")}
      width={850}
      open={open}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setAlertDialogState(AlertDialogState.CANCEL)}
          >
            <Typo typoType="btn3m">{t("emailDialog:button.cancelButton")}</Typo>
          </Button>
          <Button
            onClick={handleSubmit(handleSubmitClick)}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t("emailDialog:button.sendButton")}
          </Button>
        </DialogFooterContainer>
      }
    >
      {renderAlertDialog()}
      {renderDialog()}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        {/* Buyer */}
        <FormItem
          label={t("common:buyer")}
          name="buyerId"
          direction="horizontal"
          rules={{ required: true }}
          type="singleSelect"
          control={control}
          options={buyerOptionList}
          inputProps={{
            suffixIcon: isSimpleContractsFetching ? <Loader /> : undefined,
            placeholder: t("emailDialog:placeholder.buyer"),
            onChange: (value) => {
              handleBuyerClick(value);
              setValue("email", []);
              setValue("contractId", undefined);
              setFocus("email");
            },
            getPopupContainer: (triggerNode) => {
              return triggerNode.parentElement;
            },
            filterOption: (input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase()) ||
              ((option?.value as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase()),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />

        {/* Recipients */}
        <RecipientsContainer>
          <RecipientsForm
            label={t("emailDialog:label.recipients")}
            type="multipleSelect"
            name="email"
            rules={{ required: true }}
            control={control as any}
            options={recipientsOptionList}
            direction="horizontal"
            inputProps={{
              suffixIcon: isBuyerFetching ? <Loader /> : undefined,
              placeholder: t("emailDialog:placeholder.email"),
              getPopupContainer: (triggerNode: any) => {
                return triggerNode.parentElement;
              },
              onRemoveItem: (value) => {
                const watchCategory = watch("email");
                const watchCategoryFilter = watchCategory.filter(
                  (item) => item !== value,
                );
                setValue("email", watchCategoryFilter);
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <StyledButton
            buttonSize={40}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setDialogState(DialogState.ADD_CONTACT_PERSON)}
          >
            <AddIcon />
            <Typo typoType="btn3m">
              {t("emailDialog:button.addContactPersonButton")}
            </Typo>
          </StyledButton>
        </RecipientsContainer>

        {/* CC */}
        <StyledSectionCardRow
          label="CC"
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="cc"
              render={({ field }) => (
                <MultipleInput
                  placeholder={t("emailDialog:placeholder.cc")}
                  value={field.value}
                  valueList={watch("emailCcList")}
                  onChangeValue={(value) => setValue("cc", value)}
                  onChangeValueList={(valueList) =>
                    setValue("emailCcList", valueList)
                  }
                  validation={isValidEmail}
                />
              )}
            />
          }
        />

        <GrayLine />

        {/* Template */}
        <StyledSectionCardRow
          label={<Label>{t("emailDialog:label.template")}</Label>}
          direction="horizontal"
          value={
            <TemplateController>
              <Controller
                control={control}
                name="template"
                render={({ field }) => (
                  <CheckboxGroup
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setFocus("emailTitle");
                    }}
                    options={[
                      {
                        label: t("emailDialog:templateOption.contract"),
                        value: TEMPLATE_OPTION_VALUE.contract,
                      },
                      {
                        label: t("emailDialog:templateOption.booking"),
                        value: TEMPLATE_OPTION_VALUE.booking,
                        disabled: true,
                      },
                    ]}
                  />
                )}
              />

              <StyledButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handlePreviewClick}
              >
                <PreviewIcon />
                <Typo typoType="btn3m">
                  {t("emailDialog:button.previewTemplateButton")}
                </Typo>
              </StyledButton>
            </TemplateController>
          }
        />

        {/* Item > SC No. */}
        <StyledSectionCardRow
          label={t("common:itemAndScNo")}
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="contractId"
              rules={{ required: isContractTemplate }}
              render={({ field }) => (
                <ContractContainer>
                  <StyledSelect
                    {...field}
                    showSearch
                    placeholder={t("emailDialog:placeholder.item")}
                    options={getContractOptionList()}
                    disabled={!isContractTemplate}
                    getPopupContainer={(triggerNode: any) => {
                      return triggerNode.parentElement;
                    }}
                    data-invalid={!!errors.contractId}
                    onSelect={(value) => {
                      setValue("contractId", value);
                      setFocus("emailTitle");
                    }}
                    filterOption={(input, option) =>
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      ((option?.value as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                  {errors.contractId && (
                    <InputError
                      message={t("emailDialog:errorMessage.selectScNo")}
                    />
                  )}
                </ContractContainer>
              )}
            />
          }
        />

        {/* Title */}
        <TitleContainer ref={titleContainerRef}>
          <FormItem
            label={t("emailDialog:label.title")}
            name="emailTitle"
            control={control}
            type="text"
            rules={{ required: true }}
            inputProps={{
              placeholder: t("emailDialog:placeholder.title"),
              onClick: () => {
                setIsRecommendTitleOpen(true);
              },
              onFocus: () => {
                setIsRecommendTitleOpen(true);
              },
              onChange: () => {
                setIsRecommendTitleOpen(true);
              },
              onKeyDown: (e) => {
                if (e.shiftKey && e.code === "Tab") {
                  setIsRecommendTitleOpen(false);
                }
                if (e.code === "ArrowDown") {
                  recommendRef.current && recommendRef.current.focus();
                }
                if (e.code === "Enter") {
                  setIsRecommendTitleOpen(false);
                }
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          {isRecommendTitleOpen && (
            <RecommendTitleContainer>
              <RecommendTitle typoType="b9r" color="gray7">
                {t("common:recommendTitle")}
              </RecommendTitle>

              <RecommendText
                ref={recommendRef}
                tabIndex={0}
                onClick={handleRecommendTitleClick}
                onKeyDown={(e) => {
                  if (e.code === "ArrowUp") {
                    setFocus("emailTitle");
                  }
                  if (e.code === "Enter") {
                    handleRecommendTitleClick();
                  }
                }}
                onBlur={() => {
                  setIsRecommendTitleOpen(false);
                }}
              >
                <Typo>{defaultTitle}</Typo>
              </RecommendText>
            </RecommendTitleContainer>
          )}
        </TitleContainer>

        {/* Contents */}
        <StyledSectionCardRow
          label={t("emailDialog:label.emailContent")}
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="emailContent"
              render={({ field }) => (
                <StyledTextArea
                  placeholder={t("emailDialog:placeholder.emailContent")}
                  {...field}
                />
              )}
            />
          }
        />
      </Form>
    </Dialog>
  );
}

export default EmailDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  p {
    width: 100%;
  }
`;

const ControllerContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
`;

const TemplateController = styled(ControllerContainer)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const GrayLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  height: max-content;
`;

const StyledTextArea = styled(BasicTextArea)`
  min-height: 379px;
`;

const Label = styled(Typo)`
  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const PreviewIcon = styled(PreviewSvg)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const RecipientsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const RecipientsForm = styled(FormItem)`
  width: 100%;
`;

const TitleContainer = styled.div`
  position: relative;
`;

const RecommendTitleContainer = styled(SectionCardWithoutHeader)`
  position: absolute;
  right: 0;
  top: 46px;
  width: calc(100% - 172px);
  background: ${colorSet.white};
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-radius: 8px;
`;

const RecommendTitle = styled(Typo)`
  padding: 8px 16px 0;
`;

const RecommendText = styled.div`
  cursor: pointer;
  padding: 8px 16px;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
    outline: none;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &[data-invalid="true"] {
    div.ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
`;

const ContractContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 632px);
  gap: 8px;
`;
