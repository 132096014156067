import CheckCircleSvg from "@/src/assets/icons/icon-checkmark-circle-green-fill.svg";
import FooterPng from "@/src/assets/images/img-email-footer.png";
import WorldMapPng from "@/src/assets/images/img-world-map.png";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import useCompanyHomeRedirect from "@/src/hooks/useCompanyHomeRedirect";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useGetEnterpriseInquiriesQuery,
  useGetPaymentMethodsQuery,
} from "@/src/store/apis/subscription";
import { PaymentMethodDto } from "@/src/store/apis/subscription/interface";
import { isUndefined } from "@/src/utils/is";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const emptyArray: PaymentMethodDto[] = [];

function SubscriptionLandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyType, exporterUserType, isCompanyTypeFetching } =
    useGetSessionQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching }) => {
        return {
          isCompanyTypeFetching: isFetching,
          companyType:
            currentData?.row.exporterUserType === "CORPORATE_MANAGER"
              ? currentData?.row.exporter.companyType
              : currentData?.row.exporterUserMainFieldType,
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    });
  const { isInquiriesFetching, inquiries } = useGetEnterpriseInquiriesQuery(
    {},
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          // 문의여부
          isInquiriesFetching: isFetching,
          inquiries: isStable ? currentData.rows : [],
        };
      },
    }
  );
  const { payments, isPaymentFetching } = useGetPaymentMethodsQuery(
    { page: 1, pageSize: 2 },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isPaymentFetching: isFetching,
          payments: isStable ? currentData.rows : emptyArray,
        };
      },
    }
  );

  const {
    func: { redirectNonCorporate },
  } = useCompanyHomeRedirect({
    companyType,
    userType: exporterUserType,
  });

  const descriptionList = [
    t("shareEmail:sharedFooter.digitalizedContainerInspection"),
    t("shareEmail:sharedFooter.automaticDocumentation"),
    t("shareEmail:sharedFooter.RealTimeCargoTracking"),
    t("shareEmail:sharedFooter.highUtilizationDashboard"),
  ];

  const goToHome = () => {
    switch (companyType) {
      case "BOTH":
      case "SELLER":
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
      case "BUYER":
        return navigate(IMPORTER_PRIVATE_PATH.HOME);
      default:
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  };

  /**
   *  - 결제수단이 있는경우 query추가
   */
  const handleNormalPlanSignupClick = () => {
    if (payments.length === 0) {
      return navigate(EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP);
    } else {
      return navigate(
        `${EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP}?isInquiry=${true}`
      );
    }
  };

  /**
   *  - 결제수단이 없고, 엔터프라이즈 문의 최신상태가 문의중일경우
   */
  const redirectEnterpriseSignup = useCallback(() => {
    if (inquiries.length === 0) {
      return;
    }

    if (
      payments.length === 0 &&
      inquiries[0]?.status !== "CANCELED" &&
      inquiries[0]?.status !== "SUBSCRIPTION_COMPLETED"
    ) {
      return navigate(EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP);
    }
  }, [inquiries, navigate, payments.length]);

  useEffect(() => {
    redirectNonCorporate();
  }, [redirectNonCorporate]);

  useEffect(() => {
    if (payments.length >= 1) {
      redirectEnterpriseSignup();
    }
  }, [payments.length, redirectEnterpriseSignup]);

  if (isCompanyTypeFetching || isInquiriesFetching || isPaymentFetching) {
    return <></>;
  }

  return (
    <SignUpLayout onLogoClick={goToHome}>
      <StyledMain>
        <CopyrightText typoType="h1">
          {t("subscriptionLanding:copyrightText")}
        </CopyrightText>

        <Content>
          <Image src={FooterPng} />

          <ListContainer>
            <WorldMapImage src={WorldMapPng} />
            <List>
              {descriptionList.map((string, idx) => {
                return (
                  <ListItem key={idx.toString()}>
                    <StyledIcon iconSrc={CheckCircleSvg} iconSize={24} />
                    <Typo typoType="b7m">{string}</Typo>
                  </ListItem>
                );
              })}
            </List>
          </ListContainer>

          <StyledButton onClick={handleNormalPlanSignupClick}>
            {t("subscriptionLanding:button.subscriptionSignUp")}
          </StyledButton>
        </Content>
      </StyledMain>
    </SignUpLayout>
  );
}

export default SubscriptionLandingPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 560px;
  padding: 24px 16px;
  margin: 0 auto;
`;

const CopyrightText = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
  padding-bottom: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Image = styled.img`
  width: 528px;
  height: 396px;
  object-fit: cover;
  border-radius: 8px;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

const WorldMapImage = styled.img`
  width: 225px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledButton = styled(Button)`
  text-align: center;
`;
