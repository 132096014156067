import { ReactComponent as ArrowHookSvg } from "@/src/assets/icons/icon-arrow-hook-red.svg";
import { ReactComponent as DeleteSvg } from "@/src/assets/icons/icon-cancle-red.svg";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import ChevronRightSvg from "@/src/assets/icons/icon-chevron-right-white.svg";
import DotGray4Svg from "@/src/assets/icons/icon-dot-gray4.svg";
import DotGray7Svg from "@/src/assets/icons/icon-dot-gray7.svg";
import PenSvg from "@/src/assets/icons/icon-pen-white.svg";
import { ReactComponent as RedoSvg } from "@/src/assets/icons/icon-redo-blue.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import TabItem from "@/src/components/molecule/TabItem";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import EmailHistoryDialog from "@/src/components/organism/EmailHistoryDialog";
import NoRegisteredSignatureAlertDialog from "@/src/components/organism/NoRegisteredSignatureAlertDialog";
import SignatureRequestAlertDialog from "@/src/components/organism/SignatureRequestAlertDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppSelector } from "@/src/store";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import {
  useConfirmPoMutation,
  useConfirmScMutation,
  useGetContractDetailBookmarkQuery,
  useGetContractDetailBookmarkUserQuery,
  useGetContractShipmentSummaryInfoQuery,
  useLazyGetContractDetailQuery,
  useLazyGetExporterContractSignatureHistoriesQuery,
  useLazyGetExporterPoHistoriesQuery,
  useLazyGetExporterScHistoriesQuery,
  useRejectPoMutation,
  useUpdateContractDetailBookmarkMutation,
  useUpdateContractDetailStatusMutation,
} from "@/src/store/apis/contracts/contractDetail";
import { ContractBookmarkListViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import { useLazyGetEmailShareHistoriesQuery } from "@/src/store/apis/emailShareHistories";
import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { css, styled } from "styled-components";
import ContractDocumentTab from "./components/detail/ContractDocumentTab";
import ContractFilesTab from "./components/detail/ContractFilesTab";
import ContractInformationTab from "./components/detail/ContractInformationTab";
import CancelContractAlertDialog from "./components/detail/dialog/CancelContractAlertDialog";
import EmailDialog from "./components/detail/dialog/EmailDialog";
import RedoAlertDialog from "./components/detail/dialog/RedoAlertDialog";
import RegisterSignDialog from "./components/detail/dialog/RegisterSignDialog";
import RejectReasonDialog from "./components/detail/dialog/RejectReasonDialog";
import ProcessingTab from "./components/detail/ProcessingTab";
import renderContractStatusBadge from "./utils/renderContractStatusBadge";
import SignatureSelectDialog from "@/src/components/organism/SignatureSelectDialog";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { getContractCurrentVersion } from "@/src/utils/getContractCurrentVersion";

enum DialogState {
  NULL,
  SEND_EMAIL,
  SENT_HISTORY,
  BOOK_MARK,
  REGISTER_SIGN,
  REJECT_PO_REASON,
}

enum AlertDialogState {
  NULL,
  CANCEL,
  REDO,
  SIGN_PO,
  REJECT_PO,
  CONFIRM_REQUEST_SC,
  REGISTER_SIGN,
  SIGNATURE_REQUEST,
  SIGNATURE_SELECT_PO,
  SIGNATURE_SELECT_SC,
}

type TabType =
  | "contractInformation"
  | "contractDocument"
  | "processing"
  | "files";

const TAB_LIST: {
  id: TabType;
  label: string;
}[] = [
  {
    id: "contractInformation",
    label: "common:exporterContractInformation",
  },
  {
    id: "contractDocument",
    label: "common:exporterContractDocument",
  },
  {
    id: "processing",
    label: "common:processing",
  },
  {
    id: "files",
    label: "common:files",
  },
];

const bookmarkEmptyArray: ContractBookmarkListViewDto[] = [];
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ExporterContractDetailPage = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const subscription = useAppSelector((state) => state.subscription);

  // State
  const [tab, setTab] = useState<TabType>(() => {
    const tabParam = searchParams.get("selectTab");
    return (tabParam as TabType) || "contractInformation";
  });
  const [poVersion, setPoVersion] = useState<number>();
  const [scVersion, setScVersion] = useState<number>();
  const [processingTab, setProcessingTab] = useState<
    "booking" | "loading" | "shipment"
  >("booking");
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  // API
  const { mainCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          mainCategoryList: isStable
            ? (currentData ?? mainCategoryEmptyArray)
            : mainCategoryEmptyArray,
        };
      },
    },
  );
  const { currentData: buyers } = useGetBuyersQuery({ isActivated: true });

  const {
    userId,
    exporterUserType,
    userMainCategory,
    isUserFetching,
    exporterUserMainFieldType,
  } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        isUserFetching: isFetching,
        userId: currentData?.row.id,
        userMainCategory: isStable
          ? currentData.row.mainCategoryCodeItemNames
          : [],
        exporterUserType: currentData?.row.exporterUserType,
        exporterUserMainFieldType: currentData?.row.exporterUserMainFieldType,
      };
    },
  });

  const { hasSign, isTwoSign, signatureManagement } = useGetExportersQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          hasSign: isStable
            ? currentData.signatureManagement.simpleExporterSignatureMedias
                ?.length > 0
            : false,
          isTwoSign: isStable
            ? currentData.signatureManagement.simpleExporterSignatureMedias
                ?.length === 2
            : false,
          signatureManagement: isStable
            ? currentData.signatureManagement.simpleExporterSignatureMedias
            : [],
        };
      },
    },
  );

  const { balanceOfContractV2, totalShippingWeightV2, percentOfShipment } =
    useGetContractShipmentSummaryInfoQuery(
      { id: Number(params.id) },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            balanceOfContractV2: !isErrorAndUndefined
              ? (currentData?.balanceOfContractV2 ?? "-")
              : "-",
            totalShippingWeightV2: !isErrorAndUndefined
              ? (currentData?.totalShippingWeightV2 ?? "-")
              : "-",
            percentOfShipment:
              !isErrorAndUndefined && currentData.percentOfShipment
                ? Number(currentData?.percentOfShipment)
                : 0,
          };
        },
      },
    );
  const [
    getContractDetail,
    {
      clientName,
      isContractError,
      contractWriterId,
      contractStatus,
      contractUpdatedAt,
      contractCreatedAt,
      contractLastEditorName,
      contractWriterName,
      scNo,
      poNo,
      isContractDetailFetching,
      isContractUnstable,
      isContractStable,
      contractMainCategory,
      contractMainCategoryForImporter,
      signatureStatus,
      buyerId,
    },
  ] = useLazyGetContractDetailQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;
      const isStable = !isUnstable;

      return {
        buyerId: isStable ? currentData.buyerId : undefined,
        clientName: isStable ? currentData.buyerName : "",
        isContractUnstable: isUnstable,
        isContractStable: isStable,
        isContractError: isError,
        isContractDetailFetching: isFetching,
        contractWriterId: isStable ? currentData.contractWriterId : null,
        contractStatus: isStable ? currentData.contractStatus : "NOT_LINKED",
        contractUpdatedAt:
          isStable && currentData.updatedAt
            ? `(${dayjs(currentData.updatedAt).format(
                DATE_FORMAT_STRINGS.YY_MM_DD,
              )},${dayjs(currentData.updatedAt).format(
                DATE_FORMAT_STRINGS.HH_mm,
              )})`
            : "-",
        contractCreatedAt:
          isStable && currentData.createdAt
            ? `(${dayjs(currentData.createdAt).format(
                DATE_FORMAT_STRINGS.YY_MM_DD,
              )},${dayjs(currentData.createdAt).format(
                DATE_FORMAT_STRINGS.HH_mm,
              )})`
            : "-",
        contractLastEditorName: isStable
          ? (currentData.contractLastEditorName ?? "-")
          : "-",
        contractWriterName: isStable
          ? (currentData.contractWriterName ?? "-")
          : "-",
        scNo: isStable ? (currentData.scNo ?? "") : "",
        poNo: isStable ? (currentData.poNo ?? "") : "",
        scSimpleMedia: isStable ? currentData?.scSimpleMedia : null,
        contractMainCategory: isStable ? (currentData.mainCategory ?? "") : "",
        contractMainCategoryForImporter: isStable
          ? (currentData.mainCategoryForImporter ?? "")
          : "",
        signatureStatus: isStable ? currentData.signatureStatus : "DRAFT",
      };
    },
  });
  const { isBookmarked } = useGetContractDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return { isBookmarked: currentData?.isBookmarked };
      },
    },
  );
  const { bookmarkUserList } = useGetContractDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookmarkUserList: !isUnstable
            ? (currentData.rows ?? bookmarkEmptyArray)
            : bookmarkEmptyArray,
        };
      },
    },
  );
  const [editContractBookmark] = useUpdateContractDetailBookmarkMutation();
  const [contractStatusChange] = useUpdateContractDetailStatusMutation();
  const [
    getContractSharedHistories,
    {
      count,
      emailHistoryList,
      isEmailShareHistoriesFetching,
      isEmailShareHistoriesError,
    },
  ] = useLazyGetEmailShareHistoriesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        count: !isUnstable ? currentData.count : 0,
        emailHistoryList: !isUnstable ? currentData.rows : undefined,
        isEmailShareHistoriesError: isError,
        isEmailShareHistoriesFetching: isFetching,
      };
    },
  });

  const [
    getContractSignatureHistories,
    {
      isOnlyReceivedPo,
      isPending,
      isExporterPoSigned,
      isImporterPoSigned,
      hasLinkedBefore,
      isSendedSc,
      previousSignature,
      currentSignature,
    },
  ] = useLazyGetExporterContractSignatureHistoriesQuery({
    selectFromResult: ({ currentData }) => ({
      previousSignature: currentData?.rows[1],
      currentSignature: currentData?.rows[0],
      isOnlyReceivedPo:
        currentData?.rows[0].signatureStatus === "PENDING" ||
        (currentData?.rows[0].signatureStatus === "SENT" &&
          currentData?.rows[0].isImporterSignedPO),
      isPending: currentData?.rows[0].signatureStatus === "PENDING",
      isExporterPoSigned: !!currentData?.rows[0].isExporterSignedPO,
      isImporterPoSigned: !!currentData?.rows[0].isImporterSignedPO,
      hasLinkedBefore: currentData?.rows.some(
        ({
          isExporterSignedSC,
          isImporterSignedSC,
          isExporterSignedPO,
          isImporterSignedPO,
        }) =>
          isExporterSignedSC &&
          isImporterSignedSC &&
          isExporterSignedPO &&
          isImporterSignedPO,
      ),
      isSendedSc: currentData?.rows[0].isExporterSignedSC,
    }),
  });

  const [getExporterPoHistories] = useLazyGetExporterPoHistoriesQuery();
  const [getExporterScHistories, { scHistories, isZeroSc }] =
    useLazyGetExporterScHistoriesQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          scHistories: currentData?.rows ?? [],
          isZeroSc: isError,
        };
      },
    });

  const [confirmSc, { isLoading: isConfirmingSc }] = useConfirmScMutation();
  const [confirmPo, { isLoading: isConfirmingPo }] = useConfirmPoMutation();
  const [rejectPo, { isLoading: isRejectingPo }] = useRejectPoMutation();

  // State
  const [documentTab, setDocumentTab] = useState<"po" | "sc">("po");
  const [filesTab, setFilesTab] = useState<"list" | "gallery">("list");
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );

  const defaultSignatureMediaId = signatureManagement?.find(
    (item) => item.isDefault,
  )?.signatureSimpleMedia.id as number;

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const mainCategoryItemName = mainCategoryList.find(
    (item) =>
      item.codeItemName ===
        contractMainCategory.toUpperCase().replace(/-/g, "_") ||
      item.codeItemName ===
        contractMainCategoryForImporter.toUpperCase().replace(/-/g, "_"),
  )?.codeItemName;

  // 매니저,중간관리자 카테고리 비교
  const isMainCategoryAuthorized =
    exporterUserType === "MANAGER" || exporterUserType === "MIDDLE_MANAGER"
      ? userMainCategory.includes(mainCategoryItemName as string)
      : true;

  // 권한이 없는 유저경우
  const isMainCategoryUnauthorized = !isMainCategoryAuthorized;

  /**
   * 컨펌요청 disabled
   * - 모든 서명 완료, SC미생성
   * - 미연결 되어있는 거래처
   * - 버전업 이후 SC생성전
   */
  const requestConfirmScDisabled = useMemo(() => {
    if (signatureStatus === "LINKED" || isZeroSc) {
      return true;
    }

    if (
      signatureStatus === "APPROVED" &&
      getContractCurrentVersion(scHistories).currentVersion >= 2 &&
      getContractCurrentVersion(scHistories).currentVersionHistoryLength === 1
    ) {
      return true;
    }

    if (
      contractStatus === "NOT_LINKED" &&
      buyers?.data.find(
        ({ buyerListQueryResultDto }) => buyerId === buyerListQueryResultDto.id,
      )?.buyerListQueryResultDto.linkedStatus !== "COMPLETED"
    ) {
      return true;
    }
  }, [
    signatureStatus,
    isZeroSc,
    scHistories,
    contractStatus,
    buyers?.data,
    buyerId,
  ]);

  /**
   * 연결되어있는 SC수정버튼 disable
   */
  const isLinkedScEditDisabled = useMemo(() => {
    if (currentSignature?.signatureStatus === "SENT") {
      return true;
    }

    if (contractStatus === "PROCESSING") {
      if (
        currentSignature?.signatureStatus === "APPROVED" &&
        !currentSignature?.isExporterSignedSC
      ) {
        return false;
      }

      if (!hasLinkedBefore && !previousSignature?.isExporterSignedSC) {
        return true;
      }
    }
  }, [
    contractStatus,
    currentSignature?.isExporterSignedSC,
    currentSignature?.signatureStatus,
    hasLinkedBefore,
    previousSignature?.isExporterSignedSC,
  ]);

  /**
   * 수정권한 (유저상태)
   * - 기업관리자, 중간관리자, 작성자
   * - 일반 매니저는 메인카테고리가 포함된 사람
   */
  const isEditAuth =
    !isContractError &&
    (userId === contractWriterId || isMainCategoryAuthorized);

  /**
   * - 수정권한이 없는유저
   * - 거래처가 연결되어있지 않는 경우
   * - 서명 요청상태 Request
   * - 버전업 이후 서명 요청상태 PO를 Pending 한 경우
   * - 버전업 이전 SC를 요청, 거래처와 연결이 되어있지 않고, 서명이 모두 완료상태
   */
  const isDisabledEdit = useMemo(() => {
    if (
      !isEditAuth ||
      !!isLinkedScEditDisabled ||
      signatureStatus === "REQUESTED"
    ) {
      return true;
    }

    if (
      hasLinkedBefore &&
      signatureStatus === "PENDING" &&
      previousSignature?.isImporterSignedPO &&
      !previousSignature?.isExporterSignedPO
    ) {
      return true;
    }

    return (
      !!isSendedSc &&
      contractStatus !== "NOT_LINKED" &&
      signatureStatus !== "LINKED"
    );
  }, [
    isEditAuth,
    isLinkedScEditDisabled,
    signatureStatus,
    hasLinkedBefore,
    previousSignature?.isImporterSignedPO,
    previousSignature?.isExporterSignedPO,
    isSendedSc,
    contractStatus,
  ]);

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };

      await editContractBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContractStatusChangeClick = async (
    type: "COMPLETE" | "PROCESSING",
  ) => {
    try {
      const contractParams = {
        contractStatus: type,
        contractId: Number(params.id),
      };

      await contractStatusChange(contractParams).unwrap();

      if (type === "COMPLETE") {
        alert.showAlert({
          type: "success",
          message: t("contract:exporter.detail.alert.completeAlert"),
        });
      }

      if (type === "PROCESSING") {
        alert.showAlert({
          type: "success",
          message: t("contract:exporter.detail.alert.redoAlert"),
        });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDocument = async (data: {
    type: "confirmPo" | "confirmSc" | "rejectPo";
    rejectReason?: string;
    signatureMediaId?: number;
  }) => {
    try {
      handleContentLoadingOn();
      const id = Number(params.id);
      switch (data.type) {
        case "confirmPo":
          if (data.signatureMediaId) {
            await confirmPo({
              id,
              signatureMediaId: data.signatureMediaId,
            }).unwrap();
          }
          alert.showAlert({
            type: "success",
            message: t(
              "contract:exporter.detail.alert.confirmationRequestCompletedAlert",
            ),
          });
          break;
        case "confirmSc":
          if (data.signatureMediaId) {
            await confirmSc({
              id,
              signatureMediaId: data.signatureMediaId,
            }).unwrap();
          }
          alert.showAlert({
            type: "success",
            message: t(
              "contract:exporter.detail.alert.signatureCompletedAlert",
            ),
          });
          break;
        case "rejectPo":
          await rejectPo({ id, rejectReason: data.rejectReason }).unwrap();
          alert.showAlert({
            type: "success",
            message: t("contract:exporter.detail.alert.rejectCompletedAlert"),
          });
          break;
      }
      await getContractDetail({
        id: Number(params.id),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setDialogState(DialogState.NULL);
      setAlertDialogState(AlertDialogState.NULL);
      handleContentLoadingOff();
    }
  };

  const handleTabChange = (newTab: TabType) => {
    setTab(newTab);
    setSearchParams({ selectTab: newTab }, { replace: true });
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }
    if (dialogState === DialogState.SEND_EMAIL) {
      return (
        <EmailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
    if (dialogState === DialogState.SENT_HISTORY) {
      return (
        <EmailHistoryDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isFetching={isEmailShareHistoriesFetching}
          isError={isEmailShareHistoriesError}
          emailHistoryList={emailHistoryList}
          totalCount={count}
          fetch={getContractSharedHistories}
          emailShareDomain={"CONTRACT"}
          emailShareDomainId={Number(params.id)}
        />
      );
    }
    if (dialogState === DialogState.REJECT_PO_REASON) {
      return (
        <RejectReasonDialog
          onClose={() => setDialogState(DialogState.NULL)}
          onSubmit={(rejectReason) => {
            handleDocument({ type: "rejectPo", rejectReason });
          }}
        />
      );
    }
    if (dialogState === DialogState.REGISTER_SIGN) {
      return (
        <RegisterSignDialog
          id={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  const renderAlertDialogs = () => {
    const closeAlertDialog = () => setAlertDialogState(AlertDialogState.NULL);
    // 계약 완료
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelContractAlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          onOk={() => {
            closeAlertDialog();
            handleContractStatusChangeClick("COMPLETE");
          }}
        />
      );
    }
    // 계약 되돌리기
    if (alertDialogState === AlertDialogState.REDO) {
      return (
        <RedoAlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          onOk={() => {
            closeAlertDialog();
            handleContractStatusChangeClick("PROCESSING");
          }}
        />
      );
    }
    // PO서명 (1개일 경우)
    if (alertDialogState === AlertDialogState.SIGN_PO) {
      return (
        <AlertDialog
          open
          onOpenChange={closeAlertDialog}
          title={t("contract:exporter.detail.alertDialog.signContractTitle")}
          description={t(
            "contract:exporter.detail.alertDialog.signContractDescription",
          )}
          onOk={() =>
            handleDocument({
              type: "confirmPo",
              signatureMediaId: defaultSignatureMediaId,
            })
          }
          onCancel={closeAlertDialog}
          cancelText={t("contract:exporter.detail.button.cancelButton")}
          okText={t("contract:exporter.detail.button.okButton")}
        />
      );
    }
    // PO서명 서명선택 (서명 2개일 경우)
    if (alertDialogState === AlertDialogState.SIGNATURE_SELECT_PO) {
      return (
        <SignatureSelectDialog
          open
          onSave={(selectSignatureMediaId) => {
            handleDocument({
              type: "confirmPo",
              signatureMediaId: selectSignatureMediaId,
            });
          }}
          onOpenChange={closeAlertDialog}
        />
      );
    }
    // PO반려
    if (alertDialogState === AlertDialogState.REJECT_PO) {
      return (
        <AlertDialog
          open
          onOpenChange={closeAlertDialog}
          title={t("contract:exporter.detail.alertDialog.rejectContractTitle")}
          description={t(
            "contract:exporter.detail.alertDialog.rejectContractDescription",
          )}
          onOk={() => handleDocument({ type: "rejectPo" })}
          onCancel={closeAlertDialog}
          cancelText={t("contract:exporter.detail.button.cancelButton")}
          okText={t("contract:exporter.detail.button.okButton")}
        />
      );
    }
    // SC컨펌요청 (서명 1개일 경우)
    if (alertDialogState === AlertDialogState.CONFIRM_REQUEST_SC) {
      return (
        <AlertDialog
          open
          onOpenChange={closeAlertDialog}
          title={t(
            "contract:exporter.detail.alertDialog.requestConfirmationTitle",
          )}
          description={t(
            "contract:exporter.detail.alertDialog.requestConfirmationDescription",
          )}
          onOk={() =>
            handleDocument({
              type: "confirmSc",
              signatureMediaId: defaultSignatureMediaId,
            })
          }
          onCancel={closeAlertDialog}
          cancelText={t("contract:exporter.detail.button.cancelButton")}
          okText={t("contract:exporter.detail.button.okButton")}
        />
      );
    }
    // SC컨펌요청 (서명 2개일 경우)
    if (alertDialogState === AlertDialogState.SIGNATURE_SELECT_SC) {
      return (
        <SignatureSelectDialog
          open
          onSave={(selectSignatureMediaId) => {
            handleDocument({
              type: "confirmSc",
              signatureMediaId: selectSignatureMediaId,
            });
          }}
          onOpenChange={closeAlertDialog}
        />
      );
    }
    // 서명등록
    if (alertDialogState === AlertDialogState.REGISTER_SIGN) {
      return (
        <NoRegisteredSignatureAlertDialog
          open
          onOpenChange={closeAlertDialog}
          onOk={() => {
            setDialogState(DialogState.REGISTER_SIGN);
            closeAlertDialog();
          }}
          onCancel={closeAlertDialog}
        />
      );
    }
    // 서명등록 요청
    if (alertDialogState === AlertDialogState.SIGNATURE_REQUEST) {
      return (
        <SignatureRequestAlertDialog
          open
          onOpenChange={closeAlertDialog}
          onCancel={closeAlertDialog}
        />
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    if (isContractDetailFetching || isUserFetching) {
      return;
    }

    if (isContractUnstable || isMainCategoryUnauthorized) {
      return (
        <NoDataContainer>
          <UnauthorizedDescription />
        </NoDataContainer>
      );
    }
  };

  const renderFooter = () => {
    switch (tab) {
      case "contractInformation":
        return (
          <div>
            {contractStatus === "COMPLETE" ? (
              <StyledButton
                buttonColor="blue"
                buttonGrade="secondary"
                onClick={() => setAlertDialogState(AlertDialogState.REDO)}
                disabled={!isEditAuth}
              >
                <RedoIcon disabled={!isEditAuth} />
                {t("contract:exporter.detail.bottomBottom.contractRedoButton")}
              </StyledButton>
            ) : (
              <StyledButton
                buttonColor="red"
                buttonGrade="secondary"
                onClick={() => setAlertDialogState(AlertDialogState.CANCEL)}
                disabled={!isEditAuth || isCreateDisabled}
              >
                <DeleteIcon disabled={!isEditAuth || isCreateDisabled} />
                {t(
                  "contract:exporter.detail.bottomBottom.contractCancelButton",
                )}
              </StyledButton>
            )}

            <StyledButton
              onClick={() =>
                navigate(
                  `${EXPORTER_PRIVATE_PATH.BOOKING_ADD}?contractId=${Number(
                    params.id,
                  )}`,
                )
              }
              disabled={
                contractStatus === "COMPLETE" || !isEditAuth || isCreateDisabled
              }
            >
              {t("contract:exporter.detail.bottomBottom.createBookingButton")}
              <Icon iconSrc={ChevronRightSvg} iconSize={16} />
            </StyledButton>
          </div>
        );
      case "contractDocument":
        if (documentTab === "po") {
          return (
            <div>
              <StyledButton
                buttonColor="red"
                buttonGrade="secondary"
                onClick={() => setDialogState(DialogState.REJECT_PO_REASON)}
                disabled={
                  !isEditAuth ||
                  !isImporterPoSigned ||
                  isExporterPoSigned ||
                  isRejectingPo ||
                  isConfirmingPo ||
                  isPending
                }
                isLoading={isRejectingPo || isConfirmingPo}
              >
                <ArrowHookIcon
                  disabled={
                    !isEditAuth ||
                    !isImporterPoSigned ||
                    isExporterPoSigned ||
                    isRejectingPo ||
                    isConfirmingPo ||
                    isPending
                  }
                />
                {t("contract:exporter.detail.button.rejectContractButton")}
              </StyledButton>

              <StyledButton
                onClick={() => {
                  if (!hasSign) {
                    /**
                     * - 기업관리자, 중간관리자 서명등록
                     * - 일반 매니저 기업관리자에게 서명요청
                     */
                    if (exporterUserType !== "MANAGER") {
                      setAlertDialogState(AlertDialogState.REGISTER_SIGN);
                    } else {
                      setAlertDialogState(AlertDialogState.SIGNATURE_REQUEST);
                    }
                  } else {
                    // 서명이 있는 경우
                    setAlertDialogState(
                      isTwoSign
                        ? AlertDialogState.SIGNATURE_SELECT_PO
                        : AlertDialogState.SIGN_PO,
                    );
                  }
                }}
                disabled={
                  !isEditAuth ||
                  !isImporterPoSigned ||
                  isExporterPoSigned ||
                  isRejectingPo ||
                  isConfirmingPo ||
                  isPending
                }
                isLoading={isRejectingPo || isConfirmingPo}
              >
                <Icon iconSrc={PenSvg} iconSize={16} />
                {t("contract:exporter.detail.button.signContractButton")}
              </StyledButton>
            </div>
          );
        }
        return (
          <StyledButton
            onClick={() =>
              setAlertDialogState(
                isTwoSign
                  ? AlertDialogState.SIGNATURE_SELECT_SC
                  : AlertDialogState.CONFIRM_REQUEST_SC,
              )
            }
            isLoading={isConfirmingSc}
            disabled={isDisabledEdit || requestConfirmScDisabled}
          >
            {t("contract:exporter.detail.button.requestForConfirmationButton")}
          </StyledButton>
        );
    }
  };

  const renderContentByTab = () => {
    switch (tab) {
      case "contractInformation":
        return (
          <ContractInformationTab
            isEditAuth={isEditAuth}
            balanceOfContractV2={balanceOfContractV2}
            percentOfShipment={percentOfShipment}
            totalShippingWeightV2={totalShippingWeightV2}
            emailDialogOn={() => setDialogState(DialogState.SEND_EMAIL)}
            historyDialogOn={() => setDialogState(DialogState.SENT_HISTORY)}
            isOnlyReceivedPo={isOnlyReceivedPo}
            isDisabledEdit={isDisabledEdit}
          />
        );
      case "contractDocument":
        return (
          <ContractDocumentTab
            tab={documentTab}
            onTabChange={(tab) => setDocumentTab(tab)}
            poVersion={poVersion}
            scVersion={scVersion}
            poNo={poNo}
            scNo={scNo}
            contractStatus={contractStatus}
            onVersionChange={({ type, version }) => {
              if (type === "po") {
                setPoVersion(version);
              }
              if (type === "sc") {
                setScVersion(version);
              }
            }}
          />
        );
      case "processing":
        return (
          <ProcessingTab
            tab={processingTab}
            onTabChange={setProcessingTab}
            signatureStatus={signatureStatus}
            hasLinkedBefore={!!hasLinkedBefore}
          />
        );
      case "files":
        return <ContractFilesTab tab={filesTab} onTabChange={setFilesTab} />;
    }
  };

  const renderContractDetailContent = () => {
    if (isContractUnstable || isMainCategoryUnauthorized) {
      return;
    }

    return (
      <>
        {/* Content */}
        <FlexColumn>
          {/* Header */}
          <HeaderContainer>
            <HeaderButtonContainer>
              {renderContractStatusBadge({
                t,
                type: contractStatus as ContractStatusType | null,
                size: "L",
              })}
            </HeaderButtonContainer>
            <HeaderButtonContainer>
              {/* BookMark */}
              <BookMarkStatus
                bookMarkStatus={isBookmarked}
                bookMarkCount={bookmarkUserList.length}
                onClickBookMark={handleBookmarkClick}
                onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
              />
            </HeaderButtonContainer>
          </HeaderContainer>

          <Tabs role="tablist">
            {TAB_LIST.map(({ id, label }) => {
              return (
                <StyledTabItem
                  key={id}
                  tabIndex={id === tab ? 0 : -1}
                  isSelect={id === tab}
                  tabValue={id}
                  onClick={() => handleTabChange(id)}
                  onFocusItem={(value) => handleTabChange(value as TabType)}
                >
                  <Typo typoType="b5m" color={id === tab ? "white" : "gray6"}>
                    {t(label)}
                  </Typo>
                </StyledTabItem>
              );
            })}
          </Tabs>
          {renderContentByTab()}
          {/* Last Editor */}
          <EditorContainer>
            <figure>
              <Icon iconSrc={DotGray7Svg} iconSize={4} />
              <Typo color="gray6" typoType="b9r">
                {`${t("contract:exporter.detail.editor.registratant")} (${t(
                  "contract:exporter.detail.editor.registratantAt",
                )}) ${contractWriterName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {contractCreatedAt}
              </Typo>
            </figure>
            <figure>
              <Icon iconSrc={DotGray4Svg} iconSize={4} />
              <Typo color="gray4" typoType="b9r">
                {`${t("contract:exporter.detail.editor.lastEditor")} (${t(
                  "contract:exporter.detail.editor.lastEditorAt",
                )}) ${contractLastEditorName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {contractUpdatedAt}
              </Typo>
            </figure>
          </EditorContainer>
        </FlexColumn>

        {/* Bottom Fixed */}
        <BottomFixedContainer>
          <BottomButtonContainer>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeft} iconSize={16} />
              {t("contract:exporter.detail.bottomBottom.backToPreviousButton")}
            </StyledButton>
            {renderFooter()}
          </BottomButtonContainer>
        </BottomFixedContainer>
      </>
    );
  };

  useEffect(() => {
    const fetchContractDetail = async (retryCount = 0) => {
      try {
        const { contractStatus } = await getContractDetail({
          id: Number(params.id),
        }).unwrap();

        if (contractStatus === "NOT_LINKED") {
          setDocumentTab("sc");
        }
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        if (e.data.statusCode === 404) {
          alert.showAlert({ type: "error", message });
          return;
        }

        if (retryCount < 5) {
          handleContentLoadingOn();
          setTimeout(() => {
            fetchContractDetail(retryCount + 1);
          }, 1000);
        } else {
          handleContentLoadingOff();
          alert.showAlert({ type: "error", message });
        }
      }
    };

    fetchContractDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContractDetail, params.id]);

  useEffect(() => {
    (async () => {
      const poResult = await getExporterPoHistories({
        contractId: Number(params.id),
      });
      const scResult = await getExporterScHistories({
        contractId: Number(params.id),
      });

      await getContractSignatureHistories({ id: Number(params.id) });

      if (poResult.isError) {
        setDocumentTab("sc");
      }

      if (!poVersion) {
        setPoVersion?.(poResult.data?.rows?.[0].id);
      }
      if (!scVersion) {
        setScVersion?.(scResult.data?.rows?.[0].id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  // URL에 selectTab이 없으면 기본값 설정
  useEffect(() => {
    if (!searchParams.get("selectTab")) {
      setSearchParams({ selectTab: "contractInformation" }, { replace: true });
      setTab("contractInformation");
    }
  }, [searchParams, setSearchParams, params.id]);

  return (
    <ExporterMainLayout
      breadcrumb={
        isContractStable && isMainCategoryAuthorized
          ? [t("sideNav:contract"), t("sideNav:contractDetail")]
          : []
      }
      pageTitle={
        <>
          {isContractStable &&
            isMainCategoryAuthorized &&
            `${t("contract:exporter.detail.header.title")} ${scNo ?? "-"}`}

          {isMainCategoryAuthorized && <ClientName>{clientName}</ClientName>}
        </>
      }
    >
      {/* Contents */}
      {renderContractDetailContent()}
      {renderUnauthorizedDescription()}
      {/* Dialogs , AlertDialogs*/}
      {renderDialogs()}
      {renderAlertDialogs()}
    </ExporterMainLayout>
  );
};

export default ExporterContractDetailPage;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    gap: 8px;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  figure {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const ArrowHookIcon = styled(ArrowHookSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.red6};
      `}
  }
`;

const DeleteIcon = styled(DeleteSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.red6};
      `}
  }
`;

const RedoIcon = styled(RedoSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClientName = styled(Typo).attrs({
  as: "h5",
})`
  padding-top: 8px;
  color: ${colorSet.gray1};
  ${typo.b5m}
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-right: 1px solid ${colorSet.gray9};
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};
  border: 1px solid ${colorSet.gray9};

  &:last-child {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
