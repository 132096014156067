import Badge from "@/src/components/atom/Badge";
import Typo from "@/src/components/atom/Typo";
import getTableIndex from "@/src/utils/getTableIndex";
import { CELL_TYPE_EDIT_ACTION } from "@/src/utils/row-data-util";
import { ColDef, ValueGetterParams } from "ag-grid-community";

export const columnItemList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    width: 60,
    resizable: false,
    cellStyle: { textAlign: "center" },
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "item",
    flex: 1,
    minWidth: 200,
    sortable: false,
  },

  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "subCategory",
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "mainCategory",
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "description",
    flex: 1,
    minWidth: 200,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.description ? params.data.description : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "isActivated",
    lockPosition: "right",
    pinned: "right",
    resizable: false,
    width: 90,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeColor="systemLime5"
            color="systemLime1"
            badgeSize="S"
          />
        );
      }
      return (
        <Badge text="NO" badgeColor="gray10" color="gray7" badgeSize="S" />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "edit",
    lockPosition: "right",
    pinned: "right",
    resizable: false,
    width: 90,
    sortable: false,
    cellRenderer: CELL_TYPE_EDIT_ACTION,
  },
];
