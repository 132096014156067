import RangePicker from "@/src/components/atom/RangePicker";
import Typo from "@/src/components/atom/Typo";
import TabItem from "@/src/components/molecule/TabItem";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import {
  initialState,
  reducer,
  TransactionStatisticAction,
} from "../util/volumeStatisticsReducer";
import ItemCodeVolumeStatistic from "./ItemCodeVolumeStatistic";
import ItemVolumeStatistic from "./ItemVolumeStatistic";
import DatePicker from "@/src/components/atom/DatePicker";

const today = dayjs();

const VolumeStatistics = () => {
  const { t } = useTranslation();
  const rangePickerRef = useRef<{
    blur: () => void;
    focus: () => void;
  }>(null);
  const [tempDate, setTempDate] = useState<[Dayjs | null, Dayjs | null]>();
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>([
    today.clone().subtract(1, "month"),
    today.clone().subtract(1, "day"),
  ]);

  const [
    {
      tab,
      periodInItem,
      periodInItemCode,
      dailyPeriodInItem,
      monthlyPeriodInItem,
      dailyPeriodInItemCode,
      monthlyPeriodInItemCode,
      dailyItemsInItem,
      dailyCategoryInItem,
      monthlyCategoryInItem,
      monthlyItemsInItem,
      dailyItemCodeInItemCode,
      monthlyItemCodeInItemCode,
      dailyCategoryInItemCode,
      monthlyCategoryInItemCode,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const isDailyTab = useMemo(
    () =>
      (tab === "item" && periodInItem === "daily") ||
      (tab === "itemCode" && periodInItemCode === "daily"),
    [tab, periodInItem, periodInItemCode],
  );

  const isMonthlyTab = useMemo(
    () =>
      (tab === "item" && periodInItem === "monthly") ||
      (tab === "itemCode" && periodInItemCode === "monthly"),
    [tab, periodInItem, periodInItemCode],
  );

  const getRange = () => {
    if (tab === "item") {
      if (periodInItem === "daily") {
        return dailyPeriodInItem;
      }
      return monthlyPeriodInItem;
    } else {
      if (periodInItemCode === "daily") {
        return dailyPeriodInItemCode;
      }
      return monthlyPeriodInItemCode;
    }
  };

  const getPreset = useMemo(() => {
    return [
      {
        label: t("common:duration.threeMonths"),
        from: today.clone().subtract(3, "month"),
        to: today.clone().subtract(1, "day"),
      },
      {
        label: t("common:duration.sixMonths"),
        from: today.clone().subtract(6, "month"),
        to: today.clone().subtract(1, "day"),
      },
      {
        label: t("common:duration.oneYear"),
        from: today.clone().subtract(1, "year"),
        to: today.clone().subtract(1, "day"),
      },
    ];
  }, [t]);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }

    const diffUnit = isDailyTab ? "days" : "month";
    const diffMeasurement = isDailyTab ? 31 : 24;

    const tooLate =
      dates[0] && current.diff(dates[0], diffUnit) >= diffMeasurement;
    const tooEarly =
      dates[1] && dates[1].diff(current, diffUnit) >= diffMeasurement;
    return !!tooEarly || !!tooLate;
  };

  const handlePresetClick = ({ from, to }: { from: Dayjs; to: Dayjs }) => {
    const value = [from, to];
    dispatch({
      type: TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_MONTHLY_TAB,
      payload: value,
    });
    dispatch({
      type: TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_CODE_MONTHLY_TAB,
      payload: value,
    });
  };

  const renderTabContent = () => {
    if (tab === "item") {
      return (
        <ItemVolumeStatistic
          tab={periodInItem}
          period={
            periodInItem === "daily" ? dailyPeriodInItem : monthlyPeriodInItem
          }
          exporterItemId={
            periodInItem === "daily" ? dailyItemsInItem : monthlyItemsInItem
          }
          category={
            periodInItem === "daily"
              ? dailyCategoryInItem
              : monthlyCategoryInItem
          }
          onCategoryChange={(value) => {
            dispatch({
              type:
                periodInItem === "daily"
                  ? TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_DAILY_TAB
                  : TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_MONTHLY_TAB,
              payload: value,
            });
            dispatch({
              type:
                periodInItem === "daily"
                  ? TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_DAILY_TAB
                  : TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_MONTHLY_TAB,
              payload: ["all"],
            });
          }}
          onItemChange={(value) => {
            const currentItems =
              periodInItem === "daily" ? dailyItemsInItem : monthlyItemsInItem;

            if (value && currentItems.includes(value)) {
              dispatch({
                type:
                  periodInItem === "daily"
                    ? TransactionStatisticAction.DELETE_ITEM_IN_ITEM_DAILY_TAB
                    : TransactionStatisticAction.DELETE_ITEM_IN_ITEM_MONTHLY_TAB,
                payload: value,
              });
            } else {
              if (value === "all") {
                return dispatch({
                  type:
                    periodInItem === "daily"
                      ? TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_DAILY_TAB
                      : TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_MONTHLY_TAB,
                  payload: ["all"],
                });
              }
              dispatch({
                type:
                  periodInItem === "daily"
                    ? TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_DAILY_TAB
                    : TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_MONTHLY_TAB,
                payload: [...currentItems, value].filter(
                  (item) => item !== "all",
                ),
              });
            }
          }}
          onRemoveItem={(value) => {
            if (value === "all") return;
            dispatch({
              type:
                periodInItem === "daily"
                  ? TransactionStatisticAction.DELETE_ITEM_IN_ITEM_DAILY_TAB
                  : TransactionStatisticAction.DELETE_ITEM_IN_ITEM_MONTHLY_TAB,
              payload: value,
            });
          }}
        />
      );
    }
    return (
      <ItemCodeVolumeStatistic
        tab={periodInItemCode}
        period={
          periodInItemCode === "daily"
            ? dailyPeriodInItemCode
            : monthlyPeriodInItemCode
        }
        exporterItemId={
          periodInItemCode === "daily"
            ? dailyItemCodeInItemCode
            : monthlyItemCodeInItemCode
        }
        category={
          periodInItemCode === "daily"
            ? dailyCategoryInItemCode
            : monthlyCategoryInItemCode
        }
        onCategoryChange={(value) => {
          dispatch({
            type:
              periodInItemCode === "daily"
                ? TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_CODE_DAILY_TAB
                : TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_CODE_MONTHLY_TAB,
            payload: value,
          });
          dispatch({
            type:
              periodInItemCode === "daily"
                ? TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_DAILY_TAB
                : TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
            payload: ["all"],
          });
        }}
        onItemChange={(value) => {
          const currentItems =
            periodInItemCode === "daily"
              ? dailyItemCodeInItemCode
              : monthlyItemCodeInItemCode;

          if (value && currentItems.includes(value)) {
            dispatch({
              type:
                periodInItemCode === "daily"
                  ? TransactionStatisticAction.DELETE_ITEM_IN_ITEM_CODE_DAILY_TAB
                  : TransactionStatisticAction.DELETE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
              payload: value,
            });
          } else {
            if (value === "all") {
              return dispatch({
                type:
                  periodInItemCode === "daily"
                    ? TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_DAILY_TAB
                    : TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
                payload: ["all"],
              });
            }
            dispatch({
              type:
                periodInItemCode === "daily"
                  ? TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_DAILY_TAB
                  : TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
              payload: [...currentItems, value].filter(
                (item) => item !== "all",
              ),
            });
          }
        }}
        onRemoveItem={(value) => {
          if (value === "all") return;
          dispatch({
            type:
              periodInItem === "daily"
                ? TransactionStatisticAction.DELETE_ITEM_IN_ITEM_CODE_DAILY_TAB
                : TransactionStatisticAction.DELETE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
            payload: value,
          });
        }}
      />
    );
  };

  const renderPresetHeader = () => {
    return (
      <Typo as="p" typoType="h6">
        {tempDate?.[0]?.format("YY.MM.DD") || "-"} ~{" "}
        {tempDate?.[1]?.format("YY.MM.DD") || "-"}
      </Typo>
    );
  };

  const renderDatePicker = () => {
    // 일간
    if (isDailyTab) {
      return (
        <StyledDatePicker
          ref={rangePickerRef}
          value={getRange()[1]}
          placement="bottomRight"
          onChange={(value) => {
            const subtractDate = dayjs(value).subtract(30, "day");
            dispatch({
              type: TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_DAILY_TAB,
              payload: [subtractDate, value],
            });
            dispatch({
              type: TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_CODE_DAILY_TAB,
              payload: [subtractDate, value],
            });
          }}
          onOpenChange={(open) => {
            if (open) {
              setTempDate(dailyPeriodInItem);
              setTempDate(dailyPeriodInItemCode);
            }
          }}
        />
      );
    }
    // 월간
    if (isMonthlyTab) {
      return (
        <StyledRangePicker
          ref={rangePickerRef}
          value={getRange()}
          onChange={(value) => {
            dispatch({
              type: TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_MONTHLY_TAB,
              payload: value,
            });
            dispatch({
              type: TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_CODE_MONTHLY_TAB,
              payload: value,
            });
          }}
          onCalendarChange={(values) => {
            if (values !== null) {
              setTempDate(values);
              setDates(values);
            }
          }}
          onOpenChange={(open) => {
            if (open) {
              setTempDate(
                tab === "item" ? monthlyPeriodInItem : monthlyPeriodInItemCode,
              );
            }
          }}
          disabledDate={disabledDate}
          presets={[]}
          dropdownClassName={
            (periodInItem === "monthly" && tab === "item") ||
            (periodInItemCode === "monthly" && tab === "itemCode")
              ? "custom-range-picker-header small-header"
              : "custom-range-picker-header"
          }
          renderExtraFooter={() => {
            return (
              <>
                <PresetHeader>
                  <Typo as="p" typoType="h6">
                    {renderPresetHeader()}
                  </Typo>
                </PresetHeader>
                <PresetContent>
                  {getPreset.map(({ label, from, to }) => {
                    return (
                      <button
                        onClick={() => {
                          handlePresetClick({ from, to });
                          if (rangePickerRef.current) {
                            rangePickerRef.current?.blur?.();
                          }
                        }}
                      >
                        {label}
                      </button>
                    );
                  })}
                </PresetContent>
              </>
            );
          }}
        />
      );
    }
  };

  return (
    <>
      <TabsContainer>
        <Tabs role="tablist">
          <StyledTabItem
            tabValue={"item"}
            tabIndex={tab === "item" ? 0 : -1}
            onFocus={() => {
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM,
                payload: periodInItemCode === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: periodInItemCode === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_TAB,
                payload: "item",
              });
            }}
            onClick={() => {
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM,
                payload: periodInItemCode === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: periodInItemCode === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_TAB,
                payload: "item",
              });
            }}
            data-selected={tab === "item"}
          >
            <Typo typoType="b7m">{t("common:item")}</Typo>
          </StyledTabItem>
          <StyledTabItem
            tabValue={"itemCode"}
            tabIndex={tab === "itemCode" ? 0 : -1}
            onFocus={() => {
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM,
                payload: periodInItem === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: periodInItem === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_TAB,
                payload: "itemCode",
              });
            }}
            onClick={() => {
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM,
                payload: periodInItem === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: periodInItem === "daily" ? "daily" : "monthly",
              });
              dispatch({
                type: TransactionStatisticAction.CHANGE_TAB,
                payload: "itemCode",
              });
            }}
            data-selected={tab === "itemCode"}
          >
            <Typo typoType="b7m">{t("common:itemCode")}</Typo>
          </StyledTabItem>
        </Tabs>

        <PeriodTabs>
          <StyledPeriodTabItem
            tabValue={"daily"}
            tabIndex={
              tab === "item"
                ? periodInItem === "daily"
                  ? 0
                  : -1
                : periodInItemCode === "daily"
                  ? 0
                  : -1
            }
            onFocus={() =>
              dispatch({
                type:
                  tab === "item"
                    ? TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM
                    : TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: "daily",
              })
            }
            onClick={() =>
              dispatch({
                type:
                  tab === "item"
                    ? TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM
                    : TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: "daily",
              })
            }
            data-selected={
              tab === "item"
                ? periodInItem === "daily"
                : periodInItemCode === "daily"
            }
          >
            {t("dashboard:exporter.daily")}
          </StyledPeriodTabItem>
          <StyledPeriodTabItem
            tabValue={"monthly"}
            tabIndex={
              tab === "item"
                ? periodInItem === "monthly"
                  ? 0
                  : -1
                : periodInItemCode === "monthly"
                  ? 0
                  : -1
            }
            onFocus={() =>
              dispatch({
                type:
                  tab === "item"
                    ? TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM
                    : TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: "monthly",
              })
            }
            onClick={() =>
              dispatch({
                type:
                  tab === "item"
                    ? TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM
                    : TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
                payload: "monthly",
              })
            }
            data-selected={
              tab === "item"
                ? periodInItem === "monthly"
                : periodInItemCode === "monthly"
            }
            style={{ borderRadius: "0 8px 8px 0px" }}
          >
            {t("dashboard:exporter.monthly")}
          </StyledPeriodTabItem>
          {/* DatePicker */}
          {renderDatePicker()}
        </PeriodTabs>
      </TabsContainer>
      <div>{renderTabContent()}</div>
    </>
  );
};

export default VolumeStatistics;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${colorSet.gray10};
  margin-bottom: 16px;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border: none;
  cursor: pointer;
  background: none;

  &[data-selected="true"] {
    padding: 10px 13px 8px 13px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const PeriodTabs = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledPeriodTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  background: ${colorSet.gray11};
  color: ${colorSet.gray7};
  border: 1px solid ${colorSet.gray9};
  ${typo.b9m};

  &[data-selected="true"] {
    background: ${colorSet.white};
    color: ${colorSet.blue4};
    border: 1px solid ${colorSet.blue4};
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }
`;

const TabsContainer = styled.div`
  position: relative;
`;

const StyledRangePicker = styled(RangePicker)`
  padding: 4px 11px 4px;
  margin-left: 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 4px 11px 4px;
  margin-left: 8px;
  width: 256px;
`;

const PresetHeader = styled.div`
  padding: 12px 16px;
  text-align: center;
`;

const PresetContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  button {
    all: unset;
    text-align: center;
    cursor: pointer;
    border: 0.5px solid ${colorSet.gray9};
    ${typo.b9m};
    padding: 8px 16px;

    &:hover {
      background: ${colorSet.gray9};
    }
  }
`;
