import CheckSvg from "@/src/assets/icons/icon-check-white.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import { ReactNode } from "react";
import { css, styled } from "styled-components";
import Typo from "../../atom/Typo";

type StepItemType = {
  title: ReactNode;
  description?: ReactNode;
};

interface StepsProps {
  items: StepItemType[];
  current: number;
  circleColor?: ColorType;
  stepColor?: ColorType;
  stepTitleColor?: ColorType;
}

const Steps = ({
  items,
  current,
  circleColor = "blue4",
  stepColor = "white",
  stepTitleColor = "gray7",
}: StepsProps) => {
  return (
    <Article>
      {items.map((item, index) => {
        const stepIndex = index + 1;
        const isBeforeStep = current > stepIndex;
        const isCurrentStep = current === stepIndex;
        const isAfterStep = current < stepIndex;
        const isLastStep = stepIndex === items.length;
        const isFirstStep = stepIndex === 1;

        return (
          <Container>
            <StepIndicator
              isAfterStep={isAfterStep}
              data-first={isFirstStep}
              data-last={isLastStep}
              circleColor={circleColor}
            >
              {isBeforeStep ? (
                <span className="check-icon" />
              ) : (
                <Typo typoType="h10" color={isAfterStep ? "gray8" : stepColor}>
                  {stepIndex}
                </Typo>
              )}
            </StepIndicator>
            <StepTitle>
              <Typo
                typoType="b9m"
                color={isCurrentStep ? "gray1" : stepTitleColor}
              >
                {item.title}
              </Typo>
            </StepTitle>
            {item.description && <>{item.description}</>}
          </Container>
        );
      })}
    </Article>
  );
};

export default Steps;

const Article = styled.article`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

const StepIndicator = styled.div<{
  isAfterStep: boolean;
  circleColor: ColorType;
}>`
  width: 22px;
  height: 22px;
  border-radius: 64px;
  ${({ isAfterStep, circleColor }) =>
    isAfterStep
      ? css`
          background: ${colorSet.white};
          border: 1px solid ${colorSet.gray8};
        `
      : css`
          border: 1px solid ${colorSet.blue3};
          background: ${colorSet[circleColor]};
        `};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 9px;
  transition: all 0.15s ease-in-out;

  &[data-last="false"] {
    &::after {
      content: "";
      position: absolute;
      width: calc(50% - 20px);
      height: 4px;
      right: 0;
      background: ${colorSet.gray9};
      border-radius: 16px 0px 0px 16px;
    }
  }

  &[data-first="false"] {
    &::before {
      content: "";
      position: absolute;
      width: calc(50% - 20px);
      height: 4px;
      left: 0;
      background: ${colorSet.gray9};
      border-radius: 0px 16px 16px 0px;
    }
  }

  span.check-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(${CheckSvg}) center center;
  }
`;

const StepTitle = styled.p`
  white-space: pre-wrap;
`;
