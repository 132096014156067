import Table from "@/src/components/atom/Table";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { useLazyGetShipmentDetailTasksQuery } from "@/src/store/apis/shipments/shipmentDetail";
import { useLazyGetShipmentShareTasksQuery } from "@/src/store/apis/shipments/shipmentShare";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { columnShardLoadingTab } from "../../columns/columnShardLoadingTab";

interface ShipmentWithTaskProps {
  sharedShipmentId: number;
  shipmentShareKey: string;
}

function ShipmentWithTask({
  sharedShipmentId,
  shipmentShareKey,
}: ShipmentWithTaskProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();

  const [columnDefs] = useState<ColDef[]>(columnShardLoadingTab);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState(false);

  const isPreview = searchParams.get("preview") === "true";
  const previewShipmentId = Number(searchParams.get("shipmentId"));

  // API
  const [
    getShipmentDetailLoadings,
    { isFetching, shipmentWithLoadingList, count },
  ] = useLazyGetShipmentShareTasksQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isFetching,
        isError,
        count: !isUnstable ? currentData?.count : 0,
        shipmentWithLoadingList: !isUnstable ? currentData?.rows : undefined,
      };
    },
  });

  // Preview API
  const [
    getShipmentDetailLoadingsPreviewData,
    { isPreviewFetching, shipmentWithLoadingListPreviewData, previewCount },
  ] = useLazyGetShipmentDetailTasksQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isPreviewFetching: isFetching,
        previewCount: !isUnstable ? currentData.count : 0,
        shipmentWithLoadingListPreviewData: !isUnstable
          ? currentData.rows
          : undefined,
      };
    },
  });

  // Preview Submit
  const handlePreviewSubmit = useCallback(
    async (param?: { page?: number; pageSize?: number }) => {
      const page = param?.page || pagination.page;
      const pageSize = param?.pageSize || pagination.pageSize;
      const loadingListParams = { page, pageSize, id: previewShipmentId };

      try {
        await getShipmentDetailLoadingsPreviewData(loadingListParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getShipmentDetailLoadingsPreviewData,
      pagination.page,
      pagination.pageSize,
      previewShipmentId,
    ],
  );

  // Submit
  const handleSubmit = useCallback(
    async (params?: { page?: number; pageSize?: number }) => {
      const page = params?.page || pagination.page;
      const pageSize = params?.pageSize || pagination.pageSize;
      const taskListParams = {
        page,
        pageSize,
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      };

      try {
        await getShipmentDetailLoadings(taskListParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getShipmentDetailLoadings,
      pagination.page,
      pagination.pageSize,
      sharedShipmentId,
      shipmentShareKey,
    ],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    if (isPreview) {
      return handlePreviewSubmit({
        page,
        pageSize,
      });
    }

    handleSubmit({
      page,
      pageSize,
    });
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "no",
        langKey: "table:no",
      },
      {
        columnKey: "item",
        langKey: "table:item",
      },
      {
        columnKey: "numberOfTaskContainer",
        langKey: "table:noOfContainer",
      },
      {
        columnKey: "totalGrossWeight",
        langKey: "table:totalGrossWeight",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "table:totalNetWeight",
      },
      {
        columnKey: "totalQuantity",
        langKey: "table:totalUnitQty",
      },
    ],
  });

  // Loading
  useEffect(() => {
    if (isPreview) {
      if (isPreviewFetching && gridRef.current) {
        gridRef?.current?.api?.showLoadingOverlay();
      }
    } else {
      if (isFetching && gridRef.current) {
        gridRef?.current?.api?.showLoadingOverlay();
      }
    }
  }, [isFetching, isPreview, isPreviewFetching]);

  useEffect(() => {
    isPreview ? handlePreviewSubmit() : handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard cardTitle={t("shipment:exporter.detail.label.loading")}>
      <Table
        ref={gridRef}
        rowData={
          isPreview
            ? shipmentWithLoadingListPreviewData
            : shipmentWithLoadingList
        }
        totalPage={isPreview ? previewCount : count}
        isPaginationDataMaping
        columnDefs={columnDefs}
        handlePaginationClick={handlePaginationClick}
        pageSize={pagination.pageSize}
        page={pagination.page}
        rowClass={"ag-row-none-cursor"}
        onGridReady={() => setIsReady(true)}
      />
    </SectionCard>
  );
}

export default ShipmentWithTask;
