import colorSet from "@/src/styles/color";
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from "antd";
import MoonLoader from "react-spinners/MoonLoader";

interface SwitchProps extends AntdSwitchProps {
  checked: boolean;
  loading?: boolean;
}

function Switch({ checked, loading, ...props }: SwitchProps) {
  const renderSwitch = () => {
    if (loading) {
      return (
        <MoonLoader color={colorSet.blue4} size={20} speedMultiplier={0.5} />
      );
    }

    return <AntdSwitch checked={checked} {...props} />;
  };

  return renderSwitch();
}

export default Switch;
