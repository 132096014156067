import Flex from "@/src/components/molecule/Flex";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import { useGetBookingContractsQuery } from "@/src/store/apis/shipments/importerShipment";
import { isUndefined } from "@/src/utils/is";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BookingContractCardItem from "./BookingContractCardItem";

interface ContractInformationCardProps {
  id: number;
}

const EMPTY_ARRAY: [] = [];

const ContractInformationCard = ({ id }: ContractInformationCardProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { contractList } = useGetBookingContractsQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;

        return {
          contractList: isStable ? currentData.rows : EMPTY_ARRAY,
        };
      },
    },
  );
  return (
    <SectionFoldableCard
      open={open}
      onOpenChange={setOpen}
      cardTitle={t("shipment:importer.detail.label.contractInformation")}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={open}
          onClick={() => setOpen((open) => !open)}
        />
      }
    >
      <Flex flexDirection="column" gap={24}>
        {contractList.map((data, index) => {
          return (
            <BookingContractCardItem
              data={data}
              index={index}
              key={`${data.id}_${index.toString()}`}
            />
          );
        })}
      </Flex>
    </SectionFoldableCard>
  );
};

export default ContractInformationCard;
