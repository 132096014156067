import bookingSvg from "@/src/assets/icons/icon-booking-gray4.svg";
import etcSvg from "@/src/assets/icons/icon-building-gray4.svg";
import contractSvg from "@/src/assets/icons/icon-document-gary4.svg";
import inquirySvg from "@/src/assets/icons/icon-inquiry-fill-blue4.svg";
import taskSvg from "@/src/assets/icons/icon-loading-gray4.svg";
import noticeSvg from "@/src/assets/icons/icon-notice-fill-blue4.svg";
import ribbonSvg from "@/src/assets/icons/icon-ribbon.svg";
import shipmentSvg from "@/src/assets/icons/icon-ship-gray4.svg";
import useAlert from "@/src/hooks/useAlert";
import { usePushAlarmReadMutation } from "@/src/store/apis/pushAlarms";
import { PushAlarmDto } from "@/src/store/apis/pushAlarms/interface";
import colorSet from "@/src/styles/color";
import getDateDifference from "@/src/utils/getDateDifference";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { RefObject } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Icon from "../../atom/Icon";
import Typo from "../../atom/Typo";
import { useAppDispatch } from "@/src/store";
import {
  closeNotification,
  openLinkCodeModal,
} from "@/src/store/slice/webUtil";

interface NotificationItemProps extends PushAlarmDto {
  domainRef: RefObject<HTMLButtonElement>;
  tabRef: RefObject<HTMLButtonElement>;
}

const notificationIcon = [
  { type: "CONTRACT", icon: contractSvg },
  { type: "BOOKING", icon: bookingSvg },
  { type: "TASK", icon: taskSvg },
  { type: "SHIPMENT", icon: shipmentSvg },
  { type: "NOTICE", icon: noticeSvg },
  { type: "INQUIRY", icon: inquirySvg },
  { type: "SUBSCRIPTION", icon: ribbonSvg },
  { type: "ETC", icon: etcSvg },
  { type: "EXPORTER", icon: etcSvg },
];

const pushAlarmBody = {
  clientLinkCode: "body.company.link.received",
};

const NotificationItem = ({
  title = "",
  isRead,
  payloadString,
  domainType,
  createdAt,
  subTitle,
  body = "",
  id,
  domainRef,
  tabRef,
  payloadJson,
}: NotificationItemProps) => {
  const { t } = useTranslation("dispatch");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const alert = useAlert();
  const navigate = useNavigate();
  const iconSrc = notificationIcon.find((item) => item.type === domainType)
    ?.icon as string;

  const [alarmUpdate] = usePushAlarmReadMutation();

  const handleNotificationClick = async (e: React.MouseEvent) => {
    /**
     * 클라이언트 링크 코드 알림 클릭 시 모달 오픈
     */
    if (body.trim() === pushAlarmBody.clientLinkCode.trim()) {
      e.preventDefault();
      e.stopPropagation();

      if (!payloadJson?.companyLinkCode) {
        return console.error("companyLinkCode가 없습니다:", payloadJson);
      }

      try {
        await alarmUpdate({ id, isRead: true });
        dispatch(closeNotification());
        dispatch(openLinkCodeModal(payloadJson?.companyLinkCode as string));
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.date.message[0]
          : e.date.message;
        alert.showAlert({ message, type: "error" });
      }
      return;
    }

    try {
      await alarmUpdate({ id, isRead: true });
      const splitedPayloadString = payloadString?.split("/")[0];
      if (
        splitedPayloadString === "import" ||
        splitedPayloadString === "export"
      ) {
        navigate(`/${payloadString}`);
      } else {
        const prefixPath = location.pathname.split("/")[1];
        navigate(`/${prefixPath}/${payloadString}`);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.shiftKey && e.code === "Tab") {
      domainRef.current?.focus();
    } else if (e.code === "Tab") {
      tabRef.current?.focus();
    }
  };

  const TranslatedText = ({ text }: { text: string }) => {
    return <Trans i18nKey={t(text)} values={payloadJson} />;
  };

  const renderNotificationContent = () => {
    return (
      <NotificationInfo>
        <Title>
          <Typo typoType="h7" className="title">
            <TranslatedText text={title} />
          </Typo>
          <Typo typoType="b9r" color="gray7" className="update-date">
            {getDateDifference({
              updateAt: createdAt,
              disableUtcNow: true,
            })}
          </Typo>
        </Title>

        {subTitle && (
          <SubTitle>
            <Typo typoType="b9r" color="blue2">
              <TranslatedText text={subTitle} />
            </Typo>
          </SubTitle>
        )}

        <Typo typoType="b7r" color="gray5" className="body">
          <TranslatedText text={body} />
        </Typo>
      </NotificationInfo>
    );
  };

  return (
    <NotificationItemContainer
      onKeyDown={handleKeyDown}
      onClick={handleNotificationClick}
    >
      <ReadBadge>{!isRead && <ReadBadgeIcon />}</ReadBadge>
      <NotificationIconContainer>
        <NotificationIcon iconSrc={iconSrc} iconSize={20} />
      </NotificationIconContainer>
      {renderNotificationContent()}
    </NotificationItemContainer>
  );
};

export default NotificationItem;

const NotificationItemContainer = styled(DropdownMenu.Item)`
  display: flex;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }

  &:focus {
    outline: none;
    background: ${colorSet.gray10};
  }
`;

const ReadBadge = styled.div`
  width: 10px;
  height: 10px;
  padding: 8px 0;
  flex-shrink: 0;
`;

const ReadBadgeIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${colorSet.red2};
`;

const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  .title,
  .body {
    word-break: break-all;
  }
`;

const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: space-between;

  .update-date {
    flex-shrink: 0;
  }
`;

const SubTitle = styled.div`
  span {
    display: inline-block;
    width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const NotificationIconContainer = styled.div`
  padding: 2px;
  flex-shrink: 0;
`;

const NotificationIcon = styled(Icon)`
  flex-shrink: 0;
`;
