import AgGridRadio from "@/src/components/atom/Radio/AgGridRadio";
import { ColDef, IRowNode } from "ag-grid-community";

export const clientIntegrationInviteCodeDialogColumn = (
  radioOnChange: (selectedId: string | null) => void,
): ColDef[] => [
  {
    headerName: "",
    field: "radioButton",
    width: 60,
    cellRenderer: (params: IRowNode) => {
      return (
        <AgGridRadio
          checked={params.data.isChecked}
          onChange={() => radioOnChange(params.data.uniqueId)}
        />
      );
    },
    lockPosition: "left",
    pinned: "left",
    sortable: false,
    resizable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "name",
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
];
