import { ScData } from "@/src/components/template/pdfs/v2/Sc/types";
// @ts-ignore
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";

// 숫자 포맷팅 함수
const formatNumber = (num: string | number) => {
  return Number(String(num).replace(/,/g, "")).toLocaleString("ko-KR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// HTML 생성 함수들을 분리
const generateMainHTML = (data: ScData) => `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <title>Sales Contract</title>
      <style>      
        html, body {
          margin: 0;
          padding: 0;
          font-family: sans-serif;
          width: 100%;
          min-height: 100%;
        }
        .container {
          width: 446pt;
          min-height: 841.89pt;
          padding: 13pt 15.2pt 0 15.2pt;
          box-sizing: border-box;
        }
        .title-section {
          border-bottom: 0.75pt solid #000;
          margin-bottom: 15pt;
          height: 45pt;
          position: relative;
        }
        .title-section div {
          display: inline-block;
          vertical-align: top;
        }
        .title-section .logo {
          min-width: 42.75pt;
          max-width: 120pt;
          height: 100%;
          line-height: 55pt;
        }
        .title-section .logo img {
          display: inline-block;
          max-width: 120pt;
          max-height: 42.75pt;
        }
        .title-section .title {
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          height: 100%;
        }
        .title-section .details {
          width: 70%;
          float: right;
          height: 100%;
          position: relative;
        }
        .title-section h1 {
          margin: 0;
          font-family: sans-serif;
          font-size: 12pt;
          font-weight: 900;
          line-height: 9pt;
          position: absolute;
          left: 0;
          right: 0;
          top: 65%;
          -ms-transform: translateY(-65%);
          transform: translateY(-65%);
        }
        .title-section h2 {
          margin: 0;
          font-family: sans-serif;
          font-size: 8.5pt;
          font-style: italic;
          font-weight: 900;
          line-height: 12pt;
          text-align: right;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .title-section .details p {
          margin: 0;
          font-family: sans-serif;
          font-size: 8pt;
          font-style: italic;
          font-weight: 900;
          line-height: 8pt;
          text-align: right;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -10.5pt;
        }
        .info-table {
          margin-bottom: 25.5pt;
        }
        .remarks-detail {
          font-family: sans-serif;
          line-height: 7.6pt;
          font-size: 7.6pt;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 4.5pt;
          overflow: hidden;
        }
        .remarks-detail-bold{
          font-family: sans-serif;
          font-weight: 900;
          line-height: 7.5pt;
          font-size: 7.5pt;
        }
        .remarks-table-title {
          border-bottom: 0.75pt solid #000;
        }
        .remarks-table {
          margin-top: 12pt;
          border: 0.75pt solid #000;
          text-align: start;
          word-break: break-all;
        }
        .info-table,
        .details-table,
        .remarks-table {
          width: 100%;
          border-collapse: collapse;
        }
        .details-table th,
        .details-table td {
          font-family: sans-serif;
          font-size: 8pt;
          font-weight: 400;
          line-height: 7.2pt;
          text-align: center;
          padding: 4.5pt;
        }
        .remarks-table th,
        .remarks-table td {
          font-family: sans-serif;
          font-size: 6pt;
          font-weight: 400;
          line-height: 7.2pt;
          text-align: left;
          padding: 4.5pt;
          vertical-align: baseline;
        }
        .remarks-table td {
          height: 143pt;
          max-height: 143pt;
          overflow: hidden;
          position: relative;
        }
        .remarks-table td p {
          margin: 0;
          padding: 0 0 7.5pt;
        }
        .info-table th,
        .details-table th,
        .remarks-table th {
          font-family: sans-serif;
          font-size: 7.5pt;
          font-weight: 700;
        }
        .info-table th {
          border: none;
          padding: 0 0 0 6pt;
          text-align: left;
        }
        .info-table td {
          vertical-align: top;
          font-family: sans-serif;
          font-size: 7pt;
          font-weight: 400;
          line-height: 8.4pt;
          text-align: left;
          padding: 3pt 0 0 6pt;
        }
        .details-table th {
          border-top: 0.75pt solid #000;
          border-bottom: 0.75pt solid #000;
        }
        .details-table td {
          vertical-align: baseline;
        }
        .details-table th.poNo {
          width: 12.5%;
        }
        .details-table th.description {
          width: 37.4%;
        }
        .details-table th.quantity {
          width: 16.7%;
        }
        .details-table th.unit-price {
          width: 16.7%;
        }
        .details-table th.amount {
          width: 16.7%;
        }
        .details-table th.payment-term,
        .details-table th.shipping-term,
        .details-table th.last-shipment-date,
        .details-table th.hs-code {
          width: 25%;
          max-width: 25%;
        }
        .details-table tr.first-row {
          height: 58.5pt;
        }
        .details-table tr.second-row {
          height: 33.75pt;
          border-bottom: 0.75pt solid #000;
        }
        .details-table tr.second-row td {
          width: 25%;
          max-width: 25%;
          word-break: break-word;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 7pt;
          padding: 4.5pt;
        }
        .details-table th {
          padding: 4.5pt 0;
        }
        .bank-detail-container {
          padding-bottom: 7.5pt;
        }
        .footer.signature-section div.bank-detail {
          font-size: 6pt;
          font-weight: 400;
          line-height: 7.2pt;
          text-align: left;
        }
        .footer.signature-section div.bank-detail strong {
          font-size: 6pt;
          font-weight: 700;
          line-height: 7.2pt;
          text-align: left;
          padding: 0 
        }
        .signature-section .signature-container {
          display: inline-block;
          width: 95pt;
        }
        .signature-container {
          line-height: 47pt;
          text-align: center;
        }
        .signature-container .buyer-signature-img,
        .signature-container .seller-signature-img {
          display: inline-block;
          max-width: 100%;
          max-height: 47pt;
          vertical-align: middle;
        }
        .signature-section .seller-signature {
          float: right;
          position: absolute;
          bottom: 0;
          right: 6pt;
        }
        .signature-section .buyer-signature {
          float: left; 
          position: absolute;
          bottom: 0;
        }
        .signature-section .signature-text {
          font-family: sans-serif;
          font-size: 8pt;
          font-weight: 700;
          line-height: 8pt; 
          text-align: center;
          padding: 4pt 0;
        }
        .signature-line {
          border-top: 0.75pt solid #000;
          width: 100%;
          margin: 6pt auto 0;
        }
        .footer {
          border-bottom: 0.75pt solid #000;
          border-left: 0.75pt solid #000;
          border-right: 0.75pt solid #000;
          padding: 4.5pt 6pt 0;
          height: 158pt;
          position: relative;
        }
        .contract-terms-page {
          width: 450pt;
          min-height: 841.89pt;
          padding: 13pt 15.2pt 0 15.2pt;
          box-sizing: border-box;
        }
        .contract-terms-title {
          font-family: sans-serif;
          font-size: 12pt;
          font-weight: 700;
          text-align: center;
          margin-bottom: 20pt;
        }
        .contract-terms-content {
          font-family: sans-serif;
          font-size: 9pt;
          line-height: 1.5;
          border: 0.75pt solid #000;
        }
        .contract-terms-content p {
          margin: 0 0 10pt 0;
          white-space: pre-wrap;
          word-break: break-all;
        }
        
        @media print {
          .page-break {
            page-break-before: always;
          }
        }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="title-section">
          <div class="logo">
            <img src="${data.componyLogoUrl}?nocache=${Date.now()}" alt="Company Logo" crossorigin="anonymous">
          </div>
          <div class="title">
            <h1>SALES CONTRACT</h1>
          </div>
          <div class="details">
            <h2>SC No. ${data.scNumber}</h2>
            <p><strong>Order Date:</strong> ${data.orderDate}</p>
          </div>
        </div>

        <table class="info-table">
          <tr>
            <th>Supplier / Seller</th>
            <th>Consignee / Buyer</th>
          </tr>
          <tr>
            <td>
              ${data.sellerCorporation}<br>
              ${data.sellerStreetAddress},<br>
              ${data.sellerLocality} ${data.sellerPostalCode}<br>
              ${data.sellerCountryName}<br>
              Contact: ${data.sellerContactPrefix} ${data.sellerContact}<br>
              FAX: ${data.sellerFaxPrefix} ${data.sellerFax || ""}
            </td>
            <td>
              ${data.buyerCorporation}<br>
              ${data.buyerStreetAddress},<br>
              ${data.buyerLocality} ${data.buyerPostalCode}<br>
              ${data.buyerCountryName}<br>
              Contact: ${data.buyerContactPrefix} ${data.buyerContact}<br>
              FAX: ${data.buyerFaxPrefix} ${data.buyerFax || ""}
            </td>
          </tr>
        </table>

        <table class="details-table">
          <tr>
            <th class="poNo">SC No.</th>
            <th class="description">DESCRIPTION</th>
            <th class="quantity">QUANTITY</th>
            <th class="unit-price">UNIT PRICE</th>
            <th class="amount">AMOUNT</th>
          </tr>
          <tr class="first-row">
            <td>${data.scNumber}</td>
            <td>${data.description}</td>
            <td>${data.quantityPrefix} ${formatNumber(data.quantity)}</td>
            <td>${data.unitPricePrefix} ${formatNumber(data.unitPrice)}</td>
            <td>${data.unitPricePrefix} ${formatNumber(data.amount)}</td>
          </tr>
        </table>

        <table class="details-table">
          <tr>
            <th>PAYMENT TERM</th>
            <th>SHIPPING TERM</th>
            <th>LAST SHIPMENT DATE</th>
            <th>HS Code</th>
          </tr>
          <tr class="second-row">
            <td>${data.paymentTerm}</td>
            <td>${data.shippingTerm}</td>
            <td>${data.lastShipmentDate}</td>
            <td>${data.hsCode}</td>
          </tr>
        </table>

        <table class="remarks-table">
          <tr class="remarks-table-title">
            <th>Remark</th>
          </tr>
          <tr>
            <td>
              <div class="remarks-detail">
                ${data.originLocation ? `<p><strong class="remarks-detail-bold">&lt;Origin/Location&gt;</strong> ${data.originLocation}</p>` : ""}
                <p>${data.remark?.replace(/\n/g, "<br>")}</p>
              </div>
            </td>
          </tr>
        </table>

        <div class="footer signature-section">
          ${
            data.isAddBankDetail
              ? `
          <div class="bank-detail-container">
            <div class="bank-detail">
              <strong>&lt;Bank Detail&gt;</strong>
            </div>
            <div class="bank-detail">BANK NAME : ${data.bankName}</div>
            <div class="bank-detail">BANK ADDRESS : ${data.bankStreetAddress}</div>
            <div class="bank-detail">BANK TEL : ${data.bankTelPrefix} ${data.bankTel}</div>
            <div class="bank-detail">FAX NO. : ${data.bankFaxPrefix} ${data.bankFax}</div>
            <div class="bank-detail">SWIFT CODE : ${data.swiftCode}</div>
            <div class="bank-detail">ACCOUNT NO. : ${data.accountNumber}</div>
            <div class="bank-detail">ACCOUNT NAME : ${data.accountName}</div>
          </div>
          `
              : ""
          }
          
          <div class="signature-container buyer-signature">
            <img src="${data.sellerSignatureUrl}?nocache=${Date.now()}" alt="Seller Signature" class="seller-signature-img" crossorigin="anonymous">
            <div class="signature-line"></div>
            <p class="signature-text">Seller Signature</p>
          </div>
          
          <div class="signature-container seller-signature">
            <img src="${data.buyerSignatureUrl}?nocache=${Date.now()}" alt="Buyer Signature"  class="seller-signature-img" crossorigin="anonymous">
            <div class="signature-line"></div>
            <p class="signature-text">Buyer Signature</p>
          </div>
        </div>
      </div>
    </body>
  </html>
`;
const generateTermsHTML = (data: ScData) => `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <title>Contract Terms</title>
      <style>
        html, body {
          margin: 0;
          padding: 0;
          font-family: sans-serif;
          width: 100%;
          min-height: 100%;
        }
        .contract-terms-page {
          width: 446pt;
          min-height: 841.89pt;
          padding: 13pt 15.2pt 0 15.2pt;
          box-sizing: border-box;
        }
        .contract-terms-title {
          font-family: sans-serif;
          font-size: 12pt;
          font-weight: 700;
          text-align: center;
          margin-bottom: 10pt;
        }
        .contract-terms-content {
          font-family: sans-serif;
          font-size: 7.5pt;
          line-height: 1.5;
          border: 0.75pt solid #000;
          padding: 10pt;
        }
        .contract-terms-content p {
          margin: 4.6pt 0;
          white-space: pre-wrap;
          word-break: break-word;
        }
      </style>
    </head>
    <body>
      <div class="contract-terms-page">
        <h2 class="contract-terms-title">${data.contractTermsTitle || "Terms of Sales Contract"}</h2>
        <div class="contract-terms-content">
          ${
            data.contractTerms
              ?.split("\n")
              .map((line) => (line.trim() ? `<p>${line}</p>` : "<p>&nbsp;</p>"))
              .join("") || ""
          }
        </div>
      </div>
    </body>
  </html>
`;

const generateScPdf = async (scData: ScData): Promise<Blob> => {
  // iframe 생성
  const iframe = document.createElement("iframe");
  iframe.style.width = "0";
  iframe.style.height = "0";
  iframe.style.border = "none";
  document.body.appendChild(iframe);

  // iframe 내부에 컨테이너 생성
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  if (!iframeDoc) throw new Error("iframe document not available");

  const mainContainer = iframeDoc.createElement("div");
  const termsContainer = iframeDoc.createElement("div");

  mainContainer.innerHTML = generateMainHTML(scData);
  iframeDoc.body.appendChild(mainContainer);

  const opt = {
    margin: 0,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      scale: 4,
      useCORS: true,
      letterRendering: true,
      logging: true,
      width: 595.28,
      height: 841.89,
    },
    jsPDF: {
      unit: "pt",
      format: "a4",
      orientation: "portrait",
      compress: false,
    },
  };

  try {
    const pdf = await html2pdf()
      .set(opt)
      .from(mainContainer)
      .toPdf()
      .get("pdf");

    if (scData.isAddContractTerms) {
      termsContainer.innerHTML = generateTermsHTML(scData);
      iframeDoc.body.appendChild(termsContainer);

      const a4Height = 841.89; // A4 높이 (pt)
      const contentHeight = termsContainer.scrollHeight;
      const pageCount = Math.ceil(contentHeight / a4Height);

      // 각 페이지별로 내용을 분할하여 캡처
      for (let i = 0; i < pageCount; i++) {
        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = generateTermsHTML(scData);
        tempContainer.style.height = `${a4Height}px`;
        tempContainer.style.overflow = "hidden";
        tempContainer.style.position = "relative";

        // 컨테이너 내부의 컨텐츠 위치 조정
        const contentWrapper = tempContainer.querySelector(
          ".contract-terms-page"
        );
        if (contentWrapper) {
          (contentWrapper as HTMLElement).style.position = "absolute";
          (contentWrapper as HTMLElement).style.top = `${-i * a4Height}px`;
        }

        document.body.appendChild(tempContainer);

        // 현재 페이지 부분만 캡처
        const canvas = await html2canvas(tempContainer, {
          ...opt.html2canvas,
          windowWidth: tempContainer.scrollWidth,
          height: a4Height,
        });

        pdf.addPage();
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          0,
          0,
          595.28,
          841.89
        );

        document.body.removeChild(tempContainer);
      }
    }

    return new Blob([pdf.output("blob")], { type: "application/pdf" });
  } finally {
    // iframe 제거
    if (iframe.parentNode) {
      document.body.removeChild(iframe);
    }
  }
};

export const scPreview = async (scData: ScData): Promise<void> => {
  try {
    const pdfBlob = await generateScPdf(scData);
    const blobUrl = URL.createObjectURL(pdfBlob);

    window.open(blobUrl + "#toolbar=0&navpanes=0&scrollbar=0", "_blank");

    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  } catch (error) {
    console.error("PDF 미리보기 실패:", error);
    throw error;
  }
};
export const scDownload = async (
  scData: ScData,
  fileName?: string
): Promise<void> => {
  try {
    const pdfBlob = await generateScPdf(scData);
    const blobUrl = URL.createObjectURL(pdfBlob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName || `sales-contract-${scData.scNumber}.pdf`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  } catch (error) {
    console.error("PDF 다운로드 실패:", error);
    throw error;
  }
};
