import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../../baseQuery";
import { Rows } from "../../type";
import { GetTaskListRequest, TaskListViewDto } from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["list", "importer-list"],
});

const taskListApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 작업 목록 조회 API
    getTaskList: build.query<Rows<TaskListViewDto>, GetTaskListRequest>({
      query: (params) => ({
        url: "/exporters/this/tasks",
        params,
      }),
      providesTags: ["list"],
    }),

    // 작업목록 카테고리 필터된 목록 조회
    getTaskCategoryFilterList: build.query<
      Rows<TaskListViewDto>,
      GetTaskListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/tasks",
        params: { isExporterUserMainCategoryFilterOn: true, ...params },
      }),
      providesTags: ["list"],
    }),

    getTasksExcelList: build.query<void, GetTaskListRequest>({
      query: (params) => ({
        url: "/exporters/this/tasks-excel",
        params: {
          isExporterUserMainCategoryFilterOn: true,
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "tasks_list.xlsx",
          });
        },
      }),
    }),

    getImporterTaskList: build.query<Rows<TaskListViewDto>, GetTaskListRequest>(
      {
        query: (params) => ({
          url: "/importers/this/tasks",
          params,
        }),
        providesTags: ["importer-list"],
      },
    ),
  }),
});

export const {
  useLazyGetTaskListQuery,
  useGetTaskListQuery,
  useLazyGetTasksExcelListQuery,

  useGetTaskCategoryFilterListQuery,
  useLazyGetTaskCategoryFilterListQuery,

  useLazyGetImporterTaskListQuery,
} = taskListApi;
export { taskListApi };
