import { renderNoRowsComponent } from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import BookingInfoTooltip from "@/src/pages/exporter/Shipment/components/detail/card/BookingInfoTooltip";
import { ShipmentDetailContractBookingListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { ShipmentDetailContractBookingShareInfosDto } from "@/src/store/apis/shipments/shipmentShare/interface";
import colorSet from "@/src/styles/color";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

interface SharedBookingContractCardItemProps {
  data?: ShipmentDetailContractBookingShareInfosDto;
  previewData?: ShipmentDetailContractBookingListViewDto;
  index: number;
  isError?: boolean;
}

function SharedBookingContractCardItem({
  data,
  previewData,
  index,
  isError,
}: SharedBookingContractCardItemProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get("preview") === "true";
  const [isCardOpen, setIsCardOpen] = useState(true);

  const {
    shipmentDetailContractShareInfo,
    shipmentDetailBookingShareInfoList,
  } = (data ?? {}) as ShipmentDetailContractBookingShareInfosDto;
  const { shipmentDetailContractInfo, shipmentDetailBookingInfoList } =
    (previewData ?? {}) as ShipmentDetailContractBookingListViewDto;

  const contractInfo = {
    scNo: isPreview
      ? shipmentDetailContractInfo.scNo
      : shipmentDetailContractShareInfo.scNo,
    poNo: isPreview
      ? shipmentDetailContractInfo.poNo
      : shipmentDetailContractShareInfo.poNo,
    exporterItem: isPreview
      ? shipmentDetailContractInfo.exporterItem
      : shipmentDetailContractShareInfo.exporterItem,
    unitPrice: isPreview
      ? shipmentDetailContractInfo.unitPrice
      : shipmentDetailContractShareInfo.unitPrice,
    unitPriceUnit: isPreview
      ? shipmentDetailContractInfo.unitPriceUnit
      : shipmentDetailContractShareInfo.unitPriceUnit,
    paymentTerms: isPreview
      ? shipmentDetailContractInfo.paymentTerms
      : shipmentDetailContractShareInfo.paymentTerms,
    paymentTermsRemark: isPreview
      ? shipmentDetailContractInfo.paymentTermsRemark
      : shipmentDetailContractShareInfo.paymentTermsRemark,
    lcNo: isPreview
      ? shipmentDetailContractInfo.lcNo
      : shipmentDetailContractShareInfo.lcNo,
    shippingTerms: isPreview
      ? shipmentDetailContractInfo.shippingTerms
      : shipmentDetailContractShareInfo.shippingTerms,
    shippingTermsRemark: isPreview
      ? shipmentDetailContractInfo.shippingTermsRemark
      : shipmentDetailContractShareInfo.shippingTermsRemark,
  };

  const renderContractContent = () => {
    return (
      <ContractContainer>
        <Title typoType="h4">
          {t("shipment:exporter.detail.label.contract")}
        </Title>
        <CardContent>
          <FlexColumn>
            <SectionCardRow
              label={t("common:scNo")}
              value={
                contractInfo.scNo ? (
                  <BreakWordTypo typoType="h7" color="blue2">
                    {contractInfo.scNo}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            <SectionCardRow
              label={t("common:poNo")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5" as="p">
                  {contractInfo.poNo ?? "-"}
                </BreakWordTypo>
              }
            />
            <SectionCardRow
              label={t("common:item")}
              value={
                <BreakWordTypo typoType="b7r" color="gray5" as="p">
                  {contractInfo?.exporterItem ?? "-"}
                </BreakWordTypo>
              }
            />
          </FlexColumn>

          <GrayLine />

          <FlexColumn>
            <SectionCardRow
              label={t("common:unitPrice")}
              value={
                contractInfo.unitPrice &&
                contractInfo.unitPriceUnit && (
                  <BreakWordTypo typoType="b7r" color="gray5" as="p">
                    {`${Number(contractInfo.unitPrice).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                      ${contractInfo.unitPriceUnit}`}
                  </BreakWordTypo>
                )
              }
            />
            <SectionCardRow
              label={t("common:paymentTerms")}
              value={
                contractInfo.paymentTerms ? (
                  <BreakWordTypo typoType="b7r" color="gray5" as="p">
                    {`${contractInfo.paymentTerms} ${
                      contractInfo.paymentTermsRemark
                        ? contractInfo.paymentTermsRemark
                        : ""
                    }
                      `}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
            {contractInfo?.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <SectionCardRow
                label={t("common:lcNo")}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5" as="p">
                    {contractInfo?.lcNo ?? "-"}
                  </BreakWordTypo>
                }
              />
            )}
            <SectionCardRow
              label={t("common:shippingTerms")}
              value={
                contractInfo.shippingTerms ? (
                  <BreakWordTypo typoType="b7r" color="gray5" as="p">
                    {`${contractInfo.shippingTerms} ${
                      contractInfo.shippingTermsRemark
                        ? contractInfo.shippingTermsRemark
                        : ""
                    }
                      `}
                  </BreakWordTypo>
                ) : (
                  "-"
                )
              }
            />
          </FlexColumn>
        </CardContent>
      </ContractContainer>
    );
  };

  const renderBookingContent = () => {
    let list;

    if (isPreview) {
      list = shipmentDetailBookingInfoList;
    } else {
      list = shipmentDetailBookingShareInfoList;
    }

    return (
      <BookingContainer>
        {list?.map(
          (
            {
              bookingNo,
              numberOfContainer,
              containerType,
              portOfDischarge,
              placeOfReceipt,
            },
            idx,
          ) => {
            return (
              <BookingContent key={idx.toString()}>
                <Title typoType="h4">
                  {t("shipment:exporter.detail.label.booking")}{" "}
                  {addZeroPrefix(idx + 1)}
                </Title>
                <CardContent>
                  <FlexColumn>
                    <SectionCardRow
                      label={t("common:bookingNo")}
                      value={
                        bookingNo ? (
                          <BreakWordTypo typoType="h7" color="blue2">
                            {bookingNo}
                          </BreakWordTypo>
                        ) : (
                          "-"
                        )
                      }
                    />
                    <SectionCardRow
                      label={
                        <NoOfContainerLabel>
                          <Typo>{t("common:noOfContainer")}</Typo>

                          {/* Tooltip */}
                          <BookingInfoTooltip />
                        </NoOfContainerLabel>
                      }
                      value={
                        numberOfContainer && containerType ? (
                          <BreakWordTypo typoType="b7r" color="gray5" as="p">
                            {`${numberOfContainer}x${containerType}`}
                          </BreakWordTypo>
                        ) : (
                          "-"
                        )
                      }
                    />
                  </FlexColumn>

                  <GrayLine />

                  <FlexColumn>
                    <SectionCardRow
                      label={t("common:portOfDischarge")}
                      value={portOfDischarge ?? "-"}
                    />
                    <SectionCardRow
                      label={t("common:placeOfReceipt")}
                      value={
                        <BreakWordTypo typoType="b7r" color="gray5" as="p">
                          {placeOfReceipt ?? "-"}
                        </BreakWordTypo>
                      }
                    />
                  </FlexColumn>
                </CardContent>
              </BookingContent>
            );
          },
        )}
      </BookingContainer>
    );
  };

  const renderShipmentContractTabContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <SectionFoldableCard
        open={isCardOpen}
        onOpenChange={setIsCardOpen}
        cardTitle={`${t(
          "shipment:exporter.detail.label.contractWithBooking",
        )} ${addZeroPrefix(Number(index + 1))}`}
        rightAccessory={
          <RightArrowIcon
            isCardOpen={isCardOpen}
            onClick={() => setIsCardOpen((prev) => !prev)}
          />
        }
      >
        <BookingContractCardContainer>
          {/* Contract */}
          {renderContractContent()}
          {/* Booking */}
          {renderBookingContent()}
        </BookingContractCardContainer>
      </SectionFoldableCard>
    );
  };

  return renderShipmentContractTabContent();
}

export default SharedBookingContractCardItem;

const BookingContractCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContractContainer = styled.section`
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const BookingContent = styled.div`
  display: flex;
  gap: 24px;
`;

const BookingContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  padding: 24px;
  flex: 1;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${colorSet.gray9};
`;

const Title = styled(Typo)`
  min-width: 120px;
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const NoOfContainerLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  figure {
    position: relative;
    display: flex;
  }
`;
