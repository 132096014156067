import Table from "@/src/components/atom/Table";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useLazyGetNoticesQuery } from "@/src/store/apis/notice";
import colorSet from "@/src/styles/color";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { columnNoticeList } from "./columns/columnNoticeList";

const ImporterNoticePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);

  const [isReady, setIsReady] = useState(false);
  const [columnDefs] = useState<ColDef[]>(columnNoticeList);
  const [getNotices, noticesResult] = useLazyGetNoticesQuery();

  const {
    state: { pagination },
    func: { onPaginationChange },
  } = useFilterSearch({
    isReady,
    gridRef: gridRef.current,
    fetch: getNotices,
    defaultFilterData: {},
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "title",
        langKey: "notice:table.title",
      },
      {
        columnKey: "createdAt",
        langKey: "notice:table.registerDate",
      },
    ],
  });

  useEffect(() => {
    if (gridRef.current) {
      if (noticesResult.isFetching) {
        gridRef.current.api.showLoadingOverlay();
      }
    }
  }, [noticesResult.isFetching]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:notice")]}
      pageTitle={t("sideNav:notice")}
    >
      <Card>
        <Table
          ref={gridRef}
          onGridReady={() => setIsReady(true)}
          columnDefs={columnDefs}
          rowData={
            noticesResult.isSuccess ? noticesResult.currentData?.rows : []
          }
          totalPage={noticesResult?.currentData?.count}
          onRowClicked={({ data }) => {
            navigate(`${IMPORTER_PRIVATE_PATH.NOTICE}/${data.id}`);
          }}
          isPaginationDataMaping
          pageSize={pagination.pageSize}
          page={pagination.page}
          handlePaginationClick={(page, pageSize) =>
            onPaginationChange({ page, pageSize })
          }
        />
      </Card>
    </ImporterMainLayout>
  );
};

export default ImporterNoticePage;

const Card = styled.div`
  border-radius: 16px;
  background: ${colorSet.white};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 8px;
`;
