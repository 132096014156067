import colorSet from "@/src/styles/color";
import { keyframes, styled } from "styled-components";

interface LoaderProps {
  size?: number;
  tickColor?: string;
  trackColor?: string;
}

const Loader = ({ size, tickColor, trackColor }: LoaderProps) => {
  return (
    <StyledLoader
      size={size ?? 20}
      $tickColor={tickColor ?? colorSet.blue4}
      $trackColor={trackColor ?? "rgba(22, 109, 215, 0.2)"}
    />
  );
};

export default Loader;

const rotation = keyframes`
 0% {
    transform: rotate(0deg);
 }
 100% {
    transform: rotate(360deg);
 }
`;

const StyledLoader = styled.div<{
  size: number;
  $tickColor: string;
  $trackColor: string;
}>`
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: 3px solid ${({ $trackColor }) => $trackColor};
  border-bottom: 3px solid ${({ $tickColor }) => $tickColor};
  border-radius: 50%;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
  flex-shrink: 0;
`;
