import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import useAlert from "@/src/hooks/useAlert";
import {
  useEditShipmentContainerMutation,
  useLazyGetShipmentContainerListForReselectionQuery,
} from "@/src/store/apis/shipments/shipmentDetail";
import { ColDef, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { selectContainerColumn } from "../columnContainerTab";

interface SelectContainerDialogProps {
  open: boolean;
  onClose: () => void;
}

function SelectContainerDialog({ open, onClose }: SelectContainerDialogProps) {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const selectedRows = useRef<any[]>([]);
  const params = useParams();
  const alert = useAlert();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [columnDefs] = useState<ColDef[]>(selectContainerColumn);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const [
    getShipmentContainerForReselection,
    shipmentContainerForReselectionResult,
  ] = useLazyGetShipmentContainerListForReselectionQuery();
  const [editShipmentContainer, { isLoading }] =
    useEditShipmentContainerMutation();

  const handleSubmitClick = async () => {
    try {
      await editShipmentContainer({
        id: Number(params.id),
        containerIdList: selectedRows.current,
      }).unwrap();

      onClose();
      alert.showAlert({
        type: "success",
        message: t("shipment:exporter.detail.alert.selectContainerSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getShipmentContainerForReselection({
          id: Number(params.id),
        }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        alert.showAlert({ type: "error", message });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t("shipment:exporter.dialog.title.selectContainer")}
      width={1000}
      open={open}
      onOpenChange={onClose}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
          >
            <Typo typoType="btn3m">
              {t("shipment:exporter.detail.button.cancelButton")}
            </Typo>
          </Button>
          <Button
            ref={buttonRef}
            onClick={() => {
              handleSubmitClick();
            }}
            isLoading={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t("shipment:exporter.detail.button.selectButton")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={onClose}
        />
      )}

      <Table
        ref={gridRef}
        onRowDataUpdated={(event) => {
          if (isLoading) {
            event.api.forEachNode((node: IRowNode) => {
              if (selectedRows.current.includes(node.data.id)) {
                node.setSelected(true);
              }
            });

            return;
          }

          event.api.forEachNode((node: IRowNode) => {
            if (node.data.isLinkedByCurrentShipment) {
              node.setSelected(true);
              selectedRows.current = [...selectedRows.current, node.data.id];
            }
          });
        }}
        rowData={
          shipmentContainerForReselectionResult.isError
            ? []
            : (shipmentContainerForReselectionResult?.currentData?.rows ?? [])
        }
        columnDefs={columnDefs}
        isPagination={false}
        height={672}
        rowSelection={"multiple"}
        rowMultiSelectWithClick={true}
        isRowSelectable={(rowNode) => {
          return (
            !rowNode.data.isShipmentContainerLinked ||
            rowNode.data.isLinkedByCurrentShipment
          );
        }}
        rowClassRules={{
          "ag-selectable-disable-row": (params) => {
            return !params.node.selectable;
          },
        }}
        onSelectionChanged={(event) => {
          selectedRows.current = event.api
            .getSelectedRows()
            .map(({ id }) => id);

          if (buttonRef.current) {
            buttonRef.current.disabled =
              event.api.getSelectedRows().length === 0;
          }
        }}
      />
    </Dialog>
  );
}

export default SelectContainerDialog;
