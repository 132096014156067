import Typo from "@/src/components/atom/Typo";
import Steps from "@/src/components/molecule/Steps";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import {
  ExporterDto,
  SignupJoinPathType,
} from "@/src/store/apis/auth/interface";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Button Status Context
import styled from "styled-components";
import StaffSignUpStepOne from "./StaffSignUpStepOne";
import StaffSignUpStepThree from "./StaffSignUpStepThree";
import StaffSignUpStepTwo from "./StaffSignUpStepTwo";

// Button Status Context
type SignUpButtonStatusType = {
  isEmailConfirm: boolean;
  isCodeSendClick: boolean;
  isCodeConfirm: boolean;
};
type SignupButtonStateContextType = SignUpButtonStatusType & {
  onButtonStatusChange: React.Dispatch<
    React.SetStateAction<SignUpButtonStatusType>
  >;
};
export const StaffSignUpButtonStatusContext = createContext<
  SignupButtonStateContextType | undefined
>(undefined);
const { Provider: StaffButtonContextProvider } = StaffSignUpButtonStatusContext;

type StaffSignUpResourceType = {
  corporateCode: string[];
  companyInfo: ExporterDto | null;
  name: string;
  aId: string;
  password: string;
  category: string[];
  authTokenId: string;
  verifyString: string;
  isMarketingEmailReceive: boolean;
  requiredTermsIdList: number[];
  optionalTermsIdList: number[];
  personalContactPrefix: string;
  personalContact: string;
  officeContactPrefix: string;
  officeContact: string;

  joinPath?: SignupJoinPathType | undefined;
  joinPathDescription?: string;
};

export const StaffSignupStepContext = createContext<
  | {
      step: number;
      onStepNext: () => void;
      onStepPrev: () => void;
    }
  | undefined
>(undefined);

const { Provider: StaffStepContextProvider } = StaffSignupStepContext;

export const StaffSignUpResourceContext = createContext<
  | (StaffSignUpResourceType & {
      onResourceChange: (key: keyof StaffSignUpResourceType, data: any) => void;
    })
  | undefined
>(undefined);

const StaffSignupPage = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [staffSignUpResource, setStaffSignUpResource] =
    useState<StaffSignUpResourceType>({
      corporateCode: ["", "", "", "", "", "", "", ""],
      companyInfo: null,
      name: "",
      aId: "",
      password: "",
      category: [],
      authTokenId: "",
      verifyString: "",
      requiredTermsIdList: [],
      optionalTermsIdList: [],
      isMarketingEmailReceive: false,
      personalContactPrefix: "",
      personalContact: "",
      officeContactPrefix: "",
      officeContact: "",
      joinPath: undefined,
      joinPathDescription: "",
    });

  const [signUpButtonStatus, setSignUpButtonStatus] =
    useState<SignUpButtonStatusType>({
      isEmailConfirm: false,
      isCodeSendClick: false,
      isCodeConfirm: false,
    });

  const renderContent = () => {
    switch (step) {
      case 1: {
        return <StaffSignUpStepOne />;
      }
      case 2: {
        return <StaffSignUpStepTwo />;
      }
      case 3: {
        return <StaffSignUpStepThree />;
      }
    }
  };

  const handleStaffSignUpResourceChange = useCallback(
    (key: keyof StaffSignUpResourceType, data: any) => {
      setStaffSignUpResource((prev) => {
        return { ...prev, [key]: data };
      });
    },
    []
  );

  const handleStepNext = () => setStep((prev) => prev + 1);
  const handleStepPrev = () => setStep((prev) => prev - 1);

  // 새로고침 감지
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <SignUpLayout>
      <StyledMain>
        <Typo as="h1" typoType="d3" color="gray1">
          {t("signup:common.signupForAStaff")}
        </Typo>

        <StepsContainer>
          <Steps
            items={[
              { title: t("signupStaff:steps.firstStep") },
              { title: t("signupStaff:steps.secondStep") },
              { title: t("signupStaff:steps.thirdStep") },
            ]}
            current={step}
          />
        </StepsContainer>

        <StaffButtonContextProvider
          value={{
            ...signUpButtonStatus,
            onButtonStatusChange: setSignUpButtonStatus,
          }}
        >
          <StaffStepContextProvider
            value={{
              step,
              onStepNext: handleStepNext,
              onStepPrev: handleStepPrev,
            }}
          >
            <StaffSignUpResourceContext.Provider
              value={{
                ...staffSignUpResource,
                onResourceChange: handleStaffSignUpResourceChange,
              }}
            >
              {renderContent()}
            </StaffSignUpResourceContext.Provider>
          </StaffStepContextProvider>
        </StaffButtonContextProvider>
      </StyledMain>
    </SignUpLayout>
  );
};

export default StaffSignupPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    padding: 24px 0;
  }
`;

const StepsContainer = styled.div`
  width: 800px;
  padding: 24px 0;
`;
