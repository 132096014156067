import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { keyframes, styled } from "styled-components";
import RightArrowIcon from "../SectionCard/RightArrowIcon";

interface SectionFoldableCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  cardTitle?: ReactNode;
  rightAccessory?: ReactNode;
  cardContentContainerStyle?: CSSProperties;
  containerClassName?: string;
}

const SectionFoldableCard = ({
  open,
  onOpenChange,
  cardTitle,
  rightAccessory,
  cardContentContainerStyle,
  children,
  containerClassName,
  ...props
}: SectionFoldableCardProps) => {
  const isCardTitleString = typeof cardTitle === "string";
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      const updateHeight = () => {
        const height = container.scrollHeight;
        setContentHeight(height);
        container.style.setProperty(
          "--card-content-container-height",
          `${height}px`,
        );

        if (!open) {
          container.style.height = "0px";
        } else {
          container.style.height = `${height}px`;
        }
      };

      updateHeight();

      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [open, children]);

  const renderContent = () => {
    return (
      <>
        {children && (
          <CardContentContainer
            style={cardContentContainerStyle}
            ref={containerRef}
            className={`${containerClassName} ${open ? "open" : "close"}`}
            data-ready={contentHeight !== null}
          >
            <div className="animation-wrapper">{children}</div>
          </CardContentContainer>
        )}
      </>
    );
  };

  return (
    <SectionCardArticle {...props}>
      <CardHeader>
        {isCardTitleString ? <CardTitle>{cardTitle}</CardTitle> : cardTitle}

        {rightAccessory ? (
          <RightAccessoryContainer>
            <RightArrowIcon
              isCardOpen={open}
              onClick={() => onOpenChange(!open)}
            />
          </RightAccessoryContainer>
        ) : null}
      </CardHeader>

      {renderContent()}
    </SectionCardArticle>
  );
};

export default SectionFoldableCard;

const SectionCardArticle = styled.article`
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #0000000d;
`;

const CardTitle = styled.h2`
  ${typo.h6}
`;

const RightAccessoryContainer = styled.div`
  margin-left: auto;
`;

const CardHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 48px;
  background: ${colorSet.gray11};
  border-radius: 8px;

  ${CardTitle} {
    flex: 1;
  }
`;

const close = keyframes`
  from {
    height: var(--card-content-container-height);
  }
  to {
    height: 0px;
  }
`;

const open = keyframes`
  from {
    height: 0px;
  }
  to {
    height: var(--card-content-container-height);
  }
`;

const CardContentContainer = styled.div`
  overflow: hidden;
  transition: height 500ms ease;
  visibility: hidden;

  &[data-ready="true"] {
    visibility: visible;
  }

  .animation-wrapper {
    padding: 24px 16px;
  }

  &.open {
    animation: ${open} 500ms forwards;
  }

  &.close {
    animation: ${close} 500ms forwards;
  }
`;
