import { ReactComponent as BellSvg } from "@/src/assets/icons/icon-bell.svg";
import ChevronDownSvg from "@/src/assets/icons/icon-chevron-down-indigo.svg";
import ContainerSvg from "@/src/assets/icons/icon-container.svg";
import GlobalLanguageSvg from "@/src/assets/icons/icon-global-language.svg";
import LockSvg from "@/src/assets/icons/icon-lock.svg";
import LogoutSvg from "@/src/assets/icons/icon-logout-black.svg";
import UserSvg from "@/src/assets/icons/icon-my-page.svg";
import PeopleSvg from "@/src/assets/icons/icon-people.svg";
import ThreeDotsSvg from "@/src/assets/icons/icon-three-dots.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import DropDown from "@/src/components/atom/DropDown";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import LinkCodeDialog from "@/src/components/organism/LinkCodeDialog";
import Notification from "@/src/components/organism/Notification";
import SessionTimeController from "@/src/components/organism/SessionTimeController";
import { LANGUAGE_LIST } from "@/src/constant/optionList";
import useAlert from "@/src/hooks/useAlert";
import { persistor } from "@/src/index";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import {
  useEditSessionMutation,
  useLazyGetSessionQuery,
  useSignOutMutation,
} from "@/src/store/apis/auth";
import { ClientType } from "@/src/store/apis/auth/interface";
import { apiSlice } from "@/src/store/apis/baseQuery";
import { useEditExportersMutation } from "@/src/store/apis/corporate/company";
import { ModifyExporterDto } from "@/src/store/apis/corporate/company/interface";
import { currencyApi } from "@/src/store/apis/currency";
import { pushAlarmsApi } from "@/src/store/apis/pushAlarms";
import { setUser } from "@/src/store/slice/auth";
import { resetTimer } from "@/src/store/slice/sessionTime";
import {
  closeNotification,
  openNotification,
  closeLinkCodeModal,
} from "@/src/store/slice/webUtil";
import colorSet from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import { StyledScroll } from "@/src/styles/scroll";
import typo from "@/src/styles/typography";
import { getPlanServiceCountColor } from "@/src/utils/color";
import { isUndefined } from "@/src/utils/is";
import {
  DropdownMenuContentProps,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";

export const MAIN_CONTENT_MAIN_ID = "layout-main";

interface MainContentLayoutProps {
  breadcrumb?: string[];
  pageTitle?: ReactNode;
  /**
   * @description Page Title을 커스텀 하고 싶을 때 사용
   */
  customPageTitle?: ReactNode;
  sideAccessory?: ReactNode;
}

const notificationDropdownContentProps: DropdownMenuContentProps = {
  collisionPadding: { right: 40 },
  sideOffset: 10,
};

const AccountDropdownContentProps: DropdownMenuContentProps = {
  align: "end",
  sideOffset: 10,
};

enum AlertDialogState {
  NULL,
  CLIENT_TYPE_CHANGE,
  NO_PERMISSION,
}

function ExporterMainContentLayout({
  children,
  breadcrumb,
  pageTitle,
  customPageTitle,
  sideAccessory,
}: PropsWithChildren<MainContentLayoutProps>) {
  const { t } = useTranslation();
  const mainRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const closeTimeoutRef = useRef<NodeJS.Timeout>();
  const isNotificationOpen = useAppSelector(
    (state) => state.webUtil.isNotificationOpen
  );
  const contentLoading = useAppSelector(
    (state) => state.webUtil.contentLoading
  );
  const subscriptionCredit = useAppSelector(
    (state) => state.subscription.subscriptionCredit
  );
  const linkCodeModal = useAppSelector((state) => state.webUtil.linkCodeModal);

  const alert = useAlert();
  const navigate = useNavigate();

  const [isScroll, setIsScroll] = useState(false);
  const [isOpenServiceCountBox, setIsOpenServiceCountBox] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const { count } = pushAlarmsApi.endpoints.getAllPushAlarms.useQueryState(
    { services: ["EXPORT_WEB", "COMMON_WEB"] },
    {
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;

        return {
          count: !isUnstable ? currentData?.count : 0,
        };
      },
    }
  );

  const [signOut] = useSignOutMutation();
  const [editExporters] = useEditExportersMutation();
  const [getSession] = useLazyGetSessionQuery();
  const [editMe] = useEditSessionMutation();

  const handleClientControlClick = () => {
    const isCorporateManager = user?.exporterUserType === "CORPORATE_MANAGER";
    const isManager = !isCorporateManager;

    // 기업관리자, 개인 타입 BOTH
    if (isCorporateManager || user?.exporterUserMainFieldType === "BOTH") {
      return navigate(IMPORTER_PRIVATE_PATH.HOME);
    }
    // 개인 타입 SELLER, 중간관리자, 일반매니저
    if (isManager && user?.exporterUserMainFieldType === "SELLER") {
      return setAlertDialogState(AlertDialogState.NO_PERMISSION);
    }
  };

  const handleClientTypeChangeClick = async () => {
    if (!user) {
      return;
    }

    const exporterParams = {
      basicInformation: {
        companyName: user.exporter.companyName || null,
        telPrefix: user.exporter.telPrefix || null,
        tel: user.exporter.tel || null,
        faxPrefix: user.exporter.faxPrefix || null,
        fax: user.exporter.fax || null,
        countryName: user.exporter.countryName || null,
        region: user.exporter.region || null,
        locality: user.exporter.locality || null,
        streetAddress: user.exporter.streetAddress || null,
        postalCode: user.exporter.postalCode || null,
        countryCodeItemName: user.exporter.countryCodeItemName || null,
        mainCategoryCodeItemNames:
          user.exporter.mainCategoryCodeItemNames || null,
        businessLogoMediaId: user.exporter.businessLogoMedia?.id || null,
        companyType: "BOTH" as ClientType,
      },
    } as ModifyExporterDto;

    try {
      const res = await editExporters(exporterParams).unwrap();

      if (res) {
        await getSession().unwrap();
        navigate(IMPORTER_PRIVATE_PATH.HOME);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const numberOfUsers =
    (subscriptionCredit?.numberOfMember as number) -
      (subscriptionCredit?.memberNumberCredit as number) || 0;

  const goTo = (path: keyof typeof PUBLIC_PATH) => navigate(PUBLIC_PATH[path]);

  const logout = async () => {
    try {
      await signOut().unwrap;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    } finally {
      persistor.purge();
      dispatch(currencyApi.util.resetApiState());
      dispatch(apiSlice.util.resetApiState());
      dispatch(resetTimer());
      goTo("LOGIN");
    }
  };

  const userChangeLanguage = async (language: string) => {
    try {
      const { row: user } = await editMe({ language }).unwrap();
      dispatch(setUser(user));
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const handleEnterCountBox = () => {
    if (closeTimeoutRef && closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsOpenServiceCountBox(true);
  };

  const handleLeaveCountBox = () => {
    const closeCountBox = () => setIsOpenServiceCountBox(false);
    const timeout = setTimeout(closeCountBox, 200);
    closeTimeoutRef.current = timeout;
  };

  const renderPageTitle = () => {
    if (customPageTitle) {
      return customPageTitle;
    }

    if (pageTitle) {
      return (
        <PageTitle as="h1" color="gray1">
          {pageTitle}
        </PageTitle>
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CLIENT_TYPE_CHANGE) {
      return (
        <AlertDialog
          title={t("subscriptionManagement:subscription.importFeaturesGuide")}
          open
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          cancelText={t("subscriptionManagement:subscription.button.cancel")}
          okText={t("subscriptionManagement:subscription.button.ok")}
          onOk={handleClientTypeChangeClick}
        >
          {t(
            "subscriptionManagement:subscription.importFeaturesGuideDescription"
          )}
        </AlertDialog>
      );
    }

    if (alertDialogState === AlertDialogState.NO_PERMISSION) {
      return (
        <AlertDialog
          title={t("subscriptionManagement:subscription.noAuthority")}
          open
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          okText={t("subscriptionManagement:subscription.button.ok")}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
        >
          {t("subscriptionManagement:subscription.noAuthorityDescription")}
        </AlertDialog>
      );
    }
  };

  const renderDialog = () => {
    /* 거래처 연동 */
    if (!!linkCodeModal?.isOpen) {
      return (
        <LinkCodeDialog
          onClose={() => dispatch(closeLinkCodeModal())}
          initialLinkCode={linkCodeModal.linkCode}
        />
      );
    }
  };

  const renderPlanServiceCount = () => {
    return (
      <>
        <ServiceCountsContainer>
          <ServiceCountContainer>
            <Icon iconSrc={ContainerSvg} iconSize={18} />
            <Typo typoType="b9m">{t("common:loadingCode.en")}</Typo>
            <p>
              <Typo
                typoType="b9m"
                color={getPlanServiceCountColor({
                  type: "loading",
                  currentCount: subscriptionCredit?.loadingCodeCredit || 0,
                  planCount: subscriptionCredit?.numberOfLoadingCode || 0,
                })}
              >
                {subscriptionCredit?.loadingCodeCredit ?? "-"}
              </Typo>
              <Typo typoType="b9m" color="gray7">
                {" "}
                / {subscriptionCredit?.numberOfLoadingCode ?? "-"}
              </Typo>
            </p>
          </ServiceCountContainer>
          <ServiceCountContainer>
            <Icon iconSrc={PeopleSvg} iconSize={18} />
            <Typo typoType="b9m">{t("common:member.en")}</Typo>
            <p>
              <Typo
                typoType="b9m"
                color={getPlanServiceCountColor({
                  type: "member",
                  currentCount: numberOfUsers,
                  planCount: subscriptionCredit?.numberOfMember || 0,
                })}
              >
                {isUndefined(subscriptionCredit?.numberOfMember) ||
                isUndefined(subscriptionCredit?.memberNumberCredit)
                  ? "-"
                  : numberOfUsers}
              </Typo>
              <Typo typoType="b9m" color="gray7">
                {" "}
                /{" "}
                {isUndefined(subscriptionCredit?.numberOfMember)
                  ? "-"
                  : subscriptionCredit?.numberOfMember}
              </Typo>
            </p>
          </ServiceCountContainer>
        </ServiceCountsContainer>

        <ServiceCountsButtonContainer>
          <EllipsisButton
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={24}
            onMouseEnter={handleEnterCountBox}
            onMouseLeave={handleLeaveCountBox}
          >
            <Icon iconSrc={ThreeDotsSvg} />
          </EllipsisButton>

          {isOpenServiceCountBox && (
            <Box
              onMouseEnter={handleEnterCountBox}
              onMouseLeave={handleLeaveCountBox}
            >
              <ServiceCountRowContainer>
                <ServiceCountContainer>
                  <Icon iconSrc={ContainerSvg} iconSize={18} />
                  <Typo typoType="b9r">{t("common:loadingCode.en")}</Typo>
                </ServiceCountContainer>
                <p>
                  <Typo
                    typoType="b9m"
                    color={getPlanServiceCountColor({
                      type: "loading",
                      currentCount: subscriptionCredit?.loadingCodeCredit || 0,
                      planCount: subscriptionCredit?.numberOfLoadingCode || 0,
                    })}
                  >
                    {subscriptionCredit?.loadingCodeCredit ?? "-"}
                  </Typo>
                  <Typo typoType="b9m" color="gray7">
                    {" "}
                    / {subscriptionCredit?.numberOfLoadingCode ?? "-"}
                  </Typo>
                </p>
              </ServiceCountRowContainer>
              <ServiceCountRowContainer>
                <ServiceCountContainer>
                  <Icon iconSrc={PeopleSvg} iconSize={18} />
                  <Typo typoType="b9r">{t("common:member.en")}</Typo>
                </ServiceCountContainer>
                <p>
                  <Typo
                    typoType="b9m"
                    color={getPlanServiceCountColor({
                      type: "member",
                      currentCount: numberOfUsers,
                      planCount: subscriptionCredit?.numberOfMember || 0,
                    })}
                  >
                    {isUndefined(subscriptionCredit?.numberOfMember) ||
                    isUndefined(subscriptionCredit?.memberNumberCredit)
                      ? "-"
                      : numberOfUsers}
                  </Typo>
                  <Typo typoType="b9m" color="gray7">
                    {" "}
                    /{" "}
                    {isUndefined(subscriptionCredit?.numberOfMember)
                      ? "-"
                      : subscriptionCredit?.numberOfMember}
                  </Typo>
                </p>
              </ServiceCountRowContainer>
            </Box>
          )}
        </ServiceCountsButtonContainer>
      </>
    );
  };

  const renderClientTypeController = () => {
    const corporate = user?.exporter;
    const isManager =
      user?.exporterUserType === "MANAGER" ||
      user?.exporterUserType === "MIDDLE_MANAGER";

    const getIsLock = () => {
      switch (isManager) {
        case true:
          return user?.exporterUserMainFieldType !== "BOTH";
        case false:
          return corporate?.companyType !== "BOTH";
      }
    };
    const isLock = getIsLock();

    return (
      <>
        <ClientSwitchPanel>
          <ClientSwitch aria-selected data-selected>
            {t("common:seller")}
          </ClientSwitch>
          <ClientSwitch
            aria-selected={false}
            data-selected={false}
            onClick={handleClientControlClick}
          >
            {isLock && <Icon iconSrc={LockSvg} iconSize={14} />}
            {t("common:buyer")}
          </ClientSwitch>
        </ClientSwitchPanel>
      </>
    );
  };

  const renderAccountDropdown = () => {
    const menu = [
      {
        icon: UserSvg,
        label: t("common:myPage"),
        onSelect: () => navigate(EXPORTER_PRIVATE_PATH.MY_PAGE),
      },
      {
        icon: LogoutSvg,
        label: t("common:logout"),
        onSelect: logout,
      },
    ];

    return (
      <>
        {menu.map(({ icon, label, onSelect }) => (
          <AccountDropDownItem key={label} onSelect={onSelect}>
            <Icon iconSrc={icon} iconSize={18} />
            <Typo typoType="b9r">{label}</Typo>
          </AccountDropDownItem>
        ))}
      </>
    );
  };

  const renderLanguageListDropdown = () => {
    const menu = LANGUAGE_LIST.map((item) => {
      return {
        ...item,
        onSelect: async () => {
          userChangeLanguage(item.value);
        },
      };
    });

    return (
      <>
        {menu.map(({ label, onSelect }) => (
          <AccountDropDownItem key={label} onSelect={onSelect}>
            <Typo typoType="b9r">{t(label)}</Typo>
          </AccountDropDownItem>
        ))}
      </>
    );
  };

  const renderContent = () => {
    if (!!sideAccessory) {
      return (
        <FlexDiv>
          {sideAccessory}

          {/* Main Content */}
          <MainContent
            ref={mainRef}
            $overflowHidden={contentLoading}
            id={MAIN_CONTENT_MAIN_ID}
            $hasSidePadding={!!sideAccessory}
          >
            {renderPageTitle()}
            {children}
          </MainContent>
        </FlexDiv>
      );
    }

    return (
      <MainContent
        ref={mainRef}
        $overflowHidden={contentLoading}
        id={MAIN_CONTENT_MAIN_ID}
        $hasSidePadding={!!sideAccessory}
      >
        {renderPageTitle()}
        {children}
      </MainContent>
    );
  };

  useEffect(() => {
    if (mainRef.current) {
      let lastScroll = document.documentElement.scrollTop || 0;

      mainRef.current.addEventListener("scroll", function () {
        let scrollTop = mainRef.current?.scrollTop as number;
        if (scrollTop === 0) {
          setIsScroll(false);
        }

        if (scrollTop > lastScroll) {
          setIsScroll(true);
        }
        lastScroll = scrollTop;
      });
    }
  }, []);

  return (
    <MainContentLayoutContainer>
      <BreadcrumbContainer $isScroll={isScroll}>
        <BreadCrumb>
          {breadcrumb?.map((text, idx, origin) => {
            if (idx === origin.length - 1) {
              return (
                <li key={idx.toString()}>
                  <Typo typoType="b10r" color="gray6">
                    {text}
                  </Typo>
                </li>
              );
            }

            return (
              <li key={idx.toString()} className="breadCrumb-text">
                <Typo typoType="b10r" color="gray7">
                  {text}
                </Typo>
              </li>
            );
          })}
        </BreadCrumb>

        <UserControllerContainer>
          {/* Loading Code & Member */}
          {renderPlanServiceCount()}
          <Divider />
          {/* 수입자 / 수출자 컨트롤러 */}
          {renderClientTypeController()}
          {/* 언어변경 컨트롤러 */}
          <DropDown
            dropdownContentProps={{
              align: "start",
              sideOffset: 10,
            }}
            dropdownTrigger={
              <StyledButton buttonColor="black" buttonGrade="ghost">
                <Flex alignItems="center" gap={4}>
                  <Icon iconSrc={GlobalLanguageSvg} iconSize={18} />
                  <Typo typoType="b9r">
                    {t(
                      LANGUAGE_LIST.find(
                        (item) => item.value === user?.account.language
                      )?.label || ""
                    )}
                  </Typo>
                </Flex>
              </StyledButton>
            }
          >
            <StyledSectionCardWithoutHeader>
              <AccountDropDownList>
                {renderLanguageListDropdown()}
              </AccountDropDownList>
            </StyledSectionCardWithoutHeader>
          </DropDown>
          {/* Notification */}
          <DropDown
            open={isNotificationOpen}
            onOpenChange={() => {
              if (document.querySelector('[role="dialog"]')) {
                return;
              }

              if (isNotificationOpen) {
                return dispatch(closeNotification());
              } else {
                dispatch(openNotification());
              }
            }}
            dropdownContentProps={notificationDropdownContentProps}
            dropdownTrigger={
              <NotificationContainer>
                <BellIcon />
                {count !== 0 && (
                  <NotificationRedCircle>
                    <Typo typoType="btn5m" color="white">
                      {count}
                    </Typo>
                  </NotificationRedCircle>
                )}
              </NotificationContainer>
            }
          >
            <Notification
              type="export"
              services={["EXPORT_WEB", "COMMON_WEB"]}
              onClose={() => {
                dispatch(closeNotification());
              }}
            />
          </DropDown>
          {/* User Info */}
          <DropDown
            dropdownContentProps={AccountDropdownContentProps}
            dropdownTrigger={
              <StyledButton
                buttonSize={24}
                buttonColor="black"
                buttonGrade="tertiary"
              >
                <Typo typoType="btn5m">{user?.name}</Typo>
                <Icon iconSrc={ChevronDownSvg} iconSize={18} />
              </StyledButton>
            }
          >
            <StyledSectionCardWithoutHeader>
              <AccountDropDownList>
                {renderAccountDropdown()}
              </AccountDropDownList>
            </StyledSectionCardWithoutHeader>
          </DropDown>
          {/* Session Time Controller */}
          <SessionTimeController />
        </UserControllerContainer>
      </BreadcrumbContainer>
      {renderContent()}
      {renderAlertDialog()}
      {renderDialog()}
    </MainContentLayoutContainer>
  );
}

export default ExporterMainContentLayout;

const FlexDiv = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

const MainContentLayoutContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  background: ${colorSet.white};
  overflow: auto;
`;

const BreadcrumbContainer = styled.section<{ $isScroll: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ $isScroll }) => ($isScroll ? "10px 40px" : "32px 40px 10px")};
  border-bottom: 1px solid ${colorSet.gray10};
  background: ${colorSet.white};
  transition: 0.3s;
  z-index: 1;
`;

const BreadCrumb = styled.ul`
  display: flex;
  align-items: center;
  gap: 2px;
  min-height: 20px;

  .breadCrumb-text {
    display: flex;
    align-items: center;
    gap: 2px;

    &::after {
      color: ${colorSet.gray7};
      padding: 0 2px;
      content: ">";
    }

    &:last-child::after {
      content: none;
    }
  }
`;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const UserControllerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px 4px 12px;

  &:focus-visible {
    outline: none;
  }
`;

const MainContent = styled.article<{
  $overflowHidden: boolean;
  $hasSidePadding: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ $hasSidePadding }) =>
    $hasSidePadding
      ? css`
          padding: 0 40px 24px 16px;
        `
      : css`
          padding: 0px 40px 24px 40px;
        `};
  width: 100%;
  margin: 0 auto;
  overflow: ${({ $overflowHidden }) => ($overflowHidden ? "hidden" : "auto")};
  flex: 1;
  position: relative;

  ${StyledScroll}
`;

const NotificationContainer = styled.div`
  display: flex;
  position: relative;
`;

const BellIcon = styled(BellSvg)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const NotificationRedCircle = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  min-height: 20px;
  min-width: 20px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: ${colorSet.red2};
  outline: 1px solid ${colorSet.white};
  cursor: pointer;
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
  width: 180px;
`;

const AccountDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;

const AccountDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const ClientSwitchPanel = styled.div`
  background: ${colorSet.gray11};
  border: 1px solid ${colorSet.gray9};
  border-radius: 6px;
  width: 136px;
  display: flex;
`;

const ClientSwitch = styled.button`
  width: 50%;
  border: none;
  border-radius: 6px;
  padding: 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  &[data-selected="true"] {
    ${typo.b11m};
    background: ${colorSet.indigo};
    color: ${colorSet.white};
  }

  &[data-selected="false"] {
    ${typo.b11r};
    background: transparent;
    color: ${colorSet.gray7};
  }
`;

const ServiceCountsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    display: none;
  }
`;

const ServiceCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Divider = styled.div`
  border-right: 1px solid ${colorSet.gray10};
  height: 24px;
`;

const ServiceCountsButtonContainer = styled.div`
  display: none;
  position: relative;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    display: flex;
    align-items: center;
  }
`;

const EllipsisButton = styled(Button)`
  display: flex;
  height: 24px;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: absolute;
  width: 224px;
  top: calc(100% + 10px);
  background: ${colorSet.white};
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #051d3940;
`;

const ServiceCountRowContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
`;
