import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const columnContractFiles: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 60,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "type",
    width: 80,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "category",
    width: 130,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "originalFileName",
    flex: 1,
    sortable: false,
    onCellClicked: (e) => {
      window.open(e.data.mediaUrl, "_blank");
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    width: 150,
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.createdAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.createdAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm,
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
];
