import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiSlice, currencyApiSlice, imageApiSlice } from "./apis/baseQuery";
import authReducer from "./slice/auth";
import companyItemReducer from "./slice/companyItem";
import sessionTimeReducer from "./slice/sessionTime";
import sideNavReducer from "./slice/sideNav";
import subscriptionReducer from "./slice/subscription";
import webUtilReducer from "./slice/webUtil";

const combinedReducers = combineReducers({
  auth: authReducer,
  webUtil: webUtilReducer,
  sideNav: sideNavReducer,
  companyItem: companyItemReducer,
  subscription: subscriptionReducer,
  sessionTime: sessionTimeReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [imageApiSlice.reducerPath]: imageApiSlice.reducer,
  [currencyApiSlice.reducerPath]: currencyApiSlice.reducer,
});

const persistConfig = {
  key:
    process.env.REACT_APP_ENV_MODE === "dev"
      ? "root-dev"
      : process.env.REACT_APP_ENV_MODE === "prod"
        ? "root-prod"
        : "root-local",
  storage,
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apiSlice.middleware,
      imageApiSlice.middleware,
      currencyApiSlice.middleware,
    ]),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
