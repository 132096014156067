import AlertDialog from "@/src/components/atom/AlertDialog";
import { useTranslation } from "react-i18next";

interface EditAlertDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleSubmit: () => void;
  /**
   * @description 기업 관리자 2명 이상 인지
   */
  isAdminSeconds: boolean;
}

function EditAlertDialog({
  isOpen,
  setIsOpen,
  handleSubmit,
  isAdminSeconds,
}: EditAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={isOpen}
      title={t("staffManagement:importer.adminAlert.title")}
      onOpenChange={setIsOpen}
      onOk={handleSubmit}
      okText={t("staffManagement:importer.adminAlert.okButton")}
      cancelText=""
    >
      {isAdminSeconds
        ? t("staffManagement:importer.adminAlert.adminOverDescription")
        : t("staffManagement:importer.adminAlert.adminUnderDescription")}
    </AlertDialog>
  );
}

export default EditAlertDialog;
