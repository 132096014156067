import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import EditSvg from "@/src/assets/icons/icon-edit-white.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import Badge from "@/src/components/atom/Badge";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import {
  useCreateWarehouseEmployeesMutation,
  useGetWarehouseQuery,
  useLazyGetWarehouseEmployeesQuery,
} from "@/src/store/apis/warehouse";
import { WarehouseEmployeeDto } from "@/src/store/apis/warehouse/interface";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isNull, isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { warehouseContactPersonListColumn } from "./columns/column";
import ContactPersonAddDialog, {
  TransformContactPersonAddModalFormType,
} from "./dialog/ContactPersonAddDialog";

enum DialogState {
  NULL,
  ADD_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
}

const employeeEmptyArray: WarehouseEmployeeDto[] = [];

const ImporterWarehouseManagementDetailPage = () => {
  const { t } = useTranslation();
  const agGridRef = useRef<AgGridReact>(null);
  const pageParams = useParams<{ id: string }>();
  const alert = useAlert();
  const navigator = useNavigate();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [columnDefs] = useState<ColDef[]>(warehouseContactPersonListColumn);

  const {
    currentData: warehouseInfo,
    isFetching: isWarehouseInfoFetching,
    warehouseName,
    country,
    mainCategories,
    businessNumber,
    tel,
    telPrefix,
    fax,
    faxPrefix,
    isActivated,
    warehouseCountryName,
    warehouseRegion,
    warehouseLocality,
    warehouseStreetAddress,
    warehousePostalCode,
    countryData,
  } = useGetWarehouseQuery(
    {
      id: Number(pageParams.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          currentData,
          isFetching,
          warehouseName: isStable ? currentData.warehouseName : "-",
          businessNumber: isStable ? currentData.businessNumber : "-",
          tel: isStable ? currentData.tel : "",
          telPrefix: isStable ? currentData.telPrefix : "",
          fax: isStable ? currentData.fax : "",
          faxPrefix: isStable ? currentData.faxPrefix : "",
          isActivated: isStable ? currentData.isActivated : false,
          mainCategories: isStable ? currentData.mainCategories : [],
          country: isStable
            ? `${currentData.country.codeItemName}) ${currentData.country.codeItemNameEn}`
            : "-",
          countryData: currentData?.country,
          warehouseCountryName: isStable
            ? currentData.warehouseCountryName
            : "",
          warehouseRegion: isStable ? currentData.warehouseRegion : "",
          warehouseLocality: isStable ? currentData.warehouseLocality : "",
          warehouseStreetAddress: isStable
            ? currentData.warehouseStreetAddress
            : "",
          warehousePostalCode: isStable ? currentData.warehousePostalCode : "",
        };
      },
    },
  );
  const [
    getWarehouseEmployees,
    { warehouseEmployees, warehouseEmployeesCount, isEmployeesFetching },
  ] = useLazyGetWarehouseEmployeesQuery({
    selectFromResult: ({ isFetching, isError, currentData }) => {
      const isUnstable = isError || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        warehouseEmployees: isStable ? currentData.rows : employeeEmptyArray,
        warehouseEmployeesCount: isStable ? currentData.count : 0,
        isEmployeesFetching: isFetching,
      };
    },
  });

  const { countryCodeList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "COUNTRY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isFetching, currentData }) => ({
        isFetching,
        countryCodeList: currentData ?? [],
      }),
    },
  );

  const [createEmployee] = useCreateWarehouseEmployeesMutation();

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "warehouseManagement:common.name",
      },
      {
        columnKey: "email",
        langKey: "warehouseManagement:common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "warehouseManagement:common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "warehouseManagement:common.officeContact",
      },
      {
        columnKey: "isActivated",
        langKey: "warehouseManagement:common.use",
      },
    ],
  });

  const basicInformationCardLeftSideItemList = [
    {
      label: t("warehouseManagement:common.warehouseName"),
      value: warehouseName,
    },
    {
      label: t("warehouseManagement:common.countryAndCity"),
      value: `${country} ${
        warehouseInfo?.city &&
        !isUndefined(warehouseInfo.gmtOffset) &&
        !isNull(warehouseInfo.gmtOffset)
          ? `/ ${warehouseInfo.city} (UTC ${
              warehouseInfo.gmtOffset > 0 ? "+" : ""
            }${warehouseInfo.gmtOffset})`
          : ""
      }`,
    },
    {
      label: t("warehouseManagement:common.categoryInCharge"),
      value:
        !isWarehouseInfoFetching &&
        mainCategories.map((item) => item.codeItemNameEn).join(", "),
    },
    {
      label: t("warehouseManagement:common.businessNumber"),
      value: businessNumber,
    },
    {
      label: t("warehouseManagement:common.businessRegistration"),
      value: warehouseInfo?.businessRegistrationMedia?.originalFileName ? (
        <RowValueContainer>
          {warehouseInfo?.businessRegistrationMedia.originalFileName}{" "}
          <StyledIconButton
            buttonGrade="secondary"
            buttonSize={24}
            buttonColor="blue"
            onClick={() => {
              if (warehouseInfo.businessRegistrationMedia) {
                window.open(warehouseInfo.businessRegistrationMedia.mediaUrl);
              }
            }}
          >
            <Icon iconSrc={PreviewSvg} iconSize={16} />
          </StyledIconButton>
        </RowValueContainer>
      ) : (
        "-"
      ),
    },
  ];

  const basicInformationCardRightSideItemList = [
    {
      label: t("signupCorp:content.address"),
      value: `${[
        warehousePostalCode,
        warehouseStreetAddress,
        warehouseLocality,
        warehouseRegion,
        warehouseCountryName,
      ]
        .filter(Boolean)
        .join(", ")}`,
    },
    {
      label: t("warehouseManagement:common.contact"),
      value:
        telPrefix && tel && countryCodeList
          ? `${telPrefix}(${
              countryCodeList.find(({ value1 }) => value1 === telPrefix)
                ?.codeItemName
            }) ${tel}`
          : "-",
    },
    {
      label: t("warehouseManagement:common.fax"),
      value:
        faxPrefix && fax && countryCodeList
          ? `${faxPrefix}(${
              countryCodeList.find(({ value1 }) => value1 === faxPrefix)
                ?.codeItemName
            }) ${fax}`
          : "-",
    },
    {
      label: t("warehouseManagement:common.use"),
      value: isActivated ? (
        <Badge
          text="YES"
          badgeSize="S"
          badgeColor="systemLime5"
          color="systemLime1"
        />
      ) : (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      ),
    },
  ];

  const fetchWarehouseEmployees = async ({
    page,
    pageSize,
  }: {
    page?: number;
    pageSize?: number;
  }) => {
    try {
      await getWarehouseEmployees({
        id: Number(pageParams.id),
        page,
        pageSize,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleCreateEmployee = async (
    values: TransformContactPersonAddModalFormType,
  ) => {
    try {
      await createEmployee({
        id: Number(pageParams.id) ?? "",
        isActivated: values.isActivated,
        name: values.name,
        email: values.email,
        personalContactPrefix: values.personalContactPrefix,
        personalContact: values.personalContact,
        officeContactPrefix: values.officeContactPrefix
          ? values.officeContactPrefix
          : undefined,
        officeContact: values.officeContact ? values.officeContact : undefined,
      }).unwrap();
      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    fetchWarehouseEmployees({
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agGridRef.current && isEmployeesFetching) {
      agGridRef.current.api.showLoadingOverlay();
    }
  }, [isEmployeesFetching]);

  useEffect(() => {
    if (isWarehouseInfoFetching) {
      handleContentLoadingOn();
    } else {
      handleContentLoadingOff();
    }
  }, [
    handleContentLoadingOff,
    handleContentLoadingOn,
    isWarehouseInfoFetching,
  ]);

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      return (
        <ContactPersonAddDialog
          open
          defaultCountryCode={countryData?.value1}
          onSave={handleCreateEmployee}
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  return (
    <ImporterMainLayout
      breadcrumb={[
        "...",
        t("sideNav:warehouseManagement"),
        t("sideNav:warehouseDetail"),
      ]}
      customPageTitle={
        <PageTitleContainer>
          <h1>{warehouseName}</h1>
          <StyledButton
            buttonSize={32}
            onClick={() =>
              navigator(
                `${IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_EDIT}/${pageParams.id}`,
              )
            }
          >
            <Icon iconSrc={EditSvg} iconSize={16} />
            <Typo typoType="btn3m" color="white">
              {t("warehouseManagement:common.edit")}
            </Typo>
          </StyledButton>
        </PageTitleContainer>
      }
    >
      <SectionContainer>
        <SectionCard
          cardTitle={t("warehouseManagement:common.basicInformation")}
        >
          <StyledDivContainer>
            <HalfDiv>
              {basicInformationCardLeftSideItemList.map(({ label, value }) => {
                return (
                  <SectionCardRow
                    label={label}
                    direction="horizontal"
                    value={value}
                  />
                );
              })}
            </HalfDiv>
            <Divider />
            <HalfDiv>
              {basicInformationCardRightSideItemList.map(({ label, value }) => {
                return (
                  <SectionCardRow
                    label={label}
                    direction="horizontal"
                    value={value}
                  />
                );
              })}
            </HalfDiv>
          </StyledDivContainer>
        </SectionCard>

        <SectionCard
          cardTitle={t("warehouseManagement:common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD_CONTACT_PERSON)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t("warehouseManagement:factoryListAddPage.addContactPerson")}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={agGridRef}
            rowData={isEmployeesFetching ? undefined : warehouseEmployees}
            columnDefs={columnDefs}
            isPaginationDataMaping
            totalPage={warehouseEmployeesCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onGridReady={() => setIsReady(true)}
            handlePaginationClick={(page, pageSize) => {
              fetchWarehouseEmployees({ page, pageSize });
              setPagination({ page, pageSize });
            }}
          />
        </SectionCard>

        <BottomFixedContainer>
          <BackButton
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => navigator(-1)}
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
            {t("warehouseManagement:common.backToPrevious")}
          </BackButton>
        </BottomFixedContainer>
      </SectionContainer>
      {renderDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterWarehouseManagementDetailPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const StyledDivContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  padding: 0.5px;
  background-color: ${colorSet.gray9};
`;

const PageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    ${typo.h1}
    padding: 15px 0;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BackButton = styled(Button)`
  ${typo.btn3m}
  display: inline-flex;
  align-self: flex-start;
`;

const RowValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIconButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 4px;
`;
