import {
  useGetCommonCodeViaCodeNameQuery,
  useGetTimeZonesQuery,
} from "@/src/store/apis/common";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ReactNode } from "react";
import {
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Loader from "../../atom/Loader";
import Select from "../../atom/Select";
import { InputError } from "../FormItem";

interface CountryWithTimezoneFormItemProps<TFieldValues extends FieldValues> {
  countryName: string;
  countryValue?: string;
  cityName: string;
  cityValue?: string;
  gmtOffsetName: string;
  register: UseFormRegister<TFieldValues>;
  label?: ReactNode;
  setValue?: UseFormSetValue<TFieldValues>;
  onCountryChange?: (value: any) => void;
  direction?: "horizontal" | "vertical";
  isRequired?: boolean;
  className?: string;
  errors?: any;
  errorMessage?: ReactNode;
}

const CountryWithTimezoneFormItem = <TFieldValues extends FieldValues>({
  countryName,
  countryValue,
  cityName,
  cityValue,
  gmtOffsetName,
  register,
  label = "City",
  setValue,
  onCountryChange,
  direction = "horizontal",
  isRequired = true,
  className,
  errors,
  errorMessage,
}: CountryWithTimezoneFormItemProps<TFieldValues>) => {
  const { t } = useTranslation();
  const hasError = errors?.[countryName] || errors?.[cityName];
  const { isFetching, data: countryCodeList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "COUNTRY",
    });

  const countryCodeListToSelectOption =
    countryCodeList?.reduce<{ label: string; value: string }[]>((acc, val) => {
      return [
        ...acc,
        {
          label: `${val.codeItemName}_${val.codeItemNameEn}`,
          value: val.codeItemName,
        },
      ];
    }, []) ?? [];

  const {
    currentData: timeZoneList,
    isFetching: isFetchingTimeZoneList,
    isError: isErrorTimeZoneList,
  } = useGetTimeZonesQuery(
    {
      countryCode: countryValue,
    },
    {
      skip: !countryValue,
    },
  );

  const timeZoneListToSelectOption = timeZoneList?.map((item) => ({
    label: `${item.zoneName} (UTC ${item.gmtOffset > 0 ? "+" : ""}${
      item.gmtOffset
    })`,
    value: item.zoneName,
  }));

  const { onChange: _onCountryChange, ...restCountryField } = register(
    countryName as Path<TFieldValues>,
    {
      required: isRequired,
    },
  );
  const { onChange: _onCityChange, ...restCityField } = register(
    cityName as Path<TFieldValues>,
    {
      required: isRequired,
    },
  );

  const resetCity = () => {
    setValue?.(cityName as Path<TFieldValues>, "" as any);
    setValue?.(gmtOffsetName as Path<TFieldValues>, "" as any);
  };

  return (
    <FormItemContainer data-direction={direction} className={className}>
      {typeof label === "string" ? (
        <LabelContainer>
          <FormLabel data-direction={direction} data-required={isRequired}>
            {label}
          </FormLabel>
        </LabelContainer>
      ) : (
        label
      )}
      <CityInputsContainer>
        <InputContainer>
          <StyledSelect
            {...restCountryField}
            disabled={isFetching}
            suffixIcon={isFetching ? <Loader /> : undefined}
            value={countryValue}
            showSearch
            data-invalid={!!errors?.[countryName]}
            placeholder={t("signupCorp:content.countryPlaceholder")}
            options={countryCodeListToSelectOption}
            onChange={(value) => {
              const codeValue =
                countryCodeList?.find(
                  ({ codeItemName }) => codeItemName === value,
                )?.codeItemName ?? "";
              setValue?.(
                countryName as Path<TFieldValues>,
                codeValue as PathValue<TFieldValues, Path<TFieldValues>>,
                { shouldValidate: true },
              );
              onCountryChange?.(value);
              resetCity();
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentElement;
            }}
          />
          <StyledSelect
            {...restCityField}
            disabled={isFetchingTimeZoneList || !countryValue}
            suffixIcon={isFetchingTimeZoneList ? <Loader /> : undefined}
            value={cityValue}
            showSearch
            data-invalid={!!errors?.[cityName]}
            placeholder={t("signupCorp:content.cityPlaceholder")}
            options={timeZoneListToSelectOption}
            onChange={(value) => {
              if (isErrorTimeZoneList) return;

              const gmtOffset =
                timeZoneList?.find(({ zoneName }) => zoneName === value)
                  ?.gmtOffset ?? 0;

              setValue?.(gmtOffsetName as Path<TFieldValues>, gmtOffset as any);
              setValue?.(cityName as Path<TFieldValues>, value);
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentElement;
            }}
          />
        </InputContainer>
        {hasError && (
          <InputError message={errorMessage || t("error:required")} />
        )}
      </CityInputsContainer>
    </FormItemContainer>
  );
};

export default CountryWithTimezoneFormItem;

const StyledSelect = styled(Select)`
  width: 140px;
  flex-shrink: 0;
  flex: 1 1 0%;

  &[data-invalid="true"] {
    div.ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
`;

const FormLabel = styled.p`
  ${typo.b7r};
  flex-shrink: 0;
  min-width: 163px;
  max-width: 163px;

  &[data-required="true"] {
    &::after {
      content: " *";
      color: ${colorSet.red2};
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormItemContainer = styled.div`
  display: flex;
  gap: 8px;

  &[data-direction="vertical"] {
    flex-direction: column;
  }
`;

const CityInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;
