import CompletedIcon from "@/src/assets/icons/icon-completed.svg";
import Dialog from "@/src/components/atom/Dialog";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { UnsubscribeType } from "./UnsubscribeDialog";

interface FreeCodeDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  unsubscribeType: UnsubscribeType;
}

function UnsubscribeCompletedDialog({
  open,
  onOpenChange,
  unsubscribeType,
}: FreeCodeDialogProps) {
  const { t } = useTranslation();

  const renderUnsubscribeDialog = () => {
    if (unsubscribeType === "EXPIRATION") {
      return (
        <Dialog
          title={t("subscriptionManagement:subscription.unsubscribe")}
          open={open}
          onOpenChange={onOpenChange}
          width={640}
        >
          <Container>
            <FlexColumn gap={40}>
              <Icon iconSrc={CompletedIcon} iconSize={60} />

              <FlexColumn gap={12}>
                <Typo typoType="d6">
                  {t(
                    "subscriptionManagement:subscription.subscriptionTerminated",
                  )}
                </Typo>
                <WhiteSpateTypo typoType="b7m">
                  {t(
                    "subscriptionManagement:subscription.expirationUnsubscribe",
                  )}
                </WhiteSpateTypo>
              </FlexColumn>
            </FlexColumn>
          </Container>
        </Dialog>
      );
    }

    if (unsubscribeType === "IMMEDIATE") {
      return (
        <Dialog
          title={t("subscriptionManagement:subscription.unsubscribe")}
          open={open}
          onOpenChange={onOpenChange}
          width={640}
        >
          <Container>
            <FlexColumn gap={40}>
              <Icon iconSrc={CompletedIcon} iconSize={60} />

              <FlexColumn gap={12}>
                <Typo typoType="d6">
                  {t(
                    "subscriptionManagement:subscription.subscriptionTerminated",
                  )}
                </Typo>
                <WhiteSpateTypo typoType="b7m">
                  {t(
                    "subscriptionManagement:subscription.immediateUnsubscribe",
                  )}
                </WhiteSpateTypo>
              </FlexColumn>
            </FlexColumn>
          </Container>
        </Dialog>
      );
    }

    return <></>;
  };

  return renderUnsubscribeDialog();
}

export default UnsubscribeCompletedDialog;

const Container = styled.div`
  padding: 40px;
`;

const FlexColumn = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  align-items: center;
`;

const WhiteSpateTypo = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
`;
