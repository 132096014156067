import Badge from "@/src/components/atom/Badge";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import Flex from "@/src/components/molecule/Flex";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import {
  subscriptionApi,
  useGetPlanQuery,
} from "@/src/store/apis/subscription";
import colorSet from "@/src/styles/color";
import { getPlanServiceCountColor } from "@/src/utils/color";
import { isNull, isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { paymentCycleStrings } from "../utils/transformStatusStrings";

function SubscriptionInfo() {
  const { t } = useTranslation();

  const { subscriptionId } =
    subscriptionApi.endpoints.getSubscriptions.useQueryState(
      { page: 1, pageSize: 10 },
      {
        selectFromResult: ({ currentData }) => {
          return {
            subscriptionId: currentData?.rows[0].id,
          };
        },
      },
    );

  const {
    planName,
    paymentCycle,
    subscriptionStartAtFrom,
    subscriptionEndAtTo,
    annualFee,
    monthlyFee,
    currentLoadingCodeCredit,
    currentMemberNumberCredit,
    planLoadingCodeCredit,
    planMemberNumberCredit,
    subscriptionStatus,
    subscriptionType,
    nextPlan,
    isError,
    isFetching,
    isStable,
    nextPlanVersion,
    nextPlanPaymentCycle,
    annualPaymentInstallment,
    planId,
  } = subscriptionApi.endpoints.getSubscription.useQueryState(
    { id: subscriptionId as number },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnStable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnStable;

        return {
          currentData,
          isError,
          isFetching,
          isStable,
          subscriptionType: currentData?.subscriptionType,
          nextPlan: currentData?.nextPlan,
          subscriptionStatus: currentData?.subscriptionStatus,
          planName: isStable ? currentData.plan.name : "-",
          paymentCycle: currentData?.paymentCycle,
          nextPlanId: currentData?.nextPlan?.id,
          annualFee: isStable ? (currentData.planVersion.annualFee ?? "") : "",
          monthlyFee: isStable
            ? (currentData.planVersion.monthlyFee ?? "")
            : "",
          numberOfLoadingCode: currentData?.planVersion.numberOfLoadingCode,
          numberOfMember: currentData?.planVersion.numberOfMember,
          subscriptionStartAtFrom: currentData?.subscriptionStartAtFrom,
          subscriptionEndAtTo: currentData?.subscriptionEndAtTo,
          currentLoadingCodeCredit: isStable
            ? currentData.loadingCodeCredit
            : 0,
          currentMemberNumberCredit: isStable
            ? currentData.memberNumberCredit
            : 0,
          planLoadingCodeCredit: isStable
            ? currentData.planVersion.numberOfLoadingCode
            : 0,
          planMemberNumberCredit: isStable
            ? (currentData.planVersion.numberOfMember ?? 0)
            : 0,
          annualPaymentInstallment: isStable
            ? currentData?.annualPaymentInstallment
            : 0,
          paymentList: isStable ? currentData.paymentList : [],
          nextPlanVersion: currentData?.nextPlanVersion,
          nextPlanPaymentCycle: currentData?.nextPlanPaymentCycle,
          planId: currentData?.plan.id,
        };
      },
    },
  );

  const { currentData: planCurrentData } = useGetPlanQuery({
    id: planId as number,
  });

  const endDate = dayjs(
    dayjs(subscriptionEndAtTo).format(DATE_FORMAT_STRINGS.YYYY_MM_DD),
  );
  const duration = endDate.diff(dayjs(), "days");
  const isPlanTermination = subscriptionStatus === "CANCELED" && duration > 0;

  const renderBadge = () => {
    const isFreePlan =
      subscriptionStatus === "PROGRESS" && subscriptionType === "FREE";
    const isStandardPlan = !isFreePlan;
    const isUnstableNextPlan = isNull(nextPlan);
    const isNextPlan = !isUnstableNextPlan;

    if (isError || isFetching) {
      return null;
    }

    if (isNextPlan) {
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.changePlan")}
          color="systemBlue2"
          badgeColor="white"
          borderColor="systemBlue2"
          badgeSize="S"
          badgeType="line"
        />
      );
    }

    if (isPlanTermination) {
      return (
        <Badge
          text={t(
            "subscriptionManagement:subscriptionStatus.subscriptionTerminated",
          )}
          color="red2"
          badgeColor="white"
          borderColor="red2"
          badgeSize="S"
          badgeType="line"
        />
      );
    }

    if (isStandardPlan) {
      return (
        <Badge
          text={t("subscriptionManagement:subscriptionStatus.progress")}
          color="systemLime1"
          badgeColor="white"
          borderColor="systemLime1"
          badgeSize="S"
          badgeType="line"
        />
      );
    }

    return <></>;
  };

  const renderPlanName = () => {
    // 구독 타입 Free, 무료기간 0일 이내
    if (subscriptionType === "FREE" && duration !== 0) {
      return (
        <StyledSectionGridCardRow
          label={
            <Flex gap={4} alignItems="center">
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.plan")}
              </Typo>
              {renderBadge()}
            </Flex>
          }
          direction="vertical"
          value={
            <>
              <BreakWordTypo typoType="h4">{planName}</BreakWordTypo>{" "}
              <BreakWordTypo typoType="h4" color="blue4">
                ({duration} {t("subscriptionManagement:subscription.daysLeft")})
              </BreakWordTypo>
            </>
          }
        />
      );

      // 구독 타입 STANDARD
    } else {
      return (
        <StyledSectionGridCardRow
          label={
            <Flex gap={4} alignItems="center">
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.plan")}
              </Typo>{" "}
              {renderBadge()}
            </Flex>
          }
          direction="vertical"
          value={<BreakWordTypo typoType="h4">{planName}</BreakWordTypo>}
        />
      );
    }
  };

  const renderSubscriptionEnd = () => {
    const subscriptionEndDate = subscriptionEndAtTo
      ? dayjs(subscriptionEndAtTo).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
      : "-";

    // 월간 결제
    if (paymentCycle === "MONTHLY") {
      return (
        <StyledSectionGridCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("subscriptionManagement:subscription.nextPaymentAmount")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {isPlanTermination ? "-" : subscriptionEndDate}
            </BreakWordTypo>
          }
        />
      );
      // 연간 결제
    } else {
      return (
        <StyledSectionGridCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("subscriptionManagement:subscription.nextEpisodeDate")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {isPlanTermination ? "-" : subscriptionEndDate}
            </BreakWordTypo>
          }
        />
      );
    }
  };

  const renderCurrentPrice = () => {
    // 무료 기간
    if (subscriptionType === "FREE") {
      return "-";
    }
    // 연간 결제
    if (paymentCycle === "ANNUALLY") {
      return `USD ${Number(annualFee).toLocaleString("ko-KR")}`;
    }

    // 월간 결제
    if (paymentCycle === "MONTHLY") {
      return `USD ${Number(monthlyFee).toLocaleString("ko-KR")}`;
    }
  };

  const renderNextPrice = () => {
    const changePlanVersion = planCurrentData?.planNextVersionList?.[0];
    const changePlanVersionReleaseAt =
      planCurrentData?.planNextVersionList?.[0]?.releasedAt;

    const isIncludedReleaseAt = dayjs(
      changePlanVersionReleaseAt,
    ).isSameOrBefore(endDate);

    // 변경예정의 버전이 있으며 반영일자가 다음회차에 포함되는 경우
    if (changePlanVersionReleaseAt && isIncludedReleaseAt) {
      if (paymentCycle === "ANNUALLY") {
        // 연간 결제
        return `USD ${Number(changePlanVersion?.annualFee).toLocaleString(
          "ko-KR",
        )}`;
      }

      // 월간 결제
      if (paymentCycle === "MONTHLY") {
        return `USD ${Number(changePlanVersion?.monthlyFee).toLocaleString(
          "ko-KR",
        )}`;
      }
    }

    if (nextPlanVersion && nextPlanPaymentCycle && nextPlan) {
      // 변경 예정 플랜이 있을 경우
      if (nextPlanPaymentCycle === "ANNUALLY") {
        // 연간 결제
        return `USD ${Number(nextPlanVersion?.annualFee).toLocaleString(
          "ko-KR",
        )}`;
      }
      // 월간 결제
      if (nextPlanPaymentCycle === "MONTHLY") {
        return `USD ${Number(nextPlanVersion.monthlyFee).toLocaleString(
          "ko-KR",
        )}`;
      }
    }

    if (paymentCycle === "ANNUALLY") {
      // 연간 결제
      return `USD ${Number(annualFee).toLocaleString("ko-KR")}`;
    }
    // 월간 결제
    if (paymentCycle === "MONTHLY") {
      return `USD ${Number(monthlyFee).toLocaleString("ko-KR")}`;
    }
  };

  const renderSectionCardGrid = () => {
    // 구독이 없는 상태
    if (subscriptionStatus === "CANCELED" && duration <= 0) {
      return <></>;
    }

    if (isStable) {
      return (
        <SectionCardGrid>
          {renderPlanName()}
          <StyledSectionGridCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.paymentCycle")}
              </Typo>
            }
            direction="vertical"
            value={
              <Flex gap={4} alignItems="center">
                <BreakWordTypo typoType="h4">
                  {paymentCycleStrings({ type: paymentCycle, t })}
                </BreakWordTypo>
                {subscriptionType === "STANDARD" &&
                  paymentCycle === "ANNUALLY" &&
                  annualPaymentInstallment && (
                    <BreakWordTypo typoType="h8" color="blue4">
                      ({annualPaymentInstallment}
                      {t("common:iteration")})
                    </BreakWordTypo>
                  )}
              </Flex>
            }
          />

          <StyledSectionGridCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.startDate")}
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {subscriptionStartAtFrom
                  ? dayjs(subscriptionStartAtFrom).format(
                      DATE_FORMAT_STRINGS.YYYY_MM_DD,
                    )
                  : "-"}
              </BreakWordTypo>
            }
          />

          <StyledSectionGridCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.subscriptionPrice")}
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {renderCurrentPrice()}
              </BreakWordTypo>
            }
          />
          <Divider />

          <StyledSectionGridCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                {t(
                  "subscriptionManagement:subscription.remainingLoadingCredits",
                )}
              </Typo>
            }
            direction="vertical"
            value={
              <>
                <BreakWordTypo
                  typoType="h4"
                  color={getPlanServiceCountColor({
                    type: "loading",
                    currentCount: currentLoadingCodeCredit,
                    planCount: planLoadingCodeCredit || 0,
                  })}
                >
                  {currentLoadingCodeCredit}
                </BreakWordTypo>{" "}
                <BreakWordTypo typoType="h4">
                  / {planLoadingCodeCredit}
                </BreakWordTypo>
              </>
            }
          />
          <StyledSectionGridCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.numberOfUsers")}
              </Typo>
            }
            direction="vertical"
            value={
              <>
                <BreakWordTypo
                  typoType="h4"
                  color={getPlanServiceCountColor({
                    type: "member",
                    currentCount:
                      planMemberNumberCredit - currentMemberNumberCredit,
                    planCount: planMemberNumberCredit,
                  })}
                >
                  {planMemberNumberCredit - currentMemberNumberCredit}
                </BreakWordTypo>{" "}
                <BreakWordTypo typoType="h4">
                  / {planMemberNumberCredit}
                </BreakWordTypo>
              </>
            }
          />
          {renderSubscriptionEnd()}
          <StyledSectionGridCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                {t("subscriptionManagement:subscription.managePaymentMethods")}
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {isPlanTermination ? "-" : renderNextPrice()}
              </BreakWordTypo>
            }
          />
        </SectionCardGrid>
      );
    }

    return <></>;
  };

  return renderSectionCardGrid();
}

export default SubscriptionInfo;

const StyledSectionGridCardRow = styled(SectionCardRow)`
  grid-column: 3 span;

  & > p {
    max-width: 100%;
  }
`;

const Divider = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;
