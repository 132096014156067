import { ReactComponent as EditSvg } from "@/src/assets/icons/icon-add-black.svg";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import { SimpleShipmentMemoDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MemoDialog from "../dialog/MemoDialog";

interface MemoCardProps {
  memoData?: SimpleShipmentMemoDto[];
  isEditAuth: boolean;
  isShipmentCompleted: boolean;
}

function MemoCard({
  isEditAuth,
  isShipmentCompleted,
  memoData,
}: MemoCardProps) {
  const { t } = useTranslation();
  const [isMemoEditDialogOpen, setIsMemoEditDialogOpen] = useState(false);

  return (
    <SectionCard
      cardTitle={t("shipment:exporter.detail.label.memo")}
      rightAccessory={
        <StyledButton
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={32}
          onClick={() => setIsMemoEditDialogOpen(true)}
          disabled={!isEditAuth || isShipmentCompleted}
        >
          <EditIcon
            color={!isEditAuth || isShipmentCompleted ? "gray8" : "gray2"}
          />
          {t("shipment:exporter.detail.button.addButton")}
        </StyledButton>
      }
    >
      {isMemoEditDialogOpen && (
        <MemoDialog
          open={isMemoEditDialogOpen}
          onOpenChange={setIsMemoEditDialogOpen}
        />
      )}
      <MemoContainer>
        <MemoDescription>
          <StyledIcon iconSrc={InfoSvg} />
          <Typo typoType="b9r" color="gray6">
            {t("shipment:exporter.detail.description.memo")}
          </Typo>
        </MemoDescription>

        <MemoContent>
          {memoData && memoData.length >= 1 ? (
            memoData?.map((item, idx) => {
              return (
                <MemoItem key={idx.toString()}>
                  <MemoLabelContainer>
                    <Typo color="gray3" typoType="h9">
                      {item.writerName}
                    </Typo>
                    <Typo color="gray7" typoType="b9r">
                      {`(${dayjs(item.wroteAt).format("YY-MM-DD")}, ${dayjs(
                        item.wroteAt,
                      ).format("HH:mm")})`}
                    </Typo>
                  </MemoLabelContainer>

                  <ReadOnlyText typoType="b7r" color="gray6">
                    {item.memo}
                  </ReadOnlyText>
                </MemoItem>
              );
            })
          ) : (
            <MemoNoDataContainer>{renderNoRowsComponent()}</MemoNoDataContainer>
          )}
        </MemoContent>
      </MemoContainer>
    </SectionCard>
  );
}

export default MemoCard;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const MemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MemoDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 174px;
  ${StyledScroll}
`;

const MemoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MemoLabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EditIcon = styled(EditSvg)<{ color: ColorType }>`
  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;
