import Typo from "@/src/components/atom/Typo";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { renderShipmentStatusBadge } from "@/src/pages/exporter/Shipment/utils/renderShipmentStatusBadge";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { TFunction } from "i18next";

export const columnShipmentList = (t: TFunction): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 60,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.status"),
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "status",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.shipmentStatus) {
        return renderShipmentStatusBadge({
          t: t,
          type: params.data.shipmentStatus,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.blNo"),
    cellStyle: { textAlign: "center" },
    field: "blNo",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.blNo ? (
        <TableBlueText typoType="b9r" color="systemBlue2">
          {params.data.blNo}
        </TableBlueText>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.bookingNo"),
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 160,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.etdAt"),
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
            </Typo>{" "}
            <EtdCompareCount
              initDate={params.data.initialEtdAt || ""}
              currentDate={params.data.etdAt || ""}
              typoType="b9m"
            />
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.etaAt"),
    cellStyle: { textAlign: "center" },
    field: "etaAt",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.numberOfContainer"),
    cellStyle: { textAlign: "center" },
    field: "numberOfContainer",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.numberOfShipmentContainer
            ? params.data.numberOfShipmentContainer
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.totalNetWeight"),
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalNetWeight ? (
        <Typo typoType="b9r">{params.data.totalNetWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 150,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.shipmentList.placeOfDelivery"),
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 150,
    sortable: false,
  },
];
