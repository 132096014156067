import TablePlaceholderSvg from "@/src/assets/icons/icon-table-placeholder.svg";
import colorSet from "@/src/styles/color";
import { ColDef, GridOptions } from "ag-grid-community/dist/lib/main";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, ReactNode, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";
import Icon from "../Icon";
import Pagination from "../Pagination";
import Typo from "../Typo";

export interface TableProps extends GridOptions {
  rowData: GridOptions["rowData"];
  columnDefs: ColDef[];
  totalPage?: number;
  page?: number;
  pageSize?: number;
  isPagination?: boolean;
  handlePaginationClick?: (page: number, pageSize: number) => void;
  onSortChange?: (
    value?: {
      sort: string;
      order: "ASC" | "DESC" | null | undefined;
    },
    isClickedHeader?: boolean,
  ) => void;
  isLoading?: boolean;
  paginationBottomPaddingOn?: boolean;
  height?: number;
  isPaginationDataMaping?: boolean;
  paginationRightAccessory?: ReactNode;
}

export const renderNoRowsComponent = (props?: {
  text?: string;
  className?: string;
}) => {
  const text = props?.text ? props.text : "No data";

  return (
    <NoRowsComponentContainer className={props?.className}>
      <Icon iconSrc={TablePlaceholderSvg} iconSize={56} />
      <Typo typoType="b7m" color="gray8">
        {text}
      </Typo>
    </NoRowsComponentContainer>
  );
};

const Table = forwardRef<AgGridReact, TableProps>(
  (
    {
      rowData,
      columnDefs,
      page,
      pageSize,
      isPagination = true,
      handlePaginationClick,
      totalPage,
      onSortChange,
      isLoading,
      paginationBottomPaddingOn = true,
      height,
      isPaginationDataMaping = false,
      paginationRightAccessory,
      ...props
    },
    ref,
  ) => {
    const gridRef = useRef<AgGridReact>();
    const { i18n, t } = useTranslation();
    const isPaginationMaping =
      isPaginationDataMaping && page && pageSize && isPagination;

    useEffect(() => {
      window.addEventListener("error", (e) => {
        if (
          e.message === "ResizeObserver loop limit exceeded" ||
          "ResizeObserver loop completed with undelivered notifications."
        ) {
          const resizeObserverErrDiv = document.getElementById(
            "webpack-dev-server-client-overlay-div",
          );
          const resizeObserverErr = document.getElementById(
            "webpack-dev-server-client-overlay",
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute("style", "display: none");
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute("style", "display: none");
          }
        }
      });
    }, []);

    const defaultColDef = useMemo(
      () => ({
        resizable: true,
        sortable: true,
        comparator: () => 0,
      }),
      [],
    );

    const renderLoadingComponent = () => {
      return <PulseLoader color={colorSet.blue4} />;
    };

    return (
      <TableContainer className="ag-theme-alpine">
        <StyledAgGrid
          ref={(node) => {
            if (node) {
              gridRef.current = node;
            }
            if (ref) {
              if (typeof ref === "function") {
                ref(node);
              } else {
                ref.current = node;
              }
            }
          }}
          pagination
          suppressPaginationPanel={true}
          paginationPageSize={pageSize}
          rowData={rowData?.map((row) => {
            if (isPaginationMaping) {
              return { ...row, lang: i18n.language, t, page, pageSize };
            }

            return { ...row, lang: i18n.language, t };
          })}
          suppressDragLeaveHidesColumns
          domLayout={props.domLayout || !height ? "autoHeight" : "normal"}
          height={height}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          alwaysShowHorizontalScroll={false}
          noRowsOverlayComponent={() => renderNoRowsComponent()}
          loadingOverlayComponent={() => renderLoadingComponent()}
          onSortChanged={(e) => {
            const allColumns = e.columnApi.getAllGridColumns();

            const sortModel = allColumns
              .map((column) => ({
                sort: column.getColId(),
                order: column.getSort(),
              }))
              .filter((item) => !!item.order);
            // props로 받은 이벤트 함수

            onSortChange?.(
              sortModel?.[0] as any,
              e.source === "uiColumnSorted",
            );
          }}
          unSortIcon
          {...props}
        />

        {isPagination && (
          <PaginationContainer
            $paginationBottomPaddingOn={paginationBottomPaddingOn}
          >
            <Pagination
              total={totalPage}
              current={page}
              pageSize={pageSize}
              onChange={(page, pageSize) => {
                handlePaginationClick && handlePaginationClick(page, pageSize);
              }}
              pageSizeOptions={[10, 30, 50, 100]}
              rightAccessory={paginationRightAccessory}
            />
          </PaginationContainer>
        )}
      </TableContainer>
    );
  },
);

export default Table;

export const TableContainer = styled.div`
  width: 100%;
`;

const StyledAgGrid = styled(AgGridReact)<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}px !important` : "")};

  .ag-center-cols-clipper {
    min-height: ${({ height }) =>
      height ? `${height - 43}px` : `421px`} !important;
  }
`;

const NoRowsComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaginationContainer = styled.div<{ $paginationBottomPaddingOn: boolean }>`
  padding: ${({ $paginationBottomPaddingOn }) =>
    $paginationBottomPaddingOn ? "18px 16px" : "18px 16px 0 16px"};
`;
