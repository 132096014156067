import React, {
  createContext,
  Dispatch,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  Ref,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { styled } from "styled-components";
import Alert from "../../atom/Alert";
import useAlertRoot from "./hooks";

//-------------------------------------- Alert Root ---------------------------------------//
export const FadeOutTime = 300;
export type AlertType = "success" | "error";
export type AlertPositionType =
  | "bottomLeft"
  | "bottomRight"
  | "bottomCenter"
  | "topCenter"
  | "topLeft"
  | "topRight";

export interface AlertListItem {
  id?: number;
  message: string;
  position?: AlertPositionType;
  type?: AlertType;
  isHide?: boolean;
  time?: number;
  limit?: number;
}

export interface AlertRootContextProps {
  /**
   * {
   * @param message: 문자
   * @param position: "bottomLeft" | "bottomRight" | "bottomCenter" | "topCenter" | "topLeft" | "topRight";
   * @param type : "info" | "success" | "warning" | "error" | "default"
   *
   * },
   * @param time : 시간
   */
  showAlert: ({ message, position, type, time, limit }: AlertListItem) => void;
  clearAll: () => void;
  alertList: AlertListItem[];
  onChangeList: Dispatch<SetStateAction<AlertListItem[]>>;
}

export const AlertRootContext = createContext<AlertRootContextProps | null>(
  null,
);

const AlertQuantumRoot: React.FC<PropsWithChildren> = ({ children }) => {
  const [alertList, setAlertList] = useState<AlertListItem[]>([]);
  const alert = useAlertRoot(alertList, setAlertList);

  return (
    <AlertRootContext.Provider
      value={{
        showAlert: alert.fnc.showAlert,
        alertList: alert.state.list,
        onChangeList: alert.fnc.onChangeList,
        clearAll: alert.fnc.clearAll,
      }}
    >
      {children}
      {alertList.length >= 1 && <Alert list={alertList} />}
    </AlertRootContext.Provider>
  );
};

//-------------------------------------- Alert Portal ---------------------------------------//

const AlertQuantumPortal: React.FC<PropsWithChildren> = ({ children }) => {
  return createPortal(
    <>{children}</>,
    document.getElementById("alert") as HTMLElement,
  );
};

//-------------------------------------- Alert List ---------------------------------------//

interface AlertListProps extends HTMLAttributes<HTMLDivElement> {}

const AlertQuantumList: React.FC<AlertListProps> = ({ children, ...props }) => {
  return <StyledDiv {...props}>{children}</StyledDiv>;
};

//-------------------------------------- Alert Item ---------------------------------------//

interface AlertItemProps extends HTMLAttributes<HTMLLIElement> {
  alertId: number;
  dismissed?: boolean;
}

interface AlertItemContextProps {
  alertId: number;
}

const AlertItemContext = createContext<AlertItemContextProps | null>(null);

const AlertQuantumItem: React.FC<AlertItemProps> = forwardRef(
  ({ alertId, children, ...props }, ref: Ref<HTMLLIElement>) => {
    return (
      <AlertItemContext.Provider value={{ alertId }}>
        <StyledLi ref={ref} {...props}>
          {children}
        </StyledLi>
      </AlertItemContext.Provider>
    );
  },
);

//-------------------------------------- Alert Close ---------------------------------------//

interface AlertCloseProps extends HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
}

const AlertQuantumClose: React.FC<AlertCloseProps> = ({
  onClick,
  children,
  ...props
}) => {
  const alertRootContext = useContext(AlertRootContext);
  const alertItemContext = useContext(AlertItemContext);

  const handleCloseAlert = () => {
    if (onClick) {
      onClick();
    } else {
      alertRootContext?.onChangeList((prev) =>
        prev.map((item) => {
          return alertItemContext?.alertId === item.id
            ? { ...item, isHide: true }
            : item;
        }),
      );

      setTimeout(() => {
        alertRootContext?.onChangeList((prev) =>
          prev.filter((item) => item.id !== alertItemContext?.alertId),
        );
      }, FadeOutTime);
    }
  };

  return (
    <StyledClose onClick={onClick ?? handleCloseAlert} {...props}>
      {children}
    </StyledClose>
  );
};

export {
  AlertQuantumRoot,
  AlertQuantumPortal,
  AlertQuantumList,
  AlertQuantumItem,
  AlertQuantumClose,
};

const StyledDiv = styled.div`
  padding: 0;
  margin: 0;
`;

const StyledLi = styled.li`
  list-style: none;
`;

const StyledClose = styled.div`
  cursor: pointer;
`;
