import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { css, styled } from "styled-components";

interface StatisticsCardProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  cardTitle: string;
  icon: string;
  numberOfStatistic?: number;
  rankOfStatistic?: { label: string; value: number }[];
  rightTopAccessory?: ReactNode;
}

const StatisticsCard = ({
  cardTitle,
  icon,
  numberOfStatistic,
  rankOfStatistic,
  rightTopAccessory,
  ...restProps
}: StatisticsCardProps) => {
  return (
    <StyledButton {...restProps}>
      <FlexColumn>
        <SpaceBetween>
          <Typo typoType="b6m" color="gray2">
            {cardTitle}
          </Typo>

          {rightTopAccessory}
        </SpaceBetween>

        <Flex
          className={`gap-8  ${
            rankOfStatistic && rankOfStatistic.length > 0 ? "pb-16" : "pb-34"
          }`}
        >
          <Icon iconSrc={icon} iconSize={40} />
          <Typo typoType="d1" color="blue4">
            {numberOfStatistic || 0}
          </Typo>
        </Flex>

        <Flex className="relative">
          <Flex className="overflow-auto relative">
            {rankOfStatistic?.map(({ label, value }, idx) => {
              return (
                <RankItem isThreeItem={idx === 2}>
                  <Typo typoType="b9r" className="manual-ellipsis">
                    {label}
                  </Typo>
                  <Typo color="blue4" typoType="h9">
                    {value}
                  </Typo>
                </RankItem>
              );
            })}
          </Flex>
        </Flex>
      </FlexColumn>
    </StyledButton>
  );
};

export default StatisticsCard;

const StyledButton = styled.button`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background: ${colorSet.white};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  cursor: pointer;
  transition: box-shadow 70ms ease-in-out;
  padding: 16px;
  min-width: 304px;

  &:focus {
    outline-offset: 1px;
    outline: 2px solid ${colorSet.blue5};
  }

  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  }

  &:active {
    outline: none;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  &.gap-8 {
    gap: 8px;
  }

  &.pb-16 {
    padding-bottom: 16px;
  }

  &.pb-34 {
    padding-bottom: 34px;
  }

  &.relative {
    position: relative;
  }
`;

const RankItem = styled.div<{ isThreeItem: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;

  &:not(:last-child) {
    padding-right: 4px;

    &::after {
      content: "";
      display: inline-block;
      height: 10px;
      border-left: 1px solid ${colorSet.gray8};
    }
  }

  .manual-ellipsis {
    display: inline-block;
    max-width: 53px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ isThreeItem }) =>
    isThreeItem &&
    css`
      padding-right: 20px;
    `}
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
