import { css } from "styled-components";
import CheckSvg from "../assets/icons/icon-checkbox-check.svg";
import CheckDisabledSvg from "../assets/icons/icon-checkbox-disabled.svg";
import UnCheckSvg from "../assets/icons/icon-checkbox-uncheck.svg";
import SortAscSvg from "../assets/icons/icon-sort-asc.svg";
import SortDefaultSvg from "../assets/icons/icon-sort-default.svg";
import SortDescSvg from "../assets/icons/icon-sort-desc.svg";
import colorSet from "./color";

const agGridCustom = css`
  .ag-theme-alpine {
    /* Theme 색상 */
    --ag-header-height: 42px;
    --ag-row-height: 42px;
    --ag-border-color: ${colorSet.gray10};
    --ag-header-background-color: ${colorSet.gray12};
    --ag-selected-row-background-color: ${colorSet.gray12};
    --ag-row-hover-color: ${colorSet.blue10};
    --ag-header-column-resize-handle-width: 1px;
    --ag-header-column-resize-handle-height: 100%;

    /* Root 테이블 테두리 */
    .ag-root-wrapper {
      border: none;
      border-radius: 8px;
    }

    // Ag grid 좌우 스크롤
    /* .ag-body-horizontal-scroll-viewport  */
    .ag-horizontal-left-spacer,
    .ag-horizontal-right-spacer {
      opacity: 0;
    }

    // Header 마지막 column
    .ag-header-cell:last-child {
      .ag-header-cell-resize {
        opacity: 0;
      }
    }

    /* Row 스타일 */
    .ag-row {
      cursor: pointer;
      border-bottom: none;
    }
    // 선적 목록 Upate Row
    .ag-row.isCiOrPlRecheckNeeded {
      background: ${colorSet.yellow6};
    }
    .ag-row-odd,
    .ag-row-even {
      background: ${colorSet.white};
    }
    .ag-row-none-cursor {
      cursor: initial;
    }
    /* Disabled Row */
    .ag-row.not-allowed-row {
      cursor: not-allowed;
    }

    /* Cell 스타일 */
    .ag-cell {
      color: ${colorSet.gray2};
      font-weight: 400;
      font-size: 14px;
    }
    .ag-cell-focus {
      border: 1px solid transparent !important;
    }
    /* Pinning Cell 스타일 */
    .ag-pinned-right-cols-container,
    .ag-pinned-left-cols-container {
      .ag-cell {
        font-weight: 500;
        font-size: 14px;
        border: none !important;
      }
    }
    .ag-cell.pointer-events-none {
      pointer-events: none;
      color: ${colorSet.gray7};
    }
    .ag-cell.pointer-events-auto {
      pointer-events: auto;
    }

    /* Header 스타일 */
    .ag-header {
      border: none;
      border-radius: 8px;
      background: #f7f9fa;

      .ag-cell-label-container {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${colorSet.gray4};
      }
    }
    .ag-right-aligned-cell {
      .ag-header-cell-text {
        flex: 1;
        text-align: center;
      }
    }
    .ag-header-hide {
      .ag-header-cell-text {
        opacity: 0;
      }
    }

    /* Header DragTrigger 스타일 */
    .ag-header-cell-resize::after {
      background: ${colorSet.gray9};
    }

    /* Checkbox */
    --ag-icon-size: 15px;
    .ag-checkbox-input-wrapper {
      background: url(${UnCheckSvg}) no-repeat;
      background-size: cover;
      --ag-input-focus-box-shadow: none;
      &:not(.ag-checked)::after {
        display: none;
      }
      &.ag-checked {
        position: relative;
        border: none;
        background: url(${CheckSvg}) no-repeat;
        background-size: cover;
      }
      &.ag-checked::after {
        display: none;
      }
    }
    .ag-grid-checkbox-disabled {
      cursor: not-allowed;
    }

    .ag-grid-header-checkbox-disabled {
      cursor: not-allowed;
    }

    .ag-grid-header-checkbox-disabled > * {
      pointer-events: none;
    }

    .ag-disabled,
    .ag-grid-checkbox-disabled .ag-checkbox-input-wrapper {
      background: url(${CheckDisabledSvg}) no-repeat;
      background-size: cover;
    }

    .ag-grid-checkbox-disabled .ag-checkbox-input-wrapper input {
      cursor: not-allowed;
    }

    .ag-selectable-disable-row .ag-cell,
    .ag-selectable-disable-row .ag-cell span {
      color: #aeb7bc !important;
    }

    .ag-selectable-disable-row .ag-checkbox-input-wrapper {
      background: url(${CheckDisabledSvg}) no-repeat;
      background-size: cover;
    }
  }

  .disabled-cell {
    color: ${colorSet.gray7}!important;
  }

  /* Sorting */
  .ag-sort-indicator-container {
    margin-left: auto;
  }
  .ag-icon-asc {
    &::before {
      content: url(${SortAscSvg});
    }
  }
  .ag-icon-desc {
    &::before {
      content: url(${SortDescSvg});
    }
  }
  .ag-icon-none {
    &::before {
      content: url(${SortDefaultSvg});
    }
  }
`;

export default agGridCustom;
