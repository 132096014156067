import { PoData } from "@/src/components/template/pdfs/Po/types";
import { companyApi } from "@/src/store/apis/corporate/company";

type PoDataType = "EDIT" | "ADD";

interface GetPoPdfDataProps {
  type: PoDataType;
  /**
   * @description 계약 추가, 수정시 PO 데이터
   */
  contractAddAndEditPoData?: {
    sellerCorporation?: string;
    sellerContactPrefix?: string;
    sellerContact?: string;
    sellerFaxPrefix?: string;
    sellerFax?: string;
    sellerCountryName?: string;
    sellerRegion?: string;
    sellerPostalCode?: string;
    sellerLocality?: string;
    sellerStreetAddress?: string;
    sellerContactPersonName?: string;

    poNumber: string;
    buyerCorporation?: string;
    buyerContactPrefix?: string;
    buyerContact?: string;
    buyerFaxPrefix?: string;
    buyerFax?: string;
    buyerCountryName?: string;
    buyerRegion?: string;
    buyerPostalCode?: string;
    buyerLocality?: string;
    buyerStreetAddress?: string;

    orderDate: string;
    paymentTerm: string;
    paymentTermsRemark: string;
    originLocation: string;
    shippingTerm: string;
    shippingTermRemark: string;
    lastShipmentDate: string;
    deliveryDate: string;
    quantityPrefix: string;
    quantity: string;
    description: string;
    hsCode: string;
    unitPricePrefix: string;
    unitPrice: string;
    amount: string;
    remark: string;
    freeTime?: string;
    placeOfDelivery?: string;

    isAddContractTerms: boolean;
  };
  /**
   * @description 문서 공유 시 PO 데이터
   */
}

const getPoPdfData = ({
  type,
  contractAddAndEditPoData,
}: GetPoPdfDataProps) => {
  const exportersData =
    companyApi.endpoints.getExporters.useQueryState(undefined);

  switch (type) {
    case "ADD":
    case "EDIT":
      return {
        poNumber: contractAddAndEditPoData?.poNumber,
        componyLogoUrl:
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.businessLogoMedia?.mediaUrl || "",
        buyerCorporation:
          contractAddAndEditPoData?.buyerCorporation ||
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.companyName ||
          "",
        buyerContact:
          contractAddAndEditPoData?.buyerContact ||
          exportersData?.currentData?.exporterDetailQueryResultDto?.tel ||
          "",
        buyerContactPrefix:
          contractAddAndEditPoData?.buyerContactPrefix ||
          exportersData?.currentData?.exporterDetailQueryResultDto?.telPrefix ||
          "",
        buyerFax:
          contractAddAndEditPoData?.buyerFax ||
          exportersData?.currentData?.exporterDetailQueryResultDto?.fax ||
          "",
        buyerFaxPrefix:
          contractAddAndEditPoData?.buyerFaxPrefix ||
          exportersData?.currentData?.exporterDetailQueryResultDto?.faxPrefix ||
          "",
        buyerCountryName:
          contractAddAndEditPoData?.buyerCountryName ||
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.countryName ||
          "",
        buyerRegion:
          contractAddAndEditPoData?.buyerRegion ||
          exportersData?.currentData?.exporterDetailQueryResultDto?.region ||
          "",
        buyerPostalCode:
          contractAddAndEditPoData?.buyerPostalCode ||
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.postalCode ||
          "",
        buyerLocality:
          contractAddAndEditPoData?.buyerLocality ||
          exportersData?.currentData?.exporterDetailQueryResultDto?.locality ||
          "",
        buyerStreetAddress:
          contractAddAndEditPoData?.buyerStreetAddress ||
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.streetAddress ||
          "",

        sellerCorporation: contractAddAndEditPoData?.sellerCorporation,
        sellerContactPrefix: contractAddAndEditPoData?.sellerContactPrefix,
        sellerContact: contractAddAndEditPoData?.sellerContact,
        sellerContactPersonName:
          contractAddAndEditPoData?.sellerContactPersonName,
        sellerFaxPrefix: contractAddAndEditPoData?.sellerFaxPrefix,
        sellerFax: contractAddAndEditPoData?.sellerFax,
        sellerCountryName: contractAddAndEditPoData?.sellerCountryName,
        sellerRegion: contractAddAndEditPoData?.sellerRegion,
        sellerPostalCode: contractAddAndEditPoData?.sellerPostalCode,
        sellerLocality: contractAddAndEditPoData?.sellerLocality,
        sellerStreetAddress: contractAddAndEditPoData?.sellerStreetAddress,

        orderDate: contractAddAndEditPoData?.orderDate,
        paymentTerm: contractAddAndEditPoData?.paymentTerm,
        paymentTermsRemark: contractAddAndEditPoData?.paymentTermsRemark,
        originLocation: contractAddAndEditPoData?.originLocation,
        shippingTerm: contractAddAndEditPoData?.shippingTerm,
        shippingTermRemark: contractAddAndEditPoData?.shippingTermRemark,
        lastShipmentDate: contractAddAndEditPoData?.lastShipmentDate,
        deliveryDate: contractAddAndEditPoData?.deliveryDate,
        quantityPrefix: contractAddAndEditPoData?.quantityPrefix,
        quantity: contractAddAndEditPoData?.quantity,
        description: contractAddAndEditPoData?.description,
        hsCode: contractAddAndEditPoData?.hsCode,
        unitPricePrefix: contractAddAndEditPoData?.unitPricePrefix,
        unitPrice: contractAddAndEditPoData?.unitPrice,
        amount: contractAddAndEditPoData?.amount,
        remark: contractAddAndEditPoData?.remark,
        freeTime: contractAddAndEditPoData?.freeTime,
        placeOfDelivery: contractAddAndEditPoData?.placeOfDelivery,

        swiftCode:
          exportersData?.currentData?.exporterDetailQueryResultDto?.swiftCode ||
          "",
        accountNumber:
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.accountNumber || "",
        accountName:
          exportersData?.currentData?.exporterDetailQueryResultDto
            ?.accountName || "",
        buyerSignatureUrl:
          exportersData?.currentData?.signatureManagement.simpleExporterSignatureMedias?.filter(
            (item) => item.isDefault,
          )[0].signatureSimpleMedia?.mediaUrl || "",
        sellerSignatureUrl: "",

        isAddContractTerms: contractAddAndEditPoData?.isAddContractTerms,
        contractTermsTitle:
          exportersData?.currentData?.termsOfSalesContract
            .salesContractTermsWithLatestHistory?.title || "",
        contractTerms:
          exportersData?.currentData?.termsOfSalesContract
            ?.salesContractTermsWithLatestHistory
            ?.latestSalesContractTermsHistory.body || "",
      } as PoData;
  }
};

export default getPoPdfData;
