import Badge from "@/src/components/atom/Badge";
import { LinkedStatusType } from "@/src/store/apis/client/buyer/interface";
import { TFunction } from "i18next";

interface LinkStatusBadgeProps {
  status: LinkedStatusType;
  badgeSize?: "L" | "S";
  t: TFunction;
}

const LinkStatusBadge = ({
  status,
  t,
  badgeSize = "S",
}: LinkStatusBadgeProps) => {
  switch (status) {
    case "PENDING":
      return (
        <Badge
          text={t("status:unlinked")}
          color="gray7"
          badgeColor="gray10"
          badgeSize={badgeSize}
        />
      );

    case "COMPLETED":
      return (
        <Badge
          text={t("status:linked")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={badgeSize}
        />
      );

    case "RECEIVED":
      return (
        <Badge
          text={t("status:required")}
          color="green1"
          badgeColor="green6"
          badgeSize={badgeSize}
        />
      );

    case "SENT":
      return (
        <Badge
          text={t("status:approval")}
          color="yellow2"
          badgeColor="yellow6"
          badgeSize={badgeSize}
        />
      );
  }
};

export default LinkStatusBadge;
