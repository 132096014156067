import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import DeleteSvg from "@/src/assets/icons/icon-close-red.svg";
import DeleteDisableSvg from "@/src/assets/icons/icon-close-red6.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import { ReactComponent as MoveSvg } from "@/src/assets/icons/icon-move.svg";
import { Button } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAlert from "@/src/hooks/useAlert";
import { useContainerIdsFullBatchDownloadMutation } from "@/src/store/apis/containerImage";
import {
  taskDetailApi,
  useCreateTaskDetailContainerMutation,
  useDeleteTaskDetailContainerMutation,
  useUpdateContainersWeightStatusMutation,
} from "@/src/store/apis/tasks/taskDetail";
import { ContainerListViewDto } from "@/src/store/apis/tasks/taskDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { Accordion as AccordionRoot } from "@radix-ui/react-accordion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import ContainerAccordionItem from "../ContainerAccordionItem";
import CombineSplitDialog from "../dialog/CombineSplitDialog";
import TaskContainerDeleteAlertDialog from "../dialog/TaskContainerDeleteAlertDialog";

enum DialogState {
  NULL,
  COMBINE_SPLIT,
}

enum AlertDialogState {
  NULL,
  DELETE_CONTAINER,
}

const containerEmptyArray: ContainerListViewDto[] = [];

function ContainerTaskResultCard() {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();

  // API
  const [createLoadingDetailContainer] = useCreateTaskDetailContainerMutation();
  const [deleteLoadingDetailContainer] = useDeleteTaskDetailContainerMutation();
  const [containerTypeImagesDownload, containerTypeImagesDownloadData] =
    useContainerIdsFullBatchDownloadMutation();
  const [updateContainersWeightStatus] =
    useUpdateContainersWeightStatusMutation();

  const { loadingStatus, extraRemark, isLoadingError } =
    taskDetailApi.endpoints.getTaskDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;

          return {
            isLoadingError: isError,
            loadingWriterId: !isUnstable ? currentData.taskWriterId : null,
            loadingStatus: !isUnstable ? currentData.taskStatus : null,
            loadingWeightStatus: !isUnstable
              ? currentData.taskWeighingStatus
              : null,
            extraRemark: !isUnstable ? currentData.extraMediaRemark : null,
          };
        },
      },
    );
  const { containerList } =
    taskDetailApi.endpoints.getTaskDetailContainerList.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;

          return {
            containerList: !isUnstable
              ? (currentData.rows ?? containerEmptyArray)
              : containerEmptyArray,
          };
        },
      },
    );

  // State
  const [containerCheckList, setContainerCheckList] = useState<number[]>([]);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );

  const isEditAuth = isLoadingError;
  const isUnableEdit =
    isEditAuth ||
    loadingStatus === "CANCEL_TASK_REQUEST" ||
    loadingStatus === "TASK_COMPLETED";

  const selectContainerList = containerList.filter(({ id }) =>
    containerCheckList.includes(id),
  );
  const isAllContainerConfirm = selectContainerList.every(
    ({ containerWeighingStatus }) =>
      containerWeighingStatus === "WEIGHING_CHECK_COMPLETED",
  );

  const handleCheckboxClick = (checkboxItem: number) => {
    if (checkboxItem >= 0) {
      setContainerCheckList((prev) => {
        if (prev.includes(checkboxItem)) {
          return prev.filter((item) => item !== checkboxItem);
        }

        return [...prev, checkboxItem];
      });
    }
  };

  const handleDeleteClick = async () => {
    try {
      await deleteLoadingDetailContainer({
        id: Number(params.id),
        containerIdList: containerCheckList,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setIsDeleteAlertOpen(AlertDialogState.NULL);
      setContainerCheckList([]);
    }
  };

  const handleContainerImageDownloadClick = async () => {
    if (containerCheckList.length === 0) {
      return;
    }

    try {
      await containerTypeImagesDownload({
        containerIdList: containerCheckList,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleCombineSplitButtonClick = () =>
    setDialogState(DialogState.COMBINE_SPLIT);

  const handleAddContainerClick = async () => {
    try {
      await createLoadingDetailContainer({ id: Number(params.id) }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContainersConfirmClick = async () => {
    const containerCheckedIdList = containerCheckList
      .map((checkedId) => {
        const containerCheckedId = containerList.find((item) => {
          return (
            item.containerWeighingStatus === "WEIGHING_CHECK_NEEDED" &&
            item.id === checkedId
          );
        })?.id;

        return containerCheckedId;
      })
      .filter(Boolean) as number[];

    try {
      await updateContainersWeightStatus({
        id: Number(params.id),
        containerIdList: containerCheckedIdList,
      });

      alert.showAlert({
        type: "success",
        message: t("alert:containerConfirmSuccess"),
      });
    } catch (e) {
      alert.showAlert({
        type: "error",
        message: t("alert:containerConfirmError"),
      });
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.COMBINE_SPLIT) {
      return (
        <CombineSplitDialog
          loadingId={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
          selectedContainer={containerCheckList}
        />
      );
    }
  };

  const renderContainerDeleteAlert = () => {
    if (isDeleteAlertOpen === AlertDialogState.DELETE_CONTAINER) {
      return (
        <TaskContainerDeleteAlertDialog
          open={isDeleteAlertOpen === AlertDialogState.DELETE_CONTAINER}
          onOpenChange={() => setIsDeleteAlertOpen(AlertDialogState.NULL)}
          onOk={handleDeleteClick}
        />
      );
    }
  };

  return (
    <SectionCard cardTitle={t("task:detail.cards.containerLoadingResult")}>
      <SeaVantageUrlDescription>
        <Icon iconSrc={InfoGray6Svg} iconSize={16} />
        <Typo typoType="b9r" color="gray6">
          {t("task:detail.description.seaVantageUrlMutation")}
        </Typo>
      </SeaVantageUrlDescription>

      <TitleContainer>
        <AllCheckboxTitle>
          {loadingStatus !== "CANCEL_TASK_REQUEST" && (
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setContainerCheckList(containerList.map(({ id }) => id));
                } else {
                  setContainerCheckList([]);
                }
              }}
              checked={
                containerList.length === containerCheckList.length &&
                containerList.length > 0
              }
            />
          )}

          <SelectContainerCount>
            <Typo typoType="h6" color="gray5">
              {t("task:detail.cards.containerList")}
            </Typo>

            <div>
              <Typo typoType="b6m" color="blue4">
                {containerCheckList.length}
              </Typo>
              <Typo typoType="b6m" color="gray7">
                /{containerList.length}
              </Typo>
            </div>
          </SelectContainerCount>
        </AllCheckboxTitle>

        {/* Buttons */}
        {loadingStatus !== "CANCEL_TASK_REQUEST" && (
          <TitleButtonContainer>
            {/* Download */}
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={handleContainerImageDownloadClick}
              disabled={
                containerTypeImagesDownloadData.isLoading ||
                selectContainerList.length === 0
              }
            >
              {containerTypeImagesDownloadData.isLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <DownloadIcon
                    color={selectContainerList.length === 0 ? "gray8" : "gray2"}
                  />
                  {t("task:detail.cards.download")}
                </>
              )}
            </StyledButton>

            {/* Combine / Split */}
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => {
                if (loadingStatus === "TASK_COMPLETED") {
                  handleCombineSplitButtonClick();
                } else {
                  alert.showAlert({
                    type: "error",
                    message: t("alert:containerMovementCondition"),
                  });
                }
              }}
              disabled={
                isEditAuth || // 1. 수정 권한이 없거나
                containerCheckList.length === 0 // 2. 컨테이너가 하나도 선택이 안됐거나
              }
            >
              <MoveIcon
                color={
                  isEditAuth || // 1. 수정 권한이 없거나
                  containerCheckList.length === 0 // 2. 컨테이너가 하나도 선택이 안됐거나
                    ? "gray8"
                    : "gray2"
                }
              />
              {t("task:detail.cards.combineSplit")}
            </StyledButton>

            {/* Add Container */}
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={handleAddContainerClick}
              disabled={isUnableEdit}
            >
              <AddIcon color={isUnableEdit ? "gray8" : "gray2"} />
              {t("task:detail.cards.addContainer")}
            </StyledButton>

            {/* Delete */}
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              disabled={containerCheckList.length === 0 || isUnableEdit}
              onClick={() =>
                setIsDeleteAlertOpen(AlertDialogState.DELETE_CONTAINER)
              }
            >
              <Icon
                iconSrc={
                  containerCheckList.length === 0 || isUnableEdit
                    ? DeleteDisableSvg
                    : DeleteSvg
                }
                iconSize={16}
              />
              <Typo
                typoType="btn3m"
                color={
                  containerCheckList.length === 0 || isUnableEdit
                    ? "red6"
                    : "red2"
                }
              >
                {t("task:detail.cards.delete")}
              </Typo>
            </StyledButton>

            {/* Confirm */}
            <StyledButton
              buttonSize={32}
              onClick={handleContainersConfirmClick}
              disabled={
                // 체크박스 미선택, 수정권한이 없는 사람, 모든 체크 박스가 confirm이 된 상태
                containerCheckList.length === 0 ||
                isUnableEdit ||
                isAllContainerConfirm
              }
            >
              {t("task:detail.button.confirmButton")}
            </StyledButton>
          </TitleButtonContainer>
        )}
      </TitleContainer>

      <AccordionRoot type="multiple">
        {containerList.length >= 1 &&
          containerList.map((item, idx) => {
            return (
              <ContainerAccordionItem
                containerNo={item.containerNo}
                sealNo={item.sealNo}
                containerStatus={item.containerStatus}
                containerWeighingStatus={item.containerWeighingStatus}
                containerId={item.id}
                onCheckChange={(containerId) =>
                  handleCheckboxClick(containerId)
                }
                extraRemark={extraRemark}
                key={item.id}
                isUnableEdit={isUnableEdit}
                containerCheckList={containerCheckList}
                index={idx}
              />
            );
          })}
      </AccordionRoot>

      {renderDialog()}
      {renderContainerDeleteAlert()}
    </SectionCard>
  );
}

export default ContainerTaskResultCard;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 12px 16px;
`;

const TitleButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AddIcon = styled(AddSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const MoveIcon = styled(MoveSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }

  circle {
    stroke: ${({ color }) => colorSet[color]};
  }
`;

const SeaVantageUrlDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DownloadIcon = styled(DownloadSvg)<{ color: ColorType }>`
  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const LoaderContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 89px;
  text-align: center;
`;

const AllCheckboxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectContainerCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
