import OpenSvg from "@/src/assets/icons/icon-open.svg";
import { IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { bookingDetailApi } from "@/src/store/apis/bookings/bookingDetail";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

function BookingCard() {
  const { t } = useTranslation();
  const params = useParams();

  const { currentData: shippingLineList } = useGetCommonCodeViaCodeNameQuery({
    codeName: "SHIPPING_LINE",
  });

  const {
    shippingLine,
    initialEtdAt,
    etdAt,
    numberOfContainer,
    portOfLoading,
    placeOfDelivery,
    vesselAndVoyageNo,
    etdDateFormat,
    etaDateFormat,
  } = bookingDetailApi.endpoints.getBookingDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          isError,
          shippingLine: !isUnstable ? currentData.shippingLine : "-",
          initialEtdAt: !isUnstable ? currentData.initialEtdAt : null,
          etdAt: !isUnstable ? currentData.etdAt : null,
          etdDateFormat: !isUnstable
            ? dayjs(currentData.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
          etaDateFormat: !isUnstable
            ? dayjs(currentData.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
          numberOfContainer:
            !isUnstable &&
            currentData.numberOfContainer &&
            currentData.containerType
              ? `${currentData.numberOfContainer.toLocaleString("ko-KR")}X${
                  currentData.containerType
                }`
              : "-",
          vesselAndVoyageNo:
            !isUnstable && currentData.vessel && currentData.voyageNo
              ? `${currentData.vessel} ${currentData.voyageNo}`
              : "-",
          portOfLoading: !isUnstable ? (currentData.portOfLoading ?? "-") : "-",
          placeOfDelivery: !isUnstable
            ? (currentData.placeOfDelivery ?? "-")
            : "-",
        };
      },
    },
  );

  const handleShippingLineClick = () => {
    const url = shippingLineList?.find(
      (item) => item.codeItemNameEn === shippingLine,
    )?.value1;

    window.open(url, "_blank");
  };

  const renderEtd = () => {
    if (!etdAt || !initialEtdAt) {
      return <BreakWordTypo typoType="h4">-</BreakWordTypo>;
    }

    return (
      <EtdTextContainer>
        <Typo typoType="h4">{etdDateFormat}</Typo>
        <EtdCompareCount initDate={initialEtdAt} currentDate={etdAt} />
      </EtdTextContainer>
    );
  };

  return (
    <SectionCardGrid>
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:etd")}
          </Typo>
        }
        direction="vertical"
        value={renderEtd()}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:eta")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{etaDateFormat}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:noOfContainer")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{numberOfContainer}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:shippingLine")}
          </Typo>
        }
        direction="vertical"
        value={
          shippingLine !== "-" ? (
            <ShippingLine>
              <BlueText
                color="systemBlue2"
                typoType="h4"
                onClick={handleShippingLineClick}
              >
                {shippingLine}
              </BlueText>
              <IconButton
                buttonSize={24}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleShippingLineClick}
              >
                <Icon iconSrc={OpenSvg} iconSize={16} />
              </IconButton>
            </ShippingLine>
          ) : (
            <BreakWordTypo typoType="h4">-</BreakWordTypo>
          )
        }
      />

      <GrayLine />

      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:vesselAndVoyageNo")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{vesselAndVoyageNo}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:portOfLoading")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{portOfLoading}</BreakWordTypo>}
      />
      <StyledSectionCardRow
        label={
          <Typo color="gray6" typoType="b9m">
            {t("common:placeOfDelivery")}
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{placeOfDelivery}</BreakWordTypo>}
      />
    </SectionCardGrid>
  );
}

export default BookingCard;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const EtdTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ShippingLine = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
  word-break: break-all;
`;
