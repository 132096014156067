import logo from "@/src/assets/logos/logo-export.svg";

export interface ConvertToInvoiceStrings {
  invoiceNo: string;
  invoiceDate: string;
  seller?: string;
  address?: string;
  country?: string;
  contact?: string;
  buyer: string;
  buyerAddress: string;
  buyerContact: string;
  buyerCountry: string;
  paymentMethod?: string;
  paymentDate: string;
  currencyUnit: string;
  items: {
    description: string;
    unitPrice: number;
    tax: number;
    amount: number;
  }[];
  period: string;
  total: {
    unitPrice: number;
    tax: number;
    totalPrice: number;
  };
}

export const generateInvoiceHTML = (rowData: ConvertToInvoiceStrings) => {
  const {
    seller = "HRM Corporation",
    address = "205, 604, 217-59, Kintex-ro, Ilsanseo-gu, Goyang-si, Gyeonggi-do",
    contact = "+82) 319072030",
    country = "KOREA",
    paymentMethod = "Credit Card",
  } = rowData;

  return `
   <html>
      <head>
        <title>Invoice</title>
        <style>
          .body-zoom {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            height: 595px;
            zoom:1.5;
          }
          .container {
            height: 100%;
            margin: 0 auto;
            background-color: #fff;
            padding: 20px;
            box-sizing: border-box;
            position:relative;
          }
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 4px solid #1c3d61;
            padding-bottom: 10px;
          }
          .header img {
            width: 150px;
          }
          .invoice-title {
            padding: 6px 12px;
            font-family: Spoqa Han Sans Neo;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
            text-align: left;
          }
          .info-section {
            margin-bottom: 20px;
          }
          .info-section div span {
            display: inline-block;
            font-weight: bold;
          }
          .invoice-section {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 6px 12px;
          }
          .invoice-section div {
            display: flex;
            gap: 4px;
          }
          .invoice-section strong {
            font-family: Spoqa Han Sans Neo;
            font-size: 8px;
            font-weight: 700;
            line-height: 9.6px;
            text-align: left;
          }
          .invoice-section .invoice-no,
          .invoice-section .invoice-date {
            font-family: Spoqa Han Sans Neo;
            font-size: 8px;
            font-weight: 400;
            line-height: 9.6px;
            text-align: left;
          }
          .seller-buyer-payment-information {
            display: flex;
            padding: 6px 12px;
          }
          .seller,
          .buyer {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
          .seller strong,
          .buyer strong {
            font-family: Spoqa Han Sans Neo;
            font-size: 8px;
            font-weight: 700;
            line-height: 9.6px;
            text-align: left;
          }
          .seller div,
          .buyer div {
            font-family: Spoqa Han Sans Neo;
            font-size: 8px;
            font-weight: 400;
            line-height: 11.2px;
            text-align: left;
          }
          .details-table {
            width: 100%;
            border-collapse: collapse;
            border-bottom: 1px solid #1a1d20;
          }
          .details-table th,
          .details-table td {
            padding: 6px 12px;
            text-align: left;
            font-family: Spoqa Han Sans Neo;
            font-size: 8px;
            line-height: 9.6px;
            text-align: left;
          }
          .details-table td.width-50 {
            width: 50%;
          }
          .details-table td.width-12-5 {
            width: 12.5%;
          }
          .details-table th {
            background-color: #eceff2;
          }
          .highlight {
            background-color: #f4f4f4;
            font-weight: bold;
          }
          .total-table {
            width: 50%;
            margin-left: auto;
            border-spacing: 0px;
          }
          .total-table td {
            padding: 6px 12px;
            text-align: left;
            font-family: Spoqa Han Sans Neo;
            font-size: 8px;
            line-height: 9.6px;
            text-align: left;
          }
          #generate-pdf-button{
            position: absolute;
            bottom: -22px;
            right: 0;
          }
          .no-print {
            display: none;
          }
          @media print {
            .no-print {
              display: none;
            }
          }
        </style>
      </head>
      <body class="body-zoom">
        <div class="container">
          <div class="header">
            <img src=${logo} alt="ECOYA Export" />
          </div>
          <h1 class="invoice-title">INVOICE</h1>
          <div class="info-section">
            <div class="invoice-section">
              <div>
                <strong>Invoice No.</strong>
                <span class="invoice-no">${rowData.invoiceNo}</span>
              </div>
              <div>
                <strong>Invoice Date</strong>
                <span class="invoice-date">${rowData.invoiceDate}</span>
              </div>
            </div>
            <div class="seller-buyer-payment-information">
              <div class="seller">
                <strong>Seller Information</strong>
                <div>
                  ${seller}<br />
                  ${address}<br />
                  ${country}<br />
                  Contact: ${contact}
                </div>
              </div>
              <div class="buyer">
                <strong>Buyer Information</strong>
                <div>
                  ${rowData.buyer}<br />
                  ${rowData.buyerAddress}<br />
                  ${rowData.buyerCountry}<br />
                  Contact: ${rowData.buyerContact}
                </div>
              </div>
            </div>
            <div class="seller-buyer-payment-information">
              <div class="seller">
                <strong>Payment Method</strong>
                <div>${paymentMethod}</div>
              </div>
              <div class="buyer">
                <strong>Payment Date</strong>
                <div>${rowData.paymentDate}</div>
              </div>
            </div>
          </div>
          <table class="details-table">
            <thead>
              <tr>
                <th>SUBSCRIPTION INFORMATION</th>
                <th>QTY</th>
                <th>UNIT PRICE</th>
                <th>TAX</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              ${rowData.items
                .map(
                  (item) => `
                <tr>
                  <td class="width-50">
                    <b>Plan</b> ${item.description}<br />
                  </td>
                  <td class="width-12-5">1</td>
                  <td class="width-12-5">${
                    rowData.currencyUnit
                  } ${item.unitPrice.toLocaleString("ko-KR")}</td>
                  <td class="width-12-5">${
                    rowData.currencyUnit
                  } ${item.tax.toLocaleString("ko-KR")}</td>
                  <td class="width-12-5">${
                    rowData.currencyUnit
                  } ${item.amount.toLocaleString("ko-KR")}</td>
                </tr>`,
                )
                .join("")}

                <tr>
                  <td class="width-50">  
                    <b class="period">Period</b> ${rowData.period}
                  </td>
                </tr>
            </tbody>
          </table>
          <table class="total-table">
            <tr class="total-row">
              <td colSpan="4"><strong>UNIT PRICE</strong></td>
              <td>${
                rowData.currencyUnit
              } ${rowData.total.unitPrice.toLocaleString("ko-KR")}</td>
            </tr>
            <tr class="total-row">
              <td colSpan="4"><strong>TAX (10%)</strong></td>
              <td>${rowData.currencyUnit} ${rowData.total.tax.toLocaleString(
                "ko-KR",
              )}</td>
            </tr>
            <tr class="total-row highlight">
              <td colSpan="4"><strong>Total PRICE</strong></td>
              <td>${
                rowData.currencyUnit
              } ${rowData.total.totalPrice.toLocaleString("ko-KR")}</td>
            </tr>
          </table>
            <button id="generate-pdf-button" onclick="generatePdf()">Download PDF</button>
          </div>
        <script>
          function generatePdf() {
            const body = document.querySelector('body');
            const button = document.getElementById('generate-pdf-button');
            const element = document.querySelector('.container');

            body.classList.remove('body-zoom');
            button.style.visibility = 'hidden'; 
            
            const opt = {
              margin: [0, 0, 0, 0],
              filename: 'invoice.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 2, useCORS: true },
              jsPDF: { unit: 'px', orientation: 'landscape', format: [842, 595] }
            };
            
            html2pdf().from(element).set(opt).save().then(() => {
              button.style.visibility = 'visible'; 
              body.classList.add('body-zoom');
            });
          }
        </script>
        <script src="https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js"></script>
      </body>
    </html>
  `;
};

export const openInvoiceInNewWindow = (
  invoiceData: ConvertToInvoiceStrings,
) => {
  const newWindow = window.open("", "_blank");
  const invoiceHTML = generateInvoiceHTML(invoiceData);

  newWindow?.document.open();
  newWindow?.document.write(invoiceHTML);
  newWindow?.document.close();
};
