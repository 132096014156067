import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Typo from "../../atom/Typo";

const FOOTER_STRING = [
  "common:footer.corporateName",
  "common:footer.representativeName",
  "common:footer.registrationNumber",
  "common:footer.address",
];

function PublicFooter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledPublicFooter>
      <div>
        {FOOTER_STRING.map((string, idx, origin) => {
          const isLastIndex = origin.length - 1 === idx;
          const wordBreak = idx === 1 || idx === 2;

          return (
            <>
              <Typo typoType="b10m" color="gray6">
                {t(string).replace("`", "")}{" "}
              </Typo>

              {!isLastIndex && !wordBreak && (
                <Typo typoType="b10m" color="gray9">
                  |{" "}
                </Typo>
              )}

              {wordBreak && <br />}
            </>
          );
        })}

        <br />
        <Typo typoType="b10m" color="gray6">
          <span
            onClick={() => navigate(PUBLIC_PATH.TERMS)}
            style={{ cursor: "pointer" }}
          >
            {t("common:legal.en")}
          </span>{" "}
          <Typo typoType="b10m" color="gray9">
            |
          </Typo>{" "}
          {t("common:copyright.en")}
        </Typo>
      </div>
    </StyledPublicFooter>
  );
}

export default PublicFooter;

const StyledPublicFooter = styled.footer`
  padding: 16px 10px 24px 10px;
  text-align: center;
`;
