import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import RadioDisabledSvg from "@/src/assets/icons/icon-radio-disalbed-unchecked.svg";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useLazyGetSalesQuery } from "@/src/store/apis/sales";
import { SalesDto } from "@/src/store/apis/sales/interface";
import { useGetWarehousesQuery } from "@/src/store/apis/warehouse";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { ColDef, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { columnSalesList } from "../../../../columns/columnSalesList";

interface SaleContentProps {
  onSaleChange: (id: SalesDto | null) => void;
  itemCode: string;
  selectedSale: null | SalesDto;
  selectedWarehouse: "all" | number;
  mainCategory: string;
  salesPage: number;
  onPageChange: (page: number) => void;
  onWarehouseChange: (warehouse: SaleContentProps["selectedWarehouse"]) => void;
}

const EMPTY_ARRAY: [] = [];
const PAGE_SIZE = 5;

const SaleContent = ({
  itemCode,
  selectedSale,
  selectedWarehouse,
  mainCategory,
  salesPage,
  onSaleChange,
  onPageChange,
  onWarehouseChange,
}: SaleContentProps) => {
  const { t } = useTranslation();
  const ref = useRef<AgGridReact>(null);
  const [isReady, setIsReady] = useState(false);
  const [saleColumn] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => <>{radioRenderer(params)}</>,
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...columnSalesList,
  ]);

  const { isWarehousesFetching, warehouses, isWarehousesFetched } =
    useGetWarehousesQuery(
      {
        mainCategories: [mainCategory],
      },
      {
        selectFromResult: ({ currentData, isFetching, isError, isSuccess }) => {
          const isUnstable = isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            warehouses: isStable ? currentData.rows : EMPTY_ARRAY,
            warehousesCount: isStable ? currentData.count : 0,
            isWarehousesFetching: isFetching,
            isWarehousesFetched: isSuccess,
          };
        },
        refetchOnMountOrArgChange: true,
      },
    );

  const [getSales, { saleList, saleCount, isSaleFetching }] =
    useLazyGetSalesQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          saleList: isStable ? currentData.rows : EMPTY_ARRAY,
          saleCount: isStable ? currentData.count : 0,
          isSaleFetching: isFetching,
        };
      },
    });

  const fetchSales = useCallback(
    async ({ warehouseId, page }: { warehouseId: string; page?: number }) => {
      await getSales({
        warehouseIdList: warehouseId,
        itemCode,
        page,
        pageSize: PAGE_SIZE,
      });
    },
    [getSales, itemCode],
  );

  const warehouseList = warehouses.reduce<{ label: string; value: string }[]>(
    (acc, val) => {
      const resource = {
        label: val.warehouseName,
        value: val.id.toString(),
      };
      return [...acc, resource];
    },
    [{ label: t("common:all"), value: "all" }],
  );

  // AgGrid Header
  useAgGridHeaderRefresh({
    gridRef: ref?.current,
    isReady,
    headerSet: [
      {
        columnKey: "warehouse.warehouseName",
        langKey: "shipment:importer.detail.table.warehouse",
      },
      {
        columnKey: "exporterItemCode.itemCode",
        langKey: "shipment:importer.detail.table.itemCode",
      },
      {
        columnKey: "finalProducts",
        langKey: "shipment:importer.detail.table.finalProducts",
      },
    ],
  });

  const radioRenderer = (params: IRowNode) => {
    const handleRadioChange = () => {
      onSaleChange(params.data);
    };

    const checked = params.data.isChecked;

    return (
      <StyledRadio>
        <input
          type="radio"
          className="custom-ag-radio"
          checked={checked}
          onChange={handleRadioChange}
        />
        <span className="custom-radio-mark" />
      </StyledRadio>
    );
  };

  useEffect(() => {
    if (isSaleFetching && ref.current) {
      ref.current?.api?.showLoadingOverlay();
    }
  }, [isSaleFetching]);

  useEffect(() => {
    if (isWarehousesFetched) {
      /**
       * 오픈 후 첫 페칭
       */
      fetchSales({
        warehouseId:
          selectedWarehouse === "all"
            ? warehouses.map(({ id }) => id).join(",")
            : selectedWarehouse.toString(),
        page: salesPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSales, isWarehousesFetched, warehouses]);

  return (
    <>
      <AccordionContentWrapper>
        <InputContainer>
          <Typo typoType="b7m">
            {t("shipment:importer.detail.label.warehouse")}
          </Typo>
          <Select
            options={warehouseList}
            showSearch
            value={selectedWarehouse}
            suffixIcon={isWarehousesFetching ? <Loader /> : undefined}
            disabled={isWarehousesFetching}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onChange={(value, option: any) => {
              const warehouseId =
                value === "all"
                  ? warehouses.map(({ id }) => id).join(",")
                  : value;
              // 1. 선택된 입고지 ID 변경
              onWarehouseChange?.(value === "all" ? "all" : option.value);
              // 2. 발주계약 페칭
              fetchSales({ warehouseId, page: 1 });
              onPageChange(1);
            }}
          />
        </InputContainer>

        {/* <InfoDiv>
          <Icon iconSrc={InfoGray6Svg} iconSize={16} />
          <Typo typoType="b9r" color="gray6">
            {t("task:detail.dialog.containerSplitBookingInfo")}
          </Typo>
        </InfoDiv> */}

        <TableWrapper>
          <Table
            ref={ref}
            columnDefs={saleColumn}
            rowData={saleList.map((sale) =>
              sale.id === selectedSale?.id
                ? { ...sale, isChecked: true }
                : { ...sale, isChecked: false },
            )}
            height={253}
            totalPage={saleCount}
            page={salesPage}
            pageSize={PAGE_SIZE}
            handlePaginationClick={(page) => {
              fetchSales({
                warehouseId:
                  selectedWarehouse === "all"
                    ? warehouses.map(({ id }) => id).join(",")
                    : selectedWarehouse.toString(),
                page,
              });
            }}
            onRowClicked={(e) => {
              // 위로 선택된 발주 계약 데이터 올리기
              const rowData: SalesDto = e.node.data;
              onSaleChange(rowData);
            }}
            onGridReady={() => setIsReady(true)}
          />
        </TableWrapper>
      </AccordionContentWrapper>
    </>
  );
};

export default SaleContent;

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  &[data-disabled="true"] {
    input {
      cursor: not-allowed;
    }
  }

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
  .custom-ag-radio:disabled + .custom-radio-mark {
    background: url(${RadioDisabledSvg}) no-repeat;
    background-size: cover;
  }
`;

const AccordionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableWrapper = styled.div`
  background: ${colorSet.white};
  padding: 8px 8px 0;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;

  .ant-pagination-options {
    display: none;
  }
`;

// const InfoDiv = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 6px;
// `;
