import { useEffect, useState } from "react";
import EXPORTER_PRIVATE_PATH from "./routes/exporter/path";
import { useAppSelector } from "./store";
import { useGetCurrentSubscriptionQuery } from "./store/apis/subscription";

function CurrentSubscription() {
  const token = useAppSelector((state) => state.auth.token);
  const isNotificationOpen = useAppSelector(
    (state) => state.webUtil.isNotificationOpen,
  );
  const [userToken, setUserToken] = useState(token);
  const isExportPath = window.location.pathname.includes("export");

  const isValidationUrl =
    window.location.pathname.includes(
      EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP,
    ) ||
    window.location.pathname.includes(
      EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING,
    ) ||
    window.location.pathname.includes(
      EXPORTER_PRIVATE_PATH.SUBSCRIPTION_ANNUALLY_TERMINATION,
    ) ||
    window.location.pathname.includes(EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP);

  useGetCurrentSubscriptionQuery(undefined, {
    skip: !userToken || !isExportPath || isValidationUrl,
    refetchOnMountOrArgChange: true,
    pollingInterval: !isNotificationOpen ? 30000 : 0,
  });

  useEffect(() => {
    setUserToken(token);
  }, [token]);

  return <></>;
}

export default CurrentSubscription;
