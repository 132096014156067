import Table from "@/src/components/atom/Table";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useLazyGetImporterWarehousingQuery } from "@/src/store/apis/contracts/contractDetail";
import { WarehousingDto } from "@/src/store/apis/sales/interface";
import { isUndefined } from "@/src/utils/is";
import { createViewAction } from "@/src/utils/row-data-util";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { columnStoreList } from "../../columns/columnStoreList";
import SalesContainerListDialog from "./dialog/SalesContainerListDialog";

const warehouseEmptyArray: WarehousingDto[] = [];

enum DialogState {
  NULL,
  VIEW,
}

function StoreTab() {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [columnDefs] = useState(columnStoreList(t));
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [selectWarehouse, setSelectWarehouse] = useState<WarehousingDto | null>(
    null,
  );

  const [
    getImporterWarehousing,
    { isError, isFetching, count, warehouseList },
  ] = useLazyGetImporterWarehousingQuery({
    selectFromResult: ({ isError, isFetching, currentData }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isError,
        isFetching,
        count: !isUnstable ? currentData.count : 0,
        warehouseList: !isUnstable
          ? (currentData.rows ?? warehouseEmptyArray)
          : warehouseEmptyArray,
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady: true,
    headerSet: [
      {
        columnKey: "purchaseOrderNo",
        langKey: "contract:importer.detail.storeList.purchaseOrderNo",
      },
      {
        columnKey: "receiptDate",
        langKey: "contract:importer.detail.storeList.receiptDate",
      },
      {
        columnKey: "receiptQty",
        langKey: "contract:importer.detail.storeList.receiptQty",
      },
      {
        columnKey: "warehouse",
        langKey: "contract:importer.detail.storeList.warehouse",
      },
      {
        columnKey: "receiptCertificate",
        langKey: "contract:importer.detail.storeList.receiptCertificate",
      },
      {
        columnKey: "numberOfContainers",
        langKey: "contract:importer.detail.storeList.numberOfContainers",
      },
      {
        columnKey: "containerDetails",
        langKey: "contract:importer.detail.storeList.containerDetails",
      },
    ],
  });

  // Submit
  const handleSubmit = useCallback(
    async (param?: { page?: number; pageSize?: number }) => {
      const page = param?.page || pagination.page;
      const pageSize = param?.pageSize || pagination.pageSize;
      const warehouseParams = { page, pageSize, id: Number(params.id) };

      try {
        await getImporterWarehousing(warehouseParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getImporterWarehousing,
      pagination.page,
      pagination.pageSize,
      params.id,
    ],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  const handleViewClick = (data: any) => {
    setSelectWarehouse(data);
    setDialogState(DialogState.VIEW);
  };

  const renderDialog = () => {
    if (dialogState === DialogState.VIEW && selectWarehouse) {
      return (
        <SalesContainerListDialog
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setSelectWarehouse(null);
          }}
          warehousingId={selectWarehouse.id}
          salesContractId={selectWarehouse.sales.id}
        />
      );
    }
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard cardTitle={t("contract:importer.detail.storeList.title")}>
      <Table
        ref={gridRef}
        totalPage={count}
        rowData={isError ? [] : warehouseList}
        columnDefs={columnDefs}
        pageSize={pagination.pageSize}
        page={pagination.page}
        isPaginationDataMaping
        handlePaginationClick={handlePaginationClick}
        onCellClicked={(e) => {
          const selectCell = e.node.data;
          const isSalesIdClick =
            e.column.getColDef().field === "purchaseOrderNo";

          if (isSalesIdClick) {
            navigate(
              `${IMPORTER_PRIVATE_PATH.SALES_DETAIL}/${selectCell.sales.id}`,
            );
          }
        }}
        components={{
          ...createViewAction((row) => {
            handleViewClick(row);
          }),
        }}
      />
      {renderDialog()}
    </SectionCard>
  );
}

export default StoreTab;
