import { Button } from "@/src/components/atom/Button";
import Table from "@/src/components/atom/Table";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { authApi } from "@/src/store/apis/auth";
import { useLazyGetSubscriptionsQuery } from "@/src/store/apis/subscription";
import { SubscriptionDto } from "@/src/store/apis/subscription/interface";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { columnSubscriptionList } from "../columns/columnList";
import PaymentDetailDialog from "./dialog/PaymentDetailDialog";

const subscriptionEmptyArray: SubscriptionDto[] = [];

enum DialogState {
  NULL,
  PAYMENT_DETAIL,
}

function MonthlyUsageHistory() {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefs] = useState<ColDef[]>(columnSubscriptionList(t));
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  // API
  const session = authApi.endpoints.getSession.useQueryState(undefined);
  const [getSubscriptions, { isFetching, count, list }] =
    useLazyGetSubscriptionsQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          isFetching,
          count: isStable ? currentData.count : 0,
          list: isStable ? currentData.rows : subscriptionEmptyArray,
        };
      },
    });

  const isManagerType =
    session.currentData?.row.exporterUserType === "MANAGER" ||
    session.currentData?.row.exporterUserType === "MIDDLE_MANAGER";

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "subscriptionType",
        langKey: "subscriptionManagement:subscription.table.subscriptionType",
      },
      {
        columnKey: "planName",
        langKey: "subscriptionManagement:subscription.table.planName",
      },
      {
        columnKey: "subscriptionStartAtFrom",
        langKey:
          "subscriptionManagement:subscription.table.subscriptionStartAtFrom",
      },
      {
        columnKey: "subscriptionEndAtTo",
        langKey:
          "subscriptionManagement:subscription.table.subscriptionEndAtTo",
      },
      {
        columnKey: "usedLoadingCredit",
        langKey: "subscriptionManagement:subscription.table.usedLoadingCredit",
      },
      {
        columnKey: "usedMember",
        langKey: "subscriptionManagement:subscription.table.usedMember",
      },
      {
        columnKey: "subscriptionStatus",
        langKey: "subscriptionManagement:subscription.table.subscriptionStatus",
      },
    ],
  });

  const handlePaymentDetailButtonClick = () => {
    setDialogState(DialogState.PAYMENT_DETAIL);
  };

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;
      const params = {
        page,
        pageSize,
      };

      try {
        await getSubscriptions(params).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [alert, getSubscriptions, pagination.page, pagination.pageSize],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  const renderDialog = () => {
    if (dialogState === DialogState.PAYMENT_DETAIL) {
      return (
        <PaymentDetailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard
      cardTitle={t("subscriptionManagement:subscription.monthlyUsageDetails")}
      rightAccessory={
        <Button
          buttonSize={32}
          onClick={handlePaymentDetailButtonClick}
          disabled={isManagerType || list.length === 0}
        >
          {t("subscriptionManagement:subscription.button.paymentDetails")}
        </Button>
      }
    >
      <Table
        ref={gridRef}
        rowData={list}
        columnDefs={columnDefs}
        isPaginationDataMaping
        totalPage={count}
        pageSize={pagination.pageSize}
        page={pagination.page}
        height={252}
        handlePaginationClick={handlePaginationClick}
        onGridReady={() => {
          setIsReady(true);
        }}
      />

      {renderDialog()}
    </SectionCard>
  );
}

export default MonthlyUsageHistory;
