import Typo from "@/src/components/atom/Typo";
import Steps from "@/src/components/molecule/Steps";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import useAlert from "@/src/hooks/useAlert";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppSelector } from "@/src/store";
import {
  ClientType,
  SignupJoinPathType,
} from "@/src/store/apis/auth/interface";
import { useLazyGetBuyersNoTokenQuery } from "@/src/store/apis/client/buyer";
import { aesDecrypt } from "@/src/utils/aesDecrypt";
import { isUndefined } from "@/src/utils/is";
import { transformURLSearchParamsToObject } from "@/src/utils/transform";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BookingDecrypt } from "../../private/SharedEmail/SharedBooking";
import { ContractDecrypt } from "../../private/SharedEmail/SharedContract";
import { ShipmentDecrypt } from "../../private/SharedEmail/SharedShipment";
import { TaskDecrypt } from "../../private/SharedEmail/SharedTask";
import TemporarySignupStepOne from "./TemporarySignupStepOne";
import TemporarySignupStepThree from "./TemporarySignupStepThree";
import TemporarySignupStepTwo from "./TemporarySignupStepTwo";

export type TemporarySignUpFormType = {
  companyType?: ClientType;
  countryCodeItemName?: string;
  businessNumber?: string;
  verifyString: string;
  companyName?: string;
  tel?: string;
  telPrefix?: string;
  faxPrefix?: string;
  fax?: string;
  countryName?: string;
  locality?: string;
  postalCode?: string;
  streetAddress?: string;
  region?: string;
  industryCodeItemNames: string[];
  name: string;
  aId: string;
  password: string;
  mainCategoryCodeItemNames: string[];
  authTokenId: string;
  requiredTermsIdList: number[];
  optionalTermsHistoryIdList: number[];
  isMarketingEmailReceive: boolean;

  joinPath?: SignupJoinPathType | undefined;
  joinPathDescription?: string;

  city: string;
  gmtOffset: number;
};

// Button Status Context
type TemporarySignUpButtonStatusType = {
  isEmailConfirm: boolean;
  isCodeSendClick: boolean;
  isCodeConfirm: boolean;
};

type TemporarySignupButtonStateContextType = TemporarySignUpButtonStatusType & {
  onButtonStatusChange: React.Dispatch<
    React.SetStateAction<TemporarySignUpButtonStatusType>
  >;
};

export const TemporarySignupButtonStatusContext = createContext<
  TemporarySignupButtonStateContextType | undefined
>(undefined);

// Form Context
export type TemporarySignUpRootContextType = TemporarySignUpFormType & {
  step?: number;
  setStep?: Dispatch<SetStateAction<number>>;
  onFormValueChange: Dispatch<SetStateAction<TemporarySignUpFormType>>;
};

export const TemporarySignupRootContext = createContext<
  TemporarySignUpRootContextType | undefined
>(undefined);

const SPLIT_TEXT = "?buyer=";

const TemporarySignupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const token = useAppSelector((state) => state.auth.token);

  const sharedEncryptKey = location.search.split(SPLIT_TEXT)[1]
    ? location.search.split(SPLIT_TEXT)[1]
    : "";
  /**
   *  @description
   *  1. 암호화된 query buyerId 추출,
   *  2. decryptToObject의타입은 공유된 도메인(계약,부킹,작업,선적)에 따라 다름
   */
  const decryptToObject = transformURLSearchParamsToObject(
    aesDecrypt(sharedEncryptKey),
  ) as ContractDecrypt | BookingDecrypt | TaskDecrypt | ShipmentDecrypt;

  const [step, setStep] = useState<number>(1);
  const [temporarySignupForm, setTemporarySignupForm] =
    useState<TemporarySignUpFormType>({
      companyType: "BUYER",
      countryName: undefined,
      countryCodeItemName: undefined,
      businessNumber: undefined,
      companyName: undefined,
      tel: undefined,
      telPrefix: undefined,
      faxPrefix: undefined,
      fax: undefined,
      locality: undefined,
      postalCode: undefined,
      streetAddress: undefined,
      region: undefined,
      industryCodeItemNames: [],
      name: "",
      aId: "",
      password: "",
      mainCategoryCodeItemNames: [],
      authTokenId: "",
      verifyString: "",
      requiredTermsIdList: [],
      optionalTermsHistoryIdList: [],
      isMarketingEmailReceive: false,
      joinPath: undefined,
      joinPathDescription: "",
      city: "",
      gmtOffset: 0,
    });
  const [temporaryButtonStatus, setTemporaryButtonStatus] =
    useState<TemporarySignUpButtonStatusType>({
      isEmailConfirm: false,
      isCodeSendClick: false,
      isCodeConfirm: false,
    });

  const [getBuyersNoToken] = useLazyGetBuyersNoTokenQuery();

  const goToLogin = useCallback(() => {
    return navigate(PUBLIC_PATH.LOGIN);
  }, [navigate]);

  // 복호화된 데이터로 수입자 데이터 가져오기
  const getBuyers = useCallback(async () => {
    if (isUndefined(decryptToObject.buyerId)) {
      return goToLogin();
    }

    try {
      getBuyersNoToken({ id: Number(decryptToObject.buyerId) }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  }, [alert, decryptToObject.buyerId, getBuyersNoToken, goToLogin]);

  // 새로고침 감지
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <TemporarySignupStepOne buyerId={Number(decryptToObject.buyerId)} />
        );
      case 2:
        return <TemporarySignupStepTwo />;
      case 3:
        return <TemporarySignupStepThree />;
    }
  };

  const renderContent = () => {
    if (isUndefined(decryptToObject.buyerId)) {
      return <></>;
    }

    return (
      <SignUpLayout isLogoClick={false}>
        <StyledMain>
          <Title typoType="d3">{t("signup:common.signupForACorp")}</Title>
          <StepsContainer>
            <Steps
              items={[
                {
                  title: t("temporarySignupCorp:steps.firstStep"),
                },
                {
                  title: t("temporarySignupCorp:steps.secondStep"),
                },
                {
                  title: t("temporarySignupCorp:steps.thirdStep"),
                },
              ]}
              current={step}
            />
          </StepsContainer>

          <TemporarySignupButtonStatusContext.Provider
            value={{
              ...temporaryButtonStatus,
              onButtonStatusChange: setTemporaryButtonStatus,
            }}
          >
            <TemporarySignupRootContext.Provider
              value={{
                step,
                setStep,
                onFormValueChange: setTemporarySignupForm,
                ...temporarySignupForm,
              }}
            >
              {renderStepContent()}
            </TemporarySignupRootContext.Provider>
          </TemporarySignupButtonStatusContext.Provider>
        </StyledMain>
      </SignUpLayout>
    );
  };

  useEffect(() => {
    if (token) {
      return goToLogin();
    }

    if (isUndefined(decryptToObject.buyerId)) {
      goToLogin();
    } else {
      getBuyers();
    }
  }, [decryptToObject.buyerId, getBuyers, goToLogin, token]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return renderContent();
};

export default TemporarySignupPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`;

const Title = styled(Typo)`
  padding: 24px 0;
`;

const StepsContainer = styled.div`
  width: 800px;
`;
