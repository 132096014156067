import { utcNow } from "@/src/utils/utcDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "./store";
import { commonApi } from "./store/apis/common";
import { closePopup } from "./store/slice/webUtil";
import colorSet from "./styles/color";
import zIndex from "./styles/zIndex";

const today = utcNow();

const isAfterOrSame = (day: Dayjs) => {
  return today.isAfter(day, "minute") || today.isSame(day, "minute");
};

const isBeforeOrSame = (day: Dayjs) => {
  return today.isBefore(day, "minute") || today.isSame(day, "minute");
};

const Popup = () => {
  const isOkWithPopup = useAppSelector((state) => state.webUtil.isOkWithPopup);
  const dispatch = useAppDispatch();
  const isOkWithPopupRef = useRef(isOkWithPopup);

  commonApi.endpoints.getPopup.useQuerySubscription();

  const { popupList } = commonApi.endpoints.getPopup.useQueryState(undefined, {
    selectFromResult: ({ currentData }) => ({
      popupList:
        currentData?.rows.filter(({ displayAtTo, displayAtFrom }) => {
          return (
            isAfterOrSame(dayjs.utc(displayAtFrom)) &&
            isBeforeOrSame(dayjs.utc(displayAtTo))
          );
        }) ?? [],
    }),
  });

  useEffect(() => {
    dispatch(closePopup());
  }, [dispatch]);

  return isOkWithPopupRef.current && popupList.length ? (
    <>
      {popupList.map(({ popUpType, pooUpMedia, link, id }) => {
        return (
          <PopupByType
            key={id}
            type={popUpType}
            imgSrc={pooUpMedia?.mediaUrl ?? ""}
            link={link}
            id={id}
          />
        );
      })}
    </>
  ) : null;
};

export default Popup;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${zIndex.popup};
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const PopupByType = ({
  type,
  imgSrc,
  link,
  id,
}: {
  type: "TYPE_ONE" | "TYPE_TWO" | "TYPE_THREE";
  imgSrc: string;
  link?: string;
  id: number;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const popupInLocalStorage = JSON.parse(
    localStorage.getItem("unviewablePopup") || "[]",
  );

  if (
    dayjs
      .utc()
      .isBefore(
        (popupInLocalStorage as { id: number; to: string }[])?.find(
          ({ id: localstorageId }) => localstorageId === id,
        )?.to,
        "minute",
      )
  ) {
    return null;
  }

  const closeToTomorrow = () => {
    const unviewablePopupList = localStorage.getItem("unviewablePopup");
    let list = [];

    if (unviewablePopupList) {
      list = JSON.parse(unviewablePopupList);
    }

    localStorage.setItem(
      "unviewablePopup",
      JSON.stringify([
        ...(list as { id: number; to: string }[]).filter(
          ({ id: localstorageId }) => localstorageId !== id,
        ),
        { id, to: utcNow().add(1, "day").endOf("day").toISOString() },
      ]),
    );
    setIsOpen(false);
  };

  if (!isOpen) return <></>;

  return (
    <Overlay>
      <RelativeDiv>
        <PopupArticle type={type}>
          <StyledImage
            src={imgSrc}
            alt="ad-popup"
            style={link ? { cursor: "pointer" } : undefined}
            onClick={() => {
              if (link) {
                window.open(link, "_blank");
              }
            }}
          />
          <ButtonSection>
            <Button onClick={closeToTomorrow}>
              {t("common:hidePopupForToday")}
            </Button>
            <Button onClick={() => setIsOpen(false)}>
              {t("common:close")}
            </Button>
          </ButtonSection>
        </PopupArticle>
      </RelativeDiv>
    </Overlay>
  );
};

const PopupArticle = styled.article<{
  type: "TYPE_ONE" | "TYPE_TWO" | "TYPE_THREE";
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    ${({ type }) =>
      type === "TYPE_ONE"
        ? css`
            width: 330px;
            height: 500px;
          `
        : type === "TYPE_TWO"
          ? css`
              width: 500px;
              height: 330px;
            `
          : css`
              width: 500px;
              height: 500px;
            `};
  }
`;

const ButtonSection = styled.section`
  width: 100%;
  display: flex;
`;

const Button = styled.button`
  flex: 1;
  padding: 8px 0;
  text-align: center;
  border: 1px solid #eceff2;
  background: ${colorSet.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #1a1d20;
`;

const StyledImage = styled.img`
  object-fit: contain;
  background-color: white;
  margin-bottom: -2px;
`;
