import { QueryDefinition } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getQueryString from "../components/molecule/Filter/utils/getQueryString";
import useAlert from "./useAlert";

type UseFilterSearchProps<P, F> = {
  isReady: boolean;
  gridRef: AgGridReact | null;
  fetch?: LazyQueryTrigger<QueryDefinition<P, any, any, any>>;
  defaultFilterData: F;
  transformUrlToParams?: (urlSet: Record<keyof P, any>) => P;
  onBeforeSetFilter?: (urlSet: Record<keyof P, string>) => F;
  onBeforeSearch?: (filterData: F) => P;
  skipMountSubmit?: boolean;
};

type OrderType = "asc" | "desc";

const NUMBER_TYPE_VALUE_KEY = ["page", "pageSize"];
const PAGINATION_TYPE_VALUE_KEY = ["sort", "order"];

const get = (searchParams: URLSearchParams, key: string) => {
  return searchParams.get(key);
};

const useFilterSearch = <ParamsType extends {}, FilterType extends {}>({
  isReady,
  gridRef,
  fetch,
  defaultFilterData,
  transformUrlToParams,
  onBeforeSetFilter,
  onBeforeSearch,
  skipMountSubmit,
}: UseFilterSearchProps<ParamsType, FilterType>) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sort, setSort] = useState<{
    sort: string;
    order: "asc" | "desc";
  }>();
  const [filterData, setFilterData] = useState<FilterType>(defaultFilterData);

  const changeQuery = useCallback(
    (querySource: any) => {
      const query = getQueryString(querySource);
      navigate(query, { replace: true });
    },
    [navigate],
  );

  const handleFilterDataChange = useCallback(
    (key: keyof FilterType, value: any) =>
      setFilterData((prev) => {
        return { ...prev, [key]: value };
      }),
    [],
  );

  const resetSort = useCallback(
    () => gridRef?.columnApi.resetColumnState(),
    [gridRef?.columnApi],
  );

  const resetPagination = useCallback(
    () =>
      setPagination({
        page: 1,
        pageSize: 10,
      }),
    [],
  );

  const resetPaginationAndQuery = useCallback(() => {
    setPagination({
      page: 1,
      pageSize: 10,
    });
    changeQuery({
      data: onBeforeSearch ? onBeforeSearch(filterData) : filterData,
      page: 1,
      pageSize: 10,
    });
  }, [changeQuery, filterData, onBeforeSearch]);

  const handleResetFilter = useCallback(async () => {
    try {
      await fetch?.({
        page: 1,
        pageSize: 10,
      } as unknown as ParamsType).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    } finally {
      resetSort();
      resetPagination();
      setFilterData(defaultFilterData);
      changeQuery({
        page: 1,
        pageSize: 10,
      });
    }
  }, [
    alert,
    changeQuery,
    defaultFilterData,
    fetch,
    resetPagination,
    resetSort,
  ]);

  const handleMountSubmit = useCallback(async () => {
    try {
      const transformedPageToNumberType = Object.entries(
        Object.fromEntries(searchParams),
      )
        .map(([key, value]) => {
          return NUMBER_TYPE_VALUE_KEY.includes(key)
            ? { [key]: Number(value) }
            : { [key]: value };
        })
        .reduce<ParamsType>((acc, val) => {
          return { ...acc, ...val };
        }, {} as ParamsType);

      const params = transformUrlToParams
        ? transformUrlToParams(
            transformedPageToNumberType as Record<keyof ParamsType, any>,
          )
        : transformedPageToNumberType;

      const removeSortAndPagination = Object.entries(
        Object.fromEntries(searchParams),
      )
        .filter(([key]) => {
          return ![
            ...NUMBER_TYPE_VALUE_KEY,
            ...PAGINATION_TYPE_VALUE_KEY,
          ].includes(key);
        })
        .reduce<ParamsType>((acc, [key, val]) => {
          return { ...acc, [key]: val };
        }, {} as ParamsType);

      const transformedFilterData = onBeforeSetFilter
        ? onBeforeSetFilter(removeSortAndPagination as any)
        : (removeSortAndPagination as unknown as FilterType);

      setFilterData(transformedFilterData);
      await fetch?.({
        page: 1,
        pageSize: 10,
        ...params,
        order: sort?.order.toUpperCase(),
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  }, [
    alert,
    fetch,
    searchParams,
    onBeforeSetFilter,
    transformUrlToParams,
    sort?.order,
  ]);

  const handleSearch = useCallback(async () => {
    const common = {
      page: 1,
      pageSize: pagination.pageSize,
    };
    try {
      const params = onBeforeSearch
        ? {
            ...onBeforeSearch(filterData),
            ...common,
          }
        : { ...(filterData as unknown as ParamsType), ...common };

      await fetch?.(params).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    } finally {
      resetSort();
      setPagination((prev) => ({
        page: 1,
        pageSize: prev.pageSize,
      }));
      const params = onBeforeSearch
        ? {
            ...onBeforeSearch(filterData),
          }
        : { ...(filterData as unknown as ParamsType) };

      changeQuery({
        data: params,
        ...common,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onBeforeSearch,
    filterData,
    fetch,
    alert,
    resetSort,
    resetPagination,
    changeQuery,
  ]);

  const handlePaginationChange = useCallback(
    async (paginationParam?: { page: number; pageSize: number }) => {
      try {
        setPagination({
          page: paginationParam?.page || 1,
          pageSize: paginationParam?.pageSize || 10,
        });

        const commonParams = {
          page: paginationParam?.page || 1,
          pageSize: paginationParam?.pageSize || 10,
          sort: sort ? sort.sort : undefined,
          order: sort ? sort.order.toUpperCase() : undefined,
        };

        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(filterData),
              ...commonParams,
            }
          : { ...(filterData as unknown as ParamsType), ...commonParams };

        await fetch?.(params).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      } finally {
        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(filterData),
            }
          : { ...(filterData as unknown as ParamsType) };

        changeQuery({
          data: params,
          page: paginationParam?.page || 1,
          pageSize: paginationParam?.pageSize || 10,
          sort: sort ? sort.sort : undefined,
          order: sort ? sort.order.toUpperCase() : undefined,
        });
      }
    },
    [alert, changeQuery, fetch, filterData, sort, onBeforeSearch],
  );

  const handleSortChange = useCallback(
    async (sortParams?: { sort: string; order: "asc" | "desc" }) => {
      try {
        setSort(sortParams ?? undefined);

        const commonParams = {
          page: pagination.page,
          pageSize: pagination.pageSize,
          sort: sortParams?.sort,
          order: sortParams?.order.toUpperCase(),
        };

        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(filterData),
              ...commonParams,
            }
          : { ...(filterData as unknown as ParamsType), ...commonParams };

        await fetch?.(params).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      } finally {
        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(filterData),
            }
          : { ...(filterData as unknown as ParamsType) };

        changeQuery({
          data: params,
          page: pagination.page,
          pageSize: pagination.pageSize,
          sort: sortParams?.sort,
          order: sortParams?.order.toUpperCase(),
        });
      }
    },
    [
      alert,
      changeQuery,
      fetch,
      filterData,
      pagination.page,
      pagination.pageSize,
      onBeforeSearch,
    ],
  );

  const handleForceSearch = useCallback(
    async (key: keyof FilterType, value: any) => {
      setFilterData((prev) => {
        return { ...prev, [key]: value };
      });
      const forceSearchParams = { ...filterData, [key]: value };

      const common = {
        page: 1,
        pageSize: 10,
      };
      try {
        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(forceSearchParams),
              ...common,
            }
          : { ...(forceSearchParams as unknown as ParamsType), ...common };

        await fetch?.(params).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      } finally {
        resetSort();
        resetPagination();
        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(forceSearchParams),
            }
          : { ...(forceSearchParams as unknown as ParamsType) };

        changeQuery({
          data: params,
          ...common,
        });
      }
    },
    [
      alert,
      changeQuery,
      fetch,
      filterData,
      resetPagination,
      resetSort,
      onBeforeSearch,
    ],
  );

  const handleMultipleItemChangeForceSearch = useCallback(
    async (items: Partial<{ [K in keyof FilterType]: any }>[]) => {
      const array = items.reduce(
        (acc, cur) => {
          const key = Object.keys(cur)[0] as keyof FilterType;
          acc[key] = cur[key];
          return acc;
        },
        {} as { [K in keyof FilterType]: any },
      );

      setFilterData((prev) => {
        return { ...prev, ...array };
      });
      const forceSearchParams = { ...filterData, ...array };

      const common = {
        page: 1,
        pageSize: 10,
      };
      try {
        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(forceSearchParams),
              ...common,
            }
          : { ...(forceSearchParams as unknown as ParamsType), ...common };

        await fetch?.(params).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      } finally {
        resetSort();
        resetPagination();
        const params = onBeforeSearch
          ? {
              ...onBeforeSearch(forceSearchParams),
            }
          : { ...(forceSearchParams as unknown as ParamsType) };

        changeQuery({
          data: params,
          ...common,
        });
      }
    },
    [
      alert,
      changeQuery,
      fetch,
      filterData,
      resetPagination,
      resetSort,
      onBeforeSearch,
    ],
  );

  useLayoutEffect(() => {
    const sortKey = get(searchParams, "sort");
    const sortOrder = get(searchParams, "order");
    const page = get(searchParams, "page");
    const pageSize = get(searchParams, "pageSize");

    const hasPreviousSort = sortKey && sortOrder;
    const hasPreviousPagination = page && pageSize;

    if (hasPreviousSort) {
      setSort({
        sort: sortKey,
        order: sortOrder.toLowerCase() as OrderType,
      });
    }

    if (hasPreviousPagination) {
      setPagination({
        page: Number(page),
        pageSize: Number(pageSize),
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const sortKey = get(searchParams, "sort");
    const sortOrder = get(searchParams, "order");
    if (!sortKey && !sortOrder) {
      if (!skipMountSubmit) {
        handleMountSubmit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isReady) return;

    const isSortedTable = !!sort;

    if (isSortedTable) {
      gridRef?.columnApi.applyColumnState({
        state: [
          {
            colId: sort.sort,
            sort: sort.order,
          },
        ],
      });
    } else {
      gridRef?.columnApi.applyColumnState({
        state: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  return {
    state: {
      pagination,
      sort,
      filterData,
    },
    func: {
      onPaginationChange: handlePaginationChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onMountSubmit: handleMountSubmit,
      onFilterDataChange: handleFilterDataChange,
      onResetFilter: handleResetFilter,
      onForceSearch: handleForceSearch,
      onMultipleItemChangeForceSearch: handleMultipleItemChangeForceSearch,
      onResetPaginationAndQuery: resetPaginationAndQuery,
    },
  };
};

export default useFilterSearch;
