import BookmarkLineSvg from "@/src/assets/icons/icon-bookmark-line.svg";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import colorSet from "@/src/styles/color";
import { styled } from "styled-components";
import Icon from "../../atom/Icon";
import Typo from "../../atom/Typo";

interface BookMarkStatusProps {
  bookMarkStatus?: boolean;
  bookMarkCount?: number;
  onClickBookMark: () => void;
  onClickCount: () => void;
  isEditAuth?: boolean;
}

function BookMarkStatus({
  bookMarkStatus,
  bookMarkCount,
  onClickBookMark,
  onClickCount,
  isEditAuth = true,
}: BookMarkStatusProps) {
  const renderBookMarkCount = () => {
    return bookMarkCount ?? "0";
  };

  return (
    <BookMarkStatusContainer>
      <BookMark
        data-disabled={!isEditAuth}
        onClick={() => isEditAuth && onClickBookMark()}
      >
        {bookMarkStatus === true ? (
          <Icon iconSrc={BookmarkSvg} iconSize={20} />
        ) : (
          <Icon iconSrc={BookmarkLineSvg} iconSize={20} />
        )}
      </BookMark>

      <GrayLine />

      <CountTypo
        typoType="label3"
        color="gray6"
        onClick={() => isEditAuth && onClickCount()}
        data-disabled={!isEditAuth}
      >
        {renderBookMarkCount()}
      </CountTypo>
    </BookMarkStatusContainer>
  );
}

export default BookMarkStatus;

const BookMarkStatusContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 46px;
  box-shadow:
    0px 1px 2px 0px rgba(5, 29, 57, 0.1),
    0px 0px 0px 0.5px rgba(160, 164, 171, 0.2);
  height: 32px;
`;

const GrayLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${colorSet.gray9};
`;

const BookMark = styled.div`
  padding: 6px 8px 6px 12px;
  cursor: pointer;

  &[data-disabled="true"] {
    cursor: not-allowed;
  }
`;

const CountTypo = styled(Typo)`
  padding: 6px 16px 6px 12px;
  cursor: pointer;

  &[data-disabled="true"] {
    cursor: not-allowed;
  }
`;
