import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { useLazyGetImporterContractDetailQuery } from "@/src/store/apis/contracts/contractDetail";
import { ContractDetailViewForImporterDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { useLazyGetImporterContractListQuery } from "@/src/store/apis/contracts/contractList";
import colorSet from "@/src/styles/color";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { columnLoadContract } from "../../columns/columnLoadContract";

interface LoadContractsDialogProps {
  open: boolean;
  onClose: () => void;
  onFetchContractViaId: (detail: ContractDetailViewForImporterDto) => void;
}

enum AlertDialogState {
  NULL,
  SELECT,
}

const EMPTY_ARRAY: [] = [];

const LoadContractDialog = ({
  open,
  onClose,
  onFetchContractViaId,
}: LoadContractsDialogProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();

  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [buyerCode, setBuyerCode] = useState<string>();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(columnLoadContract(setSelectedId));

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "buyerNameCode",
        langKey: "table:sellerCode",
      },
      {
        columnKey: "createdAt",
        langKey: "table:contractRegistration",
      },
      {
        columnKey: "poNo",
        langKey: "table:poNo",
      },
      {
        columnKey: "item",
        langKey: "table:item",
      },
      {
        columnKey: "qty",
        langKey: "table:qty",
      },
      {
        columnKey: "price",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "location",
        langKey: "table:location",
      },
    ],
  });

  const { isBuyerListFetching, buyers } = useGetBuyersQuery(
    {},
    {
      selectFromResult: ({ currentData, isFetching, isError }) => ({
        buyers: !isError && currentData?.data ? currentData.data : [],
        isBuyerListError: isError,
        isBuyerListFetching: isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );
  const [getContracts, { isFetching, count, list }] =
    useLazyGetImporterContractListQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => ({
        isFetching,
        list: !isError ? currentData?.rows || EMPTY_ARRAY : EMPTY_ARRAY,
        count: currentData?.count || 0,
      }),
    });
  const [getContractDetail] = useLazyGetImporterContractDetailQuery();

  const contractsList = list.reduce<any>((acc, val) => {
    const resource = {
      id: val.id,
      buyerNameCode: val.buyerNameCode,
      createdAt: val.orderDateAt
        ? dayjs(val.orderDateAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
        : "-",
      poNo: val.poNo,
      item: val.itemCode,
      qty: `${Number(val.quantity).toLocaleString("ko-KR")} ${
        val.quantityUnit
      }`,
      price: `${Number(val.unitPrice).toLocaleString("ko-KR")} ${
        val.unitPriceUnit
      }`,
      shippingTerms: val.shippingTerms,
      placeOfDelivery: val.placeOfDelivery ?? "",
      location: val.origin ?? "",
      isChecked: selectedId === val.id,
    };
    return [...acc, resource];
  }, []);

  const buyerList = buyers.reduce<{ label: string; value: string }[]>(
    (acc, val) => {
      const resource = {
        label: val?.buyerNameCode || "",
        value: `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
      };
      return [...acc, resource];
    },
    [{ label: t("common:all"), value: "all" }],
  );

  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getContracts({
        // isExporterMainCategoryFilterOn: true,
        page: 1,
        pageSize: 10,
      });
    })();
  }, [getContracts]);

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.SELECT) {
      return (
        <AlertDialog
          open
          title={t(
            "contract:importer.add.alertDialog.loadPreviousContract.title",
          )}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={async () => {
            if (!selectedId) {
              return setAlertDialogState(AlertDialogState.NULL);
            }
            try {
              const row = await getContractDetail({
                id: selectedId,
              }).unwrap();
              onFetchContractViaId(row);
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;
              alert.showAlert({ type: "error", message });
            } finally {
              setAlertDialogState(AlertDialogState.NULL);
              onClose();
            }
          }}
          okText={t("contract:importer.add.common.ok")}
          cancelText={t("contract:importer.add.common.exit")}
        >
          <WhiteSpace>
            {t(
              "contract:importer.add.alertDialog.loadPreviousContract.description",
            )}
          </WhiteSpace>
        </AlertDialog>
      );
    }
  };

  return (
    <>
      <Dialog
        title={t("contract:importer.add.alert.loadContracts")}
        open={open}
        onOpenChange={onClose}
        width={1000}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={onClose}
            >
              {t("contract:importer.add.common.exit")}
            </Button>
            <Button
              disabled={!selectedId}
              onClick={() => setAlertDialogState(AlertDialogState.SELECT)}
            >
              {t("contract:importer.add.common.select")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <Content>
          <InputContainer>
            <Typo typoType="b7m">
              {t("contract:importer.add.alert.sellerCode")}
            </Typo>
            <Select
              options={buyerList}
              showSearch
              defaultValue={"all"}
              suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
              disabled={isBuyerListFetching}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              onChange={async (value, option: any) => {
                setBuyerCode(value === "all" ? undefined : option.label);
                setPagination({ page: 1, pageSize: pagination.pageSize });
                await getContracts({
                  buyerNameCode: value === "all" ? undefined : option.label,
                  isExporterMainCategoryFilterOn: true,
                  pageSize: pagination.pageSize,
                });
              }}
            />
          </InputContainer>
          <Table
            ref={gridRef}
            onGridReady={() => setIsReady(true)}
            columnDefs={columnDefs}
            rowData={contractsList}
            totalPage={count}
            page={pagination.page}
            pageSize={pagination.pageSize}
            handlePaginationClick={async (page, pageSize) => {
              setPagination({ page, pageSize });
              await getContracts({
                page,
                pageSize,
                buyerNameCode: buyerCode,
                isExporterMainCategoryFilterOn: true,
              });
            }}
            onRowClicked={(params) => setSelectedId(params.data.id)}
          />
        </Content>
      </Dialog>
      {renderAlertDialog()}
    </>
  );
};

export default LoadContractDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    border: 1px solid ${colorSet.gray9} !important;
    border-radius: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WhiteSpace = styled.p`
  white-space: pre-wrap;
`;
