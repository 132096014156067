import { Button } from "@/src/components/atom/Button";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import FormItem from "@/src/components/molecule/FormItem";
import TabItem from "@/src/components/molecule/TabItem";
import SharedEmailLayout from "@/src/components/template/Layout/SharedEmailLayout";
import { TEMPLATE_OPTION_VALUE } from "@/src/constant/emailDialog";
import { sessionStorageKeySet } from "@/src/constant/sessionstorage";
import useAlert from "@/src/hooks/useAlert";
import { useLazyGetBookingDetailQuery } from "@/src/store/apis/bookings/bookingDetail";
import { BookingDetailViewDto } from "@/src/store/apis/bookings/bookingDetail/interface";
import {
  useLazyGetContractDetailQuery,
  useLazyGetExporterContractSignatureHistoriesQuery,
} from "@/src/store/apis/contracts/contractDetail";
import {
  ContractDetailViewDto,
  ContractSignature,
} from "@/src/store/apis/contracts/contractDetail/interface";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import {
  useLazyGetTaskDetailContainerListQuery,
  useLazyGetTaskDetailQuery,
} from "@/src/store/apis/tasks/taskDetail";
import {
  ContainerListViewDto,
  TaskDetailViewDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import {
  useLazyGetTaskContainerListSharedQuery,
  useLazyGetTaskSharedQuery,
} from "@/src/store/apis/tasks/taskShare";
import { ContainerShareInfoListDto } from "@/src/store/apis/tasks/taskShare/interface";
import colorSet from "@/src/styles/color";
import { aesDecrypt } from "@/src/utils/aesDecrypt";
import { isNull, isUndefined } from "@/src/utils/is";
import { transformURLSearchParamsToObject } from "@/src/utils/transform";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CertificationCodeLayout from "./components/CertificationCode";
import BookingItem from "./components/tabs/BookingItem";
import ContractItem from "./components/tabs/ContractItem";
import TaskItem from "./components/tabs/TaskItem";
import { SHARED_SEARCH_PARAMS_STRINGS } from "./constants";
import { useLazyGetContractSharedSignatureQuery } from "@/src/store/apis/contracts/contractShare";

type TabType = "CONTRACT" | "BOOKING" | "LOADING";

export type TaskDecrypt = {
  buyerId: string;
  bookingShareKey: string;
  contractShareKey: string;
  taskId: string;
  taskShareKey: string;
};

const containerEmptyArray: ContainerShareInfoListDto[] = [];

const SharedTaskPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const location = useLocation();
  const sharedLoadingEncryptKey = location.search.slice(1);

  // Preview API
  const [
    getPreviewContract,
    { isError: isPreviewContractError, isFetching: isPreviewContractFetching },
  ] = useLazyGetContractDetailQuery();
  const [
    getPreviewBooking,
    { isError: isPreviewBookingError, isFetching: isPreviewBookingFetching },
  ] = useLazyGetBookingDetailQuery();
  const [
    getPreviewLoading,
    { isPreviewLoadingError, isPreviewLoadingFetching, extraRemark },
  ] = useLazyGetTaskDetailQuery({
    selectFromResult: ({ isError, currentData, isFetching }) => {
      return {
        isPreviewLoadingError: isError,
        isPreviewLoadingFetching: isFetching,
        extraRemark: currentData?.extraMediaRemark,
      };
    },
  });
  const [getPreviewContainer, { isError: isPreviewContainerError }] =
    useLazyGetTaskDetailContainerListQuery();
  const [
    getContractPreviewSignature,
    {
      isError: isContractPreviewSignatureError,
      isFetching: isContractPreviewSignatureFetching,
    },
  ] = useLazyGetExporterContractSignatureHistoriesQuery();

  // API
  const [
    getLoadingSharedData,
    { isError, contractData, bookingData, loadingData, isSharedTaskFetching },
  ] = useLazyGetTaskSharedQuery({
    selectFromResult: ({ isError, currentData, isFetching }) => {
      return {
        isError,
        isSharedTaskFetching: isFetching,
        contractData: currentData?.row?.contractDetailShareInfo,
        bookingData: currentData?.row?.bookingDetailShareInfo,
        loadingData: currentData?.row?.taskDetailShareInfo,
      };
    },
  });
  const [
    getLoadingContainerData,
    { isContainerDataError, containerList: containerData },
  ] = useLazyGetTaskContainerListSharedQuery({
    selectFromResult: ({ isError, currentData, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isContainerDataError: isUnstable,
        containerList: !isUnstable
          ? (currentData.rows ?? containerEmptyArray)
          : containerEmptyArray,
      };
    },
  });
  const [
    getContractSharedSignature,
    {
      isError: isContractSignatureError,
      isFetching: isContractSignatureFetching,
    },
  ] = useLazyGetContractSharedSignatureQuery();

  // Query
  // 복호화된 Query String 객체
  const taskDecryptToObject = transformURLSearchParamsToObject(
    aesDecrypt(sharedLoadingEncryptKey)
  ) as TaskDecrypt;

  const taskSessionKeysArray = window.sessionStorage.getItem(
    sessionStorageKeySet.SHARED_TASK
  )
    ? (JSON.parse(
        window.sessionStorage.getItem(
          sessionStorageKeySet.SHARED_TASK
        ) as string
      ) as string[])
    : [];
  const isIncludesTaskSessionKey = taskSessionKeysArray?.includes(
    sharedLoadingEncryptKey
  );

  // 미리보기용 Query
  const isPreview =
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.preview) === "true";
  const previewLoadingId = Number(
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.taskId)
  );
  const previewContractId = Number(
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.contractId)
  );
  const previewBookingId = Number(
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.bookingId)
  );
  const previewTabList = (searchParams
    .get(SHARED_SEARCH_PARAMS_STRINGS.template)
    ?.split(",") as TabType[]) ?? [TEMPLATE_OPTION_VALUE.loading];
  const isPreviewContractTab = previewTabList.includes(
    TEMPLATE_OPTION_VALUE.contract as TabType
  );
  const isPreviewBookingTab = previewTabList.includes(
    TEMPLATE_OPTION_VALUE.booking as TabType
  );

  // State
  const [previewContractData, setPreviewContractData] =
    useState<ContractDetailViewDto>();
  const [previewBookingData, setPreviewBookingData] =
    useState<BookingDetailViewDto>();
  const [previewLoadingData, setPreviewLoadingData] =
    useState<TaskDetailViewDto>();
  const [previewContainerData, setPreviewContainerData] =
    useState<ContainerListViewDto[]>();
  const [previewSignature, setPreviewSignature] = useState<ContractSignature[]>(
    []
  );
  const [contractSignatureData, setContractSignatureData] = useState<
    ContractSignature[]
  >([]);
  const [isCodeConfirm, setIsCodeConfirm] = useState(false);
  const [tabList, setTabList] = useState<TabType[]>(
    isPreview ? previewTabList : []
  );
  const [selectTab, setSelectTab] = useState<TabType>(tabList[0]);

  const { control, getValues, handleSubmit, setFocus } = useForm<{
    taskShareKey: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      taskShareKey: "",
    },
  });

  const isFetching =
    isSharedTaskFetching ||
    isPreviewContractFetching ||
    isPreviewBookingFetching ||
    isPreviewLoadingFetching ||
    isContractPreviewSignatureFetching ||
    isContractSignatureFetching;

  // 공유코드 입력후 공유용 Container API Fetching
  const getLoadingContainer = async () => {
    const params = {
      taskShareKey: getValues("taskShareKey"),
      taskId: Number(taskDecryptToObject.taskId),
    };

    try {
      await getLoadingContainerData(params).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  // 공유코드 입력후 공유용 API Fetching
  const handleSubmitClick = async () => {
    const params = {
      taskShareKey: getValues("taskShareKey"),
      taskId: Number(taskDecryptToObject.taskId),
      bookingShareKey: taskDecryptToObject.bookingShareKey,
      contractShareKey: taskDecryptToObject.bookingShareKey,
    };

    try {
      const res = await getLoadingSharedData(params).unwrap();
      getLoadingContainer();

      if (res && params.contractShareKey) {
        const signatureRes = await getContractSharedSignature({
          contractId: res?.row?.contractDetailShareInfo?.id as number,
          contractShareKey: params.contractShareKey,
        }).unwrap();

        setContractSignatureData(signatureRes.rows || []);
      }

      const jsonArray = isIncludesTaskSessionKey
        ? JSON.stringify(taskSessionKeysArray)
        : JSON.stringify([...taskSessionKeysArray, sharedLoadingEncryptKey]);

      window.sessionStorage.setItem(
        sessionStorageKeySet.SHARED_TASK,
        jsonArray
      );

      if (
        !isNull(res.row.contractDetailShareInfo) &&
        !isNull(res.row.bookingDetailShareInfo)
      ) {
        setTabList(["CONTRACT", "BOOKING", "LOADING"]);
        setSelectTab("CONTRACT");
      }

      if (!isNull(res.row.contractDetailShareInfo)) {
        setTabList(["CONTRACT", "LOADING"]);
        setSelectTab("CONTRACT");
      }

      if (!isNull(res.row.bookingDetailShareInfo)) {
        setTabList(["BOOKING", "LOADING"]);
        setSelectTab("BOOKING");
      }

      if (
        isNull(res.row.contractDetailShareInfo) &&
        isNull(res.row.bookingDetailShareInfo)
      ) {
        setSelectTab("LOADING");
      }

      setIsCodeConfirm(true);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  };

  // 마운트 시 query내 복호화된 shareKey가 입력되었을 때 공유용 API Fetching
  const fetchWhenMount = useCallback(async () => {
    const params = {
      taskShareKey: taskDecryptToObject.taskShareKey,
      taskId: Number(taskDecryptToObject.taskId),
      bookingShareKey: taskDecryptToObject.bookingShareKey,
      contractShareKey: taskDecryptToObject.bookingShareKey,
    };

    try {
      const res = await getLoadingSharedData(params).unwrap();

      if (
        !isNull(res.row.contractDetailShareInfo) &&
        !isNull(res.row.bookingDetailShareInfo)
      ) {
        const signatureRes = await getContractSharedSignature({
          contractId: res.row.contractDetailShareInfo?.id as number,
          contractShareKey: params.contractShareKey,
        }).unwrap();

        setContractSignatureData(signatureRes.rows || []);
        setTabList(["CONTRACT", "BOOKING", "LOADING"]);
        setSelectTab("CONTRACT");
      }

      if (!isNull(res.row.contractDetailShareInfo)) {
        const signatureRes = await getContractSharedSignature({
          contractId: res.row.contractDetailShareInfo?.id as number,
          contractShareKey: params.contractShareKey,
        }).unwrap();

        setContractSignatureData(signatureRes.rows || []);
        setTabList(["CONTRACT", "LOADING"]);
        setSelectTab("CONTRACT");
      }

      if (!isNull(res.row.bookingDetailShareInfo)) {
        setTabList(["BOOKING", "LOADING"]);
        setSelectTab("BOOKING");
      }

      if (
        isNull(res.row.contractDetailShareInfo) &&
        isNull(res.row.bookingDetailShareInfo)
      ) {
        setSelectTab("LOADING");
      }
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;
      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  }, [
    alert,
    getContractSharedSignature,
    getLoadingSharedData,
    taskDecryptToObject,
  ]);

  // 마운트 시 query내 복호화된 shareKey가 입력되었을 때 공유용 Container API Fetching
  const loadingContainerFetchWhenMount = useCallback(async () => {
    const params = {
      taskShareKey: taskDecryptToObject.taskShareKey,
      taskId: Number(taskDecryptToObject.taskId),
    };

    try {
      await getLoadingContainerData(params).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;
      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  }, [
    alert,
    getLoadingContainerData,
    taskDecryptToObject.taskId,
    taskDecryptToObject.taskShareKey,
  ]);

  // 미리보기 형식 계약 상세정보 Fetching
  const getPreviewContractData = async () => {
    try {
      const res = await getPreviewContract({
        id: previewContractId,
      }).unwrap();
      const signResponse = await getContractPreviewSignature({
        id: previewContractId,
      }).unwrap();

      setPreviewContractData(res);
      setPreviewSignature(signResponse.rows || []);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  // 미리보기 형식 부킹 상세정보 Fetching
  const getPreviewBookingData = async () => {
    try {
      const res = await getPreviewBooking({
        id: previewBookingId,
      }).unwrap();

      setPreviewBookingData(res);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  // 미리보기 형식 작업 상세정보 Fetching
  const getPreviewLoadingData = async () => {
    try {
      const res = await getPreviewLoading({
        id: previewLoadingId,
      }).unwrap();

      setPreviewLoadingData(res);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  // 미리보기 형식 작업 컨테이너 상세정보 Fetching
  const getPreviewContainerData = async () => {
    try {
      const res = await getPreviewContainer({
        id: previewLoadingId,
      }).unwrap();

      setPreviewContainerData(res.rows);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const renderTabItemTitle = (type: TabType) => {
    switch (type) {
      case "CONTRACT":
        return `common:contract`;
      case "BOOKING":
        return `common:booking`;
      case "LOADING":
        return `common:loading`;
    }
  };

  const renderTabItemContent = (tabType: TabType) => {
    const tabContentData = {
      contractData: isPreview ? previewContractData : contractData,
      isContractError: isPreview
        ? isPreviewContractError || isContractPreviewSignatureError
        : isError || isContractSignatureError,
      signatureData: isPreview ? previewSignature : contractSignatureData,

      bookingData: isPreview ? previewBookingData : bookingData,
      isBookingError: isPreview ? isPreviewBookingError : isError,

      taskData: isPreview ? previewLoadingData : loadingData,
      isTaskError: isPreview ? isPreviewLoadingError : isError,

      containerData: isPreview ? previewContainerData : containerData,
      isContainerError: isPreview
        ? isPreviewContainerError
        : isContainerDataError,
    };

    switch (tabType) {
      case "CONTRACT":
        return (
          <ContractItem
            contractData={
              tabContentData.contractData as
                | ContractDetailShareInfoDto
                | ContractDetailViewDto
            }
            signatureData={tabContentData.signatureData}
            isError={tabContentData.isContractError}
            isSignatureDataFetching={
              isPreview
                ? isContractPreviewSignatureFetching
                : isContractSignatureFetching
            }
          />
        );
      case "BOOKING":
        return (
          <BookingItem
            bookingData={tabContentData.bookingData}
            isError={tabContentData.isBookingError}
          />
        );
      case "LOADING":
        return (
          <TaskItem
            taskData={tabContentData.taskData}
            containerData={tabContentData.containerData}
            isError={tabContentData.isTaskError}
            isContainerDataError={tabContentData.isContainerError}
            extraRemark={extraRemark}
            taskShareKey={
              isIncludesTaskSessionKey
                ? taskDecryptToObject.taskShareKey
                : getValues("taskShareKey")
            }
          />
        );
    }
  };

  const renderSharedEmailContent = () => {
    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    return (
      <>
        {(tabList.includes("CONTRACT") || tabList.includes("BOOKING")) && (
          <Tabs role="tablist">
            {tabList.map((item, idx) => {
              return (
                <StyledTabItem
                  key={idx.toString()}
                  tabIndex={item === selectTab ? 0 : -1}
                  data-selected={item === selectTab}
                  tabValue={item}
                  onClick={() => setSelectTab(item)}
                  onFocusItem={(value) => {
                    setSelectTab(value as TabType);
                  }}
                >
                  <Typo
                    typoType="b4m"
                    color={item === selectTab ? "gray2" : "gray6"}
                  >
                    {t(renderTabItemTitle(item))}
                  </Typo>
                </StyledTabItem>
              );
            })}
          </Tabs>
        )}

        {renderTabItemContent(selectTab)}
      </>
    );
  };

  useLayoutEffect(() => {
    if (isPreview) {
      setIsCodeConfirm(true);
      isPreviewContractTab && getPreviewContractData();
      isPreviewBookingTab && getPreviewBookingData();
      getPreviewLoadingData();
      getPreviewContainerData();
    }
    if (isIncludesTaskSessionKey) {
      setIsCodeConfirm(true);
      fetchWhenMount();
      loadingContainerFetchWhenMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFocus("taskShareKey");
  }, [setFocus]);

  return (
    <>
      {!isCodeConfirm ? (
        <CertificationCodeLayout
          title={t("common:checkLoadingContractInformation")}
        >
          <Form onSubmit={handleSubmit(handleSubmitClick)}>
            <FormItem
              label={t("common:certificationCode")}
              type="text"
              name="taskShareKey"
              rules={{ required: true }}
              errorsMessage={{
                required: t("error:required"),
              }}
              control={control}
              direction="vertical"
              inputProps={{
                placeholder: t("placeholder:enterCode"),
              }}
            />

            <StyledButton type="submit">{t("common:confirm")}</StyledButton>
          </Form>
        </CertificationCodeLayout>
      ) : (
        <SharedEmailLayout isLoading={isFetching}>
          {renderSharedEmailContent()}
        </SharedEmailLayout>
      )}
    </>
  );
};

export default SharedTaskPage;

const Tabs = styled.nav`
  display: flex;
  gap: 16px;
  margin: 24px 0;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  cursor: pointer;
  background: none;
  border: none;

  &[data-selected="true"] {
    padding: 8px 16px 6px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const StyledButton = styled(Button)`
  text-align: center;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
