import AgGridRadio from "@/src/components/atom/Radio/AgGridRadio";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

export const columnLoadContract = (
  radioOnChange: (selectedId: number | null) => void,
): ColDef[] => [
  {
    headerName: "",
    field: "radioButton",
    width: 60,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <AgGridRadio
          checked={params.data.isChecked}
          onChange={() => radioOnChange(params.data.id)}
        />
      );
    },
    lockPosition: "left",
    pinned: "left",
    sortable: false,
    resizable: false,
  },
  {
    field: "buyerNameCode",
    width: 180,
    ...commonColumn,
  },
  {
    field: "createdAt",
    width: 180,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      return dayjs(params.data.createdAt).format("YYYY-MM-DD");
    },
    ...commonColumn,
  },
  {
    field: "scNo",
    width: 140,
    ...commonColumn,
  },
  {
    field: "item",
    width: 160,
    ...commonColumn,
  },
  {
    field: "qty",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    field: "price",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    field: "shippingTerms",
    width: 160,
    ...commonColumn,
  },
  {
    field: "placeOfDelivery",
    width: 160,
    cellRenderer: (params: ValueGetterParams) => {
      if (!params.data.placeOfDelivery) {
        return "-";
      }
      return params.data.placeOfDelivery;
    },
    ...commonColumn,
  },
  {
    field: "location",
    width: 150,
    cellRenderer: (params: ValueGetterParams) => {
      if (!params.data.location) {
        return "-";
      }
      return params.data.location;
    },
    ...commonColumn,
  },
];
