import ClearSvg from "@/src/assets/icons/icon-input-clear.svg";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
import Icon from "../Icon";

export interface IconInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: ReactNode;
  onClear?: () => void;
  allowClear?: boolean;
  ["data-invalid"]?: boolean;
}

const IconInput = forwardRef<HTMLInputElement, IconInputProps>(
  ({ icon, allowClear = true, ...inputProps }, ref) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [value, setValue] = useState(inputProps.value);
    const inputRef = useRef<HTMLInputElement | null>(null);

    return (
      <IconInputContainer
        className="icon-input-container"
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        data-focus={isFocus}
        data-invalid={inputProps["data-invalid"]}
      >
        <BorderlessInput
          ref={(node) => {
            if (node && ref) {
              if (typeof ref === "function") {
                ref(node);
              } else {
                ref.current = node;
              }
            }
            inputRef.current = node;
          }}
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange?.(e);
            setValue(e.target.value);
          }}
        />
        {allowClear && value && !inputProps.disabled && (
          <StyledIcon
            iconSrc={ClearSvg}
            iconSize={20}
            onPointerDown={() => {
              inputProps.onClear?.();
              setValue("");
              setTimeout(() => inputRef.current?.focus(), 0);
            }}
          />
        )}
        {icon}
      </IconInputContainer>
    );
  },
);

export default IconInput;

export const IconInputContainer = styled.div`
  background: ${colorSet.white};
  border: 1px solid ${colorSet.gray9};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0px 16px;
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    box-shadow:
      0px 0px 0px 1px #1479eb,
      0px 0px 0px 3px rgba(24, 123, 235, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25);
  }

  &[data-focus="true"] {
    border: 1px solid ${colorSet.blue1};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }

  &[data-invalid="true"] {
    border: 1px solid ${colorSet.red2};
  }
`;

const BorderlessInput = styled.input`
  width: 100%;
  border: none;
  flex: 1;
  ${typo.b7r};

  &::placeholder {
    color: ${colorSet.gray7};
  }

  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
