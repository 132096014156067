import DownloadBlackSvg from "@/src/assets/icons/icon-download-black.svg";
import DownloadBlueSvg from "@/src/assets/icons/icon-download-blue.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import PreviewBlackSvg from "@/src/assets/icons/icon-preview-black.svg";
import PreviewBlueSvg from "@/src/assets/icons/icon-preview-blue.svg";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import {
  scDownload,
  scPreview,
} from "@/src/components/template/pdfs/v2/Sc/ScContent";
import { ScData } from "@/src/components/template/pdfs/v2/Sc/types";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import renderContractStatusBadge from "@/src/pages/exporter/Contract/utils/renderContractStatusBadge";
import {
  ContractDetailViewDto,
  ContractSignature,
} from "@/src/store/apis/contracts/contractDetail/interface";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import typo from "@/src/styles/typography";
import { downloadFile } from "@/src/utils/downloadFile";
import { isNull } from "@/src/utils/is";
import dayjs from "dayjs";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface ContractItemProps {
  contractData?: ContractDetailShareInfoDto | ContractDetailViewDto;
  signatureData: ContractSignature[];
  isError?: boolean;
  isSignatureDataFetching: boolean;
}

function ContractItem({
  contractData,
  signatureData,
  isError,
  isSignatureDataFetching,
}: ContractItemProps) {
  const { t } = useTranslation();

  // SC PDF
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const [scData, setScData] = useState<ScData>({
    scNumber: "",
    componyLogoUrl: "",
    sellerCorporation: "",
    sellerContact: "",
    sellerContactPrefix: "",
    sellerFax: "",
    sellerFaxPrefix: "",
    sellerCountryName: "",
    sellerRegion: "",
    sellerPostalCode: "",
    sellerLocality: "",
    sellerStreetAddress: "",
    buyerCorporation: "",
    buyerContactPrefix: "",
    buyerContact: "",
    buyerFaxPrefix: "",
    buyerFax: "",
    buyerCountryName: "",
    buyerRegion: "",
    buyerPostalCode: "",
    buyerLocality: "",
    buyerStreetAddress: "",
    orderDate: "",
    paymentTerm: "",
    paymentTermsRemark: "",
    originLocation: "",
    shippingTerm: "",
    shippingTermRemark: "",
    lastShipmentDate: "",
    quantityPrefix: "",
    quantity: "",
    description: "",
    hsCode: "",
    unitPricePrefix: "",
    unitPrice: "",
    amount: "",
    remark: "",
    isAddBankDetail: false,
    isAddContractTerms: false,
    contractTermsTitle: "",
    contractTerms: "",
    bankName: "",
    bankTelPrefix: "",
    bankTel: "",
    bankFaxPrefix: "",
    bankFax: "",
    bankStreetAddress: "",
    bankLocality: "",
    bankRegion: "",
    bankPostalCode: "",
    bankCountryName: "",
    swiftCode: "",
    accountNumber: "",
    accountName: "",
    sellerSignatureUrl: "",
    buyerSignatureUrl: "",
  });

  useEffect(() => {
    if (!isSignatureDataFetching && contractData) {
      setScData({
        scNumber: contractData?.scNo || "",
        componyLogoUrl: contractData?.businessLogoSimpleMedia?.mediaUrl || "",
        sellerCorporation: contractData?.companyName || "",
        sellerContact: contractData?.tel || "",
        sellerContactPrefix: contractData?.telPrefix || "",
        sellerFax: contractData?.fax || "",
        sellerFaxPrefix: contractData?.faxPrefix || "",
        sellerCountryName: contractData?.countryName || "",
        sellerRegion: contractData?.region || "",
        sellerPostalCode: contractData?.postalCode || "",
        sellerLocality: contractData?.locality || "",
        sellerStreetAddress: contractData?.streetAddress || "",
        buyerCorporation: contractData?.buyerName || "",
        buyerContactPrefix: contractData?.buyerTelPrefix || "",
        buyerContact: contractData?.buyerTel || "",
        buyerFaxPrefix: contractData?.buyerFaxPrefix || "",
        buyerFax: contractData?.buyerFax || "",
        buyerCountryName: contractData?.buyerCountryName || "",
        buyerRegion: contractData?.buyerRegion || "",
        buyerPostalCode: contractData?.buyerPostalCode || "",
        buyerLocality: contractData?.buyerLocality || "",
        buyerStreetAddress: contractData?.buyerStreetAddress || "",
        orderDate: contractData?.orderDateAt
          ? dayjs(contractData?.orderDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD,
            )
          : "",
        paymentTerm: contractData?.paymentTerms || "",
        paymentTermsRemark: contractData?.paymentTermsRemark || "",
        originLocation: contractData?.origin || "",
        shippingTerm: contractData?.shippingTerms || "",
        shippingTermRemark: contractData?.shippingTermsRemark || "",
        lastShipmentDate: contractData?.lastShipmentDateAt
          ? dayjs(contractData?.lastShipmentDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD,
            )
          : "",
        quantityPrefix: contractData?.quantityUnit || "",
        quantity: contractData?.quantity
          ? Number(
              contractData?.quantity.toString().replace(/[,]/g, ""),
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
        description: contractData?.exporterItem || "",
        hsCode: contractData?.hsCode || "",
        unitPricePrefix: contractData?.unitPriceUnit || "",
        unitPrice: contractData?.unitPrice
          ? Number(
              contractData?.unitPrice.toString().replace(/[,]/g, ""),
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
        amount:
          (contractData?.quantity &&
            contractData?.unitPrice &&
            Number(
              (
                (parseFloat(
                  contractData?.quantity.toString().replace(/,/g, ""),
                ) || 0) *
                (parseFloat(
                  contractData?.unitPrice.toString().replace(/,/g, ""),
                ) || 0)
              ).toFixed(2),
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })) ||
          "",
        remark: contractData?.contractRemark || "",
        isAddBankDetail: !!contractData?.isAddBankDetail,
        isAddContractTerms: !!contractData?.isAddContractTerms,
        contractTermsTitle: contractData?.salesContractTerms?.title || "",
        contractTerms:
          contractData?.salesContractTerms?.latestSalesContractTermsHistory
            ?.body || "",
        bankName: contractData?.bankName || "",
        bankTelPrefix: contractData?.bankTelPrefix || "",
        bankTel: contractData?.bankTel || "",
        bankFaxPrefix: contractData?.bankFaxPrefix || "",
        bankFax: contractData?.bankFax || "",
        bankStreetAddress: contractData?.bankStreetAddress || "",
        bankLocality: contractData?.bankLocality || "",
        bankRegion: contractData?.bankRegion || "",
        bankPostalCode: contractData?.bankPostalCode || "",
        bankCountryName: contractData?.bankCountryName || "",
        swiftCode: contractData?.swiftCode || "",
        accountNumber: contractData?.accountNumber || "",
        accountName: contractData?.accountName || "",
        sellerSignatureUrl:
          signatureData[0]?.exporterScSignMedia?.mediaUrl || "",
        buyerSignatureUrl:
          signatureData[0]?.importerScSignMedia?.mediaUrl || "",
      });
    }
  }, [contractData, signatureData, isSignatureDataFetching]);

  const handlePreview = () => {
    if (!isSignatureDataFetching) {
      scPreview(scData);
    }
  };

  const handleDownload = () => {
    if (!isSignatureDataFetching) {
      scDownload(scData, `${t("common:scNo")}${contractData?.scNo}.pdf`);
    }
  };

  const renderBadgeContainer = () => {
    return (
      <HeaderContainer>
        {contractData?.contractStatus &&
          renderContractStatusBadge({
            t,
            type: contractData?.contractStatus as ContractStatusType,
            size: "L",
          })}

        {!contractData?.scSimpleMedia?.id && (
          <PdfButtonContainer>
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={handleDownload}
              disabled={isError || isPreviewLoading || isSignatureDataFetching}
            >
              <Icon iconSrc={DownloadBlackSvg} iconSize={20} />
              {t("shareEmail:sharedContract.buttons.download")}
            </StyledButton>

            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              ref={previewButtonRef}
              onClick={() => {
                setIsPreviewLoading(true);
                setTimeout(() => {
                  if (previewButtonRef.current) {
                    previewButtonRef.current.click();
                    setIsPreviewLoading(false);
                  }
                }, 1500);
                handlePreview();
              }}
              isLoading={isPreviewLoading || isSignatureDataFetching}
              disabled={isError || isPreviewLoading || isSignatureDataFetching}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Icon iconSrc={PreviewBlackSvg} iconSize={20} />
              {t("shareEmail:sharedContract.buttons.preview")}
            </StyledButton>
          </PdfButtonContainer>
        )}
      </HeaderContainer>
    );
  };

  const renderContractNoneTitleCard = () => {
    return (
      <SectionCardGrid>
        <StyledSectionCardColumnSix
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:buyer")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {contractData?.buyerName ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:shippingTerms")}
            </Typo>
          }
          direction="vertical"
          value={
            <ShippingTermsContainer>
              <Typo typoType="h4">{`${
                contractData?.shippingTerms ?? "-"
              }`}</Typo>
              {contractData?.shippingTermsRemark && (
                <BreakWordTypo typoType="h4">{`${contractData?.shippingTermsRemark}`}</BreakWordTypo>
              )}
            </ShippingTermsContainer>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:buyerContact")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {contractData?.buyerEmployeeName ?? "-"}
            </BreakWordTypo>
          }
        />

        <Divider />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:item")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {contractData?.exporterItem ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:qty")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              contractData?.quantity && contractData?.quantityUnit
                ? `${Number(contractData?.quantity).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${contractData?.quantityUnit}`
                : "-"
            } `}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:unitPrice")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              contractData?.unitPrice && contractData?.unitPriceUnit
                ? `${Number(contractData?.unitPrice).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${contractData?.unitPriceUnit}`
                : "-"
            }`}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:amount")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {`${
                contractData?.quantity && contractData?.unitPrice
                  ? `${parseFloat(
                      (
                        Number(contractData?.quantity) *
                        Number(contractData?.unitPrice)
                      ).toFixed(2),
                    ).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${contractData?.unitPriceUnit}`
                  : "-"
              }`}
            </BreakWordTypo>
          }
        />
      </SectionCardGrid>
    );
  };

  const renderContractInformationCard = () => {
    return (
      <SectionCard
        cardTitle={t("contract:exporter.detail.contractInformationCard.title")}
      >
        <ContractInformationFlexColumn>
          {/* Card */}
          <ContractDateContainer>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:paymentTerms")}
              </Typo>
              <PaymentTermsContainer>
                <BreakWordTypo color="blue2" typoType="h4">
                  {contractData?.paymentTerms
                    ? `${contractData?.paymentTerms} ${
                        contractData?.paymentTermsRemark
                          ? contractData?.paymentTermsRemark
                          : ""
                      }`
                    : "-"}
                </BreakWordTypo>
              </PaymentTermsContainer>
            </ContractDateCard>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:orderDate")}
              </Typo>
              <BreakWordTypo color="blue2" typoType="h4">
                {contractData?.orderDateAt
                  ? dayjs.utc(contractData?.orderDateAt).format("YYYY-MM-DD")
                  : "-"}
              </BreakWordTypo>
            </ContractDateCard>

            {contractData?.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  {t("common:expireDate")}
                </Typo>
                <Typo color="blue2" typoType="h4">
                  {contractData?.expireDateAt
                    ? dayjs.utc(contractData?.expireDateAt).format("YYYY-MM-DD")
                    : "-"}
                </Typo>
              </ContractDateCard>
            )}

            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:lastShipmentDate")}
              </Typo>
              <Typo color="blue2" typoType="h4">
                {contractData?.lastShipmentDateAt
                  ? dayjs
                      .utc(contractData?.lastShipmentDateAt)
                      .format("YYYY-MM-DD")
                  : "-"}
              </Typo>
            </ContractDateCard>
          </ContractDateContainer>

          <ContractInformationGridContainer>
            <ContractInformationGridItem>
              {/* Location */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:location")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:placeOfDelivery")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.placeOfDelivery ??
                          contractData?.anyPortIn ??
                          "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={t("common:originLocation")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.origin ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  {contractData?.allocation && (
                    <SectionCardRow
                      label={t("common:allocation")}
                      value={
                        <BreakWordTypo typoType="b7r" color="gray5" as="p">
                          {contractData?.allocation ?? "-"}
                        </BreakWordTypo>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>

              {/* Attachment File */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:attachmentFile")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:poNo")}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {contractData?.poNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.poSimpleMedia)}
                          >
                            {isNull(contractData?.poSimpleMedia) ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    contractData?.poSimpleMedia?.mediaUrl ?? "",
                                    contractData?.poSimpleMedia
                                      ?.originalFileName ?? "",
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.poSimpleMedia)}
                          >
                            {isNull(contractData?.poSimpleMedia) ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={contractData?.poSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  <SectionCardRow
                    label={t("common:scNo")}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {contractData?.scNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.scSimpleMedia)}
                          >
                            {!contractData?.scSimpleMedia ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    contractData?.scSimpleMedia?.mediaUrl ?? "",
                                    contractData?.scSimpleMedia
                                      ?.originalFileName ?? "",
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.scSimpleMedia)}
                          >
                            {!contractData?.scSimpleMedia ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={contractData?.scSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  {contractData?.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                    <SectionCardRow
                      label={t("common:lcNo")}
                      value={
                        <FileInfoContainer>
                          <BreakWordTypo typoType="b7r" color="gray5">
                            {contractData?.lcNo ?? "-"}
                          </BreakWordTypo>
                          <div>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                contractData?.paymentTermsSimpleMedia,
                              )}
                            >
                              {contractData?.paymentTermsSimpleMedia ===
                              null ? (
                                <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                              ) : (
                                <Icon
                                  iconSrc={DownloadBlueSvg}
                                  iconSize={16}
                                  onClick={() =>
                                    downloadFile(
                                      contractData?.paymentTermsSimpleMedia
                                        ?.mediaUrl ?? "",
                                      contractData?.paymentTermsSimpleMedia
                                        ?.originalFileName ?? "",
                                    )
                                  }
                                />
                              )}
                            </IconButton>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                contractData?.paymentTermsSimpleMedia,
                              )}
                            >
                              {contractData?.paymentTermsSimpleMedia ===
                              null ? (
                                <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                              ) : (
                                <a
                                  href={
                                    contractData?.paymentTermsSimpleMedia
                                      ?.mediaUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon
                                    iconSrc={PreviewBlueSvg}
                                    iconSize={16}
                                  />
                                </a>
                              )}
                            </IconButton>
                          </div>
                        </FileInfoContainer>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>

            <GrayLine />

            <ContractInformationGridItem>
              {/* ETC */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:etc")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:hsCode")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.hsCode ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={t("common:freetime")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.freetime ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                </section>
              </ContractInformationRow>

              {/* Optional */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:optional")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:contractTerm")}
                    value={
                      contractData?.isAddContractTerms
                        ? t("common:included")
                        : t("common:notIncluded")
                    }
                  />
                  <SectionCardRow
                    label={t("common:bankDetails")}
                    value={
                      contractData?.isAddBankDetail
                        ? t("common:included")
                        : t("common:notIncluded")
                    }
                  />
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>
          </ContractInformationGridContainer>
        </ContractInformationFlexColumn>
      </SectionCard>
    );
  };

  const renderRemarkCard = () => {
    return (
      <SectionCard cardTitle={t("common:remark")}>
        <RemarkContainer>
          <SectionCardRow
            label={
              <Typo color="gray7" typoType="b9m">
                {t("common:contractRemark")}
              </Typo>
            }
            direction="vertical"
            value={
              <ReadOnlyText>{contractData?.contractRemark ?? "-"}</ReadOnlyText>
            }
          />
        </RemarkContainer>
      </SectionCard>
    );
  };

  const renderContractContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          {t("common:salesContractNo")} {contractData?.scNo ?? "-"}
        </PageTitle>

        <FlexColumn>
          {/* header */}
          {renderBadgeContainer()}
          {/* ContractNoneTitleCard */}
          {renderContractNoneTitleCard()}
          {/* Contract Information */}
          {renderContractInformationCard()}
          {/* Remark */}
          {renderRemarkCard()}
        </FlexColumn>
      </>
    );
  };

  return renderContractContent();
}

export default ContractItem;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PdfButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardColumnSix = styled(SectionCardRow)`
  grid-column: 6 span;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;
`;

const GrayLine = styled.div`
  border-right: 1px solid ${colorSet.gray9};
`;

const ShippingTermsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const ContractInformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ContractDateCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ContractDateContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${colorSet.gray11};
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;

const ContractInformationFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const ContractInformationGridContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const ContractInformationGridItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RemarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 182px;
  ${StyledScroll}
`;

const Divider = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
