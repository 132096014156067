import { WarehousingsType } from "@/src/store/apis/sales/interface";

export const calculateTotalNetWeight = (
  warehousings: WarehousingsType[],
): number => {
  return warehousings.reduce((totalWeight, warehousing) => {
    const warehousingTotalWeight = warehousing.warehousingContainers.reduce(
      (sum, container) => {
        const netWeight = parseFloat(container.container.netWeight);
        return sum + (isNaN(netWeight) ? 0 : netWeight);
      },
      0,
    );

    return totalWeight + warehousingTotalWeight;
  }, 0);
};
