import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import { ContractSignature } from "../contractDetail/interface";
import {
  ContractDetailShareInfoDto,
  GetContractSharedRequest,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const contractShareApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    //  이메일로 전송받은 contractShareKey와 contractId로 계약 단일 조회 API
    getContractShared: build.query<
      Row<ContractDetailShareInfoDto>,
      GetContractSharedRequest
    >({
      query: (params) => ({
        url: "/contracts/this",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 contractShareKey와 contractId로 계약의 서명 리스트 조회 API
    getContractSharedSignature: build.query<
      Rows<ContractSignature>,
      GetContractSharedRequest
    >({
      query: (params) => ({
        url: "/contracts/this/signature-histories",
        params,
      }),
      providesTags: [""],
    }),
  }),
});

export const {
  useLazyGetContractSharedQuery,
  useLazyGetContractSharedSignatureQuery,
} = contractShareApi;
export { contractShareApi };
