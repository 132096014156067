import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import BreakWordTypo from "../BreakWordTypo";

interface bookmarkUser {
  // 이름
  name: string;
  // 이메일
  email: string;
  // 수출입웹 유저의 ID(PK)
  exporterUserId: number;
}

interface BookmarkDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  bookmarkUserList: bookmarkUser[];
}

function BookmarkDialog({
  open,
  onOpenChange,
  bookmarkUserList,
}: BookmarkDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("common:bookmark")}
      open={open}
      width={496}
      onOpenChange={onOpenChange}
      height={460}
    >
      <BookmarkContent>
        <Title>
          <Typo typoType="b6m">{t("common:bookmark")} </Typo>
          <Typo typoType="h6">
            {addZeroPrefix(bookmarkUserList.length ?? 0)}
          </Typo>
        </Title>
        <BookmarkList>
          {bookmarkUserList?.map(({ name, email }, idx) => {
            return (
              <SectionCardRow
                key={idx.toString()}
                label={name}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {email}
                  </BreakWordTypo>
                }
              />
            );
          })}
        </BookmarkList>
      </BookmarkContent>
    </Dialog>
  );
}

export default BookmarkDialog;

const BookmarkContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  display: flex;
  padding-bottom: 12px;
  border-bottom: 1px solid ${colorSet.gray4};
  gap: 4px;
`;

const BookmarkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
