import Typo from "@/src/components/atom/Typo";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import { CopiedBadge } from "../../../Task";

export const columnLoadingTab = (): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },

  {
    headerName: "Loading No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "taskNo",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.taskNo ? (
        <Typo typoType="b9r">
          {params.data.isCopiedTask && <CopiedBadge size="xs">C</CopiedBadge>}
          {params.data.taskNo}
        </Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Factory",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "workplaceName",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.workplaceName ? (
        <Typo typoType="b9r">{params.data.workplaceName}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Item Code",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "No. of Container",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "numberOfTaskContainer",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfTaskContainer && params.data.containerType ? (
        <Typo typoType="b9r">
          {params.data.numberOfTaskContainer}x{params.data.containerType}
        </Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Total Gross Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalGrossWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalGrossWeight ? (
        <Typo typoType="b9r">{params.data.totalGrossWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Total Net Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalNetWeight ? (
        <Typo typoType="b9r">{params.data.totalNetWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Total Unit Q’ty",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "totalQuantity",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalQuantity ? (
        <Typo typoType="b9r">{params.data.totalQuantity}</Typo>
      ) : (
        "-"
      );
    },
  },
];
