import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import SectionCard from "@/src/components/molecule/SectionCard";
import AddMemoDialog from "@/src/components/organism/AddMemoDialog";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import {
  useAddImporterBookingMemoMutation,
  useGetImporterBookingDetailQuery,
} from "@/src/store/apis/bookings/bookingDetail";
import { StyledScroll } from "@/src/styles/scroll";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface ShipmentMemoCardProps {
  id: number;
}

const EMPTY_ARRAY: [] = [];

const ShipmentMemoCard = ({ id }: ShipmentMemoCardProps) => {
  const { t } = useTranslation();
  const [openMemoDialog, setOpenMemoDialog] = useState(false);
  const alert = useAlert();
  const { simpleBookingMemos } = useGetImporterBookingDetailQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;

        return {
          simpleBookingMemos: isStable
            ? currentData.simpleBookingMemos || EMPTY_ARRAY
            : EMPTY_ARRAY,
        };
      },
    },
  );
  const [addMemo, { isLoading }] = useAddImporterBookingMemoMutation();

  const renderDialog = () => {
    if (openMemoDialog) {
      return (
        <AddMemoDialog
          open
          onOpenChange={() => setOpenMemoDialog(false)}
          onSave={async (memo) => {
            try {
              await addMemo({ id, memo }).unwrap();
              setOpenMemoDialog(false);
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;
              alert.showAlert({ type: "error", message: message });
            } finally {
            }
          }}
          isLoading={isLoading}
        />
      );
    }
  };

  return (
    <>
      <SectionCard
        cardTitle={t("shipment:importer.detail.label.memo")}
        rightAccessory={
          <Button
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setOpenMemoDialog(true)}
          >
            <Flex alignItems="center">
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo>{t("shipment:importer.detail.button.addButton")}</Typo>
            </Flex>
          </Button>
        }
      >
        <MemoContent>
          {simpleBookingMemos.length >= 1 ? (
            simpleBookingMemos.map((item, idx) => {
              return (
                <MemoItem key={idx.toString()}>
                  <MemoLabelContainer>
                    <Typo color="gray3" typoType="h9">
                      {item.writerName}
                    </Typo>
                    <Typo color="gray7" typoType="b9r">
                      {`(${dayjs(item.wroteAt).format(
                        DATE_FORMAT_STRINGS.YY_MM_DD,
                      )}, ${dayjs(item.wroteAt).format(
                        DATE_FORMAT_STRINGS.HH_mm,
                      )})`}
                    </Typo>
                  </MemoLabelContainer>

                  <ReadOnlyText typoType="b7r" color="gray6">
                    {item.memo}
                  </ReadOnlyText>
                </MemoItem>
              );
            })
          ) : (
            <MemoNoDataContainer>{renderNoRowsComponent()}</MemoNoDataContainer>
          )}
        </MemoContent>
      </SectionCard>
      {renderDialog()}
    </>
  );
};

export default ShipmentMemoCard;

const MemoLabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const MemoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 440px;

  ${StyledScroll}
`;
