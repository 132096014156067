import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import Filter from "@/src/components/molecule/Filter";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useLazyGetFactoriesQuery } from "@/src/store/apis/client/factory";
import { GetWorkplacesRequestParams } from "@/src/store/apis/client/factory/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { factoryListColumn } from "./columns/column";

interface FilterItem {
  workplaceName?: string;
  countryCodeItemName?: string;
  tel?: string;
  isActivated?: string;
  mainCategories?: string[];
}

const defaultValue = {
  workplaceName: undefined,
  countryCodeItemName: "all",
  tel: undefined,
  isActivated: "all",
  mainCategories: ["all"],
};

const FAST_SEARCH_QUERY_KEY = [
  "countryCodeItemName",
  "isActivated",
  "mainCategories",
];

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ExporterFactoryManagementPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const [columnDefs] = useState<ColDef[]>(factoryListColumn);
  const [isReady, setIsReady] = useState<boolean>(false);

  // API
  const [getFactories, factoryList] = useLazyGetFactoriesQuery();
  const { isFetching, currentData: countryCodeList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "COUNTRY",
    });
  const {
    isFetching: isFetchingMainCategoryList,
    currentData: mainCategoryCurrentData,
  } = useGetCommonCodeViaCodeNameQuery({
    codeName: "MAIN_CATEGORY",
  });
  const { mainCategoryList } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined =
        isError || isFetching || currentData === undefined;

      const isManager =
        currentData?.row.exporterUserType === "MANAGER" ||
        currentData?.row.exporterUserType === "MIDDLE_MANAGER";

      return {
        mainCategoryList: !isErrorAndUndefined
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : mainCategoryEmptyArray,
      };
    },
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetWorkplacesRequestParams, FilterItem>({
    isReady,
    gridRef: gridRef.current,
    fetch: getFactories,
    defaultFilterData: defaultValue,
    onBeforeSearch: (data) => {
      let mainCategories;
      if (data.mainCategories && data.mainCategories.length > 0) {
        if (data.mainCategories.includes("all")) {
          if (data.mainCategories.length === 1) {
            mainCategories = undefined;
          } else {
            mainCategories = data.mainCategories.filter((val) => val !== "all");
          }
        } else {
          mainCategories = data.mainCategories;
        }
      } else {
        mainCategories = undefined;
      }

      return {
        workplaceName: data.workplaceName || undefined,
        countryCodeItemName:
          countryCodeList?.find(
            ({ codeItemNameKo }) => codeItemNameKo === data.countryCodeItemName,
          )?.codeItemName || undefined,
        tel: data.tel || undefined,
        isActivated:
          data.isActivated === "true"
            ? true
            : data.isActivated === "false"
              ? false
              : undefined,
        mainCategories,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        ...urlObject,
        countryCodeItemName:
          countryCodeList?.find(
            ({ codeItemName }) =>
              codeItemName === urlObject.countryCodeItemName,
          )?.codeItemNameKo || "all",
        isActivated: urlObject.isActivated || "all",
        mainCategories: urlObject.mainCategories?.split(",") ?? ["all"],
      };
    },
    transformUrlToParams: (urlSet) => {
      const { mainCategories, ...rest } = urlSet;

      return {
        ...rest,
        mainCategories: mainCategories?.split(",") ?? undefined,
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "workplaceName",
        langKey: "factoryManagement:common.factoryName",
      },
      {
        columnKey: "tel",
        langKey: "factoryManagement:common.contact",
      },
      {
        columnKey: "countryCodeItemName",
        langKey: "factoryManagement:common.country",
      },
      { columnKey: "isActivated", langKey: "factoryManagement:common.use" },
    ],
  });

  const goToDetail = (buyerId: number) => {
    navigate(`${EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_DETAIL}/${buyerId}`);
  };

  const allOptionItem = {
    label: t("factoryManagement:placeholder.all"),
    value: "all",
  };

  const buyerListToRowData = factoryList.isError
    ? []
    : factoryList?.currentData?.rows.reduce<
        {
          workplaceName: string;
          tel: string;
          countryCodeItemName: string;
          isActivated: boolean;
          workPlaceId: number;
          mainCategory: string;
        }[]
      >((acc, val, idx) => {
        const newValue = {
          no: idx + 1,
          workplaceName: val.workplaceName,
          tel: val.telPrefix && val.tel ? `${val.telPrefix} ${val.tel}` : "-",
          countryCodeItemName: val.country,
          isActivated: val.isActivated,
          workPlaceId: val.workplaceListQueryResultDto?.id ?? "",
          mainCategory: val.mainCategory,
        };
        return [...acc, newValue];
      }, []);

  const countryCodeListToSelectOption = countryCodeList?.reduce<
    { label: string; value: string }[]
  >(
    (acc, val) => {
      return [
        ...acc,
        {
          label: `${val.codeItemName}_${val.codeItemNameEn}`,
          value: val.codeItemNameKo,
        },
      ];
    },
    [allOptionItem],
  );

  const mainCategoryListToSelectOption = useMemo(() => {
    return mainCategoryCurrentData
      ?.filter((item) =>
        (mainCategoryList as string[]).includes(item.codeItemName),
      )
      .map((value) => ({
        label:
          i18n.language === "ko" ? value.codeItemNameKo : value.codeItemNameEn,
        value: value.codeItemName,
      }));
  }, [i18n.language, mainCategoryCurrentData, mainCategoryList]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:management"), t("sideNav:factoryManagement")]}
      pageTitle={t("sideNav:factoryManagement")}
    >
      <Filter
        filterKeyList={[
          {
            key: "workplaceName",
            label: t("factoryManagement:common.factoryName"),
            type: "input",
            props: {
              placeholder: t("factoryManagement:placeholder.factoryName"),
            },
          },
          {
            key: "countryCodeItemName",
            label: t("factoryManagement:common.country"),
            type: "searchSelect",
            props: {
              placeholder: t("factoryManagement:placeholder.all"),
              suffixIcon: isFetching ? <Loader /> : undefined,
              disabled: isFetching,
            },
          },
          {
            key: "tel",
            label: t("factoryManagement:common.contact"),
            type: "input",
            props: {
              placeholder: t("factoryManagement:placeholder.contact"),
            },
          },
          {
            key: "isActivated",
            label: t("factoryManagement:common.use"),
            type: "searchSelect",
            props: {
              placeholder: t("factoryManagement:placeholder.all"),
            },
          },
          {
            key: "mainCategories",
            label: t("factoryManagement:common.mainCategory"),
            type: "comboBoxSelect",
            props: {
              placeholder: t("factoryManagement:placeholder.all"),
              disabled: isFetchingMainCategoryList,
              suffixIcon: isFetching ? <Loader /> : undefined,
              searchInputPlaceholder: t(
                "factoryManagement:common.searchMainCategories",
              ),
            },
          },
        ]}
        filterData={{
          mainCategories: filterData.mainCategories,
          workplaceName: filterData.workplaceName,
          countryCodeItemName: filterData.countryCodeItemName,
          isActivated: filterData.isActivated,
          tel: filterData.tel,
        }}
        optionList={[
          {
            key: "countryCodeItemName",
            option: countryCodeListToSelectOption ?? [],
          },
          {
            key: "isActivated",
            option: [
              allOptionItem,
              { value: "true", label: "Y" },
              { value: "false", label: "N" },
            ],
          },
          {
            key: "mainCategories",
            option: [allOptionItem, ...(mainCategoryListToSelectOption ?? [])],
          },
        ]}
        onSubmit={onSearch}
        onReset={onResetFilter}
        onChange={(key, value) => {
          onFilterDataChange(key, value);

          if (FAST_SEARCH_QUERY_KEY.includes(key)) {
            onForceSearch(key, value);
          }
        }}
      />

      <MiddleSection>
        <Typo typoType="b5m" color="gray4">
          {t("factoryManagement:factoryList.factoryList")}
        </Typo>

        <StyledButton
          onClick={() => {
            navigate(EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_ADD);
          }}
        >
          <Icon iconSrc={AddSvg} iconSize={16} />
          <Typo typoType="btn3m" color="white">
            {t("factoryManagement:factoryList.factoryRegistartion")}
          </Typo>
        </StyledButton>
      </MiddleSection>

      <SectionCardWithoutHeader>
        <Table
          ref={gridRef}
          rowData={buyerListToRowData}
          columnDefs={columnDefs}
          onRowClicked={({ data }) => goToDetail(data.workPlaceId)}
          isPaginationDataMaping
          totalPage={factoryList.data?.count}
          page={pagination.page}
          pageSize={pagination.pageSize}
          onSortChange={(sortSource, isClickedHeader) =>
            !!isClickedHeader && onSortChange(sortSource as any)
          }
          handlePaginationClick={(page, pageSize) =>
            onPaginationChange({ page, pageSize })
          }
          onGridReady={() => setIsReady(true)}
        />
      </SectionCardWithoutHeader>
    </ExporterMainLayout>
  );
};

export default ExporterFactoryManagementPage;

const MiddleSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
