export const isUndefined = (value: any): value is undefined =>
  typeof value === "undefined";

export const isBoolean = (value: any): value is boolean =>
  typeof value === "boolean";

export const isNull = (value: any) => value === null;

export const isNullish = (value: any | null | undefined) =>
  value === null || value === undefined;

export const isBase64Data = (data: string) => {
  return data.startsWith("data:") && data.includes(";base64,");
};

export const isValidUrl = (url: string): boolean => {
  try {
    // 문자열 정제
    const sanitized = url.trim();
    if (!sanitized) return false;
    // URL 객체로 파싱 시도 전에 프로토콜 확인 및 추가
    let urlToCheck = sanitized;
    if (
      !urlToCheck.startsWith("http://") &&
      !urlToCheck.startsWith("https://")
    ) {
      urlToCheck = `https://${urlToCheck}`;
    }
    // URL 객체로 파싱
    const urlObject = new URL(urlToCheck);
    // 허용된 프로토콜 검사
    if (!["http:", "https:"].includes(urlObject.protocol)) {
      return false;
    }
    // 기본적인 도메인 유효성 검사
    return !(!urlObject.hostname || urlObject.hostname.length < 1);
  } catch {
    return false;
  }
};

export const isFileAccepted = (
  file: File,
  accept: string | undefined,
): boolean => {
  // .exe 파일 체크
  if (file.name.toLowerCase().endsWith(".exe")) {
    return false;
  }
  if (!accept) return true;

  const acceptedTypes = accept.split(",").map((type) => type.trim());

  return acceptedTypes.some((type) => {
    if (type.startsWith(".")) {
      return file.name.toLowerCase().endsWith(type.toLowerCase());
    } else {
      return file.type === type;
    }
  });
};
