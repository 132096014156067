import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../store";
import {
  offContentLoading as offLoading,
  onContentLoading as onLoading,
} from "../store/slice/webUtil";

const useContentLoading = () => {
  const dispatch = useAppDispatch();

  const handleContentLoadingOn = useCallback(() => {
    dispatch(onLoading());
  }, [dispatch]);
  const handleContentLoadingOff = useCallback(() => {
    dispatch(offLoading());
  }, [dispatch]);

  useEffect(() => {
    return () => handleContentLoadingOff();
  }, [handleContentLoadingOff]);

  return {
    handleContentLoadingOn,
    handleContentLoadingOff,
  };
};

export default useContentLoading;
