import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { forwardRef, HTMLAttributes, Ref } from "react";
import styled from "styled-components";

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  required?: boolean;
}

const Label = forwardRef(
  ({ required, children, ...rest }: LabelProps, ref: Ref<HTMLLabelElement>) => {
    return (
      <StyledLabel ref={ref} data-required={required} {...rest}>
        {children}
      </StyledLabel>
    );
  },
);

export default Label;

const StyledLabel = styled.label`
  ${typo.b7m};
  flex-shrink: 0;
  min-width: 164px;
  color: ${colorSet.gray2};

  &[data-required="true"] {
    &::after {
      content: " *";
      color: ${colorSet.red2};
    }
  }
`;
