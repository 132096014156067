import BackgroundImagePng from "@/src/assets/icons/hrm_bg.png";
import BottomSubtractSvg from "@/src/assets/icons/icon-bottom-subtract.svg";
import ChevronDownSvg from "@/src/assets/icons/icon-chevron-down-white.svg";
import TopSubtractSvg from "@/src/assets/icons/icon-top-subtract.svg";
import LogoDevSvg from "@/src/assets/logos/logo-export-white-dev.svg";
import LogoSvg from "@/src/assets/logos/logo-export-white.svg";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { setItemType } from "@/src/store/slice/companyItem";
import { setExporterTwoDepsNav } from "@/src/store/slice/sideNav";
import {
  exporterSideNavSlideDown,
  exporterSideNavSlideUp,
} from "@/src/styles/animation";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { isDevelopment } from "@/src/utils/environment";
import {
  Accordion as AccordionRoot,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import ExporterMainContentLayout from "./ExporterMainContentLayout";
import {
  EXPORTER_CUSTOMER_SERVICE_MENU,
  EXPORTER_OPERATION_NAV_MENU,
  EXPORTER_TERMS_POLICY_NAV_MENU,
} from "./menu";

interface MainLayoutProps {
  children?: ReactNode;
  breadcrumb?: string[];
  pageTitle?: ReactNode;
  customPageTitle?: ReactNode;
  sideAccessory?: ReactNode;
}

const ExporterMainLayout = ({
  children,
  breadcrumb,
  pageTitle,
  customPageTitle,
  sideAccessory,
}: MainLayoutProps) => {
  return (
    <Container>
      <Nav />
      <ExporterMainContentLayout
        breadcrumb={breadcrumb}
        pageTitle={pageTitle}
        customPageTitle={customPageTitle}
        sideAccessory={sideAccessory}
      >
        {children}
      </ExporterMainContentLayout>
    </Container>
  );
};

export default ExporterMainLayout;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 1250px;
  background:
    linear-gradient(180deg, #172a43 0%, #203f68d0 71.88%),
    url(${BackgroundImagePng}) no-repeat center/cover;
  background-position-y: 40%;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Nav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { exporterSelectTwoDepsNav } = useAppSelector((state) => state.sideNav);
  const user = useAppSelector((state) => state.auth.user);

  const handleTwoDepsClick = (idx: number) => {
    const target = exporterSelectTwoDepsNav[idx];

    if (target) {
      const copy = exporterSelectTwoDepsNav.slice();
      copy[idx] = { ...target, isOpen: !target.isOpen };

      dispatch(setExporterTwoDepsNav(copy));
    }
  };

  const handleNavItemClick = (path: string, isBlank?: boolean) => {
    if (location.pathname === path) {
      return;
    }

    if (isBlank) {
      return window.open(path, "_blank");
    }

    return navigate(path);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingNavItem = exporterSelectTwoDepsNav.find((navItem) =>
      navItem.children.some((child) => child.destination === currentPath)
    );

    if (matchingNavItem && !matchingNavItem.isOpen) {
      const updatedNav = exporterSelectTwoDepsNav.map((navItem) =>
        navItem.labelKey === matchingNavItem.labelKey
          ? { ...navItem, isOpen: true }
          : navItem
      );
      dispatch(setExporterTwoDepsNav(updatedNav));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <StyledNav>
      <div>
        <Logo
          $logoSrc={isDevelopment ? LogoDevSvg : LogoSvg}
          $isDevelopment={isDevelopment}
          className="logo"
          onClick={() => navigate(EXPORTER_PRIVATE_PATH.HOME)}
        />

        <NavigationSection>
          {/* Service operation */}
          <NavGroupTitle>{t("sideNav:serviceOperation")}</NavGroupTitle>
          <NavGroupContainer>
            {EXPORTER_OPERATION_NAV_MENU.map(
              ({ iconSrc, labelKey, destination, selectedIconSrc }) => {
                const currentPath = window.location.pathname.split("/")[2];
                const isSelected =
                  `${EXPORTER_PRIVATE_PATH.ROOT}/${currentPath}` ===
                  destination;

                return (
                  <NavItem
                    key={labelKey}
                    data-status={isSelected ? "selected" : "unselected"}
                    onClick={() => handleNavItemClick(destination)}
                  >
                    <Icon
                      iconSrc={isSelected ? (selectedIconSrc ?? "") : iconSrc}
                      iconSize={20}
                    />
                    <Typo
                      color={isSelected ? "indigo" : "white"}
                      typoType="b8m"
                    >
                      {t(labelKey)}
                    </Typo>
                  </NavItem>
                );
              }
            )}
          </NavGroupContainer>
          {/* Management */}
          {exporterSelectTwoDepsNav?.map((item, idx) => {
            const title =
              idx === 0
                ? t("common:corporateOperations")
                : t("common:exportManagement");
            return (
              <div key={idx.toString()}>
                <NavGroupTitle>{title}</NavGroupTitle>
                <AccordionRoot
                  type="single"
                  value={item.isOpen ? t(item.labelKey) : ""}
                >
                  <AccordionItem
                    key={idx.toString()}
                    value={t(item.labelKey)}
                    onClick={() => {
                      handleTwoDepsClick(idx);
                    }}
                  >
                    <StyledAccordionTrigger>
                      <div>
                        <Icon iconSrc={item.iconSrc} iconSize={20} />
                        <Typo typoType="b8m" color="white">
                          {t(item.labelKey)}
                        </Typo>
                      </div>
                      <StyledChevronIcon
                        iconSrc={ChevronDownSvg}
                        iconSize={20}
                      />
                    </StyledAccordionTrigger>

                    <StyledAccordionContent>
                      {item.children.map(({ labelKey, destination }) => {
                        const isManager =
                          user?.exporterUserType === "MANAGER" ||
                          user?.exporterUserType === "MIDDLE_MANAGER";
                        const currentPath =
                          window.location.pathname.split("/")[2];
                        const isSelected =
                          `${EXPORTER_PRIVATE_PATH.ROOT}/${currentPath}` ===
                          destination;

                        if (
                          isManager &&
                          EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT ===
                            destination
                        ) {
                          return null;
                        }

                        return (
                          <AccordionNavItem
                            key={t(labelKey)}
                            data-status={isSelected ? "selected" : "unselected"}
                            onClick={(e) => {
                              if (
                                destination ===
                                EXPORTER_PRIVATE_PATH.ITEM_MANAGEMENT
                              ) {
                                dispatch(setItemType("item"));
                              }

                              e.preventDefault();
                              e.stopPropagation();
                              handleNavItemClick(destination);
                            }}
                          >
                            <Typo
                              color={isSelected ? "indigo" : "white"}
                              typoType="b9m"
                            >
                              {t(labelKey)}
                            </Typo>
                          </AccordionNavItem>
                        );
                      })}
                    </StyledAccordionContent>
                  </AccordionItem>
                </AccordionRoot>
              </div>
            );
          })}
          {/* Customer ServiceCenter Menu */}
          <NavGroupTitle>{t("sideNav:customerServiceCenter")}</NavGroupTitle>
          <NavGroupContainer>
            {EXPORTER_CUSTOMER_SERVICE_MENU.map(
              ({ iconSrc, labelKey, destination, selectedIconSrc }) => {
                const currentPath = window.location.pathname.split("/")[2];
                const isSelected =
                  `${EXPORTER_PRIVATE_PATH.ROOT}/${currentPath}` ===
                  destination;

                return (
                  <NavItem
                    key={labelKey}
                    data-status={isSelected ? "selected" : "unselected"}
                    onClick={() => handleNavItemClick(destination)}
                  >
                    <Icon
                      iconSrc={isSelected ? (selectedIconSrc ?? "") : iconSrc}
                      iconSize={20}
                    />
                    <Typo
                      color={isSelected ? "indigo" : "white"}
                      typoType="b8m"
                    >
                      {t(labelKey)}
                    </Typo>
                  </NavItem>
                );
              }
            )}
          </NavGroupContainer>
        </NavigationSection>
      </div>

      <NavBottomDiv>
        <NavGroupContainer>
          {EXPORTER_TERMS_POLICY_NAV_MENU.map(({ labelKey, destination }) => {
            return (
              <NavItem
                key={labelKey}
                onClick={() => handleNavItemClick(destination)}
              >
                <Typo color="white" typoType="b9m">
                  {t(labelKey)}
                </Typo>
              </NavItem>
            );
          })}
        </NavGroupContainer>
      </NavBottomDiv>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  width: 256px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Logo = styled.span<{ $logoSrc: string; $isDevelopment: boolean }>`
  display: inline-block;
  background: ${({ $logoSrc }) => `url(${$logoSrc}) no-repeat`};
  background-size: contain;
  width: ${({ $isDevelopment }) => ($isDevelopment ? "197px" : "160px")};
  height: 24px;
  margin: 32px 32px 20px 32px;
  flex-shrink: 0;
  cursor: pointer;
`;

const NavigationSection = styled.section`
  padding: 0 0 0 16px;
`;

const NavGroupTitle = styled.p`
  ${typo.b10m};
  color: ${colorSet.white};
  padding: 20px 16px 12px;
`;

const NavGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0 12px 16px;
  cursor: pointer;

  &[data-status="selected"] {
    position: relative;
    background: ${colorSet.white};
    border-radius: 80px 0px 0px 80px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    &::before {
      content: "";
      position: absolute;
      top: -23px;
      right: 0px;
      width: 23px;
      height: 24px;
      background: url(${TopSubtractSvg}) no-repeat;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -23px;
      right: 0px;
      width: 23px;
      height: 24px;
      background: url(${BottomSubtractSvg}) no-repeat;
    }
  }

  &[data-status="unselected"] {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 36px 0px 0px 36px;
    }
  }
`;

const StyledChevronIcon = styled(Icon)`
  transition: 0.3s;
`;

const StyledAccordionTrigger = styled(AccordionTrigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
  }

  &[data-state="open"] {
    border-top-left-radius: 32px;
    background: rgba(0, 0, 0, 0.25);

    ${StyledChevronIcon} {
      transform: rotateZ(-180deg);
    }
  }

  &[data-state="closed"] {
    ${StyledChevronIcon} {
      transform: rotateZ(0deg);
    }
  }
`;

const StyledAccordionContent = styled(AccordionContent)`
  border-bottom-left-radius: 32px;
  background: rgba(0, 0, 0, 0.25);
  padding-bottom: 12px;

  &[data-state="open"] {
    animation: ${exporterSideNavSlideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${exporterSideNavSlideUp} 0.3s ease-in-out;
  }
`;

const AccordionNavItem = styled(NavItem)`
  margin-left: 24px;
`;

const NavBottomDiv = styled.div`
  padding: 0 0 32px 16px;
`;
