import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle.svg";
import EyeOffSvg from "@/src/assets/icons/icon-eye-off.svg";
import EyeSvg from "@/src/assets/icons/icon-eye.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import IconInput from "@/src/components/atom/Input/IconInput";
import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import { SUBSCRIPTION_EMPTY_LIST_ERROR_CODE } from "@/src/constant/error";
import useAlert from "@/src/hooks/useAlert";
import useRedirectNavigate from "@/src/hooks/useRedirectNavigate";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { useChangePasswordMutation } from "@/src/store/apis/auth";
import {
  useLazyGetCurrentSubscriptionQuery,
  useLazyGetEnterpriseInquiriesQuery,
  useLazyGetPlansQuery,
} from "@/src/store/apis/subscription";
import {
  setTempUser,
  setUser,
  setUserTemporaryPasswordCheck,
} from "@/src/store/slice/auth";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { passwordRegex } from "@/src/utils/regex";
import { utcNow } from "@/src/utils/utcDayjs";
import dayjs from "dayjs";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type PasswordType = "text" | "password";

type State = {
  currentPassword: PasswordType;
  newPassword: PasswordType;
  reNewPassword: PasswordType;
};

type Action =
  | { type: "currentPassword" }
  | { type: "newPassword" }
  | { type: "reNewPassword" };

const initialState: State = {
  currentPassword: "password",
  newPassword: "password",
  reNewPassword: "password",
};

export const changePasswordReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "currentPassword":
      return {
        ...state,
        currentPassword:
          state.currentPassword === "password" ? "text" : "password",
      };
    case "newPassword":
      return {
        ...state,
        newPassword: state.newPassword === "password" ? "text" : "password",
      };
    case "reNewPassword":
      return {
        ...state,
        reNewPassword: state.reNewPassword === "password" ? "text" : "password",
      };
  }
};

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      rePassword: "",
    },
  });
  const [passwordVisibleState, passwordTypeDispatch] = useReducer(
    changePasswordReducer,
    initialState
  );
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const tempUser = useAppSelector((state) => state.auth.tempUser);
  const alert = useAlert();
  const redirectOrNavigate = useRedirectNavigate();

  // API
  const [changePassword] = useChangePasswordMutation();
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();
  const [getPlans] = useLazyGetPlansQuery();
  const [getEnterpriseInquiries] = useLazyGetEnterpriseInquiriesQuery();
  const ref = useRef<HTMLInputElement>(null);

  const companyType =
    user?.exporterUserType === "CORPORATE_MANAGER"
      ? user?.exporter.companyType
      : user?.exporterUserMainFieldType;
  const isBuyerType = companyType === "BUYER";

  const goTo = useCallback(
    (path: keyof typeof PUBLIC_PATH) => {
      redirectOrNavigate(PUBLIC_PATH[path], { replace: true });
    },
    [redirectOrNavigate]
  );

  const redirectUserByType = async () => {
    if (user === null) {
      return;
    }

    try {
      const subscription = await getCurrentSubscription().unwrap();
      await getPlans({ planType: "ENTERPRISE" });
      await getEnterpriseInquiries({
        page: 1,
        pageSize: 1,
      });

      dispatch(setTempUser(null));

      switch (companyType) {
        case "BUYER":
          return redirectOrNavigate(IMPORTER_PRIVATE_PATH.HOME);
        case "SELLER":
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
        case "BOTH":
          if (subscription.subscriptionStatus === "CANCELED") {
            return redirectOrNavigate(
              EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING
            );
          }
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
        default:
          return redirectOrNavigate(EXPORTER_PRIVATE_PATH.HOME);
      }
    } catch (e: any) {
      // 구독 내역이 없는 경우, 수출자, BOTH
      if (e.data.errorCode === SUBSCRIPTION_EMPTY_LIST_ERROR_CODE) {
        return redirectOrNavigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING);
      }

      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  const handleChangePassword = async () => {
    if (!user) {
      alert.showAlert({
        message: t("alert:somethingWrong"),
        type: "error",
      });
      return goTo("LOGIN");
    }

    try {
      await changePassword({
        aId: user.aId,
        password: getValues("newPassword"),
        oldPassword: getValues("currentPassword"),
      }).unwrap();

      dispatch(setUserTemporaryPasswordCheck(false));
      redirectUserByType();

      alert.showAlert({
        message: t("alert:changeYourPassword"),
      });
    } catch (e: any) {
      if (e.status === 400) {
        return alert.showAlert({
          message: t("alert:yourConfirmPasswordDoesNotMatch"),
          type: "error",
        });
      }

      alert.showAlert({
        message: Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  useEffect(() => {
    if (tempUser) {
      dispatch(setUser(tempUser));
    }

    return () => {
      dispatch(setTempUser(null));
    };
  }, [dispatch, tempUser]);

  useEffect(() => {
    if (user && user.account.isTemporaryPassword) return;

    const newestChangedDate = dayjs
      .utc(user?.account.passwordChangePendedAt)
      .isAfter(user?.account.passwordChangedAt)
      ? user?.account.passwordChangePendedAt
      : user?.account.passwordChangedAt;

    const isSafePassword = dayjs
      .utc(newestChangedDate)
      .add(90, "day")
      .isAfter(utcNow());

    if (!isSafePassword) return;

    if (user && !user.account.isTemporaryPassword) {
      dispatch(setTempUser(null));

      return redirectOrNavigate(
        isBuyerType ? IMPORTER_PRIVATE_PATH.HOME : EXPORTER_PRIVATE_PATH.HOME
      );
    }
    if (!tempUser) {
      return goTo("LOGIN");
    }
  }, [dispatch, goTo, isBuyerType, redirectOrNavigate, tempUser, user]);

  return (
    <SignUpLayout>
      <StyledMain>
        <h2>{t("changePassword:pageTitle")}</h2>

        <Card>
          <TypoSection>
            <Typo typoType="d6" color="gray1">
              {t("changePassword:cardTitle")}
            </Typo>
            <Typo>{t("changePassword:cardDescription")}</Typo>
          </TypoSection>

          <StyledForm onSubmit={handleSubmit(handleChangePassword)}>
            <FieldDiv data-invalid={!!errors.currentPassword}>
              <label htmlFor="currentPassword">
                <Typo>
                  {t("changePassword:currentPassword")} <strong>*</strong>
                </Typo>
              </label>
              <Controller
                name="currentPassword"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    name="currentPassword"
                    type={passwordVisibleState.currentPassword}
                    icon={
                      <Icon
                        iconSrc={
                          passwordVisibleState.currentPassword === "password"
                            ? EyeOffSvg
                            : EyeSvg
                        }
                        iconSize={20}
                        onClick={() =>
                          passwordTypeDispatch({ type: "currentPassword" })
                        }
                      />
                    }
                    placeholder={t("changePassword:currentPasswordPlaceholder")}
                    onClear={() => setValue("currentPassword", "")}
                  />
                )}
              />

              {errors.currentPassword?.type === "required" && (
                <FormErrorMessage>
                  <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <FieldDiv data-invalid={!!errors.newPassword}>
              <label htmlFor="newPassword">
                <Typo>
                  {t("changePassword:newPassword")} <strong>*</strong>
                </Typo>
              </label>
              <Controller
                name="newPassword"
                rules={{
                  required: true,
                  pattern: passwordRegex,
                }}
                control={control}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    name="newPassword"
                    type={passwordVisibleState.newPassword}
                    icon={
                      <StyledIcon
                        iconSrc={
                          passwordVisibleState.newPassword === "password"
                            ? EyeOffSvg
                            : EyeSvg
                        }
                        iconSize={20}
                        onClick={() =>
                          passwordTypeDispatch({ type: "newPassword" })
                        }
                      />
                    }
                    placeholder={t("changePassword:newPasswordPlaceholder")}
                    onClear={() => setValue("newPassword", "")}
                  />
                )}
              />

              {errors.newPassword?.type === "required" && (
                <FormErrorMessage>
                  <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
              {errors.newPassword?.type === "pattern" && (
                <FormErrorMessage>
                  <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:passwordPatterError")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <FieldDiv data-invalid={!!errors.rePassword}>
              <label htmlFor="id">
                <Typo>
                  {t("changePassword:rePassword")} <strong>*</strong>
                </Typo>
              </label>
              <Controller
                name="rePassword"
                rules={{
                  required: true,
                  validate: (value, formValues) =>
                    value === formValues.newPassword,
                }}
                control={control}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    name="rePassword"
                    type={passwordVisibleState.reNewPassword}
                    icon={
                      <StyledIcon
                        iconSrc={
                          passwordVisibleState.reNewPassword === "password"
                            ? EyeOffSvg
                            : EyeSvg
                        }
                        iconSize={20}
                        onClick={() =>
                          passwordTypeDispatch({ type: "reNewPassword" })
                        }
                      />
                    }
                    placeholder={t("changePassword:rePasswordPlaceholder")}
                    onClear={() => setValue("rePassword", "")}
                  />
                )}
              />

              {errors.rePassword?.type === "required" && (
                <FormErrorMessage>
                  <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:required")}
                </FormErrorMessage>
              )}
              {errors.rePassword?.type === "validate" && (
                <FormErrorMessage>
                  <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
                  {t("error:passwordRecheckFail")}
                </FormErrorMessage>
              )}
            </FieldDiv>

            <ButtonContainer>
              <StyledButton
                buttonColor="black"
                buttonGrade="tertiary"
                style={{ textAlign: "center" }}
                type="button"
                onClick={() => {
                  dispatch(setTempUser(null));
                  goTo("ROOT");
                }}
              >
                {t("changePassword:later")}
              </StyledButton>

              <StyledButton style={{ textAlign: "center" }} type="submit">
                {t("changePassword:resetButtonLabel")}
              </StyledButton>
            </ButtonContainer>
          </StyledForm>
        </Card>
      </StyledMain>
    </SignUpLayout>
  );
};

export default ChangePasswordPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    padding: 24px 0;
    ${typo.d3};
  }
`;

const Card = styled.div`
  width: 640px;
  padding: 48px;
  margin-top: 24px;
  background: ${colorSet.white};
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TypoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    color: ${colorSet.red2};
  }

  &[data-invalid="true"] {
    .icon-input-container {
      border: 1px solid ${colorSet.red2};
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const StyledIcon = styled(Icon)``;

const FormErrorMessage = styled.span`
  ${typo.b9r}
  color: ${colorSet.red2};
  padding-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;

  ${StyledIcon} {
    flex-shrink: 0;
  }
`;

const StyledInput = styled(IconInput)``;

const StyledButton = styled(Button)`
  ${typo.btn3m}
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;

  ${StyledButton} {
    flex: 1;
  }
`;
