import { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { AlertListItem, AlertRootContext, FadeOutTime } from ".";

const useAlertRoot = (
  list: AlertListItem[],
  onChangeList: Dispatch<SetStateAction<AlertListItem[]>>
) => {
  const context = useContext(AlertRootContext);

  if (context === undefined) {
    throw Error("Alert Dialog Context 안에서 사용하세요");
  }

  const removeAlert = useCallback(
    (target: AlertListItem) => {
      // 먼저 hide 상태로 변경
      onChangeList((prev) =>
        prev.map((item) => {
          return target.id === item.id ? { ...item, isHide: true } : item;
        })
      );

      // FadeOutTime 후에 실제로 제거
      setTimeout(() => {
        onChangeList((prev) => prev.filter((item) => item.id !== target.id));
      }, FadeOutTime);
    },
    [onChangeList]
  );

  const showAlert = ({
    message,
    type = "success",
    position = "topCenter",
    time = 3000,
  }: AlertListItem) => {
    const newAlert: AlertListItem = {
      id: Date.now(),
      message,
      position,
      type,
      isHide: false,
      time,
    };

    // 기존 알림이 있으면 먼저 hide 처리
    if (list.length > 0) {
      list.forEach((alert) => removeAlert(alert));

      // 기존 알림의 페이드아웃이 시작된 후 새 알림 추가
      setTimeout(() => {
        onChangeList([newAlert]);
      }, 100); // 약간의 지연을 주어 애니메이션이 겹치지 않도록 함
    } else {
      onChangeList([newAlert]);
    }

    // 자동 제거 타이머
    setTimeout(() => {
      removeAlert(newAlert);
    }, time);
  };

  const clearAll = useCallback(() => {
    list.forEach((alert) => removeAlert(alert));
  }, [list, removeAlert]);

  return {
    fnc: { showAlert, onChangeList, removeAlert, clearAll },
    state: { list },
  };
};

export default useAlertRoot;
