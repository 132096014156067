import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ConfigProvider, SelectProps as AntdSelectProps } from "antd";
import { forwardRef } from "react";
import { styled } from "styled-components";
import Select from ".";
import Tag from "../Tag";

type OptionType = {
  value: string;
  label: string;
};

export interface MultipleSelectProps extends AntdSelectProps {
  options?: OptionType[];
  value?: string[];
  onRemoveItem?: (value: string) => void;
  disableValues?: string[];
}

const MultipleSelect = forwardRef<HTMLSelectElement, MultipleSelectProps>(
  (props, ref) => {
    const { options, value, onRemoveItem, disableValues, ...restProps } = props;

    return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Pretendard Regular",
            controlItemBgHover: colorSet.gray10,
            borderRadius: 8,
            colorTextPlaceholder: colorSet.gray7,
            fontSizeIcon: 16,
          },
        }}
      >
        <StyledSelect
          ref={ref}
          style={{ width: "100%" }}
          mode="multiple"
          showAction={["focus"]}
          options={
            options?.map(({ label, value }) => {
              return {
                label,
                value,
                disabled: disableValues?.includes(value),
              };
            }) ?? []
          }
          value={value}
          onKeyDown={(e) => e.stopPropagation()}
          tagRender={(props) => {
            return (
              <Tag
                style={{ margin: "4px" }}
                label={props.label}
                onRemove={
                  disableValues?.includes(props.value)
                    ? undefined
                    : () => {
                        onRemoveItem?.(props.value);
                      }
                }
              />
            );
          }}
          filterOption={(input, option) =>
            ((option?.label as string) ?? "")
              .toLowerCase()
              .includes(input.toLowerCase()) ||
            ((option?.value as string) ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          {...restProps}
        />
      </ConfigProvider>
    );
  },
);

export default MultipleSelect;

const StyledSelect = styled(Select)`
  .ant-select .ant-select-arrow {
    background: blue;
  }

  // max count
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    & > span {
      background: ${colorSet.blue10};
      box-shadow:
        0px 0px 0px 0.5px rgba(23, 98, 195, 0.2),
        0px 1px 2px rgba(5, 29, 57, 0.1);
      border-radius: 64px;
      padding: 2px 8px;
      color: ${colorSet.blue4};
      ${typo.btn3m}
      margin-right: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
`;
