import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import zIndex from "@/src/styles/zIndex";
import {
  ConfigProvider,
  Pagination as AntdPagination,
  PaginationProps as AntdPaginationProps,
} from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import Typo from "../Typo";

interface PaginationProps extends AntdPaginationProps {
  total?: number;
  rightAccessory?: ReactNode;
}

function Pagination({ total, rightAccessory, ...props }: PaginationProps) {
  const { t } = useTranslation();

  return (
    <PaginationContainer>
      <TotalContainer>
        <Typo typoType="b9r" color="gray6">
          {t("common:total")}
        </Typo>
        <Typo typoType="b9r">{total ?? 0}</Typo>
      </TotalContainer>

      <CenterContainer>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Pretendard Regular",
            },
          }}
        >
          <StyledPagination
            showSizeChanger
            defaultPageSize={10}
            defaultCurrent={1}
            total={total}
            {...props}
          />
        </ConfigProvider>
        {rightAccessory}
      </CenterContainer>
    </PaginationContainer>
  );
}

export default Pagination;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  position: relative;
`;

const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  position: absolute;
  left: 0;
`;

const StyledPagination = styled(AntdPagination)`
  .ant-select-selection-search {
    display: none;
  }
  .ant-select-selector {
    cursor: pointer !important;
    height: 34px !important;
  }
  display: flex;
  justify-content: center;

  .ant-select-dropdown {
    z-index: ${zIndex.paginationDropDown};
  }

  /* pagination Button */
  .ant-pagination-item {
    border: none;
  }
  .ant-select-selection-item {
    ${typo.b9r}
  }
  /* pagination Active Button */
  .ant-pagination-item-active {
    border: none;
    box-shadow:
      0px 0px 0px 1px rgba(23, 98, 195, 0.6),
      0px 0px 0px 2px rgba(173, 204, 243, 0.6),
      0px 2px 4px rgba(5, 29, 57, 0.2);
    border-radius: 8px;
  }
  /* pagination Active Button Text */
  .ant-pagination-item-active a {
    color: ${colorSet.blue4};
  }

  /* Pagination Page Size */
  .ant-pagination-options {
    margin-left: 4px;

    .ant-select-selector {
      border: 1px solid ${colorSet.gray9};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
