import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import {
  GenerateWarehouseDto,
  GenerateWarehouseEmployeeDto,
  GetWarehousesRequestParams,
  ModifyWarehouseDto,
  ModifyWarehouseEmployeeDto,
  WarehouseDto,
  WarehouseEmployeeDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["factoryEmployees", "warehouses", "workplace"],
});

const warehousesApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 입고지 생성 API
    createWarehouses: build.mutation<Row<WarehouseDto>, GenerateWarehouseDto>({
      query: (body) => ({
        url: "/importers/this/warehouses",
        method: "POST",
        body,
      }),
      invalidatesTags: ["warehouses"],
    }),

    // 입고지 목록 조회 API
    getWarehouses: build.query<Rows<WarehouseDto>, GetWarehousesRequestParams>({
      query: (params) => ({
        url: "/importers/this/warehouses",
        params,
      }),
      providesTags: ["warehouses"],
    }),

    // 입고지 단일 조회 API
    getWarehouse: build.query<WarehouseDto, { id: number }>({
      query: ({ id }) => ({
        url: `/importers/this/warehouses/${id}`,
      }),
      providesTags: ["workplace"],
      transformResponse: (res: Row<WarehouseDto>) => res.row,
    }),

    // 입고지 단일 수정 API
    editWarehouse: build.mutation<void, ModifyWarehouseDto & { id: number }>({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/importers/this/warehouses/${body.id}`,
          method: "PUT",
          body: restBody,
        };
      },
      invalidatesTags: ["workplace"],
    }),

    // 입고지 직원 단일 생성 API
    createWarehouseEmployees: build.mutation<
      Row<WarehouseEmployeeDto>,
      GenerateWarehouseEmployeeDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/importers/this/warehouses/${id}/warehouse-employees`,
          method: "POST",
          body: restBody,
        };
      },
      invalidatesTags: ["factoryEmployees"],
    }),

    // 입고지 직원 목록 조회 API
    getWarehouseEmployees: build.query<
      Rows<WarehouseEmployeeDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: (params) => {
        const { id, ...restParams } = params;
        return {
          url: `/importers/this/warehouses/${params.id}/warehouse-employees`,
          params: restParams,
        };
      },
      providesTags: ["factoryEmployees"],
    }),

    // 입고지 직원 단일 수정 API
    editWarehouseEmployees: build.mutation<
      Row<WarehouseEmployeeDto>,
      ModifyWarehouseEmployeeDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/importers/this/warehouse-employees/${body.id}`,
          method: "PUT",
          body: restBody,
        };
      },
      invalidatesTags: ["factoryEmployees"],
    }),
  }),
});

export const {
  useGetWarehousesQuery,
  useLazyGetWarehousesQuery,
  useGetWarehouseQuery,
  useLazyGetWarehouseQuery,
  useCreateWarehousesMutation,
  useEditWarehouseMutation,

  useGetWarehouseEmployeesQuery,
  useLazyGetWarehouseEmployeesQuery,
  useCreateWarehouseEmployeesMutation,
  useEditWarehouseEmployeesMutation,
} = warehousesApi;
export { warehousesApi };
