import ErrorCircleSvg, {
  ReactComponent as InfoCircle,
} from "@/src/assets/icons/icon-error-circle.svg";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ConfigProvider } from "antd";
import { ReactNode } from "react";
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { css, styled } from "styled-components";
import Checkbox from "../../atom/Checkbox";
import DatePicker, { StyledTimePicker } from "../../atom/DatePicker";
import File from "../../atom/File";
import Icon from "../../atom/Icon";
import Input from "../../atom/Input";
import Radio from "../../atom/Radio";
import { StyledRadioGroup } from "../../atom/RadioGroup";
import Select from "../../atom/Select";
import MultipleSelect from "../../atom/Select/MultipleSelect";
import TextArea from "../../atom/TextArea";
import Typo from "../../atom/Typo";
import ImageUpload from "../ImageUpload";
import FormInputPropsWithInputType from "./type";

type NarrowErrorType =
  | "required"
  | "min"
  | "max"
  | "maxLength"
  | "minLength"
  | "validate"
  | "value"
  | "pattern";

export interface FormItemProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> {
  direction?: "vertical" | "horizontal";
  label?: ReactNode;
  name: UseControllerProps<TFieldValues, TName>["name"];
  control: UseControllerProps<TFieldValues>["control"];
  rules?: UseControllerProps["rules"];
  shouldUnregister?: boolean;
  defaultValue?: UseControllerProps["defaultValue"];
  disabled?: boolean;
  className?: string;
  inputContainerClassName?: string;
  errorsMessage?: Partial<Record<NarrowErrorType, ReactNode>>;
  bottomAccessory?: ReactNode;
  noLabel?: boolean;
}

const FormItem = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  direction = "horizontal",
  label,
  type,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  inputProps,
  disabled,
  className,
  inputContainerClassName,
  options,
  errorsMessage,
  bottomAccessory,
  noLabel,
}: FormItemProps<TFieldValues, TName> & FormInputPropsWithInputType) => {
  const {
    field,
    formState: { errors },
  } = useController<TFieldValues, TName>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const isRequired = !!rules?.required;
  const isInvalid = !!errors[name];
  const isStringLabel = typeof label === "string";
  const hasInputProps = inputProps !== undefined;
  const { onBlur, onChange, ...restField } = field;

  const getErrorMessage = (type: any) => {
    return type ? errorsMessage?.[type as NarrowErrorType] : "";
  };

  const renderItemViaType = () => {
    switch (type) {
      case "text": {
        return (
          <InputContainer className={inputContainerClassName}>
            <FormItemTextInput
              {...restField}
              {...inputProps}
              disabled={disabled || inputProps?.disabled}
              data-invalid={isInvalid}
              onBlur={(e) => {
                onBlur();
                if (hasInputProps) {
                  inputProps.onBlur?.(e);
                }
              }}
              onChange={(e) => {
                onChange(e);
                if (hasInputProps) {
                  inputProps?.onChange?.(e);
                }
              }}
              onClear={() => onChange("")}
            />
            <InputError message={getErrorMessage(errors[name]?.type)} />
            {bottomAccessory}
          </InputContainer>
        );
      }
      case "date": {
        return (
          <InputContainer className={inputContainerClassName}>
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    fontSize: 16,
                  },
                },
              }}
            >
              <StyledDatePicker
                {...restField}
                {...inputProps}
                disabled={disabled || inputProps?.disabled}
                data-invalid={isInvalid}
                onBlur={(e) => {
                  onBlur();
                  inputProps?.onBlur?.(e);
                }}
                onChange={(date, dateString) => {
                  onChange(date, dateString);
                  inputProps?.onChange?.(date, dateString);
                }}
              />
            </ConfigProvider>
            <InputError message={getErrorMessage(errors[name]?.type)} />
            {bottomAccessory}
          </InputContainer>
        );
      }
      case "timePicker": {
        return (
          <InputContainer className={inputContainerClassName}>
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    fontSize: 16,
                    colorPrimary: colorSet.blue4,
                    colorText: colorSet.gray2,
                  },
                },
              }}
            >
              <StyledTimePicker
                {...restField}
                {...inputProps}
                disabled={disabled || inputProps?.disabled}
                data-invalid={isInvalid}
                onBlur={(e) => {
                  onBlur();
                  inputProps?.onBlur?.(e);
                }}
                onChange={(date, dateString) => {
                  onChange(date, dateString);
                  inputProps?.onChange?.(date, dateString);
                }}
              />
            </ConfigProvider>
            <InputError message={getErrorMessage(errors[name]?.type)} />
            {bottomAccessory}
          </InputContainer>
        );
      }
      case "checkbox": {
        return (
          <InputContainer className={inputContainerClassName}>
            <CheckboxGroupContainer>
              {options.map(({ value, label }) => {
                return (
                  <CheckboxContainer>
                    <Checkbox
                      {...restField}
                      id={`${label}_${value}`}
                      disabled={inputProps?.disabled}
                      value={value}
                      checked={((field.value as string[]) ?? []).includes(
                        value,
                      )}
                      onChange={(e) => {
                        const isChecked = (
                          (field.value as string[]) ?? []
                        ).includes(value);
                        const valueArray = isChecked
                          ? (field.value as string[]).filter(
                              (item) => item !== e.target.value,
                            )
                          : [...field.value, e.target.value];
                        onChange(valueArray);
                        inputProps?.onChange?.(e);
                      }}
                      onBlur={onBlur}
                    />

                    <StyledLabel
                      htmlFor={`${label}_${value}`}
                      disabled={inputProps?.disabled}
                    >
                      {label}
                    </StyledLabel>
                  </CheckboxContainer>
                );
              })}
            </CheckboxGroupContainer>
            {bottomAccessory}
            <InputError message={getErrorMessage(errors[name]?.type)} />
          </InputContainer>
        );
      }
      case "radioGroup": {
        return (
          <InputContainer className={inputContainerClassName}>
            <StyledFormRadioGroup value={field.value}>
              {options.map(({ label, value, disabled }) => {
                const { onChange, ...restField } = field;
                return (
                  <Radio
                    {...restField}
                    typoType="b7r"
                    onChange={(e) => {
                      onChange(e.target.value);
                      inputProps?.onChange?.(e);
                    }}
                    label={label}
                    value={value}
                    disabled={disabled}
                  />
                );
              })}
            </StyledFormRadioGroup>
            {bottomAccessory}
            <InputError message={getErrorMessage(errors[name]?.type)} />
          </InputContainer>
        );
      }
      case "singleSelect": {
        const { onChange, onBlur, value, ...restField } = field;
        return (
          <InputContainer className={inputContainerClassName}>
            <StyledSelect
              {...restField}
              showSearch
              filterOption={(inputValue, option) => {
                return (
                  ((option?.label as string) ?? "")
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  ((option?.value as string) ?? "")
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                );
              }}
              {...inputProps}
              popupClassName={
                inputProps?.stopEllipsis
                  ? `${inputProps.popupClassName} stopEllipsis`
                  : inputProps?.popupClassName
              }
              value={value || undefined}
              onChange={(value, option) => {
                onChange(value);
                inputProps?.onChange?.(value, option);
              }}
              onBlur={(e) => {
                onBlur();
                inputProps?.onBlur?.(e);
              }}
              options={options}
            />
            <InputError message={getErrorMessage(errors[name]?.type)} />
            {bottomAccessory}
          </InputContainer>
        );
      }
      case "multipleSelect": {
        const { onChange, onBlur, value, ...restField } = field;
        return (
          <InputContainer className={inputContainerClassName}>
            <StyledMultipleSelect
              {...restField}
              {...inputProps}
              data-invalid={isInvalid}
              value={value}
              onChange={(value, option) => {
                onChange(value);
                inputProps?.onChange?.(value, option);
              }}
              onBlur={(e) => {
                onBlur();
                inputProps?.onBlur?.(e);
              }}
              options={options}
            />
            {bottomAccessory}
            <InputError message={getErrorMessage(errors[name]?.type)} />
          </InputContainer>
        );
      }
      case "textarea": {
        return (
          <InputContainer className={inputContainerClassName}>
            <StyledTextArea
              {...restField}
              {...inputProps}
              onChange={(e) => {
                onChange(e);
                inputProps?.onChange?.(e);
              }}
              onBlur={(e) => {
                onBlur();
                inputProps?.onBlur?.(e);
              }}
              data-invalid={isInvalid}
              containerStyle={
                isInvalid
                  ? { outline: `1px solid ${colorSet.red2}` }
                  : undefined
              }
            />
            {bottomAccessory}
            <InputError message={getErrorMessage(errors[name]?.type)} />
          </InputContainer>
        );
      }
      case "file": {
        return (
          <InputContainer className={inputContainerClassName}>
            <File
              {...restField}
              {...inputProps}
              onChange={(e) => {
                onChange(e);
                inputProps?.onChange?.(e);
              }}
              onBlur={(e) => {
                onBlur();
                inputProps?.onBlur?.(e);
              }}
            />
            {bottomAccessory}
            <InputError message={getErrorMessage(errors[name]?.type)} />
          </InputContainer>
        );
      }
      case "imageUpload": {
        return (
          <InputContainer className={inputContainerClassName}>
            <ImageUpload
              {...restField}
              {...inputProps}
              onChange={(imageFile) => {
                const value = [...field.value, ...imageFile];
                onChange(value);
                inputProps?.onChange?.(imageFile);
              }}
              onBlur={(e) => {
                onBlur();
                inputProps?.onBlur?.(e);
              }}
            />
            <InputError message={getErrorMessage(errors[name]?.type)} />
            {bottomAccessory}
          </InputContainer>
        );
      }
    }
  };

  return (
    <FormItemContainer
      className={className}
      data-invalid={isInvalid}
      data-direction={direction}
      $noLabel={!!noLabel}
    >
      {isStringLabel ? (
        <FormLabel data-required={isRequired}>{label}</FormLabel>
      ) : (
        label
      )}
      {renderItemViaType()}
    </FormItemContainer>
  );
};

export default FormItem;

const FormLabel = styled.p`
  ${typo.b7m};
  flex-shrink: 0;
  min-width: 164px;
  color: ${colorSet.gray2};

  &[data-required="true"] {
    &::after {
      content: " *";
      color: ${colorSet.red2};
    }
  }
`;

const FormItemTextInput = styled(Input)`
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  width: 100%;
  font-size: 16px;
  padding: 0 16px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledMultipleSelect = styled(MultipleSelect)``;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const FormItemContainer = styled.div<{ $noLabel: boolean }>`
  display: flex;
  gap: 8px;

  ${({ $noLabel }) =>
    $noLabel &&
    css`
      ${InputContainer} {
        width: 100%;
      }
    `};

  &[data-direction="vertical"] {
    flex-direction: column;
  }

  &[data-invalid="true"] {
    ${StyledDatePicker} {
      border: 1px solid ${colorSet.red2};
    }
    ${StyledSelect},${StyledMultipleSelect} {
      div.ant-select-selector {
        border: 1px solid ${colorSet.red2} !important;
      }
    }
  }
`;

const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    cursor: pointer;
    ${typo.b7r};
  }
`;

const StyledFormRadioGroup = styled(StyledRadioGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;

  & > label {
    gap: 0;

    > span.ant-radio {
      margin-right: 8px;
    }
  }
`;

const StyledTextArea = styled(TextArea)`
  height: 142px;
`;

export const InputError = ({
  message,
  className,
}: {
  message: ReactNode;
  className?: string;
}) => {
  if (!message) return null;
  return (
    <ErrorMessage className={className}>
      <StyledIcon iconSrc={ErrorCircleSvg} iconSize={16} />
      <Typo typoType="b9r" color="red2">
        {message}
      </Typo>
    </ErrorMessage>
  );
};

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
const ErrorMessage = styled.p`
  ${typo.b9r};
  color: ${colorSet.red2};
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const InputSuccess = ({
  message,
  className,
}: {
  message: ReactNode;
  className?: string;
}) => {
  if (!message) return null;
  return (
    <SuccessMessage className={className}>
      <InfoCircleIcon />
      <Typo typoType="b9r" color="green1">
        {message}
      </Typo>
    </SuccessMessage>
  );
};

const SuccessMessage = styled(ErrorMessage)`
  color: ${colorSet.green1};
`;

const InfoCircleIcon = styled(InfoCircle)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.green1};
  }
`;

const StyledLabel = styled.label<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? colorSet.gray7 : colorSet.gray2)};
`;
