import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { EmailShareDomainType } from "@/src/store/apis/emailShareHistories/interface";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../atom/Dialog";
import Table from "../../atom/Table";
import { columnEmailHistoryList } from "./columnEmailHistoryList";

interface EmailHistoryDialogProps<T> {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  fetch?: LazyQueryTrigger<QueryDefinition<any, any, any, any>>;
  totalCount?: number;
  isFetching?: boolean;
  emailHistoryList?: T[];
  emailShareDomain: EmailShareDomainType;
  emailShareDomainId: number;
  isError: boolean;
}

const headerSet = [
  {
    columnKey: "emailSender",
    langKey: "emailHistoryDialog:table.emailSender",
  },
  {
    columnKey: "templateType",
    langKey: "emailHistoryDialog:table.templateType",
  },
  {
    columnKey: "buyerName",
    langKey: "emailHistoryDialog:table.buyerName",
  },
  {
    columnKey: "recipients",
    langKey: "emailHistoryDialog:table.recipients",
  },
  {
    columnKey: "cc",
    langKey: "emailHistoryDialog:table.cc",
  },
  {
    columnKey: "sentDateAt",
    langKey: "emailHistoryDialog:table.sentDateAt",
  },
];

function EmailHistoryDialog<HistoryList extends {}>({
  open,
  onOpenChange,
  fetch,
  totalCount,
  isFetching,
  emailHistoryList,
  emailShareDomain,
  emailShareDomainId,
  isError,
}: EmailHistoryDialogProps<HistoryList>) {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefs] = useState<ColDef[]>(
    emailShareDomain === "FILE_BOX_EXPORTER"
      ? columnEmailHistoryList.filter(({ field }) => field !== "templateType")
      : columnEmailHistoryList,
  );
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);

  useAgGridHeaderRefresh({
    gridRef: gridRef?.current,
    isReady,
    headerSet:
      emailShareDomain === "FILE_BOX_EXPORTER"
        ? headerSet.filter(({ columnKey }) => columnKey !== "templateType")
        : headerSet,
  });

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;

      if (!fetch) {
        return;
      }

      try {
        await fetch?.({
          page,
          pageSize,
          emailShareDomain,
          emailShareDomainId,
        }).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      emailShareDomain,
      emailShareDomainId,
      fetch,
      pagination.page,
      pagination.pageSize,
    ],
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  // Mount
  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={t("emailHistoryDialog:dialog.title")}
      open={open}
      width={1200}
      onOpenChange={onOpenChange}
    >
      <Table
        ref={gridRef}
        rowData={isError ? [] : emailHistoryList}
        columnDefs={columnDefs}
        totalPage={totalCount}
        pageSize={pagination.pageSize}
        page={pagination.page}
        handlePaginationClick={handlePaginationClick}
        onGridReady={() => {
          setIsReady(true);
        }}
      />
    </Dialog>
  );
}

export default EmailHistoryDialog;
