import PlusCircleSvg from "@/src/assets/icons/icon-add-circle-fill.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import Badge from "@/src/components/atom/Badge";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import RadioGroup from "@/src/components/atom/RadioGroup";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import useAlert from "@/src/hooks/useAlert";
import { useAppSelector } from "@/src/store";
import { authApi } from "@/src/store/apis/auth";
import {
  useAddDispatcherOptionMutation,
  useLazyGetDispatcherOptionQuery,
  useUpdateDispatcherOptionMutation,
} from "@/src/store/apis/dispatcherOption";
import { AlarmType } from "@/src/store/apis/dispatcherOption/interface";
import {
  useDeletePaymentMethodsMutation,
  useGetPaymentMethodsQuery,
  useUpdatePaymentMethodsMutation,
} from "@/src/store/apis/subscription";
import { PaymentMethodDto } from "@/src/store/apis/subscription/interface";
import colorSet from "@/src/styles/color";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { isUndefined } from "@/src/utils/is";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatCreditCardMasking } from "../utils/formatCreditCardMasking";
import AddPaymentMethodsDialog from "./dialog/AddPaymentMethodsDialog";

enum DialogState {
  NULL,
  PAYMENT_METHODS_ADD,
}

enum AlertDialogState {
  NULL,
  MAIN_PAYMENT_METHODS,
  PAYMENT_METHODS_DELETE,
}

type AlarmStateType = "PUSH_ALARM_AND_EMAIL" | "PUSH_ALARM" | "EMAIL";
const emptyArray: PaymentMethodDto[] = [];

function PaymentManagement() {
  const { t } = useTranslation();
  const alert = useAlert();
  const user = useAppSelector((state) => state.auth.user);

  // API
  const session = authApi.endpoints.getSession.useQueryState(undefined);
  const [deletePayment, { isLoading: isDeletePaymentLoading }] =
    useDeletePaymentMethodsMutation();
  const [updatePayment] = useUpdatePaymentMethodsMutation();
  const { payments, isFetching } = useGetPaymentMethodsQuery(
    { page: 1, pageSize: 2 },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFetching,
          payments: isStable ? currentData.rows : emptyArray,
        };
      },
    },
  );
  const [getDispatcherOptionFetch] = useLazyGetDispatcherOptionQuery();
  const [updateDispatcherOption] = useUpdateDispatcherOptionMutation();
  const [addDispatcherOption] = useAddDispatcherOptionMutation();

  const [alarms, setAlarms] = useState<AlarmStateType | undefined>(undefined);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [selectPayment, setSelectPayment] = useState<PaymentMethodDto | null>(
    null,
  );

  const isManagerType =
    session.currentData?.row.exporterUserType === "MANAGER" ||
    session.currentData?.row.exporterUserType === "MIDDLE_MANAGER";

  const handleChangeMainPaymentClick = () => {
    setAlertDialogState(AlertDialogState.MAIN_PAYMENT_METHODS);
  };
  const handlePaymentMethodDeleteClick = () => {
    setAlertDialogState(AlertDialogState.PAYMENT_METHODS_DELETE);
  };
  const handlePaymentAddClick = () => {
    setDialogState(DialogState.PAYMENT_METHODS_ADD);
  };

  const handleDeletePayment = async (id: number) => {
    try {
      await deletePayment({ id }).unwrap();

      setAlertDialogState(AlertDialogState.NULL);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const getDispatcherOption = useCallback(async () => {
    try {
      const res = await getDispatcherOptionFetch("PAYMENT_SUCCESS").unwrap();

      if (res?.alarmTypeList.length >= 2) {
        // 푸시알림, 이메일 모두 사용중
        return setAlarms("PUSH_ALARM_AND_EMAIL");
      } else {
        return setAlarms(res?.alarmTypeList[0] as "EMAIL" | "PUSH_ALARM");
      }
    } catch (e: any) {
      if (e.status === 404) {
        // 값이 없을경우 알림을 생성해주어야함 default로 이메일 & 푸시 알림 설정
        await addDispatcherOption({
          dispatcherKey: "SUBSCRIPTION_PAYMENT_NOTICE",
          alarmTypeList: ["PUSH_ALARM", "EMAIL"],
        });
        await addDispatcherOption({
          dispatcherKey: "PAYMENT_SUCCESS",
          alarmTypeList: ["PUSH_ALARM", "EMAIL"],
        });

        return setAlarms("PUSH_ALARM_AND_EMAIL");
      }

      if (e.status !== 404) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  }, [addDispatcherOption, alert, getDispatcherOptionFetch]);

  const handleChangeAlarms = async () => {
    const params = {
      alarmTypeList: (alarms === "PUSH_ALARM_AND_EMAIL"
        ? ["PUSH_ALARM", "EMAIL"]
        : [alarms]) as AlarmType[],
    };

    try {
      await updateDispatcherOption({
        dispatcherKey: "SUBSCRIPTION_PAYMENT_NOTICE",
        alarmTypeList: params.alarmTypeList,
      });
      await updateDispatcherOption({
        dispatcherKey: "PAYMENT_SUCCESS",
        alarmTypeList: params.alarmTypeList,
      });

      await getDispatcherOption();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const updateMainPaymentMethods = async () => {
    if (!user || !selectPayment) {
      return;
    }

    const updateParams = {
      id: selectPayment.id,
      cardNumber: selectPayment.cardNumber,
      userInfo: selectPayment.userInfo,
      expiryDate: selectPayment.expiryDate,
      password2: selectPayment.password2,
      isRepresentative: true,
      exporterId: user.exporter.id,
    };

    try {
      await updatePayment(updateParams).unwrap();

      setAlertDialogState(AlertDialogState.NULL);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderPaymentMethods = () => {
    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={50} />
        </LoaderContainer>
      );
    }

    if (payments.length === 0) {
      // 데이터 없을 시
      return <LoaderContainer>{renderNoRowsComponent()}</LoaderContainer>;
    }

    // 결제수단 1개 일시
    if (payments.length === 1) {
      const { cardNumber, expiryDate, isRepresentative, userInfo } =
        payments[0];

      const isBusiness = user?.exporter.businessNumber === userInfo;
      const year = expiryDate.substring(0, 2);
      const month = expiryDate.substring(2, 4);

      return (
        <Grid>
          <StyledCallOut backgroundColor="white">
            <Flex direction="column" gap={16}>
              <Flex gap={8} alignItems="center">
                <Typo typoType="h4">
                  {t("subscriptionManagement:subscription.paymentMethod")} 01
                </Typo>
                {isRepresentative && (
                  <Badge
                    badgeSize="S"
                    badgeColor="systemBlue6"
                    color="systemBlue2"
                    text={t(
                      "subscriptionManagement:subscription.primaryPaymentMethod",
                    )}
                  />
                )}
              </Flex>

              <Flex direction="column" gap={8}>
                <SectionCardRow
                  label={
                    <Typo color="gray6" typoType="b7m">
                      {t("subscriptionManagement:subscription.cardInformation")}
                    </Typo>
                  }
                  value={
                    <Flex gap={8} alignItems="center">
                      <Typo typoType="b7r">
                        {formatCreditCardMasking(cardNumber)}
                      </Typo>
                      <Badge
                        badgeSize="S"
                        badgeColor="gray10"
                        color="gray4"
                        text={
                          isBusiness
                            ? t("subscriptionManagement:subscription.business")
                            : t(
                                "subscriptionManagement:subscription.individual",
                              )
                        }
                      />
                    </Flex>
                  }
                />
                <SectionCardRow
                  label={
                    <Typo color="gray6" typoType="b7m">
                      {t(
                        "subscriptionManagement:subscription.cardExpirationDate",
                      )}
                    </Typo>
                  }
                  value={
                    <Typo typoType="b7r">
                      20{year}-{month}
                    </Typo>
                  }
                />
              </Flex>

              <Flex gap={8}>
                <Button
                  buttonColor="black"
                  buttonGrade="tertiary"
                  disabled
                  buttonSize={32}
                  className="button-full-size"
                >
                  {t(
                    "subscriptionManagement:subscription.button.paymentDelete",
                  )}
                </Button>
                <Button
                  buttonGrade="secondary"
                  disabled
                  buttonSize={32}
                  className="button-full-size"
                >
                  {t("subscriptionManagement:subscription.button.setUpPrimary")}
                </Button>
              </Flex>
            </Flex>
          </StyledCallOut>

          <StyledCallOut backgroundColor="white">
            <Flex direction="column">
              <Flex gap={8} alignItems="center" justifyContent="center">
                <Icon iconSrc={PlusCircleSvg} iconSize={24} />
                <Typo typoType="h3" className="text-center" color="gray8">
                  {t("subscriptionManagement:subscription.addPaymentMethod")}
                </Typo>
              </Flex>

              <Button
                buttonSize={32}
                buttonGrade="secondary"
                className="button-full-size"
                onClick={handlePaymentAddClick}
                disabled={isManagerType}
              >
                {t(
                  "subscriptionManagement:subscription.button.addPaymentMethod",
                )}
              </Button>
            </Flex>
          </StyledCallOut>
        </Grid>
      );
    }

    // 결제수단 2개 일시
    return (
      <Grid>
        {payments.map(
          (
            { cardNumber, expiryDate, isRepresentative, id, userInfo, ...rest },
            idx,
          ) => {
            const isBusiness = user?.exporter.businessNumber === userInfo;
            const year = expiryDate.substring(0, 2);
            const month = expiryDate.substring(2, 4);

            return (
              <StyledCallOut backgroundColor="white">
                <Flex direction="column" gap={16}>
                  <Flex gap={8} alignItems="center">
                    <Typo typoType="h4">
                      {t("subscriptionManagement:subscription.paymentMethod")}{" "}
                      {addZeroPrefix(idx + 1)}
                    </Typo>
                    {isRepresentative && (
                      <Badge
                        badgeSize="S"
                        badgeColor="systemBlue6"
                        color="systemBlue2"
                        text={t(
                          "subscriptionManagement:subscription.primaryPaymentMethod",
                        )}
                      />
                    )}
                  </Flex>

                  <Flex direction="column" gap={8}>
                    <SectionCardRow
                      label={
                        <Typo color="gray6" typoType="b7m">
                          {t(
                            "subscriptionManagement:subscription.cardInformation",
                          )}
                        </Typo>
                      }
                      value={
                        <Flex gap={8} alignItems="center">
                          <Typo typoType="b7r">
                            {formatCreditCardMasking(cardNumber)}
                          </Typo>
                          <Badge
                            badgeSize="S"
                            badgeColor="gray10"
                            color="gray4"
                            text={
                              isBusiness
                                ? t(
                                    "subscriptionManagement:subscription.business",
                                  )
                                : t(
                                    "subscriptionManagement:subscription.individual",
                                  )
                            }
                          />
                        </Flex>
                      }
                    />
                    <SectionCardRow
                      label={
                        <Typo color="gray6" typoType="b7m">
                          {t(
                            "subscriptionManagement:subscription.cardExpirationDate",
                          )}
                        </Typo>
                      }
                      value={
                        <Typo typoType="b7r">
                          20{year}-{month}
                        </Typo>
                      }
                    />
                  </Flex>

                  <Flex gap={8}>
                    <Button
                      buttonColor="black"
                      buttonGrade="tertiary"
                      buttonSize={32}
                      className="button-full-size"
                      onClick={() => {
                        setSelectPayment({
                          cardNumber,
                          expiryDate,
                          isRepresentative,
                          id,
                          userInfo,
                          ...rest,
                        });
                        handlePaymentMethodDeleteClick();
                      }}
                      disabled={isRepresentative || isManagerType}
                    >
                      {t(
                        "subscriptionManagement:subscription.button.paymentDelete",
                      )}
                    </Button>
                    <Button
                      buttonGrade="secondary"
                      buttonSize={32}
                      className="button-full-size"
                      onClick={() => {
                        handleChangeMainPaymentClick();
                        setSelectPayment({
                          cardNumber,
                          expiryDate,
                          isRepresentative,
                          id,
                          userInfo,
                          ...rest,
                        });
                      }}
                      disabled={isRepresentative || isManagerType}
                    >
                      {t(
                        "subscriptionManagement:subscription.button.setUpPrimary",
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </StyledCallOut>
            );
          },
        )}
      </Grid>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.PAYMENT_METHODS_ADD) {
      return (
        <AddPaymentMethodsDialog
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
          }}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.MAIN_PAYMENT_METHODS) {
      return (
        <AlertDialog
          open
          title={t("subscriptionManagement:subscription.changePaymentMethod")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={updateMainPaymentMethods}
          okText={t("subscriptionManagement:subscription.button.ok")}
          cancelText={t("subscriptionManagement:subscription.button.cancel")}
        >
          {t(
            "subscriptionManagement:subscription.changePaymentMethodDescription",
          )}
        </AlertDialog>
      );
    }

    if (alertDialogState === AlertDialogState.PAYMENT_METHODS_DELETE) {
      return (
        <StyledAlertDialog
          open
          title={t("subscriptionManagement:subscription.paymentDelete")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
        >
          <Flex direction="column" gap={24}>
            <Typo>
              {t(
                "subscriptionManagement:subscription.paymentDeleteDescription",
              )}
            </Typo>
            <Flex gap={8}>
              <StyledButton
                buttonGrade="tertiary"
                buttonColor="black"
                onClick={() => {
                  setAlertDialogState(AlertDialogState.NULL);
                }}
              >
                {t("subscriptionManagement:subscription.button.cancel")}
              </StyledButton>
              <StyledButton
                isLoading={isDeletePaymentLoading}
                disabled={isDeletePaymentLoading}
                onClick={() => {
                  selectPayment?.id && handleDeletePayment(selectPayment?.id);
                }}
              >
                {t("subscriptionManagement:subscription.button.ok")}
              </StyledButton>
            </Flex>
          </Flex>
        </StyledAlertDialog>
      );
    }
  };

  useEffect(() => {
    getDispatcherOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard
      cardTitle={t(
        "subscriptionManagement:subscription.managePaymentMethodsCardTitle",
      )}
    >
      {renderDialog()}
      {renderAlertDialog()}
      {renderPaymentMethods()}

      <Divider />

      <StyledSectionCardRow
        label={t(
          "subscriptionManagement:subscription.paymentCompletionNotification",
        )}
        value={
          <Flex justifyContent="space-between">
            <RadioGroup
              size="large"
              name="alarms"
              value={alarms}
              onChange={(e) => {
                setAlarms(e.target.value);
              }}
              typoType="b7r"
              options={[
                {
                  label: t("subscriptionManagement:subscription.email"),
                  value: "EMAIL",
                },
                {
                  label: t("subscriptionManagement:subscription.push"),
                  value: "PUSH_ALARM",
                },
                {
                  label: t("subscriptionManagement:subscription.emailAndPush"),
                  value: "PUSH_ALARM_AND_EMAIL",
                },
              ]}
            />

            <AlarmsChangeButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={handleChangeAlarms}
            >
              {t("subscriptionManagement:subscription.button.change")}
            </AlarmsChangeButton>
          </Flex>
        }
      />
    </SectionCard>
  );
}

export default PaymentManagement;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const Flex = styled.div<{
  direction?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  gap?: number;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap || 0}px;
  width: 100%;
  height: 100%;

  .button-full-size {
    flex: 1;
    text-align: center;
  }

  .text-center {
    text-align: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledCallOut = styled(CallOut)`
  display: block;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  padding-top: 20px;

  & > p {
    max-width: 100%;
  }

  & > :last-child {
    flex: 1;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 182px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  text-align: center;
`;

const StyledAlertDialog = styled(AlertDialog)`
  & {
    gap: 0;
  }
`;

const AlarmsChangeButton = styled(Button)`
  margin-left: auto;
`;
