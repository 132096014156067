import { ReactComponent as RibbonSvg } from "@/src/assets/icons/icon-gold-ribbon.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import styled from "styled-components";

export const renderRibbonIcon = (index: number, size?: number) => {
  const iconSize = size ?? 14;

  switch (index) {
    case 0:
      return <RibbonIcon color="yellow2" size={iconSize} />;
    case 1:
      return <RibbonIcon color="gray8" size={iconSize} />;
    case 2:
      return <RibbonIcon color="red7" size={iconSize} />;
  }
};

const RibbonIcon = styled(RibbonSvg)<{ color: ColorType; size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;
