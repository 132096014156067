import { useAppDispatch, useAppSelector } from "@/src/store";
import { setRedirectUrl } from "@/src/store/slice/auth";
import { useCallback } from "react";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

const useRedirectNavigate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectUrl = useAppSelector((state) => state.auth.redirectUrl);

  const redirectOrNavigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      if (redirectUrl) {
        setTimeout(() => {
          dispatch(setRedirectUrl(null));
        }, 0);

        navigate(redirectUrl, options);
      } else {
        navigate(to, options);
      }
    },
    [redirectUrl, navigate, dispatch],
  );

  return redirectOrNavigate;
};

export default useRedirectNavigate;
