import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "../routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "../routes/importer/path";
import PUBLIC_PATH from "../routes/public/path";
import { useAppSelector } from "../store";
import { ClientType, ExporterUserType } from "../store/apis/auth/interface";

interface UseCompanyRedirectProps {
  companyType?: ClientType;
  userType?: ExporterUserType;
}

/**
 * @description
 * 해당 페이지 진입시 회사타입, (일반 매니저, 중간관리자) 따라 해당 기업의 Home으로 Redirect를 하기위한 Hook
 *
 * - token이 없을시 로그인 페이지
 * - 중간관리자, 일반 매니저 진입불가
 * - 기업 유형이 (수입자): 수입자 홈화면 이동
 * - 기업 유형이 (수출자): 수출자 홈화면 이동
 * - 기업 유형 (Both): 수출자 홈화면 이동
 */
const useCompanyHomeRedirect = ({
  companyType,
  userType,
}: UseCompanyRedirectProps) => {
  const token = useAppSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const isCorporateManager = userType === "CORPORATE_MANAGER";

  const handleTypeRedirect = (redirectPath: string) => {
    // 중간관리자, 일반매니저
    if (!isCorporateManager) {
      return navigate(redirectPath, { replace: true });
    }
  };

  const redirectNonCorporate = () => {
    if (!token) {
      return navigate(PUBLIC_PATH.LOGIN, { replace: true });
    }

    switch (companyType) {
      case "BOTH":
      case "SELLER":
        return handleTypeRedirect(EXPORTER_PRIVATE_PATH.HOME);
      case "BUYER":
        return handleTypeRedirect(IMPORTER_PRIVATE_PATH.HOME);
    }
  };

  return {
    func: {
      redirectNonCorporate,
    },
  };
};

export default useCompanyHomeRedirect;
