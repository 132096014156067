import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import useCompanyHomeRedirect from "@/src/hooks/useCompanyHomeRedirect";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useGetCurrentSubscriptionQuery,
  useGetPaymentMethodsQuery,
  useGetPlansQuery,
  useLazyRejoinCheckQuery,
} from "@/src/store/apis/subscription";
import {
  PaymentCycleType,
  PaymentMethodDto,
} from "@/src/store/apis/subscription/interface";
import { ErrorResponse } from "@/src/store/apis/type";
import { isUndefined } from "@/src/utils/is";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import NormalPlanSignupCompleted from "./components/NormalPlanSignupCompleted";
import PaymentInformation from "./components/PaymentInformation";
import SelectNormalPlan from "./components/SelectNormalPlan";

export type PlanDataType = {
  paymentCycle: PaymentCycleType;
  planId?: number;
  planVersionId?: number;
  planName?: string;
  currencyUnit?: string;
  numberOfMember?: number;
  numberOfLoadingCode?: number;
  monthlyFee?: string;
  annualFee?: string;
  subscriptionStartAtFrom?: string;
  subscriptionEndAtTo?: string;
  isFreeTrial?: boolean;
};

export type NormalPlanSignupState =
  | "SELECT_PLAN"
  | "PAYMENT_INFORMATION"
  | "COMPLETED";

export type NormalPlanSignupContextType = PlanDataType & {
  step: NormalPlanSignupState;
  onStepChange: Dispatch<SetStateAction<NormalPlanSignupState>>;
  onPlanChange: Dispatch<SetStateAction<PlanDataType>>;
};

const emptyArray: PaymentMethodDto[] = [];

export const NormalPlanSignupContext = createContext<
  NormalPlanSignupContextType | undefined
>(undefined);

function NormalPlanSignupPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isInquiryQuery = !!searchParams.get("isInquiry");

  const [step, setStep] = useState<NormalPlanSignupState>("SELECT_PLAN");
  const [planData, setPlanData] = useState<PlanDataType>({
    paymentCycle: "MONTHLY",
  });

  // API
  useGetPlansQuery({}, { refetchOnMountOrArgChange: true });
  const [rejoinCheck] = useLazyRejoinCheckQuery();

  const {
    businessNumber,
    companyName,
    companyType,
    freeSubscriptionTrialUsedAt,
    exporterUserType,
  } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        exporterUserType: currentData?.row.exporterUserType,
        businessNumber: isStable
          ? currentData?.row.exporter.businessNumber
          : "",
        companyName: isStable ? currentData?.row.exporter.companyName : "",
        freeSubscriptionTrialUsedAt: isStable
          ? currentData?.row.exporter.freeSubscriptionTrialUsedAt
          : null,
        companyType:
          currentData?.row.exporterUserType === "CORPORATE_MANAGER"
            ? currentData?.row.exporter.companyType
            : currentData?.row.exporterUserMainFieldType,
      };
    },
  });

  const { subscription, isSubscription } = useGetCurrentSubscriptionQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          subscriptionId: currentData?.id,
          isSubscription: isStable
            ? currentData.subscriptionStatus === "PROGRESS"
            : false,
          subscription: currentData,
        };
      },
    },
  );

  const { payments } = useGetPaymentMethodsQuery(
    { page: 1, pageSize: 2 },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnStable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnStable;

        return {
          payments: isStable ? currentData.rows : emptyArray,
        };
      },
    },
  );

  const goToHome = () => {
    switch (companyType) {
      case "BOTH":
      case "SELLER":
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
      case "BUYER":
        return navigate(IMPORTER_PRIVATE_PATH.HOME);
      default:
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  };

  const representativePayments = payments
    ? payments.find((item) => item.isRepresentative)
    : null;

  const {
    func: { redirectNonCorporate },
  } = useCompanyHomeRedirect({
    companyType,
    userType: exporterUserType,
  });

  const redirectRoute = useCallback(() => {
    if (isSubscription) {
      return navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
    }

    redirectNonCorporate();
  }, [isSubscription, navigate, redirectNonCorporate]);

  /**
   * @description
   * - 재가입 경우
   * - 신규가입 경우
   * - 정규플랜 무료기간 구독을 사용한 경우 (엔터프라이즈 가입은 무료기간을 사용 안함)
   */
  const checkCompanyReRegistered = useCallback(async () => {
    if (businessNumber === "" || companyName === "") {
      return;
    }

    try {
      const res = await rejoinCheck({
        businessNumber,
        companyName,
      }).unwrap();

      // 재가입인 경우 200을,
      if (res.statusCode === 200) {
        setPlanData((prev) => {
          return { ...prev, isFreeTrial: false };
        });
      }
    } catch (e: any) {
      const error = e as ErrorResponse;
      // 신규가입 경우 404
      if (error.status === 404) {
        setPlanData((prev) => {
          return { ...prev, isFreeTrial: true };
        });
      }
    } finally {
      // 정규플랜 무료기간 구독을 한번이라도 사용한 경우
      if (freeSubscriptionTrialUsedAt) {
        setPlanData((prev) => {
          return { ...prev, isFreeTrial: false };
        });
      }

      // 엔터프라이즈를 사용했으면
      if (subscription?.plan.planType === "ENTERPRISE") {
        setPlanData((prev) => {
          return { ...prev, isFreeTrial: false };
        });
      }
    }
  }, [
    businessNumber,
    companyName,
    freeSubscriptionTrialUsedAt,
    rejoinCheck,
    subscription?.plan.planType,
  ]);

  const renderContent = () => {
    // 플랜 선택 화면
    if (step === "SELECT_PLAN") {
      return <SelectNormalPlan />;
    }
    // 결제 정보 입력
    if (step === "PAYMENT_INFORMATION") {
      return <PaymentInformation />;
    }
    // 플랜 구독 완료
    if (step === "COMPLETED") {
      return <NormalPlanSignupCompleted />;
    }
  };

  useEffect(() => {
    checkCompanyReRegistered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    redirectRoute();

    // 결제방식이 있으며 query isInquiry 없을시
    if (representativePayments && !isInquiryQuery) {
      if (companyType === "SELLER" || companyType === "BOTH") {
        navigate(EXPORTER_PRIVATE_PATH.HOME);
      }
    }
  }, [
    companyType,
    isInquiryQuery,
    navigate,
    redirectRoute,
    representativePayments,
    subscription,
  ]);

  return (
    <SignUpLayout onLogoClick={goToHome}>
      <StyledMain>
        <Title typoType="d3">{t("normalPlanSignup:title")}</Title>

        <NormalPlanSignupContext.Provider
          value={{
            ...planData,
            onPlanChange: setPlanData,
            step,
            onStepChange: setStep,
          }}
        >
          {renderContent()}
        </NormalPlanSignupContext.Provider>
      </StyledMain>
    </SignUpLayout>
  );
}

export default NormalPlanSignupPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  margin: 0 auto;
`;

const Title = styled(Typo)`
  padding: 24px 0;
  margin-bottom: 24px;
`;
