import CheckboxGroup from "@/src/components/atom/CheckboxGroup";
import DatePicker from "@/src/components/atom/DatePicker";
import Input from "@/src/components/atom/Input";
import RadioGroup from "@/src/components/atom/RadioGroup";
import RangePicker from "@/src/components/atom/RangePicker";
import ComboBoxSelect from "@/src/components/atom/Select/ComboBoxSelect";
import MultipleSelect from "@/src/components/atom/Select/MultipleSelect";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import React from "react";
import { styled } from "styled-components";
import { FilterOptionType, GroupType } from "types";
import { FilterItem } from "../types/filterItem";

const renderFilterItem = <T extends {}>(
  filterItem: FilterItem,
  filterData: T,
  onChange: (key: keyof T, value: any) => void,
  optionList?: {
    key: keyof T;
    option: FilterOptionType[] | GroupType[];
  }[],
) => {
  const { type, props, key } = filterItem;
  const values = filterData[key as keyof T] as any;

  switch (type) {
    case "input":
      return (
        <StyledInput
          inputSize="small"
          value={values ?? ""}
          onChange={(e) => onChange(key as keyof T, e.target.value)}
          placeholder={props?.placeholder ?? "search"}
          allowClear={false}
          {...props}
        />
      );

    case "checkbox":
      const checkboxTargetOptions =
        optionList && optionList.find((item) => item.key === key);
      const checkboxTargetOptionsMaping = (
        checkboxTargetOptions?.option as FilterOptionType[]
      ).map((item) => ({
        value: item.value,
        label: item.label,
      }));

      return (
        <StyledCheckboxGroup
          value={values ?? []}
          options={checkboxTargetOptionsMaping}
          onChange={(e) => {
            onChange?.(key as keyof T, e);
          }}
          {...props}
        />
      );

    case "radio":
      const radioTargetOptions =
        optionList && optionList.find((item) => item.key === key);
      const radioTargetOptionsMaping = (
        radioTargetOptions?.option as FilterOptionType[]
      ).map((item) => ({
        value: item.value,
        label: item.label,
      })) as any;

      return (
        <StyledRadioGroup
          name={key}
          value={values}
          onChange={(e) => {
            onChange?.(key as keyof T, e.target.value);
          }}
          options={radioTargetOptionsMaping}
          {...props}
        />
      );

    case "datePicker":
      return (
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                colorPrimary: colorSet.blue4,
                fontSize: 14,
              },
            },
          }}
        >
          <StyledDatePicker
            value={values ? dayjs.utc(values) : undefined}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(day) => {
              onChange?.(key as keyof T, day);
            }}
            format={props?.format ?? "YYYY-MM-DD"}
            {...props}
          />
        </ConfigProvider>
      );

    case "dateRangePicker":
      let dateValues = values;

      if (typeof dateValues === "string") {
        dateValues = dateValues.split(",");
      }

      return (
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                colorPrimary: colorSet.blue4,
                fontSize: 14,
              },
            },
          }}
        >
          <StyledRangePicker
            value={
              dateValues
                ? [dayjs.utc(dateValues[0]), dayjs.utc(dateValues[1])]
                : undefined
            }
            allowClear
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(day) => {
              return onChange?.(key as keyof T, day && [day[0], day[1]]);
            }}
            format={props?.format ?? "YYYY-MM-DD"}
            {...props}
          />
        </ConfigProvider>
      );

    case "searchSelect":
      const searchSelectTargetOptions =
        optionList && optionList.find((item) => item.key === key);
      const searchSelectTargetOptionsMaping =
        searchSelectTargetOptions?.option.map((item) => {
          if ("options" in item) {
            // GroupType인 경우
            return {
              label: item.label,
              options: item.options.map((option) => ({
                value: option.value,
                label: option.label,
              })),
            };
          }
          // FilterOptionType인 경우
          return {
            value: item.value,
            label: item.label,
          };
        });

      return (
        <ConfigProvider
          theme={{
            token: {
              controlHeight: 34,
              fontSize: 14,
            },
          }}
        >
          <StyledSelect
            showSearch
            size="middle"
            style={
              {
                height: "34px",
                "--antd-select-option-font-size": `14px`,
              } as React.CSSProperties
            }
            value={values}
            onChange={(value) => {
              onChange?.(key as keyof T, value);
            }}
            popupClassName="filter-select-option"
            options={searchSelectTargetOptionsMaping}
            filterOption={(input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase()) ||
              ((option?.value as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            {...props}
          />
        </ConfigProvider>
      );

    case "checkboxSelect":
      const checkboxSelectTargetOptions =
        optionList && optionList.find((item) => item.key === key);
      const checkboxSelectTargetOptionsMaping = (
        checkboxSelectTargetOptions?.option as FilterOptionType[]
      ).map((item) => ({
        value: item.value,
        label: item.label,
      }));

      return (
        <ConfigProvider
          theme={{
            token: {
              controlHeight: 34,
              fontSize: 14,
            },
          }}
        >
          <StyledCheckboxSelect
            maxTagCount="responsive"
            value={values}
            size="middle"
            style={
              {
                height: "34px",
                "--antd-select-option-font-size": `14px`,
              } as React.CSSProperties
            }
            popupClassName="filter-select-option"
            options={checkboxSelectTargetOptionsMaping}
            onChange={(value) => {
              onChange?.(key as keyof T, value);
            }}
            onRemoveItem={(value) => {
              onChange?.(
                key as keyof T,
                values.filter((item: any) => item !== value),
              );
            }}
            {...props}
          />
        </ConfigProvider>
      );

    case "comboBoxSelect":
      const comboBoxSelectTargetOptions =
        optionList && optionList.find((item) => item.key === key);
      const comboBoxSelectTargetOptionsMaping = (
        comboBoxSelectTargetOptions?.option as FilterOptionType[]
      ).map((item) => ({
        value: item.value,
        label: item.label,
      }));

      return (
        <ConfigProvider
          theme={{
            token: {
              controlHeight: 34,
              fontSize: 14,
            },
          }}
        >
          <StyledComboBoxSelect
            maxTagCount="responsive"
            value={values}
            size="middle"
            style={
              {
                height: "34px",
                "--antd-select-option-font-size": `14px`,
              } as React.CSSProperties
            }
            popupClassName="filter-select-option"
            options={comboBoxSelectTargetOptionsMaping}
            onChange={(value) => {
              onChange?.(key as keyof T, value);
            }}
            {...props}
          />
        </ConfigProvider>
      );
  }
};

export default renderFilterItem;

const StyledInput = styled(Input)`
  height: 100%;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  ${typo.b9r}

  &::placeholder {
    ${typo.b9r}
  }
`;

const StyledCheckboxGroup = styled(CheckboxGroup)`
  height: 100%;
  width: 100%;
  flex: 1;
`;

const StyledRadioGroup = styled(RadioGroup)`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const StyledDatePicker = styled(DatePicker)`
  max-height: 34px;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const StyledRangePicker = styled(RangePicker)`
  height: 100%;
  width: 100%;
  flex: 1;

  .ant-picker .ant-picker-range {
    padding: 0 !important;
  }

  .ant-picker-input > input::placeholder {
    ${typo.b9r}
  }

  .ant-picker-clear {
    right: 17px;
  }
`;

const StyledSelect = styled(SearchSelect)`
  flex: 1;
  width: 10px;

  span.ant-select-selection-item {
    ${typo.b9r}
  }
`;

const StyledCheckboxSelect = styled(MultipleSelect)`
  flex: 1;

  .ant-select-selection-overflow-item span {
    ${typo.b9r}
  }
`;

const StyledComboBoxSelect = styled(ComboBoxSelect)`
  flex: 1;

  .ant-select-selection-overflow-item span {
    ${typo.b9r}
  }
`;
