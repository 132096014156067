import ActiveCheckFillSvg from "@/src/assets/icons/icon-check-circle-fill.svg";
import ChevronDisabledSvg from "@/src/assets/icons/icon-chevron-left-disabled.svg";
import ChevronAbledSvg from "@/src/assets/icons/icon-chevron-right-abled.svg";
import colorSet from "@/src/styles/color";
import zIndex from "@/src/styles/zIndex";
import { ConfigProvider } from "antd";
import { ImgHTMLAttributes } from "react";
import ImageGallery, { ReactImageGalleryProps } from "react-image-gallery";
import { css, styled } from "styled-components";
import Icon from "../../atom/Icon";

export interface PhotoCollectionProps extends ReactImageGalleryProps {
  onImageClick: (imageUrl: string) => void;
  imageHeight?: number;
  imgProps?: ImgHTMLAttributes<HTMLImageElement>;
  thumbnailProps?: ImgHTMLAttributes<HTMLImageElement>;
  onSlideChange?: (index: number) => void; // 슬라이드 변경 시 외부로 알릴 함수
  isNoData?: boolean;
}

const PhotoCollection = (props: PhotoCollectionProps) => {
  const {
    onImageClick,
    imageHeight = 380,
    imgProps,
    thumbnailProps,
    onSlideChange,
    isNoData,
  } = props;

  return (
    <ImageGallery
      showFullscreenButton={false}
      onSlide={(currentIndex) => {
        if (onSlideChange) {
          onSlideChange(currentIndex);
        }
      }}
      thumbnailPosition="top"
      renderItem={(item) => {
        return (
          <ConfigProvider
            theme={{
              components: {
                Image: {
                  zIndexPopup: zIndex.antdImage,
                  zIndexPopupBase: zIndex.antdImageBase,
                },
              },
            }}
          >
            <StyledImage
              src={item.original}
              width={718}
              height={imageHeight}
              onClick={() => onImageClick(item.original)}
              isPlaceholder={isNoData}
              {...imgProps}
            />
          </ConfigProvider>
        );
      }}
      showPlayButton={false}
      renderThumbInner={(item) => {
        return (
          <>
            <StyledThumbnail {...thumbnailProps}>
              <img
                src={item.thumbnail}
                alt={"thumbnail"}
                className={` ${isNoData ? "no-data" : ""}`}
              />
            </StyledThumbnail>

            <div className={`active-overlay ${isNoData ? "no-data" : ""}`}>
              {!isNoData && <Icon iconSrc={ActiveCheckFillSvg} iconSize={32} />}
            </div>
          </>
        );
      }}
      infinite={false}
      renderLeftNav={(onClick, disabled) => {
        return <LeftNav onClick={onClick} disabled={disabled} />;
      }}
      renderRightNav={(onClick, disabled) => {
        return <RightNav onClick={onClick} disabled={disabled} />;
      }}
      {...props}
    />
  );
};

export default PhotoCollection;

const StyledImage = styled.img<{ isPlaceholder?: boolean }>`
  object-fit: ${({ isPlaceholder }) => (isPlaceholder ? "cover" : "contain")};
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};
  margin-top: 8px;
`;

const StyledThumbnail = styled.div`
  width: 82px;
  height: 82px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;

  .no-data {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

const RightNav = ({
  onClick,
  disabled,
}: {
  onClick: (e: any) => void;
  disabled: boolean;
}) => {
  return (
    <StyledRightIcon
      disabled={disabled}
      iconSrc={disabled ? ChevronDisabledSvg : ChevronAbledSvg}
      onClick={!disabled ? onClick : () => {}}
      iconSize={40}
    />
  );
};

const LeftNav = ({
  onClick,
  disabled,
}: {
  onClick: (e: any) => void;
  disabled: boolean;
}) => {
  return (
    <StyledLeftIcon
      disabled={disabled}
      iconSrc={disabled ? ChevronDisabledSvg : ChevronAbledSvg}
      onClick={!disabled ? onClick : () => {}}
      iconSize={40}
    />
  );
};

const StyledRightIcon = styled(Icon)<{ disabled: boolean }>`
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  z-index: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `};
`;

const StyledLeftIcon = styled(Icon)<{ disabled: boolean }>`
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  z-index: 1;

  ${({ disabled }) =>
    !disabled &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `};
`;
