import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { renderLoadingStatusBadge } from "../../Task/utils/renderTaskStatusBadge";

export const columnLoadingList = (t: TFunction): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 60,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.loadingList.loadingStatus"),
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "taskStatus",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.taskStatus) {
        return renderLoadingStatusBadge({
          type: params.data.taskStatus,
          t: params.data.t,
        });
      } else {
        return "-";
      }
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.loadingList.workplaceName"),
    cellStyle: { textAlign: "center" },
    field: "workplaceName",
    flex: 1,
    minWidth: 150,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.loadingList.workingDayAt"),
    cellStyle: { textAlign: "center" },
    field: "workingDayAt",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.workingDayAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD,
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t(
      "contract:exporter.detail.loadingList.numberOfLoadingContainer",
    ),
    cellStyle: { textAlign: "center" },
    field: "numberOfTaskContainer",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfTaskContainer && params.data.containerType ? (
        <Typo typoType="b9r">
          {params.data.numberOfTaskContainer}x{params.data.containerType}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.loadingList.totalNetWeight"),
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return "-";
      } else {
        return "-";
      }
    },
  },
];
