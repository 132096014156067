import Typo from "@/src/components/atom/Typo";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import getTableIndex from "@/src/utils/getTableIndex";
import { CELL_TYPE_VIEW_ACTION } from "@/src/utils/row-data-util";
import { ColDef, ValueGetterParams } from "ag-grid-community";

export const columnShardContainerTab: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize,
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "item",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.item ? (
        <Typo typoType="b9r">{params.data.item}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "containerNo",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.containerNo ? (
        <TableBlueText typoType="b9r">{params.data.containerNo}</TableBlueText>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "sealNo",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sealNo ? (
        <Typo typoType="b9r">{params.data.sealNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitQuantity",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitQuantity ? (
        <Typo typoType="b9r">{params.data.unitQuantity}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "grossWeight",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.grossWeight ? (
        <Typo typoType="b9r">{params.data.grossWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "netWeight",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.netWeight ? (
        <Typo typoType="b9r">{params.data.netWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "photo",
    flex: 1,
    width: 110,
    sortable: false,
    lockPosition: "right",
    pinned: "right",
    cellRenderer: CELL_TYPE_VIEW_ACTION,
  },
];

export const columnShardSelectContainer: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex ? params.node?.rowIndex + 1 : 1}
        </Typo>
      );
    },
    sortable: false,
  },

  {
    headerName: "Item",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "item",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.item ? (
        <Typo typoType="b9r">{params.data.item}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Container No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "containerNo",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.containerNo ? (
        <Typo typoType="b9r">{params.data.containerNo}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Seal No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "sealNo",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sealNo ? (
        <Typo typoType="b9r">{params.data.sealNo}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Unit Q’ty",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitQuantity",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitQuantity ? (
        <Typo typoType="b9r">{params.data.unitQuantity}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Gross Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "grossWeight",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.grossWeight ? (
        <Typo typoType="b9r">{params.data.grossWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Net Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "netWeight",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.netWeight ? (
        <Typo typoType="b9r">{params.data.netWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
];
