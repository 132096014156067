import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import BasicTextArea from "@/src/components/atom/TextArea/BasicTextArea";
import Typo from "@/src/components/atom/Typo";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import useAlert from "@/src/hooks/useAlert";
import { useCreateSalesContractHistoryMutation } from "@/src/store/apis/corporate/company";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface TermsEditDialogProps {
  title: string;
  open: boolean;
  onOpenChange: (value: boolean) => void;
  termsData: { salesContractTermsId: number; title: string; body: string };
  onSave?: () => void;
}

const TermsEditDialog = ({
  title,
  open,
  onOpenChange,
  termsData,
  onSave,
}: TermsEditDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [createSalesContractHistory, { isLoading }] =
    useCreateSalesContractHistoryMutation();

  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setFocus,
    getValues,
    formState: { errors },
  } = useForm<{
    title: string;
    body: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      title: termsData.title,
      body: termsData.body,
    },
  });

  useEffect(() => {
    setFocus("title");
  }, [setFocus]);

  const handleSubmitClick = async () => {
    const params = {
      title: getValues("title"),
      body: getValues("body"),
      salesContractTermsId: termsData.salesContractTermsId as number,
    };

    try {
      await createSalesContractHistory(params).unwrap();
      onSave?.();
      onOpenChange(false);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  return (
    <Dialog
      title={title}
      open={open}
      width={850}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
            ref={cancelButtonRef}
            onKeyUp={(e) => {
              if (e.shiftKey && e.code === "Tab") {
                setFocus("body");
              }
            }}
          >
            <Typo typoType="btn3m">
              {t(
                "companyManagement:exporter.companyInfoDialog.terms.cancelButton",
              )}
            </Typo>
          </Button>
          <Button
            onClick={() => submitButtonRef.current?.click()}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t(
                "companyManagement:exporter.companyInfoDialog.terms.saveButton",
              )}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <FormItem
          label={t("companyManagement:exporter.companyInfoDialog.terms.title")}
          type="text"
          name="title"
          rules={{ required: true }}
          errorsMessage={{
            required: t("error:required"),
          }}
          control={control}
          direction="vertical"
          inputProps={{
            placeholder: t(
              "companyManagement:exporter.companyInfoDialog.terms.titlePlaceholder",
            ),
          }}
        />

        <SectionCardRow
          direction="vertical"
          label={
            <Typo typoType="b7m">
              {t("companyManagement:exporter.companyInfoDialog.terms.contents")}{" "}
              <Typo typoType="b7m" color="red2">
                *
              </Typo>
            </Typo>
          }
          value={
            <Controller
              control={control}
              name="body"
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <BasicTextArea
                    {...field}
                    placeholder={t(
                      "companyManagement:exporter.companyInfoDialog.terms.textEditorPlaceholder",
                    )}
                    errorMessage={errors.body ? t("error:required") : ""}
                  />
                );
              }}
            />
          }
        />
        <Hidden
          ref={submitButtonRef}
          type="submit"
          onFocus={() => cancelButtonRef.current?.focus()}
        />
      </Form>
    </Dialog>
  );
};

export default TermsEditDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
