import FolderBagSvg from "@/src/assets/icons/icon-fold-bag.svg";
import GoogleLogoSvg from "@/src/assets/icons/icon-google.svg";
import PickerSvg from "@/src/assets/icons/icon-picker.svg";
import SearchSvg from "@/src/assets/icons/icon-search.svg";
import useAlert from "@/src/hooks/useAlert";
import {
  useLazyGetAddressDetailQuery,
  useLazyGetAddressQuery,
} from "@/src/store/apis/common";
import { AddressDto } from "@/src/store/apis/common/interface";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Dialog from "../../atom/Dialog";
import Icon from "../../atom/Icon";
import IconInput from "../../atom/Input/IconInput";
import Typo from "../../atom/Typo";

interface AddressSearchModalProps {
  onClose: () => void;
  onAddressItemClick: (addressDetail?: AddressDto) => void;
}

const AddressSearchModal = ({
  onClose,
  onAddressItemClick,
}: AddressSearchModalProps) => {
  const { t } = useTranslation();
  const [addressSearchQuery, setAddressSearchQuery] = useState<string>("");
  const modalInputRef = useRef<HTMLInputElement | null>();
  const [addressSearch, addressSearchResult] = useLazyGetAddressQuery();
  const [addressDetailSearch] = useLazyGetAddressDetailQuery();
  const alert = useAlert();

  const divideBoldAndNormalText = (resourceText: string) => {
    const query = addressSearchResult.originalArgs?.input.toLowerCase() ?? "";
    const startIndex = resourceText.toLowerCase().indexOf(query);
    const endIndex = startIndex === -1 ? 0 : startIndex + query.length;
    const splitAll = resourceText.split("");
    return (
      <AddressMainText>
        {splitAll.map((char, idx) => {
          const isBold = idx >= startIndex && idx < endIndex;
          return <>{isBold ? <b>{char}</b> : <span>{char}</span>}</>;
        })}
      </AddressMainText>
    );
  };

  const handleAddressDetailSearch = async (placeId: string) => {
    try {
      const response = await addressDetailSearch({
        placeId,
        language: "en",
      }).unwrap();
      onAddressItemClick(response);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      addressSearch({
        language: "en",
        input: addressSearchQuery,
      })
        .unwrap()
        .catch(() => {});
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [addressSearchQuery, addressSearch]);

  useEffect(() => {
    if (modalInputRef.current) {
      modalInputRef.current.focus();
    }
  }, []);

  return (
    <>
      <Dialog
        title={t("signupCorp:content.searchAddress")}
        open
        onOpenChange={() => {
          setAddressSearchQuery("");
          onClose();
        }}
      >
        <IconInput
          icon={<Icon iconSrc={SearchSvg} iconSize={20} />}
          value={addressSearchQuery}
          onChange={(e) => {
            setAddressSearchQuery(e.target.value);
          }}
          onClear={() => setAddressSearchQuery("")}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) return;
            if (e.key === "Enter" && addressSearchResult.data?.length) {
              handleAddressDetailSearch(
                addressSearchResult.data[0].placeId ?? "",
              );
            }

            if (e.key === "ArrowDown") {
              e.preventDefault();
              const addressItems = document.querySelectorAll("#address-item");
              if (addressItems.length) {
                (addressItems[0] as HTMLElement).focus();
              }
            }
          }}
          ref={(node) => (modalInputRef.current = node)}
          placeholder={t("signupCorp:content.searchAddressPlaceholder")}
        />

        <AddressSearchContentContainer>
          {addressSearchResult.currentData?.length === 0 ||
          !addressSearchResult.currentData ? (
            <AddressModalPlaceholderContainer>
              <Icon iconSrc={FolderBagSvg} iconSize={56} />
              <Typo typoType="b7m" color="gray8">
                {t("common:noData")}
              </Typo>
            </AddressModalPlaceholderContainer>
          ) : (
            (addressSearchResult.currentData.map(
              ({ mainText, secondaryText, placeId }, index) => {
                return (
                  <AddressSearchContentItem
                    id="address-item"
                    key={placeId}
                    tabIndex={1}
                    onClick={() => handleAddressDetailSearch(placeId ?? "")}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown") {
                        const addressItems =
                          document.querySelectorAll("#address-item");
                        if (addressItems.length && addressItems[index + 1]) {
                          (addressItems[index + 1] as HTMLElement).focus();
                        }
                      }

                      if (e.key === "ArrowUp") {
                        if (index === 0 && modalInputRef.current) {
                          modalInputRef.current.focus();
                        }
                        const addressItems =
                          document.querySelectorAll("#address-item");
                        if (addressItems.length && addressItems[index - 1]) {
                          (addressItems[index - 1] as HTMLElement).focus();
                        }
                      }

                      if (e.key === "Enter") {
                        handleAddressDetailSearch(placeId ?? "");
                      }
                    }}
                  >
                    <FlexCenter>
                      <StyledIcon iconSrc={PickerSvg} />
                      {divideBoldAndNormalText(mainText ?? "")}
                    </FlexCenter>
                    <AddressSubText>{secondaryText}</AddressSubText>
                  </AddressSearchContentItem>
                );
              },
            ) ?? [])
          )}
          <CopyRightContainer>
            <Typo typoType="label2" color="gray7">
              {t("common:poweredBy")}
            </Typo>
            <span className="google-logo" />
          </CopyRightContainer>
        </AddressSearchContentContainer>
      </Dialog>
    </>
  );
};

export default AddressSearchModal;

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AddressSearchContentContainer = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  height: 344px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const AddressSearchContentItem = styled.div`
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background: ${colorSet.gray10};
  }
`;

const AddressMainText = styled.span`
  ${typo.b7r}
  color: ${colorSet.gray1};

  ${ellipsis}
  b {
    ${typo.h7}
  }
`;

const AddressSubText = styled.span`
  ${typo.b10r}
  color: ${colorSet.gray6};
  padding-left: 24px;
  ${ellipsis}
`;

const AddressModalPlaceholderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CopyRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  width: 100%;
  margin-top: auto;

  .google-logo {
    display: block;
    height: 18px;
    width: 59.8px;
    margin-left: 2px;
    background: url(${GoogleLogoSvg}) no-repeat center;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
