import CompletedIcon from "@/src/assets/icons/icon-completed.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import useAlert from "@/src/hooks/useAlert";
import { persistor } from "@/src/index";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch } from "@/src/store";
import { useSignOutMutation } from "@/src/store/apis/auth";
import { apiSlice } from "@/src/store/apis/baseQuery";
import { currencyApi } from "@/src/store/apis/currency";
import { resetTimer } from "@/src/store/slice/sessionTime";
import { CSSProperties, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useEnterPriseSignupStepContext } from "../hooks";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";

function AdminConfirmCompleted() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const isHomeButton = searchParams.get("isHomeButton") === "true";

  const { onStepChange } = useEnterPriseSignupStepContext();

  const [signOut] = useSignOutMutation();

  const handleLogoutClick = useCallback(async () => {
    try {
      await signOut().unwrap;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    } finally {
      persistor.purge();
      dispatch(currencyApi.util.resetApiState());
      dispatch(apiSlice.util.resetApiState());
      dispatch(resetTimer());
      navigate(PUBLIC_PATH.LOGIN, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentRegistrationClick = () => {
    onStepChange("PAYMENT_REGISTRATION");
  };

  const renderPreviousButton = () => {
    if (isHomeButton) {
      return (
        <StyledButton
          buttonColor="black"
          buttonGrade="tertiary"
          onClick={() => navigate(EXPORTER_PRIVATE_PATH.HOME)}
        >
          {t("common:backToTheHome")}
        </StyledButton>
      );
    }
    return (
      <StyledButton
        buttonColor="black"
        buttonGrade="tertiary"
        onClick={handleLogoutClick}
      >
        {t("enterpriseSignup:button.logout")}
      </StyledButton>
    );
  };

  return (
    <StyledSectionCard>
      <FlexColumn gap={80}>
        {/* Information */}
        <PlanInfo>
          <FlexColumn alignItems="center">
            <Icon iconSrc={CompletedIcon} iconSize={60} />
          </FlexColumn>

          <FlexColumn gap={12} alignItems="center">
            <Typo typoType="d6">{t("enterpriseSignup:adminConfirm")}</Typo>
            <WhiteSpace>
              {t("enterpriseSignup:adminConfirmDescription")}
            </WhiteSpace>
          </FlexColumn>
        </PlanInfo>

        {/* Button */}
        <ButtonContainer>
          {renderPreviousButton()}
          <StyledButton onClick={handlePaymentRegistrationClick}>
            {t("enterpriseSignup:button.payment")}
          </StyledButton>
        </ButtonContainer>
      </FlexColumn>
    </StyledSectionCard>
  );
}

export default AdminConfirmCompleted;

const StyledSectionCard = styled(SectionCardWithoutHeader)`
  width: 640px;
  padding: 40px 48px;
`;

const FlexColumn = styled.div<{
  gap?: number;
  alignItems?: CSSProperties["alignItems"];
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 40px;
`;

const StyledButton = styled(Button)`
  text-align: center;
  flex: 1;
`;

const WhiteSpace = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;
