import { ShipmentContainerMediaInfoDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { ContainerMediaType } from "@/src/store/apis/tasks/taskDetail/interface";
import { TFunction } from "i18next";
import { ReactNode } from "react";

export type ContainerPhotoListType = {
  isActive: boolean;
  maxCount: number;
  uploadedCount: number;
  imageList: ShipmentContainerMediaInfoDto[];
  containerType: ContainerMediaType;
  title: ReactNode;
  icon: ReactNode;
  extraMediaRemark?: string | null;
};

export const MEDIA_COUNT_KEY_LIST = [
  "containerSampleBaleMediasCount",
  "containerEmptyMediasCount",
  "containerQuarterLoadedMediasCount",
  "containerHalfLoadedMediasCount",
  "containerFullLoadedMediasCount",
  "containerOneDoorClosedMediasCount",
  "containerBothDoorsClosedMediasCount",
  "containerCloseUpSealMediasCount",
  "containerExtraMediasCount",
] as const;

export const PHOTO_TYPE_LIST = [
  "sampleBale",
  "empty",
  "quarterLoaded",
  "halfLoaded",
  "fullLoaded",
  "oneDoorClosed",
  "bothDoorsClosed",
  "closeUpSeal",
  "extra",
] as const;

export const MEDIA_UPLOAD_COUNT_MAP: Record<
  (typeof MEDIA_COUNT_KEY_LIST)[number],
  string
> = {
  containerSampleBaleMediasCount: "uploadedContainerSampleBaleMediasCount",
  containerEmptyMediasCount: "uploadedContainerEmptyMediasCount",
  containerQuarterLoadedMediasCount:
    "uploadedContainerQuarterLoadedMediasCount",
  containerHalfLoadedMediasCount: "uploadedContainerHalfLoadedMediasCount",
  containerFullLoadedMediasCount: "uploadedContainerFullLoadedMediasCount",
  containerOneDoorClosedMediasCount:
    "uploadedContainerOneDoorClosedMediasCount",
  containerBothDoorsClosedMediasCount:
    "uploadedContainerBothDoorsClosedMediasCount",
  containerCloseUpSealMediasCount: "uploadedContainerCloseUpSealMediasCount",
  containerExtraMediasCount: "uploadedContainerExtraMediasCount",
} as const;

export const MEDIA_MAP: Record<(typeof MEDIA_COUNT_KEY_LIST)[number], string> =
  {
    containerSampleBaleMediasCount: "sampleBaleMediaInfoList",
    containerEmptyMediasCount: "emptyMediaInfoList",
    containerQuarterLoadedMediasCount: "quarterLoadedMediaInfoList",
    containerHalfLoadedMediasCount: "halfLoadedMediaInfoList",
    containerFullLoadedMediasCount: "fullLoadedMediaInfoList",
    containerOneDoorClosedMediasCount: "oneDoorClosedMediaInfoList",
    containerBothDoorsClosedMediasCount: "bothDoorsClosedMediaInfoList",
    containerCloseUpSealMediasCount: "closeUpSealMediaInfoList",
    containerExtraMediasCount: "extraMediaInfoList",
  };

export const MEDIA_CONTAINER_MAP: Record<
  (typeof MEDIA_COUNT_KEY_LIST)[number],
  ContainerMediaType
> = {
  containerSampleBaleMediasCount: "SAMPLE_BALE",
  containerEmptyMediasCount: "EMPTY",
  containerQuarterLoadedMediasCount: "QUARTER_LOADED",
  containerHalfLoadedMediasCount: "HALF_LOADED",
  containerFullLoadedMediasCount: "FULL_LOADED",
  containerOneDoorClosedMediasCount: "ONE_DOOR_CLOSED",
  containerBothDoorsClosedMediasCount: "BOTH_DOORS_CLOSED",
  containerCloseUpSealMediasCount: "CLOSE_UP_SEAL",
  containerExtraMediasCount: "EXTRA",
};

export const PHOTO_TYPE_CONTAINER_VALUE_TYPE_MAP: Record<
  (typeof PHOTO_TYPE_LIST)[number],
  ContainerMediaType
> = {
  sampleBale: "SAMPLE_BALE",
  empty: "EMPTY",
  quarterLoaded: "QUARTER_LOADED",
  halfLoaded: "HALF_LOADED",
  fullLoaded: "FULL_LOADED",
  oneDoorClosed: "ONE_DOOR_CLOSED",
  bothDoorsClosed: "BOTH_DOORS_CLOSED",
  closeUpSeal: "CLOSE_UP_SEAL",
  extra: "EXTRA",
};

export const PHOTO_TYPE_CONTAINER_LABEL_TYPE_MAP = (
  t: TFunction,
): Record<(typeof PHOTO_TYPE_LIST)[number], string> => ({
  sampleBale: t("task:add.photoType.sampleBale"),
  empty: t("task:add.photoType.empty"),
  quarterLoaded: t("task:add.photoType.quarterLoaded"),
  halfLoaded: t("task:add.photoType.halfLoaded"),
  fullLoaded: t("task:add.photoType.fullLoaded"),
  oneDoorClosed: t("task:add.photoType.oneDoorClosed"),
  bothDoorsClosed: t("task:add.photoType.bothDoorsClosed"),
  closeUpSeal: t("task:add.photoType.closeUpSeal"),
  extra: t("task:add.photoType.extra"),
});

export const CONTAINER_VALUE_TYPE_CONTAINER_LABEL_TYPE_MAP = (
  t: TFunction,
): Record<ContainerMediaType, string> => ({
  SAMPLE_BALE: t("task:add.photoType.sampleBale"),
  EMPTY: t("task:add.photoType.empty"),
  QUARTER_LOADED: t("task:add.photoType.quarterLoaded"),
  HALF_LOADED: t("task:add.photoType.halfLoaded"),
  FULL_LOADED: t("task:add.photoType.fullLoaded"),
  ONE_DOOR_CLOSED: t("task:add.photoType.oneDoorClosed"),
  BOTH_DOORS_CLOSED: t("task:add.photoType.bothDoorsClosed"),
  CLOSE_UP_SEAL: t("task:add.photoType.closeUpSeal"),
  EXTRA: t("task:add.photoType.extra"),
});
