import { Button } from "@/src/components/atom/Button";
import BasicCard from "@/src/components/atom/Cards/BasicCard";
import Input from "@/src/components/atom/Input";
import RadioGroup from "@/src/components/atom/RadioGroup";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import { InputError } from "@/src/components/molecule/FormItem";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { ClientType, ExporterDto } from "@/src/store/apis/auth/interface";
import { useGetStaffsQuery } from "@/src/store/apis/corporate/staffManagement";
import {
  useLinkExistedClientMutation,
  useLinkNewClientMutation,
} from "@/src/store/apis/link";
import colorSet from "@/src/styles/color";
import { RowSelectedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LinkToAccountStaffManagerColumn } from "../columns/column";

interface LinkCompanyProps {
  companyInfo?: ExporterDto;
  isNewClient: boolean;
  clientId?: number;
  linkCode: string;
  onPrevStep: () => void;
  onNextStep: () => void;
}

const LinkCompany = ({
  companyInfo,
  onPrevStep,
  isNewClient,
  clientId,
  linkCode,
  onNextStep,
}: LinkCompanyProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact | null>(null);
  const alert = useAlert();
  const [column] = useState(LinkToAccountStaffManagerColumn);
  const [staffPagination, setStaffPagination] = useState(1);
  const [selectedStaffList, setSelectedStaffList] = useState<number[]>([]);
  const [isReady, setIsReady] = useState(false);

  const [linkNewClient, { isLoading: isNewClientConnecting }] =
    useLinkNewClientMutation();
  const [linkExistedClient, { isLoading: isExistedClientConnecting }] =
    useLinkExistedClientMutation();
  const {
    setValue,
    control,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      clientType: companyInfo?.companyType,
      clientCode: companyInfo?.companyName,
    },
  });
  const { isFetching, data, isError } = useGetStaffsQuery(
    {
      isActivated: true,
      pageSize: 5,
      page: staffPagination,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.exporterUserListQueryResultDto.id;
      const keys = selectedNodesData.map(
        (item) => item.data.exporterUserListQueryResultDto.id,
      ) as number[];

      if (selectedStaffList.includes(accountKey)) {
        setSelectedStaffList((prev) => prev.filter((id) => id !== accountKey));
      } else {
        const set: any = new Set([...selectedStaffList, ...keys]);
        setSelectedStaffList([...set]);
      }
    }
  };

  const handleRowDataUpdated = () => {
    const isLiveGridRef = !!gridRef.current;

    if (isLiveGridRef) {
      gridRef.current?.api.forEachNode((rowNode) => {
        if (
          selectedStaffList.includes(
            rowNode.data.exporterUserListQueryResultDto.id,
          )
        ) {
          rowNode.setSelected(true);
        }
      });
    }
  };

  const handleConnect = async () => {
    try {
      if (!companyInfo) {
        // eslint-disable-next-line no-throw-literal
        throw {
          data: {
            message: t("alert:linkToCompanyConnectError"),
          },
        };
      }

      if (isNewClient) {
        await linkNewClient({
          exporterId: companyInfo.id,
          companyLinkCode: linkCode,
          buyerNameCode: getValues("clientCode") as string,
          companyType: getValues("clientType") as ClientType,
          exporterUserIdList: selectedStaffList,
        }).unwrap();
      } else {
        await linkExistedClient({
          exporterId: companyInfo.id,
          companyLinkCode: linkCode,
          buyerNameCode: getValues("clientCode") as string,
          companyType: getValues("clientType") as ClientType,
          buyerId: clientId as number,
          exporterUserIdList: selectedStaffList,
        }).unwrap();
      }

      onNextStep();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "linkToAccount:integrated.name",
      },
      {
        columnKey: "email",
        langKey: "linkToAccount:integrated.email",
      },
      {
        columnKey: "personalContact",
        langKey: "linkToAccount:integrated.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "linkToAccount:integrated.officeContact",
      },
    ],
  });

  return (
    <StyledMain>
      <Typo as="h1" typoType="d3" className="enter-account-code-heading">
        {t("linkToAccount:linkToAccount")}
      </Typo>

      <StyledBasicCard
        title={
          <BasicCardTitleContainer>
            {t("linkToAccount:integrated.integratedCompanyInformation")}
          </BasicCardTitleContainer>
        }
      >
        <Article>
          <DescriptionSection>
            <Typo typoType="h2">
              {t(
                "linkToAccount:integrated.pleaseConfirmTheIntegratedCompanyInformation",
              )}
            </Typo>
            <StyledTypo typoType="b7m">
              {t(
                "linkToAccount:integrated.pleaseConfirmTheIntegratedCompanyInformationDescription",
              )}
            </StyledTypo>
          </DescriptionSection>

          <ClientInfoBox>
            {[
              {
                label: t("linkToAccount:integrated.client"),
                value: <>{companyInfo?.companyName}</>,
              },
              {
                label: t("linkToAccount:integrated.clientCode"),
                value: (
                  <>
                    <Controller
                      control={control}
                      name="clientCode"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => {
                        return (
                          <Input
                            data-invalid={!!errors.clientCode}
                            onClear={() => setValue("clientCode", "")}
                            {...field}
                          />
                        );
                      }}
                    />
                    {errors.clientCode && (
                      <StyledInputError message={t("error:required")} />
                    )}
                  </>
                ),
              },
              {
                label: t("linkToAccount:integrated.clientType"),
                value: (
                  <>
                    <Controller
                      control={control}
                      name="clientType"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => {
                        return (
                          <RadioContainer>
                            <RadioGroup
                              {...field}
                              name={"radio"}
                              options={CLIENT_TYPE_OPTION_LIST.map((item) => {
                                return { ...item, label: t(item.langKey) };
                              })}
                            />
                          </RadioContainer>
                        );
                      }}
                    />
                  </>
                ),
              },
              {
                label: t("linkToAccount:integrated.country"),
                value: <>{companyInfo?.country}</>,
              },
              {
                label: t("linkToAccount:integrated.businessNumber"),
                value: <>{companyInfo?.businessNumber}</>,
              },
              {
                label: t("linkToAccount:integrated.contact"),
                value: (
                  <>
                    {companyInfo?.telPrefix} {companyInfo?.tel}
                  </>
                ),
              },
              {
                label: t("linkToAccount:integrated.address"),
                value: (
                  <>
                    {[
                      companyInfo?.postalCode,
                      companyInfo?.streetAddress,
                      companyInfo?.locality,
                      companyInfo?.region,
                      companyInfo?.countryName,
                    ]
                      .filter((adrs) => !!adrs)
                      .join(", ")}
                  </>
                ),
              },
            ].map(({ label, value }) => {
              return (
                <InfoRow>
                  <InfoLabel>
                    <Typo>{label}</Typo>
                  </InfoLabel>
                  <InfoValue>{value}</InfoValue>
                </InfoRow>
              );
            })}
          </ClientInfoBox>

          <Divider />

          <StaffListSection>
            <Typo>
              {t("linkToAccount:integrated.selectIntegrationEmployees")}{" "}
              <Typo color="red2">*</Typo>
            </Typo>

            <Table
              ref={gridRef}
              columnDefs={column}
              rowData={isFetching ? undefined : isError ? [] : data?.rows || []}
              pageSize={5}
              height={253}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              totalPage={data ? data.count : 0}
              page={staffPagination}
              handlePaginationClick={(page) => setStaffPagination(page)}
              onRowSelected={handleSelectionChanged}
              onRowDataUpdated={handleRowDataUpdated}
              onGridReady={() => setIsReady(true)}
            />
          </StaffListSection>

          <ButtonWrapper>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={onPrevStep}
            >
              {t("linkToAccount:integrated.back")}
            </StyledButton>
            <StyledButton
              disabled={
                !!errors.clientCode ||
                !watch("clientCode") ||
                isNewClientConnecting ||
                isExistedClientConnecting ||
                selectedStaffList.length === 0
              }
              isLoading={isExistedClientConnecting || isNewClientConnecting}
              onClick={handleConnect}
            >
              {t("linkToAccount:integrated.ok")}
            </StyledButton>
          </ButtonWrapper>
        </Article>
      </StyledBasicCard>
    </StyledMain>
  );
};

export default LinkCompany;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  h1.enter-account-code-heading {
    padding: 24px 0;
  }
`;

const StyledBasicCard = styled(BasicCard)`
  width: 800px;

  display: flex;
  flex-direction: column;
`;

const BasicCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const Article = styled.article`
  padding: 32px 40px 40px;
`;

const DescriptionSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: ${colorSet.gray9};
`;

const ClientInfoBox = styled.div`
  padding: 16px;
  margin-top: 32px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoLabel = styled.div`
  width: 164px;
`;
const InfoValue = styled.div`
  flex: 1;
`;

const StaffListSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-pagination-options {
    display: none;
  }
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-top: 32px;
`;

const StyledButton = styled(Button)`
  width: 236px;
  text-align: center;
`;

const StyledTypo = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
`;
