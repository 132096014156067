import CloseIconSvg from "@/src/assets/icons/icon-cancle-black.svg";
import useAlert from "@/src/hooks/useAlert";
import {
  pushAlarmsApi,
  useLazyGetPushAlarmsMergeQuery,
  useMultiplePushAlarmsReadMutation,
} from "@/src/store/apis/pushAlarms";
import {
  PushAlarmDomainType,
  PushAlarmDto,
  PushAlarmServiceType,
} from "@/src/store/apis/pushAlarms/interface";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import { isUndefined } from "@/src/utils/is";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, IconButton } from "../../atom/Button";
import Icon from "../../atom/Icon";
import Loader from "../../atom/Loader";
import { renderNoRowsComponent } from "../../atom/Table";
import Typo from "../../atom/Typo";
import TabItem from "../../molecule/TabItem";
import { NOTIFICATION_STRINGS } from "./constant";
import NotificationItem from "./NotificationItem";

interface NotificationProps {
  type: "export" | "import";
  services: PushAlarmServiceType[];
  onClose: () => void;
}

const emptyArray: PushAlarmDto[] = [];

type DomainType = keyof typeof NOTIFICATION_STRINGS.buttons;
const EXPORT_DOMAIN_LIST: {
  value: PushAlarmDomainType | "ALL";
  key: DomainType;
}[] = [
  { value: "ALL", key: "all" },
  { value: "CONTRACT", key: "contract" },
  { value: "BOOKING", key: "booking" },
  { value: "TASK", key: "loading" },
  { value: "SHIPMENT", key: "shipment" },
  { value: "SUBSCRIPTION", key: "subscription" },
  { value: "NOTICE", key: "notice" },
  { value: "INQUIRY", key: "inquiry" },
  { value: "ETC", key: "etc" },
];

const IMPORT_DOMAIN_LIST: {
  value: PushAlarmDomainType | "ALL";
  key: DomainType;
}[] = [
  { value: "ALL", key: "all" },
  { value: "CONTRACT", key: "contract" },
  { value: "SHIPMENT", key: "shipment" },
  { value: "NOTICE", key: "notice" },
  { value: "INQUIRY", key: "inquiry" },
  { value: "ETC", key: "etc" },
];

type TabType = "ALL" | "UN_READ" | "READ";
const TAB_LIST: TabType[] = ["ALL", "UN_READ", "READ"];

const INIT_PAGINATION = { page: 1, pageSize: 10 };

const Notification = ({ onClose, type, services }: NotificationProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const tabRef = useRef<HTMLButtonElement>(null);
  const domainRef = useRef<HTMLButtonElement>(null);
  const domainTabList =
    type === "export" ? EXPORT_DOMAIN_LIST : IMPORT_DOMAIN_LIST;

  const contentUlRef = useRef<HTMLUListElement>(null);
  const allListBottomDiv = useRef<HTMLDivElement>(null);
  const unReadListBottomDiv = useRef<HTMLDivElement>(null);
  const readListBottomDiv = useRef<HTMLDivElement>(null);

  const [selectTab, setSelectTab] = useState<TabType>("ALL");
  const [selectDomain, setSelectDomain] = useState<PushAlarmDomainType | "ALL">(
    "ALL"
  );
  const [pagination, setPagination] = useState(INIT_PAGINATION);
  const [isObserver, setIsObserver] = useState(false);

  // API
  const [getPushAlarmsMerge, { isFetching, alarmList }] =
    useLazyGetPushAlarmsMergeQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;

        return {
          isFetching,
          alarmList: !isUnstable
            ? (currentData?.rows ?? emptyArray)
            : emptyArray,
        };
      },
    });

  const { allTypeUnreadCount } =
    pushAlarmsApi.endpoints.getAllPushAlarms.useQueryState(
      { services },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            allTypeUnreadCount: !isUnstable ? currentData?.count : 0,
          };
        },
      }
    );

  const [multipleAlarmsUpdate] = useMultiplePushAlarmsReadMutation();

  const getPushAlarmList = useCallback(
    async (params: {
      page: number;
      pageSize: number;
      tab?: TabType;
      domainType?: PushAlarmDomainType | "ALL";
      isReset?: boolean;
    }) => {
      const { tab, domainType, ...rest } = params;

      let isRead;
      if (tab === "ALL") {
        isRead = undefined;
      }
      if (tab === "READ") {
        isRead = true;
      }
      if (tab === "UN_READ") {
        isRead = false;
      }

      const pushAlarmsParams = {
        isRead,
        domainType: domainType === "ALL" ? undefined : domainType,
        services,
        ...rest,
      };

      try {
        const res = await getPushAlarmsMerge(pushAlarmsParams).unwrap();

        if (res.rows?.length / params.page === INIT_PAGINATION.pageSize) {
          setIsObserver(true);
        } else {
          setIsObserver(false);
        }
      } catch (e) {}
    },
    [getPushAlarmsMerge, services]
  );

  // Tab Click;
  const handleTabClick = (tab: TabType) => {
    getPushAlarmList({
      tab,
      domainType: selectDomain,
      isReset: true,
      ...INIT_PAGINATION,
    });

    contentUlRef.current?.scrollTo({ top: 0 });
  };

  // Domain Click;
  const handleDomainClick = (domain: PushAlarmDomainType | "ALL") => {
    getPushAlarmList({
      tab: selectTab,
      domainType: domain,
      isReset: true,
      ...INIT_PAGINATION,
    });

    contentUlRef.current?.scrollTo({ top: 0 });
  };

  // All Read Button
  const handleAllReadButtonClick = async () => {
    const param = {
      domainType: selectDomain === "ALL" ? undefined : selectDomain,
    };

    try {
      await multipleAlarmsUpdate(param).unwrap();
      await getPushAlarmList({
        tab: selectTab,
        domainType: selectDomain,
        ...pagination,
        isReset: true,
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    }
  };
  const renderMarkAllReadButton = () => {
    let unReadCount = 0;

    if (selectTab === "ALL" || selectTab === "UN_READ") {
      unReadCount = alarmList.filter((item) => !item.isRead).length;
    }

    if (
      (selectTab === "ALL" && selectDomain === "ALL") ||
      (selectTab === "UN_READ" && selectDomain === "ALL")
    ) {
      unReadCount = allTypeUnreadCount;
    }

    if (selectTab === "READ" || unReadCount === 0) {
      return;
    }

    return (
      <MarkAllReadButtonContainer>
        <Typo typoType="b7r" color="gray5">
          {unReadCount} {t("notification:text.unread")}
        </Typo>
        <Button
          buttonColor="black"
          buttonGrade="tertiary"
          buttonSize={32}
          onClick={handleAllReadButtonClick}
        >
          {t("notification:buttons.markAllRead")}
        </Button>
      </MarkAllReadButtonContainer>
    );
  };

  const renderTabItemTitle = (type: TabType) => {
    switch (type) {
      case "ALL":
        return t("notification:tabs.all");

      case "UN_READ":
        return t("notification:tabs.unRead");

      case "READ":
        return t("notification:tabs.read");
    }
  };

  // Alarm List
  const renderBottomDiv = (type: TabType) => {
    switch (type) {
      case "ALL":
        return (
          <AllListBottomDiv ref={allListBottomDiv}>
            {isFetching && <Loader size={40} />}
          </AllListBottomDiv>
        );

      case "READ":
        return (
          <ReadListBottomDiv ref={readListBottomDiv}>
            {isFetching && <Loader size={40} />}
          </ReadListBottomDiv>
        );

      case "UN_READ":
        return (
          <UnReadListBottomDiv ref={unReadListBottomDiv}>
            {isFetching && <Loader size={40} />}
          </UnReadListBottomDiv>
        );
    }
  };
  const renderNotificationList = (type: TabType) => {
    if (alarmList.length >= 1) {
      return (
        <ListContainer>
          <StyledUl ref={contentUlRef}>
            {alarmList.map((item, idx) => {
              return (
                <NotificationItem
                  key={idx.toString()}
                  tabRef={tabRef}
                  domainRef={domainRef}
                  {...item}
                />
              );
            })}

            {renderBottomDiv(type)}
          </StyledUl>
        </ListContainer>
      );
    }

    return (
      <ListContainer>
        <NoRowsContainer>
          {isFetching ? <Loader size={40} /> : renderNoRowsComponent()}
        </NoRowsContainer>
      </ListContainer>
    );
  };

  // Scroll Fetching
  const handleScrollFetch = useCallback(
    (page: number) => {
      getPushAlarmList({
        tab: selectTab,
        domainType: selectDomain,
        page: page + 1,
        pageSize: INIT_PAGINATION.pageSize,
      });

      if (alarmList?.length / page === INIT_PAGINATION.pageSize) {
        setPagination((prev) => ({
          page: page + 1,
          pageSize: prev.pageSize,
        }));
      }
    },
    [alarmList.length, getPushAlarmList, selectDomain, selectTab]
  );

  const onObserver = useCallback(
    (entry: IntersectionObserverEntry, observer: IntersectionObserver) => {
      if (entry.isIntersecting && !isFetching) {
        observer.unobserve(entry.target);
        handleScrollFetch(pagination.page);
        observer.observe(entry.target);
      }
    },
    [handleScrollFetch, isFetching, pagination.page]
  );

  // Intersection Observer
  useEffect(() => {
    if (!isObserver) {
      return;
    }

    let observer: IntersectionObserver;

    if (selectTab === "ALL" && allListBottomDiv.current) {
      const allListObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          onObserver(entry, allListObserver);
        });
      });

      observer = allListObserver;

      allListObserver.observe(allListBottomDiv.current as HTMLDivElement);
    }

    if (selectTab === "UN_READ" && unReadListBottomDiv.current) {
      const unReadListObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          onObserver(entry, unReadListObserver);
        });
      });

      observer = unReadListObserver;

      unReadListObserver.observe(unReadListBottomDiv.current as HTMLDivElement);
    }

    if (selectTab === "READ" && readListBottomDiv.current) {
      const readListObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          onObserver(entry, readListObserver);
        });
      });

      observer = readListObserver;

      readListObserver.observe(readListBottomDiv.current as HTMLDivElement);
    }

    return () => {
      observer?.disconnect();
    };
  }, [isObserver, onObserver, selectTab]);

  // Mount Fetch
  useEffect(() => {
    getPushAlarmList({
      tab: selectTab,
      domainType: selectDomain,
      ...INIT_PAGINATION,
      isReset: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    domainRef.current?.focus();
  }, []);

  return (
    <NotificationContainer>
      {/* Title */}
      <TitleContainer>
        <Typo typoType="h4">{t("notification:title")}</Typo>
        <IconButton
          buttonSize={32}
          buttonColor="black"
          buttonGrade="tertiary"
          onClick={() => onClose()}
        >
          <Icon iconSrc={CloseIconSvg} iconSize={20} />
        </IconButton>
      </TitleContainer>

      {/* Tabs */}
      <Tabs role="tablist">
        {TAB_LIST.map((item, idx) => {
          return (
            <StyledTabItem
              key={idx.toString()}
              ref={item === selectTab ? tabRef : null}
              tabIndex={item === selectTab ? 0 : -1}
              data-selected={item === selectTab}
              tabValue={item}
              onClick={() => {
                if (item !== selectTab) {
                  setPagination(INIT_PAGINATION);
                  setSelectTab(item);
                  handleTabClick(item);
                }
              }}
              onFocusItem={(tab) => {
                if (item !== selectTab) {
                  setPagination(INIT_PAGINATION);
                  setSelectTab(item);
                  handleTabClick(tab);
                }
              }}
              onKeyDown={(e) => {
                if (e.code === "Tab") {
                  domainRef.current?.focus();
                }
              }}
            >
              <Typo
                typoType="b7m"
                color={item === selectTab ? "gray2" : "gray6"}
              >
                {renderTabItemTitle(item)}
              </Typo>
            </StyledTabItem>
          );
        })}
      </Tabs>

      {/* Content */}
      <NotificationContent>
        <CategoryContainer
          onKeyDown={(e) => {
            if (e.code === "Tab") {
              const alarm = contentUlRef.current?.children[0] as HTMLElement;

              alarm?.focus();
            }

            if (e.shiftKey && e.code === "Tab") {
              tabRef.current?.focus();
            }
          }}
        >
          <CategoryList role="tablist">
            {domainTabList.map(({ value, key }, idx) => {
              return (
                <StyledButtonItem
                  key={idx.toString()}
                  ref={value === selectDomain ? domainRef : null}
                  data-selected={value === selectDomain}
                  onClick={() => {
                    if (value !== selectDomain) {
                      setPagination(INIT_PAGINATION);
                      setSelectDomain(value);
                      handleDomainClick(value);
                    }
                  }}
                  tabIndex={value === selectDomain ? 0 : -1}
                  tabValue={value}
                  onFocusItem={(domain) => {
                    if (value !== selectDomain) {
                      setPagination(INIT_PAGINATION);
                      setSelectDomain(value);
                      handleDomainClick(domain);
                    }
                  }}
                >
                  <Typo
                    typoType="b9m"
                    color={value === selectDomain ? "blue4" : "gray7"}
                  >
                    {t(`notification:buttons.${key}`)}
                  </Typo>
                </StyledButtonItem>
              );
            })}
          </CategoryList>
          {renderMarkAllReadButton()}
        </CategoryContainer>

        {renderNotificationList(selectTab)}
      </NotificationContent>
    </NotificationContainer>
  );
};

export default Notification;

const NotificationContainer = styled.article`
  display: flex;
  flex-direction: column;
  background: ${colorSet.white};
  border-radius: 16px;
  width: 496px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 160px);
  overflow: hidden;
  padding-bottom: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 24px;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const Tabs = styled.div`
  display: flex;
  margin: 0 24px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 86px;
  padding: 10px 0;
  cursor: pointer;
  background: none;
  border: none;

  &[data-selected="true"] {
    padding: 10px 0 8px 0;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const NotificationContent = styled.section`
  background: ${colorSet.white};
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 24px 16px 24px;
  background: ${colorSet.white};
`;

const CategoryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledButtonItem = styled(TabItem)`
  padding: 8px 16px;
  border-radius: 999px;
  border: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};
  cursor: pointer;

  &[data-selected="true"] {
    border: 1px solid ${colorSet.blue4};
    background: ${colorSet.white};
  }
`;

const ListContainer = styled.div`
  height: 568px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 24px 16px;
  height: 100%;
  max-height: calc(100vh - 412px);
  background: ${colorSet.white};
  overflow: auto;
  width: 100%;

  ${StyledScroll}
`;

const MarkAllReadButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AllListBottomDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const UnReadListBottomDiv = styled(AllListBottomDiv)``;
const ReadListBottomDiv = styled(AllListBottomDiv)``;

const NoRowsContainer = styled.div`
  padding-top: 24px;
`;
