import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import { ReactComponent as MinusSvg } from "@/src/assets/icons/icon-minus.svg";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { ReactNode, useId } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { IconButton } from "../../atom/Button";
import Checkbox from "../../atom/Checkbox";

interface CheckboxCountFieldProps {
  key?: string;
  disabled?: boolean;
  isChecked?: boolean;
  isRequired?: boolean;
  count?: number;
  min?: number;
  max?: number;
  bottomAccessory?: ReactNode;
  label?: ReactNode;
  className?: string;
  onCheckChange?: (key: string, checkState: boolean) => void;
  onCountUp?: (count: number) => void;
  onCountDown?: (count: number) => void;
}

function CheckboxCountField({
  key,
  disabled = false,
  isRequired = false,
  isChecked,
  count = 0,
  min = 1,
  max = 3,
  bottomAccessory,
  label,
  className,
  onCheckChange,
  onCountDown,
  onCountUp,
}: CheckboxCountFieldProps) {
  const { t } = useTranslation();
  const id = useId();
  const isCountOverMaxCount = count >= max;
  const isCountUnderMinCount = count <= min;

  const handleLabelClick = () => {
    if (!disabled) {
      onCheckChange?.(key || id, !isChecked); // Checkbox 상태 반전
    }
  };

  return (
    <FieldArticle
      data-checked={!!isChecked}
      data-disabled={disabled}
      className={className}
    >
      <FieldContent>
        <FieldLabelContent data-disabled={disabled}>
          <Checkbox
            checked={!!isChecked}
            disabled={disabled}
            data-disabled={disabled}
            onChange={(e) => {
              onCheckChange?.(key || id, e.target.checked);
            }}
          />
          <p className="field-label" onClick={handleLabelClick}>
            {label || t("common:empty")}
          </p>
        </FieldLabelContent>
        <FieldCountContainer>
          <StyledIconButton
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={32}
            onClick={() => onCountDown?.(count)}
            disabled={
              ((disabled || !isChecked) && !isRequired) || isCountUnderMinCount
            }
          >
            <MinusIcon
              data-disabled={
                ((disabled || !isChecked) && !isRequired) ||
                isCountUnderMinCount
              }
            />
          </StyledIconButton>

          <CountContainer
            data-disabled={(disabled || !isChecked) && !isRequired}
          >
            {count}
          </CountContainer>

          <StyledIconButton
            buttonColor="black"
            buttonGrade="tertiary"
            buttonSize={32}
            onClick={() => onCountUp?.(count)}
            disabled={
              ((disabled || !isChecked) && !isRequired) || isCountOverMaxCount
            }
          >
            <AddIcon
              data-disabled={
                ((disabled || !isChecked) && !isRequired) || isCountOverMaxCount
              }
            />
          </StyledIconButton>
        </FieldCountContainer>
      </FieldContent>
      {bottomAccessory && (
        <BottomAccessoryContainer>{bottomAccessory}</BottomAccessoryContainer>
      )}
    </FieldArticle>
  );
}

export default CheckboxCountField;

const FieldArticle = styled.article`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;

  &[data-checked="true"] {
    background: ${colorSet.blue10};
  }
  &[data-checked="false"] {
    background: ${colorSet.gray11};
  }
`;

const FieldContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldLabelContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.b7r};
  color: ${colorSet.gray2};
  flex: 1;
  min-width: 0;

  p.field-label {
    flex: 1;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  &[data-disabled="true"] {
    color: ${colorSet.gray7};
  }
`;

const FieldCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CountContainer = styled.div`
  padding: 8px 0px;
  width: 50px;
  text-align: center;
  background: ${colorSet.white};
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  ${typo.b9r};
  color: ${colorSet.gray2};
  transition: all 0.15s;

  &[data-disabled="true"] {
    color: ${colorSet.gray7};
    border: 1px solid ${colorSet.gray8};
    background: ${colorSet.gray10};
  }
`;

const StyledIconButton = styled(IconButton)`
  &:disabled {
    background: ${colorSet.gray10};
    box-shadow:
      0px 1px 2px 0px rgba(5, 29, 57, 0.1),
      0px 0px 0px 0.5px rgba(160, 164, 171, 0.2);
  }
`;

const BottomAccessoryContainer = styled.div`
  width: 100%;
`;

const MinusIcon = styled(MinusSvg)`
  width: 20px;
  height: 20px;

  &[data-disabled="true"] {
    rect {
      fill: ${colorSet.gray8};
    }
  }
`;

const AddIcon = styled(AddSvg)`
  width: 20px;
  height: 20px;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
