import {
  CorporationSignUpFormContext,
  CorporationStepContext,
  SignUpButtonStatusContext,
} from "@/pages/public/CorporateSignup";
import { isUndefined } from "@/src/utils/is";
import { useContext } from "react";

export const useCorporationSignUpFormContext = () => {
  const context = useContext(CorporationSignUpFormContext);

  if (isUndefined(context)) {
    throw Error("Signup Form Context가 존재 하지 않습니다.");
  }

  return context;
};

export const useCorporationStepContext = () => {
  const context = useContext(CorporationStepContext);
  if (isUndefined(context)) {
    throw Error("Signup Step Context가 존재 하지 않습니다.");
  }
  return context;
};

export const useCorporationSignUpButtonStatusContext = () => {
  const context = useContext(SignUpButtonStatusContext);

  if (isUndefined(context)) {
    throw Error("SignUp Button Context가 존재 하지 않습니다.");
  }

  return context;
};
