import DownloadBlackSvg from "@/src/assets/icons/icon-download-black.svg";
import DownloadBlueSvg from "@/src/assets/icons/icon-download-blue.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import PreviewBlackSvg from "@/src/assets/icons/icon-preview-black.svg";
import PreviewBlueSvg from "@/src/assets/icons/icon-preview-blue.svg";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import { Button, IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import renderContractStatusBadge from "@/src/pages/exporter/Contract/utils/renderContractStatusBadge";
import {
  ContractDetailViewForImporterDto,
  ContractSignature,
} from "@/src/store/apis/contracts/contractDetail/interface";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import typo from "@/src/styles/typography";
import { downloadFile } from "@/src/utils/downloadFile";
import { isNull, isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { SHARED_SEARCH_PARAMS_STRINGS } from "../../constants";
import {
  poDownload,
  poPreview,
} from "@/src/components/template/pdfs/v2/Po/PoContent";
import { PoData } from "@/src/components/template/pdfs/v2/Po/types";
import { useRef, useState, useEffect, useMemo } from "react";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";

type ImportContractItemDataType = ContractDetailViewForImporterDto &
  ContractDetailShareInfoDto;

interface ImportContractItemProps {
  contractData: ImportContractItemDataType;
  signatureData: ContractSignature[];
  isError?: boolean;
  isSignatureDataFetching: boolean;
}

function ImportContractItem({
  contractData,
  signatureData,
  isError,
  isSignatureDataFetching,
}: ImportContractItemProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isPreview =
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.preview) === "true";

  // PO PDF
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const [poData, setPoData] = useState<PoData>({
    poNumber: "",
    componyLogoUrl: "",
    sellerCorporation: "",
    sellerContact: "",
    sellerContactPrefix: "",
    sellerFax: "",
    sellerFaxPrefix: "",
    sellerCountryName: "",
    sellerRegion: "",
    sellerPostalCode: "",
    sellerLocality: "",
    sellerStreetAddress: "",
    sellerContactPersonName: "",
    buyerCorporation: "",
    buyerContactPrefix: "",
    buyerContact: "",
    buyerFaxPrefix: "",
    buyerFax: "",
    buyerCountryName: "",
    buyerRegion: "",
    buyerPostalCode: "",
    buyerLocality: "",
    buyerStreetAddress: "",
    orderDate: "",
    paymentTerm: "",
    paymentTermsRemark: "",
    originLocation: "",
    shippingTerm: "",
    shippingTermRemark: "",
    lastShipmentDate: "",
    deliveryDate: "",
    quantityPrefix: "",
    quantity: "",
    description: "",
    hsCode: "",
    unitPricePrefix: "",
    unitPrice: "",
    amount: "",
    remark: "",
    isAddContractTerms: false,
    contractTermsTitle: "",
    contractTerms: "",
    sellerSignatureUrl: "",
    buyerSignatureUrl: "",
  });

  const { businessLogoSimpleMediaUrl, buyer } = useGetExportersQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      skip: !isPreview,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          buyer: currentData?.exporterDetailQueryResultDto,
          businessLogoSimpleMediaUrl: isStable
            ? currentData.basicInformation.businessLogoSimpleMedia.mediaUrl
            : "",
        };
      },
    }
  );

  const commonData = isPreview ? contractData : contractData?.importerInfo;
  const sharedData = useMemo(
    () => ({
      ...commonData,
      sellerCorporation: isPreview
        ? contractData?.buyerName || ""
        : contractData?.importerInfo.buyerName || "",
      sellerContact: isPreview
        ? contractData?.buyerTel || ""
        : contractData?.importerInfo.buyerTel || "",
      sellerContactPrefix: isPreview
        ? contractData?.buyerTelPrefix || ""
        : contractData?.importerInfo.buyerTelPrefix || "",
      sellerFax: isPreview
        ? contractData?.buyerFax || ""
        : contractData?.importerInfo.buyerFax || "",
      sellerFaxPrefix: isPreview
        ? contractData?.buyerFaxPrefix || ""
        : contractData?.importerInfo.buyerFaxPrefix || "",
      sellerCountryName: isPreview
        ? contractData?.buyerCountryName || ""
        : contractData?.importerInfo.buyerCountryName || "",
      sellerRegion: isPreview
        ? contractData?.buyerRegion || ""
        : contractData?.importerInfo.buyerRegion || "",
      sellerPostalCode: isPreview
        ? contractData?.buyerPostalCode || ""
        : contractData?.importerInfo.buyerPostalCode || "",
      sellerLocality: isPreview
        ? contractData?.buyerLocality || ""
        : contractData?.importerInfo.buyerLocality || "",
      sellerStreetAddress: isPreview
        ? contractData?.buyerStreetAddress || ""
        : contractData?.importerInfo.buyerStreetAddress || "",

      buyerCorporation: isPreview
        ? contractData?.companyName || ""
        : contractData?.importerInfo.companyName || "",
      buyerContact: isPreview
        ? buyer?.tel || ""
        : contractData?.importerInfo.tel || "",
      buyerContactPrefix: isPreview
        ? buyer?.telPrefix || ""
        : contractData?.importerInfo.telPrefix || "",
      buyerFax: isPreview
        ? buyer?.fax || ""
        : contractData?.importerInfo.fax || "",
      buyerFaxPrefix: isPreview
        ? buyer?.faxPrefix || ""
        : contractData?.importerInfo.faxPrefix || "",
      buyerCountryName: isPreview
        ? buyer?.countryName || ""
        : contractData?.importerInfo.countryName || "",
      buyerRegion: isPreview
        ? buyer?.region || ""
        : contractData?.importerInfo.region || "",
      buyerPostalCode: isPreview
        ? buyer?.postalCode || ""
        : contractData?.importerInfo.postalCode || "",
      buyerLocality: isPreview
        ? buyer?.locality || ""
        : contractData?.importerInfo.locality || "",
      buyerStreetAddress: isPreview
        ? buyer?.streetAddress || ""
        : contractData?.importerInfo.streetAddress || "",

      description: isPreview
        ? contractData?.importerItem || ""
        : contractData?.importerInfo?.importerItem || "",
      componyLogoUrl: isPreview
        ? businessLogoSimpleMediaUrl
        : contractData?.importerInfo?.businessLogoSimpleMedia?.mediaUrl || "",
      buyerName: isPreview
        ? contractData?.buyerName || ""
        : contractData?.importerInfo?.companyName || "",
      orderDateAt: isPreview
        ? contractData?.importerOrderDateAt || ""
        : contractData?.importerInfo?.importerOrderDateAt || "",
    }),
    [commonData, isPreview, contractData, buyer, businessLogoSimpleMediaUrl]
  );

  useEffect(() => {
    if (!isSignatureDataFetching && sharedData) {
      setPoData({
        poNumber: sharedData?.poNo || "",
        componyLogoUrl: sharedData?.componyLogoUrl || "",
        sellerCorporation: sharedData?.sellerCorporation || "",
        sellerContact: sharedData?.sellerContact || "",
        sellerContactPrefix: sharedData?.sellerContactPrefix || "",
        sellerFax: sharedData?.sellerFax || "",
        sellerFaxPrefix: sharedData?.sellerFaxPrefix || "",
        sellerCountryName: sharedData?.sellerCountryName || "",
        sellerRegion: sharedData?.sellerRegion || "",
        sellerPostalCode: sharedData?.sellerPostalCode || "",
        sellerLocality: sharedData?.sellerLocality || "",
        sellerStreetAddress: sharedData?.sellerStreetAddress || "",
        sellerContactPersonName: "",

        buyerCorporation: sharedData?.buyerCorporation || "",
        buyerContactPrefix: sharedData?.buyerContactPrefix || "",
        buyerContact: sharedData?.buyerContact || "",
        buyerFaxPrefix: sharedData?.buyerFaxPrefix || "",
        buyerFax: sharedData?.buyerFax || "",
        buyerCountryName: sharedData?.buyerCountryName || "",
        buyerRegion: sharedData?.buyerRegion || "",
        buyerPostalCode: sharedData?.buyerPostalCode || "",
        buyerLocality: sharedData?.buyerLocality || "",
        buyerStreetAddress: sharedData?.buyerStreetAddress || "",
        orderDate: sharedData?.orderDateAt
          ? dayjs(sharedData?.orderDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )
          : "",
        paymentTerm: sharedData?.paymentTerms || "",
        paymentTermsRemark: sharedData?.paymentTermsRemark || "",
        originLocation: sharedData?.origin || "",
        shippingTerm: sharedData?.shippingTerms || "",
        shippingTermRemark: sharedData?.shippingTermsRemark || "",
        lastShipmentDate: sharedData?.lastShipmentDateAt
          ? dayjs(sharedData?.lastShipmentDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )
          : "",
        deliveryDate: sharedData?.deliveryDateAt
          ? dayjs(sharedData?.deliveryDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )
          : "",
        quantityPrefix: sharedData?.quantityUnit || "",
        quantity: sharedData?.quantity
          ? Number(
              sharedData?.quantity.toString().replace(/[,]/g, "")
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
        description: sharedData?.description || "",
        hsCode: sharedData?.hsCode || "",
        unitPricePrefix: sharedData?.unitPriceUnit || "",
        unitPrice: sharedData?.unitPrice
          ? Number(
              sharedData?.unitPrice.toString().replace(/[,]/g, "")
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
        amount:
          (sharedData?.quantity &&
            sharedData?.unitPrice &&
            Number(
              (
                (parseFloat(
                  sharedData?.quantity.toString().replace(/,/g, "")
                ) || 0) *
                (parseFloat(
                  sharedData?.unitPrice.toString().replace(/,/g, "")
                ) || 0)
              ).toFixed(2)
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })) ||
          "",
        remark: sharedData?.importerContractRemark || "",
        isAddContractTerms: false,
        contractTermsTitle: "",
        contractTerms: "",
        sellerSignatureUrl:
          signatureData[0]?.exporterPOSignMedia?.mediaUrl || "",
        buyerSignatureUrl:
          signatureData[0]?.importerPOSignMedia?.mediaUrl || "",
      });
    }
  }, [sharedData, signatureData, isSignatureDataFetching]);

  const handlePreview = () => {
    if (!isSignatureDataFetching) {
      poPreview(poData);
    }
  };

  const handleDownload = () => {
    if (!isSignatureDataFetching) {
      poDownload(poData, `${t("common:poNo")}${contractData?.poNo}.pdf`);
    }
  };

  const renderBadgeContainer = () => {
    return (
      <HeaderContainer>
        {sharedData.contractStatus &&
          renderContractStatusBadge({
            t,
            type: sharedData.contractStatus as ContractStatusType,
            size: "L",
          })}

        {!sharedData.scSimpleMedia?.id && (
          <PdfButtonContainer>
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={handleDownload}
              disabled={isError || isPreviewLoading || isSignatureDataFetching}
            >
              <Icon iconSrc={DownloadBlackSvg} iconSize={20} />
              {t("shareEmail:sharedContract.buttons.download")}
            </StyledButton>

            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              ref={previewButtonRef}
              onClick={() => {
                setIsPreviewLoading(true);
                setTimeout(() => {
                  if (previewButtonRef.current) {
                    previewButtonRef.current.click();
                    setIsPreviewLoading(false);
                  }
                }, 1500);
                handlePreview();
              }}
              isLoading={isPreviewLoading || isSignatureDataFetching}
              disabled={isError || isPreviewLoading || isSignatureDataFetching}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Icon iconSrc={PreviewBlackSvg} iconSize={20} />
              {t("shareEmail:sharedContract.buttons.preview")}
            </StyledButton>
          </PdfButtonContainer>
        )}
      </HeaderContainer>
    );
  };

  const renderContractNoneTitleCard = () => {
    return (
      <SectionCardGrid>
        <StyledSectionCardColumnSix
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:seller")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {poData.sellerCorporation ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:shippingTerms")}
            </Typo>
          }
          direction="vertical"
          value={
            <ShippingTermsContainer>
              <Typo typoType="h4">{`${sharedData.shippingTerms ?? "-"}`}</Typo>
              {sharedData.shippingTermsRemark && (
                <BreakWordTypo typoType="h4">{`${sharedData.shippingTermsRemark}`}</BreakWordTypo>
              )}
            </ShippingTermsContainer>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:sellerContact")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {sharedData.buyerEmployeeName ?? "-"}
            </BreakWordTypo>
          }
        />

        <Divider />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:item")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {sharedData.importerItem ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:qty")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              sharedData.quantity && sharedData.quantityUnit
                ? `${Number(sharedData.quantity).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${sharedData.quantityUnit}`
                : "-"
            } `}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:unitPrice")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              sharedData.unitPrice && sharedData.unitPriceUnit
                ? `${Number(sharedData.unitPrice).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${sharedData.unitPriceUnit}`
                : "-"
            }`}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              {t("common:amount")}
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {`${
                sharedData.quantity && sharedData.unitPrice
                  ? `${parseFloat(
                      (
                        Number(sharedData.quantity) *
                        Number(sharedData.unitPrice)
                      ).toFixed(2)
                    ).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${sharedData.unitPriceUnit}`
                  : "-"
              }`}
            </BreakWordTypo>
          }
        />
      </SectionCardGrid>
    );
  };

  const renderContractInformationCard = () => {
    return (
      <SectionCard
        cardTitle={t("contract:exporter.detail.contractInformationCard.title")}
      >
        <ContractInformationFlexColumn>
          {/* Card */}
          <ContractDateContainer>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:paymentTerms")}
              </Typo>
              <PaymentTermsContainer>
                <BreakWordTypo color="blue2" typoType="h4">
                  {sharedData.paymentTerms
                    ? `${sharedData.paymentTerms} ${
                        sharedData.paymentTermsRemark
                          ? sharedData.paymentTermsRemark
                          : ""
                      }`
                    : "-"}
                </BreakWordTypo>
              </PaymentTermsContainer>
            </ContractDateCard>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:orderDate")}
              </Typo>
              <BreakWordTypo color="blue2" typoType="h4">
                {sharedData.orderDateAt
                  ? dayjs.utc(sharedData.orderDateAt).format("YYYY-MM-DD")
                  : "-"}
              </BreakWordTypo>
            </ContractDateCard>

            {sharedData.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  {t("common:expireDate")}
                </Typo>
                <Typo color="blue2" typoType="h4">
                  {sharedData.expireDateAt
                    ? dayjs.utc(sharedData.expireDateAt).format("YYYY-MM-DD")
                    : "-"}
                </Typo>
              </ContractDateCard>
            )}

            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:lastShipmentDate")}
              </Typo>
              <Typo color="blue2" typoType="h4">
                {sharedData.lastShipmentDateAt
                  ? dayjs
                      .utc(sharedData.lastShipmentDateAt)
                      .format("YYYY-MM-DD")
                  : "-"}
              </Typo>
            </ContractDateCard>

            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                {t("common:deliveryDate")}
              </Typo>
              <Typo color="blue2" typoType="h4">
                {sharedData.deliveryDateAt
                  ? dayjs.utc(sharedData.deliveryDateAt).format("YYYY-MM-DD")
                  : "-"}
              </Typo>
            </ContractDateCard>
          </ContractDateContainer>

          <ContractInformationGridContainer>
            <ContractInformationGridItem>
              {/* Location */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:location")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:placeOfDelivery")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.placeOfDelivery ??
                          sharedData.anyPortIn ??
                          "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={t("common:originLocation")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.origin ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  {sharedData.allocation && (
                    <SectionCardRow
                      label={t("common:allocation")}
                      value={
                        <BreakWordTypo typoType="b7r" color="gray5" as="p">
                          {sharedData.allocation ?? "-"}
                        </BreakWordTypo>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>

              {/* Attachment File */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:attachmentFile")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:poNo")}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {sharedData.poNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.poSimpleMedia)}
                          >
                            {isNull(sharedData.poSimpleMedia) ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    sharedData.poSimpleMedia?.mediaUrl ?? "",
                                    sharedData.poSimpleMedia
                                      ?.originalFileName ?? ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.poSimpleMedia)}
                          >
                            {isNull(sharedData.poSimpleMedia) ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={sharedData.poSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  <SectionCardRow
                    label={t("common:scNo")}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {sharedData.scNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.scSimpleMedia)}
                          >
                            {!sharedData.scSimpleMedia ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    sharedData.scSimpleMedia?.mediaUrl ?? "",
                                    sharedData.scSimpleMedia
                                      ?.originalFileName ?? ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.scSimpleMedia)}
                          >
                            {!sharedData.scSimpleMedia ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={sharedData.scSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  {sharedData.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                    <SectionCardRow
                      label={t("common:lcNo")}
                      value={
                        <FileInfoContainer>
                          <BreakWordTypo typoType="b7r" color="gray5">
                            {sharedData.lcNo ?? "-"}
                          </BreakWordTypo>
                          <div>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                sharedData.paymentTermsSimpleMedia
                              )}
                            >
                              {sharedData.paymentTermsSimpleMedia === null ? (
                                <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                              ) : (
                                <Icon
                                  iconSrc={DownloadBlueSvg}
                                  iconSize={16}
                                  onClick={() =>
                                    downloadFile(
                                      sharedData.paymentTermsSimpleMedia
                                        ?.mediaUrl ?? "",
                                      sharedData.paymentTermsSimpleMedia
                                        ?.originalFileName ?? ""
                                    )
                                  }
                                />
                              )}
                            </IconButton>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                sharedData.paymentTermsSimpleMedia
                              )}
                            >
                              {sharedData.paymentTermsSimpleMedia === null ? (
                                <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                              ) : (
                                <a
                                  href={
                                    sharedData.paymentTermsSimpleMedia?.mediaUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon
                                    iconSrc={PreviewBlueSvg}
                                    iconSize={16}
                                  />
                                </a>
                              )}
                            </IconButton>
                          </div>
                        </FileInfoContainer>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>

            <GrayLine />

            <ContractInformationGridItem>
              {/* ETC */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:etc")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:hsCode")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.importerHsCode ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={t("common:freetime")}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.freetime ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                </section>
              </ContractInformationRow>

              {/* Optional */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  {t("common:optional")}
                </Typo>
                <section>
                  <SectionCardRow
                    label={t("common:contractTerm")}
                    value={
                      sharedData.isAddContractTerms
                        ? t("common:included")
                        : t("common:notIncluded")
                    }
                  />
                  <SectionCardRow
                    label={t("common:bankDetails")}
                    value={
                      sharedData.isAddBankDetail
                        ? t("common:included")
                        : t("common:notIncluded")
                    }
                  />
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>
          </ContractInformationGridContainer>
        </ContractInformationFlexColumn>
      </SectionCard>
    );
  };

  const renderRemarkCard = () => {
    return (
      <SectionCard cardTitle={t("common:remark")}>
        <RemarkContainer>
          <SectionCardRow
            label={
              <Typo color="gray7" typoType="b9m">
                {t("common:contractRemark")}
              </Typo>
            }
            direction="vertical"
            value={
              <ReadOnlyText>
                {sharedData.importerContractRemark ?? "-"}
              </ReadOnlyText>
            }
          />
        </RemarkContainer>
      </SectionCard>
    );
  };

  const renderContractContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          {t("common:purchaseOrderNo")} {sharedData.poNo ?? "-"}
        </PageTitle>

        <FlexColumn>
          {/* header */}
          {renderBadgeContainer()}
          {/* ContractNoneTitleCard */}
          {renderContractNoneTitleCard()}
          {/* Contract Information */}
          {renderContractInformationCard()}
          {/* Remark */}
          {renderRemarkCard()}
        </FlexColumn>
      </>
    );
  };

  return renderContractContent();
}

export default ImportContractItem;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PdfButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardColumnSix = styled(SectionCardRow)`
  grid-column: 6 span;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;
`;

const GrayLine = styled.div`
  border-right: 1px solid ${colorSet.gray9};
`;

const ShippingTermsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const ContractInformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ContractDateCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ContractDateContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${colorSet.gray11};
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;

const ContractInformationFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const ContractInformationGridContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const ContractInformationGridItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RemarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 182px;
  ${StyledScroll}
`;

const Divider = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
