import colorSet, { ColorType } from "@/src/styles/color";
import typo, { FontSizeType } from "@/src/styles/typography";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

export interface TypoProps {
  typoType?: FontSizeType;
  color?: ColorType;
  as?: keyof HTMLElementTagNameMap;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  ellipsis?: boolean;
}

const Typo = ({
  typoType = "b7m",
  as = "span",
  color = "gray2",
  className,
  children,
  onClick,
  ellipsis = false,
  ...rest
}: TypoProps) => {
  return (
    <Component
      as={as}
      $typoType={typoType}
      $typoColor={color}
      $ellipsis={ellipsis}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Typo;

const Component = styled.span<{
  $typoType: FontSizeType;
  $typoColor: ColorType;
  $ellipsis: boolean;
}>`
  ${({ $typoType }) => typo[$typoType]};
  color: ${({ $typoColor }) => colorSet[$typoColor]};

  /* $ellipsis 스타일 적용 */
  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
