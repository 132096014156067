import AddItemDialog, {
  AddItemType,
} from "@/components/organism/AddItemDialog";
import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import AddBlueSvg from "@/src/assets/icons/icon-add-blue.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import SelectOptionButton from "@/src/components/atom/Button/SelectOptionButton";
import Checkbox from "@/src/components/atom/Checkbox";
import DatePicker from "@/src/components/atom/DatePicker";
import FileComponent from "@/src/components/atom/File";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import Label from "@/src/components/atom/Label";
import Loader from "@/src/components/atom/Loader";
import RadioGroup from "@/src/components/atom/RadioGroup";
import Select from "@/src/components/atom/Select";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import Flex from "@/src/components/molecule/Flex";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import NoRegisteredSignatureAlertDialog from "@/src/components/organism/NoRegisteredSignatureAlertDialog";
import ReferenceImageDialog from "@/src/components/organism/ReferenceImageDialog";
import RegisterCompanySignDialog from "@/src/components/organism/RegisterCompanySignDialog";
import SignatureRequestAlertDialog from "@/src/components/organism/SignatureRequestAlertDialog";
import TermsAddDialog from "@/src/components/organism/TermsAddDialog";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import PoPreview from "@/src/components/template/pdfs/Po/Preview";
import getPoPdfData from "@/src/components/template/pdfs/Po/utils/getPoPdfData";
import { DIRECT_INPUT_LABEL, DIRECT_INPUT_VALUE } from "@/src/constant";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCreateBuyerEmployeesMutation,
  useGetBuyersQuery,
  useLazyGetBuyerEmployeesQuery,
} from "@/src/store/apis/client/buyer";
import { GenerateBuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import {
  useGetCommonCodeListQuery,
  useGetCommonCodeViaCodeNameQuery,
} from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useEditImporterContractMutation } from "@/src/store/apis/contracts";
import {
  useLazyGetImporterContractDetailQuery,
  useLazyGetImporterContractSignatureHistoriesQuery,
} from "@/src/store/apis/contracts/contractDetail";
import { ContractDetailViewForImporterDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { GenerateContractV2Dto } from "@/src/store/apis/contracts/interface";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import {
  useCreateSingleItemCodeMutation,
  useLazyGetNonFilteredItemCodesQuery,
} from "@/src/store/apis/corporate/companyItemManagement";
import { ExporterItemCodeListViewV2Dto } from "@/src/store/apis/corporate/companyItemManagement/interface";
import { ErrorResponse } from "@/src/store/apis/type";
import colorSet from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import { commonCodeMapper } from "@/src/utils/commonCodeHelper";
import { isNull, isUndefined } from "@/src/utils/is";
import { transformValueOrNull } from "@/src/utils/transform";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { GroupType } from "types";
import BuyerEmployeeAddDialog from "../ClientManagement/dialog/BuyerEmployeeAddDialog";
import { ContractAddFormType, OptionType } from "./types/contract.type.js";
import { contractFormDefaultValue } from "./utils/defaultValue";
import {
  getIntegerWithDecimal,
  handleIntegerWithDecimalsChange,
} from "./utils/getIntegerWithDecimal";
import SignatureSelectDialog from "@/src/components/organism/SignatureSelectDialog";

enum DialogState {
  NULL,
  IMAGE,
  ADD_ITEM,
  ADD_CONTACT_PERSON,
  CREATE_TERMS,
  REGISTER_SIGN,
}

enum AlertDialogState {
  NULL,
  BACK_TO_PREVIOUS,
  SEND_TO_BUYER,
  REGISTER_SIGN,
  SIGNATURE_REQUEST,
  SIGNATURE_SELECT_PO,
}

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ImporterContractEditPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const originalContractBuyerId = useRef<number>();
  const [memoList, setMemoList] = useState<
    { memo: string; writerName: string; wroteAt: string }[]
  >([]);
  const [selectImage, setSelectImage] = useState("");

  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  const exportersData = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const isCompanyTerms =
    !!exportersData.currentData?.termsOfSalesContract
      .salesContractTermsWithLatestHistory;

  const isTwoSign =
    exportersData.currentData?.signatureManagement
      ?.simpleExporterSignatureMedias?.length === 2;

  const defaultSignatureMediaId =
    exportersData?.currentData?.signatureManagement?.simpleExporterSignatureMedias?.find(
      (item) => item.isDefault,
    )?.signatureSimpleMedia.id as number;

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    clearErrors,
    reset,
    setFocus,
    setError,
  } = useForm<ContractAddFormType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      ...contractFormDefaultValue,
      importerOrderDateAt: undefined,
      isAddContractTerms: isCompanyTerms ? ["true"] : [],
    },
  });
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL,
  );
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isSendPoToBuyer, setIsSendPoToBuyer] = useState(true);

  // PO PDF
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const previewPoPdfResourceRef = useRef<ContractAddFormType | null>(null);

  const [getContractDetail, contractDetail] =
    useLazyGetImporterContractDetailQuery();

  const [editContractDetail] = useEditImporterContractMutation();
  const [createEmployee] = useCreateBuyerEmployeesMutation();
  const [createSingleItemCode] = useCreateSingleItemCodeMutation();
  const { buyerList, isBuyersFetching } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          buyerList: !isUnstable ? currentData.data : [],
          isBuyersFetching: isFetching,
        };
      },
    },
  );
  const [getBuyerEmployees, buyerEmployeesResult] =
    useLazyGetBuyerEmployeesQuery();
  const [getItems, { itemsList, isItemsFetching }] =
    useLazyGetNonFilteredItemCodesQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const isStable = !isUnstable;

        return {
          itemsList: isStable ? currentData.rows : [],
          isItemsFetching: isFetching,
        };
      },
    });
  const { userMainCategory, exporterUserType } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined =
        isError || isFetching || currentData === undefined;

      const isManager =
        currentData?.row.exporterUserType === "MANAGER" ||
        currentData?.row.exporterUserType === "MIDDLE_MANAGER";

      return {
        userMainCategory: !isErrorAndUndefined
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : [],

        exporterUserType: currentData?.row.exporterUserType,
      };
    },
  });
  const { isCommonCodeFetching, commonCodeCurrentData } =
    useGetCommonCodeListQuery(
      {
        codeNameList: [
          { codeName: "CURRENCY" },
          { codeName: "FREETIME" },
          { codeName: "MEASUREMENT" },
          { codeName: "PAYMENT_TERMS" },
          { codeName: "PLACE_OF_DELIVERY" },
          { codeName: "SHIPPING_TERMS", sort: "codeItemNameEn", order: "ASC" },
          { codeName: "COUNTRY" },
        ],
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ isFetching, currentData }) => {
          return {
            isCommonCodeFetching: isFetching,
            commonCodeCurrentData: currentData ?? [],
          };
        },
      },
    );

  const { mainCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          mainCategoryList: isStable
            ? (currentData ?? mainCategoryEmptyArray)
            : mainCategoryEmptyArray,
        };
      },
    },
  );

  const [
    currencyList = [],
    freeTimeList = [],
    measurementList = [],
    paymentTermsList = [],
    placeOfDeliveryList = [],
    shippingTermsList = [],
    countryList = [],
  ] = commonCodeCurrentData;

  const [getContractSignatureHistories, { isAllSignedBefore }] =
    useLazyGetImporterContractSignatureHistoriesQuery({
      selectFromResult: ({ currentData }) => {
        return {
          isAllSignedBefore: currentData?.rows
            ? currentData.rows.some(
                ({
                  isExporterSignedPO,
                  isExporterSignedSC,
                  isImporterSignedPO,
                  isImporterSignedSC,
                }) =>
                  isExporterSignedSC &&
                  isImporterSignedSC &&
                  isExporterSignedPO &&
                  isImporterSignedPO,
              )
            : false,
        };
      },
    });

  const isContractLinked =
    contractDetail.currentData?.signatureStatus === "LINKED" ||
    isAllSignedBefore;

  const isPending =
    contractDetail.currentData?.contractStatus === "NOT_LINKED" &&
    contractDetail.currentData?.signatureStatus === "PENDING";

  const buyerToOptionList = buyerList
    .filter(
      ({ isActivated, buyerListQueryResultDto, mainCategoryCodeItemNames }) =>
        (isActivated &&
          mainCategoryCodeItemNames.some((mainCategory) =>
            userMainCategory.includes(mainCategory),
          )) ||
        contractDetail.currentData?.importerBuyerId ===
          buyerListQueryResultDto.id,
    )
    .reduce<OptionType[]>((acc, val) => {
      const resource = {
        label: val.buyerName,
        value: val.buyerListQueryResultDto.id.toString(),
      };
      return [...acc, resource];
    }, []);

  const buyerListToOptionList = buyerEmployeesResult.isError
    ? []
    : buyerEmployeesResult.currentData?.rows
        .filter(
          ({ isActivated, id }) =>
            isActivated ||
            contractDetail.currentData?.importerBuyerEmployeeId === id,
        )
        .reduce<OptionType[]>((acc, val) => {
          const resource = {
            label: val.name,
            value: val.id.toString(),
          };
          return [...acc, resource];
        }, []);

  const itemListToGroupedOptionList = itemsList
    .filter(({ isActivated, id, mainCategoryCodeItemName }) => {
      const buyerMainCategory =
        buyerList.find(({ id }) => watch("importerBuyerId") === id.toString())
          ?.mainCategoryCodeItemNames ?? [];

      return (
        (isActivated &&
          userMainCategory.includes(mainCategoryCodeItemName) &&
          buyerMainCategory.includes(mainCategoryCodeItemName)) ||
        (contractDetail.currentData?.importerItemCodeId === id &&
          String(originalContractBuyerId.current || 0) ===
            watch("importerBuyerId"))
      );
    })
    .reduce((acc: GroupType[], item: ExporterItemCodeListViewV2Dto) => {
      const groupLabel = `${item.mainCategory} > ${item.subCategory}`;

      // 그룹이 이미 존재하는지 확인
      let group = acc.find((g) => g.label === groupLabel);
      if (!group) {
        group = { label: groupLabel, options: [] };
        acc.push(group);
      }

      // 옵션 레이블 생성
      const isItemCodeSameAsItem = item.item === item.itemCode;
      const optionLabel = isItemCodeSameAsItem
        ? `${item.item}`
        : `${item.item}(${item.itemCode})`;

      // value 필드 수정
      const optionValue = `${item.id}_${item.exporterItemId}`;

      // 옵션 추가
      group.options.push({
        label: optionLabel,
        value: optionValue,
      });

      return acc;
    }, []);

  const mesurementListToOptionList = measurementList
    ? measurementList.reduce<OptionType[]>(commonCodeMapper, [])
    : [];

  const currencyListToOptionList = currencyList
    ? currencyList.reduce<OptionType[]>(commonCodeMapper, [])
    : [];

  const freetimeListToOptionList = freeTimeList
    ? freeTimeList.reduce<OptionType[]>(commonCodeMapper, [])
    : [];

  const placeOfDeliveryListToOptionList = placeOfDeliveryList
    ? placeOfDeliveryList.reduce<OptionType[]>(commonCodeMapper, [])
    : [];

  const paymentTermsListToOptionList = paymentTermsList
    ? paymentTermsList.reduce<OptionType[]>(commonCodeMapper, [])
    : [];

  const shippingTermsListToOptionList = shippingTermsList
    ? shippingTermsList.reduce<OptionType[]>(commonCodeMapper, [])
    : [];

  const countryListToOptionList = countryList
    ? countryList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: `${val.codeItemName}) ${val.codeItemNameEn}`,
          value: val.codeItemNameKo,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const mainCategoryItemName = mainCategoryList.find(
    (item) =>
      item.codeItemNameEn === contractDetail.currentData?.mainCategory ||
      item.codeItemName ===
        contractDetail.currentData?.mainCategoryForImporter
          ?.toLocaleUpperCase()
          .replace(/-/g, "_"),
  )?.codeItemName;

  // 매니저,중간관리자 카테고리 비교
  const isEditAuth =
    exporterUserType === "MANAGER" || exporterUserType === "MIDDLE_MANAGER"
      ? userMainCategory.includes(mainCategoryItemName as string)
      : true;

  const imageList =
    watch("referenceMediaIdList")?.map((item) => item.mediaUrl) || [];

  const selectBuyer = buyerEmployeesResult
    ? buyerEmployeesResult?.currentData?.rows.filter(
        (item) => item.id === Number(watch("importerBuyerId")),
      )[0]?.buyer
    : null;

  const isBuyerLinkedStatus =
    buyerList.find(
      ({ buyerListQueryResultDto }) =>
        buyerListQueryResultDto.id.toString() === watch().importerBuyerId,
    )?.buyerListQueryResultDto.linkedStatus === "COMPLETED";

  const contractEditPoData = getPoPdfData({
    type: "EDIT",
    contractAddAndEditPoData: {
      sellerCorporation: selectBuyer ? selectBuyer?.buyerName : "",
      sellerContactPrefix: selectBuyer ? selectBuyer?.telPrefix : "",
      sellerContact: selectBuyer ? selectBuyer?.tel : "",
      sellerFaxPrefix: selectBuyer ? selectBuyer?.faxPrefix : "",
      sellerFax: selectBuyer ? selectBuyer?.fax : "",
      sellerCountryName: selectBuyer ? selectBuyer?.buyerCountryName : "",
      sellerRegion: selectBuyer ? selectBuyer?.buyerRegion : "",
      sellerPostalCode: selectBuyer ? selectBuyer?.buyerPostalCode : "",
      sellerLocality: selectBuyer ? selectBuyer?.buyerLocality : "",
      sellerStreetAddress: selectBuyer
        ? selectBuyer?.buyerStreetAddress || ""
        : "",
      sellerContactPersonName:
        buyerListToOptionList?.find(
          (employee) =>
            employee.value ===
            previewPoPdfResourceRef.current?.importerBuyerEmployeeId,
        )?.label ?? "",

      poNumber: previewPoPdfResourceRef.current?.poNo || "",
      orderDate: previewPoPdfResourceRef.current?.importerOrderDateAt
        ? dayjs(previewPoPdfResourceRef.current?.importerOrderDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD,
          )
        : "",
      paymentTerm: previewPoPdfResourceRef.current?.paymentTerms || "",
      paymentTermsRemark:
        previewPoPdfResourceRef.current?.paymentTermsRemark || "",
      originLocation: previewPoPdfResourceRef.current?.origin || "",
      shippingTerm: previewPoPdfResourceRef.current?.shippingTerms || "",
      shippingTermRemark:
        previewPoPdfResourceRef.current?.shippingTermsRemark || "",
      lastShipmentDate: previewPoPdfResourceRef.current?.lastShipmentDateAt
        ? dayjs(previewPoPdfResourceRef.current?.lastShipmentDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD,
          )
        : "",
      deliveryDate: previewPoPdfResourceRef.current?.deliveryDateAt
        ? dayjs(previewPoPdfResourceRef.current?.deliveryDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD,
          )
        : "",
      quantityPrefix: previewPoPdfResourceRef.current?.quantityUnit || "",
      quantity: previewPoPdfResourceRef.current?.quantity
        ? Number(
            previewPoPdfResourceRef.current?.quantity.replace(/[,]/g, ""),
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      description: previewPoPdfResourceRef.current?.item || "",
      hsCode: previewPoPdfResourceRef.current?.importerHsCode || "",
      unitPricePrefix: previewPoPdfResourceRef.current?.unitPriceUnit || "",
      unitPrice: previewPoPdfResourceRef.current?.unitPrice
        ? Number(
            previewPoPdfResourceRef.current?.unitPrice.replace(/[,]/g, ""),
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      amount:
        (previewPoPdfResourceRef.current?.quantity &&
          previewPoPdfResourceRef.current?.unitPrice &&
          Number(
            (
              (parseFloat(
                previewPoPdfResourceRef.current?.quantity.replace(/,/g, ""),
              ) || 0) *
              (parseFloat(
                previewPoPdfResourceRef.current?.unitPrice.replace(/,/g, ""),
              ) || 0)
            ).toFixed(2),
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) ||
        "",
      remark: previewPoPdfResourceRef.current?.importerContractRemark || "",
      freeTime:
        (previewPoPdfResourceRef.current?.freetimeCodeItemName ===
        DIRECT_INPUT_VALUE
          ? previewPoPdfResourceRef.current.freetimeDirect
          : previewPoPdfResourceRef.current?.freetime) ?? "",
      placeOfDelivery: previewPoPdfResourceRef.current?.placeOfDelivery || "",
      isAddContractTerms:
        !!previewPoPdfResourceRef.current?.isAddContractTerms.length,
    },
  });

  const initData = (
    detail: ContractDetailViewForImporterDto,
    itemList?: ExporterItemCodeListViewV2Dto[],
  ) => {
    const {
      poNo,
      importerBuyerId,
      importerBuyerEmployeeId,
      allocation = undefined,
      hsCode = undefined,
      importerHsCode = undefined,
      importerItemId,
      importerItemCodeId = undefined,
      importerItemCode,
      quantityUnitCodeItemName,
      quantityUnit,
      quantity,
      unitPriceUnit,
      unitPriceUnitCodeItemName,
      unitPrice,
      origin = undefined,
      freetime = undefined,
      freetimeCodeItemName,
      placeOfDelivery,
      placeOfDeliveryCodeItemName,
      anyPortIn,
      anyPortInCodeItemName,
      paymentTerms,
      paymentTermsCodeItemName,
      paymentTermsRemark,
      paymentTermsSimpleMedia,
      expireDateAt,
      lastShipmentDateAt,
      deliveryDateAt,
      shippingTerms,
      shippingTermsCodeItemName,
      shippingTermsRemark,
      importerContractRemark,
      referenceSimpleMedias,
      poSimpleMedia,
      simpleContractMemos,
      lcNo,
      portOfDischarge,
      portOfDischargeCodeItemName,
      importerOrderDateAt,
    } = detail;

    const itemInfo = itemList?.find(
      (item) => item.exporterItemId === importerItemId,
    );

    reset({
      poNo,
      importerBuyerId: importerBuyerId ? importerBuyerId.toString() : undefined,
      importerBuyerEmployeeId: importerBuyerEmployeeId.toString(),
      allocation,
      importerOrderDateAt: importerOrderDateAt
        ? dayjs(importerOrderDateAt)
        : undefined,
      importerHsCode: importerHsCode || hsCode,
      importerItemId:
        importerItemCodeId && importerItemId
          ? `${importerItemCodeId}_${importerItemId}`
          : undefined,
      importerItemCodeId: importerItemCodeId
        ? importerItemCodeId.toString()
        : undefined,
      importerItemCode: importerItemCode || "",
      quantityUnitCodeItemName,
      quantityUnit,
      quantity: getIntegerWithDecimal(quantity.toString()),
      unitPriceUnit,
      unitPriceUnitCodeItemName,
      unitPrice: getIntegerWithDecimal(unitPrice.toString()),
      origin,
      freetime,
      freetimeCodeItemName:
        !freetimeCodeItemName && !!freetime
          ? DIRECT_INPUT_VALUE
          : freetimeCodeItemName,
      freetimeDirect:
        !freetimeCodeItemName && !!freetime ? freetime : undefined,
      placeOfDelivery,
      placeOfDeliveryCodeItemName,
      anyPortIn,
      anyPortInCodeItemName,
      paymentTerms,
      paymentTermsCodeItemName,
      paymentTermsRemark,
      lcNo,
      expireDateAt: expireDateAt ? dayjs(expireDateAt) : dayjs(),
      lastShipmentDateAt: lastShipmentDateAt
        ? (dayjs(lastShipmentDateAt) as unknown as string)
        : undefined,
      shippingTerms,
      shippingTermsCodeItemName,
      shippingTermsRemark,
      importerContractRemark,
      isAddContractTerms: detail.isAddContractTerms ? ["true"] : [],
      notYetSpecified: !!anyPortInCodeItemName,
      paymentTermsMediaId: paymentTermsSimpleMedia,
      referenceMediaIdList: referenceSimpleMedias ?? [],
      poMediaId: poSimpleMedia ? [poSimpleMedia] : undefined,
      memo: undefined,
      mainCategory: itemInfo?.mainCategory,
      subCategory: itemInfo?.subCategory,
      item: itemInfo?.item,
      portOfDischarge,
      portOfDischargeCodeItemName,
      deliveryDateAt: deliveryDateAt ? dayjs(deliveryDateAt) : undefined,
    });
    originalContractBuyerId.current = detail.importerBuyerId;
    setMemoList(simpleContractMemos || []);
  };

  const handleEdit = async (signatureMediaId: number) => {
    handleContentLoadingOn();

    let isSendToBuyerValue = false;
    if (!isPending && isBuyerLinkedStatus) {
      isSendToBuyerValue = isSendPoToBuyer;
    }

    const transformedBody = {
      ...getValues(),
      anyPortIn: getValues("notYetSpecified")
        ? getValues("anyPortIn")
        : undefined,
      anyPortInCodeItemName: getValues("notYetSpecified")
        ? getValues("anyPortInCodeItemName")
        : undefined,
      placeOfDelivery: getValues("placeOfDelivery"),
      placeOfDeliveryCodeItemName: getValues("placeOfDeliveryCodeItemName"),
      isAddContractTerms: !!getValues("isAddContractTerms").length,
      paymentTermsMediaId:
        getValues("paymentTerms") === "L/C"
          ? getValues("paymentTermsMediaId")?.id
          : undefined,
      lcNo: getValues("paymentTerms") === "L/C" ? getValues("lcNo") : undefined,
      importerOrderDateAt: dayjs(getValues("importerOrderDateAt"))
        .startOf("day")
        .toISOString(),
      lastShipmentDateAt: getValues("lastShipmentDateAt")
        ? dayjs(getValues("lastShipmentDateAt")).startOf("day").toISOString()
        : undefined,
      poMediaId: getValues("poMediaId")?.[0].id,
      referenceMediaIdList: getValues("referenceMediaIdList").map(
        ({ id }) => id,
      ),
      unitPrice: Number(getValues("unitPrice").replace(/,/g, "")),
      quantity: Number(getValues("quantity").replace(/,/g, "")),
      importerBuyerId: Number(getValues("importerBuyerId")),
      importerBuyerEmployeeId: Number(getValues("importerBuyerEmployeeId")),
      importerItemId: Number(getValues("importerItemId").split("_")[1]),
      importerItemCodeId: Number(getValues("importerItemCodeId")),
      freetimeCodeItemName:
        getValues("freetimeCodeItemName") === DIRECT_INPUT_VALUE
          ? undefined
          : getValues("freetimeCodeItemName"),
      freetime:
        getValues("freetimeCodeItemName") === DIRECT_INPUT_VALUE
          ? getValues("freetimeDirect")
          : getValues("freetime"),
      unitPriceUnit: getValues("unitPriceUnit"),
      unitPriceUnitCodeItemName: getValues("unitPriceUnit"),
      isSendToBuyer: isPending ? undefined : isSendToBuyerValue,
      signatureMediaId,
    };
    const body = transformValueOrNull({
      targetObject: transformedBody,
      removeFieldKey: [
        "amount",
        "freetimeDirect",
        "mainCategory",
        "subCategory",
        "item",
        "importerItemCode",
      ],
    });
    try {
      await editContractDetail({
        id: Number(params.id),
        ...(body as GenerateContractV2Dto),
      }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
      navigate(`${IMPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${params.id}`);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  const handleEditContract = async () => {
    /**
     * - 기업관리자, 중간관리자 서명등록
     * - 일반 매니저 기업관리자에게 서명요청
     */
    if (
      isNull(
        exportersData.currentData?.signatureManagement
          .simpleExporterSignatureMedias,
      )
    ) {
      if (exporterUserType !== "MANAGER") {
        return setAlertDialogState(AlertDialogState.REGISTER_SIGN);
      } else {
        return setAlertDialogState(AlertDialogState.SIGNATURE_REQUEST);
      }
    }
    // 선택한 거래처와 연결이 되어 있는 경우 거래처에게 PO 보낼지 여부 확인
    if (isBuyerLinkedStatus) {
      return setAlertDialogState(AlertDialogState.SEND_TO_BUYER);
    }

    await handleEdit(defaultSignatureMediaId);
  };

  const handleImageClick = (image: string) => {
    setDialogState(DialogState.IMAGE);
    setSelectImage(image);
  };

  const handleAddItems = async ({
    item,
    exporterItemCodeList,
    subCategoryCodeItemName,
    mainCategoryCodeItemName,
  }: AddItemType) => {
    try {
      const { row } = await createSingleItemCode({
        item: item,
        itemCode: exporterItemCodeList[0].itemCode,
        subCategoryCodeItemName: subCategoryCodeItemName,
        mainCategoryCodeItemName: mainCategoryCodeItemName,
      }).unwrap();

      await getItems({}).unwrap();

      const categoryList =
        buyerList.find(({ id }) => watch("importerBuyerId") === id.toString())
          ?.mainCategoryCodeItemNames ?? [];

      if (categoryList.includes(mainCategoryCodeItemName)) {
        setValue("importerItemId", `${row.id}_${row.exporterItemId}`);
        setValue("mainCategory", row.mainCategory ?? "");
        setValue("subCategory", row.subCategory ?? "");
        setValue("item", row.item ?? "");
        setValue("importerItemCodeId", String(row.id));
        setValue("importerItemCode", row.itemCode);
      }

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });

      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddBuyerEmployee = async (
    values: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    },
  ) => {
    const importerBuyerId = getValues("importerBuyerId");

    try {
      setDialogState(DialogState.NULL);
      const { row: createdEmployee } = await createEmployee({
        id: Number(importerBuyerId),
        name: values.name,
        email: values.email,
        isActivated: values.isActivated === "Y",
        personalContactPrefix: values.personalContactPrefix || undefined,
        personalContact: values.personalContact || undefined,
        officeContactPrefix: values.officeContactPrefix || undefined,
        officeContact: values.officeContact || undefined,
        buyerEmployeeRemark: values.buyerEmployeeRemark || undefined,
      }).unwrap();

      await getBuyerEmployees({
        id: Number(importerBuyerId),
      });

      setValue("importerBuyerEmployeeId", createdEmployee.id.toString());
      clearErrors("importerBuyerEmployeeId");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
    }
  };

  // 기본 정보
  const renderBasicInformation = () => {
    // 선택사항
    const optionalItemValue = () => {
      if (exportersData.isFetching) {
        return <Loader />;
      }
      // 약관이 있는경우에만 체크박스
      if (isCompanyTerms) {
        return (
          <StyledFormItem
            type="checkbox"
            control={control as any}
            name="isAddContractTerms"
            options={[
              {
                label: t("contract:importer.add.common.addContractTerm"),
                value: "true",
              },
            ]}
          />
        );
      }

      return (
        <Flex gap={16} alignItems="center" flexWrap="wrap">
          <Typo typoType="b7r" color="gray7">
            {t("common:noRegisteredOrderTerms")}
          </Typo>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => setDialogState(DialogState.CREATE_TERMS)}
          >
            <Flex alignItems="center" gap={4}>
              <AddIcon />
              {t("common:registerOrderTerms")}
            </Flex>
          </Button>
        </Flex>
      );
    };

    return (
      <SectionCard
        cardTitle={t("contract:importer.add.common.basicInformation")}
      >
        <Flex flexDirection="column" gap={24}>
          <StyledFormItem
            label={t("contract:importer.add.common.poNo")}
            type={"text"}
            name={"poNo"}
            control={control as any}
            inputProps={{
              placeholder: t("contract:importer.add.placeholder.poNo"),
              disabled: isContractLinked,
            }}
            rules={{
              required: true,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />

          <StyledFormItem
            label={t("contract:importer.add.common.seller")}
            rules={{
              required: true,
            }}
            type="singleSelect"
            control={control as any}
            name="importerBuyerId"
            inputContainerClassName="selector-size-m"
            inputProps={{
              suffixIcon: isBuyersFetching ? <Loader /> : undefined,
              disabled: isBuyersFetching || isContractLinked,
              placeholder: t("contract:importer.add.placeholder.buyer"),
              filterOption: (input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()),
              onChange: async (a: any) => {
                try {
                  const res = await getBuyerEmployees({
                    id: Number(a),
                  }).unwrap();

                  const candidate = contractFormDefaultValue;

                  setValue("importerItemId", candidate["importerItemId"]);
                  setValue("mainCategory", candidate["mainCategory"]);
                  setValue("subCategory", candidate["subCategory"]);
                  setValue("item", candidate["item"]);
                  setValue(
                    "importerItemCodeId",
                    candidate["importerItemCodeId"],
                  );
                  setValue("importerItemCode", candidate["importerItemCode"]);

                  const validBuyerList = res.rows.filter(
                    ({ isActivated, id }) =>
                      isActivated ||
                      contractDetail.currentData?.importerBuyerEmployeeId ===
                        id,
                  );

                  if (validBuyerList.length === 1) {
                    setValue(
                      "importerBuyerEmployeeId",
                      validBuyerList[0].id.toString(),
                    );
                    clearErrors("importerBuyerEmployeeId");
                  } else {
                    setValue(
                      "importerBuyerEmployeeId",
                      candidate["importerBuyerEmployeeId"],
                    );
                  }
                  if (validBuyerList.length > 1 || !validBuyerList.length) {
                    setValue("importerBuyerEmployeeId", "");
                  }
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;
                  alert.showAlert({ type: "error", message });
                }
              },
            }}
            options={buyerToOptionList}
            bottomAccessory={
              <>
                <SubFormRow>
                  <SubFormLabel>
                    {t("contract:importer.add.common.sellerContact")}
                    <strong>*</strong>
                  </SubFormLabel>
                  <Controller
                    name="importerBuyerEmployeeId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FlexDiv>
                        <StyledSelect
                          {...field}
                          disabled={
                            !watch("importerBuyerId") ||
                            buyerEmployeesResult.isFetching ||
                            isContractLinked
                          }
                          value={field.value || undefined}
                          data-invalid={!!errors.importerBuyerEmployeeId}
                          showSearch
                          filterOption={(input, option) =>
                            ((option?.label as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={buyerListToOptionList}
                          placeholder={t(
                            "contract:importer.add.placeholder.sellerContact",
                          )}
                          suffixIcon={
                            buyerEmployeesResult.isFetching ? (
                              <Loader />
                            ) : undefined
                          }
                          dropdownRender={(menu) => {
                            return (
                              <>
                                <DirectInputSelectOptionButton
                                  onClick={() => {
                                    setValue("importerBuyerEmployeeId", "");
                                    setDialogState(
                                      DialogState.ADD_CONTACT_PERSON,
                                    );
                                  }}
                                >
                                  {t(DIRECT_INPUT_LABEL)}
                                </DirectInputSelectOptionButton>
                                {(buyerListToOptionList?.length ?? 0) > 0 && (
                                  <div>{menu}</div>
                                )}
                              </>
                            );
                          }}
                        />
                        {errors.importerBuyerEmployeeId && (
                          <StyledInputError message={t("error:required")} />
                        )}
                      </FlexDiv>
                    )}
                  />
                </SubFormRow>
              </>
            }
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
            }}
          />
          <StyledFormItem
            label={t("contract:importer.add.common.orderDate")}
            type="date"
            control={control as any}
            name="importerOrderDateAt"
            rules={{ required: true }}
            inputProps={{ disabled: isContractLinked }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <SectionCardRow
            label={t("contract:exporter.add.common.optional")}
            value={optionalItemValue()}
          />
        </Flex>
      </SectionCard>
    );
  };

  // 아이템 코드
  const renderItemSubInputs = () => {
    return (
      <>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:importer.add.common.category")}
          </SubFormLabel>
          <Controller
            name="mainCategory"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder={t("common:mainCategory")}
                value={
                  getValues("mainCategory")
                    ? `${field.value} > ${watch("subCategory")}`
                    : "-"
                }
              />
            )}
          />
        </SubFormRow>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:importer.add.common.itemCode")}
          </SubFormLabel>
          <Controller
            name="importerItemCode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder={
                  getValues("mainCategory") ? "-" : t("common:itemCode")
                }
              />
            )}
          />
        </SubFormRow>
      </>
    );
  };

  // Item / Payment Terms
  const renderItem = () => {
    return (
      <SectionCard
        cardTitle={t("contract:importer.add.common.itemPaymentTerms")}
      >
        <Flex flexDirection="column" gap={24}>
          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("contract:importer.add.common.item")}
            </StyledCustomLabel>
            <DoubleInputContainerWithError style={{ flex: 1 }}>
              <DoubleInputContainer style={{ width: "100%" }}>
                <Controller
                  name="importerItemId"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <StyledSelect
                          {...field}
                          style={{ flex: 1, width: 1 }}
                          placeholder={t(
                            "contract:importer.add.placeholder.item",
                          )}
                          value={field.value || undefined}
                          filterOption={(input, option) =>
                            ((option?.label as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={itemListToGroupedOptionList}
                          data-invalid={!!errors.importerItemId}
                          showSearch
                          suffixIcon={isItemsFetching ? <Loader /> : undefined}
                          disabled={isItemsFetching || isContractLinked}
                          onChange={(value) => {
                            const splitedValue = (value as string).split("_");
                            const selectItem = itemsList.find(
                              (itemInfo) =>
                                itemInfo.id === Number(splitedValue[0]),
                            );

                            if (selectItem) {
                              setValue("importerItemId", value);
                              setValue("mainCategory", selectItem.mainCategory);
                              setValue("subCategory", selectItem.subCategory);
                              setValue("item", selectItem.item);
                              setValue("importerItemCodeId", splitedValue[0]);
                              setValue("importerItemCode", selectItem.itemCode);
                            }
                          }}
                        />
                        <StyledButton
                          buttonColor="black"
                          buttonGrade="tertiary"
                          buttonSize={40}
                          onClick={() => setDialogState(DialogState.ADD_ITEM)}
                          disabled={!isEditAuth || isContractLinked}
                        >
                          <AddIcon
                            data-disabled={!isEditAuth || isContractLinked}
                          />
                          {t("contract:importer.add.common.addItem")}
                        </StyledButton>
                      </>
                    );
                  }}
                />
              </DoubleInputContainer>
              {errors.importerItemId && (
                <InputError message={t("error:required")} />
              )}
              {renderItemSubInputs()}
            </DoubleInputContainerWithError>
          </CustomFormItemRow>

          <StyledFormItem
            label={t("contract:importer.add.common.hsCode")}
            control={control as any}
            name="importerHsCode"
            type="text"
            inputProps={{
              disabled: isContractLinked,
              placeholder: t("contract:importer.add.placeholder.hsCode"),
            }}
          />

          <CustomFormItemRow>
            <Label required>{t("contract:importer.add.common.qty")}</Label>
            <DoubleInputContainer className="fixed-width-container">
              <Controller
                name="quantityUnit"
                control={control}
                render={({ field }) => (
                  <FlexDiv>
                    <DoubleInputContainer>
                      <StyledSelect
                        {...field}
                        data-invalid={!!errors.quantity}
                        style={{ width: "108px", flexShrink: "0" }}
                        value={watch("quantityUnitCodeItemName")}
                        options={mesurementListToOptionList}
                        onChange={(_, option: any) => {
                          setValue("quantityUnit", option.label);
                          setValue("quantityUnitCodeItemName", option.value);
                        }}
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                      />
                      <Input
                        {...register("quantity", { required: true })}
                        onChange={(e) => {
                          const quantityWithDecimals =
                            handleIntegerWithDecimalsChange(e.target);

                          setValue(
                            "quantity",
                            String(quantityWithDecimals ?? ""),
                          );
                        }}
                        data-invalid={!!errors.quantity}
                        onBlur={(e) => {
                          const quantityWithDecimals = getIntegerWithDecimal(
                            e.target.value,
                          );

                          if (
                            quantityWithDecimals === "0.00" ||
                            quantityWithDecimals === ""
                          ) {
                            return setError("quantity", { type: "required" });
                          }

                          setValue(
                            "quantity",
                            String(quantityWithDecimals ?? ""),
                          );
                          clearErrors("quantity");
                        }}
                        placeholder={t("contract:importer.add.placeholder.qty")}
                        onClear={() => setValue("quantity", "")}
                        autoComplete="off"
                      />
                    </DoubleInputContainer>
                    {!!errors.quantity && (
                      <StyledInputError message={t("error:required")} />
                    )}
                  </FlexDiv>
                )}
              />
            </DoubleInputContainer>
          </CustomFormItemRow>

          <CustomFormItemRow>
            <Label required>
              {t("contract:importer.add.common.unitPrice")}
            </Label>
            <DoubleInputContainer>
              <Controller
                name="unitPriceUnit"
                control={control}
                render={({ field }) => (
                  <FlexDiv>
                    <DoubleInputContainer>
                      <StyledSelect
                        {...field}
                        style={{ width: "108px", flexShrink: "0" }}
                        options={currencyListToOptionList}
                        data-invalid={!!errors.unitPrice}
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                      />
                      <StyledInput
                        {...register("unitPrice", { required: true })}
                        style={{ width: "100%" }}
                        data-invalid={!!errors.unitPrice}
                        placeholder={t(
                          "contract:importer.add.placeholder.unitPrice",
                        )}
                        onChange={(e) => {
                          const quantityWithDecimals =
                            handleIntegerWithDecimalsChange(e.target);

                          setValue(
                            "unitPrice",
                            String(quantityWithDecimals ?? ""),
                          );
                        }}
                        onBlur={(e) => {
                          const quantityWithDecimals = getIntegerWithDecimal(
                            e.target.value,
                          );

                          if (
                            quantityWithDecimals === "0.00" ||
                            quantityWithDecimals === ""
                          ) {
                            return setError("unitPrice", {
                              type: "required",
                            });
                          }

                          setValue(
                            "unitPrice",
                            String(quantityWithDecimals ?? ""),
                          );
                          clearErrors("unitPrice");
                        }}
                        onClear={() => setValue("unitPrice", "")}
                        autoComplete="off"
                      />
                    </DoubleInputContainer>
                    {!!errors.unitPrice && (
                      <StyledInputError message={t("error:required")} />
                    )}
                  </FlexDiv>
                )}
              />
            </DoubleInputContainer>
          </CustomFormItemRow>

          <StyledSectionCardRow
            className="flex-column"
            label={<Label>{t("contract:importer.add.common.amount")}</Label>}
            value={
              <AmountContent>
                <Typo typoType="b7r" color="blue4">
                  {parseFloat(
                    (
                      (parseFloat(watch("quantity").replace(/,/g, "")) || 0) *
                      (parseFloat(watch("unitPrice").replace(/,/g, "")) || 0)
                    ).toFixed(2),
                  ).toLocaleString("ko-KR", {
                    minimumFractionDigits:
                      watch("quantity") || watch("unitPrice") ? 2 : undefined,
                    maximumFractionDigits:
                      watch("quantity") || watch("unitPrice") ? 2 : undefined,
                  })}
                </Typo>{" "}
                <Typo typoType="b7r">{watch("unitPriceUnit")}</Typo> (
                <Typo typoType="b7r" color="blue4">
                  {Number(
                    parseFloat(watch("quantity").replace(/,/g, "")) || 0,
                  ).toLocaleString("ko-KR")}{" "}
                </Typo>
                <Typo typoType="b7r">{watch("quantityUnit")}</Typo> X{" "}
                <Typo typoType="b7r" color="blue4">
                  {(
                    parseFloat(watch("unitPrice").replace(/,/g, "")) || 0
                  ).toLocaleString("ko-KR")}
                </Typo>{" "}
                <Typo typoType="b7r">{watch("unitPriceUnit")}</Typo>)
              </AmountContent>
            }
          />

          <Divider />

          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("contract:importer.add.common.paymentTerms")}
            </StyledCustomLabel>
            <FlexDiv style={{ gap: "8px" }}>
              <DoubleInputContainer>
                <Controller
                  name="paymentTermsCodeItemName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <StyledSelect
                        {...field}
                        style={{ width: "140px", flexShrink: "0" }}
                        data-invalid={!!errors.paymentTermsCodeItemName}
                        placeholder={t("contract:importer.add.common.select")}
                        options={paymentTermsListToOptionList}
                        showSearch
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                        disabled={isCommonCodeFetching || isContractLinked}
                        onChange={(label, option: any) => {
                          field.onChange(label);
                          setValue("paymentTerms", option.label);
                        }}
                      />
                      <Input
                        {...register("paymentTermsRemark")}
                        style={{ width: "100%" }}
                        disabled={isContractLinked}
                        placeholder={t(
                          "contract:importer.add.placeholder.paymentTermsRemark",
                        )}
                        onClear={() => setValue("paymentTermsRemark", "")}
                      />
                    </>
                  )}
                />
              </DoubleInputContainer>
              {errors.paymentTermsCodeItemName && (
                <InputError message={t("error:required")} />
              )}
              {watch("paymentTermsCodeItemName") === "L_C" && (
                <Controller
                  control={control}
                  name="lcNo"
                  render={({ field }) => {
                    return (
                      <SubFormRow>
                        <SubFormLabel>{t("common:lcNo")}</SubFormLabel>
                        <FlexDiv>
                          <Input
                            {...field}
                            placeholder={t("placeholder:lcNo")}
                            onClear={() => setValue("lcNo", "")}
                            disabled={isContractLinked}
                          />
                        </FlexDiv>
                      </SubFormRow>
                    );
                  }}
                />
              )}
              {watch("paymentTermsCodeItemName") === "L_C" && (
                <>
                  <Controller
                    control={control}
                    name="paymentTermsMediaId"
                    render={() => {
                      return (
                        <SubFormRow>
                          <SubFormLabel>
                            {t("contract:importer.add.common.lcFile")}
                          </SubFormLabel>
                          <FlexDiv>
                            <FileComponent
                              defaultFileList={
                                watch("paymentTermsMediaId")
                                  ? [
                                      new File(
                                        [""],
                                        watch("paymentTermsMediaId")
                                          ?.originalFileName ?? "",
                                      ),
                                    ]
                                  : undefined
                              }
                              onChange={(a) =>
                                setValue("paymentTermsMediaId", a[0])
                              }
                              disabled={isContractLinked}
                            />
                          </FlexDiv>
                        </SubFormRow>
                      );
                    }}
                  />
                </>
              )}
              {watch("paymentTermsCodeItemName") === "L_C" && (
                <Controller
                  control={control}
                  name="expireDateAt"
                  rules={{
                    required: watch("paymentTermsCodeItemName") === "L_C",
                  }}
                  render={({ field }) => {
                    return (
                      <SubFormRow>
                        <SubFormLabel>
                          {t("contract:importer.add.common.expireDate")}
                          <strong>*</strong>
                        </SubFormLabel>
                        <FlexDiv>
                          <DatePickerContainer
                            data-invalid={!!errors.expireDateAt}
                          >
                            <ConfigProvider
                              theme={{
                                components: {
                                  DatePicker: {
                                    fontSize: 16,
                                  },
                                },
                              }}
                            >
                              <DatePicker
                                {...field}
                                style={{ width: "100%" }}
                                disabled={isContractLinked}
                                onChange={(day) => {
                                  setValue(
                                    "expireDateAt",
                                    dayjs(
                                      dayjs(day).format("YYYY-MM-DD"),
                                      "YYYY-MM-DD",
                                    ),
                                  );
                                }}
                              />
                            </ConfigProvider>
                          </DatePickerContainer>
                          {!!errors.expireDateAt && (
                            <StyledInputError message={t("error:required")} />
                          )}
                        </FlexDiv>
                      </SubFormRow>
                    );
                  }}
                />
              )}
            </FlexDiv>
          </CustomFormItemRow>
        </Flex>
      </SectionCard>
    );
  };

  // Shipping Terms
  const renderShippingTermsInformation = () => {
    return (
      <SectionCard
        cardTitle={t("contract:importer.add.common.shippingTerms")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <HalfFormItemContainer>
          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("contract:importer.add.common.shippingTerms")}
            </StyledCustomLabel>
            <DoubleInputContainerWithError style={{ flex: 1 }}>
              <DoubleInputContainer>
                <Controller
                  name="shippingTermsCodeItemName"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledSelect
                        {...field}
                        style={{ width: "140px", flexShrink: "0" }}
                        placeholder={t("contract:importer.add.common.select")}
                        options={shippingTermsListToOptionList}
                        data-invalid={!!errors.shippingTermsCodeItemName}
                        showSearch
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                        disabled={isCommonCodeFetching}
                        onChange={(value, option: any) => {
                          field.onChange(value);
                          setValue("shippingTerms", option.value);
                        }}
                      />
                      <Input
                        {...register("shippingTermsRemark")}
                        style={{ width: "100%" }}
                        placeholder={t(
                          "contract:importer.add.placeholder.shippingTermsRemark",
                        )}
                        onClear={() => setValue("shippingTermsRemark", "")}
                      />
                    </>
                  )}
                />
              </DoubleInputContainer>
              {errors.shippingTermsCodeItemName && (
                <InputError message={t("error:required")} />
              )}
            </DoubleInputContainerWithError>
          </CustomFormItemRow>
          <StyledFormItem
            label={t("contract:importer.add.common.deliveryDate")}
            type="date"
            name="deliveryDateAt"
            control={control as any}
            rules={{ required: true }}
            inputProps={{
              allowClear: true,
            }}
          />
          <StyledFormItem
            label={t("contract:importer.add.common.lastShipmentDate")}
            type="date"
            control={control as any}
            name="lastShipmentDateAt"
            inputProps={{
              allowClear: true,
            }}
          />

          <StyledFormItem
            label={t("contract:importer.add.common.freeTime")}
            type="singleSelect"
            name={"freetimeCodeItemName"}
            control={control as any}
            options={[
              {
                label: t(DIRECT_INPUT_LABEL),
                value: DIRECT_INPUT_VALUE,
              },
              ...freetimeListToOptionList,
            ]}
            inputContainerClassName="selector-size-m"
            inputProps={{
              placeholder: t("contract:importer.add.placeholder.freetime"),
              allowClear: true,
              virtual: false,
              disabled: isContractLinked,
              filterOption: (input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                ((option?.value as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                option?.value === DIRECT_INPUT_VALUE,
              dropdownRender: (menu) => {
                return <CustomDropdownWrapper>{menu}</CustomDropdownWrapper>;
              },
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              onChange: (label: any, option: any) => {
                if (option) {
                  setValue("freetime", option.label);
                }
              },
              onClear: () => {
                setValue("freetime", undefined);
              },
            }}
            bottomAccessory={
              watch("freetimeCodeItemName") === DIRECT_INPUT_VALUE ? (
                <Input
                  {...register("freetimeDirect")}
                  disabled={isContractLinked}
                  placeholder={t("placeholder:enterFreetime")}
                  onClear={() => setValue("freetimeDirect", "")}
                />
              ) : null
            }
          />
        </HalfFormItemContainer>

        <HalfFormItemContainer>
          <StyledFormItem
            label={t("contract:importer.add.common.origin")}
            type={"text"}
            name={"origin"}
            control={control as any}
            inputProps={{
              disabled: isContractLinked,
              placeholder: t("contract:importer.add.placeholder.origin"),
            }}
          />
          <StyledFormItem
            label={t("contract:importer.add.common.placeOfDelivery")}
            type="singleSelect"
            name={"placeOfDeliveryCodeItemName"}
            control={control as any}
            options={placeOfDeliveryListToOptionList}
            inputContainerClassName="selector-size-m"
            inputProps={{
              placeholder: t(
                "contract:importer.add.placeholder.placeOfDelivery",
              ),
              allowClear: true,
              disabled:
                watch("notYetSpecified") ||
                isCommonCodeFetching ||
                isAllSignedBefore,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              onChange: (label: any, option: any) => {
                if (option) {
                  setValue("placeOfDelivery", option.label);
                }
              },
              onClear: () => {
                setValue("placeOfDelivery", undefined);
                setValue("placeOfDeliveryCodeItemName", undefined);
              },
            }}
            bottomAccessory={
              <>
                <CheckboxLabel>
                  <Checkbox
                    checked={watch("notYetSpecified")}
                    disabled={isAllSignedBefore}
                    onChange={(e) => {
                      setValue("notYetSpecified", e.target.checked);
                    }}
                  />
                  <Typo
                    typoType="b7r"
                    color={isAllSignedBefore ? "gray7" : "gray2"}
                  >
                    {t("contract:importer.add.common.notYetSpecified")}
                  </Typo>
                </CheckboxLabel>
                {watch("notYetSpecified") && (
                  <SubFormRow>
                    <SubFormLabel>
                      {t("contract:importer.add.common.anyPortIn")}
                      <strong>*</strong>
                    </SubFormLabel>

                    <FlexDiv
                      style={{ gap: "8px", width: "calc(100% - 108px)" }}
                    >
                      <Controller
                        name="anyPortIn"
                        control={control}
                        rules={{ required: watch("notYetSpecified") }}
                        render={({ field }) => (
                          <StyledSelect
                            {...field}
                            value={
                              countryList?.find(
                                ({ codeItemName }) =>
                                  codeItemName ===
                                  watch("anyPortInCodeItemName"),
                              )?.codeItemNameKo
                            }
                            onChange={(value, option: any) => {
                              field.onChange(option.label);
                              setValue(
                                "anyPortInCodeItemName",
                                (option.label as string).split(")")[0],
                              );
                            }}
                            data-invalid={!!errors.anyPortIn}
                            disabled={isCommonCodeFetching || isAllSignedBefore}
                            showSearch
                            filterOption={(input, option) =>
                              ((option?.label as string) ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase()) ||
                              ((option?.value as string) ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countryListToOptionList}
                            placeholder={t(
                              "contract:importer.add.placeholder.anyPortIn",
                            )}
                            suffixIcon={
                              isCommonCodeFetching ? <Loader /> : undefined
                            }
                          />
                        )}
                      />
                      {errors.anyPortIn && (
                        <InputError message={t("error:required")} />
                      )}
                    </FlexDiv>
                  </SubFormRow>
                )}
              </>
            }
          />
          <StyledFormItem
            label={t("contract:importer.add.common.portOfDischarge")}
            type="singleSelect"
            name={"portOfDischargeCodeItemName"}
            control={control as any}
            options={placeOfDeliveryListToOptionList}
            inputContainerClassName="selector-size-m"
            inputProps={{
              placeholder: t(
                "contract:importer.add.placeholder.placeOfDelivery",
              ),
              allowClear: true,
              disabled: isCommonCodeFetching || isContractLinked,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              onChange: (_: any, option: any) => {
                if (option) {
                  setValue("portOfDischarge", option.label);
                  setValue("portOfDischargeCodeItemName", option.label);
                }
              },
              onClear: () => {
                setValue("portOfDischarge", undefined);
                setValue("portOfDischargeCodeItemName", undefined);
              },
            }}
          />
        </HalfFormItemContainer>
      </SectionCard>
    );
  };

  // 첨부 파일
  const renderAttachmentFile = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:importer.add.common.attachmentFile")}
      >
        <FlexGapDiv>
          <StyledFormItem
            label={t("contract:importer.add.common.poFile")}
            type="file"
            name="poMediaId"
            control={control as any}
            inputProps={{
              defaultFileList: watch("poMediaId")
                ? [
                    new File(
                      [""],
                      watch("poMediaId")?.[0]?.originalFileName ?? "",
                    ),
                  ]
                : undefined,
              onRemove: () => {
                setValue("poMediaId", undefined);
              },
              disabled: !isEditAuth || isContractLinked,
            }}
          />

          <StyledFormItem
            label={t("contract:importer.add.common.referenceImage")}
            type="imageUpload"
            name="referenceMediaIdList"
            control={control as any}
            errorsMessage={{
              required: t("error:required"),
            }}
            bottomAccessory={
              <WarningDescription>
                <StyledIcon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("contract:importer.add.placeholder.referenceImage")}
                </Typo>
              </WarningDescription>
            }
            inputProps={{
              imageSrcList: watch("referenceMediaIdList")?.map(
                ({ mediaUrl }) => {
                  return {
                    imageSrc: mediaUrl,
                    onClick: () => handleImageClick(mediaUrl),
                  };
                },
              ),

              onRemove: (removeItemUrl) => {
                setValue(
                  "referenceMediaIdList",
                  watch("referenceMediaIdList").filter(
                    ({ mediaUrl }) => mediaUrl !== removeItemUrl,
                  ),
                );
              },
              disabled: !isEditAuth || isContractLinked,
            }}
          />
        </FlexGapDiv>
      </StyledSectionCard>
    );
  };

  // Remark
  const renderContractRemark = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:importer.add.common.contractRemark")}
      >
        <FormItem
          type="textarea"
          name="importerContractRemark"
          control={control}
          inputProps={{
            style: { width: "100%" },
            placeholder: t("contract:importer.add.placeholder.contractRemark"),
            maxLength: 1000,
          }}
          noLabel
        />
      </StyledSectionCard>
    );
  };

  // Memo
  const renderMemo = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:importer.add.common.memo")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <MemoHalfContainer>
          <MemoPlaceholder>
            {memoList?.map(({ writerName, wroteAt, memo }) => {
              return (
                <MemoRow>
                  <p>
                    <Typo typoType="h9" color="gray3">
                      {writerName}
                    </Typo>
                    <Typo typoType="b9r" color="gray7">
                      ({dayjs(wroteAt).format("YY-MM-DD,hh:mm")})
                    </Typo>
                  </p>
                  <ReadOnlyText color="gray5" typoType="b7r">
                    {memo}
                  </ReadOnlyText>
                </MemoRow>
              );
            })}
          </MemoPlaceholder>
        </MemoHalfContainer>
        <MemoHalfContainer>
          <MemoInputContainer>
            <WarningDescription style={{ color: colorSet.gray6 }}>
              <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
              {t("contract:importer.add.memoInfo")}
            </WarningDescription>
            <FormItem
              type="textarea"
              name="memo"
              control={control}
              inputProps={{
                style: { width: "100%", height: "195px" },
                placeholder: t("contract:importer.add.placeholder.memo"),
                maxLength: 500,
              }}
              noLabel
            />
          </MemoInputContainer>
        </MemoHalfContainer>
      </StyledSectionCard>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.IMAGE && selectImage) {
      return (
        <ReferenceImageDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          imageList={imageList ?? [""]}
          selectImage={selectImage}
          onChangeSelect={setSelectImage}
        />
      );
    }
    // 아이템 추가
    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isSingleItemCode
          onSave={handleAddItems}
        />
      );
    }
    // 거래처 추가
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      const selectedBuyer = buyerList.find(
        ({ id }) => watch("importerBuyerId") === id.toString(),
      );

      return (
        <BuyerEmployeeAddDialog
          onSave={handleAddBuyerEmployee}
          onClose={() => setDialogState(DialogState.NULL)}
          defaultCountryCode={selectedBuyer?.telPrefix}
        />
      );
    }
    // 약관추가
    if (dialogState === DialogState.CREATE_TERMS) {
      return (
        <TermsAddDialog
          title={t("common:registerOrderTerms")}
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={async () => await exportersData.refetch()}
        />
      );
    }
    // 서명추가
    if (dialogState === DialogState.REGISTER_SIGN) {
      return (
        <RegisterCompanySignDialog
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    const closeAlertDialog = () => setAlertDialogState(AlertDialogState.NULL);
    // 뒤로가기
    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t("contract:importer.add.alertDialog.backToPrevious.title")}
          onOpenChange={() => {
            closeAlertDialog();
          }}
          onOk={() => navigate(-1)}
          okText={t("contract:importer.add.common.ok")}
          cancelText={t("contract:importer.add.common.exit")}
        >
          {t("contract:importer.add.alertDialog.backToPrevious.description")}
        </AlertDialog>
      );
    }
    // 발주 수정
    if (alertDialogState === AlertDialogState.SEND_TO_BUYER) {
      return (
        <AlertDialog
          open
          title={t("contract:exporter.edit.alertDialog.editContract.title")}
          onOpenChange={() => {
            closeAlertDialog();
          }}
          onOk={() => {
            const defaultEdit = () => {
              handleEdit(defaultSignatureMediaId);
              closeAlertDialog();
            };
            /**
             * - Yes
             *   - 서명이 2개일 경우 서명선택모달
             *   - 서명이 1개일 경우 default
             * - No
             *   - default
             */
            if (isSendPoToBuyer) {
              isTwoSign
                ? setAlertDialogState(AlertDialogState.SIGNATURE_SELECT_PO)
                : defaultEdit();
            } else {
              defaultEdit();
            }
          }}
          okText={t("contract:exporter.add.common.ok")}
          cancelText={t("contract:exporter.add.common.exit")}
        >
          <FlexGap flexGap={16}>
            {t("contract:exporter.edit.alertDialog.createContract.description")}
            {isBuyerLinkedStatus ? (
              <FlexGap flexGap={8}>
                <Typo typoType="b9r" color="gray6">
                  {t("contract:importer.add.common.sendPoToSeller")}
                </Typo>

                <RadioGroup
                  value={isSendPoToBuyer}
                  onChange={() => {
                    setIsSendPoToBuyer(!isSendPoToBuyer);
                  }}
                  options={[
                    {
                      value: true,
                      label: "Y",
                    },
                    {
                      value: false,
                      label: "N",
                    },
                  ]}
                  name="sendSc"
                />
              </FlexGap>
            ) : null}
          </FlexGap>
        </AlertDialog>
      );
    }
    // 서명등록
    if (alertDialogState === AlertDialogState.REGISTER_SIGN) {
      return (
        <NoRegisteredSignatureAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            setDialogState(DialogState.REGISTER_SIGN);
            closeAlertDialog();
          }}
          onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
        />
      );
    }
    // 서명등록 요청
    if (alertDialogState === AlertDialogState.SIGNATURE_REQUEST) {
      return (
        <SignatureRequestAlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          onCancel={closeAlertDialog}
        />
      );
    }
    // PO서명선택 (서명 2개일 경우)
    if (alertDialogState === AlertDialogState.SIGNATURE_SELECT_PO) {
      return (
        <SignatureSelectDialog
          open
          onSave={(selectSignatureMediaId) => {
            handleEdit(selectSignatureMediaId);
            closeAlertDialog();
          }}
          onOpenChange={closeAlertDialog}
        />
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    if (contractDetail.isFetching || isUndefined(contractDetail.currentData)) {
      return;
    }

    // 권한이 없을 경우에 보여준다, 서버 http Status 404를 받음
    return (
      <NoDataContainer>
        <UnauthorizedDescription />
      </NoDataContainer>
    );
  };

  useEffect(() => {
    (async () => {
      try {
        handleContentLoadingOn();
        const detailData = await getContractDetail({
          id: Number(params.id),
        }).unwrap();

        await getContractSignatureHistories({ id: Number(params.id) });

        const { data: itemList } = await getItems({});

        await getBuyerEmployees({
          id: detailData.importerBuyerId,
        });

        initData(
          {
            ...detailData,
          },
          itemList?.rows,
        );
      } catch (e: any) {
        const error = e as ErrorResponse;
        const message = Array.isArray(error.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (error.status === 404) {
          return;
        } else {
          alert.showAlert({ type: "error", message });
        }
      } finally {
        handleContentLoadingOff();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFocus("poNo");
  }, [setFocus]);

  return (
    <ImporterMainLayout
      breadcrumb={
        !(contractDetail.isError || !isEditAuth)
          ? ["...", t("sideNav:importContractDetail"), t("sideNav:editPO")]
          : []
      }
      customPageTitle={
        !(contractDetail.isError || !isEditAuth) && (
          <HeaderInnerDiv>
            <h1>{t("sideNav:importContractEdit")}</h1>
          </HeaderInnerDiv>
        )
      }
      pageTitle={
        !(contractDetail.isError || !isEditAuth) &&
        t("sideNav:contractRegistration")
      }
    >
      {contractDetail.isError || !isEditAuth ? (
        renderUnauthorizedDescription()
      ) : (
        <ContentSection>
          <StyledForm onSubmit={handleSubmit(handleEditContract)}>
            <WarningDescription>
              <Icon iconSrc={InfoSvg} iconSize={16} />
              {t("contract:importer.add.contractAddInfo")}
            </WarningDescription>

            <Grid>
              {renderBasicInformation()}
              {renderItem()}
            </Grid>
            {renderShippingTermsInformation()}
            <Grid>
              {renderAttachmentFile()}
              {renderContractRemark()}
            </Grid>
            {renderMemo()}

            <BottomFixedContainer>
              <FooterButtonSection>
                <Button
                  buttonGrade="tertiary"
                  buttonColor="black"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  onClick={() => {
                    setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS);
                  }}
                >
                  <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
                  {t("contract:importer.add.buttonLabel.backToPrevious")}
                </Button>

                <SaveButtonContainer>
                  {(!watch("poMediaId") || !watch("poMediaId")?.length) &&
                    !contractDetail.isFetching && (
                      <>
                        <PoPreview
                          data={contractEditPoData}
                          trigger={
                            <HiddenButton
                              type="button"
                              ref={previewButtonRef}
                            />
                          }
                        />
                        <Button
                          buttonGrade="tertiary"
                          buttonColor="black"
                          isLoading={
                            contractDetail.isFetching || // 상세정보 fetching
                            isBuyersFetching || // 수출자정보 fetching
                            isPreviewLoading // PDF 미리보기 클릭시 loading
                          }
                          disabled={
                            contractDetail.isFetching || // 상세정보 fetching
                            isBuyersFetching || // 수출자정보 fetching
                            isPreviewLoading || // PDF 미리보기 클릭시 loading
                            !isEditAuth
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                          onClick={() => {
                            previewPoPdfResourceRef.current = getValues();
                            setIsPreviewLoading(true);

                            setTimeout(() => {
                              if (previewButtonRef.current) {
                                previewButtonRef.current.click();
                                setIsPreviewLoading(false);
                              }
                            }, 1000);
                          }}
                        >
                          <Icon iconSrc={PreviewSvg} iconSize={16} />
                          {t("contract:importer.add.buttonLabel.preview")}
                        </Button>
                      </>
                    )}
                  <Button
                    buttonGrade="primary"
                    style={{ width: 158 }}
                    type="submit"
                    onClick={handleSubmit(handleEditContract)}
                    disabled={!isEditAuth}
                  >
                    {t("contract:importer.add.buttonLabel.save")}
                  </Button>
                </SaveButtonContainer>
              </FooterButtonSection>
            </BottomFixedContainer>
          </StyledForm>
        </ContentSection>
      )}

      {renderDialog()}
      {renderAlertDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterContractEditPage;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const StyleButton = styled(Button)`
  ${typo.btn3m}
`;

const HeaderInnerDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  h1 {
    ${typo.h1}
  }

  ${StyleButton} {
    flex-shrink: 0;
    margin-left: auto;
  }
`;

const WarningDescription = styled.p`
  ${typo.b9r};
  color: ${colorSet.gray4};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const HalfFormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
  gap: 24px;
`;

const SubFormLabel = styled.p`
  color: ${colorSet.gray6};
  ${typo.b9r};

  strong {
    color: ${colorSet.red2};
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubFormRow = styled.div<{ isRequired?: boolean }>`
  display: flex;
  width: 100%;
  gap: 8px;

  strong {
    font-size: 12px;
    padding-left: 2px;
  }

  ${FlexDiv} {
    flex: 1;
  }

  ${SubFormLabel} {
    width: 100px;
    flex-shrink: 0;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }

  .ant-select-selection-placeholder {
    width: 1px;
  }
`;

const CustomLabel = styled.p`
  width: 164px;
  color: ${colorSet.gray6};
  ${typo.b9r};
  flex-shrink: 0;

  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const StyledCustomLabel = styled(CustomLabel)`
  color: ${colorSet.gray1};
  ${typo.b7r};
`;

const CustomFormItemRow = styled.div`
  display: flex;
  gap: 8px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const DoubleInputContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  &.fixed-width-container {
    width: calc(100% - 172px);

    ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
      width: 100%;
    }
  }
`;

const StyledSectionCard = styled(SectionCard)`
  flex: 1;
`;

const MemoHalfContainer = styled.div`
  flex: 1;
  width: calc(50% - 12px);
`;

const MemoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px;
  height: 252px;
  overflow: auto;
  word-break: normal;
  overflow-wrap: break-word;
`;

const MemoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FlexGapDiv = styled(FlexDiv)`
  gap: 24px;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const DoubleInputContainerWithError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const StyledFormItem = styled(FormItem)`
  .ant-select-selection-placeholder {
    width: 1px;
  }

  .selector-size-m {
    width: calc(100% - 164px - 8px);
  }

  .ant-picker .ant-picker-clear {
    width: 18px;
    height: 18px;
    outline: 1px solid white;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;

    .selector-size-m {
      width: 100%;
    }
  }
`;

const MemoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    display: flex;
    gap: 4px;
  }
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const DatePickerContainer = styled.div`
  &[data-invalid="true"] {
    .ant-picker {
      border: 1px solid ${colorSet.red2};
    }
  }
`;

const CustomDropdownWrapper = styled.div`
  div.ant-select-item {
    &:first-of-type {
      color: ${colorSet.blue4};

      &::before {
        content: url(${AddBlueSvg});
        width: 18px;
        height: 18px;
        position: relative;
        margin-right: 8px;
        top: 3px;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DirectInputSelectOptionButton = styled(SelectOptionButton)`
  color: ${colorSet.blue4};

  &:hover {
    &&& {
      color: ${colorSet.blue4};
    }
  }

  &::before {
    content: url(${AddBlueSvg});
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
    top: 4px;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HiddenButton = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FlexGap = styled(FlexDiv)<{ flexGap?: number }>`
  ${({ flexGap }) =>
    flexGap &&
    css`
      gap: ${flexGap}px;
    `};
`;

const Grid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const AmountContent = styled.div`
  color: ${colorSet.gray2};
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  &.flex-column {
    ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
      flex-direction: column !important;
      gap: 8px;
    }
  }
`;
