import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppSelector } from "@/src/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedExportPrivateRoute = () => {
  const user = useAppSelector((state) => state.auth.user);
  const token = useAppSelector((state) => state.auth.token);
  const search = useLocation();

  if (!user) {
    return <Navigate to={PUBLIC_PATH.LOGIN} replace />;
  }

  const getClientType = () => {
    if (isSame(user.exporterUserType, "CORPORATE_MANAGER")) {
      return user.exporter.companyType;
    }
    return user.exporterUserMainFieldType;
  };

  /* 수입자 일 때 */
  if (isSame(getClientType(), "BUYER")) {
    return <Navigate to={IMPORTER_PRIVATE_PATH.HOME} replace />;
  }

  /* BOTH 일 때, 수출자 일 때 */
  if (isSame(getClientType(), "BOTH") || isSame(getClientType(), "SELLER")) {
    const managerType = user.exporterUserType;
    const isCorporateManager = isSame(managerType, "CORPORATE_MANAGER");
    const hasExportPlan = true;
    const hasPlanHistory = true;

    // 플랜 가입 안되어있고, 플랜 사용 내역도 존재 안했을때
    if (!hasExportPlan && !hasPlanHistory) {
      // 기업 관리자
      if (isCorporateManager) {
        return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} replace />; // 구독 가입 페이지로
      }
      // 중간 관리자, 일반 매니저
      return <Navigate to={PUBLIC_PATH.NOT_FOUND} replace />;
    }
  }

  if (
    token &&
    (search.pathname === EXPORTER_PRIVATE_PATH.ROOT ||
      search.pathname === PUBLIC_PATH.ROOT)
  ) {
    return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} replace />;
  }

  return <Outlet />;
};

export default ProtectedExportPrivateRoute;

function isSame<T>(x: T, y: T): boolean {
  return x === y;
}
