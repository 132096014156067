import DownloadArrowSvg from "@/src/assets/icons/icon-arrow-download.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import { IconButton } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Dialog from "@/src/components/atom/Dialog";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import RadixTooltip from "@/src/components/atom/Tooltip/RadixTooltip";
import Typo from "@/src/components/atom/Typo";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import TabItem from "@/src/components/molecule/TabItem";
import LoadingPhotoPreviewDialog from "@/src/components/organism/LoadingPhotoPreviewDialog";
import useAlert from "@/src/hooks/useAlert";
import {
  renderContainerIcon,
  renderContainerText,
} from "@/src/pages/exporter/Task/components/detail/utils/renderContainerType";
import {
  useContainerIdsFullBatchDownloadMutation,
  useShipmentIdWithMediaTypeFullBatchDownloadMutation,
} from "@/src/store/apis/containerImage";
import {
  useLazyGetShipmentContainerMediaByPhotoTypeQuery,
  useLazyGetShipmentContainersListQuery,
  useLazyGetSingleShipmentContainerMediaQuery,
} from "@/src/store/apis/shipments/shipmentDetail";
import { MediaByPhotoTypeDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { ContainerMediaType } from "@/src/store/apis/tasks/taskDetail/interface";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { downloadFile } from "@/src/utils/downloadFile";
import { toPascalCase } from "@/src/utils/transform";
import dayjs from "dayjs";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css, styled } from "styled-components";
import {
  ContainerPhotoListType,
  CONTAINER_VALUE_TYPE_CONTAINER_LABEL_TYPE_MAP,
  MEDIA_CONTAINER_MAP,
  MEDIA_COUNT_KEY_LIST,
  MEDIA_MAP,
  MEDIA_UPLOAD_COUNT_MAP,
  PHOTO_TYPE_CONTAINER_LABEL_TYPE_MAP,
  PHOTO_TYPE_CONTAINER_VALUE_TYPE_MAP,
  PHOTO_TYPE_LIST,
} from "./imageMapper";
import InformationItemTooltip from "./InformationItemTooltip";

interface ContainerPhotosDialogProps {
  onClose?: () => void;
  shipmentId: number;
}

const ContainerPhotosDialog = ({
  onClose,
  shipmentId,
}: ContainerPhotosDialogProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const isRenderPhotoTypeTab = useRef<boolean>(false);
  const [tab, setTab] = useState<"container" | "photoType">("container");
  const [selectedContainers, setSelectedContainers] = useState<number[]>([]);
  const [selectedSingleContainer, setSelectedSingleContainer] =
    useState<number>();
  const [selectedPhotoType, setSelectedPhotoType] =
    useState<ContainerMediaType>();
  const [selectedPhotoTypes, setSelectedPhotoTypes] = useState<
    ContainerMediaType[]
  >([]);
  const [selectedImageSrc, setSelectedImageSrc] = useState<{
    src: string;
    createdAt: string;
    latitude?: string;
    longitude?: string;
    zoomLevel?: number;
    mediaCoordinateRange?: MediaCoordinateRangeType;
    originalFileName?: string;
  }>();

  const [isPreviewDownloadLoading, setIsPreviewDownloadLoading] =
    useState(false);
  const [containerListLazyLoading, setContainerListLazyLoading] =
    useState(false);
  const [containerContentLazyLoading, setContainerContentLazyLoading] =
    useState(false);

  const [photoTypeListLazyLoading, setPhotoTypeListLazyLoading] =
    useState(false);

  const [getShipmentContainerList, shipmentContainerList] =
    useLazyGetShipmentContainersListQuery();
  const [getSingleContainerMedia, singleContainerMediaResult] =
    useLazyGetSingleShipmentContainerMediaQuery();
  const [getContainerMediaByPhotoType, containerMediaByPhotoTypeResult] =
    useLazyGetShipmentContainerMediaByPhotoTypeQuery();

  // Image Download API
  const [containerTypeImagesDownload, containerTypeImagesDownloadData] =
    useContainerIdsFullBatchDownloadMutation();
  const [mediaTypeImagesDownload, mediaTypeImagesDownloadData] =
    useShipmentIdWithMediaTypeFullBatchDownloadMutation();

  const addSelectContainer = (value: number) => {
    setSelectedContainers((prev) => [...prev, value]);
  };

  const removeSelectContainer = (value: number) => {
    setSelectedContainers((prev) => prev.filter((item) => item !== value));
  };

  const addAllSelectContainer = () => {
    setSelectedContainers(
      shipmentContainerList.currentData?.map(({ id }) => id) ?? [],
    );
  };

  const removeAllSelectContainer = () => {
    setSelectedContainers([]);
  };

  const addSelectPhotoType = (value: ContainerMediaType) => {
    setSelectedPhotoTypes((prev) => [...prev, value]);
  };

  const removeSelectPhotoType = (value: ContainerMediaType) => {
    setSelectedPhotoTypes((prev) => prev.filter((item) => item !== value));
  };

  const addAllSelectPhotoType = () => {
    setSelectedPhotoTypes(
      Object.entries(containerMediaByPhotoTypeResult.currentData ?? {})
        .filter(([_, val]) => !!val)
        .map(
          ([type]) =>
            PHOTO_TYPE_CONTAINER_VALUE_TYPE_MAP[
              type as (typeof PHOTO_TYPE_LIST)[number]
            ],
        ),
    );
  };

  const handleMediaTypeImageDownloadClick = async () => {
    if (selectedPhotoTypes.length === 0) {
      return;
    }

    try {
      await mediaTypeImagesDownload({
        id: Number(params.id),
        containerMediaTypeList: selectedPhotoTypes,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContainerTypeImageDownloadClick = async () => {
    if (selectedContainers.length === 0) {
      return;
    }

    try {
      await containerTypeImagesDownload({
        containerIdList: selectedContainers,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const removeAllSelectPhotoType = () => {
    setSelectedPhotoTypes([]);
  };

  const containerPhotoList = singleContainerMediaResult.isSuccess
    ? Object.entries(singleContainerMediaResult.data).reduce<
        ContainerPhotoListType[]
      >((acc, [key, val]) => {
        if (
          MEDIA_COUNT_KEY_LIST.includes(
            key as (typeof MEDIA_COUNT_KEY_LIST)[number],
          )
        ) {
          const mediaCountKey = key as (typeof MEDIA_COUNT_KEY_LIST)[number];
          const containerType = MEDIA_CONTAINER_MAP[mediaCountKey];

          return [
            ...acc,
            {
              isActive: val !== 0,
              maxCount: val,
              uploadedCount: (singleContainerMediaResult.data as any)[
                MEDIA_UPLOAD_COUNT_MAP[mediaCountKey]
              ],
              imageList: (singleContainerMediaResult.data as any)[
                MEDIA_MAP[mediaCountKey]
              ],
              containerType: containerType,
              title: renderContainerText(t, containerType, "h6"),
              icon: renderContainerIcon(containerType),
              extraMediaRemark:
                singleContainerMediaResult.data.extraMediaRemark,
            },
          ];
        }

        return acc;
      }, [])
    : [];

  const containerPhotoListByPhotoType =
    containerMediaByPhotoTypeResult.isSuccess
      ? (Object.entries(containerMediaByPhotoTypeResult.currentData ?? {})
          .filter(([_, val]) => !!val)
          .find(([key]) => {
            return (
              PHOTO_TYPE_CONTAINER_VALUE_TYPE_MAP[
                key as (typeof PHOTO_TYPE_LIST)[number]
              ] === selectedPhotoType
            );
          })?.[1] ?? [])
      : [];

  const handlePreviewDownloadClick = async () => {
    try {
      setIsPreviewDownloadLoading(true);
      const selectUrl = {
        ...selectedImageSrc,
        mediaUrl: selectedImageSrc?.src,
      };

      if (!selectUrl?.mediaUrl) {
        return;
      }

      await downloadFile(
        selectUrl?.mediaUrl ?? "",
        selectUrl?.originalFileName ?? "",
      );
    } finally {
      setIsPreviewDownloadLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getShipmentContainerList({
          id: shipmentId,
        }).unwrap();
        if (response) {
          setSelectedSingleContainer(response[0].id);
        }
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        alert.showAlert({ type: "error", message });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "photoType" && !isRenderPhotoTypeTab.current) {
      isRenderPhotoTypeTab.current = true;
      (async () => {
        try {
          const response = await getContainerMediaByPhotoType({
            id: shipmentId,
          }).unwrap();

          const validField = Object.entries(response).filter(
            ([_, val]) => !!val,
          )[0];

          const validValue =
            PHOTO_TYPE_CONTAINER_VALUE_TYPE_MAP[
              validField[0] as (typeof PHOTO_TYPE_LIST)[number]
            ];

          setSelectedPhotoType(validValue);
        } catch (e: any) {
          const message = Array.isArray(e.data.message)
            ? e.data.message[0]
            : e.data.message;

          alert.showAlert({ type: "error", message });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!shipmentContainerList.isFetching) {
      setTimeout(() => {
        setContainerListLazyLoading(false);
      }, 300);
    } else {
      setContainerListLazyLoading(true);
    }
  }, [shipmentContainerList.isFetching]);

  useEffect(() => {
    if (!singleContainerMediaResult.isFetching) {
      setTimeout(() => {
        setContainerContentLazyLoading(false);
      }, 300);
    } else {
      setContainerContentLazyLoading(true);
    }
  }, [singleContainerMediaResult.isFetching]);

  useEffect(() => {
    if (!containerMediaByPhotoTypeResult.isFetching) {
      setTimeout(() => {
        setPhotoTypeListLazyLoading(false);
      }, 300);
    } else {
      setPhotoTypeListLazyLoading(true);
    }
  }, [containerMediaByPhotoTypeResult.isFetching]);

  useEffect(() => {
    if (selectedSingleContainer) {
      (async () => {
        try {
          await getSingleContainerMedia({
            id: selectedSingleContainer,
          }).unwrap();
        } catch (e: any) {
          const message = Array.isArray(e.data.message)
            ? e.data.message[0]
            : e.data.message;

          alert.showAlert({ type: "error", message });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getShipmentContainerList, selectedSingleContainer, shipmentId]);

  const renderCheckboxListByTabType = () => {
    if (tab === "container") {
      return (
        <>
          <AllContainerCell>
            <Checkbox
              disabled={
                containerListLazyLoading ||
                !shipmentContainerList.currentData?.length
              }
              checked={
                selectedContainers.length ===
                shipmentContainerList.currentData?.length
              }
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  addAllSelectContainer();
                } else {
                  removeAllSelectContainer();
                }
              }}
            />
            <Typo color="gray4" typoType="b7r">
              {t("common:containerNo")}
            </Typo>
          </AllContainerCell>
          <ContainerList>
            {containerListLazyLoading ? (
              <FlexCenterContainer>
                <Loader />
              </FlexCenterContainer>
            ) : (
              <>
                {shipmentContainerList.currentData?.map(
                  ({ id, containerNo }) => {
                    return (
                      <ContainerItem
                        className="padding-12"
                        data-selected={id === selectedSingleContainer}
                        onClick={() => {
                          setSelectedSingleContainer(id);
                        }}
                      >
                        <Checkbox
                          checked={selectedContainers.includes(id)}
                          style={{ flexShrink: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();

                            const isSelected = selectedContainers.includes(id);
                            if (isSelected) {
                              return removeSelectContainer(id);
                            }
                            addSelectContainer(id);
                          }}
                        />
                        <Typo
                          color="gray4"
                          typoType="b7r"
                          className="ellipsis-label"
                        >
                          {containerNo ?? "-"}
                        </Typo>
                      </ContainerItem>
                    );
                  },
                ) ?? null}
              </>
            )}
          </ContainerList>
          {!containerListLazyLoading &&
            !!shipmentContainerList.currentData?.length && (
              <DownloadButton
                onClick={handleContainerTypeImageDownloadClick}
                disabled={containerTypeImagesDownloadData.isLoading}
              >
                {containerTypeImagesDownloadData.isLoading ? (
                  <ImageDownloadContainer>
                    <Loader />
                  </ImageDownloadContainer>
                ) : (
                  <>
                    <Icon iconSrc={DownloadArrowSvg} iconSize={18} />
                    {t("shipment:exporter.detail.button.downloadButton")}
                  </>
                )}
              </DownloadButton>
            )}
        </>
      );
    }

    if (tab === "photoType") {
      return (
        <>
          <AllContainerCell>
            <Checkbox
              disabled={photoTypeListLazyLoading}
              checked={
                selectedPhotoTypes.length ===
                Object.entries(
                  containerMediaByPhotoTypeResult?.currentData ?? {},
                ).filter(([_, val]) => !!val).length
              }
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  addAllSelectPhotoType();
                } else {
                  removeAllSelectPhotoType();
                }
              }}
            />
            <Typo color="gray4" typoType="b7r">
              {t("common:photoType")}
            </Typo>
          </AllContainerCell>

          <ContainerList>
            {photoTypeListLazyLoading ? (
              <FlexCenterContainer>
                <Loader />
              </FlexCenterContainer>
            ) : (
              <>
                {Object.entries(
                  containerMediaByPhotoTypeResult.currentData ?? {},
                )
                  .filter(([_, val]) => !!val)
                  .map(([key]) => {
                    return {
                      label:
                        PHOTO_TYPE_CONTAINER_LABEL_TYPE_MAP(t)[
                          key as (typeof PHOTO_TYPE_LIST)[number]
                        ],
                      value:
                        PHOTO_TYPE_CONTAINER_VALUE_TYPE_MAP[
                          key as (typeof PHOTO_TYPE_LIST)[number]
                        ],
                    };
                  })
                  .map(({ label, value }) => {
                    return (
                      <ContainerItem
                        className="padding-12"
                        data-selected={value === selectedPhotoType}
                        onClick={() => {
                          setSelectedPhotoType(value);
                        }}
                      >
                        <Checkbox
                          checked={selectedPhotoTypes.includes(value)}
                          style={{ flexShrink: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();

                            const isSelected =
                              selectedPhotoTypes.includes(value);
                            if (isSelected) {
                              return removeSelectPhotoType(value);
                            }
                            addSelectPhotoType(value);
                          }}
                        />
                        <Typo
                          color="gray4"
                          typoType="b7r"
                          className="ellipsis-label"
                        >
                          {label}
                        </Typo>
                      </ContainerItem>
                    );
                  })}
              </>
            )}
          </ContainerList>
          {!photoTypeListLazyLoading && (
            <DownloadButton
              onClick={handleMediaTypeImageDownloadClick}
              disabled={mediaTypeImagesDownloadData.isLoading}
            >
              {mediaTypeImagesDownloadData.isLoading ? (
                <ImageDownloadContainer>
                  <Loader />
                </ImageDownloadContainer>
              ) : (
                <>
                  <Icon iconSrc={DownloadArrowSvg} iconSize={18} />
                  {t("shipment:exporter.detail.button.downloadButton")}
                </>
              )}
            </DownloadButton>
          )}
        </>
      );
    }
  };

  const renderImageListByTabType = () => {
    if (tab === "container") {
      const totalMaxCount = containerPhotoList
        .filter(({ isActive }) => isActive)
        .reduce((acc, val) => acc + val.maxCount, 0);

      const totalUploadCount = containerPhotoList
        .filter(({ isActive }) => isActive)
        .reduce((acc, val) => acc + val.uploadedCount, 0);

      const isDashValue =
        !singleContainerMediaResult.isSuccess ||
        singleContainerMediaResult.isFetching ||
        singleContainerMediaResult.isError;

      return (
        <RestWidthFlexItem>
          <MiddleTitleSection>
            <p className="ellipsis-header">
              <Typo typoType="h6">
                {isDashValue
                  ? "-"
                  : (singleContainerMediaResult.currentData.containerNo ?? "-")}
              </Typo>
            </p>
            <p>
              <Typo typoType="b8m" color="gray5" className="flex-shrink-0">
                {t("common:total")} {totalUploadCount} / {totalMaxCount}
              </Typo>
            </p>
          </MiddleTitleSection>

          <MiddleRestSection>
            {containerContentLazyLoading ? (
              <FlexCenterContainer>
                <Loader size={50} />
              </FlexCenterContainer>
            ) : (
              <>
                {containerPhotoList
                  .filter(({ isActive }) => isActive)
                  .map(
                    ({
                      title,
                      icon,
                      maxCount,
                      uploadedCount,
                      imageList,
                      containerType,
                      extraMediaRemark,
                    }) => {
                      return (
                        <ContainerItemArticle tabType={tab}>
                          <header>
                            <TitleWithIcon>
                              {icon}
                              <Typo
                                typoType="h6"
                                className="ellipsis-middle-header"
                              >
                                {title}
                                {containerType === "EXTRA" && (
                                  <TooltipContainer>
                                    <RadixTooltip
                                      content={
                                        <TooltipContent>
                                          <span>{t("common:remark")}</span>

                                          {extraMediaRemark && (
                                            <span>{extraMediaRemark}</span>
                                          )}
                                        </TooltipContent>
                                      }
                                    >
                                      <InfoIcon color={"gray7"} />
                                    </RadixTooltip>
                                  </TooltipContainer>
                                )}
                              </Typo>
                            </TitleWithIcon>
                            <Typo
                              typoType="b8m"
                              color="gray5"
                              className="flex-shrink-0"
                            >
                              {t("common:total")} {uploadedCount} / {maxCount}
                            </Typo>
                          </header>

                          <ImageSection>
                            {!!imageList?.length ? (
                              <>
                                {imageList.map(
                                  (
                                    { mediaUrl, createdAt, coordinate },
                                    idx,
                                  ) => {
                                    const containerNo =
                                      singleContainerMediaResult?.currentData
                                        ?.containerNo || "-";

                                    return (
                                      <StyledImage
                                        onClick={() =>
                                          setSelectedImageSrc({
                                            src: mediaUrl,
                                            createdAt: createdAt,
                                            latitude: coordinate?.latitude,
                                            longitude: coordinate?.longitude,
                                            zoomLevel: coordinate?.zoomLevel
                                              ? Number(coordinate?.zoomLevel)
                                              : undefined,
                                            mediaCoordinateRange: "ALL",
                                            originalFileName: `${containerNo}_${toPascalCase(
                                              containerType,
                                            )}${String(idx + 1).padStart(
                                              2,
                                              "0",
                                            )}.jpg`,
                                          })
                                        }
                                      >
                                        <img
                                          src={mediaUrl}
                                          alt={`${containerType}_${idx}`}
                                        />
                                      </StyledImage>
                                    );
                                  },
                                )}
                              </>
                            ) : (
                              <NoDataContainer>
                                {renderNoRowsComponent()}
                              </NoDataContainer>
                            )}
                          </ImageSection>
                        </ContainerItemArticle>
                      );
                    },
                  )}
              </>
            )}
          </MiddleRestSection>
        </RestWidthFlexItem>
      );
    }

    const containerMediaListByPhotoType = Object.entries(
      containerMediaByPhotoTypeResult.currentData ?? {},
    )
      .filter(([key, val]) => !!val)
      .reduce<MediaByPhotoTypeDto[]>((acc, [_, val]) => {
        return [...acc, ...val];
      }, []);

    const totalContainerMediaListByPhotoType =
      containerMediaListByPhotoType.reduce(
        (acc, val) => acc + val.requiredContainerMediasCount,
        0,
      );

    const uploadedCountOfContainerMediaListByPhotoType =
      containerMediaListByPhotoType.reduce(
        (acc, val) => acc + val.uploadedContainerMediasCount,
        0,
      );

    return (
      <RestWidthFlexItem>
        <MiddleTitleSection>
          <p className="ellipsis-header">
            <Typo typoType="h6">
              {selectedPhotoType
                ? CONTAINER_VALUE_TYPE_CONTAINER_LABEL_TYPE_MAP(t)[
                    selectedPhotoType
                  ]
                : "-"}
            </Typo>
          </p>
          <p>
            <Typo typoType="b8m" color="gray5" className="flex-shrink-0">
              {t("common:total")}{" "}
              {`${uploadedCountOfContainerMediaListByPhotoType} / ${totalContainerMediaListByPhotoType}`}
            </Typo>
          </p>
        </MiddleTitleSection>

        <MiddleRestSection>
          {photoTypeListLazyLoading ? (
            <FlexCenterContainer>
              <Loader size={50} />
            </FlexCenterContainer>
          ) : (
            <>
              {(containerPhotoListByPhotoType as MediaByPhotoTypeDto[]).map(
                ({
                  containerNo,
                  requiredContainerMediasCount,
                  uploadedContainerMediasCount,
                  shipmentContainerMediaInfoList = [],
                  extraMediaRemark,
                }) => {
                  return (
                    <ContainerItemArticle tabType={tab}>
                      <header>
                        <Typo typoType="h6" className="ellipsis-middle-header">
                          {containerNo ?? "-"}
                          {selectedPhotoType === "EXTRA" && (
                            <TooltipContainer>
                              <RadixTooltip
                                content={
                                  <TooltipContent>
                                    <span>{t("common:remark")}</span>

                                    {extraMediaRemark && (
                                      <span>{extraMediaRemark}</span>
                                    )}
                                  </TooltipContent>
                                }
                              >
                                <InfoIcon color={"gray7"} />
                              </RadixTooltip>
                            </TooltipContainer>
                          )}
                        </Typo>
                        <Typo
                          typoType="b8m"
                          color="gray5"
                          className="flex-shrink-0"
                        >
                          {t("common:total")}{" "}
                          {`${uploadedContainerMediasCount} / ${requiredContainerMediasCount}`}
                        </Typo>
                      </header>

                      <ImageSection>
                        {!!shipmentContainerMediaInfoList?.length ? (
                          <>
                            {shipmentContainerMediaInfoList.map(
                              (
                                {
                                  mediaUrl,
                                  containerMediaType,
                                  createdAt,
                                  coordinate,
                                },
                                idx,
                              ) => {
                                return (
                                  <StyledImage
                                    onClick={() =>
                                      setSelectedImageSrc({
                                        src: mediaUrl,
                                        createdAt: createdAt,
                                        latitude: coordinate?.latitude,
                                        longitude: coordinate?.longitude,
                                        zoomLevel: coordinate?.zoomLevel
                                          ? Number(coordinate?.zoomLevel)
                                          : undefined,
                                        mediaCoordinateRange: "ALL",
                                        originalFileName: `${
                                          containerNo || "-"
                                        }_${toPascalCase(
                                          selectedPhotoType || "",
                                        )}${String(idx + 1).padStart(
                                          2,
                                          "0",
                                        )}.jpg`,
                                      })
                                    }
                                  >
                                    <img
                                      src={mediaUrl}
                                      alt={`${containerMediaType}_${idx}`}
                                    />
                                  </StyledImage>
                                );
                              },
                            )}
                          </>
                        ) : (
                          <NoDataContainer>
                            {renderNoRowsComponent()}
                          </NoDataContainer>
                        )}
                      </ImageSection>
                    </ContainerItemArticle>
                  );
                },
              )}
            </>
          )}
        </MiddleRestSection>
      </RestWidthFlexItem>
    );
  };

  const renderInformation = () => {
    const isDashValue =
      !singleContainerMediaResult.isSuccess ||
      singleContainerMediaResult.isFetching ||
      singleContainerMediaResult.isError;

    return (
      <>
        {[
          {
            label: t("common:containerNo"),
            value: (
              <Typo typoType="h6" color="blue2">
                {isDashValue
                  ? "-"
                  : (singleContainerMediaResult.currentData.containerNo ?? "-")}
              </Typo>
            ),
          },
          {
            label: t("common:sealNo"),
            value: (
              <Typo typoType="h6" color="blue2">
                {isDashValue
                  ? "-"
                  : (singleContainerMediaResult.currentData.sealNo ?? "-")}
              </Typo>
            ),
          },
          { isGrayDivider: true },
          {
            label: t("shipment:exporter.dialog.label.workingDay"),
            value: isDashValue
              ? "-"
              : dayjs(
                  singleContainerMediaResult.currentData.workingDayAt,
                ).format("YYYY-MM-DD"),
          },
          {
            label: t("shipment:exporter.dialog.label.factory"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.workplaceName,
            isTooltip: true,
          },
          {
            label: t("common:buyer"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.buyerName,
            isTooltip: true,
          },
          {
            label: t("common:item"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.item,
            isTooltip: true,
          },
          {
            label: t("common:itemCode"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.itemCode,
            isTooltip: true,
          },
          {
            label: t("common:netWeight"),
            value: isDashValue
              ? "-"
              : (singleContainerMediaResult.currentData.netWeight ?? "-"),
          },
        ].map(({ label, value, isGrayDivider = false, isTooltip }) => {
          if (isGrayDivider) {
            return <Divider />;
          }
          return (
            <InformationItem
              label={label}
              value={value}
              isTooltip={isTooltip}
            />
          );
        })}
      </>
    );
  };

  const renderDialog = () => {
    if (!selectedImageSrc) return null;
    const { src, ...rest } = selectedImageSrc;

    return (
      // 수출자 > 선적상세 > 컨테이너 > 전체보기 > 단일항목 사진조회
      <LoadingPhotoPreviewDialog
        open
        onOpenChange={() => setSelectedImageSrc(undefined)}
        selectImage={{ ...rest, mediaUrl: src }}
        mediaCoordinateRange="ALL"
        photoDetailRightAccessory={
          <IconButton
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={handlePreviewDownloadClick}
            isLoading={isPreviewDownloadLoading}
          >
            <DownloadIcon />
          </IconButton>
        }
      />
    );
  };

  return (
    <>
      <Dialog
        title={t("shipment:exporter.dialog.title.ViewPhotosOfContainerWork")}
        open
        onOpenChange={() => {
          onClose?.();
        }}
        width={1200}
      >
        <InnerContent>
          <FlexBox>
            <FixedWidthFlexItem>
              <TabsContainer>
                <Tabs role="tablist">
                  <StyledTabItem
                    tabValue={"container"}
                    onFocusItem={() => {}}
                    tabIndex={tab === "container" ? 0 : -1}
                    onFocus={() => setTab("container")}
                    onClick={() => setTab("container")}
                    data-selected={tab === "container"}
                  >
                    <Typo typoType="b7m">{t("common:container")}</Typo>
                  </StyledTabItem>
                  <StyledTabItem
                    tabValue={"photoType"}
                    onFocusItem={() => {}}
                    tabIndex={tab === "photoType" ? 0 : -1}
                    onFocus={() => setTab("photoType")}
                    onClick={() => setTab("photoType")}
                    data-selected={tab === "photoType"}
                  >
                    <Typo typoType="b7m">{t("common:photoType")}</Typo>
                  </StyledTabItem>
                </Tabs>
              </TabsContainer>

              {renderCheckboxListByTabType()}
            </FixedWidthFlexItem>

            {renderImageListByTabType()}

            {tab === "container" && (
              <FixedWidthFlexItem>
                <InformationTitleSection>
                  <Typo typoType="h6">
                    {t("shipment:exporter.dialog.label.information")}
                  </Typo>
                </InformationTitleSection>

                <MiddleRestSection className="gap-24">
                  {renderInformation()}
                </MiddleRestSection>
              </FixedWidthFlexItem>
            )}
          </FlexBox>
        </InnerContent>
        {renderDialog()}
      </Dialog>
    </>
  );
};

export default ContainerPhotosDialog;

const InnerContent = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const FlexBox = styled.div`
  display: flex;
`;

const FixedWidthFlexItem = styled.div`
  width: 220px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  &:first-of-type {
    border-right: 1px solid ${colorSet.gray9};
  }

  &:last-of-type {
    border-left: 1px solid ${colorSet.gray9};
  }
`;
const RestWidthFlexItem = styled.div`
  flex: 1;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 60px;
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 13px;
  border: none;
  cursor: pointer;
  background: none;

  &[data-selected="true"] {
    padding: 10px 13px 8px 13px;
    border-bottom: 2px solid ${colorSet.gray2};
  }
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &.padding-12 {
    padding: 12px;
  }
`;

const ContainerItem = styled(FlexAlignCenter)`
  cursor: pointer;

  .ellipsis-label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background: ${colorSet.gray10};
  }

  &[data-selected="true"] {
    background: ${colorSet.systemBlue6};
  }
`;

const AllContainerCell = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${colorSet.gray11};
  border-top: 1px solid ${colorSet.gray9};
  border-bottom: 1px solid ${colorSet.gray9};
`;

const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  height: 596px;
  overflow: auto;
  position: relative;
`;

const DownloadButton = styled.button`
  position: absolute;
  all: unset;
  box-sizing: border-box;
  padding: 12px;
  background: ${colorSet.indigo};
  color: ${colorSet.white};
  border-radius: 0 0 0 8px;
  cursor: pointer;
  ${typo.b8m};
  display: flex;
  gap: 8px;
  align-items: center;

  &:disabled {
    background: ${colorSet.gray10};
    cursor: not-allowed;
  }
`;

const MiddleTitleSection = styled.div`
  width: 100%;
  height: 61px;
  padding: 16px 16px 10px 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background: ${colorSet.gray11};
  border-bottom: 1px solid ${colorSet.gray9};

  .ellipsis-header {
    max-width: 617px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const MiddleRestSection = styled.div`
  flex: 1;
  height: 689px;
  overflow: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.gap-24 {
    gap: 24px;
  }
`;

const ContainerItemArticle = styled.article<{
  tabType: "container" | "photoType";
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${colorSet.gray9};

    .flex-shrink-0 {
      flex-shrink: 0;
    }

    .ellipsis-middle-header {
      max-width: ${({ tabType }) =>
        tabType === "container" ? "609" : "821"}px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const StyledImage = styled.div`
  width: 209px;
  height: 209px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
  overflow: hidden;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const ImageSection = styled.section`
  display: flex;
  gap: 16px;
`;

const InformationTitleSection = styled.div`
  height: 61px;
  padding: 16px 16px 10px 16px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const Divider = styled.div<{ gap?: number }>`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
  ${({ gap }) =>
    gap &&
    css`
      margin: ${gap}px 0;
    `};
`;

const InformationItem = ({
  label,
  value,
  isTooltip,
}: {
  label: ReactNode;
  value: ReactNode;
  isTooltip?: boolean;
}) => {
  if (isTooltip) {
    return (
      <InformationItemContainer>
        <Typo typoType="b9m" color="gray6">
          {label}
        </Typo>

        <InformationItemTooltip
          trigger={
            <Typo typoType="h6" color="gray2" className="ellipsis">
              {value}
            </Typo>
          }
          content={
            <BreakWordTypo color="white" typoType="b10m">
              {value}
            </BreakWordTypo>
          }
        />
      </InformationItemContainer>
    );
  }

  return (
    <InformationItemContainer>
      <Typo typoType="b9m" color="gray6">
        {label}
      </Typo>
      <Typo typoType="h6" color="gray2" className="ellipsis">
        {value}
      </Typo>
    </InformationItemContainer>
  );
};

const InformationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NoDataContainer = styled.div`
  height: 210px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TitleWithIcon = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ImageDownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const TooltipContent = styled.div`
  background: ${colorSet.gray1};
  opacity: 80%;
  max-width: 494px;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${typo.b10m};
  color: ${colorSet.white};
`;

const TooltipContainer = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 3px;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;
