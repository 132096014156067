import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import { ReactComponent as DotGray7Svg } from "@/src/assets/icons/icon-dot-gray7.svg";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import { Button } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import Label from "@/src/components/atom/Label";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import FormItem from "@/src/components/molecule/FormItem";
import GridCardRadio from "@/src/components/molecule/GridCardRadio";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import {
  CARD_REGISTER_TYPE_OPTION_LIST,
  REGISTER_TYPE_OPTION_LIST,
} from "@/src/constant/optionList";

import useAlert from "@/src/hooks/useAlert";
import { authApi } from "@/src/store/apis/auth";
import {
  subscriptionApi,
  useCreateSubscriptionsMutation,
} from "@/src/store/apis/subscription";
import {
  PaymentCycleType,
  PlanDto,
  SubscriptionStatusType,
  SubscriptionType,
} from "@/src/store/apis/subscription/interface";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  CARD_NUMBER_CHECK_LENGTH,
  DEFAULT_CARD_NUMBER,
} from "../../NormalPlanSignup/components/PaymentInformation";
import { convertPaymentTypeToString } from "../../NormalPlanSignup/utils";
import {
  CardType,
  RegisterType,
} from "../../SubscriptionManagement/components/dialog/AddPaymentMethodsDialog";
import {
  isValidBirthDay,
  isValidExpirationDate,
} from "../../SubscriptionManagement/utils/isValidExpirationDate";
import {
  useEnterPriseSignupDateContext,
  useEnterPriseSignupStepContext,
} from "../hooks";

const planEmptyArray: PlanDto[] = [];

function EnterprisePaymentRegistration() {
  const { t } = useTranslation();
  const alert = useAlert();
  const inputContainerRef = useRef<HTMLDivElement | null>(null);

  const { onStepChange } = useEnterPriseSignupStepContext();
  const { onSubscriptionsDateChange } = useEnterPriseSignupDateContext();

  // API
  const [createSubscriptions, { isLoading: isSubscribeLoading }] =
    useCreateSubscriptionsMutation();
  const { enterprisePlans } = subscriptionApi.endpoints.getPlans.useQueryState(
    {
      planType: "ENTERPRISE",
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          enterprisePlans: isStable ? currentData?.rows : planEmptyArray,
        };
      },
    }
  );
  const { businessNumber, exporterId } =
    authApi.endpoints.getSession.useQueryState(undefined, {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          businessNumber: isStable
            ? currentData?.row.exporter.businessNumber
            : "",
          exporterId: currentData?.row.exporter.id,
        };
      },
    });

  const [isConfirm, setIsConfirm] = useState(false);
  const [cardNumber, setCardNumber] = useState(DEFAULT_CARD_NUMBER);
  const { handleSubmit, control, watch, getValues, setValue, clearErrors } =
    useForm({
      mode: "onBlur",
      defaultValues: {
        cardType: "CORPORATE_CARD" as CardType,
        password2: "",
        expiredDate: "",
        registerType: "BUSINESS" as RegisterType,
        userInfo: "",
        businessNumber,
      },
    });

  const isButtonDisabled =
    isSubscribeLoading ||
    !isConfirm ||
    cardNumber.join("").length <= CARD_NUMBER_CHECK_LENGTH ||
    watch("password2").length !== 2 ||
    watch("expiredDate").length !== 5 ||
    (watch("registerType") === "PERSONAL" && watch("userInfo").length !== 6);

  const goToPrev = () => {
    onStepChange("ADMIN_CONFIRM_COMPLETED");
  };

  const goToNext = () => {
    onStepChange("COMPLETED");
  };

  const handleSubmitClick = async () => {
    if (!exporterId) {
      return;
    }

    const enterprisePlan = enterprisePlans[0];
    const expiryDateYear = getValues("expiredDate").substring(3, 5);
    const expiryDateMonth = getValues("expiredDate").substring(0, 2);

    const params = {
      subscriptionStatus: "PROGRESS" as SubscriptionStatusType,
      subscriptionType: "STANDARD" as SubscriptionType,
      paymentCycle: enterprisePlan?.planVersionList?.[0]
        .paymentCycle as PaymentCycleType,
      planId: enterprisePlan.id,
      planVersionId: enterprisePlan?.planVersionList?.[0].id as number,
      cardNumber: cardNumber.join(""),
      // 기업일경우와 카드종류가 법인명 카드일경우 비즈니스 넘버
      // 개인일경우 생년월일
      userInfo:
        getValues("registerType") === "BUSINESS" &&
        getValues("cardType") === "CORPORATE_CARD"
          ? businessNumber
          : getValues("userInfo"),
      expiryDate: `${expiryDateYear}${expiryDateMonth}`,
      password2: getValues("password2"),
      isRepresentative: true,
      exporterId,
    };

    try {
      const res = await createSubscriptions(params).unwrap();

      goToNext();
      onSubscriptionsDateChange({
        planName: res.row.plan.name,
        subscriptionStartAtFrom: res.row.subscriptionStartAtFrom,
        subscriptionEndAtTo: res.row.subscriptionEndAtTo,
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleExpiredDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, "");

    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4);
    }
    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    }

    setValue("expiredDate", inputValue);
  };

  const handleCardNumberInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    idx: number
  ) => {
    const isBackSpaceKeyDown = e.code === "Backspace";

    const nextInput = inputContainerRef.current?.childNodes[idx].nextSibling
      ?.firstChild as HTMLInputElement;
    const prevInput = inputContainerRef.current?.childNodes[idx].previousSibling
      ?.firstChild as HTMLInputElement;

    if (cardNumber[idx].length === 0 && prevInput && isBackSpaceKeyDown) {
      prevInput.focus();
    }

    if (cardNumber[idx].length === 4 && nextInput && !isBackSpaceKeyDown) {
      nextInput.focus();
    }
  };

  const renderPlanInformationCard = () => {
    const enterprisePlan = enterprisePlans[0];
    const { name, planVersionList } = enterprisePlan;
    const {
      paymentCycle,
      monthlyFee,
      annualFee,
      currencyUnit,
      numberOfMember,
      numberOfLoadingCode,
    } = planVersionList?.[0] as {
      paymentCycle: PaymentCycleType;
      annualFee: string | null;
      monthlyFee: string | null;
      currencyUnit: string;
      numberOfMember: number;
      numberOfLoadingCode: number;
    };

    const paymentPrice = paymentCycle === "MONTHLY" ? monthlyFee : annualFee;

    return (
      <CallOut backgroundColor="white">
        <FlexColumn gap={16}>
          <FlexColumn>
            <Typo>{name}</Typo>
            <Typo typoType="h4">
              {convertPaymentTypeToString(paymentCycle, t)}{" "}
              {Number(paymentPrice).toLocaleString("ko-KR")} {currencyUnit}
            </Typo>

            <FlexRow gap={6}>
              <InfoIcon />
              <Typo typoType="b9r" color="blue4">
                {t("enterpriseSignup:subscriptionNotice")}
              </Typo>
            </FlexRow>
          </FlexColumn>

          <Divider />

          <FlexColumn>
            <FlexRow>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">
                {t("enterpriseSignup:usedAccount")} {numberOfMember}
                {t("enterpriseSignup:accountUnit")}
              </Typo>
            </FlexRow>
            <FlexRow>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">
                {t("enterpriseSignup:loadingCode")} {numberOfLoadingCode}
                {t("enterpriseSignup:transmittedCount")}
              </Typo>
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </CallOut>
    );
  };

  const renderRegisterTypeItem = () => {
    if (watch("registerType") === "BUSINESS") {
      return (
        <>
          <FormItem
            label={t("common:cardType")}
            type="radioGroup"
            name="cardType"
            control={control}
            rules={{ required: true }}
            direction="vertical"
            options={CARD_REGISTER_TYPE_OPTION_LIST.map((item) => {
              return { ...item, label: t(item.langKey) };
            })}
            inputProps={{
              onChange: (e) => {
                setValue("cardType", e.target.value);
                clearErrors("userInfo");
              },
            }}
          />
          {watch("cardType") === "EMPLOYEE_PERSONALIZED_CARD" && (
            <FormItem
              label={t("normalPlanSignup:userInfo")}
              type="text"
              name="userInfo"
              control={control}
              rules={{
                required: watch("cardType") === "EMPLOYEE_PERSONALIZED_CARD",
                minLength: 6,
                validate: (value) => {
                  return isValidBirthDay(value);
                },
              }}
              errorsMessage={{
                required: t("error:required"),
                minLength: t("error:enterDateYYMMDD"),
                validate: t("error:enterDateYYMMDD"),
              }}
              direction="vertical"
              inputProps={{
                placeholder: t("normalPlanSignup:userInfoPlaceholder"),
                maxLength: 6,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("userInfo", e.target.value.replace(/[^0-9]/g, ""));
                },
              }}
            />
          )}
          <SectionCardRow
            label={
              <Label required>{t("normalPlanSignup:businessNumber")}</Label>
            }
            value={<Input disabled value={businessNumber} />}
            direction="vertical"
          />
        </>
      );
    }

    if (watch("registerType") === "PERSONAL") {
      return (
        <FormItem
          label={t("normalPlanSignup:userInfo")}
          type="text"
          name="userInfo"
          control={control}
          rules={{
            required: watch("registerType") === "PERSONAL",
            minLength: 6,
            validate: (value) => {
              return isValidBirthDay(value);
            },
          }}
          errorsMessage={{
            required: t("error:required"),
            minLength: t("error:enterDateYYMMDD"),
            validate: t("error:enterDateYYMMDD"),
          }}
          direction="vertical"
          inputProps={{
            placeholder: t("normalPlanSignup:userInfoPlaceholder"),
            maxLength: 6,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setValue("userInfo", e.target.value.replace(/[^0-9]/g, ""));
            },
          }}
        />
      );
    }
  };

  return (
    <StyledSectionCard
      cardTitle={t("enterpriseSignup:paymentInfoCardTitle")}
      cardContentContainerStyle={{ padding: "40px 152px" }}
    >
      <Main>
        <StyledTypo typoType="h1">
          {t("enterpriseSignup:paymentInfoTitle")}
        </StyledTypo>

        <Form onSubmit={handleSubmit(handleSubmitClick)}>
          {renderPlanInformationCard()}

          <StyledSectionCardRowFullWidth
            direction="vertical"
            label={
              <Label required>
                {t("normalPlanSignup:selectBusinessIndividual")}
              </Label>
            }
            value={
              <Controller
                name="registerType"
                control={control}
                render={({ field }) => {
                  return (
                    <StyledGridCardRadioContainer>
                      {REGISTER_TYPE_OPTION_LIST.map(({ langKey, value }) => {
                        const isChecked =
                          watch("registerType")?.includes(value);

                        return (
                          <GridCardRadio.Radio
                            {...field}
                            label={
                              <Typo
                                typoType="h9"
                                color={isChecked ? "blue4" : "gray6"}
                              >
                                {t(langKey)}
                              </Typo>
                            }
                            value={value}
                            checked={isChecked}
                          />
                        );
                      })}
                    </StyledGridCardRadioContainer>
                  );
                }}
              />
            }
          />
          {renderRegisterTypeItem()}

          <Divider />

          <SectionCardRow
            direction="vertical"
            label={<Label required>{t("normalPlanSignup:cardNumber")}</Label>}
            value={
              <FlexRow gap={8} ref={inputContainerRef}>
                {cardNumber.map((_, idx) => {
                  return (
                    <Input
                      key={idx.toString()}
                      type="text"
                      value={cardNumber[idx]}
                      placeholder={t("placeholder:enter")}
                      maxLength={4}
                      allowClear={false}
                      onChange={(e) => {
                        const copyCardNumber = [...cardNumber];
                        const result = copyCardNumber.map((item, itemIdx) => {
                          if (itemIdx === idx) {
                            return e?.target?.value.replace(/[^0-9]/g, "");
                          }
                          return item;
                        });

                        setCardNumber(result);
                      }}
                      onKeyDown={(e) => {
                        handleCardNumberInputKeyDown(e, idx);
                      }}
                    />
                  );
                })}
              </FlexRow>
            }
          />
          <FlexRow gap={8}>
            <StyledFormItem
              label={t("normalPlanSignup:expiredDate")}
              type="text"
              name="expiredDate"
              control={control as any}
              rules={{
                required: true,
                minLength: 5,
                validate: (value) => {
                  return isValidExpirationDate(value.replace(/[^0-9]/g, ""));
                },
              }}
              errorsMessage={{
                required: t("error:required"),
                validate: t("error:enterDateMMYY"),
                minLength: t("error:enterDateMMYY"),
              }}
              direction="vertical"
              inputProps={{
                placeholder: t("normalPlanSignup:expiredDatePlaceholder"),
                maxLength: 5,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleExpiredDateChange(e);
                },
                onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === "/") {
                    e.preventDefault();
                  }
                },
              }}
            />
            <StyledSectionCardRow
              label={<Label>{t("normalPlanSignup:password")}</Label>}
              direction="vertical"
              value={
                <Grid>
                  <StyledFormItem
                    type="text"
                    name="password2"
                    control={control as any}
                    rules={{ required: true }}
                    errorsMessage={{
                      required: t("error:required"),
                    }}
                    direction="vertical"
                    inputProps={{
                      placeholder: t("normalPlanSignup:passwordPlaceholder"),
                      maxLength: 2,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(
                          "password2",
                          e.target.value.replace(/[^0-9]/g, "")
                        );
                      },
                    }}
                  />
                  <Dot />
                  <Dot />
                </Grid>
              }
            />
          </FlexRow>

          <CheckboxContainer>
            <Checkbox
              checked={isConfirm}
              onChange={() => setIsConfirm((prev) => !prev)}
            />
            <Typo typoType="b7r">
              {t("enterpriseSignup:userInformationConfirm")}
            </Typo>
          </CheckboxContainer>

          <ButtonContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={goToPrev}
            >
              {t("enterpriseSignup:button.back")}
            </Button>
            <Button
              type="submit"
              disabled={isButtonDisabled}
              isLoading={isSubscribeLoading}
            >
              {t("enterpriseSignup:button.subscription")}
            </Button>
          </ButtonContainer>
        </Form>
      </Main>
    </StyledSectionCard>
  );
}

export default EnterprisePaymentRegistration;

const StyledSectionCard = styled(SectionCard)`
  width: 100%;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const StyledTypo = styled(Typo)`
  text-align: center;
  padding-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  button {
    flex: 1;
    text-align: center;
  }
`;

const FlexColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? 4}px;
  width: 100%;
`;

const FlexRow = styled(FlexColumn)`
  flex-direction: row;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const InfoIcon = styled(InfoSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.blue4};
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledGridCardRadioContainer = styled(GridCardRadio.Container)`
  grid-template-rows: minmax(60px, 60px);
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
  align-self: baseline;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  flex: 1;
  align-self: baseline;
`;

const Dot = styled(DotGray7Svg)`
  width: 8px;
  height: 8px;

  * {
    fill: ${colorSet.gray2};
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto 24px 24px;
  align-items: baseline;
  justify-items: center;
`;

const StyledSectionCardRowFullWidth = styled(SectionCardRow)`
  & > p {
    max-width: 100%;
  }
`;
