import { useAppSelector } from "@/src/store";
import colorSet from "@/src/styles/color";
import zIndex from "@/src/styles/zIndex";
import { useEffect } from "react";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";

const ContentLoading = () => {
  const isContentLoadingOn = useAppSelector(
    (store) => store.webUtil.contentLoading,
  );

  useEffect(() => {
    const rootDiv = document.getElementById("root");
    if (rootDiv && isContentLoadingOn) {
      rootDiv.style.overflow = "hidden";
    }

    return () => {
      if (rootDiv && !isContentLoadingOn) {
        rootDiv.style.overflow = "auto";
      }
    };
  }, [isContentLoadingOn]);

  if (!isContentLoadingOn) return null;

  return (
    <StyledContentLoading>
      <PulseLoader color={colorSet.blue4} />
    </StyledContentLoading>
  );
};

export default ContentLoading;

const StyledContentLoading = styled.div`
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 256px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.fullLoading};
  background-color: rgba(277, 277, 277, 0.9);
`;
