import CloseSvg from "@/src/assets/icons/icon-close-red.svg";
import PencelSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import { IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Radio from "@/src/components/atom/Radio";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SingleImageUpload from "@/src/components/molecule/SingleImageUpload";
import useAlert from "@/src/hooks/useAlert";
import { useAppSelector } from "@/src/store";
import {
  useAddSignMutation,
  useEditSignMutation,
  useGetExportersQuery,
  useRemoveSignMutation,
} from "@/src/store/apis/corporate/company";
import { MediaDto } from "@/src/store/apis/media/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DEFAULT_LOADING_TIME = 300;

const SignatureManagement = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const [addSign] = useAddSignMutation();
  const [editSign] = useEditSignMutation();
  const [deleteSign] = useRemoveSignMutation();
  const alert = useAlert();
  const { currentData } = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const isAdminUser = user?.exporterUserType === "CORPORATE_MANAGER";

  const handleChange = async (
    media: MediaDto,
    type: "edit" | "create",
    originalId?: number,
  ) => {
    try {
      if (type === "create") {
        await addSign({
          signatureMediaId: media.id,
        }).unwrap();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, DEFAULT_LOADING_TIME);
      } else {
        if (originalId) {
          await editSign({
            id: originalId,
            signatureMediaId: media.id,
          }).unwrap();
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
          }, DEFAULT_LOADING_TIME);
        }
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const list = Array.from({ length: 2 }).map(
    (empty, idx) =>
      currentData?.signatureManagement?.simpleExporterSignatureMedias?.[idx] ??
      undefined,
  );

  return (
    <StyledSectionCard
      cardTitle={t(
        "companyManagement:importer.companyInfo.signatureManagement.title",
      )}
    >
      <Content>
        {list.map((item, index) => {
          if (isLoading)
            return (
              <FlexCenter>
                <Loader />
              </FlexCenter>
            );
          return (
            <SingleImageContainer key={index.toString()}>
              <SingleImageUpload
                disabled={!isAdminUser}
                onChange={(media, type) => handleChange(media, type, item?.id)}
                value={item ? item.signatureSimpleMedia.mediaUrl : undefined}
                topAccessory={({ handleEdit, handleRemove }) => {
                  const isDeletable = list.filter(Boolean).length === 2;

                  return (
                    isAdminUser && (
                      <IconButtonContainer>
                        <StyledIconButton
                          buttonColor="black"
                          buttonGrade="tertiary"
                          buttonSize={24}
                          onClick={() => handleEdit()}
                        >
                          <Icon iconSrc={PencelSvg} iconSize={16} />
                        </StyledIconButton>
                        {isDeletable && (
                          <StyledIconButton
                            buttonColor="black"
                            buttonGrade="tertiary"
                            buttonSize={24}
                            onClick={async () => {
                              try {
                                handleRemove();
                                if (item) {
                                  await deleteSign({
                                    id: item.id,
                                  }).unwrap();
                                  setIsLoading(true);
                                  setTimeout(() => {
                                    setIsLoading(false);
                                  }, DEFAULT_LOADING_TIME);
                                }
                              } catch (e: any) {
                                const message = Array.isArray(e.data.message)
                                  ? e.data.message[0]
                                  : e.data.message;
                                alert.showAlert({ message, type: "error" });
                              }
                            }}
                          >
                            <Icon iconSrc={CloseSvg} iconSize={16} />
                          </StyledIconButton>
                        )}
                      </IconButtonContainer>
                    )
                  );
                }}
              />
              <RadioRow>
                <Radio
                  disabled={!item || !isAdminUser}
                  checked={item?.isDefault}
                  onClick={async () => {
                    if (item && !item.isDefault) {
                      await editSign({
                        id: item.id as number,
                        isDefault: true,
                      }).unwrap();
                      setIsLoading(true);
                      setTimeout(() => {
                        setIsLoading(false);
                      }, DEFAULT_LOADING_TIME);
                    }
                  }}
                >
                  <Typo typoType="b7r" color={item ? "gray2" : "gray7"}>
                    {t(
                      "companyManagement:importer.companyInfo.signatureManagement.signatureItem",
                    )}{" "}
                    {index + 1}
                  </Typo>
                </Radio>
              </RadioRow>
            </SingleImageContainer>
          );
        })}
      </Content>
    </StyledSectionCard>
  );
};

export default SignatureManagement;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledSectionCard = styled(SectionCard)`
  height: 100%;
`;

const RadioRow = styled.div`
  text-align: center;
  padding-top: 8px;
`;

const SingleImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;
  height: 128px;
`;

const IconButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 4px 0 0;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
