import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import styled, { css } from "styled-components";

interface SplitBadgeProps {
  size: "xs" | "s";
}

const SplitBadge = ({ size }: SplitBadgeProps) => {
  return <StyledBadge size={size}>S</StyledBadge>;
};

export default SplitBadge;

export const StyledBadge = styled.span<{ size: "xs" | "s" }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ size }) =>
    size === "xs"
      ? css`
          width: 14px;
          height: 14px;
          ${typo.b11m}
          line-height: 15px;
        `
      : css`
          width: 20px;
          height: 20px;
          ${typo.btn5m}
        `};

  color: ${colorSet.white};
  background: ${colorSet.systemGrape3};
  border-radius: 2px;
  flex-shrink: 0;
  margin-right: 4px;
`;
